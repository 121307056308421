<template>
    <div :class="[b(),className]"
         :style="styleSizeName">
      <div :ref="id"
           :style="styleChartName"></div>
    </div>
  </template>
  
  <script>
  import create from "../../create";
  
  export default create({
    name: "baseinstrumentpanel",
    computed: {
      x2 () {
        return this.option.gridX2 || 20;
      }
    },
  
    methods: {
      updateChart () {
        const optionData = this.deepClone(this.dataChart);
        console.log('optionData>>>>>>>>>>>>>>>>>>>>>>>');
        console.log(optionData);
        const option = {
          tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
          },
          series: optionData.series 
        };
        this.myChart.resize();
        this.myChart.setOption(option, true);
      }
    }
  });
  
  </script>
  