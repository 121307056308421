<!-- 柱状图配置 -->
<template>
    <div>
      <!-- <el-form-item label="GeoJsonUrl">
        <avue-input v-model="main.activeOption.geoJsonUrl">
        </avue-input>
      </el-form-item> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'buildings',
    inject: ["main"]
  }
  </script>
  
  <style>
  </style>