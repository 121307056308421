<template>
    <div :class="[b(),className]"
         :style="styleSizeName">
      <div :ref="id"
           :style="styleChartName"></div>
    </div>
  </template>
  
  <script>
  import create from "../../create";
  import cityJson from "../../../cons/HK";
  
  export default create({
    name: "hkpopulationdensity",
    computed: {
      x2 () {
        return this.option.gridX2 || 20;
      }
    },
  
    methods: {
      updateChart () {

        this.myChart.hideLoading();
        const optionData = this.deepClone(this.dataChart);
        echarts.registerMap(optionData.cityName, cityJson.cityJson[optionData.cityName]);

        const option = {
            title: this.ishasprop(this.option.titleShow, {
                text: this.option.title,
                subtext: this.option.subtext || '',
                textStyle: {
                    color: this.option.titleColor || '#333',
                    fontSize: this.option.titleFontSize || 16
                },
                left: this.option.titlePostion || 'auto',
                subtextStyle: {
                    color: this.option.subTitleColor || '#aaa',
                    fontSize: this.option.subTitleFontSize || 14
                }
            }, {}),
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br/>{c} (p / km2)'
            },
            // toolbox: {
            //     show: true,
            //     orient: 'vertical',
            //     left: 'right',
            //     top: 'center',
            //     feature: {
            //     dataView: { readOnly: false },
            //     restore: {},
            //     saveAsImage: {}
            //     }
            // },
            visualMap: {
                min: 800,
                max: 50000,
                text: ['High', 'Low'],
                realtime: false,
                calculable: true,
                inRange: {
                color: ['lightskyblue', 'yellow', 'orangered']
                }
            },
            series: [
                {
                name: optionData.name,
                type: 'map',
                map: optionData.cityName,
                label: {
                    show: true
                },
                data: optionData.data,
                // 自定义名称映射
                nameMap: optionData.nameMap
                }
            ]
        };
        this.myChart.resize();
        this.myChart.setOption(option, true);
      }
    }
  });
  
  </script>
  