<template>
    <div :class="[b(),className]"
         :style="styleSizeName">
      <!-- <div :ref="id"
           :style="styleChartName"></div>-->
      <iframe id="iframe" :src="option.iframeSrc" height="100%" width="100%" frameborder="0"></iframe>
    </div> 
    
  </template>
  
  <script>
  import create from "../../create";
  
  export default create({
    name: "outhtml",
    computed: {
      x2 () {
        return this.option.gridX2 || 20;
      }
    },
    methods: {
      updateChart () {
        const optionData = this.deepClone(this.dataChart);
        this.option.iframeSrc = optionData.outHtmlUrl;
      }
    }
  });
  
  </script>
  