<template>
    <div :class="[b(),className]"
         :style="styleSizeName">
      <div :ref="id"
           :style="styleChartName"></div>
    </div>
  </template>
  
  <script>
  import create from "../../create";
  
  export default create({
    name: "treevertical",
    computed: {
      x2 () {
        return this.option.gridX2 || 20;
      }
    },
  
    methods: {
        // setColor(obj,color) {
        //     obj['lineStyle'] = {
        //         color : color
        //     };
        //     //去实现子节点下的所有节点
        //     if(obj.children){
        //         obj.children.forEach( e => {
        //             this.setColor( e , color )
        //         })
        //     }
        // },
        updateChart () {
        const optionData = this.deepClone(this.dataChart);
        // this.setColor(optionData.data , this.option.lineColor);
        // console.log(optionData)

        const option = {
            title: this.ishasprop(this.option.titleShow, {
            text: this.option.title,
            subtext: this.option.subtext || '',
            textStyle: {
                color: this.option.titleColor || '#333',
                fontSize: this.option.titleFontSize || 16
            },
            left: this.option.titlePostion || 'auto',
            subtextStyle: {
                color: this.option.subTitleColor || '#aaa',
                fontSize: this.option.subTitleFontSize || 14
            }
            }, {}),
            // tooltip: {
            //     trigger: 'item',
            //     triggerOn: 'mousemove'
            // },
            tooltip:  this.ishasprop(this.formatter, {
                formatter: name => { return this.formatter(name, this.dataChart) }
            }, {
                trigger: 'item',
                triggerOn: 'mousemove',
                textStyle: {
                    fontSize: this.option.tipFontSize,
                    color: this.option.tipColor || "#fff"
                }
            }),
            series: [
                {
                type: 'tree',
                data: [optionData.data],
                left: '2%',
                right: '2%',
                top: '8%',
                bottom: '20%',
                symbol: 'emptyCircle',
                orient: 'vertical',
                expandAndCollapse: true,
                label: {
                    show: this.vaildData(this.option.labelShow, false), //开启显示
                    position: 'top', //在上方显示,
                    rotate: this.option.xAxisRotate || 0,
                    verticalAlign: 'middle',
                    align: 'right',
                    fontSize: this.option.labelShowFontSize || 14,
                    color: this.option.labelShowColor || "#333",
                    fontWeight: this.option.labelShowFontWeight || 500
                },
                leaves: {
                    label: {
                        show: this.vaildData(this.option.labelShow, false), //开启显示
                        position: 'bottom', //在下方显示,
                        rotate: this.option.xAxisRotate || 0,
                        verticalAlign: 'middle',
                        align: 'left',
                        fontSize: this.option.labelShowFontSize || 14,
                        color: this.option.labelShowColor || "#333",
                        fontWeight: this.option.labelShowFontWeight || 500
                    }
                },
                animationDurationUpdate: 750
                }
            ]
            };
        this.myChart.resize();
        this.myChart.setOption(option, true);
      }
    }
  });
  
  </script>
  