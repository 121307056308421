<!-- 柱状图配置 -->
<template>
    <div>
      <el-form-item label="文字角度">
        <avue-input-number v-model="main.activeOption.xAxisRotate">
        </avue-input-number>
      </el-form-item>
      <!-- <el-form-item label="连线颜色">
        <avue-input-color v-model="main.activeOption.lineColor"></avue-input-color>
      </el-form-item> -->
    </div>

    
  </template>
  
  <script>
  export default {
    name: 'treevertical',
    inject: ["main"]
  }
  </script>
  
  <style>
  </style>