const cityJson ={
	'HK': {
		"type": "FeatureCollection",
		"features": [{
				"type": "Feature",
				"properties": {
					"name": "Central and Western",
					"ID_0": 102,
					"ID_1": 1,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.113747, 22.285694],
								[114.113747, 22.285418],
								[114.114586, 22.285418],
								[114.114586, 22.285139],
								[114.114861, 22.285139],
								[114.114861, 22.284306],
								[114.115135, 22.284306],
								[114.115135, 22.283751],
								[114.114861, 22.283751],
								[114.114861, 22.283472],
								[114.113472, 22.283472],
								[114.113472, 22.283194],
								[114.112640, 22.283194],
								[114.112640, 22.282917],
								[114.111526, 22.282917],
								[114.111526, 22.282639],
								[114.111252, 22.282639],
								[114.111252, 22.284019],
								[114.111252, 22.284306],
								[114.110970, 22.284306],
								[114.110970, 22.284582],
								[114.111252, 22.284582],
								[114.111252, 22.284861],
								[114.111526, 22.284861],
								[114.111526, 22.284582],
								[114.111809, 22.284582],
								[114.111809, 22.285139],
								[114.112083, 22.285139],
								[114.112083, 22.284861],
								[114.112358, 22.284861],
								[114.112358, 22.285694],
								[114.113197, 22.285694],
								[114.113197, 22.285418],
								[114.113472, 22.285418],
								[114.113472, 22.285694],
								[114.113747, 22.285694]
							]
						],
						[
							[
								[114.143471, 22.290972],
								[114.143471, 22.290695],
								[114.144585, 22.290695],
								[114.144585, 22.290419],
								[114.145142, 22.290419],
								[114.145142, 22.290695],
								[114.146530, 22.290695],
								[114.146530, 22.290419],
								[114.147362, 22.290419],
								[114.147362, 22.290136],
								[114.147919, 22.290136],
								[114.147919, 22.289862],
								[114.148476, 22.289862],
								[114.148476, 22.289583],
								[114.149864, 22.289583],
								[114.149864, 22.289305],
								[114.150414, 22.289305],
								[114.150414, 22.289583],
								[114.150696, 22.289583],
								[114.150696, 22.290136],
								[114.150970, 22.290136],
								[114.150970, 22.290419],
								[114.152359, 22.290419],
								[114.152359, 22.289862],
								[114.152916, 22.289862],
								[114.152916, 22.289305],
								[114.153748, 22.289305],
								[114.153748, 22.289026],
								[114.153473, 22.289026],
								[114.153473, 22.288750],
								[114.153748, 22.288750],
								[114.153748, 22.288473],
								[114.154587, 22.288473],
								[114.154587, 22.288750],
								[114.156250, 22.288750],
								[114.156250, 22.288473],
								[114.157211, 22.288473],
								[114.157364, 22.288473],
								[114.157364, 22.288195],
								[114.157913, 22.288195],
								[114.157913, 22.288473],
								[114.159859, 22.288473],
								[114.159859, 22.288195],
								[114.160973, 22.288195],
								[114.160973, 22.288473],
								[114.161247, 22.288473],
								[114.161247, 22.288195],
								[114.161530, 22.288195],
								[114.161530, 22.286804],
								[114.161247, 22.286804],
								[114.161247, 22.285139],
								[114.160973, 22.285139],
								[114.160973, 22.284861],
								[114.161247, 22.284861],
								[114.161247, 22.284582],
								[114.160973, 22.284582],
								[114.160973, 22.284027],
								[114.161247, 22.284027],
								[114.161247, 22.283751],
								[114.161804, 22.283751],
								[114.161804, 22.283472],
								[114.162315, 22.283472],
								[114.162361, 22.283194],
								[114.163750, 22.283194],
								[114.163750, 22.282917],
								[114.164864, 22.282917],
								[114.164864, 22.282639],
								[114.165970, 22.282639],
								[114.165970, 22.282492],
								[114.165970, 22.282084],
								[114.166252, 22.282084],
								[114.166252, 22.281805],
								[114.166809, 22.281805],
								[114.166809, 22.281528],
								[114.166977, 22.281528],
								[114.167130, 22.280863],
								[114.167244, 22.280384],
								[114.168182, 22.278849],
								[114.168320, 22.278654],
								[114.168289, 22.276232],
								[114.168289, 22.274973],
								[114.166641, 22.273319],
								[114.166222, 22.271214],
								[114.165794, 22.269951],
								[114.165794, 22.268688],
								[114.165794, 22.267426],
								[114.166222, 22.266163],
								[114.165794, 22.264057],
								[114.165375, 22.262794],
								[114.164505, 22.261501],
								[114.163689, 22.260689],
								[114.163307, 22.260391],
								[114.161980, 22.259392],
								[114.161285, 22.259623],
								[114.160820, 22.259777],
								[114.160713, 22.259813],
								[114.158607, 22.258970],
								[114.158073, 22.259108],
								[114.157402, 22.259272],
								[114.156921, 22.259392],
								[114.155441, 22.260578],
								[114.154816, 22.261076],
								[114.153137, 22.261076],
								[114.152290, 22.260235],
								[114.152084, 22.259604],
								[114.151871, 22.258972],
								[114.151451, 22.257708],
								[114.148926, 22.256023],
								[114.145981, 22.254761],
								[114.144714, 22.255604],
								[114.144753, 22.255718],
								[114.144897, 22.256144],
								[114.145134, 22.256866],
								[114.145134, 22.258129],
								[114.144295, 22.258970],
								[114.143028, 22.259813],
								[114.142189, 22.260654],
								[114.142326, 22.261076],
								[114.142609, 22.261919],
								[114.142097, 22.262684],
								[114.141769, 22.263182],
								[114.141129, 22.264147],
								[114.140930, 22.264444],
								[114.140701, 22.265108],
								[114.140587, 22.265459],
								[114.140503, 22.265707],
								[114.139244, 22.266550],
								[114.138443, 22.268145],
								[114.137688, 22.269299],
								[114.137558, 22.269497],
								[114.137482, 22.269733],
								[114.137367, 22.270073],
								[114.137138, 22.270760],
								[114.135452, 22.272444],
								[114.134743, 22.272917],
								[114.134193, 22.273285],
								[114.131241, 22.274969],
								[114.126610, 22.274969],
								[114.124931, 22.274130],
								[114.123245, 22.273285],
								[114.119659, 22.273285],
								[114.119453, 22.273285],
								[114.118195, 22.273211],
								[114.117920, 22.273193],
								[114.117920, 22.273472],
								[114.117638, 22.273472],
								[114.117638, 22.273750],
								[114.117363, 22.273750],
								[114.117363, 22.274031],
								[114.116806, 22.274031],
								[114.116806, 22.274311],
								[114.116531, 22.274311],
								[114.116531, 22.274860],
								[114.116249, 22.274860],
								[114.116249, 22.275141],
								[114.115974, 22.275141],
								[114.115974, 22.276808],
								[114.116249, 22.276808],
								[114.116249, 22.278473],
								[114.116531, 22.278473],
								[114.116531, 22.279306],
								[114.116806, 22.279306],
								[114.116806, 22.279861],
								[114.117081, 22.279861],
								[114.117081, 22.280140],
								[114.117363, 22.280140],
								[114.117363, 22.280416],
								[114.117867, 22.280416],
								[114.117920, 22.280972],
								[114.118195, 22.280972],
								[114.118195, 22.281528],
								[114.118752, 22.281528],
								[114.118752, 22.281805],
								[114.119026, 22.281805],
								[114.119026, 22.282084],
								[114.120140, 22.282084],
								[114.120140, 22.282364],
								[114.120415, 22.282364],
								[114.120415, 22.282639],
								[114.121246, 22.282639],
								[114.121246, 22.282917],
								[114.122643, 22.282917],
								[114.122643, 22.283194],
								[114.123749, 22.283194],
								[114.123749, 22.283472],
								[114.124306, 22.283472],
								[114.124306, 22.283751],
								[114.125137, 22.283751],
								[114.125137, 22.284027],
								[114.125420, 22.284027],
								[114.125420, 22.284861],
								[114.125694, 22.284861],
								[114.125694, 22.285973],
								[114.126251, 22.285973],
								[114.126251, 22.286249],
								[114.126526, 22.286249],
								[114.126526, 22.286528],
								[114.128197, 22.286528],
								[114.128197, 22.286804],
								[114.128754, 22.286804],
								[114.128754, 22.287361],
								[114.129303, 22.287361],
								[114.129303, 22.287640],
								[114.131531, 22.287640],
								[114.131531, 22.288195],
								[114.131805, 22.288195],
								[114.131805, 22.288473],
								[114.132080, 22.288473],
								[114.132080, 22.289026],
								[114.132637, 22.289026],
								[114.132637, 22.289305],
								[114.132919, 22.289305],
								[114.132919, 22.289583],
								[114.133469, 22.289583],
								[114.133469, 22.289862],
								[114.134308, 22.289862],
								[114.134308, 22.289583],
								[114.136528, 22.289583],
								[114.136528, 22.289862],
								[114.137642, 22.289862],
								[114.137642, 22.290136],
								[114.138748, 22.290136],
								[114.138748, 22.290419],
								[114.139030, 22.290419],
								[114.139030, 22.290695],
								[114.139580, 22.290695],
								[114.139580, 22.290972],
								[114.139862, 22.290972],
								[114.139862, 22.290695],
								[114.140137, 22.290695],
								[114.140137, 22.290972],
								[114.141808, 22.290972],
								[114.141808, 22.290695],
								[114.142365, 22.290695],
								[114.142365, 22.290972],
								[114.143471, 22.290972]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Eastern",
					"ID_0": 102,
					"ID_1": 2,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.198250, 22.258787],
							[114.198608, 22.261059],
							[114.198738, 22.262077],
							[114.199104, 22.264368],
							[114.199203, 22.264963],
							[114.200386, 22.265554],
							[114.202217, 22.266737],
							[114.203873, 22.268097],
							[114.205582, 22.269453],
							[114.206474, 22.270342],
							[114.206886, 22.271345],
							[114.206825, 22.272646],
							[114.206413, 22.273354],
							[114.204872, 22.274652],
							[114.204765, 22.274767],
							[114.204048, 22.275539],
							[114.203873, 22.275597],
							[114.202911, 22.276558],
							[114.202812, 22.276653],
							[114.202492, 22.276867],
							[114.201553, 22.277494],
							[114.200706, 22.276653],
							[114.200706, 22.275646],
							[114.200706, 22.275406],
							[114.199448, 22.274548],
							[114.197884, 22.273785],
							[114.197372, 22.274162],
							[114.197304, 22.274239],
							[114.195343, 22.276524],
							[114.195053, 22.277111],
							[114.194679, 22.278034],
							[114.194397, 22.278761],
							[114.194214, 22.279469],
							[114.193970, 22.280443],
							[114.192711, 22.281284],
							[114.192490, 22.282156],
							[114.192291, 22.282969],
							[114.192009, 22.282782],
							[114.191681, 22.282560],
							[114.191025, 22.282125],
							[114.190346, 22.281855],
							[114.189705, 22.281597],
							[114.188919, 22.281284],
							[114.187553, 22.280739],
							[114.187447, 22.280716],
							[114.187447, 22.280857],
							[114.187210, 22.281742],
							[114.185379, 22.282808],
							[114.184135, 22.282984],
							[114.183601, 22.283064],
							[114.183495, 22.283079],
							[114.183044, 22.283381],
							[114.182449, 22.283232],
							[114.182129, 22.283279],
							[114.180473, 22.283043],
							[114.180305, 22.283072],
							[114.177917, 22.283470],
							[114.177917, 22.283751],
							[114.178192, 22.283751],
							[114.178192, 22.284582],
							[114.178391, 22.284582],
							[114.181526, 22.284580],
							[114.181526, 22.284861],
							[114.182640, 22.284861],
							[114.182640, 22.285418],
							[114.182907, 22.285418],
							[114.182907, 22.285141],
							[114.183197, 22.285139],
							[114.183197, 22.285418],
							[114.183434, 22.285418],
							[114.183472, 22.285694],
							[114.184029, 22.285694],
							[114.184029, 22.285973],
							[114.184586, 22.285973],
							[114.184586, 22.286249],
							[114.184860, 22.286249],
							[114.184860, 22.286528],
							[114.185417, 22.286528],
							[114.185417, 22.286804],
							[114.186531, 22.286804],
							[114.186531, 22.286528],
							[114.187080, 22.286528],
							[114.187080, 22.286251],
							[114.187920, 22.286249],
							[114.187920, 22.286528],
							[114.188194, 22.286528],
							[114.188194, 22.286804],
							[114.188469, 22.286804],
							[114.188469, 22.288195],
							[114.188751, 22.288195],
							[114.188751, 22.288473],
							[114.188858, 22.288473],
							[114.189583, 22.288473],
							[114.189583, 22.288750],
							[114.189857, 22.288750],
							[114.189857, 22.289026],
							[114.190140, 22.289026],
							[114.190140, 22.289305],
							[114.190414, 22.289305],
							[114.190414, 22.289583],
							[114.191254, 22.289583],
							[114.191254, 22.290136],
							[114.191803, 22.290136],
							[114.191803, 22.290419],
							[114.192085, 22.290419],
							[114.192085, 22.290695],
							[114.192360, 22.290695],
							[114.192360, 22.291248],
							[114.192642, 22.291248],
							[114.192642, 22.291531],
							[114.193192, 22.291531],
							[114.193192, 22.291805],
							[114.193474, 22.291805],
							[114.193474, 22.292358],
							[114.194580, 22.292358],
							[114.194580, 22.292641],
							[114.195694, 22.292641],
							[114.195694, 22.292917],
							[114.196526, 22.292917],
							[114.196526, 22.293194],
							[114.197083, 22.293194],
							[114.197083, 22.293472],
							[114.197250, 22.293472],
							[114.197906, 22.293472],
							[114.197914, 22.293751],
							[114.198471, 22.293751],
							[114.198471, 22.294306],
							[114.199028, 22.294306],
							[114.199028, 22.294584],
							[114.199585, 22.294584],
							[114.199585, 22.294306],
							[114.202362, 22.294306],
							[114.202362, 22.294584],
							[114.202637, 22.294584],
							[114.202637, 22.295139],
							[114.204025, 22.295139],
							[114.204025, 22.294863],
							[114.204308, 22.294863],
							[114.204308, 22.294584],
							[114.204842, 22.294584],
							[114.204865, 22.294306],
							[114.205139, 22.294306],
							[114.205139, 22.294027],
							[114.205971, 22.294027],
							[114.205971, 22.293751],
							[114.206253, 22.293751],
							[114.206253, 22.294027],
							[114.206528, 22.294027],
							[114.206528, 22.293751],
							[114.207359, 22.293751],
							[114.207359, 22.294027],
							[114.208473, 22.294027],
							[114.208473, 22.293751],
							[114.208748, 22.293751],
							[114.208748, 22.293194],
							[114.209305, 22.293194],
							[114.209305, 22.292917],
							[114.209587, 22.292917],
							[114.209587, 22.292641],
							[114.210976, 22.292641],
							[114.210976, 22.292917],
							[114.211250, 22.292917],
							[114.211250, 22.292641],
							[114.212082, 22.292641],
							[114.212082, 22.292917],
							[114.212364, 22.292917],
							[114.212364, 22.292641],
							[114.213196, 22.292641],
							[114.213196, 22.292084],
							[114.213753, 22.292084],
							[114.213753, 22.291805],
							[114.214584, 22.291805],
							[114.214584, 22.291531],
							[114.215141, 22.291531],
							[114.215141, 22.291248],
							[114.215973, 22.291248],
							[114.215973, 22.290972],
							[114.216248, 22.290972],
							[114.216248, 22.290695],
							[114.216530, 22.290695],
							[114.216530, 22.290136],
							[114.217361, 22.290136],
							[114.217361, 22.289862],
							[114.217918, 22.289862],
							[114.217918, 22.290136],
							[114.218475, 22.290136],
							[114.218475, 22.289862],
							[114.219025, 22.289862],
							[114.219025, 22.289583],
							[114.219582, 22.289583],
							[114.219582, 22.289305],
							[114.219864, 22.289305],
							[114.219864, 22.289026],
							[114.220413, 22.289026],
							[114.220413, 22.288761],
							[114.221527, 22.288750],
							[114.221527, 22.288473],
							[114.221756, 22.288473],
							[114.221809, 22.288195],
							[114.222359, 22.288195],
							[114.222359, 22.287914],
							[114.222916, 22.287914],
							[114.222916, 22.287640],
							[114.223473, 22.287640],
							[114.223473, 22.287361],
							[114.223747, 22.287361],
							[114.223747, 22.287083],
							[114.224304, 22.287083],
							[114.224304, 22.286804],
							[114.224861, 22.286804],
							[114.224861, 22.286528],
							[114.225136, 22.286528],
							[114.225136, 22.286249],
							[114.225418, 22.286249],
							[114.225418, 22.285973],
							[114.225975, 22.285973],
							[114.225975, 22.285694],
							[114.226250, 22.285694],
							[114.226250, 22.284027],
							[114.226524, 22.284027],
							[114.226524, 22.283472],
							[114.226784, 22.283472],
							[114.226807, 22.283194],
							[114.227364, 22.283194],
							[114.227364, 22.282917],
							[114.227913, 22.282917],
							[114.227913, 22.282639],
							[114.228195, 22.282639],
							[114.228195, 22.282364],
							[114.229584, 22.282364],
							[114.229584, 22.282639],
							[114.229858, 22.282639],
							[114.229858, 22.282917],
							[114.230141, 22.282917],
							[114.230141, 22.283194],
							[114.232086, 22.283194],
							[114.232086, 22.283472],
							[114.232635, 22.283472],
							[114.232635, 22.283751],
							[114.232918, 22.283751],
							[114.232918, 22.284027],
							[114.233032, 22.284027],
							[114.233749, 22.284027],
							[114.233749, 22.284306],
							[114.234306, 22.284306],
							[114.234306, 22.284582],
							[114.234863, 22.284582],
							[114.234863, 22.284306],
							[114.235008, 22.284306],
							[114.235420, 22.284306],
							[114.235420, 22.283751],
							[114.235695, 22.283751],
							[114.235695, 22.283472],
							[114.236206, 22.283472],
							[114.236252, 22.282955],
							[114.236809, 22.282917],
							[114.236809, 22.282639],
							[114.237083, 22.282639],
							[114.237083, 22.282084],
							[114.237640, 22.282084],
							[114.237640, 22.281805],
							[114.238197, 22.281805],
							[114.238197, 22.281528],
							[114.238472, 22.281528],
							[114.238472, 22.281252],
							[114.239029, 22.281252],
							[114.239029, 22.280972],
							[114.239586, 22.280972],
							[114.239586, 22.280416],
							[114.240135, 22.280416],
							[114.240135, 22.280140],
							[114.240417, 22.280140],
							[114.240417, 22.279861],
							[114.240692, 22.279861],
							[114.240692, 22.279583],
							[114.240974, 22.279583],
							[114.240974, 22.279306],
							[114.241249, 22.279306],
							[114.241249, 22.279030],
							[114.241531, 22.279030],
							[114.241531, 22.278748],
							[114.241806, 22.278748],
							[114.241806, 22.277637],
							[114.242081, 22.277637],
							[114.242081, 22.276808],
							[114.242363, 22.276808],
							[114.242363, 22.275694],
							[114.242638, 22.275694],
							[114.242638, 22.275139],
							[114.243195, 22.275139],
							[114.243195, 22.274860],
							[114.243469, 22.274860],
							[114.243469, 22.274311],
							[114.244026, 22.274305],
							[114.244026, 22.273750],
							[114.244858, 22.273750],
							[114.244858, 22.273472],
							[114.244583, 22.273472],
							[114.244583, 22.272917],
							[114.244308, 22.272917],
							[114.244308, 22.272083],
							[114.244026, 22.272083],
							[114.244026, 22.271528],
							[114.243752, 22.271528],
							[114.243752, 22.271250],
							[114.243469, 22.271250],
							[114.243469, 22.270695],
							[114.243195, 22.270695],
							[114.243195, 22.270416],
							[114.242920, 22.270416],
							[114.242920, 22.269861],
							[114.242638, 22.269861],
							[114.242638, 22.268749],
							[114.242920, 22.268749],
							[114.242920, 22.268194],
							[114.243469, 22.268194],
							[114.243469, 22.268473],
							[114.243752, 22.268473],
							[114.243752, 22.268749],
							[114.244026, 22.268749],
							[114.244026, 22.269028],
							[114.244308, 22.269028],
							[114.244308, 22.269306],
							[114.244858, 22.269306],
							[114.244858, 22.269583],
							[114.245140, 22.269583],
							[114.245140, 22.269861],
							[114.245415, 22.269861],
							[114.245415, 22.270140],
							[114.245140, 22.270140],
							[114.245140, 22.271250],
							[114.245415, 22.271250],
							[114.245415, 22.272083],
							[114.245697, 22.272083],
							[114.245697, 22.272638],
							[114.245972, 22.272638],
							[114.245972, 22.272083],
							[114.246246, 22.272083],
							[114.246246, 22.271805],
							[114.246803, 22.271805],
							[114.246803, 22.271250],
							[114.247086, 22.271250],
							[114.247086, 22.270428],
							[114.247353, 22.270416],
							[114.247360, 22.269861],
							[114.247917, 22.269861],
							[114.247917, 22.269583],
							[114.249016, 22.269583],
							[114.249031, 22.269306],
							[114.249306, 22.269306],
							[114.249306, 22.269028],
							[114.249580, 22.269028],
							[114.249580, 22.268749],
							[114.249863, 22.268749],
							[114.249863, 22.268473],
							[114.250137, 22.268473],
							[114.250137, 22.268194],
							[114.250420, 22.268194],
							[114.250420, 22.267918],
							[114.250580, 22.267916],
							[114.250694, 22.267916],
							[114.250694, 22.267651],
							[114.251808, 22.267639],
							[114.251808, 22.267363],
							[114.252357, 22.267363],
							[114.252357, 22.266806],
							[114.252640, 22.266806],
							[114.252640, 22.265972],
							[114.252876, 22.265972],
							[114.252914, 22.265694],
							[114.253197, 22.265694],
							[114.253197, 22.265417],
							[114.253471, 22.265417],
							[114.253471, 22.265141],
							[114.254028, 22.265141],
							[114.254028, 22.264862],
							[114.254250, 22.264862],
							[114.254303, 22.264584],
							[114.254585, 22.264584],
							[114.254585, 22.264305],
							[114.254860, 22.264305],
							[114.254860, 22.264027],
							[114.255417, 22.264027],
							[114.255417, 22.263748],
							[114.255692, 22.263748],
							[114.255692, 22.263472],
							[114.255974, 22.263472],
							[114.255974, 22.263197],
							[114.256248, 22.263197],
							[114.256248, 22.262920],
							[114.256531, 22.262918],
							[114.256531, 22.262362],
							[114.257080, 22.262362],
							[114.257080, 22.262083],
							[114.257362, 22.262083],
							[114.257362, 22.260973],
							[114.256805, 22.260973],
							[114.256805, 22.260695],
							[114.256531, 22.260695],
							[114.256531, 22.260414],
							[114.256248, 22.260414],
							[114.256248, 22.257921],
							[114.256531, 22.257917],
							[114.256531, 22.257572],
							[114.256531, 22.256433],
							[114.256531, 22.256250],
							[114.256248, 22.256250],
							[114.256248, 22.255138],
							[114.255692, 22.255138],
							[114.255692, 22.254583],
							[114.255417, 22.254583],
							[114.255417, 22.254305],
							[114.255692, 22.254305],
							[114.255692, 22.253752],
							[114.256248, 22.253752],
							[114.256248, 22.253469],
							[114.256531, 22.253469],
							[114.256531, 22.252916],
							[114.256805, 22.252916],
							[114.256805, 22.252361],
							[114.256531, 22.252361],
							[114.256531, 22.252083],
							[114.256248, 22.252083],
							[114.256248, 22.251247],
							[114.255974, 22.251247],
							[114.255974, 22.250973],
							[114.255692, 22.250973],
							[114.255692, 22.250416],
							[114.255417, 22.250416],
							[114.255417, 22.250141],
							[114.255142, 22.250137],
							[114.255142, 22.249861],
							[114.254860, 22.249861],
							[114.254860, 22.249584],
							[114.254585, 22.249584],
							[114.254585, 22.249308],
							[114.254303, 22.249308],
							[114.254303, 22.248751],
							[114.254028, 22.248751],
							[114.254028, 22.248194],
							[114.253754, 22.248194],
							[114.253754, 22.247915],
							[114.252914, 22.247915],
							[114.252914, 22.248194],
							[114.252640, 22.248194],
							[114.252640, 22.248472],
							[114.252357, 22.248472],
							[114.252357, 22.248751],
							[114.251808, 22.248751],
							[114.251808, 22.248472],
							[114.251251, 22.248472],
							[114.251251, 22.248194],
							[114.250969, 22.248194],
							[114.250969, 22.247084],
							[114.250465, 22.247114],
							[114.249603, 22.248459],
							[114.248703, 22.249710],
							[114.248283, 22.250973],
							[114.247437, 22.252235],
							[114.247017, 22.253498],
							[114.244072, 22.253920],
							[114.241547, 22.256023],
							[114.239861, 22.256445],
							[114.238174, 22.256866],
							[114.236496, 22.256866],
							[114.234810, 22.255604],
							[114.233124, 22.254761],
							[114.231018, 22.254761],
							[114.230179, 22.256023],
							[114.228493, 22.257288],
							[114.227234, 22.257708],
							[114.224281, 22.257708],
							[114.223022, 22.257708],
							[114.221756, 22.257708],
							[114.220497, 22.257708],
							[114.219231, 22.258129],
							[114.217552, 22.258551],
							[114.216286, 22.258970],
							[114.215446, 22.259813],
							[114.214600, 22.261076],
							[114.213341, 22.261076],
							[114.212074, 22.261076],
							[114.209969, 22.260654],
							[114.208290, 22.259813],
							[114.206184, 22.258970],
							[114.204079, 22.259813],
							[114.202393, 22.258970],
							[114.200706, 22.258551],
							[114.200577, 22.258551],
							[114.199448, 22.258551],
							[114.199341, 22.258411],
							[114.198936, 22.258551],
							[114.198792, 22.258600],
							[114.198250, 22.258787]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Islands",
					"ID_0": 102,
					"ID_1": 3,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[113.924026, 22.157084],
								[113.924026, 22.155972],
								[113.924309, 22.155972],
								[113.924309, 22.154583],
								[113.923752, 22.154583],
								[113.923752, 22.153749],
								[113.923470, 22.153749],
								[113.923470, 22.153473],
								[113.923195, 22.153473],
								[113.923195, 22.153194],
								[113.922081, 22.153194],
								[113.922081, 22.153749],
								[113.921524, 22.153749],
								[113.921524, 22.155136],
								[113.921806, 22.155136],
								[113.921806, 22.155697],
								[113.922081, 22.155697],
								[113.922081, 22.155972],
								[113.922638, 22.155972],
								[113.922638, 22.156248],
								[113.922920, 22.156248],
								[113.922920, 22.156807],
								[113.923195, 22.156807],
								[113.923195, 22.157084],
								[113.924026, 22.157084]
							]
						],
						[
							[
								[114.251808, 22.161249],
								[114.251808, 22.160139],
								[114.251526, 22.160139],
								[114.251526, 22.159861],
								[114.250969, 22.159861],
								[114.250969, 22.159584],
								[114.249031, 22.159584],
								[114.249031, 22.160418],
								[114.249489, 22.160418],
								[114.249580, 22.160418],
								[114.249580, 22.160694],
								[114.250137, 22.160694],
								[114.250137, 22.160973],
								[114.250420, 22.160973],
								[114.250420, 22.161528],
								[114.251526, 22.161528],
								[114.251526, 22.161249],
								[114.251808, 22.161249]
							]
						],
						[
							[
								[113.912086, 22.170973],
								[113.912086, 22.170137],
								[113.912361, 22.170137],
								[113.912361, 22.169584],
								[113.912636, 22.169584],
								[113.912636, 22.169025],
								[113.913475, 22.169025],
								[113.913475, 22.169308],
								[113.915138, 22.169308],
								[113.915138, 22.169025],
								[113.915413, 22.169025],
								[113.915413, 22.168751],
								[113.916252, 22.168751],
								[113.916252, 22.168472],
								[113.916527, 22.168472],
								[113.916527, 22.167084],
								[113.916252, 22.167084],
								[113.916252, 22.166529],
								[113.915695, 22.166529],
								[113.915695, 22.166250],
								[113.913475, 22.166250],
								[113.913475, 22.165972],
								[113.912918, 22.165972],
								[113.912918, 22.165417],
								[113.913193, 22.165417],
								[113.913193, 22.163195],
								[113.914581, 22.163195],
								[113.914581, 22.162916],
								[113.914864, 22.162916],
								[113.914864, 22.161528],
								[113.915138, 22.161528],
								[113.915138, 22.160694],
								[113.915695, 22.160694],
								[113.915695, 22.160418],
								[113.915970, 22.160418],
								[113.915970, 22.160139],
								[113.916252, 22.160139],
								[113.916252, 22.159584],
								[113.915970, 22.159584],
								[113.915970, 22.159306],
								[113.915695, 22.159306],
								[113.915695, 22.159027],
								[113.915138, 22.159027],
								[113.915138, 22.159306],
								[113.914864, 22.159306],
								[113.914864, 22.159584],
								[113.914307, 22.159584],
								[113.914307, 22.159861],
								[113.913750, 22.159861],
								[113.913750, 22.159584],
								[113.912361, 22.159584],
								[113.912361, 22.159027],
								[113.912086, 22.159027],
								[113.912086, 22.158472],
								[113.911804, 22.158472],
								[113.911804, 22.157919],
								[113.910416, 22.157919],
								[113.910416, 22.158472],
								[113.909859, 22.158472],
								[113.909859, 22.158751],
								[113.909027, 22.158751],
								[113.909027, 22.158472],
								[113.908470, 22.158472],
								[113.908470, 22.158194],
								[113.907913, 22.158194],
								[113.907913, 22.157919],
								[113.907364, 22.157919],
								[113.907364, 22.158194],
								[113.906250, 22.158194],
								[113.906250, 22.157919],
								[113.905975, 22.157919],
								[113.905975, 22.157084],
								[113.905693, 22.157084],
								[113.905693, 22.156807],
								[113.904305, 22.156807],
								[113.904305, 22.156248],
								[113.903748, 22.156248],
								[113.903748, 22.155972],
								[113.902641, 22.155972],
								[113.902641, 22.156248],
								[113.902359, 22.156248],
								[113.902359, 22.156807],
								[113.902084, 22.156807],
								[113.902084, 22.157084],
								[113.901253, 22.157084],
								[113.901253, 22.157640],
								[113.900406, 22.157640],
								[113.900139, 22.157640],
								[113.900139, 22.157909],
								[113.900139, 22.158472],
								[113.899574, 22.158472],
								[113.899307, 22.158472],
								[113.899307, 22.158751],
								[113.899025, 22.158751],
								[113.899025, 22.158897],
								[113.899025, 22.159027],
								[113.898766, 22.159027],
								[113.898750, 22.160418],
								[113.899025, 22.160418],
								[113.899025, 22.160694],
								[113.899307, 22.160694],
								[113.899307, 22.161249],
								[113.899582, 22.161249],
								[113.899582, 22.162083],
								[113.899864, 22.162083],
								[113.899864, 22.162361],
								[113.900696, 22.162361],
								[113.900696, 22.162640],
								[113.900970, 22.162640],
								[113.900970, 22.163195],
								[113.901802, 22.163195],
								[113.901802, 22.163473],
								[113.902916, 22.163473],
								[113.902916, 22.163750],
								[113.903259, 22.163750],
								[113.904861, 22.163750],
								[113.904861, 22.163473],
								[113.905136, 22.163473],
								[113.905136, 22.163082],
								[113.905136, 22.162916],
								[113.905418, 22.162916],
								[113.905418, 22.162361],
								[113.906525, 22.162361],
								[113.906525, 22.163195],
								[113.906807, 22.163195],
								[113.906807, 22.163750],
								[113.907082, 22.163750],
								[113.907082, 22.164028],
								[113.907639, 22.164028],
								[113.907639, 22.163473],
								[113.907913, 22.163473],
								[113.907913, 22.163750],
								[113.908195, 22.163750],
								[113.908195, 22.164028],
								[113.908752, 22.164028],
								[113.908752, 22.164583],
								[113.909302, 22.164583],
								[113.909302, 22.164862],
								[113.909584, 22.164862],
								[113.909584, 22.166250],
								[113.909302, 22.166250],
								[113.909302, 22.166529],
								[113.908752, 22.166529],
								[113.908752, 22.166805],
								[113.908493, 22.166805],
								[113.908470, 22.167084],
								[113.908752, 22.167084],
								[113.908752, 22.169308],
								[113.909027, 22.169308],
								[113.909027, 22.169861],
								[113.909584, 22.169861],
								[113.909584, 22.170137],
								[113.910141, 22.170137],
								[113.910141, 22.170694],
								[113.910698, 22.170694],
								[113.910698, 22.170973],
								[113.912086, 22.170973]
							]
						],
						[
							[
								[113.901802, 22.173471],
								[113.901802, 22.173195],
								[113.902359, 22.173195],
								[113.902359, 22.171806],
								[113.902084, 22.171806],
								[113.902084, 22.171530],
								[113.901802, 22.171530],
								[113.901802, 22.171247],
								[113.900696, 22.171247],
								[113.900696, 22.171806],
								[113.899864, 22.171806],
								[113.899864, 22.171247],
								[113.899582, 22.171247],
								[113.899582, 22.170694],
								[113.898193, 22.170694],
								[113.898193, 22.171247],
								[113.897636, 22.171247],
								[113.897636, 22.171530],
								[113.897362, 22.171530],
								[113.897362, 22.171806],
								[113.897636, 22.171806],
								[113.897636, 22.172083],
								[113.898750, 22.172083],
								[113.898750, 22.172361],
								[113.899857, 22.172361],
								[113.899864, 22.172640],
								[113.900970, 22.172642],
								[113.900970, 22.172916],
								[113.901215, 22.172916],
								[113.901253, 22.173471],
								[113.901802, 22.173471]
							]
						],
						[
							[
								[113.905418, 22.175694],
								[113.905418, 22.175417],
								[113.905693, 22.175417],
								[113.905693, 22.174862],
								[113.905418, 22.174862],
								[113.905418, 22.173752],
								[113.905975, 22.173752],
								[113.905975, 22.172947],
								[113.905693, 22.172916],
								[113.905693, 22.172642],
								[113.905136, 22.172640],
								[113.905136, 22.172916],
								[113.904587, 22.172916],
								[113.904587, 22.173195],
								[113.904030, 22.173195],
								[113.904030, 22.173752],
								[113.903748, 22.173752],
								[113.903748, 22.174583],
								[113.904305, 22.174583],
								[113.904305, 22.175138],
								[113.904587, 22.175138],
								[113.904587, 22.175417],
								[113.904861, 22.175417],
								[113.904861, 22.175694],
								[113.905418, 22.175694]
							]
						],
						[
							[
								[113.923752, 22.180414],
								[113.923752, 22.180140],
								[113.924026, 22.180140],
								[113.924026, 22.179583],
								[113.924583, 22.179583],
								[113.924583, 22.179028],
								[113.925140, 22.179028],
								[113.925140, 22.178751],
								[113.925407, 22.178749],
								[113.925415, 22.178473],
								[113.925697, 22.178473],
								[113.925697, 22.178192],
								[113.926247, 22.178192],
								[113.926247, 22.177639],
								[113.925972, 22.177639],
								[113.925972, 22.177082],
								[113.924858, 22.177082],
								[113.924858, 22.177361],
								[113.924583, 22.177361],
								[113.924583, 22.177639],
								[113.924026, 22.177639],
								[113.924026, 22.177917],
								[113.923752, 22.177917],
								[113.923752, 22.178192],
								[113.923470, 22.178192],
								[113.923470, 22.179028],
								[113.923241, 22.179028],
								[113.923195, 22.179583],
								[113.922920, 22.179583],
								[113.922920, 22.180140],
								[113.923187, 22.180140],
								[113.923195, 22.180414],
								[113.923752, 22.180414]
							]
						],
						[
							[
								[114.251808, 22.161249],
								[114.251808, 22.162361],
								[114.252083, 22.162361],
								[114.252083, 22.162640],
								[114.252640, 22.162640],
								[114.252640, 22.162916],
								[114.252914, 22.162916],
								[114.252914, 22.163473],
								[114.253197, 22.163473],
								[114.253197, 22.163750],
								[114.253471, 22.163750],
								[114.253471, 22.164028],
								[114.253754, 22.164028],
								[114.253754, 22.164305],
								[114.254028, 22.164305],
								[114.254028, 22.164583],
								[114.254303, 22.164583],
								[114.254303, 22.164862],
								[114.252640, 22.164862],
								[114.252640, 22.164583],
								[114.252357, 22.164583],
								[114.252357, 22.164305],
								[114.251526, 22.164305],
								[114.251526, 22.164028],
								[114.250969, 22.164028],
								[114.250969, 22.164305],
								[114.250420, 22.164305],
								[114.250420, 22.164862],
								[114.250137, 22.164862],
								[114.250137, 22.165138],
								[114.249863, 22.165138],
								[114.249863, 22.166250],
								[114.250137, 22.166250],
								[114.250137, 22.166805],
								[114.250420, 22.166805],
								[114.250420, 22.167360],
								[114.250694, 22.167360],
								[114.250694, 22.167915],
								[114.250969, 22.167915],
								[114.250969, 22.168194],
								[114.251251, 22.168194],
								[114.251251, 22.168472],
								[114.251808, 22.168472],
								[114.251808, 22.168751],
								[114.251526, 22.168751],
								[114.251526, 22.169025],
								[114.251808, 22.169025],
								[114.251808, 22.169456],
								[114.251808, 22.170137],
								[114.252083, 22.170137],
								[114.252083, 22.170694],
								[114.252357, 22.170694],
								[114.252357, 22.170973],
								[114.252640, 22.170973],
								[114.252640, 22.171530],
								[114.252914, 22.171530],
								[114.252914, 22.171806],
								[114.253197, 22.171806],
								[114.253197, 22.172083],
								[114.253471, 22.172083],
								[114.253471, 22.172361],
								[114.253754, 22.172361],
								[114.253754, 22.172640],
								[114.253914, 22.172642],
								[114.254028, 22.172642],
								[114.254028, 22.172916],
								[114.254150, 22.172916],
								[114.254303, 22.172916],
								[114.254303, 22.173195],
								[114.254585, 22.173195],
								[114.254585, 22.173471],
								[114.254860, 22.173471],
								[114.254860, 22.173752],
								[114.255142, 22.173752],
								[114.255142, 22.174028],
								[114.255417, 22.174028],
								[114.255417, 22.174305],
								[114.255692, 22.174305],
								[114.255692, 22.174583],
								[114.255974, 22.174583],
								[114.255974, 22.174862],
								[114.256248, 22.174862],
								[114.256248, 22.175138],
								[114.256531, 22.175138],
								[114.256531, 22.175417],
								[114.256805, 22.175417],
								[114.256805, 22.175694],
								[114.257080, 22.175695],
								[114.257080, 22.175974],
								[114.257919, 22.175974],
								[114.257919, 22.176250],
								[114.258751, 22.176250],
								[114.258751, 22.176527],
								[114.259583, 22.176527],
								[114.259583, 22.176805],
								[114.261253, 22.176805],
								[114.261253, 22.177082],
								[114.262642, 22.177082],
								[114.262642, 22.177361],
								[114.263748, 22.177361],
								[114.263748, 22.179304],
								[114.264030, 22.179304],
								[114.264030, 22.179861],
								[114.264580, 22.179861],
								[114.264580, 22.180140],
								[114.265976, 22.180140],
								[114.265976, 22.180414],
								[114.266251, 22.180414],
								[114.266251, 22.180695],
								[114.266808, 22.180695],
								[114.266808, 22.180414],
								[114.267082, 22.180414],
								[114.267082, 22.179861],
								[114.267365, 22.179861],
								[114.267365, 22.179028],
								[114.267639, 22.179028],
								[114.267639, 22.178749],
								[114.267914, 22.178749],
								[114.267914, 22.178473],
								[114.268471, 22.178473],
								[114.268471, 22.178192],
								[114.268753, 22.178192],
								[114.268753, 22.177917],
								[114.269585, 22.177917],
								[114.269585, 22.177639],
								[114.270142, 22.177639],
								[114.270142, 22.177361],
								[114.270416, 22.177361],
								[114.270416, 22.177082],
								[114.270973, 22.177082],
								[114.270973, 22.176805],
								[114.271530, 22.176805],
								[114.271530, 22.176527],
								[114.271805, 22.176527],
								[114.271805, 22.176111],
								[114.271805, 22.175974],
								[114.272087, 22.175974],
								[114.272087, 22.175695],
								[114.272362, 22.175694],
								[114.272362, 22.175278],
								[114.272362, 22.174028],
								[114.272636, 22.174028],
								[114.272636, 22.173195],
								[114.273193, 22.173195],
								[114.273193, 22.171530],
								[114.272919, 22.171530],
								[114.272919, 22.171247],
								[114.272636, 22.171247],
								[114.272636, 22.170973],
								[114.272362, 22.170973],
								[114.272362, 22.170694],
								[114.272087, 22.170694],
								[114.272087, 22.169359],
								[114.272087, 22.169025],
								[114.272362, 22.169025],
								[114.272362, 22.168472],
								[114.272636, 22.168472],
								[114.272636, 22.168194],
								[114.273193, 22.168194],
								[114.273193, 22.168034],
								[114.273193, 22.167639],
								[114.273476, 22.167639],
								[114.273476, 22.166529],
								[114.273750, 22.166529],
								[114.273750, 22.166250],
								[114.274025, 22.166250],
								[114.274025, 22.165972],
								[114.274307, 22.165972],
								[114.274307, 22.165417],
								[114.274582, 22.165417],
								[114.274582, 22.164028],
								[114.274864, 22.164028],
								[114.274864, 22.162640],
								[114.274582, 22.162640],
								[114.274582, 22.162361],
								[114.273476, 22.162361],
								[114.273476, 22.162083],
								[114.270973, 22.162083],
								[114.270973, 22.161806],
								[114.270416, 22.161806],
								[114.270416, 22.162083],
								[114.269302, 22.162083],
								[114.269302, 22.162361],
								[114.268753, 22.162361],
								[114.268753, 22.162640],
								[114.267639, 22.162640],
								[114.267639, 22.162361],
								[114.266251, 22.162361],
								[114.266251, 22.162916],
								[114.265976, 22.162916],
								[114.265976, 22.164028],
								[114.265694, 22.164028],
								[114.265694, 22.164305],
								[114.264862, 22.164305],
								[114.264862, 22.164028],
								[114.264580, 22.164028],
								[114.264580, 22.163750],
								[114.264305, 22.163750],
								[114.264305, 22.163473],
								[114.264030, 22.163473],
								[114.264030, 22.162640],
								[114.263748, 22.162640],
								[114.263748, 22.162361],
								[114.263191, 22.162361],
								[114.263191, 22.162083],
								[114.262917, 22.162083],
								[114.262917, 22.161806],
								[114.262642, 22.161806],
								[114.262642, 22.161528],
								[114.262360, 22.161528],
								[114.262360, 22.161249],
								[114.262085, 22.161249],
								[114.262085, 22.160973],
								[114.261803, 22.160973],
								[114.261803, 22.160694],
								[114.261528, 22.160694],
								[114.261528, 22.159584],
								[114.261803, 22.159584],
								[114.261803, 22.159027],
								[114.261528, 22.159027],
								[114.261528, 22.158472],
								[114.261253, 22.158472],
								[114.261253, 22.158194],
								[114.260971, 22.158194],
								[114.260971, 22.157919],
								[114.260414, 22.157919],
								[114.260414, 22.157640],
								[114.260139, 22.157640],
								[114.260139, 22.157362],
								[114.259865, 22.157362],
								[114.259865, 22.156807],
								[114.259308, 22.156807],
								[114.259308, 22.156527],
								[114.259026, 22.156527],
								[114.259026, 22.156248],
								[114.258751, 22.156248],
								[114.258751, 22.155972],
								[114.257919, 22.155972],
								[114.257919, 22.155697],
								[114.257080, 22.155697],
								[114.257080, 22.155418],
								[114.256531, 22.155418],
								[114.256531, 22.155136],
								[114.255692, 22.155136],
								[114.255692, 22.155418],
								[114.255417, 22.155418],
								[114.255417, 22.155972],
								[114.255692, 22.155972],
								[114.255692, 22.156527],
								[114.255974, 22.156527],
								[114.255974, 22.157084],
								[114.256248, 22.157084],
								[114.256248, 22.157362],
								[114.256531, 22.157362],
								[114.256531, 22.157728],
								[114.256531, 22.157919],
								[114.256805, 22.157919],
								[114.256805, 22.158194],
								[114.257080, 22.158194],
								[114.257080, 22.158472],
								[114.257362, 22.158472],
								[114.257362, 22.158751],
								[114.257637, 22.158751],
								[114.257637, 22.159027],
								[114.257919, 22.159027],
								[114.257919, 22.159861],
								[114.257637, 22.159861],
								[114.257637, 22.160139],
								[114.257080, 22.160139],
								[114.257080, 22.160418],
								[114.256531, 22.160418],
								[114.256531, 22.160694],
								[114.255692, 22.160694],
								[114.255692, 22.160973],
								[114.254028, 22.160973],
								[114.254028, 22.161249],
								[114.251808, 22.161249]
							]
						],
						[
							[
								[113.907082, 22.187916],
								[113.907082, 22.187363],
								[113.907913, 22.187363],
								[113.907913, 22.187084],
								[113.908928, 22.187084],
								[113.909027, 22.187084],
								[113.909027, 22.186806],
								[113.909302, 22.186806],
								[113.909302, 22.186670],
								[113.909309, 22.186527],
								[113.909576, 22.186527],
								[113.909584, 22.186251],
								[113.910141, 22.186251],
								[113.910141, 22.185972],
								[113.910416, 22.185972],
								[113.910416, 22.185417],
								[113.910698, 22.185417],
								[113.910698, 22.184584],
								[113.910973, 22.184584],
								[113.910973, 22.184029],
								[113.911247, 22.184029],
								[113.911247, 22.183748],
								[113.912636, 22.183748],
								[113.912636, 22.183195],
								[113.913475, 22.183195],
								[113.913475, 22.183748],
								[113.913750, 22.183748],
								[113.913750, 22.184029],
								[113.915138, 22.184029],
								[113.915138, 22.183748],
								[113.915413, 22.183748],
								[113.915413, 22.183472],
								[113.915695, 22.183472],
								[113.915695, 22.183195],
								[113.915970, 22.183195],
								[113.915970, 22.182919],
								[113.916252, 22.182919],
								[113.916252, 22.182636],
								[113.916527, 22.182636],
								[113.916527, 22.182362],
								[113.916809, 22.182362],
								[113.916809, 22.181805],
								[113.917915, 22.181805],
								[113.917915, 22.182083],
								[113.918198, 22.182083],
								[113.918198, 22.182362],
								[113.918747, 22.182362],
								[113.918747, 22.182636],
								[113.919029, 22.182636],
								[113.919029, 22.182362],
								[113.919586, 22.182362],
								[113.919586, 22.182083],
								[113.919861, 22.182083],
								[113.919861, 22.181526],
								[113.920135, 22.181526],
								[113.920135, 22.181250],
								[113.920418, 22.181250],
								[113.920418, 22.180695],
								[113.920692, 22.180695],
								[113.920692, 22.180414],
								[113.920967, 22.180414],
								[113.920975, 22.180140],
								[113.921249, 22.180140],
								[113.921249, 22.179304],
								[113.920975, 22.179304],
								[113.920975, 22.178473],
								[113.920692, 22.178473],
								[113.920692, 22.177917],
								[113.919586, 22.177917],
								[113.919586, 22.178473],
								[113.919312, 22.178473],
								[113.919304, 22.179028],
								[113.919029, 22.179028],
								[113.919029, 22.179304],
								[113.918472, 22.179304],
								[113.918472, 22.179583],
								[113.917915, 22.179583],
								[113.917915, 22.179861],
								[113.917641, 22.179861],
								[113.917641, 22.180140],
								[113.917358, 22.180140],
								[113.917358, 22.180414],
								[113.917084, 22.180414],
								[113.917084, 22.180140],
								[113.916527, 22.180140],
								[113.916527, 22.179304],
								[113.916809, 22.179304],
								[113.916809, 22.179028],
								[113.917076, 22.179028],
								[113.917084, 22.178473],
								[113.917358, 22.178473],
								[113.917358, 22.177917],
								[113.917084, 22.177917],
								[113.917084, 22.177639],
								[113.916809, 22.177639],
								[113.916809, 22.177082],
								[113.915703, 22.177082],
								[113.915695, 22.177361],
								[113.915413, 22.177361],
								[113.915413, 22.177917],
								[113.915138, 22.177917],
								[113.915138, 22.179304],
								[113.914864, 22.179304],
								[113.914864, 22.179583],
								[113.914581, 22.179583],
								[113.914581, 22.179028],
								[113.914307, 22.179028],
								[113.914307, 22.178473],
								[113.912918, 22.178473],
								[113.912918, 22.178749],
								[113.912636, 22.178749],
								[113.912636, 22.179861],
								[113.912918, 22.179861],
								[113.912918, 22.180140],
								[113.913193, 22.180140],
								[113.913193, 22.180695],
								[113.912918, 22.180695],
								[113.912918, 22.181250],
								[113.912636, 22.181250],
								[113.912636, 22.181805],
								[113.912361, 22.181805],
								[113.912361, 22.182083],
								[113.910141, 22.182083],
								[113.910141, 22.181805],
								[113.909859, 22.181805],
								[113.909859, 22.181526],
								[113.909027, 22.181526],
								[113.909027, 22.181250],
								[113.908752, 22.181250],
								[113.908752, 22.180140],
								[113.909027, 22.180140],
								[113.909027, 22.179861],
								[113.908752, 22.179861],
								[113.908752, 22.179583],
								[113.907639, 22.179583],
								[113.907639, 22.178473],
								[113.906525, 22.178473],
								[113.906525, 22.179028],
								[113.905357, 22.179028],
								[113.905136, 22.179028],
								[113.905136, 22.179583],
								[113.904861, 22.179583],
								[113.904861, 22.180140],
								[113.905136, 22.180140],
								[113.905136, 22.180973],
								[113.905418, 22.180973],
								[113.905418, 22.181250],
								[113.905693, 22.181250],
								[113.905693, 22.181526],
								[113.905975, 22.181526],
								[113.905975, 22.181805],
								[113.905693, 22.181805],
								[113.905693, 22.182362],
								[113.906250, 22.182362],
								[113.906250, 22.182636],
								[113.905975, 22.182636],
								[113.905975, 22.183195],
								[113.905693, 22.183195],
								[113.905693, 22.183472],
								[113.905418, 22.183472],
								[113.905418, 22.184305],
								[113.905693, 22.184305],
								[113.905693, 22.184584],
								[113.905418, 22.184584],
								[113.905418, 22.185141],
								[113.905670, 22.185141],
								[113.905693, 22.185972],
								[113.905975, 22.185972],
								[113.905975, 22.187639],
								[113.906250, 22.187639],
								[113.906250, 22.187916],
								[113.907082, 22.187916]
							]
						],
						[
							[
								[114.304031, 22.181526],
								[114.304031, 22.181250],
								[114.303749, 22.181250],
								[114.303749, 22.180973],
								[114.303474, 22.180973],
								[114.303474, 22.180695],
								[114.302635, 22.180695],
								[114.302635, 22.180971],
								[114.302361, 22.180973],
								[114.302361, 22.181250],
								[114.302086, 22.181250],
								[114.302086, 22.181526],
								[114.301804, 22.181526],
								[114.301804, 22.182362],
								[114.302086, 22.182362],
								[114.302086, 22.182636],
								[114.302361, 22.182636],
								[114.302361, 22.182919],
								[114.302917, 22.182919],
								[114.302917, 22.183195],
								[114.303474, 22.183195],
								[114.303474, 22.184029],
								[114.303749, 22.184029],
								[114.303749, 22.184305],
								[114.304031, 22.184305],
								[114.304031, 22.184584],
								[114.304306, 22.184584],
								[114.304306, 22.184858],
								[114.304581, 22.184858],
								[114.304581, 22.185417],
								[114.304863, 22.185417],
								[114.304863, 22.186251],
								[114.305138, 22.186251],
								[114.305138, 22.188473],
								[114.305420, 22.188473],
								[114.305420, 22.188749],
								[114.305695, 22.188749],
								[114.305695, 22.189028],
								[114.306808, 22.189028],
								[114.306808, 22.188751],
								[114.307083, 22.188749],
								[114.307083, 22.187363],
								[114.306808, 22.187363],
								[114.306808, 22.186251],
								[114.306526, 22.186251],
								[114.306526, 22.185417],
								[114.306252, 22.185417],
								[114.306252, 22.184858],
								[114.305969, 22.184858],
								[114.305969, 22.184584],
								[114.305695, 22.184584],
								[114.305695, 22.184029],
								[114.305420, 22.184029],
								[114.305420, 22.183472],
								[114.305138, 22.183472],
								[114.305138, 22.182919],
								[114.304863, 22.182919],
								[114.304863, 22.182362],
								[114.304581, 22.182362],
								[114.304581, 22.181805],
								[114.304306, 22.181805],
								[114.304306, 22.181530],
								[114.304031, 22.181526]
							]
						],
						[
							[
								[114.255417, 22.189861],
								[114.255417, 22.189583],
								[114.255692, 22.189583],
								[114.255692, 22.189306],
								[114.255966, 22.189306],
								[114.255974, 22.189030],
								[114.256248, 22.189028],
								[114.256248, 22.188751],
								[114.256531, 22.188749],
								[114.256531, 22.188473],
								[114.256805, 22.188473],
								[114.256805, 22.188194],
								[114.257080, 22.188194],
								[114.257080, 22.184029],
								[114.256805, 22.184029],
								[114.256805, 22.183195],
								[114.256531, 22.183195],
								[114.256531, 22.182636],
								[114.256248, 22.182636],
								[114.256248, 22.182362],
								[114.255974, 22.182362],
								[114.255974, 22.182083],
								[114.255692, 22.182083],
								[114.255692, 22.181805],
								[114.255417, 22.181805],
								[114.255417, 22.181526],
								[114.255142, 22.181526],
								[114.255142, 22.181250],
								[114.254860, 22.181250],
								[114.254860, 22.180973],
								[114.254143, 22.180973],
								[114.254028, 22.180973],
								[114.254028, 22.180695],
								[114.253754, 22.180695],
								[114.253754, 22.180414],
								[114.253197, 22.180414],
								[114.253197, 22.180140],
								[114.252640, 22.180140],
								[114.252640, 22.179861],
								[114.252083, 22.179861],
								[114.252083, 22.179583],
								[114.251526, 22.179583],
								[114.251526, 22.179304],
								[114.250969, 22.179304],
								[114.250969, 22.179583],
								[114.250694, 22.179583],
								[114.250694, 22.179861],
								[114.250420, 22.179861],
								[114.250420, 22.180414],
								[114.249863, 22.180414],
								[114.249863, 22.180695],
								[114.249306, 22.180695],
								[114.249306, 22.180414],
								[114.247917, 22.180414],
								[114.247917, 22.180695],
								[114.247360, 22.180695],
								[114.247360, 22.181250],
								[114.247093, 22.181250],
								[114.247086, 22.181526],
								[114.245972, 22.181526],
								[114.245972, 22.181250],
								[114.245697, 22.181250],
								[114.245697, 22.180140],
								[114.245972, 22.180140],
								[114.245972, 22.179028],
								[114.245140, 22.179028],
								[114.245140, 22.178751],
								[114.244858, 22.178749],
								[114.244858, 22.179304],
								[114.244583, 22.179304],
								[114.244583, 22.179583],
								[114.244858, 22.179583],
								[114.244858, 22.180140],
								[114.244583, 22.180140],
								[114.244583, 22.180414],
								[114.244308, 22.180414],
								[114.244308, 22.180973],
								[114.244026, 22.180973],
								[114.244026, 22.181250],
								[114.243752, 22.181250],
								[114.243752, 22.181526],
								[114.243469, 22.181526],
								[114.243469, 22.182919],
								[114.243752, 22.182919],
								[114.243752, 22.184029],
								[114.244003, 22.184029],
								[114.244026, 22.184584],
								[114.243752, 22.184584],
								[114.243752, 22.186806],
								[114.243469, 22.186806],
								[114.243469, 22.188194],
								[114.244858, 22.188194],
								[114.244858, 22.188473],
								[114.246246, 22.188473],
								[114.246246, 22.188749],
								[114.248192, 22.188749],
								[114.248192, 22.189028],
								[114.249863, 22.189028],
								[114.249863, 22.189306],
								[114.251808, 22.189306],
								[114.251808, 22.189583],
								[114.252914, 22.189583],
								[114.252914, 22.189861],
								[114.255417, 22.189861]
							]
						],
						[
							[
								[114.290413, 22.190140],
								[114.290413, 22.189861],
								[114.290642, 22.189861],
								[114.290695, 22.189583],
								[114.290970, 22.189583],
								[114.290970, 22.189306],
								[114.291252, 22.189306],
								[114.291252, 22.188751],
								[114.290970, 22.188749],
								[114.290970, 22.188194],
								[114.290695, 22.188194],
								[114.290695, 22.187916],
								[114.290413, 22.187916],
								[114.290413, 22.187363],
								[114.290138, 22.187363],
								[114.290138, 22.187084],
								[114.289864, 22.187084],
								[114.289864, 22.185972],
								[114.289581, 22.185972],
								[114.289581, 22.185141],
								[114.289307, 22.185141],
								[114.289307, 22.184858],
								[114.289581, 22.184858],
								[114.289581, 22.183802],
								[114.289864, 22.183748],
								[114.289864, 22.182362],
								[114.289581, 22.182362],
								[114.289581, 22.181805],
								[114.289307, 22.181805],
								[114.289307, 22.181250],
								[114.289024, 22.181250],
								[114.289024, 22.180973],
								[114.288750, 22.180973],
								[114.288750, 22.180695],
								[114.288193, 22.180695],
								[114.288193, 22.180414],
								[114.286530, 22.180414],
								[114.286530, 22.180695],
								[114.285141, 22.180695],
								[114.285141, 22.180973],
								[114.284584, 22.180973],
								[114.284584, 22.181250],
								[114.284302, 22.181250],
								[114.284302, 22.181526],
								[114.284027, 22.181526],
								[114.284027, 22.181805],
								[114.283752, 22.181805],
								[114.283752, 22.182083],
								[114.283470, 22.182083],
								[114.283470, 22.182362],
								[114.283752, 22.182362],
								[114.283752, 22.182636],
								[114.283470, 22.182636],
								[114.283470, 22.184584],
								[114.283195, 22.184584],
								[114.283195, 22.184858],
								[114.282913, 22.184858],
								[114.282913, 22.185141],
								[114.282661, 22.185141],
								[114.282639, 22.185694],
								[114.282913, 22.185694],
								[114.282913, 22.186251],
								[114.283195, 22.186251],
								[114.283195, 22.186527],
								[114.283752, 22.186527],
								[114.283752, 22.186806],
								[114.284279, 22.186806],
								[114.284302, 22.187084],
								[114.284859, 22.187084],
								[114.284859, 22.186806],
								[114.285141, 22.186806],
								[114.285141, 22.186527],
								[114.285698, 22.186527],
								[114.285698, 22.187639],
								[114.285973, 22.187639],
								[114.285973, 22.188749],
								[114.286247, 22.188749],
								[114.286247, 22.189306],
								[114.286530, 22.189306],
								[114.286530, 22.189583],
								[114.287361, 22.189583],
								[114.287361, 22.189861],
								[114.288193, 22.189861],
								[114.288193, 22.189583],
								[114.288475, 22.189583],
								[114.288475, 22.189306],
								[114.288750, 22.189306],
								[114.288750, 22.189030],
								[114.289307, 22.189028],
								[114.289307, 22.189306],
								[114.289581, 22.189306],
								[114.289581, 22.189861],
								[114.289856, 22.189861],
								[114.289864, 22.190140],
								[114.290413, 22.190140]
							]
						],
						[
							[
								[113.988197, 22.202362],
								[113.988197, 22.202084],
								[113.989586, 22.202084],
								[113.989586, 22.201529],
								[113.990417, 22.201529],
								[113.990417, 22.201250],
								[113.990692, 22.201250],
								[113.990692, 22.200972],
								[113.990974, 22.200972],
								[113.990974, 22.200138],
								[113.991249, 22.200138],
								[113.991249, 22.199862],
								[113.991806, 22.199862],
								[113.991806, 22.199583],
								[113.992081, 22.199583],
								[113.992081, 22.198748],
								[113.991806, 22.198748],
								[113.991806, 22.198473],
								[113.992081, 22.198473],
								[113.992081, 22.197918],
								[113.992363, 22.197918],
								[113.992363, 22.197639],
								[113.992638, 22.197639],
								[113.992638, 22.197083],
								[113.992363, 22.197083],
								[113.992363, 22.196808],
								[113.991806, 22.196808],
								[113.991806, 22.196529],
								[113.991531, 22.196529],
								[113.991531, 22.194860],
								[113.992638, 22.194860],
								[113.992638, 22.195415],
								[113.992920, 22.195415],
								[113.992920, 22.195694],
								[113.994026, 22.195694],
								[113.994026, 22.195415],
								[113.994583, 22.195415],
								[113.994583, 22.194027],
								[113.994308, 22.194027],
								[113.994308, 22.193750],
								[113.994583, 22.193750],
								[113.994583, 22.192638],
								[113.994308, 22.192638],
								[113.994308, 22.191805],
								[113.994583, 22.191805],
								[113.994583, 22.190416],
								[113.994308, 22.190416],
								[113.994308, 22.190140],
								[113.993752, 22.190140],
								[113.993752, 22.189861],
								[113.993469, 22.189861],
								[113.993469, 22.189583],
								[113.992081, 22.189583],
								[113.992081, 22.189306],
								[113.991531, 22.189306],
								[113.991531, 22.189028],
								[113.990974, 22.189028],
								[113.990974, 22.188749],
								[113.990692, 22.188749],
								[113.990692, 22.188473],
								[113.990417, 22.188473],
								[113.990417, 22.187639],
								[113.990135, 22.187639],
								[113.990135, 22.187916],
								[113.989861, 22.187916],
								[113.989861, 22.188194],
								[113.989586, 22.188194],
								[113.989586, 22.188749],
								[113.988747, 22.188749],
								[113.988747, 22.189028],
								[113.988197, 22.189028],
								[113.988197, 22.189507],
								[113.988197, 22.189583],
								[113.987915, 22.189583],
								[113.987915, 22.189695],
								[113.987915, 22.190140],
								[113.987473, 22.190140],
								[113.987358, 22.190140],
								[113.987358, 22.190252],
								[113.987358, 22.190971],
								[113.985420, 22.190971],
								[113.985420, 22.191250],
								[113.985138, 22.191250],
								[113.985138, 22.191528],
								[113.984863, 22.191528],
								[113.984863, 22.192083],
								[113.984306, 22.192083],
								[113.984306, 22.192362],
								[113.982918, 22.192362],
								[113.982918, 22.192917],
								[113.982635, 22.192917],
								[113.982635, 22.193195],
								[113.982361, 22.193195],
								[113.982361, 22.193750],
								[113.982086, 22.193750],
								[113.982086, 22.194305],
								[113.982208, 22.194305],
								[113.982361, 22.194305],
								[113.982361, 22.196529],
								[113.982635, 22.196529],
								[113.982635, 22.197918],
								[113.982918, 22.197918],
								[113.982918, 22.198473],
								[113.983475, 22.198473],
								[113.983475, 22.199030],
								[113.984024, 22.199030],
								[113.984024, 22.199583],
								[113.984581, 22.199583],
								[113.984581, 22.200138],
								[113.984863, 22.200138],
								[113.984863, 22.200695],
								[113.985695, 22.200695],
								[113.985695, 22.200972],
								[113.985970, 22.200972],
								[113.985970, 22.201250],
								[113.986252, 22.201250],
								[113.986252, 22.201529],
								[113.986809, 22.201529],
								[113.986809, 22.202084],
								[113.987083, 22.202084],
								[113.987083, 22.202362],
								[113.987457, 22.202362],
								[113.988197, 22.202362]
							]
						],
						[
							[
								[113.836525, 22.217916],
								[113.836525, 22.217638],
								[113.836807, 22.217638],
								[113.836807, 22.217361],
								[113.837357, 22.217361],
								[113.837364, 22.217638],
								[113.837639, 22.217638],
								[113.837639, 22.217916],
								[113.837898, 22.217916],
								[113.837914, 22.217640],
								[113.838470, 22.217638],
								[113.838470, 22.217085],
								[113.838753, 22.217085],
								[113.838753, 22.216539],
								[113.838470, 22.216528],
								[113.838470, 22.215694],
								[113.838196, 22.215694],
								[113.838196, 22.215139],
								[113.837914, 22.215139],
								[113.837914, 22.214863],
								[113.837639, 22.214863],
								[113.837639, 22.214582],
								[113.836807, 22.214582],
								[113.836807, 22.214306],
								[113.836250, 22.214306],
								[113.836250, 22.214582],
								[113.835976, 22.214582],
								[113.835976, 22.215973],
								[113.835419, 22.215973],
								[113.835419, 22.216249],
								[113.835136, 22.216249],
								[113.835136, 22.216528],
								[113.834587, 22.216528],
								[113.834587, 22.217638],
								[113.834862, 22.217638],
								[113.834862, 22.217916],
								[113.835419, 22.217916],
								[113.835419, 22.218193],
								[113.836250, 22.218193],
								[113.836250, 22.217920],
								[113.836525, 22.217916]
							]
						],
						[
							[
								[114.034859, 22.224030],
								[114.034859, 22.222637],
								[114.035141, 22.222637],
								[114.035141, 22.222361],
								[114.035416, 22.222361],
								[114.035416, 22.222086],
								[114.036247, 22.222086],
								[114.036247, 22.221251],
								[114.035973, 22.221251],
								[114.035973, 22.220694],
								[114.035698, 22.220694],
								[114.035698, 22.220139],
								[114.035416, 22.220139],
								[114.035416, 22.219862],
								[114.034859, 22.219862],
								[114.034859, 22.219584],
								[114.034309, 22.219584],
								[114.034302, 22.219303],
								[114.034027, 22.219303],
								[114.034027, 22.219028],
								[114.033470, 22.219028],
								[114.033470, 22.218750],
								[114.032913, 22.218750],
								[114.032913, 22.218472],
								[114.032639, 22.218472],
								[114.032639, 22.218193],
								[114.033897, 22.218193],
								[114.035973, 22.218193],
								[114.035973, 22.217916],
								[114.036247, 22.217916],
								[114.036247, 22.216806],
								[114.035698, 22.216806],
								[114.035698, 22.216528],
								[114.034859, 22.216528],
								[114.034859, 22.216249],
								[114.034027, 22.216249],
								[114.034027, 22.215973],
								[114.033752, 22.215973],
								[114.033752, 22.215694],
								[114.032913, 22.215694],
								[114.032913, 22.215416],
								[114.030975, 22.215416],
								[114.030975, 22.215139],
								[114.030693, 22.215139],
								[114.030693, 22.214863],
								[114.030418, 22.214863],
								[114.030418, 22.214582],
								[114.030136, 22.214582],
								[114.030136, 22.214306],
								[114.029587, 22.214306],
								[114.029587, 22.214027],
								[114.029305, 22.214027],
								[114.029305, 22.213697],
								[114.029305, 22.213194],
								[114.029587, 22.213194],
								[114.029587, 22.211529],
								[114.029861, 22.211529],
								[114.029861, 22.211250],
								[114.030136, 22.211250],
								[114.030136, 22.210419],
								[114.030418, 22.210419],
								[114.030418, 22.209862],
								[114.030693, 22.209862],
								[114.030693, 22.209583],
								[114.030975, 22.209583],
								[114.030975, 22.209305],
								[114.031250, 22.209305],
								[114.031250, 22.208750],
								[114.031525, 22.208750],
								[114.031525, 22.208471],
								[114.032082, 22.208471],
								[114.032082, 22.208195],
								[114.032364, 22.208195],
								[114.032364, 22.207914],
								[114.032913, 22.207914],
								[114.032913, 22.207640],
								[114.033470, 22.207640],
								[114.033470, 22.207361],
								[114.033752, 22.207361],
								[114.033752, 22.207083],
								[114.034859, 22.207083],
								[114.034859, 22.206804],
								[114.035416, 22.206804],
								[114.035416, 22.207083],
								[114.035698, 22.207083],
								[114.035698, 22.207361],
								[114.035973, 22.207361],
								[114.035973, 22.207640],
								[114.036247, 22.207640],
								[114.036247, 22.207914],
								[114.036530, 22.207914],
								[114.036530, 22.208195],
								[114.038193, 22.208195],
								[114.038193, 22.207914],
								[114.038475, 22.207914],
								[114.038475, 22.207361],
								[114.038750, 22.207361],
								[114.038750, 22.206804],
								[114.039024, 22.206804],
								[114.039024, 22.206528],
								[114.039307, 22.206528],
								[114.039307, 22.206249],
								[114.039581, 22.206249],
								[114.039581, 22.205973],
								[114.040138, 22.205973],
								[114.040138, 22.205694],
								[114.040413, 22.205694],
								[114.040413, 22.205416],
								[114.040138, 22.205416],
								[114.040138, 22.205139],
								[114.039864, 22.205139],
								[114.039864, 22.204861],
								[114.039581, 22.204861],
								[114.039581, 22.204582],
								[114.039024, 22.204582],
								[114.039024, 22.204403],
								[114.039024, 22.204027],
								[114.039307, 22.204027],
								[114.039307, 22.203472],
								[114.039024, 22.203472],
								[114.039024, 22.202917],
								[114.038750, 22.202917],
								[114.038750, 22.202639],
								[114.038193, 22.202639],
								[114.038193, 22.202362],
								[114.036804, 22.202360],
								[114.036804, 22.202084],
								[114.036530, 22.202084],
								[114.036530, 22.201805],
								[114.036247, 22.201805],
								[114.036247, 22.201250],
								[114.036530, 22.201250],
								[114.036530, 22.200972],
								[114.036247, 22.200972],
								[114.036247, 22.200138],
								[114.035973, 22.200138],
								[114.035973, 22.199862],
								[114.035416, 22.199862],
								[114.035416, 22.200138],
								[114.035141, 22.200138],
								[114.035141, 22.200417],
								[114.034859, 22.200417],
								[114.034859, 22.200972],
								[114.034584, 22.200972],
								[114.034584, 22.201529],
								[114.034302, 22.201529],
								[114.034302, 22.202084],
								[114.034027, 22.202084],
								[114.034027, 22.202360],
								[114.033752, 22.202362],
								[114.033752, 22.202639],
								[114.033470, 22.202639],
								[114.033470, 22.202917],
								[114.032639, 22.202917],
								[114.032639, 22.202639],
								[114.032364, 22.202639],
								[114.032364, 22.202362],
								[114.032082, 22.202360],
								[114.032082, 22.202084],
								[114.030418, 22.202084],
								[114.030418, 22.201805],
								[114.029861, 22.201805],
								[114.029861, 22.201529],
								[114.029587, 22.201529],
								[114.029587, 22.201250],
								[114.029305, 22.201250],
								[114.029305, 22.200972],
								[114.028748, 22.200972],
								[114.028748, 22.200695],
								[114.028473, 22.200695],
								[114.028473, 22.200417],
								[114.028198, 22.200417],
								[114.028198, 22.200138],
								[114.027916, 22.200138],
								[114.027916, 22.199030],
								[114.027359, 22.199030],
								[114.027359, 22.198748],
								[114.026802, 22.198748],
								[114.026802, 22.198473],
								[114.026527, 22.198473],
								[114.026527, 22.198195],
								[114.025970, 22.198195],
								[114.025970, 22.198473],
								[114.025414, 22.198473],
								[114.025414, 22.198195],
								[114.024864, 22.198195],
								[114.024864, 22.196529],
								[114.024307, 22.196529],
								[114.024307, 22.196808],
								[114.023750, 22.196808],
								[114.023750, 22.196529],
								[114.023476, 22.196529],
								[114.023476, 22.196251],
								[114.021530, 22.196251],
								[114.021530, 22.197359],
								[114.021248, 22.197359],
								[114.021248, 22.197466],
								[114.021248, 22.197639],
								[114.021133, 22.197639],
								[114.020691, 22.197639],
								[114.020691, 22.197359],
								[114.019585, 22.197359],
								[114.019585, 22.197083],
								[114.019028, 22.197083],
								[114.019028, 22.197359],
								[114.018471, 22.197359],
								[114.018471, 22.197639],
								[114.018196, 22.197639],
								[114.018196, 22.199583],
								[114.017914, 22.199583],
								[114.017914, 22.199862],
								[114.017639, 22.199862],
								[114.017639, 22.200138],
								[114.017365, 22.200138],
								[114.017365, 22.201250],
								[114.018097, 22.201250],
								[114.018196, 22.201250],
								[114.018196, 22.201529],
								[114.018471, 22.201529],
								[114.018471, 22.201805],
								[114.019028, 22.201805],
								[114.019028, 22.201626],
								[114.019028, 22.201529],
								[114.019455, 22.201529],
								[114.019585, 22.201529],
								[114.019585, 22.201387],
								[114.019585, 22.201250],
								[114.019722, 22.201250],
								[114.020416, 22.201250],
								[114.020416, 22.201529],
								[114.020538, 22.201529],
								[114.020973, 22.201529],
								[114.020973, 22.201805],
								[114.021248, 22.201805],
								[114.021248, 22.202084],
								[114.021805, 22.202084],
								[114.021805, 22.202639],
								[114.022087, 22.202639],
								[114.022087, 22.203194],
								[114.022362, 22.203194],
								[114.022362, 22.204582],
								[114.022636, 22.204582],
								[114.022636, 22.205139],
								[114.022919, 22.205139],
								[114.022919, 22.205416],
								[114.023193, 22.205418],
								[114.023193, 22.205694],
								[114.024796, 22.205694],
								[114.025696, 22.205694],
								[114.025696, 22.205824],
								[114.025696, 22.205973],
								[114.026527, 22.206017],
								[114.026527, 22.206249],
								[114.026802, 22.206249],
								[114.026802, 22.206528],
								[114.027084, 22.206528],
								[114.027084, 22.206804],
								[114.027359, 22.206804],
								[114.027359, 22.207083],
								[114.027641, 22.207083],
								[114.027641, 22.207361],
								[114.027916, 22.207361],
								[114.027916, 22.207914],
								[114.027359, 22.207914],
								[114.027359, 22.208471],
								[114.027084, 22.208471],
								[114.027084, 22.209026],
								[114.026802, 22.209026],
								[114.026802, 22.209862],
								[114.026527, 22.209862],
								[114.026527, 22.210136],
								[114.026253, 22.210136],
								[114.026253, 22.210695],
								[114.025696, 22.210695],
								[114.025696, 22.210972],
								[114.023750, 22.210972],
								[114.023750, 22.211250],
								[114.022919, 22.211250],
								[114.022919, 22.211529],
								[114.022636, 22.211529],
								[114.022636, 22.212917],
								[114.022362, 22.212917],
								[114.022362, 22.213194],
								[114.022087, 22.213194],
								[114.022087, 22.213604],
								[114.022087, 22.214027],
								[114.023193, 22.214027],
								[114.023193, 22.213751],
								[114.024025, 22.213751],
								[114.024025, 22.214027],
								[114.024307, 22.214027],
								[114.024307, 22.214306],
								[114.024864, 22.214306],
								[114.024864, 22.215416],
								[114.025139, 22.215416],
								[114.025139, 22.216249],
								[114.025414, 22.216249],
								[114.025414, 22.216528],
								[114.026253, 22.216528],
								[114.026253, 22.217085],
								[114.025970, 22.217085],
								[114.025970, 22.217916],
								[114.025696, 22.217916],
								[114.025696, 22.218193],
								[114.025414, 22.218193],
								[114.025414, 22.218472],
								[114.024582, 22.218472],
								[114.024582, 22.219028],
								[114.024864, 22.219028],
								[114.024864, 22.219584],
								[114.025139, 22.219584],
								[114.025139, 22.219862],
								[114.025246, 22.219862],
								[114.025414, 22.219862],
								[114.025414, 22.220415],
								[114.025696, 22.220415],
								[114.025696, 22.220694],
								[114.025970, 22.220694],
								[114.025970, 22.220972],
								[114.026527, 22.220972],
								[114.026527, 22.221525],
								[114.027359, 22.221525],
								[114.027359, 22.221806],
								[114.027641, 22.221806],
								[114.027641, 22.222361],
								[114.030273, 22.222361],
								[114.031525, 22.222361],
								[114.031525, 22.222086],
								[114.031807, 22.222086],
								[114.031807, 22.221806],
								[114.032082, 22.221806],
								[114.032082, 22.222086],
								[114.032364, 22.222086],
								[114.032364, 22.222361],
								[114.032639, 22.222361],
								[114.032639, 22.222637],
								[114.032913, 22.222637],
								[114.032913, 22.222971],
								[114.032913, 22.223194],
								[114.033470, 22.223194],
								[114.033470, 22.223473],
								[114.033752, 22.223473],
								[114.033752, 22.223747],
								[114.034027, 22.223747],
								[114.034027, 22.224030],
								[114.034859, 22.224030]
							]
						],
						[
							[
								[114.135971, 22.225973],
								[114.135971, 22.224583],
								[114.136253, 22.224583],
								[114.136253, 22.224030],
								[114.135971, 22.224030],
								[114.135971, 22.223747],
								[114.135414, 22.223747],
								[114.135414, 22.223473],
								[114.135139, 22.223473],
								[114.135139, 22.223194],
								[114.134308, 22.223194],
								[114.134308, 22.222919],
								[114.133469, 22.222919],
								[114.133469, 22.223194],
								[114.132637, 22.223194],
								[114.132637, 22.223473],
								[114.132362, 22.223473],
								[114.132362, 22.224030],
								[114.132607, 22.224030],
								[114.132637, 22.224583],
								[114.132919, 22.224583],
								[114.132919, 22.225140],
								[114.133186, 22.225140],
								[114.133194, 22.225416],
								[114.133469, 22.225416],
								[114.133469, 22.225695],
								[114.134026, 22.225695],
								[114.134026, 22.225973],
								[114.134308, 22.225973],
								[114.134308, 22.226252],
								[114.134857, 22.226252],
								[114.134865, 22.226528],
								[114.136253, 22.226528],
								[114.136253, 22.225973],
								[114.135971, 22.225973]
							]
						],
						[
							[
								[114.118187, 22.241249],
								[114.118195, 22.240973],
								[114.118469, 22.240973],
								[114.118469, 22.240417],
								[114.118752, 22.240417],
								[114.118752, 22.239584],
								[114.119301, 22.239584],
								[114.119308, 22.239305],
								[114.119446, 22.239305],
								[114.121529, 22.239305],
								[114.121529, 22.239031],
								[114.121803, 22.239029],
								[114.121803, 22.238470],
								[114.122055, 22.238470],
								[114.122086, 22.237919],
								[114.122360, 22.237919],
								[114.122360, 22.237652],
								[114.122917, 22.237640],
								[114.122917, 22.236805],
								[114.123192, 22.236805],
								[114.123192, 22.235416],
								[114.122643, 22.235416],
								[114.122643, 22.234028],
								[114.122360, 22.234028],
								[114.122360, 22.233749],
								[114.121529, 22.233749],
								[114.121529, 22.233473],
								[114.120415, 22.233473],
								[114.120415, 22.233194],
								[114.120140, 22.233194],
								[114.120140, 22.231806],
								[114.120407, 22.231806],
								[114.120415, 22.230421],
								[114.120697, 22.230417],
								[114.120697, 22.229305],
								[114.120956, 22.229305],
								[114.120972, 22.228479],
								[114.121529, 22.228472],
								[114.121529, 22.228203],
								[114.122086, 22.228195],
								[114.122086, 22.227917],
								[114.122917, 22.227917],
								[114.122917, 22.227638],
								[114.123466, 22.227638],
								[114.123474, 22.227362],
								[114.123749, 22.227362],
								[114.123749, 22.227083],
								[114.124306, 22.227083],
								[114.124306, 22.226805],
								[114.124580, 22.226805],
								[114.124580, 22.226528],
								[114.124863, 22.226528],
								[114.124863, 22.226252],
								[114.125420, 22.226252],
								[114.125420, 22.225973],
								[114.125641, 22.225973],
								[114.125694, 22.223473],
								[114.125420, 22.223473],
								[114.125420, 22.223194],
								[114.125137, 22.223194],
								[114.125137, 22.222919],
								[114.125969, 22.222919],
								[114.125969, 22.222637],
								[114.126251, 22.222637],
								[114.126251, 22.222086],
								[114.126518, 22.222086],
								[114.126526, 22.221806],
								[114.126808, 22.221806],
								[114.126808, 22.221525],
								[114.127083, 22.221525],
								[114.127083, 22.221251],
								[114.127357, 22.221251],
								[114.127357, 22.220972],
								[114.127609, 22.220972],
								[114.127640, 22.220415],
								[114.127907, 22.220415],
								[114.127914, 22.220140],
								[114.128197, 22.220139],
								[114.128197, 22.219862],
								[114.128456, 22.219862],
								[114.128471, 22.219584],
								[114.129585, 22.219584],
								[114.129585, 22.219303],
								[114.130692, 22.219303],
								[114.130692, 22.219030],
								[114.131531, 22.219028],
								[114.131531, 22.220139],
								[114.131805, 22.220139],
								[114.131805, 22.220415],
								[114.132362, 22.220415],
								[114.132362, 22.220694],
								[114.132919, 22.220694],
								[114.132919, 22.220972],
								[114.133469, 22.220972],
								[114.133469, 22.221251],
								[114.134583, 22.221251],
								[114.134583, 22.220972],
								[114.135078, 22.220972],
								[114.135139, 22.220694],
								[114.135414, 22.220694],
								[114.135414, 22.220415],
								[114.135696, 22.220415],
								[114.135696, 22.220140],
								[114.135956, 22.220139],
								[114.135971, 22.219862],
								[114.136238, 22.219862],
								[114.136253, 22.219584],
								[114.136528, 22.219584],
								[114.136528, 22.219303],
								[114.136803, 22.219303],
								[114.136803, 22.219030],
								[114.137077, 22.219028],
								[114.137085, 22.217916],
								[114.136803, 22.217916],
								[114.136803, 22.216249],
								[114.136528, 22.216249],
								[114.136528, 22.215973],
								[114.135414, 22.215973],
								[114.135414, 22.216528],
								[114.134865, 22.216528],
								[114.134865, 22.215973],
								[114.135117, 22.215973],
								[114.135139, 22.215694],
								[114.135414, 22.215694],
								[114.135414, 22.215416],
								[114.135696, 22.215416],
								[114.135696, 22.214027],
								[114.134583, 22.214027],
								[114.134583, 22.213472],
								[114.131805, 22.213472],
								[114.131805, 22.213194],
								[114.131531, 22.213194],
								[114.131531, 22.212917],
								[114.131248, 22.212917],
								[114.131248, 22.212641],
								[114.130692, 22.212641],
								[114.130692, 22.212358],
								[114.130417, 22.212358],
								[114.130417, 22.212084],
								[114.130142, 22.212084],
								[114.130142, 22.211805],
								[114.129860, 22.211805],
								[114.129860, 22.211531],
								[114.129593, 22.211529],
								[114.129585, 22.211250],
								[114.129303, 22.211250],
								[114.129303, 22.210972],
								[114.128754, 22.210972],
								[114.128754, 22.210695],
								[114.128471, 22.210695],
								[114.128471, 22.210419],
								[114.128197, 22.210419],
								[114.128197, 22.210136],
								[114.127914, 22.210136],
								[114.127914, 22.209583],
								[114.127640, 22.209583],
								[114.127640, 22.209305],
								[114.127357, 22.209305],
								[114.127357, 22.208750],
								[114.127083, 22.208750],
								[114.127083, 22.207914],
								[114.126808, 22.207914],
								[114.126808, 22.206810],
								[114.126526, 22.206804],
								[114.126526, 22.205973],
								[114.126808, 22.205973],
								[114.126808, 22.205694],
								[114.127083, 22.205694],
								[114.127083, 22.205139],
								[114.127357, 22.205139],
								[114.127357, 22.204861],
								[114.127640, 22.204861],
								[114.127640, 22.204582],
								[114.127899, 22.204582],
								[114.127914, 22.204306],
								[114.128197, 22.204306],
								[114.128197, 22.204027],
								[114.128471, 22.204027],
								[114.128471, 22.203751],
								[114.128754, 22.203751],
								[114.128754, 22.203472],
								[114.128983, 22.203472],
								[114.129028, 22.203194],
								[114.129303, 22.203194],
								[114.129303, 22.202917],
								[114.130142, 22.202917],
								[114.130142, 22.203472],
								[114.130417, 22.203472],
								[114.130417, 22.205973],
								[114.130966, 22.205973],
								[114.130974, 22.206249],
								[114.131531, 22.206249],
								[114.131531, 22.206528],
								[114.132080, 22.206528],
								[114.132080, 22.206804],
								[114.132637, 22.206804],
								[114.132637, 22.207083],
								[114.132919, 22.207083],
								[114.132919, 22.207361],
								[114.133186, 22.207361],
								[114.133194, 22.207640],
								[114.133469, 22.207640],
								[114.133469, 22.207914],
								[114.133751, 22.207914],
								[114.133751, 22.208195],
								[114.134865, 22.208195],
								[114.134865, 22.208471],
								[114.135139, 22.208471],
								[114.135139, 22.208750],
								[114.135406, 22.208750],
								[114.135414, 22.209026],
								[114.137642, 22.209026],
								[114.137642, 22.209305],
								[114.140694, 22.209305],
								[114.140694, 22.209026],
								[114.141525, 22.209026],
								[114.141525, 22.208750],
								[114.142365, 22.208750],
								[114.142365, 22.208471],
								[114.142960, 22.208471],
								[114.143196, 22.208471],
								[114.143196, 22.208195],
								[114.144028, 22.208195],
								[114.144028, 22.207914],
								[114.144577, 22.207914],
								[114.144585, 22.207640],
								[114.145416, 22.207640],
								[114.145416, 22.209026],
								[114.145973, 22.209026],
								[114.145973, 22.209305],
								[114.146530, 22.209305],
								[114.146530, 22.210695],
								[114.146805, 22.210695],
								[114.146805, 22.211250],
								[114.148193, 22.211250],
								[114.148193, 22.210972],
								[114.148750, 22.210972],
								[114.148750, 22.211805],
								[114.149582, 22.211805],
								[114.149582, 22.212084],
								[114.150406, 22.212084],
								[114.150414, 22.212358],
								[114.151802, 22.212358],
								[114.151802, 22.211805],
								[114.152077, 22.211805],
								[114.152084, 22.211250],
								[114.152359, 22.211250],
								[114.152359, 22.210695],
								[114.152641, 22.210695],
								[114.152641, 22.210140],
								[114.152916, 22.210136],
								[114.152916, 22.209862],
								[114.155136, 22.209862],
								[114.155136, 22.210136],
								[114.158745, 22.210136],
								[114.159027, 22.210136],
								[114.159027, 22.209929],
								[114.159027, 22.209583],
								[114.159271, 22.209583],
								[114.159309, 22.209305],
								[114.159584, 22.209305],
								[114.159584, 22.209026],
								[114.159859, 22.209026],
								[114.159859, 22.208471],
								[114.160065, 22.208471],
								[114.160141, 22.208471],
								[114.160141, 22.208195],
								[114.160416, 22.208195],
								[114.160416, 22.207640],
								[114.160141, 22.207640],
								[114.160141, 22.206804],
								[114.159859, 22.206804],
								[114.159859, 22.206251],
								[114.159309, 22.206249],
								[114.159302, 22.205973],
								[114.159027, 22.205973],
								[114.159027, 22.205694],
								[114.158470, 22.205694],
								[114.158470, 22.205139],
								[114.158195, 22.205139],
								[114.158195, 22.204582],
								[114.157913, 22.204582],
								[114.157913, 22.204027],
								[114.157364, 22.204027],
								[114.157364, 22.203751],
								[114.156250, 22.203751],
								[114.156250, 22.203472],
								[114.154305, 22.203472],
								[114.154305, 22.203751],
								[114.154030, 22.203751],
								[114.154030, 22.204027],
								[114.153748, 22.204027],
								[114.153748, 22.204306],
								[114.153488, 22.204306],
								[114.153473, 22.204582],
								[114.153198, 22.204582],
								[114.153191, 22.204861],
								[114.152916, 22.204861],
								[114.152916, 22.205416],
								[114.152641, 22.205416],
								[114.152641, 22.205694],
								[114.152359, 22.205694],
								[114.152359, 22.205973],
								[114.151802, 22.205973],
								[114.151802, 22.206249],
								[114.150970, 22.206249],
								[114.150970, 22.205973],
								[114.150696, 22.205973],
								[114.150696, 22.205694],
								[114.150414, 22.205694],
								[114.150414, 22.205416],
								[114.150139, 22.205416],
								[114.150139, 22.205141],
								[114.148750, 22.205139],
								[114.148750, 22.204861],
								[114.149025, 22.204861],
								[114.149025, 22.204582],
								[114.149307, 22.204582],
								[114.149307, 22.203472],
								[114.149025, 22.203472],
								[114.149025, 22.203194],
								[114.147636, 22.203194],
								[114.147636, 22.202639],
								[114.147362, 22.202639],
								[114.147362, 22.202362],
								[114.147118, 22.202360],
								[114.147087, 22.202084],
								[114.146530, 22.202084],
								[114.146530, 22.202360],
								[114.145973, 22.202360],
								[114.145973, 22.201529],
								[114.145691, 22.201529],
								[114.145691, 22.200417],
								[114.145973, 22.200417],
								[114.145973, 22.199862],
								[114.145691, 22.199862],
								[114.145691, 22.199306],
								[114.144859, 22.199305],
								[114.144859, 22.199030],
								[114.144028, 22.199030],
								[114.144028, 22.198748],
								[114.143753, 22.198748],
								[114.143753, 22.198195],
								[114.143471, 22.198195],
								[114.143471, 22.197918],
								[114.142914, 22.197918],
								[114.142914, 22.198195],
								[114.142639, 22.198195],
								[114.142639, 22.198473],
								[114.140137, 22.198473],
								[114.140137, 22.197359],
								[114.139862, 22.197359],
								[114.139862, 22.195694],
								[114.140129, 22.195694],
								[114.140137, 22.195415],
								[114.140694, 22.195415],
								[114.140694, 22.195694],
								[114.141251, 22.195694],
								[114.141251, 22.195972],
								[114.142365, 22.195972],
								[114.142365, 22.195694],
								[114.142509, 22.195694],
								[114.142639, 22.195694],
								[114.142639, 22.195358],
								[114.142639, 22.195139],
								[114.142784, 22.195139],
								[114.142914, 22.195139],
								[114.142914, 22.194956],
								[114.142914, 22.194860],
								[114.143196, 22.194860],
								[114.143196, 22.194523],
								[114.143196, 22.193472],
								[114.143456, 22.193472],
								[114.143471, 22.192083],
								[114.143753, 22.192083],
								[114.143753, 22.191528],
								[114.144028, 22.191528],
								[114.144028, 22.190971],
								[114.144302, 22.190971],
								[114.144302, 22.190695],
								[114.144585, 22.190695],
								[114.144585, 22.190140],
								[114.144859, 22.190140],
								[114.144859, 22.189583],
								[114.145142, 22.189583],
								[114.145142, 22.189306],
								[114.145416, 22.189306],
								[114.145416, 22.188751],
								[114.145691, 22.188749],
								[114.145691, 22.188473],
								[114.145973, 22.188473],
								[114.145973, 22.188194],
								[114.146248, 22.188194],
								[114.146248, 22.187639],
								[114.146530, 22.187639],
								[114.146530, 22.187363],
								[114.146805, 22.187363],
								[114.146805, 22.187084],
								[114.147064, 22.187084],
								[114.147087, 22.186527],
								[114.147362, 22.186527],
								[114.147362, 22.185972],
								[114.147575, 22.185972],
								[114.147636, 22.185417],
								[114.147919, 22.185417],
								[114.147919, 22.184584],
								[114.148048, 22.184584],
								[114.148193, 22.184584],
								[114.148193, 22.184406],
								[114.148193, 22.184029],
								[114.148476, 22.184029],
								[114.148476, 22.183195],
								[114.148712, 22.183195],
								[114.148720, 22.183046],
								[114.148750, 22.182636],
								[114.149025, 22.182636],
								[114.149025, 22.181250],
								[114.148750, 22.181250],
								[114.148750, 22.180973],
								[114.148193, 22.180973],
								[114.148193, 22.181250],
								[114.147919, 22.181250],
								[114.147919, 22.181526],
								[114.147636, 22.181526],
								[114.147636, 22.182636],
								[114.147362, 22.182636],
								[114.147362, 22.182919],
								[114.146530, 22.182919],
								[114.146530, 22.183195],
								[114.145973, 22.183195],
								[114.145973, 22.183748],
								[114.145142, 22.183748],
								[114.145142, 22.183472],
								[114.144302, 22.183472],
								[114.144302, 22.183748],
								[114.144028, 22.183748],
								[114.144028, 22.184029],
								[114.143753, 22.184029],
								[114.143753, 22.184305],
								[114.143471, 22.184305],
								[114.143471, 22.184584],
								[114.143196, 22.184584],
								[114.143196, 22.184858],
								[114.142914, 22.184858],
								[114.142914, 22.185417],
								[114.142639, 22.185417],
								[114.142639, 22.185694],
								[114.142365, 22.185694],
								[114.142365, 22.185972],
								[114.142082, 22.185972],
								[114.142082, 22.186251],
								[114.141251, 22.186251],
								[114.141251, 22.186527],
								[114.140694, 22.186527],
								[114.140694, 22.187084],
								[114.140419, 22.187084],
								[114.140419, 22.187363],
								[114.140137, 22.187363],
								[114.140137, 22.187639],
								[114.139862, 22.187639],
								[114.139862, 22.187916],
								[114.139305, 22.187916],
								[114.139305, 22.188194],
								[114.138748, 22.188194],
								[114.138748, 22.188473],
								[114.138191, 22.188473],
								[114.138191, 22.188749],
								[114.137917, 22.188749],
								[114.137917, 22.189028],
								[114.137642, 22.189028],
								[114.137642, 22.189306],
								[114.137360, 22.189306],
								[114.137360, 22.189583],
								[114.137085, 22.189583],
								[114.137085, 22.191250],
								[114.136803, 22.191250],
								[114.136803, 22.190971],
								[114.136528, 22.190971],
								[114.136528, 22.190695],
								[114.135971, 22.190695],
								[114.135971, 22.190416],
								[114.135696, 22.190416],
								[114.135696, 22.190140],
								[114.135414, 22.190140],
								[114.135414, 22.189861],
								[114.134865, 22.189861],
								[114.134865, 22.189583],
								[114.134583, 22.189583],
								[114.134583, 22.188473],
								[114.134865, 22.188473],
								[114.134865, 22.187363],
								[114.135139, 22.187363],
								[114.135139, 22.186810],
								[114.135406, 22.186806],
								[114.135414, 22.186251],
								[114.135696, 22.186251],
								[114.135696, 22.185694],
								[114.135971, 22.185694],
								[114.135971, 22.185141],
								[114.136253, 22.185141],
								[114.136253, 22.184858],
								[114.136528, 22.184858],
								[114.136528, 22.184305],
								[114.136803, 22.184305],
								[114.136803, 22.183748],
								[114.137077, 22.183748],
								[114.137085, 22.183201],
								[114.137360, 22.183195],
								[114.137360, 22.182640],
								[114.137642, 22.182636],
								[114.137642, 22.178473],
								[114.137360, 22.178473],
								[114.137360, 22.177639],
								[114.136253, 22.177639],
								[114.136253, 22.177917],
								[114.135818, 22.177917],
								[114.135696, 22.177917],
								[114.135696, 22.178192],
								[114.135414, 22.178192],
								[114.135414, 22.178473],
								[114.134865, 22.178473],
								[114.134865, 22.178749],
								[114.134583, 22.178749],
								[114.134583, 22.179028],
								[114.134354, 22.179028],
								[114.134308, 22.179583],
								[114.134026, 22.179583],
								[114.134026, 22.179861],
								[114.133751, 22.179861],
								[114.133751, 22.180140],
								[114.133469, 22.180140],
								[114.133469, 22.180695],
								[114.133194, 22.180695],
								[114.133194, 22.181250],
								[114.132919, 22.181250],
								[114.132919, 22.181805],
								[114.132637, 22.181805],
								[114.132637, 22.182362],
								[114.132362, 22.182362],
								[114.132362, 22.182919],
								[114.132080, 22.182919],
								[114.132080, 22.183195],
								[114.131805, 22.183195],
								[114.131805, 22.182919],
								[114.131531, 22.182919],
								[114.131531, 22.182640],
								[114.131248, 22.182636],
								[114.131248, 22.182362],
								[114.130974, 22.182362],
								[114.130974, 22.182083],
								[114.130692, 22.182083],
								[114.130692, 22.181805],
								[114.130417, 22.181805],
								[114.130417, 22.181250],
								[114.130142, 22.181250],
								[114.130142, 22.180973],
								[114.129860, 22.180973],
								[114.129860, 22.180695],
								[114.129585, 22.180695],
								[114.129585, 22.180414],
								[114.129303, 22.180414],
								[114.129303, 22.180140],
								[114.129028, 22.180140],
								[114.129028, 22.179861],
								[114.128471, 22.179861],
								[114.128471, 22.179583],
								[114.128197, 22.179583],
								[114.128197, 22.178749],
								[114.128471, 22.178749],
								[114.128471, 22.178473],
								[114.128197, 22.178473],
								[114.128197, 22.178192],
								[114.127640, 22.178192],
								[114.127640, 22.177917],
								[114.127457, 22.177917],
								[114.126808, 22.177917],
								[114.126808, 22.177641],
								[114.125969, 22.177639],
								[114.125969, 22.177370],
								[114.125420, 22.177361],
								[114.125420, 22.177639],
								[114.125137, 22.177639],
								[114.125137, 22.177917],
								[114.124863, 22.177917],
								[114.124863, 22.179304],
								[114.124580, 22.179304],
								[114.124580, 22.179861],
								[114.124031, 22.179861],
								[114.124031, 22.180140],
								[114.123749, 22.180140],
								[114.123749, 22.180414],
								[114.123474, 22.180414],
								[114.123474, 22.180695],
								[114.123192, 22.180695],
								[114.123192, 22.180973],
								[114.122917, 22.180973],
								[114.122917, 22.181250],
								[114.122360, 22.181250],
								[114.122360, 22.180973],
								[114.122086, 22.180973],
								[114.122086, 22.180140],
								[114.121803, 22.180140],
								[114.121803, 22.179583],
								[114.121246, 22.179583],
								[114.121246, 22.179304],
								[114.119583, 22.179304],
								[114.119583, 22.179583],
								[114.118469, 22.179583],
								[114.118469, 22.179861],
								[114.118195, 22.179861],
								[114.118195, 22.180140],
								[114.117920, 22.180140],
								[114.117920, 22.180414],
								[114.117638, 22.180414],
								[114.117638, 22.180695],
								[114.117081, 22.180695],
								[114.117081, 22.180973],
								[114.116928, 22.180973],
								[114.115974, 22.180973],
								[114.115974, 22.181250],
								[114.115417, 22.181250],
								[114.115417, 22.181526],
								[114.115135, 22.181526],
								[114.115135, 22.181805],
								[114.114586, 22.181805],
								[114.114586, 22.182362],
								[114.114304, 22.182362],
								[114.114304, 22.182919],
								[114.114029, 22.182919],
								[114.114029, 22.184029],
								[114.113747, 22.184029],
								[114.113747, 22.185141],
								[114.114006, 22.185141],
								[114.114029, 22.186251],
								[114.114304, 22.186251],
								[114.114304, 22.187084],
								[114.114586, 22.187084],
								[114.114586, 22.187363],
								[114.114861, 22.187363],
								[114.114861, 22.187639],
								[114.115135, 22.187639],
								[114.115135, 22.188194],
								[114.115692, 22.188194],
								[114.115692, 22.188473],
								[114.116531, 22.188473],
								[114.116531, 22.188194],
								[114.116806, 22.188194],
								[114.116806, 22.187916],
								[114.117081, 22.187916],
								[114.117081, 22.188473],
								[114.117363, 22.188473],
								[114.117363, 22.188749],
								[114.117638, 22.188749],
								[114.117638, 22.189306],
								[114.117920, 22.189306],
								[114.117920, 22.189583],
								[114.118195, 22.189583],
								[114.118195, 22.189861],
								[114.118469, 22.189861],
								[114.118469, 22.190140],
								[114.119026, 22.190140],
								[114.119026, 22.190416],
								[114.120140, 22.190416],
								[114.120140, 22.191250],
								[114.119858, 22.191250],
								[114.119858, 22.192362],
								[114.120140, 22.192362],
								[114.120140, 22.192638],
								[114.120415, 22.192638],
								[114.120415, 22.194860],
								[114.120140, 22.194860],
								[114.120140, 22.195139],
								[114.120415, 22.195139],
								[114.120415, 22.196251],
								[114.120697, 22.196251],
								[114.120697, 22.197083],
								[114.120956, 22.197083],
								[114.120972, 22.197359],
								[114.121529, 22.197359],
								[114.121529, 22.201805],
								[114.121803, 22.201805],
								[114.121803, 22.202917],
								[114.121529, 22.202917],
								[114.121529, 22.203194],
								[114.121246, 22.203194],
								[114.121246, 22.203472],
								[114.121422, 22.203472],
								[114.122360, 22.203472],
								[114.122360, 22.203751],
								[114.122643, 22.203751],
								[114.122643, 22.204582],
								[114.122360, 22.204582],
								[114.122360, 22.204861],
								[114.121803, 22.204861],
								[114.121803, 22.205139],
								[114.120972, 22.205139],
								[114.120972, 22.205416],
								[114.120422, 22.205416],
								[114.120415, 22.205694],
								[114.120140, 22.205694],
								[114.120140, 22.205973],
								[114.119858, 22.205973],
								[114.119858, 22.206528],
								[114.119614, 22.206528],
								[114.119583, 22.207361],
								[114.119858, 22.207361],
								[114.119858, 22.207640],
								[114.120407, 22.207640],
								[114.120415, 22.207914],
								[114.121246, 22.207914],
								[114.121246, 22.209305],
								[114.120972, 22.209305],
								[114.120972, 22.210136],
								[114.121246, 22.210136],
								[114.121246, 22.210419],
								[114.121529, 22.210419],
								[114.121529, 22.210695],
								[114.121803, 22.210695],
								[114.121803, 22.211529],
								[114.121529, 22.211529],
								[114.121529, 22.211805],
								[114.121246, 22.211805],
								[114.121246, 22.212358],
								[114.120697, 22.212358],
								[114.120697, 22.212917],
								[114.120415, 22.212917],
								[114.120415, 22.213194],
								[114.120697, 22.213194],
								[114.120697, 22.213472],
								[114.120972, 22.213472],
								[114.120972, 22.214306],
								[114.120697, 22.214306],
								[114.120697, 22.214863],
								[114.120415, 22.214863],
								[114.120415, 22.215139],
								[114.120140, 22.215139],
								[114.120140, 22.217085],
								[114.119583, 22.217085],
								[114.119583, 22.217361],
								[114.119858, 22.217361],
								[114.119858, 22.218472],
								[114.119308, 22.218472],
								[114.119308, 22.218750],
								[114.118469, 22.218750],
								[114.118469, 22.219028],
								[114.117363, 22.219028],
								[114.117363, 22.219303],
								[114.117081, 22.219303],
								[114.117081, 22.219584],
								[114.116806, 22.219584],
								[114.116806, 22.219862],
								[114.116249, 22.219862],
								[114.116249, 22.220139],
								[114.113747, 22.220139],
								[114.113747, 22.218193],
								[114.113472, 22.218193],
								[114.113472, 22.217361],
								[114.113197, 22.217361],
								[114.113197, 22.216528],
								[114.112915, 22.216528],
								[114.112915, 22.216249],
								[114.103470, 22.216249],
								[114.103470, 22.215973],
								[114.102951, 22.215973],
								[114.102913, 22.216249],
								[114.102638, 22.216249],
								[114.102638, 22.218472],
								[114.102913, 22.218472],
								[114.102913, 22.219028],
								[114.103142, 22.219028],
								[114.103195, 22.219584],
								[114.103470, 22.219584],
								[114.103470, 22.219862],
								[114.103752, 22.219862],
								[114.103752, 22.220139],
								[114.104309, 22.220139],
								[114.104309, 22.221251],
								[114.104576, 22.221251],
								[114.104584, 22.221525],
								[114.104858, 22.221525],
								[114.104858, 22.221806],
								[114.105118, 22.221806],
								[114.105141, 22.222086],
								[114.105415, 22.222086],
								[114.105415, 22.222361],
								[114.105698, 22.222361],
								[114.105698, 22.222637],
								[114.105972, 22.222637],
								[114.105972, 22.222919],
								[114.106224, 22.222919],
								[114.106247, 22.223194],
								[114.106796, 22.223194],
								[114.106804, 22.223747],
								[114.107086, 22.223747],
								[114.107086, 22.224030],
								[114.107361, 22.224030],
								[114.107361, 22.224306],
								[114.107918, 22.224306],
								[114.107918, 22.224583],
								[114.108192, 22.224583],
								[114.108192, 22.224859],
								[114.108749, 22.224859],
								[114.108749, 22.225140],
								[114.109306, 22.225140],
								[114.109306, 22.225416],
								[114.109863, 22.225416],
								[114.109863, 22.225695],
								[114.110687, 22.225695],
								[114.110695, 22.226252],
								[114.110420, 22.226252],
								[114.110420, 22.226528],
								[114.108749, 22.226528],
								[114.108749, 22.227638],
								[114.108475, 22.227638],
								[114.108475, 22.228472],
								[114.108749, 22.228472],
								[114.108749, 22.228750],
								[114.109024, 22.228750],
								[114.109024, 22.229029],
								[114.109306, 22.229029],
								[114.109306, 22.229305],
								[114.109863, 22.229305],
								[114.109863, 22.229584],
								[114.110138, 22.229584],
								[114.110077, 22.230972],
								[114.109863, 22.230972],
								[114.109863, 22.231251],
								[114.109581, 22.231251],
								[114.109581, 22.231527],
								[114.108749, 22.231527],
								[114.108749, 22.231806],
								[114.107925, 22.231806],
								[114.107918, 22.232082],
								[114.107086, 22.232084],
								[114.107086, 22.232361],
								[114.106529, 22.232361],
								[114.106529, 22.232639],
								[114.106247, 22.232639],
								[114.106247, 22.232916],
								[114.105972, 22.232916],
								[114.105972, 22.233194],
								[114.105698, 22.233194],
								[114.105698, 22.234028],
								[114.105972, 22.234028],
								[114.105972, 22.234304],
								[114.106529, 22.234304],
								[114.106529, 22.234583],
								[114.107361, 22.234583],
								[114.107361, 22.234861],
								[114.107635, 22.234861],
								[114.107635, 22.235138],
								[114.108749, 22.235140],
								[114.108749, 22.235416],
								[114.109261, 22.235416],
								[114.109306, 22.235697],
								[114.109581, 22.235697],
								[114.109581, 22.236526],
								[114.110001, 22.236526],
								[114.110138, 22.236526],
								[114.110138, 22.236805],
								[114.111526, 22.236805],
								[114.111526, 22.237083],
								[114.112358, 22.237083],
								[114.112358, 22.237362],
								[114.112640, 22.237362],
								[114.112640, 22.237640],
								[114.113197, 22.237640],
								[114.113197, 22.237919],
								[114.114304, 22.237919],
								[114.114304, 22.238190],
								[114.114861, 22.238195],
								[114.114861, 22.238470],
								[114.115021, 22.238470],
								[114.116531, 22.238470],
								[114.116531, 22.238750],
								[114.116806, 22.238750],
								[114.116806, 22.239305],
								[114.117081, 22.239305],
								[114.117081, 22.240417],
								[114.117325, 22.240417],
								[114.117363, 22.240973],
								[114.117638, 22.240973],
								[114.117638, 22.241249],
								[114.118187, 22.241249]
							]
						],
						[
							[
								[114.029861, 22.259027],
								[114.029861, 22.258192],
								[114.030663, 22.258192],
								[114.030693, 22.258472],
								[114.032639, 22.258472],
								[114.032639, 22.258751],
								[114.033195, 22.258751],
								[114.033195, 22.258474],
								[114.036530, 22.258472],
								[114.036530, 22.258192],
								[114.038193, 22.258192],
								[114.038193, 22.257917],
								[114.039864, 22.257917],
								[114.039864, 22.258192],
								[114.040413, 22.258192],
								[114.040413, 22.258472],
								[114.041809, 22.258472],
								[114.041809, 22.258192],
								[114.042915, 22.258192],
								[114.042915, 22.257917],
								[114.043747, 22.257917],
								[114.043747, 22.257639],
								[114.044029, 22.257639],
								[114.044029, 22.257084],
								[114.044304, 22.257084],
								[114.044304, 22.256805],
								[114.044586, 22.256805],
								[114.044586, 22.256527],
								[114.044861, 22.256527],
								[114.044861, 22.255974],
								[114.045120, 22.255974],
								[114.045135, 22.255695],
								[114.045418, 22.255695],
								[114.045418, 22.254862],
								[114.045135, 22.254862],
								[114.045135, 22.253469],
								[114.044861, 22.253469],
								[114.044861, 22.252361],
								[114.044586, 22.252361],
								[114.044586, 22.251530],
								[114.044304, 22.251530],
								[114.044304, 22.250694],
								[114.044029, 22.250694],
								[114.044029, 22.250137],
								[114.044296, 22.250137],
								[114.044304, 22.249861],
								[114.044586, 22.249861],
								[114.044586, 22.249584],
								[114.044861, 22.249584],
								[114.044861, 22.249308],
								[114.045135, 22.249308],
								[114.045135, 22.249025],
								[114.045418, 22.249025],
								[114.045418, 22.248751],
								[114.045692, 22.248751],
								[114.045692, 22.248194],
								[114.045975, 22.248194],
								[114.045975, 22.247639],
								[114.046249, 22.247639],
								[114.046249, 22.247084],
								[114.046524, 22.247084],
								[114.046524, 22.246529],
								[114.046806, 22.246529],
								[114.046806, 22.245972],
								[114.047081, 22.245972],
								[114.047081, 22.244862],
								[114.046806, 22.244862],
								[114.046806, 22.243750],
								[114.046524, 22.243750],
								[114.046524, 22.242916],
								[114.046249, 22.242916],
								[114.046249, 22.242640],
								[114.045975, 22.242640],
								[114.045975, 22.242361],
								[114.045692, 22.242361],
								[114.045692, 22.242083],
								[114.045418, 22.242083],
								[114.045418, 22.241806],
								[114.045135, 22.241806],
								[114.045135, 22.241528],
								[114.044586, 22.241528],
								[114.044586, 22.241253],
								[114.044029, 22.241249],
								[114.044029, 22.240973],
								[114.043472, 22.240973],
								[114.043472, 22.241249],
								[114.042915, 22.241249],
								[114.042915, 22.241528],
								[114.042358, 22.241528],
								[114.042358, 22.241806],
								[114.042084, 22.241806],
								[114.042084, 22.242083],
								[114.041252, 22.242083],
								[114.041252, 22.242361],
								[114.040970, 22.242361],
								[114.040970, 22.242640],
								[114.040703, 22.242640],
								[114.040695, 22.242916],
								[114.040413, 22.242916],
								[114.040413, 22.243195],
								[114.040138, 22.243195],
								[114.040138, 22.243471],
								[114.039871, 22.243471],
								[114.039864, 22.245138],
								[114.039581, 22.245138],
								[114.039581, 22.245417],
								[114.039322, 22.245417],
								[114.039307, 22.245972],
								[114.039024, 22.245972],
								[114.039024, 22.246250],
								[114.038750, 22.246250],
								[114.038750, 22.246805],
								[114.038475, 22.246805],
								[114.038475, 22.247084],
								[114.038193, 22.247084],
								[114.038193, 22.249025],
								[114.037918, 22.249025],
								[114.037918, 22.249308],
								[114.038193, 22.249308],
								[114.038193, 22.249584],
								[114.037636, 22.249584],
								[114.037636, 22.249861],
								[114.035423, 22.249861],
								[114.035416, 22.250137],
								[114.035141, 22.250137],
								[114.035141, 22.250416],
								[114.034584, 22.250416],
								[114.034584, 22.251806],
								[114.034302, 22.251806],
								[114.034302, 22.252083],
								[114.033752, 22.252083],
								[114.033752, 22.252642],
								[114.033470, 22.252642],
								[114.033470, 22.253195],
								[114.033195, 22.253195],
								[114.033195, 22.253469],
								[114.032913, 22.253469],
								[114.032913, 22.253752],
								[114.031807, 22.253752],
								[114.031807, 22.254028],
								[114.030418, 22.254028],
								[114.030418, 22.253752],
								[114.029861, 22.253752],
								[114.029861, 22.254028],
								[114.029305, 22.254028],
								[114.029305, 22.253752],
								[114.029030, 22.253752],
								[114.029030, 22.254028],
								[114.027641, 22.254028],
								[114.027641, 22.254583],
								[114.027359, 22.254583],
								[114.027359, 22.255138],
								[114.027084, 22.255138],
								[114.027084, 22.255417],
								[114.026802, 22.255417],
								[114.026802, 22.255695],
								[114.026527, 22.255695],
								[114.026527, 22.256527],
								[114.026253, 22.256527],
								[114.026253, 22.256805],
								[114.027916, 22.256805],
								[114.027916, 22.257639],
								[114.027641, 22.257639],
								[114.027641, 22.258751],
								[114.028191, 22.258751],
								[114.028198, 22.259027],
								[114.029030, 22.259027],
								[114.029030, 22.258751],
								[114.029305, 22.258751],
								[114.029305, 22.259027],
								[114.029861, 22.259027]
							]
						],
						[
							[
								[114.054581, 22.264862],
								[114.054581, 22.264584],
								[114.055138, 22.264584],
								[114.055138, 22.264305],
								[114.055687, 22.264305],
								[114.055695, 22.263748],
								[114.055969, 22.263748],
								[114.055969, 22.263201],
								[114.056252, 22.263197],
								[114.056252, 22.262636],
								[114.055969, 22.262636],
								[114.055969, 22.261526],
								[114.055695, 22.261526],
								[114.055695, 22.260695],
								[114.055420, 22.260695],
								[114.055420, 22.260139],
								[114.055138, 22.260139],
								[114.055138, 22.259861],
								[114.054863, 22.259861],
								[114.054863, 22.259583],
								[114.054581, 22.259583],
								[114.054581, 22.259304],
								[114.054306, 22.259304],
								[114.054306, 22.259029],
								[114.054031, 22.259027],
								[114.054031, 22.258751],
								[114.053749, 22.258751],
								[114.053749, 22.258472],
								[114.053192, 22.258472],
								[114.053192, 22.258192],
								[114.052635, 22.258192],
								[114.052635, 22.257917],
								[114.051804, 22.257917],
								[114.051804, 22.257639],
								[114.051529, 22.257639],
								[114.051529, 22.257360],
								[114.051247, 22.257360],
								[114.051247, 22.257084],
								[114.050697, 22.257084],
								[114.050697, 22.256805],
								[114.049309, 22.256805],
								[114.049309, 22.257084],
								[114.048470, 22.257084],
								[114.048470, 22.257360],
								[114.048195, 22.257360],
								[114.048195, 22.257639],
								[114.047638, 22.257639],
								[114.047638, 22.257917],
								[114.047394, 22.257917],
								[114.047363, 22.258192],
								[114.047081, 22.258192],
								[114.047081, 22.258751],
								[114.046806, 22.258751],
								[114.046806, 22.259027],
								[114.046524, 22.259027],
								[114.046524, 22.259583],
								[114.046806, 22.259583],
								[114.046806, 22.260414],
								[114.047081, 22.260414],
								[114.047081, 22.261808],
								[114.047333, 22.261808],
								[114.047363, 22.262362],
								[114.047638, 22.262362],
								[114.047638, 22.262636],
								[114.048752, 22.262636],
								[114.048752, 22.263197],
								[114.049026, 22.263197],
								[114.049026, 22.263748],
								[114.049309, 22.263748],
								[114.049309, 22.264027],
								[114.049583, 22.264027],
								[114.049583, 22.264305],
								[114.051247, 22.264305],
								[114.051247, 22.264584],
								[114.052597, 22.264584],
								[114.052635, 22.264862],
								[114.054581, 22.264862]
							]
						],
						[
							[
								[114.079025, 22.286804],
								[114.079025, 22.286528],
								[114.079308, 22.286528],
								[114.079308, 22.284582],
								[114.079025, 22.284582],
								[114.079025, 22.284027],
								[114.079308, 22.284027],
								[114.079308, 22.282639],
								[114.079025, 22.282639],
								[114.079025, 22.282084],
								[114.077919, 22.282084],
								[114.077919, 22.281805],
								[114.075417, 22.281805],
								[114.075417, 22.282084],
								[114.074860, 22.282084],
								[114.074860, 22.282364],
								[114.074585, 22.282364],
								[114.074585, 22.282639],
								[114.074303, 22.282639],
								[114.074303, 22.283194],
								[114.074028, 22.283194],
								[114.074028, 22.283751],
								[114.073753, 22.283751],
								[114.073753, 22.283981],
								[114.073753, 22.284306],
								[114.074028, 22.284306],
								[114.074028, 22.284582],
								[114.074303, 22.284582],
								[114.074303, 22.284861],
								[114.074585, 22.284861],
								[114.074585, 22.285139],
								[114.074860, 22.285139],
								[114.074860, 22.285418],
								[114.075142, 22.285418],
								[114.075142, 22.285694],
								[114.075691, 22.285694],
								[114.075691, 22.285973],
								[114.077019, 22.285973],
								[114.077637, 22.285973],
								[114.077637, 22.286249],
								[114.078476, 22.286249],
								[114.078476, 22.286528],
								[114.078751, 22.286528],
								[114.078751, 22.286804],
								[114.079025, 22.286804]
							]
						],
						[
							[
								[114.059029, 22.290136],
								[114.059029, 22.289862],
								[114.059303, 22.289862],
								[114.059303, 22.289583],
								[114.059586, 22.289583],
								[114.059586, 22.289305],
								[114.059860, 22.289305],
								[114.059860, 22.288958],
								[114.059860, 22.288750],
								[114.060143, 22.288750],
								[114.060143, 22.287640],
								[114.059029, 22.287640],
								[114.059029, 22.287083],
								[114.057915, 22.287083],
								[114.057915, 22.287361],
								[114.057640, 22.287361],
								[114.057640, 22.288046],
								[114.057640, 22.288195],
								[114.057396, 22.288195],
								[114.057083, 22.288195],
								[114.057083, 22.288506],
								[114.057083, 22.288750],
								[114.056839, 22.288750],
								[114.056252, 22.288750],
								[114.056252, 22.289026],
								[114.056526, 22.289026],
								[114.056526, 22.289583],
								[114.057808, 22.289646],
								[114.057915, 22.289654],
								[114.057915, 22.289862],
								[114.058472, 22.289862],
								[114.058472, 22.290136],
								[114.059029, 22.290136]
							]
						],
						[
							[
								[114.044029, 22.292358],
								[114.044029, 22.292084],
								[114.044304, 22.292084],
								[114.044304, 22.291531],
								[114.044586, 22.291531],
								[114.044586, 22.290695],
								[114.044861, 22.290695],
								[114.044861, 22.289862],
								[114.044586, 22.289862],
								[114.044586, 22.289583],
								[114.044029, 22.289583],
								[114.044029, 22.289305],
								[114.043198, 22.289305],
								[114.043198, 22.289026],
								[114.042915, 22.289026],
								[114.042915, 22.288750],
								[114.042358, 22.288750],
								[114.042358, 22.288473],
								[114.042084, 22.288473],
								[114.042084, 22.288195],
								[114.041809, 22.288195],
								[114.041809, 22.287914],
								[114.041527, 22.287914],
								[114.041527, 22.287640],
								[114.040970, 22.287640],
								[114.040970, 22.287361],
								[114.040695, 22.287361],
								[114.040695, 22.287083],
								[114.040413, 22.287083],
								[114.040413, 22.285973],
								[114.040695, 22.285973],
								[114.040695, 22.285418],
								[114.040970, 22.285418],
								[114.040970, 22.285139],
								[114.041527, 22.285139],
								[114.041527, 22.285973],
								[114.042076, 22.285973],
								[114.042084, 22.286528],
								[114.042358, 22.286528],
								[114.042358, 22.286804],
								[114.043198, 22.286804],
								[114.043198, 22.287083],
								[114.044861, 22.287083],
								[114.044861, 22.286804],
								[114.046249, 22.286804],
								[114.046249, 22.285694],
								[114.046509, 22.285694],
								[114.046524, 22.285139],
								[114.047081, 22.285139],
								[114.047081, 22.284861],
								[114.047363, 22.284861],
								[114.047363, 22.283751],
								[114.047081, 22.283751],
								[114.047081, 22.283194],
								[114.046806, 22.283194],
								[114.046806, 22.282639],
								[114.046524, 22.282639],
								[114.046524, 22.282084],
								[114.046249, 22.282084],
								[114.046249, 22.281528],
								[114.045975, 22.281528],
								[114.045975, 22.281252],
								[114.045692, 22.281252],
								[114.045692, 22.280972],
								[114.045418, 22.280972],
								[114.045418, 22.280695],
								[114.044861, 22.280695],
								[114.044861, 22.280140],
								[114.042641, 22.280140],
								[114.042641, 22.279861],
								[114.041809, 22.279861],
								[114.041809, 22.279583],
								[114.041252, 22.279583],
								[114.041252, 22.279310],
								[114.040970, 22.279306],
								[114.040970, 22.279030],
								[114.040703, 22.279030],
								[114.040695, 22.278748],
								[114.039871, 22.278748],
								[114.039864, 22.279030],
								[114.039581, 22.279030],
								[114.039581, 22.280140],
								[114.039307, 22.280140],
								[114.039307, 22.280416],
								[114.039047, 22.280416],
								[114.039024, 22.280695],
								[114.038475, 22.280695],
								[114.038475, 22.280972],
								[114.037918, 22.280972],
								[114.037918, 22.281528],
								[114.037636, 22.281528],
								[114.037636, 22.281805],
								[114.037361, 22.281805],
								[114.037361, 22.282364],
								[114.037086, 22.282364],
								[114.037086, 22.283194],
								[114.037361, 22.283194],
								[114.037361, 22.284027],
								[114.037086, 22.284027],
								[114.037086, 22.284306],
								[114.036819, 22.284306],
								[114.036804, 22.284582],
								[114.036530, 22.284582],
								[114.036530, 22.284861],
								[114.036247, 22.284861],
								[114.036247, 22.285418],
								[114.036018, 22.285418],
								[114.035973, 22.285694],
								[114.035698, 22.285694],
								[114.035698, 22.285973],
								[114.035423, 22.285973],
								[114.035416, 22.286804],
								[114.035698, 22.286804],
								[114.035698, 22.287640],
								[114.035423, 22.287640],
								[114.035416, 22.287914],
								[114.035141, 22.287914],
								[114.035141, 22.288473],
								[114.034859, 22.288473],
								[114.034859, 22.289583],
								[114.035698, 22.289583],
								[114.035698, 22.289862],
								[114.036247, 22.289862],
								[114.036247, 22.290136],
								[114.036804, 22.290136],
								[114.036804, 22.290419],
								[114.037086, 22.290419],
								[114.037086, 22.290695],
								[114.039307, 22.290695],
								[114.039307, 22.290972],
								[114.039864, 22.290972],
								[114.039864, 22.291248],
								[114.040413, 22.291248],
								[114.040413, 22.291531],
								[114.040695, 22.291531],
								[114.040695, 22.291248],
								[114.040970, 22.291248],
								[114.040970, 22.291531],
								[114.042084, 22.291531],
								[114.042084, 22.291805],
								[114.042641, 22.291805],
								[114.042641, 22.292084],
								[114.043747, 22.292084],
								[114.043747, 22.292358],
								[114.044029, 22.292358]
							]
						],
						[
							[
								[113.998405, 22.314135],
								[114.013885, 22.313904],
								[114.028908, 22.313904],
								[114.035126, 22.314013],
								[114.036247, 22.314035],
								[114.036247, 22.313749],
								[114.035973, 22.313751],
								[114.035973, 22.312931],
								[114.036247, 22.312916],
								[114.036247, 22.311810],
								[114.036530, 22.311806],
								[114.036530, 22.311531],
								[114.036797, 22.311527],
								[114.036797, 22.311251],
								[114.037086, 22.311251],
								[114.037086, 22.310694],
								[114.036530, 22.310694],
								[114.036530, 22.310417],
								[114.035973, 22.310423],
								[114.035973, 22.310141],
								[114.035698, 22.310141],
								[114.035698, 22.309860],
								[114.035141, 22.309860],
								[114.035141, 22.309584],
								[114.034584, 22.309584],
								[114.034584, 22.309305],
								[114.032913, 22.309305],
								[114.032913, 22.309584],
								[114.032639, 22.309584],
								[114.032639, 22.309860],
								[114.032364, 22.309860],
								[114.032364, 22.310314],
								[114.032364, 22.310694],
								[114.032082, 22.310694],
								[114.032082, 22.310972],
								[114.031807, 22.310972],
								[114.031807, 22.311169],
								[114.031807, 22.311531],
								[114.031570, 22.311562],
								[114.031525, 22.311806],
								[114.030975, 22.311806],
								[114.030975, 22.312084],
								[114.030693, 22.312084],
								[114.030701, 22.312361],
								[114.030136, 22.312361],
								[114.030136, 22.312641],
								[114.029587, 22.312641],
								[114.029587, 22.312916],
								[114.028473, 22.312931],
								[114.028473, 22.312361],
								[114.028198, 22.312361],
								[114.028191, 22.312113],
								[114.027695, 22.312084],
								[114.027641, 22.311806],
								[114.027077, 22.311810],
								[114.027077, 22.311531],
								[114.026802, 22.311531],
								[114.026802, 22.311251],
								[114.026527, 22.311251],
								[114.026527, 22.310972],
								[114.026253, 22.310972],
								[114.026253, 22.310694],
								[114.025970, 22.310694],
								[114.025970, 22.310417],
								[114.025139, 22.310423],
								[114.025139, 22.310141],
								[114.024567, 22.310141],
								[114.024567, 22.309860],
								[114.022636, 22.309860],
								[114.022636, 22.310139],
								[114.019859, 22.310141],
								[114.019859, 22.310417],
								[114.019463, 22.310417],
								[114.018471, 22.310423],
								[114.018471, 22.310139],
								[114.018196, 22.310139],
								[114.018196, 22.309860],
								[114.017914, 22.309860],
								[114.017914, 22.308472],
								[114.017639, 22.308472],
								[114.017639, 22.308195],
								[114.017365, 22.308195],
								[114.017365, 22.307638],
								[114.017082, 22.307638],
								[114.017082, 22.307362],
								[114.016808, 22.307362],
								[114.016808, 22.307638],
								[114.015976, 22.307638],
								[114.015976, 22.307362],
								[114.015694, 22.307362],
								[114.015694, 22.306528],
								[114.015976, 22.306528],
								[114.015976, 22.305695],
								[114.016251, 22.305695],
								[114.016251, 22.305416],
								[114.016525, 22.305416],
								[114.016525, 22.304583],
								[114.016251, 22.304583],
								[114.016251, 22.304029],
								[114.015976, 22.304029],
								[114.015976, 22.303747],
								[114.015694, 22.303747],
								[114.015694, 22.303194],
								[114.019028, 22.303194],
								[114.019028, 22.302919],
								[114.019302, 22.302919],
								[114.019302, 22.302641],
								[114.019577, 22.302637],
								[114.019585, 22.302084],
								[114.019844, 22.302084],
								[114.019859, 22.300972],
								[114.019585, 22.300972],
								[114.019585, 22.300695],
								[114.019028, 22.300694],
								[114.019028, 22.300421],
								[114.018471, 22.300417],
								[114.018471, 22.300138],
								[114.017639, 22.300138],
								[114.017639, 22.299862],
								[114.017365, 22.299862],
								[114.017365, 22.300138],
								[114.017082, 22.300138],
								[114.017082, 22.299862],
								[114.015694, 22.299862],
								[114.015694, 22.299583],
								[114.015137, 22.299583],
								[114.015137, 22.299303],
								[114.014862, 22.299303],
								[114.014862, 22.298750],
								[114.014580, 22.298750],
								[114.014580, 22.297361],
								[114.014862, 22.297361],
								[114.014862, 22.297085],
								[114.015419, 22.297085],
								[114.015419, 22.296806],
								[114.015976, 22.296806],
								[114.015976, 22.296528],
								[114.016808, 22.296528],
								[114.016808, 22.296806],
								[114.017082, 22.296806],
								[114.017082, 22.297085],
								[114.017365, 22.297085],
								[114.017365, 22.297361],
								[114.017914, 22.297361],
								[114.017914, 22.297085],
								[114.018471, 22.297085],
								[114.018471, 22.296806],
								[114.018753, 22.296806],
								[114.018753, 22.296249],
								[114.019028, 22.296249],
								[114.019028, 22.295973],
								[114.019302, 22.295973],
								[114.019302, 22.296249],
								[114.019577, 22.296249],
								[114.019585, 22.296528],
								[114.019859, 22.296528],
								[114.019859, 22.297638],
								[114.020142, 22.297638],
								[114.020142, 22.297916],
								[114.020691, 22.297916],
								[114.020691, 22.298195],
								[114.021248, 22.298195],
								[114.021248, 22.297916],
								[114.021530, 22.297916],
								[114.021530, 22.297638],
								[114.021797, 22.297638],
								[114.021805, 22.297361],
								[114.022087, 22.297361],
								[114.022087, 22.297085],
								[114.022362, 22.297085],
								[114.022362, 22.297361],
								[114.022636, 22.297361],
								[114.022636, 22.297916],
								[114.022919, 22.297916],
								[114.022919, 22.298471],
								[114.023193, 22.298471],
								[114.023193, 22.298750],
								[114.023476, 22.298750],
								[114.023476, 22.299028],
								[114.024307, 22.299028],
								[114.024307, 22.297916],
								[114.024582, 22.297916],
								[114.024582, 22.297638],
								[114.024857, 22.297638],
								[114.024864, 22.297361],
								[114.025139, 22.297361],
								[114.025139, 22.296806],
								[114.025406, 22.296806],
								[114.025414, 22.295416],
								[114.025696, 22.295416],
								[114.025696, 22.295139],
								[114.025970, 22.295139],
								[114.025970, 22.294863],
								[114.026253, 22.294863],
								[114.026253, 22.294306],
								[114.026527, 22.294306],
								[114.026527, 22.294027],
								[114.026802, 22.294027],
								[114.026802, 22.293751],
								[114.027084, 22.293751],
								[114.027084, 22.293194],
								[114.027359, 22.293194],
								[114.027359, 22.292641],
								[114.027641, 22.292641],
								[114.027641, 22.291248],
								[114.026802, 22.291248],
								[114.026802, 22.291531],
								[114.026527, 22.291531],
								[114.026527, 22.292084],
								[114.025970, 22.292084],
								[114.025970, 22.291805],
								[114.023750, 22.291805],
								[114.023750, 22.291531],
								[114.022919, 22.291531],
								[114.022919, 22.291805],
								[114.022362, 22.291805],
								[114.022362, 22.292084],
								[114.022087, 22.292084],
								[114.022087, 22.292358],
								[114.021805, 22.292358],
								[114.021805, 22.292917],
								[114.021530, 22.292917],
								[114.021530, 22.293194],
								[114.021248, 22.293194],
								[114.021248, 22.293472],
								[114.020691, 22.293472],
								[114.020691, 22.293751],
								[114.019585, 22.293751],
								[114.019585, 22.293472],
								[114.019302, 22.293472],
								[114.019302, 22.292917],
								[114.019028, 22.292917],
								[114.019028, 22.292358],
								[114.019302, 22.292358],
								[114.019302, 22.291822],
								[114.019585, 22.291805],
								[114.019585, 22.291531],
								[114.020142, 22.291531],
								[114.020142, 22.290972],
								[114.020973, 22.290972],
								[114.020973, 22.290695],
								[114.020416, 22.290695],
								[114.020416, 22.290419],
								[114.019859, 22.290419],
								[114.019859, 22.289862],
								[114.019585, 22.289862],
								[114.019585, 22.289583],
								[114.019028, 22.289583],
								[114.019028, 22.289305],
								[114.018753, 22.289305],
								[114.018753, 22.287914],
								[114.019028, 22.287914],
								[114.019028, 22.287361],
								[114.019302, 22.287361],
								[114.019302, 22.287083],
								[114.019585, 22.287083],
								[114.019585, 22.285694],
								[114.019859, 22.285694],
								[114.019859, 22.285139],
								[114.020142, 22.285139],
								[114.020142, 22.284861],
								[114.020416, 22.284861],
								[114.020416, 22.284582],
								[114.022209, 22.284582],
								[114.022362, 22.284582],
								[114.022362, 22.284306],
								[114.022636, 22.284306],
								[114.022636, 22.283751],
								[114.022919, 22.283751],
								[114.022919, 22.282364],
								[114.022636, 22.282364],
								[114.022636, 22.281252],
								[114.022362, 22.281252],
								[114.022362, 22.280972],
								[114.021805, 22.280972],
								[114.021805, 22.280695],
								[114.022087, 22.280695],
								[114.022087, 22.279861],
								[114.022362, 22.279861],
								[114.022362, 22.279306],
								[114.022636, 22.279306],
								[114.022636, 22.279030],
								[114.022919, 22.279030],
								[114.022919, 22.278748],
								[114.023193, 22.278748],
								[114.023193, 22.278194],
								[114.022919, 22.278194],
								[114.022919, 22.277916],
								[114.021805, 22.277916],
								[114.021805, 22.277637],
								[114.021248, 22.277637],
								[114.021248, 22.276529],
								[114.021530, 22.276529],
								[114.021530, 22.275972],
								[114.021248, 22.275972],
								[114.021248, 22.275694],
								[114.020973, 22.275694],
								[114.020973, 22.275415],
								[114.020691, 22.275415],
								[114.020691, 22.275139],
								[114.020973, 22.275139],
								[114.020973, 22.274860],
								[114.021248, 22.274860],
								[114.021248, 22.274584],
								[114.021790, 22.274584],
								[114.021805, 22.274305],
								[114.022087, 22.274305],
								[114.022087, 22.274027],
								[114.022362, 22.274027],
								[114.022362, 22.273750],
								[114.022636, 22.273750],
								[114.022636, 22.272362],
								[114.022362, 22.272362],
								[114.022362, 22.272083],
								[114.022087, 22.272083],
								[114.022087, 22.271805],
								[114.021805, 22.271805],
								[114.021805, 22.270971],
								[114.021530, 22.270971],
								[114.021530, 22.269583],
								[114.021805, 22.269583],
								[114.021805, 22.269028],
								[114.022362, 22.269028],
								[114.022362, 22.268751],
								[114.022087, 22.268749],
								[114.022087, 22.268473],
								[114.021805, 22.268473],
								[114.021805, 22.267918],
								[114.021530, 22.267916],
								[114.021530, 22.267651],
								[114.021248, 22.267639],
								[114.021248, 22.267084],
								[114.021500, 22.267084],
								[114.021530, 22.265972],
								[114.020416, 22.265972],
								[114.020416, 22.266251],
								[114.020142, 22.266251],
								[114.020142, 22.266527],
								[114.019302, 22.266527],
								[114.019302, 22.266251],
								[114.018753, 22.266251],
								[114.018753, 22.265972],
								[114.018196, 22.265972],
								[114.018196, 22.265694],
								[114.017914, 22.265694],
								[114.017914, 22.265417],
								[114.017639, 22.265417],
								[114.017639, 22.265141],
								[114.017082, 22.265141],
								[114.017082, 22.264862],
								[114.016808, 22.264862],
								[114.016808, 22.265141],
								[114.015419, 22.265141],
								[114.015419, 22.265417],
								[114.013748, 22.265417],
								[114.013748, 22.265694],
								[114.013191, 22.265694],
								[114.013191, 22.265972],
								[114.012642, 22.265972],
								[114.012642, 22.266251],
								[114.010414, 22.266251],
								[114.010414, 22.266527],
								[114.010139, 22.266527],
								[114.010139, 22.268194],
								[114.009865, 22.268194],
								[114.009865, 22.268473],
								[114.009583, 22.268473],
								[114.009583, 22.268749],
								[114.008751, 22.268749],
								[114.008751, 22.269028],
								[114.006531, 22.269028],
								[114.006531, 22.269306],
								[114.006248, 22.269306],
								[114.006248, 22.269861],
								[114.005974, 22.269861],
								[114.005974, 22.270140],
								[114.005692, 22.270140],
								[114.005692, 22.270695],
								[114.005142, 22.270695],
								[114.005142, 22.270971],
								[114.004860, 22.270971],
								[114.004860, 22.271250],
								[114.004303, 22.271250],
								[114.004303, 22.271528],
								[114.004028, 22.271528],
								[114.004028, 22.271805],
								[114.003197, 22.271805],
								[114.003197, 22.272083],
								[114.001526, 22.272083],
								[114.001526, 22.271805],
								[114.001251, 22.271805],
								[114.001251, 22.272083],
								[114.000969, 22.272083],
								[114.000969, 22.271805],
								[114.000694, 22.271805],
								[114.000694, 22.271528],
								[114.000137, 22.271528],
								[114.000137, 22.270971],
								[113.999916, 22.270971],
								[113.999580, 22.270971],
								[113.999580, 22.270695],
								[113.999580, 22.270416],
								[113.999306, 22.270416],
								[113.999306, 22.269861],
								[113.999031, 22.269861],
								[113.999031, 22.269306],
								[113.998749, 22.269306],
								[113.998749, 22.267639],
								[113.999031, 22.267639],
								[113.999031, 22.267363],
								[113.999306, 22.267363],
								[113.999306, 22.267084],
								[113.999863, 22.267084],
								[113.999863, 22.266806],
								[114.000420, 22.266806],
								[114.000420, 22.266529],
								[114.000687, 22.266527],
								[114.000694, 22.266251],
								[114.000969, 22.266251],
								[114.000969, 22.265972],
								[114.001251, 22.265972],
								[114.001251, 22.266251],
								[114.001526, 22.266251],
								[114.001526, 22.265972],
								[114.001762, 22.265972],
								[114.001808, 22.265694],
								[114.002357, 22.265694],
								[114.002357, 22.265417],
								[114.002640, 22.265417],
								[114.002640, 22.264862],
								[114.002357, 22.264862],
								[114.002357, 22.263197],
								[114.002640, 22.263197],
								[114.002640, 22.262918],
								[114.002907, 22.262918],
								[114.002914, 22.262638],
								[114.004303, 22.262636],
								[114.004303, 22.262362],
								[114.005417, 22.262362],
								[114.005417, 22.262083],
								[114.005692, 22.262083],
								[114.005692, 22.261526],
								[114.005974, 22.261526],
								[114.005974, 22.260973],
								[114.006226, 22.260973],
								[114.006248, 22.260695],
								[114.006531, 22.260695],
								[114.006531, 22.260414],
								[114.007080, 22.260414],
								[114.007080, 22.260139],
								[114.007362, 22.260139],
								[114.007362, 22.259583],
								[114.007637, 22.259583],
								[114.007637, 22.259027],
								[114.007919, 22.259027],
								[114.007919, 22.258472],
								[114.008194, 22.258472],
								[114.008194, 22.257917],
								[114.008469, 22.257917],
								[114.008469, 22.257360],
								[114.008751, 22.257360],
								[114.008751, 22.256805],
								[114.009026, 22.256805],
								[114.009026, 22.256250],
								[114.009308, 22.256250],
								[114.009308, 22.255974],
								[114.009567, 22.255974],
								[114.009583, 22.255417],
								[114.009865, 22.255417],
								[114.009865, 22.254862],
								[114.010971, 22.254862],
								[114.010971, 22.254583],
								[114.011253, 22.254583],
								[114.011253, 22.254028],
								[114.011528, 22.254028],
								[114.011528, 22.253752],
								[114.011803, 22.253752],
								[114.011803, 22.251808],
								[114.010696, 22.251806],
								[114.010696, 22.251530],
								[114.009583, 22.251530],
								[114.009583, 22.251247],
								[114.009026, 22.251247],
								[114.009026, 22.250973],
								[114.008751, 22.250973],
								[114.008751, 22.250694],
								[114.008194, 22.250694],
								[114.008194, 22.250416],
								[114.007637, 22.250416],
								[114.007637, 22.249861],
								[114.007362, 22.249861],
								[114.007362, 22.249584],
								[114.006805, 22.249584],
								[114.006805, 22.249308],
								[114.005692, 22.249308],
								[114.005692, 22.249025],
								[114.005142, 22.249025],
								[114.005142, 22.248751],
								[114.004860, 22.248751],
								[114.004860, 22.249025],
								[114.004585, 22.249025],
								[114.004585, 22.248751],
								[114.004303, 22.248751],
								[114.004303, 22.248472],
								[114.002914, 22.248472],
								[114.002640, 22.248472],
								[114.002640, 22.248194],
								[114.002083, 22.248194],
								[114.002083, 22.248472],
								[114.001526, 22.248472],
								[114.001526, 22.248194],
								[114.000969, 22.248194],
								[114.000969, 22.247639],
								[114.000694, 22.247639],
								[114.000694, 22.247084],
								[114.000420, 22.247084],
								[114.000420, 22.246529],
								[114.000137, 22.246529],
								[114.000137, 22.245972],
								[113.999863, 22.245972],
								[113.999863, 22.245693],
								[113.999863, 22.245420],
								[113.999580, 22.245417],
								[113.999580, 22.245138],
								[113.999031, 22.245138],
								[113.999031, 22.244862],
								[113.997917, 22.244862],
								[113.997917, 22.244583],
								[113.997360, 22.244583],
								[113.997360, 22.244305],
								[113.997086, 22.244305],
								[113.997086, 22.244028],
								[113.996803, 22.244028],
								[113.996803, 22.243750],
								[113.995972, 22.243750],
								[113.995972, 22.243195],
								[113.995415, 22.243195],
								[113.995415, 22.242916],
								[113.994583, 22.242916],
								[113.994583, 22.242640],
								[113.993752, 22.242640],
								[113.993752, 22.240973],
								[113.994026, 22.240973],
								[113.994026, 22.240694],
								[113.994308, 22.240694],
								[113.994308, 22.240417],
								[113.994423, 22.240417],
								[113.995140, 22.240417],
								[113.995140, 22.240141],
								[113.995415, 22.240141],
								[113.995415, 22.240417],
								[113.995697, 22.240417],
								[113.995697, 22.240141],
								[113.995972, 22.240141],
								[113.995972, 22.239859],
								[113.996246, 22.239859],
								[113.996246, 22.239584],
								[113.996803, 22.239584],
								[113.996803, 22.239859],
								[113.997086, 22.239859],
								[113.997086, 22.240141],
								[113.997917, 22.240141],
								[113.997917, 22.240417],
								[113.998154, 22.240417],
								[113.998192, 22.240694],
								[113.998749, 22.240694],
								[113.998749, 22.239859],
								[113.999031, 22.239859],
								[113.999031, 22.239305],
								[113.999306, 22.239305],
								[113.999306, 22.238750],
								[113.999863, 22.238750],
								[114.000420, 22.238750],
								[114.000420, 22.238194],
								[114.000694, 22.238194],
								[114.000694, 22.237919],
								[114.000969, 22.237919],
								[114.000969, 22.237640],
								[114.001251, 22.237640],
								[114.001251, 22.237362],
								[114.001808, 22.237362],
								[114.001808, 22.237083],
								[114.002083, 22.237083],
								[114.002083, 22.236805],
								[114.002357, 22.236805],
								[114.002357, 22.235971],
								[114.002640, 22.235971],
								[114.002640, 22.235416],
								[114.002907, 22.235416],
								[114.002914, 22.235140],
								[114.003197, 22.235138],
								[114.003197, 22.234583],
								[114.003471, 22.234583],
								[114.003471, 22.233749],
								[114.004028, 22.233749],
								[114.004028, 22.233473],
								[114.004578, 22.233473],
								[114.004585, 22.233194],
								[114.005692, 22.233194],
								[114.005692, 22.232916],
								[114.007362, 22.232916],
								[114.007362, 22.233194],
								[114.007637, 22.233194],
								[114.007637, 22.233473],
								[114.007919, 22.233473],
								[114.007919, 22.233749],
								[114.008194, 22.233749],
								[114.008194, 22.234304],
								[114.008751, 22.234304],
								[114.008751, 22.234583],
								[114.009026, 22.234583],
								[114.009026, 22.235138],
								[114.009857, 22.235138],
								[114.009865, 22.234861],
								[114.010139, 22.234861],
								[114.010139, 22.234583],
								[114.010414, 22.234583],
								[114.010414, 22.234028],
								[114.010696, 22.234028],
								[114.010696, 22.233749],
								[114.010971, 22.233749],
								[114.010971, 22.233194],
								[114.011253, 22.233194],
								[114.011253, 22.232916],
								[114.011528, 22.232916],
								[114.011528, 22.232639],
								[114.012085, 22.232639],
								[114.012085, 22.232361],
								[114.013748, 22.232361],
								[114.013748, 22.232084],
								[114.015419, 22.232082],
								[114.015419, 22.231806],
								[114.015694, 22.231806],
								[114.015694, 22.231251],
								[114.015976, 22.231251],
								[114.015976, 22.230972],
								[114.016251, 22.230972],
								[114.016251, 22.230694],
								[114.016525, 22.230694],
								[114.016525, 22.231251],
								[114.016808, 22.231251],
								[114.016808, 22.231527],
								[114.017357, 22.231527],
								[114.017365, 22.231806],
								[114.017639, 22.231806],
								[114.017639, 22.231251],
								[114.017914, 22.231251],
								[114.017914, 22.230972],
								[114.018196, 22.230972],
								[114.018196, 22.230694],
								[114.019028, 22.230694],
								[114.019028, 22.230417],
								[114.019577, 22.230417],
								[114.019585, 22.229860],
								[114.019028, 22.229860],
								[114.019028, 22.229584],
								[114.018471, 22.229584],
								[114.018471, 22.229305],
								[114.017914, 22.229305],
								[114.017914, 22.229029],
								[114.017365, 22.229029],
								[114.017365, 22.228479],
								[114.017639, 22.228472],
								[114.017639, 22.227917],
								[114.017914, 22.227917],
								[114.017914, 22.227362],
								[114.018196, 22.227362],
								[114.018196, 22.227083],
								[114.017914, 22.227083],
								[114.017914, 22.226528],
								[114.017082, 22.226528],
								[114.017082, 22.226252],
								[114.017365, 22.226252],
								[114.017365, 22.225973],
								[114.017082, 22.225973],
								[114.017082, 22.225695],
								[114.016808, 22.225695],
								[114.016808, 22.225416],
								[114.016251, 22.225416],
								[114.016251, 22.225140],
								[114.015419, 22.225140],
								[114.015419, 22.224859],
								[114.015137, 22.224859],
								[114.015137, 22.224583],
								[114.014580, 22.224583],
								[114.014580, 22.224306],
								[114.012917, 22.224306],
								[114.012917, 22.224030],
								[114.012085, 22.224030],
								[114.012085, 22.223751],
								[114.011528, 22.223747],
								[114.011528, 22.223473],
								[114.011253, 22.223473],
								[114.011253, 22.223194],
								[114.010971, 22.223194],
								[114.010971, 22.222637],
								[114.010696, 22.222637],
								[114.010696, 22.222361],
								[114.010414, 22.222361],
								[114.010414, 22.222086],
								[114.010139, 22.222086],
								[114.010139, 22.221806],
								[114.009865, 22.221806],
								[114.009865, 22.221525],
								[114.009583, 22.221525],
								[114.009583, 22.220972],
								[114.009865, 22.220972],
								[114.009865, 22.220415],
								[114.010139, 22.220415],
								[114.010139, 22.219862],
								[114.009865, 22.219862],
								[114.009865, 22.219584],
								[114.009308, 22.219584],
								[114.009308, 22.219303],
								[114.006805, 22.219303],
								[114.006805, 22.219030],
								[114.006248, 22.219028],
								[114.006248, 22.218750],
								[114.005692, 22.218750],
								[114.005692, 22.218472],
								[114.005417, 22.218472],
								[114.005417, 22.217916],
								[114.005142, 22.217916],
								[114.005142, 22.217085],
								[114.004860, 22.217085],
								[114.004860, 22.216249],
								[114.004585, 22.216249],
								[114.004585, 22.215694],
								[114.004303, 22.215694],
								[114.004303, 22.215416],
								[114.004028, 22.215416],
								[114.004028, 22.214863],
								[114.002357, 22.214863],
								[114.002357, 22.215139],
								[114.001526, 22.215139],
								[114.001526, 22.214863],
								[114.000969, 22.214863],
								[114.000969, 22.215139],
								[114.000694, 22.215139],
								[114.000694, 22.215416],
								[114.000420, 22.215416],
								[114.000420, 22.215694],
								[114.000137, 22.215694],
								[114.000137, 22.217085],
								[113.999580, 22.217085],
								[113.999580, 22.217361],
								[113.999306, 22.217361],
								[113.999306, 22.217638],
								[113.998749, 22.217638],
								[113.998749, 22.218193],
								[113.997086, 22.218193],
								[113.997086, 22.217916],
								[113.996529, 22.217916],
								[113.996529, 22.217638],
								[113.995972, 22.217638],
								[113.995972, 22.217361],
								[113.995415, 22.217361],
								[113.995415, 22.217085],
								[113.995140, 22.217085],
								[113.995140, 22.216806],
								[113.994583, 22.216806],
								[113.994583, 22.216249],
								[113.994308, 22.216249],
								[113.994308, 22.215973],
								[113.994026, 22.215973],
								[113.994026, 22.215416],
								[113.993469, 22.215416],
								[113.993469, 22.215139],
								[113.992920, 22.215139],
								[113.992920, 22.214863],
								[113.992081, 22.214863],
								[113.992081, 22.215139],
								[113.991249, 22.215139],
								[113.991249, 22.214863],
								[113.990974, 22.214863],
								[113.990974, 22.214582],
								[113.990692, 22.214582],
								[113.990692, 22.214306],
								[113.990135, 22.214306],
								[113.990135, 22.214027],
								[113.989861, 22.214027],
								[113.989861, 22.213751],
								[113.990135, 22.213751],
								[113.990135, 22.212917],
								[113.989586, 22.212917],
								[113.989586, 22.211809],
								[113.988747, 22.211805],
								[113.988747, 22.212084],
								[113.988472, 22.212084],
								[113.988472, 22.212358],
								[113.987640, 22.212358],
								[113.987640, 22.212084],
								[113.987358, 22.212084],
								[113.987358, 22.211805],
								[113.986526, 22.211805],
								[113.986526, 22.212641],
								[113.986809, 22.212641],
								[113.986809, 22.214306],
								[113.986526, 22.214306],
								[113.986526, 22.214863],
								[113.986252, 22.214863],
								[113.986252, 22.215139],
								[113.984024, 22.215139],
								[113.984024, 22.214863],
								[113.983749, 22.214863],
								[113.983749, 22.215139],
								[113.983475, 22.215139],
								[113.983475, 22.215416],
								[113.983192, 22.215416],
								[113.983192, 22.215694],
								[113.982635, 22.215694],
								[113.982635, 22.215973],
								[113.981529, 22.215973],
								[113.981529, 22.216249],
								[113.981247, 22.216249],
								[113.981247, 22.216528],
								[113.980972, 22.216528],
								[113.980972, 22.216806],
								[113.980736, 22.216806],
								[113.980698, 22.217085],
								[113.980415, 22.217085],
								[113.980415, 22.218193],
								[113.980698, 22.218193],
								[113.980698, 22.218472],
								[113.980972, 22.218472],
								[113.980972, 22.218750],
								[113.981247, 22.218750],
								[113.981247, 22.219028],
								[113.981804, 22.219028],
								[113.981804, 22.219303],
								[113.982086, 22.219303],
								[113.982086, 22.219862],
								[113.982361, 22.219862],
								[113.982361, 22.220415],
								[113.982635, 22.220415],
								[113.982635, 22.220972],
								[113.982918, 22.220972],
								[113.982918, 22.221525],
								[113.983475, 22.221525],
								[113.983475, 22.221806],
								[113.983749, 22.221806],
								[113.983749, 22.222637],
								[113.984024, 22.222637],
								[113.984024, 22.223194],
								[113.983475, 22.223194],
								[113.983475, 22.223473],
								[113.983192, 22.223473],
								[113.983192, 22.224583],
								[113.982918, 22.224583],
								[113.982918, 22.224859],
								[113.982361, 22.224859],
								[113.982361, 22.225140],
								[113.982086, 22.225140],
								[113.982086, 22.225416],
								[113.981804, 22.225416],
								[113.981804, 22.225695],
								[113.981247, 22.225695],
								[113.981247, 22.225973],
								[113.980972, 22.225973],
								[113.980972, 22.226252],
								[113.980698, 22.226252],
								[113.980698, 22.226528],
								[113.980415, 22.226528],
								[113.980415, 22.226805],
								[113.980141, 22.226805],
								[113.980141, 22.227362],
								[113.979858, 22.227362],
								[113.979858, 22.227638],
								[113.978752, 22.227638],
								[113.978752, 22.227917],
								[113.977913, 22.227917],
								[113.977913, 22.228472],
								[113.977364, 22.228472],
								[113.977364, 22.229305],
								[113.977638, 22.229305],
								[113.977638, 22.229860],
								[113.978195, 22.229860],
								[113.978195, 22.230417],
								[113.978470, 22.230417],
								[113.978470, 22.231251],
								[113.978752, 22.231251],
								[113.978752, 22.231806],
								[113.978470, 22.231806],
								[113.978470, 22.232082],
								[113.978630, 22.232084],
								[113.978752, 22.232084],
								[113.978752, 22.233194],
								[113.979027, 22.233194],
								[113.979027, 22.233473],
								[113.979309, 22.233473],
								[113.979309, 22.234028],
								[113.979584, 22.234028],
								[113.979584, 22.234304],
								[113.980141, 22.234304],
								[113.980141, 22.234583],
								[113.980698, 22.234583],
								[113.980698, 22.235138],
								[113.980972, 22.235138],
								[113.980972, 22.235416],
								[113.981224, 22.235416],
								[113.981247, 22.235971],
								[113.981529, 22.235971],
								[113.981529, 22.237083],
								[113.981247, 22.237083],
								[113.981247, 22.237362],
								[113.980972, 22.237362],
								[113.980972, 22.237640],
								[113.980415, 22.237640],
								[113.980415, 22.237919],
								[113.980141, 22.237919],
								[113.980141, 22.238194],
								[113.979813, 22.238195],
								[113.979584, 22.238195],
								[113.979584, 22.238470],
								[113.979309, 22.238470],
								[113.979309, 22.238750],
								[113.978752, 22.238750],
								[113.978752, 22.239029],
								[113.978470, 22.239029],
								[113.978470, 22.239305],
								[113.977364, 22.239305],
								[113.977364, 22.239029],
								[113.977081, 22.239029],
								[113.977081, 22.239305],
								[113.976807, 22.239305],
								[113.976807, 22.239584],
								[113.976250, 22.239584],
								[113.976250, 22.239859],
								[113.975983, 22.239859],
								[113.975975, 22.240141],
								[113.975136, 22.240141],
								[113.975136, 22.240417],
								[113.973198, 22.240417],
								[113.973198, 22.240141],
								[113.972359, 22.240141],
								[113.972359, 22.239859],
								[113.972084, 22.239859],
								[113.972084, 22.239584],
								[113.971802, 22.239584],
								[113.971802, 22.239305],
								[113.970695, 22.239305],
								[113.970695, 22.239029],
								[113.970139, 22.239029],
								[113.970139, 22.238750],
								[113.969582, 22.238750],
								[113.969582, 22.238470],
								[113.969307, 22.238470],
								[113.969307, 22.237919],
								[113.969025, 22.237919],
								[113.969025, 22.237362],
								[113.968193, 22.237362],
								[113.968193, 22.237083],
								[113.967361, 22.237083],
								[113.967361, 22.236805],
								[113.966805, 22.236805],
								[113.966805, 22.236526],
								[113.966599, 22.236526],
								[113.965973, 22.236526],
								[113.965973, 22.236250],
								[113.965416, 22.236250],
								[113.965416, 22.236526],
								[113.964859, 22.236526],
								[113.964859, 22.236250],
								[113.964584, 22.236250],
								[113.964584, 22.235971],
								[113.964302, 22.235971],
								[113.964302, 22.235697],
								[113.963753, 22.235697],
								[113.963753, 22.235416],
								[113.963196, 22.235416],
								[113.963196, 22.235140],
								[113.962639, 22.235138],
								[113.962639, 22.234861],
								[113.962364, 22.234861],
								[113.962364, 22.234304],
								[113.962082, 22.234304],
								[113.962082, 22.233473],
								[113.962631, 22.233473],
								[113.962639, 22.233194],
								[113.962364, 22.233194],
								[113.962364, 22.232916],
								[113.962082, 22.232916],
								[113.962082, 22.232641],
								[113.961807, 22.232639],
								[113.961807, 22.232361],
								[113.960976, 22.232361],
								[113.960976, 22.232639],
								[113.959862, 22.232639],
								[113.959862, 22.232361],
								[113.959305, 22.232361],
								[113.959305, 22.232084],
								[113.959030, 22.232084],
								[113.959030, 22.231806],
								[113.958748, 22.231806],
								[113.958748, 22.231527],
								[113.957642, 22.231527],
								[113.957642, 22.230972],
								[113.957359, 22.230972],
								[113.957359, 22.231251],
								[113.956802, 22.231251],
								[113.956802, 22.231806],
								[113.956528, 22.231806],
								[113.956528, 22.232082],
								[113.956802, 22.232084],
								[113.956802, 22.232639],
								[113.957085, 22.232639],
								[113.957085, 22.233194],
								[113.956802, 22.233194],
								[113.956802, 22.233473],
								[113.955971, 22.233473],
								[113.955971, 22.233749],
								[113.955139, 22.233749],
								[113.955139, 22.234028],
								[113.953476, 22.234028],
								[113.953476, 22.233473],
								[113.952362, 22.233473],
								[113.952362, 22.234028],
								[113.951530, 22.234028],
								[113.951530, 22.233749],
								[113.950974, 22.233749],
								[113.950974, 22.233473],
								[113.949028, 22.233473],
								[113.949028, 22.233194],
								[113.947914, 22.233194],
								[113.947914, 22.232916],
								[113.947083, 22.232916],
								[113.947083, 22.232639],
								[113.946526, 22.232639],
								[113.946526, 22.232361],
								[113.945694, 22.232361],
								[113.945694, 22.232084],
								[113.944862, 22.232084],
								[113.944862, 22.231806],
								[113.944305, 22.231806],
								[113.944305, 22.231527],
								[113.943474, 22.231527],
								[113.943474, 22.231251],
								[113.942917, 22.231251],
								[113.942917, 22.230972],
								[113.942047, 22.230972],
								[113.941803, 22.230972],
								[113.941803, 22.230139],
								[113.941528, 22.230139],
								[113.941528, 22.229860],
								[113.941254, 22.229860],
								[113.941254, 22.229584],
								[113.940140, 22.229584],
								[113.940140, 22.229029],
								[113.939857, 22.229029],
								[113.939857, 22.228750],
								[113.939308, 22.228750],
								[113.939308, 22.228479],
								[113.939026, 22.228472],
								[113.939026, 22.228195],
								[113.938194, 22.228195],
								[113.938194, 22.228472],
								[113.936249, 22.228472],
								[113.936249, 22.228195],
								[113.935692, 22.228195],
								[113.935692, 22.227917],
								[113.935417, 22.227917],
								[113.935417, 22.227638],
								[113.934860, 22.227638],
								[113.934860, 22.227083],
								[113.934586, 22.227083],
								[113.934586, 22.226528],
								[113.934303, 22.226528],
								[113.934303, 22.225973],
								[113.934029, 22.225973],
								[113.934029, 22.225695],
								[113.933472, 22.225695],
								[113.933472, 22.225416],
								[113.933197, 22.225416],
								[113.933197, 22.225140],
								[113.932640, 22.225140],
								[113.932640, 22.224859],
								[113.931808, 22.224859],
								[113.931808, 22.224583],
								[113.930969, 22.224583],
								[113.930969, 22.224306],
								[113.929863, 22.224306],
								[113.929863, 22.224030],
								[113.929306, 22.224030],
								[113.929306, 22.223747],
								[113.928474, 22.223747],
								[113.928474, 22.223473],
								[113.927917, 22.223473],
								[113.927917, 22.223747],
								[113.927635, 22.223747],
								[113.927635, 22.224030],
								[113.926804, 22.224030],
								[113.926804, 22.224306],
								[113.926529, 22.224306],
								[113.926529, 22.224583],
								[113.926247, 22.224583],
								[113.926247, 22.225416],
								[113.925972, 22.225416],
								[113.925972, 22.225695],
								[113.925697, 22.225695],
								[113.925697, 22.225973],
								[113.924309, 22.225973],
								[113.924309, 22.225695],
								[113.923752, 22.225695],
								[113.923752, 22.225416],
								[113.923195, 22.225416],
								[113.923195, 22.225140],
								[113.922920, 22.225140],
								[113.922920, 22.224306],
								[113.922363, 22.224306],
								[113.922363, 22.224030],
								[113.922081, 22.224030],
								[113.922081, 22.222919],
								[113.921806, 22.222919],
								[113.921806, 22.222086],
								[113.921524, 22.222086],
								[113.921524, 22.221525],
								[113.920975, 22.221525],
								[113.920975, 22.221251],
								[113.919586, 22.221251],
								[113.919586, 22.220972],
								[113.919029, 22.220972],
								[113.919029, 22.220694],
								[113.918747, 22.220694],
								[113.918747, 22.219862],
								[113.919029, 22.219862],
								[113.919029, 22.218750],
								[113.918747, 22.218750],
								[113.918747, 22.218472],
								[113.919586, 22.218472],
								[113.919586, 22.218193],
								[113.920418, 22.218193],
								[113.920418, 22.217916],
								[113.920692, 22.217916],
								[113.920692, 22.217640],
								[113.921249, 22.217638],
								[113.921249, 22.217916],
								[113.921806, 22.217916],
								[113.921806, 22.217638],
								[113.922081, 22.217638],
								[113.922081, 22.217361],
								[113.923195, 22.217361],
								[113.923195, 22.217085],
								[113.924026, 22.217085],
								[113.924026, 22.217916],
								[113.924583, 22.217916],
								[113.924583, 22.218193],
								[113.925140, 22.218193],
								[113.925140, 22.218472],
								[113.925407, 22.218472],
								[113.925415, 22.219028],
								[113.925697, 22.219028],
								[113.925697, 22.219303],
								[113.926247, 22.219303],
								[113.926247, 22.218750],
								[113.927361, 22.218750],
								[113.927361, 22.218472],
								[113.927917, 22.218472],
								[113.927917, 22.218193],
								[113.928192, 22.218193],
								[113.928192, 22.217916],
								[113.929031, 22.217916],
								[113.929031, 22.217640],
								[113.929581, 22.217638],
								[113.929581, 22.217361],
								[113.930695, 22.217361],
								[113.930695, 22.217085],
								[113.930969, 22.217085],
								[113.930969, 22.216806],
								[113.931252, 22.216806],
								[113.931252, 22.214863],
								[113.930969, 22.214863],
								[113.930969, 22.213472],
								[113.930695, 22.213472],
								[113.930695, 22.212917],
								[113.930420, 22.212917],
								[113.930420, 22.212641],
								[113.930687, 22.212641],
								[113.930695, 22.211250],
								[113.930969, 22.211250],
								[113.930969, 22.210972],
								[113.931252, 22.210972],
								[113.931252, 22.210695],
								[113.931808, 22.210695],
								[113.931808, 22.210419],
								[113.932358, 22.210419],
								[113.932358, 22.210136],
								[113.932640, 22.210136],
								[113.932640, 22.209862],
								[113.932358, 22.209862],
								[113.932358, 22.209583],
								[113.930420, 22.209583],
								[113.930420, 22.209862],
								[113.929306, 22.209862],
								[113.929306, 22.209583],
								[113.928192, 22.209583],
								[113.928192, 22.209305],
								[113.927635, 22.209305],
								[113.927635, 22.209026],
								[113.927086, 22.209026],
								[113.927086, 22.209305],
								[113.926529, 22.209305],
								[113.926529, 22.209026],
								[113.925697, 22.209026],
								[113.925697, 22.208750],
								[113.925140, 22.208750],
								[113.925140, 22.208195],
								[113.924026, 22.208195],
								[113.924026, 22.207914],
								[113.923470, 22.207914],
								[113.923470, 22.207640],
								[113.922081, 22.207640],
								[113.922081, 22.207361],
								[113.921249, 22.207361],
								[113.921249, 22.207083],
								[113.920975, 22.207083],
								[113.920975, 22.207361],
								[113.920418, 22.207361],
								[113.920418, 22.207640],
								[113.920135, 22.207640],
								[113.920135, 22.208471],
								[113.919861, 22.208471],
								[113.919861, 22.209026],
								[113.918472, 22.209026],
								[113.918472, 22.209862],
								[113.918198, 22.209862],
								[113.918198, 22.210136],
								[113.917915, 22.210136],
								[113.917915, 22.210695],
								[113.917641, 22.210695],
								[113.917641, 22.211250],
								[113.916527, 22.211250],
								[113.916527, 22.211529],
								[113.915138, 22.211529],
								[113.915138, 22.211250],
								[113.914307, 22.211250],
								[113.914307, 22.209026],
								[113.914024, 22.209026],
								[113.914024, 22.208195],
								[113.913750, 22.208195],
								[113.913750, 22.207640],
								[113.913475, 22.207640],
								[113.913475, 22.206804],
								[113.912361, 22.206804],
								[113.912361, 22.206530],
								[113.912086, 22.206528],
								[113.912086, 22.206249],
								[113.911804, 22.206249],
								[113.911804, 22.205973],
								[113.911530, 22.205973],
								[113.911530, 22.206249],
								[113.911247, 22.206249],
								[113.911247, 22.206528],
								[113.910973, 22.206528],
								[113.910973, 22.206804],
								[113.910416, 22.206804],
								[113.910416, 22.207083],
								[113.909027, 22.207083],
								[113.909027, 22.207361],
								[113.908752, 22.207361],
								[113.908752, 22.207640],
								[113.908203, 22.207640],
								[113.908195, 22.207914],
								[113.907639, 22.207914],
								[113.907639, 22.208195],
								[113.907364, 22.208195],
								[113.907364, 22.208471],
								[113.907082, 22.208471],
								[113.907082, 22.208750],
								[113.906807, 22.208750],
								[113.906807, 22.209305],
								[113.906525, 22.209305],
								[113.906525, 22.209583],
								[113.906250, 22.209583],
								[113.906250, 22.209862],
								[113.905693, 22.209862],
								[113.905693, 22.210136],
								[113.905136, 22.210136],
								[113.905136, 22.210419],
								[113.904861, 22.210419],
								[113.904861, 22.210695],
								[113.904305, 22.210695],
								[113.904305, 22.210136],
								[113.903473, 22.210136],
								[113.903473, 22.210695],
								[113.903198, 22.210695],
								[113.903198, 22.211250],
								[113.902916, 22.211250],
								[113.902916, 22.212358],
								[113.902084, 22.212358],
								[113.902084, 22.212641],
								[113.901802, 22.212641],
								[113.901802, 22.213194],
								[113.901527, 22.213194],
								[113.901527, 22.213472],
								[113.901253, 22.213472],
								[113.901253, 22.213751],
								[113.899025, 22.213751],
								[113.899025, 22.214027],
								[113.898750, 22.214027],
								[113.898750, 22.214306],
								[113.898476, 22.214306],
								[113.898476, 22.215139],
								[113.898193, 22.215139],
								[113.898193, 22.215416],
								[113.897919, 22.215416],
								[113.897919, 22.217085],
								[113.897636, 22.217085],
								[113.897636, 22.217361],
								[113.897362, 22.217361],
								[113.897362, 22.217638],
								[113.897087, 22.217638],
								[113.897087, 22.217916],
								[113.896828, 22.217916],
								[113.896805, 22.218193],
								[113.897087, 22.218193],
								[113.897087, 22.219303],
								[113.897362, 22.219303],
								[113.897362, 22.219584],
								[113.897636, 22.219584],
								[113.897636, 22.220139],
								[113.898193, 22.220139],
								[113.898193, 22.220415],
								[113.898476, 22.220415],
								[113.898476, 22.220694],
								[113.898193, 22.220694],
								[113.898193, 22.221806],
								[113.894302, 22.221806],
								[113.894302, 22.221251],
								[113.894028, 22.221251],
								[113.894028, 22.222086],
								[113.893753, 22.222086],
								[113.893753, 22.222637],
								[113.893471, 22.222637],
								[113.893471, 22.222919],
								[113.892639, 22.222919],
								[113.892639, 22.223194],
								[113.891808, 22.223194],
								[113.891808, 22.222919],
								[113.891525, 22.222919],
								[113.891525, 22.221806],
								[113.891808, 22.221806],
								[113.891808, 22.221525],
								[113.892082, 22.221525],
								[113.892082, 22.221251],
								[113.891808, 22.221251],
								[113.891808, 22.220694],
								[113.891251, 22.220694],
								[113.891251, 22.220415],
								[113.890976, 22.220415],
								[113.890976, 22.220140],
								[113.890137, 22.220139],
								[113.890137, 22.219862],
								[113.889862, 22.219862],
								[113.889862, 22.219584],
								[113.889580, 22.219584],
								[113.889580, 22.219303],
								[113.888748, 22.219303],
								[113.888748, 22.219862],
								[113.888191, 22.219862],
								[113.888191, 22.221251],
								[113.887642, 22.221251],
								[113.887642, 22.220972],
								[113.885651, 22.220972],
								[113.885414, 22.220972],
								[113.885414, 22.220694],
								[113.885139, 22.220694],
								[113.885139, 22.220415],
								[113.884865, 22.220415],
								[113.884865, 22.220139],
								[113.884583, 22.220139],
								[113.884583, 22.219862],
								[113.884026, 22.219862],
								[113.884026, 22.219584],
								[113.883751, 22.219584],
								[113.883751, 22.219303],
								[113.883469, 22.219303],
								[113.883469, 22.219028],
								[113.882919, 22.219028],
								[113.882919, 22.218750],
								[113.882637, 22.218750],
								[113.882637, 22.218472],
								[113.882362, 22.218472],
								[113.882362, 22.218193],
								[113.882080, 22.218193],
								[113.882080, 22.217916],
								[113.881531, 22.217916],
								[113.881531, 22.217638],
								[113.881248, 22.217638],
								[113.881248, 22.216806],
								[113.880974, 22.216806],
								[113.880974, 22.215973],
								[113.880692, 22.215973],
								[113.880692, 22.215416],
								[113.880417, 22.215416],
								[113.880417, 22.215139],
								[113.880142, 22.215139],
								[113.880142, 22.214863],
								[113.879585, 22.214863],
								[113.879585, 22.214582],
								[113.879028, 22.214582],
								[113.879028, 22.214306],
								[113.878471, 22.214306],
								[113.878471, 22.213482],
								[113.878754, 22.213472],
								[113.878754, 22.212917],
								[113.879028, 22.212917],
								[113.879028, 22.212084],
								[113.879303, 22.212084],
								[113.879303, 22.211529],
								[113.879585, 22.211529],
								[113.879585, 22.210695],
								[113.879303, 22.210695],
								[113.879303, 22.210419],
								[113.878471, 22.210419],
								[113.878471, 22.210136],
								[113.877914, 22.210136],
								[113.877914, 22.210419],
								[113.877640, 22.210419],
								[113.877640, 22.210695],
								[113.877357, 22.210695],
								[113.877357, 22.210972],
								[113.877083, 22.210972],
								[113.877083, 22.211250],
								[113.875137, 22.211250],
								[113.875137, 22.210972],
								[113.874306, 22.210972],
								[113.874306, 22.210695],
								[113.874031, 22.210695],
								[113.874031, 22.210419],
								[113.873749, 22.210419],
								[113.873749, 22.210136],
								[113.873474, 22.210136],
								[113.873474, 22.209862],
								[113.873192, 22.209862],
								[113.873192, 22.209583],
								[113.872360, 22.209583],
								[113.872360, 22.209305],
								[113.872086, 22.209305],
								[113.872086, 22.209026],
								[113.871803, 22.209026],
								[113.871803, 22.208750],
								[113.871529, 22.208750],
								[113.871529, 22.208471],
								[113.871246, 22.208471],
								[113.871246, 22.207914],
								[113.870972, 22.207914],
								[113.870972, 22.207640],
								[113.870697, 22.207640],
								[113.870697, 22.207083],
								[113.870415, 22.207083],
								[113.870415, 22.206804],
								[113.870140, 22.206804],
								[113.870140, 22.205973],
								[113.869858, 22.205973],
								[113.869858, 22.205694],
								[113.869026, 22.205694],
								[113.869026, 22.205418],
								[113.868752, 22.205416],
								[113.868752, 22.204027],
								[113.868469, 22.204027],
								[113.868469, 22.203751],
								[113.867920, 22.203751],
								[113.867920, 22.202641],
								[113.867638, 22.202639],
								[113.867638, 22.202362],
								[113.867363, 22.202362],
								[113.867363, 22.202084],
								[113.866806, 22.202084],
								[113.866806, 22.201529],
								[113.866531, 22.201529],
								[113.866531, 22.201805],
								[113.866249, 22.201805],
								[113.866249, 22.202084],
								[113.865135, 22.202084],
								[113.865135, 22.201529],
								[113.864029, 22.201529],
								[113.864029, 22.203194],
								[113.864296, 22.203194],
								[113.864304, 22.203751],
								[113.862358, 22.203751],
								[113.862358, 22.204027],
								[113.861809, 22.204027],
								[113.861809, 22.203751],
								[113.860695, 22.203751],
								[113.860695, 22.203472],
								[113.860420, 22.203472],
								[113.860420, 22.203194],
								[113.860138, 22.203194],
								[113.860138, 22.202917],
								[113.859863, 22.202917],
								[113.859863, 22.202639],
								[113.859306, 22.202639],
								[113.859306, 22.202917],
								[113.857361, 22.202917],
								[113.857361, 22.202641],
								[113.857086, 22.202639],
								[113.857086, 22.202084],
								[113.854584, 22.202084],
								[113.854584, 22.201805],
								[113.854309, 22.201805],
								[113.854309, 22.201540],
								[113.853752, 22.201529],
								[113.853752, 22.201250],
								[113.853470, 22.201250],
								[113.853470, 22.200972],
								[113.853195, 22.200972],
								[113.853195, 22.200695],
								[113.852913, 22.200695],
								[113.852913, 22.200420],
								[113.852638, 22.200417],
								[113.852638, 22.199310],
								[113.852768, 22.199308],
								[113.852913, 22.199305],
								[113.852913, 22.199030],
								[113.853752, 22.199030],
								[113.853752, 22.198748],
								[113.854027, 22.198748],
								[113.854027, 22.198473],
								[113.854309, 22.198473],
								[113.854309, 22.197359],
								[113.853195, 22.197359],
								[113.853195, 22.196808],
								[113.852913, 22.196808],
								[113.852913, 22.196529],
								[113.852638, 22.196529],
								[113.852638, 22.195972],
								[113.852364, 22.195972],
								[113.852364, 22.196251],
								[113.851524, 22.196251],
								[113.851524, 22.196529],
								[113.850693, 22.196529],
								[113.850693, 22.196808],
								[113.849861, 22.196808],
								[113.849861, 22.197083],
								[113.847916, 22.197083],
								[113.847916, 22.196808],
								[113.847359, 22.196808],
								[113.847359, 22.196529],
								[113.847084, 22.196529],
								[113.847084, 22.195972],
								[113.846802, 22.195972],
								[113.846802, 22.195694],
								[113.847084, 22.195694],
								[113.847084, 22.194584],
								[113.846802, 22.194584],
								[113.846802, 22.194305],
								[113.845970, 22.194305],
								[113.845970, 22.194860],
								[113.845703, 22.194860],
								[113.845695, 22.195139],
								[113.845413, 22.195139],
								[113.845413, 22.195694],
								[113.845139, 22.195694],
								[113.845139, 22.195972],
								[113.844582, 22.195972],
								[113.844582, 22.196529],
								[113.844307, 22.196529],
								[113.844307, 22.196808],
								[113.844582, 22.196808],
								[113.844582, 22.197083],
								[113.845413, 22.197083],
								[113.845413, 22.197359],
								[113.845695, 22.197359],
								[113.845695, 22.197639],
								[113.846252, 22.197639],
								[113.846252, 22.197918],
								[113.846527, 22.197918],
								[113.846527, 22.198195],
								[113.846802, 22.198195],
								[113.846802, 22.199306],
								[113.846527, 22.199306],
								[113.846527, 22.199862],
								[113.846802, 22.199862],
								[113.846802, 22.200558],
								[113.846809, 22.201805],
								[113.849030, 22.201805],
								[113.849030, 22.203194],
								[113.849297, 22.203194],
								[113.849304, 22.204027],
								[113.849030, 22.204027],
								[113.849030, 22.204582],
								[113.848473, 22.204582],
								[113.848473, 22.204861],
								[113.846802, 22.204861],
								[113.846802, 22.204582],
								[113.846527, 22.204582],
								[113.846527, 22.204027],
								[113.846252, 22.204027],
								[113.846252, 22.204306],
								[113.845970, 22.204306],
								[113.845970, 22.204861],
								[113.844864, 22.204861],
								[113.844864, 22.205139],
								[113.844582, 22.205139],
								[113.844582, 22.205416],
								[113.844330, 22.205418],
								[113.844307, 22.205973],
								[113.844025, 22.205973],
								[113.844025, 22.206528],
								[113.843193, 22.206528],
								[113.843193, 22.206804],
								[113.842636, 22.206804],
								[113.842636, 22.207083],
								[113.842361, 22.207083],
								[113.842361, 22.207361],
								[113.841805, 22.207361],
								[113.841805, 22.207640],
								[113.841530, 22.207640],
								[113.841530, 22.208471],
								[113.841805, 22.208471],
								[113.841805, 22.209583],
								[113.841530, 22.209583],
								[113.841530, 22.210136],
								[113.841248, 22.210136],
								[113.841248, 22.210695],
								[113.841530, 22.210695],
								[113.841530, 22.210972],
								[113.841805, 22.210972],
								[113.841805, 22.211805],
								[113.842087, 22.211805],
								[113.842087, 22.212641],
								[113.843193, 22.212641],
								[113.843193, 22.213751],
								[113.843475, 22.213751],
								[113.843475, 22.215973],
								[113.843193, 22.215973],
								[113.843193, 22.216249],
								[113.842918, 22.216249],
								[113.842918, 22.216806],
								[113.842674, 22.216806],
								[113.842636, 22.217085],
								[113.842087, 22.217085],
								[113.842087, 22.217361],
								[113.841530, 22.217361],
								[113.841530, 22.217638],
								[113.841248, 22.217638],
								[113.841248, 22.217916],
								[113.840996, 22.217916],
								[113.840973, 22.218193],
								[113.840416, 22.218193],
								[113.840416, 22.218472],
								[113.840141, 22.218472],
								[113.840141, 22.218750],
								[113.839859, 22.218750],
								[113.839859, 22.219028],
								[113.839584, 22.219028],
								[113.839584, 22.223747],
								[113.839859, 22.223747],
								[113.839859, 22.224030],
								[113.839584, 22.224030],
								[113.839584, 22.225140],
								[113.839859, 22.225140],
								[113.839859, 22.225695],
								[113.840141, 22.225695],
								[113.840141, 22.225973],
								[113.839859, 22.225973],
								[113.839859, 22.226528],
								[113.839302, 22.226528],
								[113.839302, 22.226252],
								[113.839027, 22.226252],
								[113.839027, 22.227917],
								[113.839584, 22.227917],
								[113.839584, 22.228195],
								[113.839859, 22.228195],
								[113.839859, 22.228472],
								[113.840141, 22.228472],
								[113.840141, 22.229029],
								[113.840416, 22.229029],
								[113.840416, 22.229584],
								[113.840698, 22.229584],
								[113.840698, 22.230694],
								[113.840973, 22.230694],
								[113.840973, 22.232082],
								[113.841133, 22.232084],
								[113.841248, 22.232084],
								[113.841248, 22.233194],
								[113.841530, 22.233194],
								[113.841530, 22.234304],
								[113.841805, 22.234304],
								[113.841805, 22.234861],
								[113.842636, 22.234861],
								[113.842636, 22.235138],
								[113.843193, 22.235138],
								[113.843193, 22.235416],
								[113.843750, 22.235416],
								[113.843750, 22.235138],
								[113.844582, 22.235138],
								[113.844582, 22.235416],
								[113.844841, 22.235416],
								[113.844864, 22.235697],
								[113.845695, 22.235697],
								[113.845695, 22.235416],
								[113.846252, 22.235416],
								[113.846252, 22.234861],
								[113.847084, 22.234861],
								[113.847084, 22.234304],
								[113.847641, 22.234304],
								[113.847641, 22.233749],
								[113.847916, 22.233749],
								[113.847916, 22.233473],
								[113.848198, 22.233473],
								[113.848198, 22.232916],
								[113.848473, 22.232916],
								[113.848473, 22.232641],
								[113.849030, 22.232639],
								[113.849030, 22.232084],
								[113.849586, 22.232082],
								[113.849586, 22.231251],
								[113.850136, 22.231251],
								[113.850136, 22.230972],
								[113.850693, 22.230972],
								[113.850693, 22.231527],
								[113.851250, 22.231527],
								[113.851250, 22.231806],
								[113.851524, 22.231806],
								[113.851524, 22.232082],
								[113.851807, 22.232084],
								[113.851807, 22.232916],
								[113.852081, 22.232916],
								[113.852081, 22.233194],
								[113.852364, 22.233194],
								[113.852364, 22.233473],
								[113.852638, 22.233473],
								[113.852638, 22.234583],
								[113.852913, 22.234583],
								[113.852913, 22.235138],
								[113.852638, 22.235140],
								[113.852638, 22.235697],
								[113.852364, 22.235697],
								[113.852364, 22.237083],
								[113.852081, 22.237083],
								[113.852081, 22.237362],
								[113.852364, 22.237362],
								[113.852364, 22.238194],
								[113.852081, 22.238195],
								[113.852081, 22.240141],
								[113.851524, 22.240141],
								[113.851524, 22.240694],
								[113.850975, 22.240694],
								[113.850975, 22.240973],
								[113.850693, 22.240973],
								[113.850693, 22.242083],
								[113.850975, 22.242083],
								[113.850975, 22.242640],
								[113.851250, 22.242640],
								[113.851250, 22.243195],
								[113.851524, 22.243195],
								[113.851524, 22.243471],
								[113.851807, 22.243471],
								[113.851807, 22.244028],
								[113.852081, 22.244028],
								[113.852081, 22.244305],
								[113.853470, 22.244305],
								[113.853470, 22.244583],
								[113.854027, 22.244583],
								[113.854027, 22.244862],
								[113.854309, 22.244862],
								[113.854309, 22.245138],
								[113.854858, 22.245138],
								[113.854858, 22.245417],
								[113.855415, 22.245417],
								[113.855415, 22.245693],
								[113.855698, 22.245693],
								[113.855698, 22.245420],
								[113.857918, 22.245417],
								[113.857918, 22.245693],
								[113.859581, 22.245693],
								[113.859581, 22.245972],
								[113.860138, 22.245972],
								[113.860138, 22.246250],
								[113.860420, 22.246250],
								[113.860420, 22.246529],
								[113.860695, 22.246529],
								[113.860695, 22.246805],
								[113.860970, 22.246805],
								[113.860970, 22.247084],
								[113.861526, 22.247084],
								[113.861526, 22.247360],
								[113.861809, 22.247360],
								[113.861809, 22.247639],
								[113.862083, 22.247639],
								[113.862083, 22.247915],
								[113.862640, 22.247915],
								[113.862640, 22.248194],
								[113.862915, 22.248194],
								[113.862915, 22.249308],
								[113.862640, 22.249308],
								[113.862640, 22.252083],
								[113.861526, 22.252083],
								[113.861526, 22.252361],
								[113.860420, 22.252361],
								[113.860420, 22.252642],
								[113.860138, 22.252642],
								[113.860138, 22.253195],
								[113.860420, 22.253195],
								[113.860420, 22.253469],
								[113.860695, 22.253469],
								[113.860695, 22.254028],
								[113.859863, 22.254028],
								[113.859863, 22.254305],
								[113.858749, 22.254305],
								[113.858749, 22.254583],
								[113.857635, 22.254583],
								[113.857635, 22.254028],
								[113.857361, 22.254028],
								[113.857361, 22.253469],
								[113.856247, 22.253469],
								[113.856247, 22.253195],
								[113.855972, 22.253195],
								[113.855972, 22.252916],
								[113.855698, 22.252916],
								[113.855698, 22.252642],
								[113.852913, 22.252642],
								[113.852913, 22.252916],
								[113.852638, 22.252916],
								[113.852638, 22.253752],
								[113.852364, 22.253752],
								[113.852364, 22.254583],
								[113.852081, 22.254583],
								[113.852081, 22.254862],
								[113.851807, 22.254862],
								[113.851807, 22.256527],
								[113.852081, 22.256527],
								[113.852081, 22.256805],
								[113.852341, 22.256805],
								[113.852364, 22.257084],
								[113.852638, 22.257084],
								[113.852638, 22.257360],
								[113.853470, 22.257360],
								[113.853470, 22.257639],
								[113.853752, 22.257639],
								[113.853752, 22.257917],
								[113.854309, 22.257917],
								[113.854309, 22.258192],
								[113.854576, 22.258192],
								[113.854584, 22.258751],
								[113.854858, 22.258751],
								[113.854858, 22.259304],
								[113.855141, 22.259304],
								[113.855141, 22.260139],
								[113.856247, 22.260139],
								[113.856247, 22.260414],
								[113.857361, 22.260414],
								[113.857361, 22.260695],
								[113.858192, 22.260695],
								[113.858192, 22.260973],
								[113.858467, 22.260973],
								[113.858475, 22.261250],
								[113.859306, 22.261250],
								[113.859306, 22.261526],
								[113.860970, 22.261526],
								[113.860970, 22.261250],
								[113.861809, 22.261250],
								[113.861809, 22.260973],
								[113.862320, 22.260973],
								[113.862358, 22.260139],
								[113.862640, 22.260139],
								[113.862640, 22.259861],
								[113.862915, 22.259861],
								[113.862915, 22.259027],
								[113.864586, 22.259027],
								[113.864586, 22.259304],
								[113.864861, 22.259304],
								[113.864861, 22.259861],
								[113.865417, 22.259861],
								[113.865417, 22.260414],
								[113.865692, 22.260414],
								[113.865692, 22.261250],
								[113.866249, 22.261250],
								[113.866249, 22.261805],
								[113.866531, 22.261808],
								[113.866531, 22.262083],
								[113.867081, 22.262083],
								[113.867081, 22.262636],
								[113.867363, 22.262636],
								[113.867363, 22.262918],
								[113.867622, 22.262918],
								[113.867638, 22.263472],
								[113.867920, 22.263472],
								[113.867920, 22.263748],
								[113.868195, 22.263748],
								[113.868195, 22.264305],
								[113.868469, 22.264305],
								[113.868469, 22.264858],
								[113.869026, 22.264862],
								[113.869026, 22.265694],
								[113.869308, 22.265694],
								[113.869308, 22.266251],
								[113.869583, 22.266251],
								[113.869583, 22.267916],
								[113.869858, 22.267916],
								[113.869858, 22.268194],
								[113.870140, 22.268194],
								[113.870140, 22.268473],
								[113.870972, 22.268473],
								[113.870972, 22.268749],
								[113.871529, 22.268749],
								[113.871529, 22.269028],
								[113.872360, 22.269028],
								[113.872360, 22.268749],
								[113.875694, 22.268749],
								[113.875694, 22.269028],
								[113.876251, 22.269028],
								[113.876251, 22.269306],
								[113.876808, 22.269306],
								[113.876808, 22.269583],
								[113.877357, 22.269583],
								[113.877357, 22.269861],
								[113.877640, 22.269861],
								[113.877640, 22.270140],
								[113.878746, 22.270140],
								[113.878754, 22.270416],
								[113.880417, 22.270416],
								[113.880417, 22.270695],
								[113.880692, 22.270695],
								[113.880692, 22.271250],
								[113.880974, 22.271250],
								[113.880974, 22.271528],
								[113.881248, 22.271528],
								[113.881248, 22.271805],
								[113.881531, 22.271805],
								[113.881531, 22.272083],
								[113.881805, 22.272083],
								[113.881805, 22.272362],
								[113.882080, 22.272362],
								[113.882080, 22.272638],
								[113.882919, 22.272638],
								[113.882919, 22.272362],
								[113.883469, 22.272362],
								[113.883469, 22.272083],
								[113.883751, 22.272083],
								[113.883751, 22.271805],
								[113.884308, 22.271805],
								[113.884308, 22.270695],
								[113.884583, 22.270695],
								[113.884583, 22.270140],
								[113.884865, 22.270140],
								[113.884865, 22.269861],
								[113.885696, 22.269861],
								[113.885696, 22.268473],
								[113.886238, 22.268473],
								[113.886253, 22.269028],
								[113.886803, 22.269028],
								[113.886803, 22.269306],
								[113.887642, 22.269306],
								[113.887642, 22.269583],
								[113.887360, 22.269583],
								[113.887360, 22.270416],
								[113.887642, 22.270416],
								[113.887642, 22.272362],
								[113.887085, 22.272362],
								[113.887085, 22.272638],
								[113.886803, 22.272638],
								[113.886803, 22.273193],
								[113.886528, 22.273193],
								[113.886528, 22.273472],
								[113.886253, 22.273472],
								[113.886253, 22.274027],
								[113.885971, 22.274027],
								[113.885971, 22.274584],
								[113.885696, 22.274584],
								[113.885696, 22.276529],
								[113.885971, 22.276529],
								[113.885971, 22.277916],
								[113.886253, 22.277916],
								[113.886253, 22.278194],
								[113.886803, 22.278194],
								[113.886803, 22.278473],
								[113.887917, 22.278473],
								[113.887917, 22.278748],
								[113.888748, 22.278748],
								[113.888748, 22.279030],
								[113.889305, 22.279030],
								[113.889305, 22.279306],
								[113.889862, 22.279306],
								[113.889862, 22.279583],
								[113.890137, 22.279583],
								[113.890137, 22.279861],
								[113.890419, 22.279861],
								[113.890419, 22.280140],
								[113.890976, 22.280140],
								[113.890976, 22.280416],
								[113.891991, 22.280416],
								[113.892082, 22.280416],
								[113.892082, 22.280695],
								[113.892365, 22.280695],
								[113.892365, 22.280972],
								[113.892639, 22.280972],
								[113.892639, 22.281528],
								[113.893196, 22.281528],
								[113.893196, 22.281805],
								[113.893471, 22.281805],
								[113.893471, 22.282084],
								[113.893753, 22.282084],
								[113.893753, 22.282364],
								[113.894028, 22.282364],
								[113.894028, 22.283472],
								[113.893753, 22.283472],
								[113.893753, 22.284027],
								[113.894028, 22.284027],
								[113.894028, 22.284306],
								[113.894287, 22.284306],
								[113.894302, 22.284582],
								[113.894028, 22.284582],
								[113.894028, 22.285973],
								[113.894302, 22.285973],
								[113.894302, 22.286528],
								[113.894585, 22.286528],
								[113.894585, 22.287083],
								[113.894302, 22.287083],
								[113.894302, 22.287640],
								[113.894028, 22.287640],
								[113.894028, 22.288195],
								[113.894585, 22.288195],
								[113.894585, 22.289305],
								[113.895973, 22.289305],
								[113.895973, 22.289583],
								[113.896248, 22.289583],
								[113.896248, 22.291531],
								[113.896530, 22.291531],
								[113.896530, 22.291805],
								[113.896805, 22.291805],
								[113.896805, 22.291531],
								[113.897087, 22.291531],
								[113.897087, 22.290972],
								[113.898750, 22.290972],
								[113.898750, 22.291531],
								[113.899582, 22.291531],
								[113.899582, 22.291248],
								[113.899857, 22.291248],
								[113.899864, 22.289583],
								[113.902084, 22.289583],
								[113.902084, 22.289862],
								[113.902359, 22.289862],
								[113.902359, 22.290972],
								[113.902641, 22.290972],
								[113.902641, 22.291248],
								[113.902916, 22.291248],
								[113.902916, 22.291531],
								[113.903198, 22.291531],
								[113.903198, 22.291805],
								[113.903748, 22.291805],
								[113.903748, 22.292917],
								[113.904587, 22.292917],
								[113.904587, 22.292641],
								[113.906807, 22.292641],
								[113.906807, 22.292358],
								[113.907364, 22.292358],
								[113.907364, 22.291805],
								[113.907913, 22.291805],
								[113.907913, 22.292084],
								[113.908195, 22.292084],
								[113.908195, 22.291805],
								[113.908470, 22.291805],
								[113.908470, 22.290972],
								[113.909027, 22.290972],
								[113.909027, 22.290419],
								[113.910973, 22.290419],
								[113.910973, 22.291531],
								[113.912086, 22.291531],
								[113.912086, 22.291805],
								[113.912361, 22.291805],
								[113.912361, 22.292084],
								[113.913475, 22.292084],
								[113.913475, 22.292641],
								[113.914024, 22.292641],
								[113.914024, 22.292917],
								[113.914307, 22.292917],
								[113.914307, 22.293194],
								[113.914864, 22.293194],
								[113.914864, 22.293472],
								[113.915695, 22.293472],
								[113.915695, 22.293194],
								[113.915970, 22.293194],
								[113.915970, 22.292641],
								[113.917915, 22.292641],
								[113.917915, 22.292358],
								[113.918198, 22.292358],
								[113.918198, 22.292084],
								[113.919029, 22.292084],
								[113.919029, 22.291805],
								[113.919304, 22.291805],
								[113.919304, 22.291248],
								[113.921806, 22.291248],
								[113.921806, 22.291531],
								[113.922081, 22.291531],
								[113.922081, 22.291248],
								[113.922638, 22.291248],
								[113.922638, 22.290972],
								[113.922920, 22.290972],
								[113.922920, 22.290695],
								[113.923195, 22.290695],
								[113.923195, 22.290421],
								[113.923470, 22.290419],
								[113.923470, 22.290140],
								[113.924026, 22.290136],
								[113.924026, 22.289862],
								[113.924309, 22.289862],
								[113.924309, 22.289305],
								[113.924583, 22.289305],
								[113.924583, 22.288473],
								[113.924858, 22.288473],
								[113.924858, 22.286528],
								[113.924583, 22.286528],
								[113.924583, 22.286249],
								[113.924858, 22.286249],
								[113.924858, 22.285694],
								[113.925140, 22.285694],
								[113.925140, 22.285418],
								[113.925697, 22.285418],
								[113.925697, 22.285139],
								[113.925972, 22.285139],
								[113.925972, 22.284582],
								[113.926224, 22.284582],
								[113.926247, 22.284306],
								[113.926529, 22.284306],
								[113.926529, 22.283751],
								[113.926804, 22.283751],
								[113.926804, 22.283472],
								[113.927551, 22.283472],
								[113.927635, 22.283472],
								[113.927635, 22.283194],
								[113.927361, 22.283194],
								[113.927361, 22.282364],
								[113.927917, 22.282364],
								[113.927917, 22.282084],
								[113.928192, 22.282084],
								[113.928192, 22.281528],
								[113.928474, 22.281528],
								[113.928474, 22.281252],
								[113.928749, 22.281252],
								[113.928749, 22.280972],
								[113.929031, 22.280972],
								[113.929031, 22.280416],
								[113.930420, 22.280416],
								[113.930420, 22.280695],
								[113.930695, 22.280695],
								[113.930695, 22.280972],
								[113.931526, 22.280972],
								[113.931526, 22.281252],
								[113.932358, 22.281252],
								[113.932358, 22.280972],
								[113.932640, 22.280972],
								[113.932640, 22.280695],
								[113.933472, 22.280695],
								[113.933472, 22.280972],
								[113.933197, 22.280972],
								[113.933197, 22.281252],
								[113.932915, 22.281252],
								[113.932915, 22.281805],
								[113.932640, 22.281805],
								[113.932640, 22.282639],
								[113.932915, 22.282639],
								[113.932915, 22.283194],
								[113.933197, 22.283194],
								[113.933197, 22.283472],
								[113.933472, 22.283472],
								[113.933472, 22.284027],
								[113.933746, 22.284027],
								[113.933746, 22.284306],
								[113.934013, 22.284306],
								[113.934029, 22.284582],
								[113.934288, 22.284582],
								[113.934303, 22.285139],
								[113.934586, 22.285139],
								[113.934586, 22.285418],
								[113.934128, 22.285418],
								[113.934029, 22.285418],
								[113.934029, 22.285694],
								[113.934303, 22.285694],
								[113.934303, 22.286249],
								[113.934586, 22.286249],
								[113.934586, 22.286804],
								[113.934860, 22.286804],
								[113.934860, 22.287361],
								[113.935143, 22.287361],
								[113.935143, 22.287914],
								[113.935417, 22.287914],
								[113.935417, 22.288195],
								[113.935692, 22.288195],
								[113.935692, 22.288473],
								[113.935974, 22.288473],
								[113.935974, 22.289305],
								[113.936249, 22.289305],
								[113.936249, 22.290136],
								[113.936531, 22.290136],
								[113.936531, 22.290419],
								[113.936798, 22.290419],
								[113.936806, 22.290972],
								[113.937080, 22.290972],
								[113.937080, 22.291248],
								[113.937363, 22.291248],
								[113.937363, 22.291531],
								[113.937614, 22.291531],
								[113.937637, 22.292084],
								[113.937920, 22.292084],
								[113.937920, 22.292358],
								[113.938194, 22.292358],
								[113.938194, 22.292917],
								[113.938469, 22.292917],
								[113.938469, 22.293194],
								[113.940414, 22.293194],
								[113.940414, 22.294027],
								[113.940697, 22.294027],
								[113.940697, 22.294306],
								[113.941254, 22.294306],
								[113.941254, 22.294582],
								[113.942917, 22.294582],
								[113.942917, 22.294306],
								[113.943192, 22.294306],
								[113.943192, 22.294027],
								[113.943474, 22.294027],
								[113.943474, 22.293751],
								[113.943748, 22.293751],
								[113.943748, 22.293194],
								[113.945137, 22.293194],
								[113.945137, 22.293472],
								[113.945969, 22.293472],
								[113.945969, 22.293194],
								[113.946526, 22.293194],
								[113.946526, 22.293472],
								[113.946808, 22.293472],
								[113.946808, 22.294027],
								[113.947083, 22.294027],
								[113.947083, 22.294306],
								[113.947365, 22.294306],
								[113.947365, 22.294582],
								[113.949860, 22.294584],
								[113.949860, 22.294863],
								[113.950142, 22.294863],
								[113.950142, 22.295139],
								[113.951248, 22.295139],
								[113.951248, 22.294863],
								[113.952362, 22.294863],
								[113.952362, 22.295139],
								[113.953751, 22.295139],
								[113.953751, 22.295416],
								[113.954865, 22.295416],
								[113.954865, 22.295694],
								[113.955414, 22.295694],
								[113.955414, 22.295973],
								[113.955696, 22.295973],
								[113.955696, 22.296249],
								[113.956253, 22.296249],
								[113.956253, 22.296528],
								[113.956528, 22.296528],
								[113.956528, 22.296806],
								[113.957085, 22.296806],
								[113.957085, 22.297085],
								[113.957916, 22.297085],
								[113.957916, 22.297361],
								[113.958473, 22.297361],
								[113.958473, 22.297638],
								[113.959030, 22.297638],
								[113.959030, 22.297916],
								[113.960136, 22.297916],
								[113.960136, 22.298195],
								[113.961250, 22.298195],
								[113.961250, 22.298471],
								[113.963753, 22.298471],
								[113.963753, 22.298750],
								[113.964859, 22.298750],
								[113.964859, 22.299028],
								[113.965698, 22.299028],
								[113.965698, 22.299303],
								[113.966805, 22.299303],
								[113.966805, 22.299583],
								[113.967636, 22.299583],
								[113.967636, 22.299862],
								[113.968750, 22.299862],
								[113.968750, 22.300138],
								[113.969307, 22.300138],
								[113.969307, 22.300417],
								[113.970413, 22.300417],
								[113.970413, 22.300694],
								[113.970947, 22.300694],
								[113.970970, 22.300972],
								[113.971802, 22.301035],
								[113.971809, 22.301250],
								[113.972359, 22.301250],
								[113.972359, 22.301525],
								[113.973198, 22.301525],
								[113.973198, 22.301805],
								[113.973717, 22.301805],
								[113.973747, 22.302084],
								[113.974297, 22.302084],
								[113.974304, 22.302361],
								[113.974586, 22.302361],
								[113.974586, 22.302637],
								[113.975136, 22.302637],
								[113.975136, 22.302919],
								[113.975693, 22.302919],
								[113.975693, 22.303194],
								[113.975975, 22.303194],
								[113.975975, 22.303473],
								[113.976524, 22.303473],
								[113.976524, 22.303747],
								[113.976807, 22.303747],
								[113.976807, 22.304029],
								[113.977318, 22.304029],
								[113.977364, 22.304583],
								[113.977638, 22.304583],
								[113.977638, 22.304859],
								[113.977913, 22.304859],
								[113.977913, 22.305138],
								[113.978195, 22.305138],
								[113.978195, 22.305416],
								[113.978470, 22.305416],
								[113.978470, 22.305695],
								[113.978752, 22.305695],
								[113.978752, 22.305973],
								[113.979309, 22.305973],
								[113.979309, 22.306252],
								[113.979584, 22.306252],
								[113.979584, 22.306528],
								[113.979858, 22.306528],
								[113.979858, 22.307362],
								[113.980141, 22.307362],
								[113.980141, 22.307638],
								[113.980698, 22.307638],
								[113.980698, 22.307917],
								[113.980972, 22.307917],
								[113.980972, 22.308195],
								[113.981247, 22.308195],
								[113.981247, 22.308472],
								[113.981796, 22.308472],
								[113.981804, 22.308750],
								[113.982086, 22.308750],
								[113.982086, 22.309029],
								[113.982361, 22.309029],
								[113.982361, 22.309305],
								[113.982918, 22.309305],
								[113.982918, 22.309584],
								[113.983475, 22.309584],
								[113.983475, 22.309860],
								[113.984024, 22.309860],
								[113.984024, 22.310139],
								[113.984863, 22.310139],
								[113.984863, 22.310417],
								[113.985138, 22.310417],
								[113.985138, 22.310694],
								[113.985420, 22.310694],
								[113.985420, 22.310972],
								[113.985565, 22.310972],
								[113.986252, 22.310972],
								[113.986252, 22.311251],
								[113.987076, 22.311251],
								[113.987083, 22.311527],
								[113.989029, 22.311527],
								[113.989029, 22.311806],
								[113.989861, 22.311806],
								[113.989861, 22.312084],
								[113.990044, 22.312084],
								[113.991531, 22.312084],
								[113.991531, 22.312361],
								[113.992363, 22.312361],
								[113.992363, 22.312639],
								[113.992920, 22.312639],
								[113.992920, 22.312916],
								[113.993469, 22.312916],
								[113.993469, 22.313194],
								[113.993752, 22.313194],
								[113.993752, 22.313473],
								[113.994026, 22.313471],
								[113.994026, 22.313749],
								[113.994308, 22.313749],
								[113.994308, 22.314028],
								[113.994583, 22.314028],
								[113.994583, 22.314445],
								[113.995117, 22.314402],
								[113.998405, 22.314135]
							]
						],
						[
							[
								[113.933746, 22.324306],
								[113.933746, 22.323750],
								[113.937637, 22.323750],
								[113.937637, 22.323471],
								[113.937920, 22.323471],
								[113.937920, 22.323195],
								[113.937080, 22.323195],
								[113.937080, 22.322916],
								[113.936249, 22.322916],
								[113.936249, 22.322639],
								[113.935417, 22.322639],
								[113.935417, 22.321253],
								[113.936211, 22.321253],
								[113.937080, 22.321253],
								[113.937080, 22.321527],
								[113.938194, 22.321527],
								[113.938194, 22.321806],
								[113.939308, 22.321806],
								[113.939308, 22.322083],
								[113.940140, 22.322083],
								[113.940140, 22.321806],
								[113.940971, 22.321806],
								[113.940971, 22.322083],
								[113.941254, 22.322083],
								[113.941254, 22.322363],
								[113.941803, 22.322363],
								[113.941803, 22.321806],
								[113.942917, 22.321806],
								[113.942917, 22.322639],
								[113.943192, 22.322639],
								[113.943192, 22.322916],
								[113.943474, 22.322916],
								[113.943474, 22.323195],
								[113.944031, 22.323195],
								[113.944031, 22.322639],
								[113.944740, 22.322639],
								[113.944862, 22.322639],
								[113.944862, 22.323195],
								[113.945137, 22.323195],
								[113.945137, 22.323471],
								[113.945969, 22.323471],
								[113.945969, 22.322916],
								[113.945694, 22.322916],
								[113.945694, 22.321806],
								[113.945969, 22.321806],
								[113.945969, 22.320141],
								[113.945694, 22.320141],
								[113.945694, 22.319859],
								[113.945419, 22.319859],
								[113.945419, 22.319029],
								[113.945137, 22.319029],
								[113.945137, 22.318748],
								[113.944862, 22.318748],
								[113.944862, 22.317919],
								[113.944305, 22.317919],
								[113.944305, 22.317636],
								[113.944031, 22.317636],
								[113.944031, 22.317083],
								[113.944305, 22.317083],
								[113.944305, 22.316526],
								[113.944031, 22.316526],
								[113.944031, 22.316250],
								[113.943748, 22.316250],
								[113.943748, 22.315695],
								[113.943474, 22.315695],
								[113.943474, 22.315416],
								[113.943192, 22.315416],
								[113.943192, 22.315140],
								[113.942917, 22.315140],
								[113.942917, 22.314583],
								[113.942642, 22.314583],
								[113.942642, 22.314304],
								[113.942360, 22.314304],
								[113.942360, 22.314028],
								[113.942085, 22.314028],
								[113.942085, 22.313473],
								[113.941803, 22.313473],
								[113.941803, 22.312916],
								[113.941528, 22.312916],
								[113.941528, 22.312639],
								[113.941254, 22.312639],
								[113.941254, 22.312084],
								[113.940971, 22.312084],
								[113.940971, 22.311806],
								[113.940697, 22.311806],
								[113.940697, 22.311527],
								[113.940140, 22.311527],
								[113.940140, 22.310139],
								[113.939857, 22.310139],
								[113.939857, 22.309860],
								[113.939583, 22.309860],
								[113.939583, 22.309305],
								[113.939308, 22.309305],
								[113.939308, 22.308195],
								[113.939026, 22.308195],
								[113.939026, 22.306252],
								[113.938751, 22.306252],
								[113.938751, 22.305695],
								[113.939026, 22.305695],
								[113.939026, 22.304859],
								[113.939583, 22.304859],
								[113.939583, 22.304583],
								[113.939857, 22.304583],
								[113.939857, 22.303747],
								[113.939583, 22.303747],
								[113.939583, 22.303194],
								[113.939308, 22.303194],
								[113.939308, 22.302637],
								[113.939026, 22.302637],
								[113.939026, 22.302361],
								[113.938469, 22.302361],
								[113.938469, 22.302084],
								[113.937920, 22.302084],
								[113.937920, 22.301805],
								[113.937637, 22.301805],
								[113.937637, 22.301525],
								[113.937363, 22.301525],
								[113.937363, 22.302084],
								[113.936531, 22.302084],
								[113.936531, 22.299303],
								[113.936806, 22.299303],
								[113.936806, 22.298195],
								[113.937080, 22.298195],
								[113.937080, 22.297640],
								[113.937363, 22.297638],
								[113.937363, 22.296528],
								[113.937637, 22.296528],
								[113.937637, 22.295416],
								[113.937920, 22.295416],
								[113.937920, 22.294863],
								[113.938194, 22.294863],
								[113.938194, 22.294584],
								[113.938469, 22.294584],
								[113.938469, 22.294306],
								[113.937920, 22.294306],
								[113.937920, 22.294027],
								[113.937637, 22.294027],
								[113.937637, 22.293751],
								[113.937363, 22.293751],
								[113.937363, 22.293472],
								[113.937080, 22.293472],
								[113.937080, 22.293194],
								[113.936806, 22.293194],
								[113.936806, 22.292917],
								[113.936531, 22.292917],
								[113.936531, 22.292641],
								[113.936249, 22.292641],
								[113.936249, 22.292358],
								[113.935974, 22.292358],
								[113.935974, 22.292084],
								[113.935692, 22.292084],
								[113.935692, 22.291805],
								[113.935417, 22.291805],
								[113.935417, 22.291531],
								[113.935143, 22.291531],
								[113.935143, 22.291248],
								[113.934860, 22.291248],
								[113.934860, 22.290972],
								[113.934303, 22.290972],
								[113.934303, 22.290695],
								[113.934029, 22.290695],
								[113.934029, 22.290419],
								[113.933472, 22.290419],
								[113.933472, 22.290136],
								[113.933197, 22.290136],
								[113.933197, 22.289862],
								[113.932358, 22.289862],
								[113.932358, 22.290136],
								[113.932083, 22.290136],
								[113.932083, 22.290419],
								[113.931808, 22.290419],
								[113.931808, 22.290695],
								[113.931526, 22.290695],
								[113.931526, 22.291248],
								[113.930969, 22.291248],
								[113.930969, 22.290993],
								[113.930138, 22.290972],
								[113.930138, 22.291229],
								[113.930138, 22.291531],
								[113.929031, 22.291531],
								[113.929031, 22.291805],
								[113.928337, 22.291805],
								[113.927917, 22.291805],
								[113.927917, 22.291531],
								[113.926529, 22.291531],
								[113.926529, 22.291805],
								[113.925697, 22.291805],
								[113.925697, 22.292084],
								[113.924858, 22.292084],
								[113.924858, 22.292358],
								[113.924026, 22.292358],
								[113.924026, 22.292641],
								[113.923195, 22.292641],
								[113.923195, 22.292917],
								[113.922081, 22.292917],
								[113.922081, 22.293194],
								[113.920975, 22.293194],
								[113.920975, 22.293472],
								[113.920692, 22.293472],
								[113.920692, 22.293751],
								[113.920135, 22.293751],
								[113.920135, 22.293472],
								[113.919861, 22.293472],
								[113.919861, 22.294027],
								[113.918747, 22.294027],
								[113.918747, 22.294306],
								[113.917915, 22.294306],
								[113.917915, 22.294582],
								[113.916809, 22.294584],
								[113.916809, 22.294863],
								[113.915970, 22.294863],
								[113.915970, 22.295139],
								[113.914307, 22.295139],
								[113.914307, 22.294584],
								[113.914024, 22.294584],
								[113.914024, 22.295139],
								[113.909584, 22.295139],
								[113.909584, 22.295416],
								[113.909027, 22.295416],
								[113.909027, 22.295139],
								[113.908195, 22.295139],
								[113.908195, 22.294863],
								[113.906807, 22.294863],
								[113.906807, 22.294584],
								[113.904305, 22.294582],
								[113.904305, 22.294306],
								[113.901802, 22.294306],
								[113.901802, 22.294027],
								[113.900970, 22.294027],
								[113.900970, 22.293751],
								[113.899864, 22.293751],
								[113.899864, 22.293472],
								[113.899025, 22.293472],
								[113.899025, 22.293194],
								[113.894859, 22.293194],
								[113.894859, 22.293472],
								[113.894585, 22.293472],
								[113.894585, 22.294027],
								[113.894302, 22.294027],
								[113.894302, 22.294306],
								[113.893753, 22.294306],
								[113.893753, 22.294027],
								[113.892914, 22.294027],
								[113.892914, 22.293751],
								[113.892082, 22.293751],
								[113.892082, 22.293472],
								[113.890976, 22.293472],
								[113.890976, 22.293751],
								[113.891251, 22.293751],
								[113.891251, 22.294306],
								[113.891525, 22.294306],
								[113.891525, 22.294863],
								[113.892365, 22.294863],
								[113.892365, 22.295139],
								[113.893196, 22.295139],
								[113.893196, 22.295416],
								[113.893471, 22.295416],
								[113.893471, 22.295973],
								[113.893753, 22.295973],
								[113.893753, 22.296249],
								[113.894028, 22.296249],
								[113.894028, 22.296511],
								[113.894028, 22.296806],
								[113.894302, 22.296806],
								[113.894302, 22.297085],
								[113.894585, 22.297085],
								[113.894585, 22.297638],
								[113.894859, 22.297640],
								[113.894859, 22.297916],
								[113.895142, 22.297916],
								[113.895142, 22.298195],
								[113.895416, 22.298195],
								[113.895416, 22.298750],
								[113.895691, 22.298750],
								[113.895691, 22.299028],
								[113.895973, 22.299028],
								[113.895973, 22.299303],
								[113.896248, 22.299303],
								[113.896248, 22.299583],
								[113.896805, 22.299583],
								[113.896805, 22.299862],
								[113.897087, 22.299862],
								[113.897087, 22.300138],
								[113.897636, 22.300138],
								[113.897636, 22.300417],
								[113.897919, 22.300417],
								[113.897919, 22.300694],
								[113.898087, 22.300695],
								[113.898193, 22.300695],
								[113.898193, 22.301250],
								[113.898476, 22.301250],
								[113.898476, 22.301805],
								[113.898193, 22.301805],
								[113.898193, 22.302084],
								[113.897919, 22.302084],
								[113.897919, 22.302919],
								[113.897636, 22.302919],
								[113.897636, 22.303473],
								[113.897362, 22.303473],
								[113.897362, 22.303747],
								[113.897087, 22.303747],
								[113.897087, 22.303926],
								[113.897087, 22.304029],
								[113.896530, 22.304029],
								[113.896530, 22.304308],
								[113.896248, 22.304308],
								[113.896248, 22.304859],
								[113.895973, 22.304859],
								[113.895973, 22.305695],
								[113.896248, 22.305695],
								[113.896248, 22.306252],
								[113.895973, 22.306252],
								[113.895973, 22.307083],
								[113.895416, 22.307083],
								[113.895416, 22.307362],
								[113.894585, 22.307362],
								[113.894585, 22.307638],
								[113.894028, 22.307638],
								[113.894028, 22.307917],
								[113.893196, 22.307917],
								[113.893196, 22.308195],
								[113.890976, 22.308195],
								[113.890976, 22.307917],
								[113.890694, 22.307917],
								[113.890694, 22.307638],
								[113.889862, 22.307638],
								[113.889862, 22.308195],
								[113.889580, 22.308195],
								[113.889580, 22.308750],
								[113.890419, 22.308750],
								[113.890419, 22.309029],
								[113.891251, 22.309029],
								[113.891251, 22.309305],
								[113.892082, 22.309305],
								[113.892082, 22.310417],
								[113.892365, 22.310417],
								[113.892365, 22.310972],
								[113.892639, 22.310972],
								[113.892639, 22.311251],
								[113.892761, 22.311251],
								[113.892914, 22.311251],
								[113.892914, 22.311527],
								[113.893593, 22.311527],
								[113.894028, 22.311527],
								[113.894028, 22.311672],
								[113.894028, 22.311806],
								[113.895416, 22.311806],
								[113.895416, 22.312084],
								[113.896530, 22.312084],
								[113.896530, 22.312361],
								[113.897362, 22.312361],
								[113.897362, 22.312639],
								[113.898193, 22.312639],
								[113.898193, 22.312916],
								[113.899025, 22.312916],
								[113.899025, 22.313194],
								[113.899582, 22.313194],
								[113.899582, 22.313473],
								[113.900414, 22.313473],
								[113.900414, 22.313749],
								[113.900681, 22.313749],
								[113.901253, 22.313749],
								[113.901253, 22.314028],
								[113.902084, 22.314028],
								[113.902084, 22.314304],
								[113.902916, 22.314304],
								[113.902916, 22.314583],
								[113.903183, 22.314583],
								[113.903748, 22.314583],
								[113.903748, 22.314861],
								[113.904587, 22.314861],
								[113.904587, 22.315140],
								[113.905418, 22.315140],
								[113.905418, 22.315416],
								[113.905663, 22.315416],
								[113.906525, 22.315416],
								[113.906525, 22.315695],
								[113.907364, 22.315695],
								[113.907364, 22.315971],
								[113.908195, 22.315971],
								[113.908195, 22.316250],
								[113.909027, 22.316250],
								[113.909027, 22.316526],
								[113.909859, 22.316526],
								[113.909859, 22.316805],
								[113.910698, 22.316805],
								[113.910698, 22.317083],
								[113.911530, 22.317083],
								[113.911530, 22.317362],
								[113.912636, 22.317362],
								[113.912636, 22.317636],
								[113.913475, 22.317636],
								[113.913475, 22.317919],
								[113.914230, 22.317919],
								[113.914307, 22.317919],
								[113.914307, 22.318195],
								[113.914932, 22.318195],
								[113.915413, 22.318195],
								[113.915413, 22.318472],
								[113.915688, 22.318472],
								[113.916252, 22.318472],
								[113.916252, 22.318748],
								[113.916527, 22.318748],
								[113.917358, 22.318748],
								[113.917358, 22.319029],
								[113.918198, 22.319029],
								[113.918198, 22.319305],
								[113.919304, 22.319305],
								[113.919304, 22.319584],
								[113.919434, 22.319584],
								[113.919586, 22.319584],
								[113.919586, 22.319859],
								[113.919861, 22.319859],
								[113.919861, 22.320141],
								[113.920692, 22.320141],
								[113.920692, 22.320417],
								[113.921524, 22.320417],
								[113.921524, 22.320694],
								[113.922363, 22.320694],
								[113.922363, 22.320972],
								[113.923195, 22.320972],
								[113.923195, 22.321253],
								[113.924026, 22.321253],
								[113.924026, 22.321527],
								[113.924858, 22.321527],
								[113.924858, 22.321806],
								[113.925972, 22.321806],
								[113.925972, 22.322083],
								[113.926804, 22.322083],
								[113.926804, 22.322363],
								[113.927353, 22.322363],
								[113.927635, 22.322363],
								[113.927635, 22.322639],
								[113.928291, 22.322639],
								[113.928474, 22.322639],
								[113.928474, 22.322916],
								[113.929306, 22.322916],
								[113.929306, 22.323195],
								[113.930138, 22.323195],
								[113.930138, 22.323471],
								[113.931252, 22.323471],
								[113.931252, 22.323750],
								[113.931946, 22.323750],
								[113.932083, 22.323750],
								[113.932083, 22.324028],
								[113.932915, 22.324028],
								[113.932915, 22.324306],
								[113.933746, 22.324306]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Kowloon City",
					"ID_0": 102,
					"ID_1": 4,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.179657, 22.349068],
							[114.180504, 22.347803],
							[114.181343, 22.346960],
							[114.181343, 22.345276],
							[114.182602, 22.345276],
							[114.184288, 22.344013],
							[114.184288, 22.342751],
							[114.183449, 22.341488],
							[114.183449, 22.340223],
							[114.184708, 22.339804],
							[114.185974, 22.338961],
							[114.185974, 22.337698],
							[114.185974, 22.335592],
							[114.187233, 22.335173],
							[114.187233, 22.333067],
							[114.188919, 22.333067],
							[114.190186, 22.334330],
							[114.191864, 22.333910],
							[114.192711, 22.333067],
							[114.194817, 22.331804],
							[114.196922, 22.333488],
							[114.199448, 22.334751],
							[114.201553, 22.336014],
							[114.202812, 22.336014],
							[114.204079, 22.335592],
							[114.204918, 22.334751],
							[114.204918, 22.327864],
							[114.204918, 22.325911],
							[114.203735, 22.324730],
							[114.202812, 22.323805],
							[114.204132, 22.322632],
							[114.206604, 22.320436],
							[114.207863, 22.319174],
							[114.208710, 22.318333],
							[114.209549, 22.317490],
							[114.210388, 22.315805],
							[114.209671, 22.315626],
							[114.209305, 22.315535],
							[114.209305, 22.315695],
							[114.208191, 22.315695],
							[114.208191, 22.315971],
							[114.207916, 22.315971],
							[114.207916, 22.316250],
							[114.207642, 22.316250],
							[114.207642, 22.316526],
							[114.207359, 22.316526],
							[114.207359, 22.316805],
							[114.206528, 22.316805],
							[114.206528, 22.317083],
							[114.206253, 22.317083],
							[114.206253, 22.317362],
							[114.205971, 22.317362],
							[114.205971, 22.317636],
							[114.205414, 22.317636],
							[114.205414, 22.317919],
							[114.204865, 22.317919],
							[114.204865, 22.318195],
							[114.204582, 22.318195],
							[114.204582, 22.318472],
							[114.204308, 22.318472],
							[114.204308, 22.319029],
							[114.203751, 22.319029],
							[114.203751, 22.319305],
							[114.203194, 22.319305],
							[114.203194, 22.319584],
							[114.202919, 22.319584],
							[114.202919, 22.320141],
							[114.202637, 22.320141],
							[114.202637, 22.320417],
							[114.202080, 22.320417],
							[114.202080, 22.320694],
							[114.201805, 22.320694],
							[114.201805, 22.321253],
							[114.201248, 22.321253],
							[114.201248, 22.321527],
							[114.200974, 22.321527],
							[114.200974, 22.320972],
							[114.200691, 22.320972],
							[114.200691, 22.320694],
							[114.200417, 22.320694],
							[114.200417, 22.319584],
							[114.200974, 22.319584],
							[114.200974, 22.319305],
							[114.201248, 22.319305],
							[114.201248, 22.319029],
							[114.201530, 22.319029],
							[114.201530, 22.318748],
							[114.201805, 22.318748],
							[114.201805, 22.318472],
							[114.202080, 22.318472],
							[114.202080, 22.318195],
							[114.202362, 22.318195],
							[114.202362, 22.317636],
							[114.202637, 22.317636],
							[114.202637, 22.317362],
							[114.203194, 22.317362],
							[114.203194, 22.317083],
							[114.203751, 22.317083],
							[114.203751, 22.316805],
							[114.204308, 22.316805],
							[114.204308, 22.316526],
							[114.204582, 22.316526],
							[114.204582, 22.316250],
							[114.204865, 22.316250],
							[114.204865, 22.315695],
							[114.205139, 22.315695],
							[114.205139, 22.315416],
							[114.205414, 22.315416],
							[114.205414, 22.315140],
							[114.205696, 22.315140],
							[114.205696, 22.314861],
							[114.206253, 22.314861],
							[114.206253, 22.314304],
							[114.206802, 22.314304],
							[114.206802, 22.314028],
							[114.207245, 22.314028],
							[114.207359, 22.314028],
							[114.207359, 22.313194],
							[114.207916, 22.313194],
							[114.207916, 22.312916],
							[114.208473, 22.312916],
							[114.208473, 22.312639],
							[114.208748, 22.312639],
							[114.208748, 22.312084],
							[114.209305, 22.312084],
							[114.209305, 22.311806],
							[114.209587, 22.311806],
							[114.209587, 22.311527],
							[114.209862, 22.311527],
							[114.209862, 22.311251],
							[114.210419, 22.311251],
							[114.210419, 22.310972],
							[114.210693, 22.310972],
							[114.210693, 22.310694],
							[114.211250, 22.310694],
							[114.211250, 22.309860],
							[114.212128, 22.309860],
							[114.212914, 22.309860],
							[114.212914, 22.310139],
							[114.213470, 22.310139],
							[114.213470, 22.309860],
							[114.214302, 22.309860],
							[114.214302, 22.309584],
							[114.214584, 22.309584],
							[114.214584, 22.309305],
							[114.214302, 22.309305],
							[114.214302, 22.308750],
							[114.214859, 22.308750],
							[114.214859, 22.308195],
							[114.215141, 22.308195],
							[114.215141, 22.307917],
							[114.215416, 22.307917],
							[114.215416, 22.306528],
							[114.215973, 22.306528],
							[114.215973, 22.306147],
							[114.215973, 22.305973],
							[114.216202, 22.305973],
							[114.216248, 22.305695],
							[114.216576, 22.305695],
							[114.216805, 22.305695],
							[114.216805, 22.305525],
							[114.216805, 22.305138],
							[114.217033, 22.305138],
							[114.217087, 22.304029],
							[114.216805, 22.304029],
							[114.216805, 22.303747],
							[114.216248, 22.303747],
							[114.216248, 22.303473],
							[114.215973, 22.303473],
							[114.215973, 22.303194],
							[114.215698, 22.303194],
							[114.215691, 22.302919],
							[114.215416, 22.302919],
							[114.215416, 22.303194],
							[114.215141, 22.303194],
							[114.215141, 22.303473],
							[114.214859, 22.303473],
							[114.214859, 22.303747],
							[114.214302, 22.303747],
							[114.214302, 22.304029],
							[114.214027, 22.304029],
							[114.214027, 22.304583],
							[114.213470, 22.304583],
							[114.213470, 22.304859],
							[114.212914, 22.304859],
							[114.212914, 22.305416],
							[114.212639, 22.305416],
							[114.212639, 22.305695],
							[114.212364, 22.305695],
							[114.212364, 22.305973],
							[114.212082, 22.305973],
							[114.212082, 22.306252],
							[114.211807, 22.306252],
							[114.211807, 22.306528],
							[114.211525, 22.306528],
							[114.211525, 22.306805],
							[114.211250, 22.306805],
							[114.211250, 22.307083],
							[114.210976, 22.307083],
							[114.210976, 22.307362],
							[114.210693, 22.307362],
							[114.210693, 22.307638],
							[114.210419, 22.307638],
							[114.210419, 22.307917],
							[114.210136, 22.307917],
							[114.210136, 22.308195],
							[114.209587, 22.308195],
							[114.209587, 22.308472],
							[114.209305, 22.308472],
							[114.209305, 22.308750],
							[114.209030, 22.308750],
							[114.209030, 22.309029],
							[114.208748, 22.309029],
							[114.208748, 22.309305],
							[114.208473, 22.309305],
							[114.208473, 22.309584],
							[114.207916, 22.309584],
							[114.207916, 22.309860],
							[114.207642, 22.309860],
							[114.207642, 22.310139],
							[114.207085, 22.310139],
							[114.207085, 22.310694],
							[114.206802, 22.310694],
							[114.206802, 22.310972],
							[114.206528, 22.310972],
							[114.206528, 22.311251],
							[114.205971, 22.311251],
							[114.205971, 22.311527],
							[114.205696, 22.311527],
							[114.205696, 22.311806],
							[114.205414, 22.311806],
							[114.205414, 22.312084],
							[114.205139, 22.312084],
							[114.205139, 22.312361],
							[114.204865, 22.312361],
							[114.204865, 22.312916],
							[114.204582, 22.312916],
							[114.204582, 22.313194],
							[114.204025, 22.313194],
							[114.204025, 22.313749],
							[114.203476, 22.313749],
							[114.203476, 22.314028],
							[114.203194, 22.314028],
							[114.203194, 22.314304],
							[114.202637, 22.314304],
							[114.202637, 22.314861],
							[114.202080, 22.314861],
							[114.202080, 22.315140],
							[114.201805, 22.315140],
							[114.201805, 22.315695],
							[114.201530, 22.315695],
							[114.201530, 22.315971],
							[114.201248, 22.315971],
							[114.201248, 22.316250],
							[114.200974, 22.316250],
							[114.200974, 22.316526],
							[114.200142, 22.316526],
							[114.200142, 22.317083],
							[114.199860, 22.317083],
							[114.199860, 22.317362],
							[114.199585, 22.317362],
							[114.199585, 22.317636],
							[114.199028, 22.317636],
							[114.199028, 22.318195],
							[114.198753, 22.318195],
							[114.198753, 22.318472],
							[114.198196, 22.318472],
							[114.198196, 22.318748],
							[114.197914, 22.318748],
							[114.197914, 22.319029],
							[114.197639, 22.319029],
							[114.197639, 22.319305],
							[114.197083, 22.319305],
							[114.197083, 22.319584],
							[114.196808, 22.319584],
							[114.196808, 22.319859],
							[114.196526, 22.319859],
							[114.196526, 22.320141],
							[114.195969, 22.320141],
							[114.195969, 22.319859],
							[114.195679, 22.319859],
							[114.195419, 22.319859],
							[114.195419, 22.319584],
							[114.195137, 22.319584],
							[114.195137, 22.319305],
							[114.194862, 22.319305],
							[114.194862, 22.319029],
							[114.194580, 22.319029],
							[114.194580, 22.318472],
							[114.194305, 22.318472],
							[114.194305, 22.317636],
							[114.194031, 22.317636],
							[114.194031, 22.317032],
							[114.194031, 22.316805],
							[114.193871, 22.316805],
							[114.193474, 22.316805],
							[114.193474, 22.316236],
							[114.193474, 22.315695],
							[114.193192, 22.315695],
							[114.193192, 22.315416],
							[114.192917, 22.315416],
							[114.192917, 22.314861],
							[114.192513, 22.314861],
							[114.192360, 22.314861],
							[114.192360, 22.314646],
							[114.192085, 22.314583],
							[114.192085, 22.313473],
							[114.191803, 22.313473],
							[114.191803, 22.313194],
							[114.192085, 22.313194],
							[114.192085, 22.312361],
							[114.192360, 22.312361],
							[114.192360, 22.311806],
							[114.192642, 22.311806],
							[114.192642, 22.311251],
							[114.192917, 22.311251],
							[114.192917, 22.310972],
							[114.193192, 22.310972],
							[114.193192, 22.309305],
							[114.193748, 22.309305],
							[114.193748, 22.309029],
							[114.194031, 22.309029],
							[114.194031, 22.307917],
							[114.193748, 22.307917],
							[114.193748, 22.305138],
							[114.193474, 22.305138],
							[114.193474, 22.304859],
							[114.193192, 22.304859],
							[114.193192, 22.304308],
							[114.193474, 22.304308],
							[114.193474, 22.303747],
							[114.193192, 22.303747],
							[114.193192, 22.302361],
							[114.192917, 22.302361],
							[114.192917, 22.302084],
							[114.192642, 22.302084],
							[114.192642, 22.301805],
							[114.192360, 22.301805],
							[114.192360, 22.301525],
							[114.191254, 22.301525],
							[114.191254, 22.301250],
							[114.190971, 22.301250],
							[114.190971, 22.300972],
							[114.190697, 22.300972],
							[114.190697, 22.300695],
							[114.190414, 22.300694],
							[114.190414, 22.300417],
							[114.189308, 22.300417],
							[114.189308, 22.299862],
							[114.189026, 22.299862],
							[114.189026, 22.300138],
							[114.187080, 22.300138],
							[114.187080, 22.299862],
							[114.186531, 22.299862],
							[114.186531, 22.299583],
							[114.185974, 22.299583],
							[114.185974, 22.299303],
							[114.185692, 22.299303],
							[114.185692, 22.299028],
							[114.185417, 22.299028],
							[114.185417, 22.297916],
							[114.184837, 22.297915],
							[114.184692, 22.299797],
							[114.184708, 22.301493],
							[114.184708, 22.302755],
							[114.182602, 22.304016],
							[114.182602, 22.305700],
							[114.180504, 22.308228],
							[114.180077, 22.309492],
							[114.179237, 22.310333],
							[114.177132, 22.312859],
							[114.175873, 22.314123],
							[114.175026, 22.315805],
							[114.174606, 22.317070],
							[114.174187, 22.318333],
							[114.173340, 22.320436],
							[114.172920, 22.321701],
							[114.172501, 22.323383],
							[114.173340, 22.325911],
							[114.174187, 22.327173],
							[114.174606, 22.328436],
							[114.175446, 22.329699],
							[114.175674, 22.330389],
							[114.175865, 22.330957],
							[114.175995, 22.331333],
							[114.176292, 22.332226],
							[114.176292, 22.333488],
							[114.176292, 22.336857],
							[114.175446, 22.338120],
							[114.175217, 22.338814],
							[114.175026, 22.339382],
							[114.174789, 22.340097],
							[114.174606, 22.340645],
							[114.173340, 22.341488],
							[114.172081, 22.342329],
							[114.172081, 22.343592],
							[114.172348, 22.343945],
							[114.173340, 22.345276],
							[114.172920, 22.346960],
							[114.173340, 22.348223],
							[114.174187, 22.349068],
							[114.179657, 22.349068]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Kwai Tsing",
					"ID_0": 102,
					"ID_1": 5,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.100418, 22.364027],
								[114.100418, 22.363750],
								[114.101524, 22.363750],
								[114.101524, 22.363474],
								[114.104019, 22.363474],
								[114.104584, 22.363474],
								[114.104584, 22.363195],
								[114.104858, 22.363194],
								[114.104858, 22.362917],
								[114.105415, 22.362917],
								[114.105415, 22.362638],
								[114.106529, 22.362638],
								[114.106529, 22.362364],
								[114.107086, 22.362364],
								[114.107086, 22.362083],
								[114.107361, 22.362083],
								[114.107361, 22.361805],
								[114.107635, 22.361805],
								[114.107635, 22.361528],
								[114.108147, 22.361528],
								[114.108192, 22.361252],
								[114.108345, 22.361252],
								[114.108475, 22.361252],
								[114.108475, 22.361071],
								[114.108475, 22.356806],
								[114.108192, 22.356806],
								[114.108192, 22.355694],
								[114.108475, 22.355694],
								[114.108475, 22.355139],
								[114.108749, 22.355139],
								[114.108749, 22.354584],
								[114.109024, 22.354584],
								[114.109024, 22.354305],
								[114.109306, 22.354305],
								[114.109306, 22.353750],
								[114.109581, 22.353750],
								[114.109581, 22.353472],
								[114.109863, 22.353472],
								[114.109863, 22.352917],
								[114.110138, 22.352917],
								[114.110138, 22.351528],
								[114.109863, 22.351528],
								[114.109863, 22.351250],
								[114.110138, 22.351250],
								[114.110138, 22.350973],
								[114.110420, 22.350973],
								[114.110420, 22.350416],
								[114.110695, 22.350416],
								[114.110695, 22.350140],
								[114.110970, 22.350140],
								[114.110970, 22.349583],
								[114.111252, 22.349583],
								[114.111252, 22.349306],
								[114.111809, 22.349306],
								[114.111809, 22.349028],
								[114.112083, 22.349028],
								[114.112083, 22.348749],
								[114.112297, 22.348749],
								[114.112640, 22.348749],
								[114.112640, 22.347639],
								[114.112358, 22.347639],
								[114.112358, 22.347084],
								[114.112083, 22.347084],
								[114.112083, 22.346806],
								[114.111809, 22.346806],
								[114.111809, 22.346510],
								[114.111809, 22.345694],
								[114.111526, 22.345694],
								[114.111526, 22.344305],
								[114.111809, 22.344305],
								[114.111809, 22.343197],
								[114.112083, 22.343197],
								[114.112083, 22.342916],
								[114.112640, 22.342916],
								[114.112640, 22.342361],
								[114.112915, 22.342361],
								[114.112915, 22.342083],
								[114.113472, 22.342083],
								[114.113472, 22.341808],
								[114.113747, 22.341808],
								[114.113747, 22.341528],
								[114.113472, 22.341528],
								[114.113472, 22.341249],
								[114.113747, 22.341249],
								[114.113747, 22.340973],
								[114.113472, 22.340973],
								[114.113472, 22.339861],
								[114.113197, 22.339861],
								[114.113197, 22.339582],
								[114.113472, 22.339582],
								[114.113472, 22.339027],
								[114.113747, 22.339027],
								[114.113747, 22.338194],
								[114.114304, 22.338194],
								[114.114304, 22.337360],
								[114.114586, 22.337360],
								[114.114586, 22.337084],
								[114.114304, 22.337084],
								[114.114304, 22.336805],
								[114.114029, 22.336805],
								[114.114029, 22.335974],
								[114.113747, 22.335974],
								[114.113747, 22.335695],
								[114.113197, 22.335695],
								[114.113197, 22.334583],
								[114.112915, 22.334583],
								[114.112915, 22.334028],
								[114.113197, 22.334028],
								[114.113197, 22.332916],
								[114.113472, 22.332916],
								[114.113472, 22.332642],
								[114.112640, 22.332642],
								[114.112640, 22.332359],
								[114.112358, 22.332359],
								[114.112358, 22.331530],
								[114.112083, 22.331530],
								[114.112083, 22.330973],
								[114.111809, 22.330973],
								[114.111809, 22.329861],
								[114.111526, 22.329861],
								[114.111526, 22.329306],
								[114.110970, 22.329306],
								[114.110970, 22.329025],
								[114.110695, 22.329025],
								[114.110695, 22.328472],
								[114.110138, 22.328472],
								[114.110138, 22.328194],
								[114.109581, 22.328194],
								[114.109581, 22.327639],
								[114.109306, 22.327639],
								[114.109306, 22.327084],
								[114.109024, 22.327084],
								[114.109024, 22.326250],
								[114.108749, 22.326250],
								[114.108749, 22.325138],
								[114.107086, 22.325138],
								[114.107086, 22.325693],
								[114.106804, 22.325693],
								[114.106804, 22.325972],
								[114.106529, 22.325972],
								[114.106529, 22.326529],
								[114.106247, 22.326529],
								[114.106247, 22.326805],
								[114.105972, 22.326805],
								[114.105972, 22.327084],
								[114.105415, 22.327084],
								[114.105415, 22.327639],
								[114.104858, 22.327639],
								[114.104858, 22.327915],
								[114.102913, 22.327915],
								[114.102913, 22.327639],
								[114.102638, 22.327639],
								[114.102638, 22.327362],
								[114.102364, 22.327360],
								[114.102364, 22.327084],
								[114.102081, 22.327084],
								[114.102081, 22.326805],
								[114.100975, 22.326805],
								[114.100975, 22.327084],
								[114.100418, 22.327084],
								[114.100418, 22.327360],
								[114.099861, 22.327362],
								[114.099861, 22.327639],
								[114.099030, 22.327639],
								[114.099030, 22.327778],
								[114.099030, 22.328472],
								[114.098747, 22.328472],
								[114.098747, 22.329025],
								[114.098473, 22.329025],
								[114.098473, 22.329306],
								[114.098198, 22.329306],
								[114.098198, 22.329584],
								[114.095970, 22.329584],
								[114.095970, 22.329306],
								[114.095695, 22.329306],
								[114.095695, 22.328751],
								[114.095139, 22.328751],
								[114.095139, 22.326250],
								[114.095413, 22.326250],
								[114.095413, 22.325972],
								[114.092918, 22.325972],
								[114.092918, 22.325693],
								[114.092087, 22.325693],
								[114.092087, 22.325972],
								[114.091805, 22.325972],
								[114.091805, 22.326250],
								[114.091530, 22.326250],
								[114.091530, 22.326805],
								[114.091248, 22.326805],
								[114.091248, 22.327360],
								[114.090973, 22.327362],
								[114.090973, 22.327639],
								[114.090805, 22.327639],
								[114.090698, 22.327639],
								[114.090691, 22.327900],
								[114.090416, 22.327915],
								[114.090416, 22.328194],
								[114.090141, 22.328194],
								[114.090141, 22.328472],
								[114.089859, 22.328472],
								[114.089859, 22.329025],
								[114.089584, 22.329025],
								[114.089584, 22.329306],
								[114.089302, 22.329306],
								[114.089302, 22.329584],
								[114.089027, 22.329584],
								[114.089027, 22.330137],
								[114.088753, 22.330137],
								[114.088753, 22.330416],
								[114.088470, 22.330416],
								[114.088470, 22.331247],
								[114.088753, 22.331247],
								[114.088753, 22.332359],
								[114.089027, 22.332359],
								[114.089027, 22.333195],
								[114.089302, 22.333195],
								[114.089302, 22.333752],
								[114.089584, 22.333752],
								[114.089584, 22.334583],
								[114.089859, 22.334583],
								[114.089859, 22.335138],
								[114.090141, 22.335138],
								[114.090141, 22.335417],
								[114.090416, 22.335417],
								[114.090416, 22.335974],
								[114.090691, 22.335974],
								[114.090698, 22.336250],
								[114.090973, 22.336250],
								[114.090973, 22.337084],
								[114.090698, 22.337084],
								[114.090691, 22.337360],
								[114.090416, 22.337360],
								[114.090416, 22.337639],
								[114.089859, 22.337639],
								[114.089859, 22.337084],
								[114.089584, 22.337084],
								[114.089584, 22.336805],
								[114.089302, 22.336805],
								[114.089302, 22.337084],
								[114.089027, 22.337084],
								[114.089027, 22.337917],
								[114.089302, 22.337917],
								[114.089302, 22.338194],
								[114.090141, 22.338194],
								[114.090141, 22.338751],
								[114.089584, 22.338751],
								[114.089584, 22.338472],
								[114.089027, 22.338472],
								[114.089027, 22.339582],
								[114.088753, 22.339582],
								[114.088753, 22.340139],
								[114.089027, 22.340139],
								[114.089027, 22.340414],
								[114.089302, 22.340414],
								[114.089302, 22.341249],
								[114.089027, 22.341249],
								[114.089027, 22.342083],
								[114.090691, 22.342083],
								[114.090691, 22.342361],
								[114.090416, 22.342361],
								[114.090416, 22.342636],
								[114.089859, 22.342636],
								[114.089859, 22.342916],
								[114.089302, 22.342916],
								[114.089302, 22.343197],
								[114.087914, 22.343197],
								[114.087914, 22.343472],
								[114.086250, 22.343472],
								[114.086250, 22.343748],
								[114.085136, 22.343748],
								[114.085136, 22.344027],
								[114.084862, 22.344027],
								[114.084862, 22.344584],
								[114.084587, 22.344584],
								[114.084587, 22.344767],
								[114.084587, 22.345140],
								[114.084305, 22.345140],
								[114.084305, 22.345419],
								[114.084030, 22.345419],
								[114.084030, 22.346806],
								[114.084305, 22.346806],
								[114.084305, 22.347359],
								[114.084030, 22.347359],
								[114.084030, 22.347918],
								[114.083916, 22.347918],
								[114.081802, 22.347918],
								[114.081802, 22.348749],
								[114.081528, 22.348749],
								[114.081528, 22.349583],
								[114.081253, 22.349583],
								[114.081253, 22.350140],
								[114.080971, 22.350140],
								[114.080971, 22.352083],
								[114.081253, 22.352083],
								[114.081253, 22.352362],
								[114.081528, 22.352362],
								[114.081528, 22.352638],
								[114.081802, 22.352638],
								[114.081802, 22.353472],
								[114.081528, 22.353472],
								[114.081528, 22.354029],
								[114.081253, 22.354029],
								[114.081253, 22.355415],
								[114.080971, 22.355415],
								[114.080971, 22.355972],
								[114.080696, 22.355972],
								[114.080696, 22.356527],
								[114.080414, 22.356527],
								[114.080414, 22.356806],
								[114.079865, 22.356806],
								[114.079865, 22.357916],
								[114.079308, 22.357916],
								[114.079308, 22.358597],
								[114.079308, 22.358747],
								[114.079460, 22.358747],
								[114.079582, 22.358747],
								[114.079582, 22.358871],
								[114.079582, 22.359030],
								[114.079742, 22.359030],
								[114.080139, 22.359030],
								[114.080139, 22.359306],
								[114.080696, 22.359306],
								[114.080696, 22.359583],
								[114.080971, 22.359583],
								[114.080971, 22.359861],
								[114.081116, 22.359861],
								[114.081802, 22.359861],
								[114.081802, 22.360140],
								[114.081947, 22.360140],
								[114.082359, 22.360142],
								[114.082359, 22.360275],
								[114.082359, 22.360416],
								[114.082642, 22.360416],
								[114.082642, 22.360695],
								[114.082916, 22.360695],
								[114.082916, 22.360970],
								[114.083473, 22.360970],
								[114.083473, 22.361252],
								[114.083649, 22.361252],
								[114.084030, 22.361252],
								[114.084030, 22.361528],
								[114.084862, 22.361528],
								[114.084862, 22.361805],
								[114.086525, 22.361805],
								[114.086525, 22.362083],
								[114.086807, 22.362083],
								[114.086807, 22.362917],
								[114.087364, 22.362917],
								[114.087364, 22.363750],
								[114.088196, 22.363750],
								[114.088196, 22.363474],
								[114.089302, 22.363474],
								[114.089302, 22.363195],
								[114.089584, 22.363194],
								[114.089584, 22.361805],
								[114.089859, 22.361805],
								[114.089859, 22.361528],
								[114.090416, 22.361528],
								[114.090416, 22.361252],
								[114.091530, 22.361252],
								[114.091530, 22.361528],
								[114.091805, 22.361528],
								[114.091805, 22.361805],
								[114.093193, 22.361805],
								[114.093193, 22.362083],
								[114.093475, 22.362083],
								[114.093475, 22.362364],
								[114.093750, 22.362364],
								[114.093750, 22.362638],
								[114.094307, 22.362638],
								[114.094307, 22.362917],
								[114.094582, 22.362917],
								[114.094582, 22.363194],
								[114.097916, 22.363195],
								[114.097916, 22.363474],
								[114.098473, 22.363474],
								[114.098473, 22.363750],
								[114.098747, 22.363750],
								[114.098747, 22.364027],
								[114.100418, 22.364027]
							]
						],
						[
							[
								[114.143875, 22.380638],
								[114.143875, 22.379377],
								[114.145134, 22.379377],
								[114.145920, 22.379181],
								[114.146111, 22.379133],
								[114.146820, 22.378956],
								[114.148926, 22.380219],
								[114.148926, 22.378712],
								[114.148926, 22.377691],
								[114.147659, 22.376431],
								[114.145134, 22.376007],
								[114.144295, 22.373901],
								[114.144814, 22.373125],
								[114.145134, 22.372641],
								[114.146820, 22.370955],
								[114.146339, 22.370668],
								[114.144714, 22.369694],
								[114.143875, 22.367590],
								[114.144203, 22.366924],
								[114.145134, 22.365065],
								[114.145302, 22.364574],
								[114.145561, 22.363800],
								[114.145134, 22.362535],
								[114.143456, 22.361694],
								[114.143028, 22.360434],
								[114.140930, 22.358330],
								[114.140930, 22.356339],
								[114.140930, 22.355383],
								[114.140930, 22.355085],
								[114.140930, 22.354116],
								[114.141350, 22.351170],
								[114.141769, 22.349909],
								[114.142021, 22.349844],
								[114.143456, 22.349485],
								[114.144714, 22.348644],
								[114.144714, 22.347382],
								[114.145981, 22.346539],
								[114.146469, 22.346048],
								[114.147240, 22.345276],
								[114.146553, 22.344587],
								[114.146614, 22.343626],
								[114.146782, 22.343044],
								[114.147659, 22.342751],
								[114.147072, 22.342161],
								[114.146400, 22.341488],
								[114.145134, 22.340645],
								[114.143028, 22.341908],
								[114.141769, 22.341066],
								[114.140503, 22.340645],
								[114.139244, 22.341488],
								[114.137558, 22.341908],
								[114.136299, 22.342329],
								[114.135033, 22.342329],
								[114.134193, 22.341488],
								[114.133347, 22.340223],
								[114.132088, 22.340645],
								[114.130821, 22.340223],
								[114.131668, 22.338543],
								[114.135452, 22.337698],
								[114.135292, 22.337049],
								[114.135719, 22.336266],
								[114.135643, 22.335363],
								[114.135643, 22.334457],
								[114.137558, 22.333910],
								[114.138824, 22.333910],
								[114.140083, 22.333488],
								[114.140083, 22.332226],
								[114.140083, 22.331856],
								[114.140228, 22.331684],
								[114.140305, 22.330406],
								[114.139999, 22.329130],
								[114.139809, 22.328857],
								[114.139175, 22.327930],
								[114.137451, 22.326277],
								[114.135048, 22.324699],
								[114.132645, 22.323198],
								[114.131516, 22.321922],
								[114.130661, 22.321072],
								[114.130821, 22.320436],
								[114.132088, 22.319174],
								[114.132698, 22.317640],
								[114.132362, 22.317636],
								[114.132362, 22.317362],
								[114.131805, 22.317362],
								[114.131805, 22.317083],
								[114.131248, 22.317083],
								[114.131248, 22.316805],
								[114.128197, 22.316805],
								[114.128197, 22.317083],
								[114.127640, 22.317083],
								[114.127640, 22.317362],
								[114.127083, 22.317362],
								[114.127083, 22.317636],
								[114.126526, 22.317636],
								[114.126526, 22.317919],
								[114.126251, 22.317919],
								[114.126251, 22.318195],
								[114.125694, 22.318195],
								[114.125694, 22.318472],
								[114.125420, 22.318472],
								[114.125420, 22.318748],
								[114.124863, 22.318748],
								[114.124863, 22.319029],
								[114.124580, 22.319029],
								[114.124580, 22.319305],
								[114.124031, 22.319305],
								[114.124031, 22.319584],
								[114.123749, 22.319584],
								[114.123749, 22.319859],
								[114.123192, 22.319859],
								[114.123192, 22.320141],
								[114.122917, 22.320141],
								[114.122917, 22.320417],
								[114.122360, 22.320417],
								[114.122360, 22.320694],
								[114.122086, 22.320694],
								[114.122086, 22.320972],
								[114.121803, 22.320972],
								[114.121803, 22.321527],
								[114.121529, 22.321527],
								[114.121529, 22.321806],
								[114.121803, 22.321806],
								[114.121803, 22.322083],
								[114.121529, 22.322083],
								[114.121529, 22.322916],
								[114.121803, 22.322916],
								[114.121803, 22.323471],
								[114.122086, 22.323471],
								[114.122086, 22.324028],
								[114.122360, 22.324028],
								[114.122360, 22.324305],
								[114.122643, 22.324306],
								[114.122643, 22.324862],
								[114.123749, 22.324862],
								[114.123749, 22.324583],
								[114.124031, 22.324583],
								[114.124031, 22.324306],
								[114.124580, 22.324306],
								[114.124580, 22.324583],
								[114.125420, 22.324583],
								[114.125420, 22.324862],
								[114.125687, 22.324862],
								[114.126251, 22.324862],
								[114.126251, 22.324987],
								[114.126251, 22.325138],
								[114.126823, 22.325138],
								[114.127083, 22.325138],
								[114.127083, 22.325417],
								[114.127655, 22.325417],
								[114.128471, 22.325417],
								[114.128471, 22.325693],
								[114.128593, 22.325693],
								[114.128754, 22.325693],
								[114.128754, 22.325972],
								[114.129562, 22.325972],
								[114.129585, 22.326250],
								[114.130417, 22.326250],
								[114.130417, 22.326529],
								[114.131248, 22.326529],
								[114.131248, 22.326805],
								[114.132080, 22.326805],
								[114.132080, 22.327084],
								[114.132919, 22.327084],
								[114.132919, 22.327360],
								[114.133751, 22.327362],
								[114.133751, 22.327639],
								[114.134583, 22.327639],
								[114.134583, 22.328194],
								[114.134308, 22.328194],
								[114.134308, 22.329306],
								[114.134026, 22.329306],
								[114.134026, 22.329584],
								[114.132637, 22.329584],
								[114.132637, 22.329306],
								[114.131805, 22.329306],
								[114.131805, 22.329025],
								[114.130692, 22.329025],
								[114.130692, 22.328751],
								[114.129860, 22.328751],
								[114.129860, 22.328472],
								[114.128754, 22.328472],
								[114.128754, 22.328194],
								[114.127640, 22.328194],
								[114.127640, 22.327915],
								[114.126808, 22.327915],
								[114.126808, 22.328472],
								[114.126251, 22.328472],
								[114.126251, 22.328751],
								[114.125694, 22.328751],
								[114.125694, 22.329584],
								[114.125420, 22.329584],
								[114.125420, 22.330416],
								[114.125137, 22.330420],
								[114.125137, 22.330973],
								[114.124863, 22.330973],
								[114.124863, 22.332359],
								[114.124580, 22.332359],
								[114.124580, 22.333195],
								[114.124306, 22.333195],
								[114.124306, 22.334305],
								[114.124031, 22.334305],
								[114.124031, 22.335974],
								[114.124863, 22.335974],
								[114.124863, 22.336250],
								[114.125969, 22.336250],
								[114.125969, 22.336527],
								[114.126808, 22.336527],
								[114.126808, 22.336805],
								[114.127914, 22.336805],
								[114.127914, 22.337084],
								[114.128082, 22.337084],
								[114.128754, 22.337084],
								[114.128754, 22.337639],
								[114.128471, 22.337639],
								[114.128471, 22.338472],
								[114.128197, 22.338472],
								[114.128197, 22.338751],
								[114.127457, 22.338751],
								[114.127083, 22.338751],
								[114.127083, 22.338472],
								[114.125969, 22.338472],
								[114.125969, 22.338194],
								[114.125137, 22.338194],
								[114.125137, 22.337917],
								[114.124306, 22.337917],
								[114.124306, 22.337639],
								[114.123474, 22.337639],
								[114.123474, 22.337360],
								[114.123192, 22.337360],
								[114.123192, 22.338472],
								[114.122917, 22.338472],
								[114.122917, 22.339031],
								[114.122917, 22.339306],
								[114.122643, 22.339306],
								[114.122643, 22.339443],
								[114.122643, 22.340414],
								[114.122360, 22.340414],
								[114.122360, 22.340694],
								[114.122086, 22.340755],
								[114.122086, 22.341528],
								[114.121803, 22.341528],
								[114.121803, 22.341778],
								[114.121803, 22.342636],
								[114.121529, 22.342636],
								[114.121529, 22.342829],
								[114.121529, 22.343472],
								[114.121246, 22.343534],
								[114.121246, 22.344027],
								[114.120972, 22.344027],
								[114.120972, 22.344223],
								[114.120972, 22.344305],
								[114.120697, 22.344305],
								[114.120697, 22.344862],
								[114.120415, 22.344862],
								[114.120415, 22.345419],
								[114.120140, 22.345419],
								[114.120140, 22.346310],
								[114.120140, 22.346527],
								[114.119858, 22.346527],
								[114.119858, 22.346645],
								[114.119858, 22.347084],
								[114.119583, 22.347084],
								[114.119583, 22.347639],
								[114.119308, 22.347639],
								[114.119308, 22.347918],
								[114.118752, 22.347918],
								[114.118752, 22.347639],
								[114.118469, 22.347639],
								[114.118469, 22.347359],
								[114.118195, 22.347359],
								[114.118195, 22.347084],
								[114.117638, 22.347084],
								[114.117638, 22.346806],
								[114.117363, 22.346806],
								[114.117363, 22.347084],
								[114.117081, 22.347084],
								[114.117081, 22.347359],
								[114.116249, 22.347359],
								[114.116249, 22.347639],
								[114.115974, 22.347639],
								[114.115974, 22.347918],
								[114.115692, 22.347918],
								[114.115692, 22.348194],
								[114.115417, 22.348194],
								[114.115417, 22.348473],
								[114.115135, 22.348473],
								[114.115135, 22.348749],
								[114.114861, 22.348749],
								[114.114861, 22.349028],
								[114.114586, 22.349028],
								[114.114586, 22.349306],
								[114.114304, 22.349306],
								[114.114304, 22.351250],
								[114.114029, 22.351250],
								[114.114029, 22.352638],
								[114.114304, 22.352638],
								[114.114304, 22.352917],
								[114.114586, 22.352917],
								[114.114586, 22.353472],
								[114.114861, 22.353472],
								[114.114861, 22.354029],
								[114.114586, 22.354029],
								[114.114586, 22.355972],
								[114.114304, 22.355972],
								[114.114304, 22.356251],
								[114.114029, 22.356251],
								[114.114029, 22.356806],
								[114.113747, 22.356806],
								[114.113747, 22.357361],
								[114.113472, 22.357361],
								[114.113472, 22.358194],
								[114.113197, 22.358194],
								[114.113197, 22.358747],
								[114.112915, 22.358747],
								[114.112915, 22.359030],
								[114.112358, 22.359030],
								[114.112358, 22.358747],
								[114.112083, 22.358747],
								[114.112083, 22.358473],
								[114.111526, 22.358473],
								[114.111526, 22.359583],
								[114.110970, 22.359583],
								[114.110970, 22.360416],
								[114.111252, 22.360416],
								[114.111252, 22.360695],
								[114.111526, 22.360695],
								[114.111526, 22.361677],
								[114.111839, 22.361538],
								[114.111900, 22.361782],
								[114.112099, 22.361767],
								[114.113144, 22.362118],
								[114.114822, 22.362959],
								[114.116089, 22.363800],
								[114.116783, 22.364079],
								[114.118195, 22.364641],
								[114.119873, 22.365482],
								[114.120369, 22.366346],
								[114.121559, 22.368429],
								[114.122055, 22.369091],
								[114.122665, 22.369905],
								[114.122826, 22.370113],
								[114.124084, 22.371799],
								[114.125351, 22.371799],
								[114.126610, 22.371799],
								[114.128296, 22.371799],
								[114.129158, 22.372143],
								[114.129448, 22.372259],
								[114.130402, 22.372641],
								[114.136124, 22.372641],
								[114.136230, 22.372969],
								[114.136032, 22.373976],
								[114.135872, 22.374746],
								[114.135872, 22.376007],
								[114.135872, 22.377272],
								[114.136017, 22.377493],
								[114.136719, 22.378532],
								[114.137138, 22.379799],
								[114.136719, 22.381062],
								[114.135033, 22.381483],
								[114.135033, 22.382746],
								[114.136177, 22.383030],
								[114.136322, 22.383068],
								[114.136719, 22.383165],
								[114.137390, 22.383335],
								[114.137978, 22.383482],
								[114.138397, 22.383587],
								[114.139664, 22.383587],
								[114.139938, 22.383656],
								[114.140259, 22.383738],
								[114.141022, 22.383968],
								[114.141884, 22.384548],
								[114.142189, 22.384850],
								[114.143875, 22.384850],
								[114.144714, 22.384008],
								[114.144562, 22.383854],
								[114.143875, 22.383165],
								[114.143501, 22.382050],
								[114.143501, 22.381756],
								[114.143616, 22.381418],
								[114.143707, 22.381140],
								[114.143784, 22.380903],
								[114.143875, 22.380638]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Kwun Tong",
					"ID_0": 102,
					"ID_1": 6,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.223022, 22.333910],
							[114.225548, 22.333910],
							[114.227654, 22.334330],
							[114.228912, 22.334330],
							[114.229340, 22.333067],
							[114.229759, 22.331804],
							[114.231865, 22.328436],
							[114.232285, 22.326752],
							[114.233124, 22.323805],
							[114.234390, 22.322542],
							[114.236069, 22.322542],
							[114.238174, 22.321280],
							[114.239441, 22.320436],
							[114.241127, 22.320436],
							[114.241966, 22.321701],
							[114.243233, 22.321280],
							[114.242805, 22.319174],
							[114.242386, 22.317911],
							[114.240280, 22.315805],
							[114.241966, 22.315386],
							[114.243233, 22.315386],
							[114.244911, 22.313702],
							[114.244072, 22.312439],
							[114.243652, 22.311174],
							[114.241966, 22.309912],
							[114.241547, 22.308647],
							[114.240280, 22.308228],
							[114.239441, 22.307386],
							[114.239861, 22.306124],
							[114.240280, 22.304859],
							[114.241966, 22.304440],
							[114.242386, 22.303177],
							[114.243652, 22.302755],
							[114.243652, 22.301493],
							[114.243652, 22.300228],
							[114.244911, 22.298964],
							[114.244911, 22.297703],
							[114.245331, 22.296438],
							[114.245331, 22.295176],
							[114.244072, 22.295176],
							[114.241966, 22.296021],
							[114.241127, 22.294758],
							[114.241966, 22.293915],
							[114.241127, 22.293074],
							[114.241547, 22.291388],
							[114.242386, 22.290546],
							[114.240700, 22.288443],
							[114.241966, 22.287601],
							[114.242386, 22.285915],
							[114.243752, 22.286257],
							[114.243752, 22.285973],
							[114.243195, 22.285973],
							[114.243195, 22.285694],
							[114.242920, 22.285694],
							[114.242920, 22.285418],
							[114.241806, 22.285418],
							[114.241806, 22.285694],
							[114.240417, 22.285694],
							[114.240417, 22.285973],
							[114.239304, 22.285973],
							[114.239304, 22.286249],
							[114.238747, 22.286249],
							[114.238747, 22.286528],
							[114.237640, 22.286528],
							[114.237640, 22.286804],
							[114.237358, 22.286804],
							[114.237358, 22.287361],
							[114.237083, 22.287361],
							[114.237083, 22.287640],
							[114.236809, 22.287640],
							[114.236809, 22.289862],
							[114.237076, 22.289862],
							[114.237083, 22.290136],
							[114.236809, 22.290136],
							[114.236809, 22.290419],
							[114.236526, 22.290419],
							[114.236526, 22.290695],
							[114.236252, 22.290695],
							[114.236252, 22.290972],
							[114.235695, 22.290972],
							[114.235695, 22.291248],
							[114.235420, 22.291248],
							[114.235420, 22.291531],
							[114.235138, 22.291531],
							[114.235138, 22.291805],
							[114.234863, 22.291805],
							[114.234863, 22.292084],
							[114.234581, 22.292084],
							[114.234581, 22.292358],
							[114.234306, 22.292358],
							[114.234306, 22.292641],
							[114.234024, 22.292641],
							[114.234024, 22.292917],
							[114.233749, 22.292917],
							[114.233749, 22.293194],
							[114.233192, 22.293194],
							[114.233192, 22.293472],
							[114.232918, 22.293472],
							[114.232918, 22.293751],
							[114.232643, 22.293751],
							[114.232635, 22.294027],
							[114.232361, 22.294027],
							[114.232361, 22.294306],
							[114.232086, 22.294306],
							[114.232086, 22.294863],
							[114.231804, 22.294863],
							[114.231804, 22.295139],
							[114.231529, 22.295139],
							[114.231529, 22.295694],
							[114.231247, 22.295694],
							[114.231247, 22.296249],
							[114.230972, 22.296249],
							[114.230972, 22.296528],
							[114.230698, 22.296528],
							[114.230698, 22.297085],
							[114.230415, 22.297085],
							[114.230415, 22.297638],
							[114.230141, 22.297640],
							[114.230141, 22.297916],
							[114.229897, 22.297916],
							[114.229858, 22.298195],
							[114.229660, 22.298195],
							[114.229584, 22.298195],
							[114.229584, 22.298471],
							[114.229294, 22.298471],
							[114.229027, 22.298471],
							[114.229027, 22.298750],
							[114.228920, 22.298750],
							[114.228752, 22.298750],
							[114.228752, 22.298878],
							[114.228752, 22.299028],
							[114.228546, 22.299028],
							[114.228470, 22.299028],
							[114.228470, 22.299303],
							[114.228195, 22.299303],
							[114.228195, 22.299583],
							[114.227913, 22.299656],
							[114.227913, 22.299862],
							[114.227364, 22.299862],
							[114.227364, 22.300138],
							[114.227081, 22.300138],
							[114.227081, 22.300417],
							[114.226807, 22.300417],
							[114.226807, 22.300694],
							[114.226540, 22.300695],
							[114.226524, 22.300972],
							[114.226250, 22.300972],
							[114.226250, 22.301250],
							[114.225693, 22.301250],
							[114.225693, 22.301525],
							[114.225418, 22.301525],
							[114.225418, 22.301805],
							[114.225151, 22.301805],
							[114.225136, 22.302084],
							[114.224861, 22.302084],
							[114.224861, 22.302361],
							[114.224304, 22.302361],
							[114.224304, 22.302637],
							[114.224030, 22.302637],
							[114.224030, 22.302919],
							[114.223747, 22.302919],
							[114.223747, 22.303194],
							[114.223473, 22.303194],
							[114.223473, 22.303473],
							[114.223198, 22.303473],
							[114.223198, 22.303747],
							[114.222916, 22.303747],
							[114.222916, 22.304029],
							[114.222641, 22.304029],
							[114.222641, 22.304308],
							[114.222359, 22.304308],
							[114.222359, 22.305138],
							[114.222084, 22.305138],
							[114.222084, 22.305416],
							[114.221809, 22.305416],
							[114.221802, 22.305754],
							[114.221802, 22.305973],
							[114.221252, 22.305973],
							[114.221252, 22.306252],
							[114.220970, 22.306252],
							[114.220970, 22.306528],
							[114.220695, 22.306528],
							[114.220695, 22.307083],
							[114.220139, 22.307083],
							[114.220139, 22.307362],
							[114.219864, 22.307362],
							[114.219864, 22.307917],
							[114.219582, 22.307917],
							[114.219582, 22.308195],
							[114.219307, 22.308195],
							[114.219307, 22.307920],
							[114.219025, 22.307917],
							[114.219025, 22.308195],
							[114.218750, 22.308195],
							[114.218750, 22.308750],
							[114.218475, 22.308750],
							[114.218475, 22.309029],
							[114.217918, 22.309029],
							[114.217918, 22.309305],
							[114.217636, 22.309305],
							[114.217636, 22.309860],
							[114.217361, 22.309860],
							[114.217361, 22.310972],
							[114.217087, 22.310972],
							[114.217087, 22.311527],
							[114.216805, 22.311527],
							[114.216805, 22.311806],
							[114.216530, 22.311806],
							[114.216469, 22.312084],
							[114.215973, 22.312084],
							[114.215973, 22.312361],
							[114.215141, 22.312361],
							[114.215141, 22.312639],
							[114.214302, 22.312639],
							[114.214302, 22.312916],
							[114.213760, 22.312916],
							[114.213753, 22.313194],
							[114.213432, 22.313194],
							[114.210136, 22.313194],
							[114.210136, 22.313749],
							[114.209862, 22.313749],
							[114.209862, 22.314232],
							[114.209862, 22.315140],
							[114.209587, 22.315140],
							[114.209587, 22.315416],
							[114.209305, 22.315416],
							[114.209305, 22.315535],
							[114.209671, 22.315626],
							[114.210388, 22.315805],
							[114.209549, 22.317490],
							[114.208710, 22.318333],
							[114.207863, 22.319174],
							[114.206604, 22.320436],
							[114.204132, 22.322632],
							[114.202812, 22.323805],
							[114.203735, 22.324730],
							[114.204918, 22.325911],
							[114.204918, 22.327864],
							[114.204918, 22.334751],
							[114.206184, 22.334330],
							[114.207443, 22.334330],
							[114.209129, 22.334330],
							[114.211235, 22.335173],
							[114.212303, 22.334103],
							[114.212921, 22.333488],
							[114.214180, 22.332645],
							[114.215446, 22.332645],
							[114.217125, 22.333067],
							[114.219650, 22.333488],
							[114.221756, 22.333910],
							[114.222900, 22.333910],
							[114.223022, 22.333910]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "North",
					"ID_0": 102,
					"ID_1": 7,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.286530, 22.521252],
								[114.286530, 22.520969],
								[114.286804, 22.520969],
								[114.286804, 22.520695],
								[114.288193, 22.520695],
								[114.288193, 22.520416],
								[114.288750, 22.520416],
								[114.288750, 22.519306],
								[114.288475, 22.519306],
								[114.288475, 22.519030],
								[114.285973, 22.519030],
								[114.285973, 22.519306],
								[114.285416, 22.519306],
								[114.285416, 22.519583],
								[114.285141, 22.519583],
								[114.285141, 22.520695],
								[114.284859, 22.520695],
								[114.284859, 22.520969],
								[114.285141, 22.520969],
								[114.285141, 22.521252],
								[114.286530, 22.521252]
							]
						],
						[
							[
								[114.320137, 22.537361],
								[114.320137, 22.536528],
								[114.320419, 22.536528],
								[114.320419, 22.535950],
								[114.320419, 22.535418],
								[114.320694, 22.535418],
								[114.320694, 22.534863],
								[114.320969, 22.534863],
								[114.320969, 22.534306],
								[114.321251, 22.534306],
								[114.321251, 22.533749],
								[114.321526, 22.533749],
								[114.321526, 22.531530],
								[114.320969, 22.531530],
								[114.320969, 22.531248],
								[114.320419, 22.531248],
								[114.320419, 22.529028],
								[114.320137, 22.529028],
								[114.320137, 22.527082],
								[114.320419, 22.527082],
								[114.320419, 22.526527],
								[114.320694, 22.526527],
								[114.320694, 22.525972],
								[114.320969, 22.525972],
								[114.320969, 22.525696],
								[114.321251, 22.525696],
								[114.321251, 22.525139],
								[114.321526, 22.525139],
								[114.321526, 22.524584],
								[114.321808, 22.524584],
								[114.321808, 22.524305],
								[114.320969, 22.524305],
								[114.320969, 22.523474],
								[114.320694, 22.523474],
								[114.320694, 22.523195],
								[114.320328, 22.523195],
								[114.320137, 22.523195],
								[114.320137, 22.522917],
								[114.318748, 22.522917],
								[114.318748, 22.523195],
								[114.318474, 22.523195],
								[114.318474, 22.523474],
								[114.318748, 22.523474],
								[114.318748, 22.523750],
								[114.318474, 22.523750],
								[114.318474, 22.524305],
								[114.318192, 22.524305],
								[114.318192, 22.524584],
								[114.317360, 22.524584],
								[114.317360, 22.524305],
								[114.316254, 22.524305],
								[114.316254, 22.524584],
								[114.315681, 22.524584],
								[114.315414, 22.524584],
								[114.315414, 22.524849],
								[114.315140, 22.524860],
								[114.315140, 22.525124],
								[114.315140, 22.525417],
								[114.314583, 22.525417],
								[114.314583, 22.525681],
								[114.314026, 22.525696],
								[114.314026, 22.525972],
								[114.313751, 22.525972],
								[114.313751, 22.526251],
								[114.313644, 22.526251],
								[114.313469, 22.526251],
								[114.313469, 22.526527],
								[114.313194, 22.526527],
								[114.313194, 22.526806],
								[114.312538, 22.526806],
								[114.312363, 22.526806],
								[114.312363, 22.527082],
								[114.312080, 22.527082],
								[114.312080, 22.527361],
								[114.311531, 22.527361],
								[114.311531, 22.527639],
								[114.310974, 22.527639],
								[114.310974, 22.527914],
								[114.310417, 22.527914],
								[114.310417, 22.528194],
								[114.309029, 22.528194],
								[114.309029, 22.528749],
								[114.308197, 22.528749],
								[114.308197, 22.531248],
								[114.307915, 22.531248],
								[114.307915, 22.532640],
								[114.308197, 22.532640],
								[114.308197, 22.532919],
								[114.307915, 22.532919],
								[114.307915, 22.533194],
								[114.308197, 22.533194],
								[114.308197, 22.533470],
								[114.308472, 22.533470],
								[114.308472, 22.534027],
								[114.308746, 22.534027],
								[114.308746, 22.534584],
								[114.309029, 22.534584],
								[114.309029, 22.535139],
								[114.309586, 22.535139],
								[114.309586, 22.534863],
								[114.309860, 22.534863],
								[114.309860, 22.534584],
								[114.310417, 22.534584],
								[114.310417, 22.534306],
								[114.310974, 22.534306],
								[114.310974, 22.534027],
								[114.311806, 22.534027],
								[114.311806, 22.533749],
								[114.312363, 22.533749],
								[114.312363, 22.533470],
								[114.312637, 22.533470],
								[114.312637, 22.532919],
								[114.312920, 22.532919],
								[114.312920, 22.532360],
								[114.313194, 22.532360],
								[114.313194, 22.532084],
								[114.313469, 22.532084],
								[114.313469, 22.531530],
								[114.313751, 22.531530],
								[114.313751, 22.531197],
								[114.313751, 22.530972],
								[114.314026, 22.530972],
								[114.314026, 22.530416],
								[114.314308, 22.530416],
								[114.314308, 22.529861],
								[114.314583, 22.529861],
								[114.314583, 22.529306],
								[114.315140, 22.529306],
								[114.315140, 22.529028],
								[114.316528, 22.529028],
								[114.316528, 22.529306],
								[114.317085, 22.529306],
								[114.317085, 22.529583],
								[114.317360, 22.529583],
								[114.317360, 22.530416],
								[114.317642, 22.530416],
								[114.317642, 22.530972],
								[114.317917, 22.530972],
								[114.317917, 22.531805],
								[114.318192, 22.531805],
								[114.318192, 22.532640],
								[114.317917, 22.532640],
								[114.317917, 22.533470],
								[114.317642, 22.533470],
								[114.317642, 22.534584],
								[114.317360, 22.534584],
								[114.317360, 22.535694],
								[114.317642, 22.535694],
								[114.317642, 22.536528],
								[114.317917, 22.536528],
								[114.317917, 22.536806],
								[114.318192, 22.536806],
								[114.318192, 22.537083],
								[114.318474, 22.537083],
								[114.318474, 22.537361],
								[114.320137, 22.537361]
							]
						],
						[
							[
								[114.226250, 22.540138],
								[114.226250, 22.539862],
								[114.226524, 22.539862],
								[114.226524, 22.539305],
								[114.226807, 22.539305],
								[114.226807, 22.539028],
								[114.227081, 22.539028],
								[114.227081, 22.538750],
								[114.226807, 22.538750],
								[114.226807, 22.538195],
								[114.227081, 22.538195],
								[114.227081, 22.537916],
								[114.226524, 22.537916],
								[114.226524, 22.537361],
								[114.226250, 22.537361],
								[114.226250, 22.536810],
								[114.225693, 22.536806],
								[114.225693, 22.536530],
								[114.225136, 22.536528],
								[114.225136, 22.536251],
								[114.224861, 22.536249],
								[114.224861, 22.535973],
								[114.222923, 22.535973],
								[114.222916, 22.536249],
								[114.222641, 22.536249],
								[114.222641, 22.536806],
								[114.222359, 22.536806],
								[114.222359, 22.537083],
								[114.222084, 22.537083],
								[114.222084, 22.537361],
								[114.221802, 22.537361],
								[114.221802, 22.537916],
								[114.221527, 22.537916],
								[114.221527, 22.538473],
								[114.222084, 22.538473],
								[114.222084, 22.538750],
								[114.223747, 22.538750],
								[114.223747, 22.539028],
								[114.224030, 22.539028],
								[114.224030, 22.539305],
								[114.224586, 22.539305],
								[114.224586, 22.539583],
								[114.225418, 22.539583],
								[114.225418, 22.539862],
								[114.225975, 22.539862],
								[114.225975, 22.540138],
								[114.226250, 22.540138]
							]
						],
						[
							[
								[114.308746, 22.542084],
								[114.308746, 22.541805],
								[114.309303, 22.541805],
								[114.309303, 22.541529],
								[114.309860, 22.541529],
								[114.309860, 22.541250],
								[114.310143, 22.541250],
								[114.310143, 22.540972],
								[114.310417, 22.540972],
								[114.310417, 22.540417],
								[114.310692, 22.540417],
								[114.310692, 22.540140],
								[114.310417, 22.540138],
								[114.310417, 22.539862],
								[114.309860, 22.539862],
								[114.309860, 22.539583],
								[114.309303, 22.539583],
								[114.309303, 22.539305],
								[114.309029, 22.539305],
								[114.309029, 22.539028],
								[114.308746, 22.539028],
								[114.308746, 22.538750],
								[114.308472, 22.538750],
								[114.308472, 22.538473],
								[114.308197, 22.538473],
								[114.308197, 22.538195],
								[114.307640, 22.538195],
								[114.307640, 22.537640],
								[114.307083, 22.537640],
								[114.307083, 22.537361],
								[114.306808, 22.537361],
								[114.306808, 22.537083],
								[114.305695, 22.537083],
								[114.305695, 22.537361],
								[114.305138, 22.537361],
								[114.305138, 22.537916],
								[114.305420, 22.537916],
								[114.305420, 22.538473],
								[114.305138, 22.538473],
								[114.305138, 22.540138],
								[114.304863, 22.540138],
								[114.304863, 22.540417],
								[114.305389, 22.540417],
								[114.305420, 22.540972],
								[114.305687, 22.540972],
								[114.305695, 22.541250],
								[114.306252, 22.541250],
								[114.306252, 22.541529],
								[114.306526, 22.541529],
								[114.306526, 22.541805],
								[114.307083, 22.541805],
								[114.307083, 22.542084],
								[114.308746, 22.542084]
							]
						],
						[
							[
								[114.330696, 22.542639],
								[114.330696, 22.542360],
								[114.330971, 22.542360],
								[114.330971, 22.542084],
								[114.331253, 22.542084],
								[114.331253, 22.541250],
								[114.331802, 22.541250],
								[114.331802, 22.540972],
								[114.331528, 22.540972],
								[114.331528, 22.540417],
								[114.331253, 22.540417],
								[114.331253, 22.540012],
								[114.331253, 22.539583],
								[114.330696, 22.539583],
								[114.330696, 22.539305],
								[114.330139, 22.539305],
								[114.330139, 22.539862],
								[114.329865, 22.539862],
								[114.329865, 22.540695],
								[114.329308, 22.540695],
								[114.329308, 22.541250],
								[114.329025, 22.541250],
								[114.329025, 22.542084],
								[114.329308, 22.542084],
								[114.329308, 22.542360],
								[114.329582, 22.542360],
								[114.329582, 22.542639],
								[114.330696, 22.542639]
							]
						],
						[
							[
								[114.293198, 22.555140],
								[114.293198, 22.554861],
								[114.293472, 22.554861],
								[114.293472, 22.554583],
								[114.294029, 22.554583],
								[114.294029, 22.554028],
								[114.293747, 22.554028],
								[114.293747, 22.553196],
								[114.293472, 22.553196],
								[114.293472, 22.552361],
								[114.293198, 22.552361],
								[114.293198, 22.552082],
								[114.293465, 22.552082],
								[114.293472, 22.551805],
								[114.293747, 22.551805],
								[114.293747, 22.551527],
								[114.294029, 22.551527],
								[114.294029, 22.551250],
								[114.297081, 22.551250],
								[114.297081, 22.550974],
								[114.300003, 22.550974],
								[114.300415, 22.550974],
								[114.300415, 22.550695],
								[114.304306, 22.550695],
								[114.304306, 22.550974],
								[114.304863, 22.550974],
								[114.304863, 22.551250],
								[114.307083, 22.551250],
								[114.307083, 22.550974],
								[114.308296, 22.550974],
								[114.308472, 22.550974],
								[114.308472, 22.550695],
								[114.309860, 22.550694],
								[114.309860, 22.550417],
								[114.313469, 22.550417],
								[114.313469, 22.550138],
								[114.316803, 22.550138],
								[114.316803, 22.549864],
								[114.315140, 22.549864],
								[114.315140, 22.549583],
								[114.314857, 22.549583],
								[114.314857, 22.549028],
								[114.314583, 22.549028],
								[114.314583, 22.548752],
								[114.314308, 22.548752],
								[114.314308, 22.548470],
								[114.314026, 22.548470],
								[114.314026, 22.548195],
								[114.313751, 22.548195],
								[114.313751, 22.547916],
								[114.313469, 22.547916],
								[114.313469, 22.547649],
								[114.312363, 22.547640],
								[114.312363, 22.547359],
								[114.311531, 22.547359],
								[114.311531, 22.546806],
								[114.311249, 22.546806],
								[114.311249, 22.547083],
								[114.310692, 22.547083],
								[114.310692, 22.547359],
								[114.310417, 22.547359],
								[114.310417, 22.547640],
								[114.309860, 22.547640],
								[114.309860, 22.547916],
								[114.309608, 22.547916],
								[114.309586, 22.548195],
								[114.309029, 22.548195],
								[114.309029, 22.548470],
								[114.308197, 22.548470],
								[114.308197, 22.547916],
								[114.307915, 22.547916],
								[114.307915, 22.547359],
								[114.308197, 22.547359],
								[114.308197, 22.547083],
								[114.307915, 22.547083],
								[114.307915, 22.546806],
								[114.306252, 22.546806],
								[114.306252, 22.547083],
								[114.305420, 22.547083],
								[114.305420, 22.546806],
								[114.305138, 22.546806],
								[114.305138, 22.545416],
								[114.304581, 22.545416],
								[114.304581, 22.544861],
								[114.304306, 22.544861],
								[114.304306, 22.544584],
								[114.303749, 22.544582],
								[114.303749, 22.544306],
								[114.303192, 22.544306],
								[114.303192, 22.544582],
								[114.302917, 22.544584],
								[114.302917, 22.544861],
								[114.302643, 22.544861],
								[114.302635, 22.545137],
								[114.302361, 22.545137],
								[114.302361, 22.545416],
								[114.302086, 22.545416],
								[114.302086, 22.545694],
								[114.301804, 22.545694],
								[114.301804, 22.545973],
								[114.301529, 22.545973],
								[114.301529, 22.546247],
								[114.300972, 22.546247],
								[114.300972, 22.546530],
								[114.300415, 22.546530],
								[114.300415, 22.546806],
								[114.300140, 22.546806],
								[114.300140, 22.547083],
								[114.299858, 22.547083],
								[114.299858, 22.547359],
								[114.299583, 22.547359],
								[114.299583, 22.547640],
								[114.299309, 22.547640],
								[114.299309, 22.547916],
								[114.299049, 22.547916],
								[114.299026, 22.548195],
								[114.298752, 22.548195],
								[114.298752, 22.548470],
								[114.298195, 22.548470],
								[114.298195, 22.548752],
								[114.297920, 22.548752],
								[114.297920, 22.548470],
								[114.297638, 22.548470],
								[114.297638, 22.548195],
								[114.296806, 22.548195],
								[114.296806, 22.547916],
								[114.296524, 22.547916],
								[114.296524, 22.548195],
								[114.296249, 22.548195],
								[114.296249, 22.548470],
								[114.295975, 22.548470],
								[114.295975, 22.548195],
								[114.295692, 22.548195],
								[114.295692, 22.547916],
								[114.295418, 22.547916],
								[114.295418, 22.547649],
								[114.295143, 22.547640],
								[114.295135, 22.547359],
								[114.294861, 22.547359],
								[114.294861, 22.547083],
								[114.294586, 22.547083],
								[114.294586, 22.546530],
								[114.294304, 22.546530],
								[114.294304, 22.545973],
								[114.294029, 22.545973],
								[114.294029, 22.544027],
								[114.294296, 22.544027],
								[114.294304, 22.543751],
								[114.295135, 22.543751],
								[114.295135, 22.543480],
								[114.295975, 22.543472],
								[114.295975, 22.543194],
								[114.297081, 22.543194],
								[114.297081, 22.542360],
								[114.297363, 22.542360],
								[114.297363, 22.541250],
								[114.297638, 22.541250],
								[114.297638, 22.540695],
								[114.297920, 22.540695],
								[114.297920, 22.540140],
								[114.298187, 22.540138],
								[114.298195, 22.539862],
								[114.298470, 22.539862],
								[114.298470, 22.539583],
								[114.298752, 22.539583],
								[114.298752, 22.539305],
								[114.299309, 22.539305],
								[114.299309, 22.539028],
								[114.299576, 22.539028],
								[114.299583, 22.538750],
								[114.299858, 22.538750],
								[114.299858, 22.538473],
								[114.300140, 22.538473],
								[114.300140, 22.538195],
								[114.300415, 22.538195],
								[114.300415, 22.537916],
								[114.300972, 22.537916],
								[114.300972, 22.537640],
								[114.301247, 22.537640],
								[114.301247, 22.537361],
								[114.301529, 22.537361],
								[114.301529, 22.537083],
								[114.302086, 22.537083],
								[114.302086, 22.536806],
								[114.302361, 22.536806],
								[114.302361, 22.536530],
								[114.302917, 22.536528],
								[114.302917, 22.536251],
								[114.303749, 22.536249],
								[114.303749, 22.536528],
								[114.304306, 22.536528],
								[114.304306, 22.536806],
								[114.305420, 22.536806],
								[114.305420, 22.536249],
								[114.305687, 22.536249],
								[114.305695, 22.535694],
								[114.305946, 22.535694],
								[114.305969, 22.535139],
								[114.305695, 22.535139],
								[114.305695, 22.534306],
								[114.305420, 22.534306],
								[114.305420, 22.533749],
								[114.305138, 22.533749],
								[114.305138, 22.533194],
								[114.304863, 22.533194],
								[114.304863, 22.532919],
								[114.304581, 22.532919],
								[114.304581, 22.532640],
								[114.304031, 22.532640],
								[114.304031, 22.532360],
								[114.304306, 22.532360],
								[114.304306, 22.532084],
								[114.304031, 22.532084],
								[114.304031, 22.531805],
								[114.303749, 22.531805],
								[114.303749, 22.532084],
								[114.302917, 22.532084],
								[114.302917, 22.532360],
								[114.300972, 22.532360],
								[114.300972, 22.532084],
								[114.300140, 22.532084],
								[114.300140, 22.532360],
								[114.299583, 22.532360],
								[114.299583, 22.532919],
								[114.299309, 22.532919],
								[114.299309, 22.533470],
								[114.299583, 22.533470],
								[114.299583, 22.534584],
								[114.299858, 22.534584],
								[114.299858, 22.535139],
								[114.299583, 22.535139],
								[114.299583, 22.535418],
								[114.299347, 22.535418],
								[114.299309, 22.535694],
								[114.298195, 22.535694],
								[114.298195, 22.535418],
								[114.298035, 22.535418],
								[114.297081, 22.535418],
								[114.297081, 22.535143],
								[114.296806, 22.535139],
								[114.296806, 22.534863],
								[114.296249, 22.534863],
								[114.296249, 22.534584],
								[114.295692, 22.534584],
								[114.295692, 22.534306],
								[114.295418, 22.534306],
								[114.295418, 22.533749],
								[114.294861, 22.533749],
								[114.294861, 22.533470],
								[114.293747, 22.533470],
								[114.293747, 22.533749],
								[114.291527, 22.533749],
								[114.291527, 22.533470],
								[114.290970, 22.533470],
								[114.290970, 22.533194],
								[114.290138, 22.533194],
								[114.290138, 22.533470],
								[114.288750, 22.533470],
								[114.288750, 22.533194],
								[114.287636, 22.533194],
								[114.287636, 22.532640],
								[114.287315, 22.532640],
								[114.285973, 22.532640],
								[114.285973, 22.532919],
								[114.285698, 22.532919],
								[114.285698, 22.533194],
								[114.285416, 22.533194],
								[114.285416, 22.534584],
								[114.285698, 22.534584],
								[114.285698, 22.534863],
								[114.285973, 22.534863],
								[114.285973, 22.535139],
								[114.286530, 22.535139],
								[114.286530, 22.535418],
								[114.286766, 22.535418],
								[114.286804, 22.535694],
								[114.287086, 22.535694],
								[114.287086, 22.535973],
								[114.287361, 22.535973],
								[114.287361, 22.536249],
								[114.287636, 22.536249],
								[114.287636, 22.537083],
								[114.287361, 22.537083],
								[114.287361, 22.538195],
								[114.287636, 22.538195],
								[114.287636, 22.538473],
								[114.290138, 22.538473],
								[114.290138, 22.538750],
								[114.290413, 22.538750],
								[114.290413, 22.539028],
								[114.290695, 22.539028],
								[114.290695, 22.539305],
								[114.290970, 22.539305],
								[114.290970, 22.539583],
								[114.291252, 22.539583],
								[114.291252, 22.539862],
								[114.292084, 22.539862],
								[114.292084, 22.539583],
								[114.291809, 22.539583],
								[114.291809, 22.539028],
								[114.291527, 22.539028],
								[114.291527, 22.538473],
								[114.292915, 22.538473],
								[114.292915, 22.538750],
								[114.293472, 22.538750],
								[114.293472, 22.539028],
								[114.293747, 22.539028],
								[114.293747, 22.539305],
								[114.294029, 22.539305],
								[114.294029, 22.539862],
								[114.293747, 22.539862],
								[114.293747, 22.540138],
								[114.293472, 22.540138],
								[114.293472, 22.540695],
								[114.293198, 22.540695],
								[114.293198, 22.540972],
								[114.292648, 22.540972],
								[114.292641, 22.541805],
								[114.292084, 22.541805],
								[114.292084, 22.542084],
								[114.291809, 22.542084],
								[114.291809, 22.542639],
								[114.291252, 22.542639],
								[114.291252, 22.542915],
								[114.290695, 22.542915],
								[114.290695, 22.543751],
								[114.290138, 22.543751],
								[114.290138, 22.544027],
								[114.289864, 22.544027],
								[114.289864, 22.544306],
								[114.289307, 22.544306],
								[114.289307, 22.544582],
								[114.288750, 22.544584],
								[114.288750, 22.544861],
								[114.288483, 22.544861],
								[114.288475, 22.545416],
								[114.288193, 22.545416],
								[114.288193, 22.545973],
								[114.287636, 22.545973],
								[114.287636, 22.546247],
								[114.287086, 22.546247],
								[114.287086, 22.546530],
								[114.286804, 22.546530],
								[114.286804, 22.546806],
								[114.286247, 22.546806],
								[114.286247, 22.547916],
								[114.286766, 22.547916],
								[114.286804, 22.548195],
								[114.287636, 22.548195],
								[114.287636, 22.547916],
								[114.288750, 22.547916],
								[114.288750, 22.548195],
								[114.289017, 22.548195],
								[114.289024, 22.548752],
								[114.289581, 22.548752],
								[114.289581, 22.549028],
								[114.289383, 22.549028],
								[114.289307, 22.549028],
								[114.289307, 22.549583],
								[114.289024, 22.549583],
								[114.289024, 22.549864],
								[114.288483, 22.549864],
								[114.288475, 22.550138],
								[114.288193, 22.550138],
								[114.288193, 22.550694],
								[114.287918, 22.550694],
								[114.287918, 22.550974],
								[114.287636, 22.550974],
								[114.287636, 22.551805],
								[114.287895, 22.551805],
								[114.287918, 22.553473],
								[114.288193, 22.553473],
								[114.288193, 22.553749],
								[114.288475, 22.553749],
								[114.288475, 22.553473],
								[114.288750, 22.553473],
								[114.288750, 22.554028],
								[114.289024, 22.554028],
								[114.289024, 22.554304],
								[114.289581, 22.554304],
								[114.289581, 22.554583],
								[114.289856, 22.554583],
								[114.289864, 22.555140],
								[114.293198, 22.555140]
							]
						],
						[
							[
								[114.163239, 22.561670],
								[114.164505, 22.559986],
								[114.165764, 22.559986],
								[114.167450, 22.561670],
								[114.169975, 22.561670],
								[114.170815, 22.560829],
								[114.172501, 22.560410],
								[114.175446, 22.560410],
								[114.177063, 22.561214],
								[114.177132, 22.561251],
								[114.177261, 22.561123],
								[114.178818, 22.559565],
								[114.179100, 22.557594],
								[114.179237, 22.556618],
								[114.180923, 22.555777],
								[114.183449, 22.555777],
								[114.186050, 22.556150],
								[114.186394, 22.556198],
								[114.187660, 22.555777],
								[114.188477, 22.555504],
								[114.188919, 22.555355],
								[114.189316, 22.555355],
								[114.190605, 22.555355],
								[114.191017, 22.555218],
								[114.191864, 22.554934],
								[114.193130, 22.555777],
								[114.194168, 22.555925],
								[114.195404, 22.556103],
								[114.196075, 22.556198],
								[114.197762, 22.557039],
								[114.199028, 22.557039],
								[114.200706, 22.557463],
								[114.200905, 22.557526],
								[114.201973, 22.557880],
								[114.203590, 22.556667],
								[114.203659, 22.556618],
								[114.204918, 22.556198],
								[114.205521, 22.556599],
								[114.206184, 22.557039],
								[114.207932, 22.556690],
								[114.208290, 22.556618],
								[114.209549, 22.556198],
								[114.212761, 22.555660],
								[114.214600, 22.555355],
								[114.215813, 22.555355],
								[114.217552, 22.555355],
								[114.220100, 22.553593],
								[114.221237, 22.552805],
								[114.223022, 22.551567],
								[114.225128, 22.551146],
								[114.228493, 22.547777],
								[114.228378, 22.547607],
								[114.226807, 22.545252],
								[114.226807, 22.543472],
								[114.226807, 22.543194],
								[114.225975, 22.543194],
								[114.225975, 22.542915],
								[114.224861, 22.542915],
								[114.224861, 22.542360],
								[114.224586, 22.542360],
								[114.224586, 22.542639],
								[114.224304, 22.542639],
								[114.224304, 22.543194],
								[114.224030, 22.543194],
								[114.224030, 22.543472],
								[114.223747, 22.543472],
								[114.223747, 22.543751],
								[114.222588, 22.543751],
								[114.221252, 22.543751],
								[114.221252, 22.543472],
								[114.220970, 22.543472],
								[114.220970, 22.543751],
								[114.220139, 22.543751],
								[114.220139, 22.543472],
								[114.219887, 22.543472],
								[114.219582, 22.543472],
								[114.219582, 22.543194],
								[114.218475, 22.543194],
								[114.218475, 22.542915],
								[114.218193, 22.542915],
								[114.218193, 22.542360],
								[114.217918, 22.542360],
								[114.217918, 22.542084],
								[114.217636, 22.542084],
								[114.217636, 22.541805],
								[114.217361, 22.541805],
								[114.217361, 22.541250],
								[114.216805, 22.541250],
								[114.216805, 22.540972],
								[114.216248, 22.540972],
								[114.216248, 22.540695],
								[114.215973, 22.540695],
								[114.215973, 22.539862],
								[114.215698, 22.539862],
								[114.215691, 22.539028],
								[114.215416, 22.539028],
								[114.215416, 22.537640],
								[114.215141, 22.537640],
								[114.215141, 22.536528],
								[114.214584, 22.536528],
								[114.214584, 22.536249],
								[114.214302, 22.536249],
								[114.214302, 22.535694],
								[114.213753, 22.535694],
								[114.213753, 22.535147],
								[114.213470, 22.535139],
								[114.213470, 22.534584],
								[114.212914, 22.534584],
								[114.212914, 22.534027],
								[114.212639, 22.534027],
								[114.212639, 22.533749],
								[114.211525, 22.533749],
								[114.211525, 22.534027],
								[114.210976, 22.534027],
								[114.210976, 22.534306],
								[114.209549, 22.534306],
								[114.209305, 22.534306],
								[114.209305, 22.533749],
								[114.209030, 22.533749],
								[114.209030, 22.533194],
								[114.208748, 22.533194],
								[114.208748, 22.532640],
								[114.209030, 22.532640],
								[114.209030, 22.531530],
								[114.209587, 22.531530],
								[114.209587, 22.530975],
								[114.209587, 22.530714],
								[114.209587, 22.530136],
								[114.209030, 22.530136],
								[114.209030, 22.529583],
								[114.208748, 22.529583],
								[114.208748, 22.528749],
								[114.208473, 22.528749],
								[114.208473, 22.525972],
								[114.208748, 22.525972],
								[114.208748, 22.525139],
								[114.209030, 22.525139],
								[114.209030, 22.524860],
								[114.209549, 22.524860],
								[114.210136, 22.524860],
								[114.210136, 22.525417],
								[114.210419, 22.525417],
								[114.210419, 22.525972],
								[114.211853, 22.525972],
								[114.212364, 22.525972],
								[114.212364, 22.525696],
								[114.214027, 22.525696],
								[114.214027, 22.525972],
								[114.214584, 22.525972],
								[114.214584, 22.526251],
								[114.214859, 22.526251],
								[114.214859, 22.526527],
								[114.215141, 22.526527],
								[114.215141, 22.526806],
								[114.215416, 22.526806],
								[114.215416, 22.527082],
								[114.215973, 22.527082],
								[114.215973, 22.527639],
								[114.216248, 22.527639],
								[114.216248, 22.527361],
								[114.216530, 22.527361],
								[114.216530, 22.526527],
								[114.216248, 22.526527],
								[114.216248, 22.526306],
								[114.216248, 22.525972],
								[114.216530, 22.525972],
								[114.216530, 22.525417],
								[114.216705, 22.525417],
								[114.216805, 22.525417],
								[114.216805, 22.525139],
								[114.217468, 22.525139],
								[114.217918, 22.525139],
								[114.217918, 22.524860],
								[114.218193, 22.524860],
								[114.218193, 22.525139],
								[114.218475, 22.525139],
								[114.218475, 22.525265],
								[114.218475, 22.525696],
								[114.218750, 22.525696],
								[114.218750, 22.525972],
								[114.219025, 22.525972],
								[114.219025, 22.526251],
								[114.219307, 22.526251],
								[114.219307, 22.527082],
								[114.219582, 22.527082],
								[114.219582, 22.527639],
								[114.219864, 22.527639],
								[114.219864, 22.527914],
								[114.220139, 22.527914],
								[114.220139, 22.528194],
								[114.220413, 22.528194],
								[114.220413, 22.528749],
								[114.220695, 22.528749],
								[114.220695, 22.529028],
								[114.220970, 22.529028],
								[114.220970, 22.530136],
								[114.221252, 22.530136],
								[114.221252, 22.530416],
								[114.221527, 22.530416],
								[114.221527, 22.530695],
								[114.222641, 22.530695],
								[114.222641, 22.530416],
								[114.223473, 22.530416],
								[114.223473, 22.530136],
								[114.223747, 22.530136],
								[114.223747, 22.529861],
								[114.224030, 22.529861],
								[114.224030, 22.529583],
								[114.224586, 22.529583],
								[114.224586, 22.529306],
								[114.225693, 22.529306],
								[114.225693, 22.529861],
								[114.226250, 22.529861],
								[114.226250, 22.530136],
								[114.226524, 22.530136],
								[114.226524, 22.530416],
								[114.227081, 22.530416],
								[114.227081, 22.530695],
								[114.227364, 22.530695],
								[114.227364, 22.530972],
								[114.228195, 22.530972],
								[114.228195, 22.531248],
								[114.228752, 22.531248],
								[114.228752, 22.531530],
								[114.229027, 22.531530],
								[114.229027, 22.533470],
								[114.229309, 22.533470],
								[114.229309, 22.533749],
								[114.230415, 22.533749],
								[114.230415, 22.533470],
								[114.230537, 22.533470],
								[114.230972, 22.533470],
								[114.230972, 22.533194],
								[114.231293, 22.533194],
								[114.231529, 22.533194],
								[114.231529, 22.532953],
								[114.231804, 22.532919],
								[114.231804, 22.532681],
								[114.232361, 22.532640],
								[114.232361, 22.532360],
								[114.232918, 22.532360],
								[114.232918, 22.532200],
								[114.232918, 22.532084],
								[114.234024, 22.532084],
								[114.234024, 22.532200],
								[114.234024, 22.532360],
								[114.234581, 22.532360],
								[114.234581, 22.532640],
								[114.235138, 22.532640],
								[114.235138, 22.532919],
								[114.235420, 22.532919],
								[114.235420, 22.533194],
								[114.235695, 22.533194],
								[114.235695, 22.533749],
								[114.235970, 22.533749],
								[114.235970, 22.534584],
								[114.236252, 22.534584],
								[114.236252, 22.534863],
								[114.236809, 22.534863],
								[114.236809, 22.535139],
								[114.237083, 22.535139],
								[114.237083, 22.535694],
								[114.237640, 22.535694],
								[114.237640, 22.536806],
								[114.237915, 22.536806],
								[114.237915, 22.537916],
								[114.238197, 22.537916],
								[114.238197, 22.539862],
								[114.238472, 22.539862],
								[114.238472, 22.540972],
								[114.238747, 22.540972],
								[114.238747, 22.541805],
								[114.239029, 22.541805],
								[114.239029, 22.542084],
								[114.239586, 22.542084],
								[114.239586, 22.542360],
								[114.239861, 22.542360],
								[114.239861, 22.542915],
								[114.240135, 22.542915],
								[114.240135, 22.543472],
								[114.240692, 22.543472],
								[114.240692, 22.544861],
								[114.240974, 22.544861],
								[114.240974, 22.545416],
								[114.240692, 22.545416],
								[114.240692, 22.546247],
								[114.240974, 22.546247],
								[114.240974, 22.546806],
								[114.241531, 22.546806],
								[114.241531, 22.547640],
								[114.241806, 22.547640],
								[114.241806, 22.548195],
								[114.241531, 22.548195],
								[114.241531, 22.548470],
								[114.241806, 22.548470],
								[114.241806, 22.549028],
								[114.242081, 22.549028],
								[114.242081, 22.549305],
								[114.242638, 22.549305],
								[114.242638, 22.549583],
								[114.243195, 22.549583],
								[114.243195, 22.549305],
								[114.243752, 22.549305],
								[114.243752, 22.548752],
								[114.244026, 22.548752],
								[114.244026, 22.549028],
								[114.244308, 22.549028],
								[114.244308, 22.549305],
								[114.244858, 22.549305],
								[114.244858, 22.549583],
								[114.245140, 22.549583],
								[114.245140, 22.549864],
								[114.245415, 22.549864],
								[114.245415, 22.549583],
								[114.246803, 22.549583],
								[114.246803, 22.549305],
								[114.246971, 22.549305],
								[114.247086, 22.549305],
								[114.247086, 22.549028],
								[114.247360, 22.549028],
								[114.247360, 22.547642],
								[114.247086, 22.547640],
								[114.247086, 22.547083],
								[114.247360, 22.547083],
								[114.247360, 22.546247],
								[114.247643, 22.546247],
								[114.247643, 22.545973],
								[114.247917, 22.545973],
								[114.247917, 22.545694],
								[114.248192, 22.545694],
								[114.248192, 22.545416],
								[114.248474, 22.545416],
								[114.248474, 22.545137],
								[114.248749, 22.545137],
								[114.248749, 22.544861],
								[114.249306, 22.544861],
								[114.249306, 22.545137],
								[114.249580, 22.545137],
								[114.249580, 22.545252],
								[114.249580, 22.545416],
								[114.250137, 22.545416],
								[114.250137, 22.545973],
								[114.250420, 22.545973],
								[114.250420, 22.547083],
								[114.250137, 22.547083],
								[114.250137, 22.547640],
								[114.250694, 22.547640],
								[114.250694, 22.547916],
								[114.251251, 22.547916],
								[114.251251, 22.547640],
								[114.252640, 22.547640],
								[114.252640, 22.547504],
								[114.252640, 22.547359],
								[114.252914, 22.547359],
								[114.252914, 22.547083],
								[114.253197, 22.547083],
								[114.253197, 22.546806],
								[114.253471, 22.546806],
								[114.253471, 22.546530],
								[114.253754, 22.546530],
								[114.253754, 22.546247],
								[114.254585, 22.546247],
								[114.254585, 22.546360],
								[114.254585, 22.546806],
								[114.254860, 22.546806],
								[114.254860, 22.546997],
								[114.254860, 22.549583],
								[114.255142, 22.549583],
								[114.255142, 22.549305],
								[114.257385, 22.549305],
								[114.257637, 22.549305],
								[114.257637, 22.549028],
								[114.257919, 22.549028],
								[114.257919, 22.548752],
								[114.258194, 22.548752],
								[114.258194, 22.548470],
								[114.258469, 22.548470],
								[114.258469, 22.548195],
								[114.259026, 22.548195],
								[114.259026, 22.548470],
								[114.259583, 22.548470],
								[114.259583, 22.548752],
								[114.260414, 22.548752],
								[114.260414, 22.548195],
								[114.260139, 22.548195],
								[114.260139, 22.547916],
								[114.259865, 22.547916],
								[114.259865, 22.547642],
								[114.259583, 22.547640],
								[114.259583, 22.547083],
								[114.259308, 22.547083],
								[114.259308, 22.546806],
								[114.259026, 22.546806],
								[114.259026, 22.546530],
								[114.258751, 22.546530],
								[114.258751, 22.546247],
								[114.258469, 22.546247],
								[114.258469, 22.545973],
								[114.257919, 22.545973],
								[114.257919, 22.545694],
								[114.257637, 22.545694],
								[114.257637, 22.545416],
								[114.257362, 22.545416],
								[114.257362, 22.545137],
								[114.257080, 22.545137],
								[114.257080, 22.544584],
								[114.257362, 22.544582],
								[114.257362, 22.544306],
								[114.257919, 22.544306],
								[114.257919, 22.544582],
								[114.258194, 22.544584],
								[114.258194, 22.544861],
								[114.258469, 22.544861],
								[114.258469, 22.545137],
								[114.258614, 22.545137],
								[114.258751, 22.545137],
								[114.258751, 22.545416],
								[114.259865, 22.545416],
								[114.259865, 22.545694],
								[114.260414, 22.545694],
								[114.260414, 22.545973],
								[114.260971, 22.545973],
								[114.260971, 22.546247],
								[114.262360, 22.546247],
								[114.262360, 22.545973],
								[114.262085, 22.545973],
								[114.262085, 22.545416],
								[114.261803, 22.545416],
								[114.261803, 22.544861],
								[114.261528, 22.544861],
								[114.261528, 22.544584],
								[114.260971, 22.544584],
								[114.260971, 22.544306],
								[114.259308, 22.544306],
								[114.259308, 22.544027],
								[114.258751, 22.544027],
								[114.258751, 22.542915],
								[114.258469, 22.542915],
								[114.258469, 22.541805],
								[114.258194, 22.541805],
								[114.258194, 22.541529],
								[114.257919, 22.541529],
								[114.257919, 22.540972],
								[114.257637, 22.540972],
								[114.257637, 22.540695],
								[114.257362, 22.540695],
								[114.257362, 22.540417],
								[114.257080, 22.540417],
								[114.257080, 22.540138],
								[114.256805, 22.540138],
								[114.256805, 22.539583],
								[114.256531, 22.539583],
								[114.256531, 22.539305],
								[114.255974, 22.539305],
								[114.255974, 22.539028],
								[114.256248, 22.539028],
								[114.256248, 22.538473],
								[114.256531, 22.538473],
								[114.256531, 22.538195],
								[114.257080, 22.538195],
								[114.257080, 22.537916],
								[114.257919, 22.537916],
								[114.257919, 22.538195],
								[114.258255, 22.538235],
								[114.258469, 22.538261],
								[114.258469, 22.538473],
								[114.259026, 22.538473],
								[114.259026, 22.538750],
								[114.259583, 22.538750],
								[114.259583, 22.539028],
								[114.259865, 22.539028],
								[114.259865, 22.539583],
								[114.260139, 22.539583],
								[114.260139, 22.539862],
								[114.260414, 22.539862],
								[114.260414, 22.540417],
								[114.261253, 22.540417],
								[114.261253, 22.540138],
								[114.262489, 22.540138],
								[114.262642, 22.540138],
								[114.262642, 22.539862],
								[114.262917, 22.539862],
								[114.262917, 22.539583],
								[114.263474, 22.539583],
								[114.263474, 22.539305],
								[114.263748, 22.539305],
								[114.263748, 22.538750],
								[114.263474, 22.538750],
								[114.263474, 22.537916],
								[114.263191, 22.537916],
								[114.263191, 22.537361],
								[114.262917, 22.537361],
								[114.262917, 22.536806],
								[114.262642, 22.536806],
								[114.262642, 22.536528],
								[114.262360, 22.536528],
								[114.262360, 22.536249],
								[114.262085, 22.536249],
								[114.262085, 22.535694],
								[114.261803, 22.535694],
								[114.261803, 22.534027],
								[114.262360, 22.534027],
								[114.262360, 22.534306],
								[114.262596, 22.534306],
								[114.262642, 22.534584],
								[114.262917, 22.534584],
								[114.262917, 22.535139],
								[114.263191, 22.535139],
								[114.263191, 22.535418],
								[114.263474, 22.535418],
								[114.263474, 22.535973],
								[114.263748, 22.535973],
								[114.263748, 22.536249],
								[114.264030, 22.536249],
								[114.264030, 22.536806],
								[114.264305, 22.536806],
								[114.264305, 22.536528],
								[114.264580, 22.536528],
								[114.264580, 22.536806],
								[114.265137, 22.536806],
								[114.265137, 22.536528],
								[114.266808, 22.536528],
								[114.266808, 22.535990],
								[114.267082, 22.535973],
								[114.267082, 22.535418],
								[114.267365, 22.535418],
								[114.267365, 22.534863],
								[114.267639, 22.534863],
								[114.267639, 22.533470],
								[114.267082, 22.533470],
								[114.267082, 22.532919],
								[114.266251, 22.532919],
								[114.266251, 22.532640],
								[114.265137, 22.532640],
								[114.265137, 22.532360],
								[114.264862, 22.532360],
								[114.264862, 22.532640],
								[114.264580, 22.532640],
								[114.264580, 22.532360],
								[114.264305, 22.532360],
								[114.264305, 22.532084],
								[114.264030, 22.532084],
								[114.264030, 22.531805],
								[114.263748, 22.531805],
								[114.263748, 22.531530],
								[114.263474, 22.531530],
								[114.263474, 22.530972],
								[114.263191, 22.530972],
								[114.263191, 22.530136],
								[114.262917, 22.530136],
								[114.262917, 22.529583],
								[114.262642, 22.529583],
								[114.262642, 22.528749],
								[114.262360, 22.528749],
								[114.262360, 22.527914],
								[114.262642, 22.527914],
								[114.262642, 22.526527],
								[114.262917, 22.526527],
								[114.262917, 22.525972],
								[114.263145, 22.525972],
								[114.263168, 22.525673],
								[114.263191, 22.525417],
								[114.265694, 22.525417],
								[114.265694, 22.525696],
								[114.265976, 22.525696],
								[114.265976, 22.526251],
								[114.266251, 22.526251],
								[114.266251, 22.526527],
								[114.266525, 22.526527],
								[114.266525, 22.526806],
								[114.269028, 22.526806],
								[114.269028, 22.526569],
								[114.269302, 22.526527],
								[114.269302, 22.526205],
								[114.269302, 22.525972],
								[114.269478, 22.525972],
								[114.269585, 22.525972],
								[114.269585, 22.525827],
								[114.269585, 22.525696],
								[114.269859, 22.525696],
								[114.269859, 22.525972],
								[114.270142, 22.525972],
								[114.270142, 22.525696],
								[114.272919, 22.525696],
								[114.272919, 22.525972],
								[114.273476, 22.525972],
								[114.273476, 22.526527],
								[114.273750, 22.526527],
								[114.273750, 22.526806],
								[114.274025, 22.526806],
								[114.274025, 22.527082],
								[114.274307, 22.527082],
								[114.274307, 22.527639],
								[114.274582, 22.527639],
								[114.274582, 22.527914],
								[114.274307, 22.527914],
								[114.274307, 22.528473],
								[114.273750, 22.528473],
								[114.273750, 22.528749],
								[114.273476, 22.528749],
								[114.273476, 22.529583],
								[114.274025, 22.529583],
								[114.274025, 22.529861],
								[114.274307, 22.529861],
								[114.274307, 22.529583],
								[114.274582, 22.529583],
								[114.274582, 22.529306],
								[114.275139, 22.529306],
								[114.275139, 22.528749],
								[114.275696, 22.528749],
								[114.275696, 22.528473],
								[114.275970, 22.528473],
								[114.275970, 22.528194],
								[114.277916, 22.528194],
								[114.277916, 22.528473],
								[114.279861, 22.528473],
								[114.279861, 22.528749],
								[114.280136, 22.528749],
								[114.280136, 22.529028],
								[114.281250, 22.529028],
								[114.281250, 22.528282],
								[114.281250, 22.528194],
								[114.280975, 22.528194],
								[114.280975, 22.527914],
								[114.280693, 22.527914],
								[114.280693, 22.527639],
								[114.280418, 22.527639],
								[114.280418, 22.527361],
								[114.280136, 22.527361],
								[114.280136, 22.527082],
								[114.279861, 22.527082],
								[114.279861, 22.526806],
								[114.279587, 22.526806],
								[114.279587, 22.525972],
								[114.279305, 22.525972],
								[114.279305, 22.525417],
								[114.279030, 22.525417],
								[114.279030, 22.524584],
								[114.278748, 22.524584],
								[114.278748, 22.524305],
								[114.278473, 22.524305],
								[114.278473, 22.523474],
								[114.278748, 22.523474],
								[114.278748, 22.523319],
								[114.278748, 22.522917],
								[114.279305, 22.522917],
								[114.279305, 22.522638],
								[114.280418, 22.522638],
								[114.280418, 22.522917],
								[114.280693, 22.522917],
								[114.280693, 22.523195],
								[114.281250, 22.523195],
								[114.281250, 22.523474],
								[114.281525, 22.523474],
								[114.281525, 22.523750],
								[114.281807, 22.523750],
								[114.281807, 22.524305],
								[114.282364, 22.524305],
								[114.282364, 22.524584],
								[114.282913, 22.524584],
								[114.282913, 22.525139],
								[114.283752, 22.525139],
								[114.283752, 22.525417],
								[114.284302, 22.525417],
								[114.284309, 22.525696],
								[114.284859, 22.525696],
								[114.284859, 22.525972],
								[114.285141, 22.525972],
								[114.285141, 22.525696],
								[114.285973, 22.525696],
								[114.285973, 22.524860],
								[114.286247, 22.524860],
								[114.286247, 22.523474],
								[114.285973, 22.523474],
								[114.285973, 22.523195],
								[114.285416, 22.523195],
								[114.285416, 22.522362],
								[114.284859, 22.522362],
								[114.284859, 22.522083],
								[114.284302, 22.522083],
								[114.284302, 22.521528],
								[114.284027, 22.521528],
								[114.284027, 22.521252],
								[114.282913, 22.521252],
								[114.282913, 22.520969],
								[114.282639, 22.520969],
								[114.282639, 22.520695],
								[114.282364, 22.520695],
								[114.282364, 22.520412],
								[114.282364, 22.519859],
								[114.282234, 22.519859],
								[114.282082, 22.519859],
								[114.282066, 22.518541],
								[114.281807, 22.518473],
								[114.281807, 22.518194],
								[114.281006, 22.518194],
								[114.280136, 22.518194],
								[114.280136, 22.517920],
								[114.279861, 22.517916],
								[114.279861, 22.517361],
								[114.279587, 22.517361],
								[114.279587, 22.515415],
								[114.278748, 22.515415],
								[114.278748, 22.515139],
								[114.279030, 22.515139],
								[114.279030, 22.514029],
								[114.278748, 22.514029],
								[114.278748, 22.513655],
								[114.278748, 22.513193],
								[114.277916, 22.513193],
								[114.277916, 22.513472],
								[114.277641, 22.513472],
								[114.277641, 22.513750],
								[114.277359, 22.513750],
								[114.277359, 22.514305],
								[114.277084, 22.514305],
								[114.277084, 22.514584],
								[114.276802, 22.514584],
								[114.276802, 22.515139],
								[114.276253, 22.515139],
								[114.276253, 22.515415],
								[114.275696, 22.515415],
								[114.275696, 22.515694],
								[114.275414, 22.515694],
								[114.275414, 22.515972],
								[114.274864, 22.515972],
								[114.274864, 22.516525],
								[114.274307, 22.516525],
								[114.274307, 22.516251],
								[114.274025, 22.516251],
								[114.274025, 22.516806],
								[114.273796, 22.516806],
								[114.272636, 22.516806],
								[114.272636, 22.515694],
								[114.272888, 22.515694],
								[114.273476, 22.515694],
								[114.273476, 22.515139],
								[114.273193, 22.515139],
								[114.273193, 22.514584],
								[114.272919, 22.514584],
								[114.272919, 22.514029],
								[114.272636, 22.514029],
								[114.272636, 22.513750],
								[114.272919, 22.513750],
								[114.272919, 22.513472],
								[114.274864, 22.513472],
								[114.274864, 22.513193],
								[114.274582, 22.513193],
								[114.274582, 22.512917],
								[114.274864, 22.512917],
								[114.274864, 22.512083],
								[114.274712, 22.512083],
								[114.274582, 22.512083],
								[114.274582, 22.511250],
								[114.274307, 22.511250],
								[114.274307, 22.510695],
								[114.274025, 22.510695],
								[114.274025, 22.510139],
								[114.273750, 22.510139],
								[114.273750, 22.509863],
								[114.274025, 22.509863],
								[114.274025, 22.509583],
								[114.274139, 22.509583],
								[114.274307, 22.509583],
								[114.274307, 22.509306],
								[114.274582, 22.509306],
								[114.274582, 22.509027],
								[114.274864, 22.509027],
								[114.274864, 22.508753],
								[114.275139, 22.508753],
								[114.275139, 22.508472],
								[114.275696, 22.508472],
								[114.275696, 22.509863],
								[114.276802, 22.509863],
								[114.276802, 22.510139],
								[114.277641, 22.510139],
								[114.277641, 22.510416],
								[114.278748, 22.510416],
								[114.278748, 22.510695],
								[114.282364, 22.510695],
								[114.282364, 22.510975],
								[114.284584, 22.510975],
								[114.284584, 22.510416],
								[114.284859, 22.510416],
								[114.284859, 22.509903],
								[114.284584, 22.509863],
								[114.284584, 22.509583],
								[114.284302, 22.509583],
								[114.284302, 22.509306],
								[114.284027, 22.509306],
								[114.284027, 22.508753],
								[114.283752, 22.508753],
								[114.283752, 22.508623],
								[114.283752, 22.508472],
								[114.287918, 22.508472],
								[114.287918, 22.507641],
								[114.288193, 22.507641],
								[114.288193, 22.507277],
								[114.288193, 22.506805],
								[114.288498, 22.506805],
								[114.288750, 22.506805],
								[114.288750, 22.506527],
								[114.289024, 22.506527],
								[114.289024, 22.506248],
								[114.289307, 22.506248],
								[114.289307, 22.508194],
								[114.290138, 22.508194],
								[114.290138, 22.508472],
								[114.290695, 22.508472],
								[114.290695, 22.508753],
								[114.292084, 22.508753],
								[114.292084, 22.508472],
								[114.292358, 22.508472],
								[114.292358, 22.507917],
								[114.292641, 22.507917],
								[114.292641, 22.507641],
								[114.293472, 22.507641],
								[114.293472, 22.507084],
								[114.293747, 22.507084],
								[114.293747, 22.506727],
								[114.293747, 22.505972],
								[114.294029, 22.505972],
								[114.294029, 22.505695],
								[114.294174, 22.505695],
								[114.294304, 22.505695],
								[114.294304, 22.505138],
								[114.295135, 22.505138],
								[114.295135, 22.504862],
								[114.296524, 22.504862],
								[114.296524, 22.505138],
								[114.296806, 22.505138],
								[114.296806, 22.505419],
								[114.297081, 22.505419],
								[114.297081, 22.505695],
								[114.297363, 22.505695],
								[114.297363, 22.505972],
								[114.297638, 22.505972],
								[114.297638, 22.506248],
								[114.297920, 22.506248],
								[114.297920, 22.506527],
								[114.297638, 22.506527],
								[114.297638, 22.506805],
								[114.296806, 22.506805],
								[114.296806, 22.507641],
								[114.297081, 22.507641],
								[114.297081, 22.508194],
								[114.297363, 22.508194],
								[114.297363, 22.508472],
								[114.298195, 22.508472],
								[114.298195, 22.509027],
								[114.297920, 22.509027],
								[114.297920, 22.509583],
								[114.297638, 22.509583],
								[114.297638, 22.509863],
								[114.297081, 22.509863],
								[114.297081, 22.510139],
								[114.296806, 22.510139],
								[114.296806, 22.510464],
								[114.296806, 22.510695],
								[114.296341, 22.510695],
								[114.295692, 22.510695],
								[114.295692, 22.511250],
								[114.295418, 22.511250],
								[114.295418, 22.511528],
								[114.294861, 22.511528],
								[114.294861, 22.511806],
								[114.294586, 22.511806],
								[114.294586, 22.512362],
								[114.294861, 22.512362],
								[114.294861, 22.512083],
								[114.295135, 22.512083],
								[114.295135, 22.512638],
								[114.299309, 22.512638],
								[114.299309, 22.512362],
								[114.299583, 22.512362],
								[114.299583, 22.512638],
								[114.300140, 22.512638],
								[114.300140, 22.512917],
								[114.300415, 22.512917],
								[114.300415, 22.513193],
								[114.301529, 22.513193],
								[114.301529, 22.512917],
								[114.302086, 22.512917],
								[114.302086, 22.512638],
								[114.302361, 22.512638],
								[114.302361, 22.512362],
								[114.302208, 22.512362],
								[114.302086, 22.512362],
								[114.302086, 22.511806],
								[114.302361, 22.511805],
								[114.302361, 22.511528],
								[114.302635, 22.511528],
								[114.302635, 22.511250],
								[114.302917, 22.511250],
								[114.302917, 22.510975],
								[114.303192, 22.510975],
								[114.303192, 22.510695],
								[114.303429, 22.510695],
								[114.304031, 22.510695],
								[114.304031, 22.510416],
								[114.304306, 22.510416],
								[114.304306, 22.510695],
								[114.304581, 22.510695],
								[114.304581, 22.511250],
								[114.304863, 22.511250],
								[114.304863, 22.512362],
								[114.305138, 22.512362],
								[114.305138, 22.512917],
								[114.305420, 22.512917],
								[114.305420, 22.513193],
								[114.305695, 22.513193],
								[114.305695, 22.513472],
								[114.305969, 22.513472],
								[114.305969, 22.514029],
								[114.307358, 22.514029],
								[114.307358, 22.515139],
								[114.307640, 22.515139],
								[114.307640, 22.515972],
								[114.307915, 22.515972],
								[114.307915, 22.516525],
								[114.308472, 22.516525],
								[114.308472, 22.516806],
								[114.308746, 22.516806],
								[114.308746, 22.517361],
								[114.309303, 22.517361],
								[114.309303, 22.517637],
								[114.309586, 22.517637],
								[114.309586, 22.518194],
								[114.309860, 22.518194],
								[114.309860, 22.519306],
								[114.310143, 22.519306],
								[114.310143, 22.519583],
								[114.309860, 22.519583],
								[114.309860, 22.520416],
								[114.309586, 22.520416],
								[114.309586, 22.520695],
								[114.309303, 22.520695],
								[114.309303, 22.520969],
								[114.309029, 22.520969],
								[114.309029, 22.522362],
								[114.309303, 22.522362],
								[114.309303, 22.523195],
								[114.309586, 22.523195],
								[114.309586, 22.523750],
								[114.309303, 22.523750],
								[114.309303, 22.524027],
								[114.309586, 22.524027],
								[114.309586, 22.524305],
								[114.309303, 22.524305],
								[114.309303, 22.524584],
								[114.309029, 22.524584],
								[114.309029, 22.525139],
								[114.308472, 22.525139],
								[114.308472, 22.525972],
								[114.308746, 22.525972],
								[114.308746, 22.526251],
								[114.309860, 22.526251],
								[114.309860, 22.525972],
								[114.310417, 22.525972],
								[114.310417, 22.525696],
								[114.310974, 22.525696],
								[114.310974, 22.525417],
								[114.311531, 22.525417],
								[114.311531, 22.525139],
								[114.312080, 22.525139],
								[114.312080, 22.524860],
								[114.313194, 22.524860],
								[114.313194, 22.524584],
								[114.314026, 22.524584],
								[114.314026, 22.524305],
								[114.314583, 22.524305],
								[114.314583, 22.524027],
								[114.314857, 22.524027],
								[114.314857, 22.523750],
								[114.315414, 22.523750],
								[114.315414, 22.523195],
								[114.315971, 22.523195],
								[114.315971, 22.522917],
								[114.315697, 22.522917],
								[114.315697, 22.522638],
								[114.315971, 22.522638],
								[114.315971, 22.522083],
								[114.316254, 22.522083],
								[114.316254, 22.521805],
								[114.316528, 22.521805],
								[114.316528, 22.521252],
								[114.316803, 22.521252],
								[114.316803, 22.520969],
								[114.317085, 22.520969],
								[114.317085, 22.520416],
								[114.317360, 22.520416],
								[114.317360, 22.520142],
								[114.317085, 22.520142],
								[114.317085, 22.519859],
								[114.316528, 22.519859],
								[114.316528, 22.519583],
								[114.316803, 22.519583],
								[114.316803, 22.519030],
								[114.317085, 22.519030],
								[114.317085, 22.518747],
								[114.318474, 22.518747],
								[114.318474, 22.518881],
								[114.318474, 22.519030],
								[114.319305, 22.519030],
								[114.319305, 22.518747],
								[114.319580, 22.518747],
								[114.319580, 22.518473],
								[114.319862, 22.518473],
								[114.319862, 22.518194],
								[114.320137, 22.518194],
								[114.320137, 22.517916],
								[114.320419, 22.517916],
								[114.320419, 22.517637],
								[114.320694, 22.517637],
								[114.320694, 22.517361],
								[114.320969, 22.517361],
								[114.320969, 22.517084],
								[114.321251, 22.517084],
								[114.321251, 22.516905],
								[114.321251, 22.516806],
								[114.320969, 22.516806],
								[114.320969, 22.515972],
								[114.319862, 22.515972],
								[114.319862, 22.515694],
								[114.319580, 22.515694],
								[114.319580, 22.515415],
								[114.319305, 22.515415],
								[114.319305, 22.514584],
								[114.318748, 22.514584],
								[114.318748, 22.513750],
								[114.318474, 22.513750],
								[114.318474, 22.513472],
								[114.317917, 22.513472],
								[114.317917, 22.513193],
								[114.317360, 22.513193],
								[114.317360, 22.512638],
								[114.317085, 22.512638],
								[114.317085, 22.512362],
								[114.316803, 22.512362],
								[114.316803, 22.512083],
								[114.316528, 22.512083],
								[114.316528, 22.511806],
								[114.315697, 22.511806],
								[114.315697, 22.511528],
								[114.315140, 22.511528],
								[114.315140, 22.511250],
								[114.314857, 22.511250],
								[114.314857, 22.511528],
								[114.314583, 22.511528],
								[114.314583, 22.511250],
								[114.314026, 22.511250],
								[114.314026, 22.510975],
								[114.312637, 22.510975],
								[114.312637, 22.510695],
								[114.312080, 22.510695],
								[114.312080, 22.510416],
								[114.311806, 22.510416],
								[114.311806, 22.509863],
								[114.311249, 22.509863],
								[114.311249, 22.509027],
								[114.310974, 22.509027],
								[114.310974, 22.508472],
								[114.310417, 22.508472],
								[114.310417, 22.508194],
								[114.309341, 22.508194],
								[114.309303, 22.508472],
								[114.309029, 22.508472],
								[114.309029, 22.508753],
								[114.308472, 22.508753],
								[114.308472, 22.509306],
								[114.308197, 22.509306],
								[114.308197, 22.509027],
								[114.307915, 22.509027],
								[114.307915, 22.509306],
								[114.307358, 22.509306],
								[114.307358, 22.509583],
								[114.306808, 22.509583],
								[114.306808, 22.509306],
								[114.306412, 22.509306],
								[114.305695, 22.509306],
								[114.305695, 22.509027],
								[114.305138, 22.509027],
								[114.305138, 22.508898],
								[114.305138, 22.508753],
								[114.304863, 22.508753],
								[114.304863, 22.508194],
								[114.304581, 22.508194],
								[114.304581, 22.507917],
								[114.304306, 22.507917],
								[114.304306, 22.507359],
								[114.303749, 22.507359],
								[114.303749, 22.506527],
								[114.303474, 22.506527],
								[114.303474, 22.506248],
								[114.302917, 22.506248],
								[114.302917, 22.505972],
								[114.302361, 22.505972],
								[114.302361, 22.506248],
								[114.301804, 22.506248],
								[114.301804, 22.505972],
								[114.301529, 22.505972],
								[114.301529, 22.505695],
								[114.300972, 22.505695],
								[114.300972, 22.505419],
								[114.300415, 22.505419],
								[114.300415, 22.505138],
								[114.299583, 22.505138],
								[114.299583, 22.504862],
								[114.299309, 22.504862],
								[114.299309, 22.504663],
								[114.299309, 22.504583],
								[114.299026, 22.504583],
								[114.299026, 22.504305],
								[114.298195, 22.504305],
								[114.298195, 22.503750],
								[114.297638, 22.503750],
								[114.297638, 22.503195],
								[114.297920, 22.503195],
								[114.297920, 22.502916],
								[114.298195, 22.502916],
								[114.298195, 22.502640],
								[114.298470, 22.502640],
								[114.298470, 22.502361],
								[114.298752, 22.502361],
								[114.298752, 22.502640],
								[114.299583, 22.502640],
								[114.299583, 22.502361],
								[114.300415, 22.502361],
								[114.300415, 22.501806],
								[114.300972, 22.501806],
								[114.300972, 22.501528],
								[114.301247, 22.501528],
								[114.301247, 22.501249],
								[114.301804, 22.501249],
								[114.301804, 22.500992],
								[114.302086, 22.500973],
								[114.302086, 22.500710],
								[114.302361, 22.500694],
								[114.302361, 22.500435],
								[114.302635, 22.500416],
								[114.302635, 22.500156],
								[114.302917, 22.500139],
								[114.302917, 22.499878],
								[114.303749, 22.499861],
								[114.303749, 22.499306],
								[114.304581, 22.499306],
								[114.304581, 22.499584],
								[114.304863, 22.499584],
								[114.304863, 22.499861],
								[114.305138, 22.499861],
								[114.305138, 22.500139],
								[114.305420, 22.500139],
								[114.305420, 22.500416],
								[114.305969, 22.500416],
								[114.305969, 22.500973],
								[114.306526, 22.500973],
								[114.306526, 22.501249],
								[114.306808, 22.501249],
								[114.306808, 22.501528],
								[114.307915, 22.501528],
								[114.307915, 22.501806],
								[114.308472, 22.501806],
								[114.308472, 22.502083],
								[114.309029, 22.502083],
								[114.309029, 22.502361],
								[114.309303, 22.502361],
								[114.309303, 22.502640],
								[114.311249, 22.502640],
								[114.312080, 22.502640],
								[114.312080, 22.502916],
								[114.312363, 22.502916],
								[114.312637, 22.502916],
								[114.312637, 22.503195],
								[114.313194, 22.503195],
								[114.313194, 22.503471],
								[114.313469, 22.503471],
								[114.313469, 22.503750],
								[114.314026, 22.503750],
								[114.314026, 22.504026],
								[114.319031, 22.504026],
								[114.319031, 22.504305],
								[114.319862, 22.504305],
								[114.319862, 22.504583],
								[114.320694, 22.504583],
								[114.320694, 22.504862],
								[114.321526, 22.504862],
								[114.321526, 22.505138],
								[114.322639, 22.505138],
								[114.322639, 22.505419],
								[114.323471, 22.505419],
								[114.323471, 22.505695],
								[114.324303, 22.505695],
								[114.324303, 22.505972],
								[114.325691, 22.505972],
								[114.325691, 22.506248],
								[114.326393, 22.506248],
								[114.326805, 22.506248],
								[114.326805, 22.506527],
								[114.327080, 22.506527],
								[114.327080, 22.506805],
								[114.327225, 22.506805],
								[114.327362, 22.506805],
								[114.327362, 22.507084],
								[114.327637, 22.507084],
								[114.327637, 22.507359],
								[114.327919, 22.507359],
								[114.327919, 22.507641],
								[114.328194, 22.507641],
								[114.328194, 22.507917],
								[114.328476, 22.507917],
								[114.328476, 22.508194],
								[114.328751, 22.508194],
								[114.328751, 22.508472],
								[114.329025, 22.508472],
								[114.329025, 22.508753],
								[114.329308, 22.508753],
								[114.329308, 22.509027],
								[114.329865, 22.509027],
								[114.329865, 22.509306],
								[114.330414, 22.509306],
								[114.330414, 22.509583],
								[114.330971, 22.509583],
								[114.330971, 22.509863],
								[114.331528, 22.509863],
								[114.331528, 22.510139],
								[114.331833, 22.510139],
								[114.332085, 22.510139],
								[114.332085, 22.510416],
								[114.332207, 22.510416],
								[114.332642, 22.510416],
								[114.332642, 22.510695],
								[114.333191, 22.510695],
								[114.333191, 22.510975],
								[114.333984, 22.510975],
								[114.334587, 22.509464],
								[114.332481, 22.508204],
								[114.331215, 22.505676],
								[114.328690, 22.504416],
								[114.327431, 22.503153],
								[114.325745, 22.502729],
								[114.324486, 22.503153],
								[114.323219, 22.502729],
								[114.321114, 22.501469],
								[114.320274, 22.500626],
								[114.319427, 22.499784],
								[114.316063, 22.498098],
								[114.314377, 22.495996],
								[114.313118, 22.495575],
								[114.311852, 22.495152],
								[114.310593, 22.494310],
								[114.309326, 22.493467],
								[114.308060, 22.492626],
								[114.306801, 22.492205],
								[114.303429, 22.490103],
								[114.302589, 22.489258],
								[114.301331, 22.489258],
								[114.300484, 22.490103],
								[114.298378, 22.490944],
								[114.297119, 22.491364],
								[114.295853, 22.491364],
								[114.294594, 22.491364],
								[114.293327, 22.493050],
								[114.291222, 22.494310],
								[114.289543, 22.495152],
								[114.288277, 22.495575],
								[114.287010, 22.496414],
								[114.285751, 22.496838],
								[114.284485, 22.497681],
								[114.278175, 22.498098],
								[114.276489, 22.497257],
								[114.275223, 22.496414],
								[114.272278, 22.496414],
								[114.271019, 22.497257],
								[114.269333, 22.495996],
								[114.268066, 22.495996],
								[114.266808, 22.495996],
								[114.267227, 22.494734],
								[114.266388, 22.493891],
								[114.263855, 22.493467],
								[114.262177, 22.493891],
								[114.259651, 22.493467],
								[114.258385, 22.493467],
								[114.257126, 22.493467],
								[114.255859, 22.493467],
								[114.254593, 22.493467],
								[114.253334, 22.493467],
								[114.251648, 22.493891],
								[114.250809, 22.494734],
								[114.249542, 22.495575],
								[114.248283, 22.497257],
								[114.246178, 22.500204],
								[114.245331, 22.501045],
								[114.243233, 22.501886],
								[114.242386, 22.502729],
								[114.241966, 22.504833],
								[114.239861, 22.504416],
								[114.239021, 22.503571],
								[114.239441, 22.502310],
								[114.239441, 22.501045],
								[114.238602, 22.500204],
								[114.237335, 22.500626],
								[114.236069, 22.499784],
								[114.234810, 22.498522],
								[114.232285, 22.497681],
								[114.231865, 22.496414],
								[114.230598, 22.494310],
								[114.228912, 22.492626],
								[114.227654, 22.492205],
								[114.225967, 22.491785],
								[114.225128, 22.490944],
								[114.224281, 22.487156],
								[114.223022, 22.487995],
								[114.220078, 22.490103],
								[114.218811, 22.490103],
								[114.217552, 22.490103],
								[114.215866, 22.490520],
								[114.214600, 22.490944],
								[114.213341, 22.491364],
								[114.210815, 22.491364],
								[114.209549, 22.490944],
								[114.207863, 22.490520],
								[114.206604, 22.490944],
								[114.205338, 22.491364],
								[114.204079, 22.492205],
								[114.201126, 22.493050],
								[114.199028, 22.493467],
								[114.196075, 22.493467],
								[114.194397, 22.495152],
								[114.191864, 22.494734],
								[114.190605, 22.495575],
								[114.186813, 22.496414],
								[114.185555, 22.496414],
								[114.184288, 22.496414],
								[114.182182, 22.495996],
								[114.180504, 22.495575],
								[114.178818, 22.494734],
								[114.177551, 22.493891],
								[114.176712, 22.493050],
								[114.175446, 22.492205],
								[114.174606, 22.491364],
								[114.174187, 22.490103],
								[114.172920, 22.487995],
								[114.172081, 22.484627],
								[114.170815, 22.484627],
								[114.169975, 22.485472],
								[114.169556, 22.486732],
								[114.168289, 22.487156],
								[114.166611, 22.486732],
								[114.164505, 22.486313],
								[114.164078, 22.489258],
								[114.164078, 22.490520],
								[114.162399, 22.490103],
								[114.160713, 22.489679],
								[114.157349, 22.489258],
								[114.156082, 22.489258],
								[114.152718, 22.489258],
								[114.151451, 22.487995],
								[114.151451, 22.486732],
								[114.153137, 22.484205],
								[114.153976, 22.482943],
								[114.153976, 22.481680],
								[114.153976, 22.480417],
								[114.152290, 22.480839],
								[114.151031, 22.481258],
								[114.148926, 22.479574],
								[114.148506, 22.477892],
								[114.147659, 22.476627],
								[114.146820, 22.475786],
								[114.145561, 22.475786],
								[114.143456, 22.474524],
								[114.141769, 22.474102],
								[114.140083, 22.472839],
								[114.139244, 22.471996],
								[114.137978, 22.471155],
								[114.137138, 22.470314],
								[114.135452, 22.469893],
								[114.134613, 22.469049],
								[114.133347, 22.468630],
								[114.132088, 22.469049],
								[114.130821, 22.469049],
								[114.129135, 22.468208],
								[114.127457, 22.466946],
								[114.126190, 22.466524],
								[114.124931, 22.466524],
								[114.123665, 22.466524],
								[114.123260, 22.466444],
								[114.122879, 22.466368],
								[114.121750, 22.466141],
								[114.121559, 22.466103],
								[114.121132, 22.466669],
								[114.120804, 22.467112],
								[114.120682, 22.467278],
								[114.120300, 22.467787],
								[114.119034, 22.469049],
								[114.117775, 22.469049],
								[114.116089, 22.470314],
								[114.115242, 22.471577],
								[114.113983, 22.471996],
								[114.111877, 22.471577],
								[114.110611, 22.471155],
								[114.107666, 22.469893],
								[114.105980, 22.470314],
								[114.103035, 22.470314],
								[114.102196, 22.469471],
								[114.100090, 22.468208],
								[114.098404, 22.467787],
								[114.096718, 22.467367],
								[114.095459, 22.467367],
								[114.094193, 22.467367],
								[114.092934, 22.467367],
								[114.091667, 22.467367],
								[114.090523, 22.466988],
								[114.090408, 22.466946],
								[114.089142, 22.466946],
								[114.089142, 22.468208],
								[114.089142, 22.469471],
								[114.089142, 22.470734],
								[114.089142, 22.471996],
								[114.089142, 22.473261],
								[114.089142, 22.474524],
								[114.089142, 22.475786],
								[114.089142, 22.477049],
								[114.089142, 22.478312],
								[114.089142, 22.479574],
								[114.089142, 22.480839],
								[114.089211, 22.481039],
								[114.089561, 22.482101],
								[114.089989, 22.483364],
								[114.090172, 22.483641],
								[114.090584, 22.484261],
								[114.090775, 22.484547],
								[114.090927, 22.484829],
								[114.091667, 22.486313],
								[114.091736, 22.486519],
								[114.091812, 22.486732],
								[114.091919, 22.487059],
								[114.092087, 22.487574],
								[114.092087, 22.490387],
								[114.092087, 22.491364],
								[114.092476, 22.491940],
								[114.092934, 22.492626],
								[114.092087, 22.493891],
								[114.090828, 22.495575],
								[114.090408, 22.496838],
								[114.089989, 22.498098],
								[114.090828, 22.499363],
								[114.091667, 22.500204],
								[114.091568, 22.500889],
								[114.091446, 22.501760],
								[114.091423, 22.501919],
								[114.091393, 22.502115],
								[114.091370, 22.502287],
								[114.091278, 22.502922],
								[114.091248, 22.503153],
								[114.090828, 22.504416],
								[114.090828, 22.505676],
								[114.089561, 22.506517],
								[114.089989, 22.507780],
								[114.089943, 22.507906],
								[114.089874, 22.508114],
								[114.089737, 22.508532],
								[114.089676, 22.508720],
								[114.089561, 22.509045],
								[114.089668, 22.509148],
								[114.090408, 22.509888],
								[114.091255, 22.509604],
								[114.091667, 22.509464],
								[114.092300, 22.510305],
								[114.092934, 22.511150],
								[114.091751, 22.512329],
								[114.091248, 22.512835],
								[114.091248, 22.514936],
								[114.090408, 22.515781],
								[114.090652, 22.517031],
								[114.090752, 22.517523],
								[114.090790, 22.517719],
								[114.090828, 22.517887],
								[114.090897, 22.518093],
								[114.090950, 22.518259],
								[114.091248, 22.519150],
								[114.091667, 22.521254],
								[114.089561, 22.522938],
								[114.088303, 22.522938],
								[114.087120, 22.521759],
								[114.087036, 22.521675],
								[114.086365, 22.520670],
								[114.086197, 22.520412],
								[114.085205, 22.520164],
								[114.084511, 22.519991],
								[114.085426, 22.521515],
								[114.086609, 22.523491],
								[114.087029, 22.524223],
								[114.086639, 22.525782],
								[114.086617, 22.525885],
								[114.084930, 22.527569],
								[114.083672, 22.527990],
								[114.081146, 22.530096],
								[114.080727, 22.531359],
								[114.083755, 22.532461],
								[114.085358, 22.533043],
								[114.087883, 22.533884],
								[114.089142, 22.535147],
								[114.089439, 22.536045],
								[114.089561, 22.536409],
								[114.090439, 22.537066],
								[114.091248, 22.537674],
								[114.094193, 22.537674],
								[114.096298, 22.535568],
								[114.097939, 22.534750],
								[114.098137, 22.534763],
								[114.101349, 22.535568],
								[114.103035, 22.535568],
								[114.103294, 22.534533],
								[114.103455, 22.533884],
								[114.103241, 22.533564],
								[114.102615, 22.532621],
								[114.103516, 22.532324],
								[114.103882, 22.532200],
								[114.105560, 22.533043],
								[114.105682, 22.533413],
								[114.105965, 22.534252],
								[114.106201, 22.534521],
								[114.106827, 22.535147],
								[114.107880, 22.535412],
								[114.108513, 22.535568],
								[114.109772, 22.536831],
								[114.109871, 22.535929],
								[114.110069, 22.534142],
								[114.110191, 22.533043],
								[114.111458, 22.530937],
								[114.113983, 22.530096],
								[114.115669, 22.530937],
								[114.117348, 22.533043],
								[114.117348, 22.535147],
								[114.121559, 22.535147],
								[114.122406, 22.536831],
								[114.123665, 22.536831],
								[114.123245, 22.538515],
								[114.124504, 22.539358],
								[114.126190, 22.540199],
								[114.127037, 22.541040],
								[114.127876, 22.540199],
								[114.129135, 22.540199],
								[114.131241, 22.542305],
								[114.133766, 22.542725],
								[114.134277, 22.542725],
								[114.135033, 22.542725],
								[114.137138, 22.543568],
								[114.140083, 22.543568],
								[114.141350, 22.544409],
								[114.143875, 22.542725],
								[114.145561, 22.541883],
								[114.147240, 22.542305],
								[114.149345, 22.542725],
								[114.150185, 22.543987],
								[114.149345, 22.544830],
								[114.152290, 22.546515],
								[114.153557, 22.547777],
								[114.153137, 22.549461],
								[114.151031, 22.550303],
								[114.152077, 22.551004],
								[114.152290, 22.551146],
								[114.153137, 22.552830],
								[114.153137, 22.554152],
								[114.153137, 22.554934],
								[114.153954, 22.555096],
								[114.155243, 22.555355],
								[114.156502, 22.554516],
								[114.157768, 22.555355],
								[114.157776, 22.557060],
								[114.158607, 22.559565],
								[114.158783, 22.559717],
								[114.161552, 22.562094],
								[114.163239, 22.561670]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Sai Kung",
					"ID_0": 102,
					"ID_1": 8,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.345695, 22.255695],
								[114.345695, 22.255417],
								[114.346252, 22.255417],
								[114.346252, 22.254583],
								[114.346527, 22.254583],
								[114.346527, 22.254305],
								[114.347359, 22.254305],
								[114.347359, 22.254028],
								[114.347641, 22.254028],
								[114.347641, 22.252920],
								[114.347084, 22.252916],
								[114.347084, 22.253195],
								[114.346527, 22.253195],
								[114.346527, 22.253469],
								[114.345413, 22.253469],
								[114.345413, 22.254028],
								[114.345139, 22.254028],
								[114.345139, 22.255695],
								[114.345695, 22.255695]
							]
						],
						[
							[
								[114.296516, 22.256527],
								[114.296524, 22.256250],
								[114.297920, 22.256250],
								[114.297920, 22.255974],
								[114.298180, 22.255974],
								[114.298195, 22.255417],
								[114.297920, 22.255417],
								[114.297920, 22.255138],
								[114.297638, 22.255138],
								[114.297638, 22.254305],
								[114.297363, 22.254305],
								[114.297363, 22.254028],
								[114.296806, 22.254028],
								[114.296806, 22.252361],
								[114.297005, 22.252361],
								[114.297081, 22.252361],
								[114.297081, 22.252083],
								[114.297363, 22.252083],
								[114.297363, 22.251530],
								[114.297081, 22.251530],
								[114.297081, 22.251247],
								[114.297363, 22.251247],
								[114.297363, 22.250973],
								[114.297638, 22.250973],
								[114.297638, 22.250694],
								[114.298470, 22.250694],
								[114.298470, 22.250416],
								[114.298752, 22.250416],
								[114.298752, 22.249861],
								[114.299026, 22.249861],
								[114.299026, 22.248472],
								[114.298752, 22.248472],
								[114.298752, 22.247915],
								[114.298470, 22.247915],
								[114.298470, 22.247639],
								[114.298195, 22.247639],
								[114.298195, 22.247360],
								[114.297920, 22.247360],
								[114.297920, 22.247084],
								[114.297363, 22.247084],
								[114.297363, 22.246805],
								[114.297081, 22.246805],
								[114.297081, 22.246529],
								[114.296806, 22.246529],
								[114.296806, 22.245972],
								[114.297081, 22.245972],
								[114.297081, 22.244583],
								[114.296806, 22.244583],
								[114.296806, 22.244028],
								[114.296524, 22.244028],
								[114.296524, 22.243750],
								[114.296249, 22.243750],
								[114.296249, 22.242916],
								[114.295975, 22.242916],
								[114.295975, 22.242640],
								[114.296249, 22.242640],
								[114.296249, 22.242361],
								[114.295975, 22.242361],
								[114.295975, 22.241528],
								[114.295692, 22.241528],
								[114.295692, 22.241249],
								[114.294304, 22.241249],
								[114.294304, 22.241528],
								[114.293747, 22.241528],
								[114.293747, 22.241806],
								[114.291252, 22.241806],
								[114.291252, 22.242083],
								[114.290970, 22.242083],
								[114.290970, 22.241528],
								[114.290695, 22.241528],
								[114.290695, 22.240694],
								[114.290138, 22.240694],
								[114.290138, 22.240417],
								[114.290413, 22.240417],
								[114.290413, 22.239859],
								[114.290695, 22.239859],
								[114.290695, 22.239601],
								[114.290413, 22.239584],
								[114.290413, 22.238470],
								[114.290138, 22.238470],
								[114.290138, 22.238194],
								[114.289864, 22.238194],
								[114.289864, 22.237919],
								[114.289581, 22.237919],
								[114.289581, 22.237640],
								[114.288750, 22.237640],
								[114.288750, 22.237362],
								[114.287918, 22.237362],
								[114.287918, 22.237640],
								[114.287361, 22.237640],
								[114.287361, 22.237919],
								[114.287086, 22.237919],
								[114.287086, 22.238194],
								[114.286804, 22.238194],
								[114.286804, 22.238470],
								[114.286530, 22.238470],
								[114.286530, 22.239305],
								[114.286789, 22.239305],
								[114.286804, 22.239584],
								[114.287086, 22.239584],
								[114.287086, 22.239859],
								[114.287361, 22.239859],
								[114.287361, 22.240141],
								[114.287636, 22.240141],
								[114.287636, 22.240694],
								[114.287918, 22.240694],
								[114.287918, 22.240973],
								[114.287636, 22.240973],
								[114.287636, 22.241249],
								[114.287361, 22.241249],
								[114.287361, 22.241528],
								[114.287086, 22.241528],
								[114.287086, 22.241806],
								[114.285416, 22.241806],
								[114.285416, 22.242083],
								[114.284584, 22.242083],
								[114.284584, 22.242361],
								[114.284027, 22.242361],
								[114.284027, 22.242640],
								[114.283203, 22.242640],
								[114.283195, 22.242916],
								[114.282639, 22.242916],
								[114.282639, 22.243195],
								[114.282364, 22.243195],
								[114.282364, 22.243471],
								[114.282082, 22.243471],
								[114.282082, 22.243750],
								[114.281807, 22.243750],
								[114.281807, 22.244028],
								[114.281525, 22.244028],
								[114.281525, 22.244305],
								[114.280693, 22.244305],
								[114.280632, 22.244583],
								[114.280136, 22.244583],
								[114.280136, 22.245138],
								[114.279861, 22.245138],
								[114.279861, 22.245417],
								[114.280121, 22.245417],
								[114.280136, 22.245972],
								[114.280418, 22.245972],
								[114.280418, 22.246250],
								[114.280693, 22.246250],
								[114.280693, 22.247639],
								[114.280441, 22.247639],
								[114.280418, 22.248194],
								[114.280136, 22.248194],
								[114.280136, 22.248751],
								[114.279861, 22.248751],
								[114.279861, 22.249584],
								[114.280136, 22.249584],
								[114.280136, 22.249861],
								[114.280418, 22.249861],
								[114.280418, 22.250137],
								[114.280975, 22.250137],
								[114.280975, 22.250416],
								[114.281250, 22.250416],
								[114.281250, 22.250973],
								[114.281517, 22.250973],
								[114.281525, 22.251247],
								[114.281807, 22.251247],
								[114.281807, 22.251530],
								[114.282082, 22.251530],
								[114.282082, 22.251806],
								[114.282364, 22.251806],
								[114.282364, 22.252083],
								[114.282082, 22.252083],
								[114.282082, 22.252361],
								[114.282356, 22.252361],
								[114.282364, 22.252642],
								[114.282639, 22.252642],
								[114.282639, 22.252916],
								[114.282913, 22.252916],
								[114.282913, 22.253195],
								[114.283195, 22.253195],
								[114.283195, 22.253469],
								[114.283752, 22.253469],
								[114.283752, 22.253752],
								[114.284302, 22.253752],
								[114.284302, 22.254028],
								[114.284859, 22.254028],
								[114.284859, 22.254305],
								[114.285416, 22.254305],
								[114.285416, 22.254583],
								[114.285698, 22.254583],
								[114.285698, 22.254862],
								[114.285973, 22.254862],
								[114.285973, 22.254583],
								[114.286247, 22.254583],
								[114.286247, 22.254305],
								[114.286530, 22.254305],
								[114.286530, 22.253752],
								[114.288750, 22.253752],
								[114.288750, 22.255138],
								[114.289581, 22.255138],
								[114.289581, 22.255417],
								[114.289864, 22.255417],
								[114.289864, 22.255695],
								[114.290131, 22.255695],
								[114.290138, 22.255974],
								[114.290413, 22.255974],
								[114.290413, 22.256250],
								[114.290695, 22.256250],
								[114.290695, 22.256527],
								[114.290970, 22.256527],
								[114.290970, 22.256805],
								[114.291992, 22.256805],
								[114.292084, 22.256805],
								[114.292084, 22.257084],
								[114.292641, 22.257084],
								[114.292641, 22.257639],
								[114.292915, 22.257639],
								[114.292915, 22.258192],
								[114.294861, 22.258192],
								[114.294861, 22.258472],
								[114.295135, 22.258472],
								[114.295135, 22.258751],
								[114.295692, 22.258751],
								[114.295692, 22.259027],
								[114.296516, 22.259027],
								[114.296524, 22.257917],
								[114.296249, 22.257917],
								[114.296249, 22.257639],
								[114.295975, 22.257639],
								[114.295975, 22.256811],
								[114.296249, 22.256805],
								[114.296249, 22.256531],
								[114.296516, 22.256527]
							]
						],
						[
							[
								[114.352364, 22.261526],
								[114.352364, 22.261250],
								[114.352638, 22.261250],
								[114.352638, 22.260420],
								[114.352913, 22.260414],
								[114.352913, 22.258192],
								[114.353188, 22.258192],
								[114.353195, 22.257917],
								[114.353470, 22.257917],
								[114.353470, 22.256811],
								[114.353203, 22.256805],
								[114.353195, 22.255695],
								[114.352913, 22.255695],
								[114.352913, 22.254583],
								[114.353195, 22.254583],
								[114.353195, 22.254305],
								[114.352913, 22.254305],
								[114.352913, 22.253752],
								[114.352364, 22.253752],
								[114.352364, 22.253469],
								[114.350975, 22.253469],
								[114.350975, 22.253752],
								[114.350136, 22.253752],
								[114.350136, 22.253469],
								[114.349861, 22.253469],
								[114.349861, 22.253195],
								[114.348747, 22.253195],
								[114.348747, 22.253469],
								[114.348473, 22.253469],
								[114.348473, 22.253752],
								[114.348198, 22.253752],
								[114.348198, 22.254028],
								[114.347916, 22.254028],
								[114.347916, 22.254583],
								[114.348198, 22.254583],
								[114.348198, 22.255695],
								[114.347923, 22.255695],
								[114.347916, 22.256250],
								[114.347641, 22.256250],
								[114.347641, 22.256805],
								[114.347916, 22.256805],
								[114.347916, 22.257639],
								[114.348198, 22.257639],
								[114.348198, 22.257917],
								[114.348747, 22.257917],
								[114.348747, 22.257641],
								[114.349030, 22.257639],
								[114.349030, 22.257360],
								[114.350693, 22.257360],
								[114.350693, 22.257917],
								[114.350945, 22.257917],
								[114.350975, 22.258751],
								[114.351250, 22.258751],
								[114.351250, 22.259861],
								[114.350975, 22.259861],
								[114.350975, 22.260139],
								[114.351250, 22.260139],
								[114.351250, 22.260973],
								[114.351517, 22.260973],
								[114.351524, 22.261250],
								[114.351807, 22.261250],
								[114.351807, 22.261526],
								[114.352364, 22.261526]
							]
						],
						[
							[
								[114.369583, 22.266527],
								[114.369583, 22.265972],
								[114.369812, 22.265972],
								[114.369858, 22.265694],
								[114.370140, 22.265694],
								[114.370140, 22.265417],
								[114.370407, 22.265417],
								[114.370415, 22.265141],
								[114.370697, 22.265141],
								[114.370697, 22.264862],
								[114.370918, 22.264862],
								[114.370972, 22.263748],
								[114.370140, 22.263748],
								[114.370140, 22.264027],
								[114.369858, 22.264027],
								[114.369858, 22.264305],
								[114.369583, 22.264305],
								[114.369583, 22.264584],
								[114.369308, 22.264584],
								[114.369308, 22.265141],
								[114.369026, 22.265141],
								[114.369026, 22.265417],
								[114.368752, 22.265417],
								[114.368752, 22.266251],
								[114.369026, 22.266251],
								[114.369026, 22.266527],
								[114.369583, 22.266527]
							]
						],
						[
							[
								[114.350975, 22.271805],
								[114.350975, 22.271528],
								[114.351250, 22.271528],
								[114.351250, 22.270695],
								[114.351524, 22.270695],
								[114.351524, 22.269306],
								[114.351250, 22.269306],
								[114.351250, 22.268749],
								[114.351135, 22.268749],
								[114.350975, 22.268749],
								[114.350975, 22.267916],
								[114.350693, 22.267916],
								[114.350693, 22.267363],
								[114.350418, 22.267363],
								[114.350418, 22.267084],
								[114.350136, 22.267084],
								[114.350136, 22.266806],
								[114.349861, 22.266806],
								[114.349861, 22.266527],
								[114.349586, 22.266527],
								[114.349586, 22.264027],
								[114.349304, 22.264027],
								[114.349304, 22.263748],
								[114.349030, 22.263748],
								[114.349030, 22.263472],
								[114.348747, 22.263472],
								[114.348747, 22.263197],
								[114.348198, 22.263197],
								[114.348198, 22.262918],
								[114.347641, 22.262918],
								[114.347641, 22.262636],
								[114.346527, 22.262636],
								[114.346527, 22.262918],
								[114.345413, 22.262918],
								[114.345413, 22.262636],
								[114.344864, 22.262636],
								[114.344864, 22.262918],
								[114.345139, 22.262918],
								[114.345139, 22.263197],
								[114.345413, 22.263197],
								[114.345413, 22.263472],
								[114.345695, 22.263472],
								[114.345695, 22.264584],
								[114.345970, 22.264584],
								[114.345970, 22.265694],
								[114.346252, 22.265694],
								[114.346252, 22.267639],
								[114.346802, 22.267639],
								[114.346802, 22.267916],
								[114.347359, 22.267918],
								[114.347359, 22.268194],
								[114.347641, 22.268194],
								[114.347641, 22.268473],
								[114.347916, 22.268473],
								[114.347916, 22.268749],
								[114.348198, 22.268749],
								[114.348198, 22.269028],
								[114.348473, 22.269028],
								[114.348473, 22.270416],
								[114.348747, 22.270416],
								[114.348747, 22.270695],
								[114.349304, 22.270695],
								[114.349304, 22.270971],
								[114.349861, 22.270971],
								[114.349861, 22.271250],
								[114.350136, 22.271250],
								[114.350136, 22.271528],
								[114.350418, 22.271528],
								[114.350418, 22.271805],
								[114.350975, 22.271805]
							]
						],
						[
							[
								[114.314583, 22.277637],
								[114.314583, 22.277361],
								[114.314857, 22.277361],
								[114.314857, 22.276808],
								[114.315140, 22.276808],
								[114.315140, 22.276529],
								[114.315414, 22.276529],
								[114.315414, 22.276251],
								[114.315697, 22.276251],
								[114.315697, 22.274769],
								[114.315697, 22.274305],
								[114.315971, 22.274305],
								[114.315971, 22.274027],
								[114.315697, 22.274027],
								[114.315697, 22.273472],
								[114.315140, 22.273472],
								[114.315140, 22.273193],
								[114.314026, 22.273193],
								[114.314026, 22.274027],
								[114.313751, 22.274027],
								[114.313751, 22.274584],
								[114.313469, 22.274584],
								[114.313469, 22.274860],
								[114.312637, 22.274860],
								[114.312637, 22.275139],
								[114.312363, 22.275139],
								[114.312363, 22.275415],
								[114.311806, 22.275415],
								[114.311806, 22.275972],
								[114.312080, 22.275972],
								[114.312080, 22.276529],
								[114.312363, 22.276529],
								[114.312363, 22.276808],
								[114.312637, 22.276808],
								[114.312637, 22.277082],
								[114.312920, 22.277082],
								[114.312920, 22.277361],
								[114.313469, 22.277361],
								[114.313469, 22.277637],
								[114.314583, 22.277637]
							]
						],
						[
							[
								[114.366531, 22.322363],
								[114.366531, 22.322083],
								[114.366806, 22.322083],
								[114.366806, 22.321527],
								[114.366531, 22.321527],
								[114.366531, 22.320972],
								[114.366249, 22.320972],
								[114.366249, 22.319859],
								[114.366531, 22.319859],
								[114.366531, 22.319584],
								[114.367065, 22.319584],
								[114.367081, 22.319305],
								[114.367363, 22.319305],
								[114.367363, 22.319029],
								[114.367920, 22.319029],
								[114.367920, 22.318748],
								[114.368195, 22.318748],
								[114.368195, 22.318472],
								[114.368469, 22.318472],
								[114.368469, 22.317919],
								[114.368752, 22.317919],
								[114.368752, 22.316805],
								[114.369019, 22.316805],
								[114.369026, 22.316250],
								[114.369308, 22.316250],
								[114.369308, 22.315971],
								[114.369583, 22.315971],
								[114.369583, 22.314583],
								[114.369308, 22.314583],
								[114.369308, 22.314028],
								[114.369026, 22.314028],
								[114.369026, 22.313473],
								[114.369308, 22.313473],
								[114.369308, 22.313194],
								[114.369583, 22.313194],
								[114.369583, 22.312916],
								[114.369858, 22.312916],
								[114.369858, 22.312639],
								[114.370140, 22.312639],
								[114.370140, 22.312361],
								[114.370697, 22.312361],
								[114.370697, 22.312084],
								[114.371681, 22.312084],
								[114.371803, 22.312084],
								[114.371803, 22.311806],
								[114.371529, 22.311806],
								[114.371529, 22.311251],
								[114.371246, 22.311251],
								[114.371246, 22.310972],
								[114.370972, 22.310972],
								[114.370972, 22.310417],
								[114.370697, 22.310417],
								[114.370697, 22.310141],
								[114.368752, 22.310139],
								[114.368752, 22.309860],
								[114.368195, 22.309860],
								[114.368195, 22.309584],
								[114.367920, 22.309584],
								[114.367920, 22.308195],
								[114.368187, 22.308195],
								[114.368195, 22.307640],
								[114.368469, 22.307638],
								[114.368469, 22.306805],
								[114.368195, 22.306805],
								[114.368195, 22.306528],
								[114.367920, 22.306528],
								[114.367920, 22.306252],
								[114.367363, 22.306252],
								[114.367363, 22.306528],
								[114.366249, 22.306528],
								[114.366249, 22.306805],
								[114.365974, 22.306805],
								[114.365974, 22.307083],
								[114.364861, 22.307083],
								[114.364861, 22.307362],
								[114.364304, 22.307362],
								[114.364304, 22.307917],
								[114.364571, 22.307917],
								[114.364586, 22.308472],
								[114.364861, 22.308472],
								[114.364861, 22.308750],
								[114.365135, 22.308750],
								[114.365135, 22.310417],
								[114.364861, 22.310417],
								[114.364861, 22.310972],
								[114.364586, 22.310972],
								[114.364586, 22.311251],
								[114.364029, 22.311251],
								[114.364029, 22.311527],
								[114.363197, 22.311527],
								[114.363197, 22.311806],
								[114.362923, 22.311806],
								[114.362854, 22.312084],
								[114.362358, 22.312084],
								[114.362358, 22.312916],
								[114.361816, 22.312916],
								[114.361809, 22.313473],
								[114.361526, 22.313473],
								[114.361526, 22.314583],
								[114.360970, 22.314583],
								[114.360970, 22.314861],
								[114.361252, 22.314861],
								[114.361252, 22.315416],
								[114.360970, 22.315416],
								[114.360970, 22.315695],
								[114.360695, 22.315695],
								[114.360695, 22.317083],
								[114.360970, 22.317083],
								[114.360970, 22.317362],
								[114.360695, 22.317362],
								[114.360695, 22.317919],
								[114.360970, 22.317919],
								[114.360970, 22.319305],
								[114.361252, 22.319305],
								[114.361252, 22.319859],
								[114.361809, 22.319859],
								[114.361809, 22.320141],
								[114.362358, 22.320141],
								[114.362358, 22.320417],
								[114.362915, 22.320417],
								[114.362915, 22.320141],
								[114.363472, 22.320141],
								[114.363472, 22.320417],
								[114.364029, 22.320417],
								[114.364029, 22.320694],
								[114.364586, 22.320694],
								[114.364586, 22.320972],
								[114.364861, 22.320972],
								[114.364861, 22.321253],
								[114.365135, 22.321253],
								[114.365135, 22.321527],
								[114.365379, 22.321527],
								[114.365417, 22.321806],
								[114.365692, 22.321806],
								[114.365692, 22.322363],
								[114.366531, 22.322363]
							]
						],
						[
							[
								[114.356804, 22.325972],
								[114.356804, 22.325417],
								[114.357086, 22.325417],
								[114.357086, 22.324871],
								[114.356804, 22.324862],
								[114.356804, 22.324583],
								[114.356529, 22.324583],
								[114.356529, 22.324028],
								[114.356247, 22.324028],
								[114.356247, 22.323750],
								[114.355972, 22.323750],
								[114.355972, 22.323471],
								[114.355698, 22.323471],
								[114.355698, 22.322916],
								[114.355415, 22.322916],
								[114.355415, 22.322639],
								[114.355141, 22.322639],
								[114.355141, 22.322363],
								[114.354584, 22.322363],
								[114.354584, 22.322083],
								[114.354309, 22.322083],
								[114.354309, 22.321527],
								[114.354027, 22.321527],
								[114.354027, 22.320972],
								[114.353752, 22.320972],
								[114.353752, 22.317362],
								[114.353470, 22.317362],
								[114.353470, 22.316805],
								[114.353195, 22.316805],
								[114.353195, 22.316526],
								[114.351807, 22.316526],
								[114.351807, 22.315695],
								[114.351524, 22.315695],
								[114.351524, 22.315416],
								[114.349304, 22.315416],
								[114.349304, 22.315971],
								[114.349030, 22.315971],
								[114.349030, 22.316250],
								[114.348747, 22.316250],
								[114.348747, 22.315971],
								[114.348473, 22.315971],
								[114.348473, 22.312916],
								[114.348198, 22.312916],
								[114.348198, 22.312641],
								[114.347641, 22.312639],
								[114.347641, 22.312916],
								[114.347092, 22.312916],
								[114.347084, 22.313473],
								[114.346802, 22.313473],
								[114.346802, 22.313749],
								[114.346527, 22.313749],
								[114.346527, 22.314304],
								[114.346252, 22.314304],
								[114.346252, 22.314583],
								[114.345970, 22.314583],
								[114.345970, 22.315140],
								[114.346252, 22.315140],
								[114.346252, 22.315695],
								[114.345970, 22.315695],
								[114.345970, 22.317362],
								[114.346252, 22.317362],
								[114.346252, 22.317919],
								[114.346527, 22.317919],
								[114.346527, 22.318195],
								[114.346779, 22.318195],
								[114.346802, 22.318472],
								[114.347084, 22.318472],
								[114.347084, 22.318748],
								[114.347359, 22.318748],
								[114.347359, 22.319029],
								[114.347641, 22.319029],
								[114.347641, 22.320141],
								[114.347359, 22.320141],
								[114.347359, 22.320972],
								[114.347641, 22.320972],
								[114.347641, 22.321527],
								[114.347878, 22.321527],
								[114.347916, 22.322083],
								[114.348198, 22.322083],
								[114.348198, 22.322639],
								[114.348473, 22.322639],
								[114.348473, 22.323195],
								[114.348747, 22.323195],
								[114.348747, 22.323471],
								[114.349030, 22.323471],
								[114.349030, 22.323750],
								[114.349297, 22.323750],
								[114.349304, 22.324306],
								[114.349564, 22.324306],
								[114.349586, 22.324583],
								[114.350136, 22.324583],
								[114.350136, 22.324862],
								[114.352081, 22.324862],
								[114.352081, 22.324583],
								[114.352325, 22.324583],
								[114.352364, 22.324306],
								[114.352638, 22.324306],
								[114.352638, 22.324030],
								[114.352913, 22.324028],
								[114.352913, 22.323750],
								[114.353195, 22.323750],
								[114.353195, 22.324028],
								[114.353470, 22.324028],
								[114.353470, 22.324306],
								[114.353729, 22.324306],
								[114.353752, 22.324583],
								[114.354027, 22.324583],
								[114.354027, 22.325138],
								[114.354309, 22.325138],
								[114.354309, 22.325417],
								[114.354561, 22.325417],
								[114.354584, 22.325693],
								[114.354858, 22.325693],
								[114.354858, 22.325972],
								[114.356804, 22.325972]
							]
						],
						[
							[
								[114.369026, 22.327084],
								[114.369026, 22.326805],
								[114.369583, 22.326805],
								[114.369583, 22.326250],
								[114.369858, 22.326250],
								[114.369858, 22.325417],
								[114.367638, 22.325417],
								[114.367638, 22.325138],
								[114.367363, 22.325138],
								[114.367363, 22.324862],
								[114.366531, 22.324862],
								[114.366531, 22.325138],
								[114.366249, 22.325138],
								[114.366249, 22.326250],
								[114.366531, 22.326250],
								[114.366531, 22.326529],
								[114.367081, 22.326529],
								[114.367081, 22.326805],
								[114.367638, 22.326805],
								[114.367638, 22.327084],
								[114.369026, 22.327084]
							]
						],
						[
							[
								[114.295135, 22.331806],
								[114.295135, 22.331530],
								[114.295418, 22.331530],
								[114.295418, 22.331247],
								[114.295975, 22.331247],
								[114.295975, 22.330973],
								[114.296249, 22.330973],
								[114.296249, 22.330694],
								[114.296524, 22.330694],
								[114.296524, 22.330420],
								[114.296806, 22.330416],
								[114.296806, 22.330137],
								[114.297081, 22.330137],
								[114.297081, 22.329861],
								[114.297638, 22.329861],
								[114.297638, 22.329584],
								[114.297920, 22.329584],
								[114.297920, 22.329306],
								[114.298195, 22.329306],
								[114.298195, 22.329025],
								[114.298470, 22.329025],
								[114.298470, 22.328751],
								[114.298752, 22.328751],
								[114.298752, 22.328472],
								[114.299026, 22.328472],
								[114.299026, 22.328194],
								[114.299309, 22.328194],
								[114.299309, 22.327915],
								[114.299858, 22.327915],
								[114.299858, 22.327639],
								[114.300140, 22.327639],
								[114.300140, 22.327362],
								[114.300415, 22.327362],
								[114.300415, 22.327084],
								[114.300697, 22.327084],
								[114.300697, 22.326805],
								[114.300972, 22.326805],
								[114.300972, 22.326529],
								[114.301247, 22.326529],
								[114.301247, 22.326250],
								[114.301529, 22.326250],
								[114.301529, 22.325972],
								[114.302086, 22.325972],
								[114.302086, 22.325693],
								[114.302361, 22.325693],
								[114.302361, 22.325333],
								[114.302361, 22.324839],
								[114.302361, 22.324028],
								[114.302635, 22.324028],
								[114.302635, 22.322639],
								[114.302086, 22.322639],
								[114.302086, 22.322529],
								[114.302086, 22.322083],
								[114.301804, 22.322083],
								[114.301804, 22.321806],
								[114.301529, 22.321806],
								[114.301529, 22.322083],
								[114.298752, 22.322083],
								[114.298752, 22.322363],
								[114.297363, 22.322363],
								[114.297363, 22.322639],
								[114.296249, 22.322639],
								[114.296249, 22.322916],
								[114.295692, 22.322916],
								[114.295692, 22.323471],
								[114.295418, 22.323471],
								[114.295418, 22.323750],
								[114.294861, 22.323750],
								[114.294861, 22.324028],
								[114.294304, 22.324028],
								[114.294304, 22.324306],
								[114.294586, 22.324306],
								[114.294586, 22.324862],
								[114.294304, 22.324862],
								[114.294304, 22.325138],
								[114.293747, 22.325138],
								[114.293747, 22.325417],
								[114.293472, 22.325417],
								[114.293472, 22.326250],
								[114.293198, 22.326250],
								[114.293198, 22.327084],
								[114.292915, 22.327084],
								[114.292915, 22.327915],
								[114.292641, 22.327915],
								[114.292641, 22.328194],
								[114.292915, 22.328194],
								[114.292915, 22.328751],
								[114.293198, 22.328751],
								[114.293198, 22.330137],
								[114.293472, 22.330137],
								[114.293472, 22.330694],
								[114.293198, 22.330694],
								[114.293198, 22.331247],
								[114.293472, 22.331247],
								[114.293472, 22.331530],
								[114.293747, 22.331530],
								[114.293747, 22.331247],
								[114.294029, 22.331247],
								[114.294029, 22.331530],
								[114.294586, 22.331530],
								[114.294586, 22.331806],
								[114.295135, 22.331806]
							]
						],
						[
							[
								[114.370407, 22.335138],
								[114.370415, 22.334583],
								[114.370659, 22.334583],
								[114.370697, 22.334305],
								[114.370972, 22.334305],
								[114.370972, 22.333469],
								[114.372086, 22.333469],
								[114.372086, 22.333195],
								[114.372360, 22.333195],
								[114.372360, 22.332916],
								[114.372643, 22.332916],
								[114.372643, 22.332642],
								[114.372917, 22.332642],
								[114.372917, 22.332359],
								[114.373466, 22.332359],
								[114.373474, 22.332083],
								[114.373749, 22.332083],
								[114.373749, 22.331806],
								[114.374031, 22.331806],
								[114.374031, 22.331530],
								[114.374306, 22.331530],
								[114.374306, 22.331247],
								[114.374580, 22.331247],
								[114.374580, 22.330973],
								[114.374863, 22.330973],
								[114.374863, 22.330694],
								[114.375420, 22.330694],
								[114.375420, 22.330420],
								[114.375687, 22.330416],
								[114.375694, 22.330141],
								[114.375969, 22.330137],
								[114.375969, 22.329584],
								[114.376480, 22.329584],
								[114.376526, 22.329306],
								[114.377083, 22.329306],
								[114.377083, 22.329025],
								[114.378197, 22.329025],
								[114.378197, 22.328751],
								[114.378471, 22.328751],
								[114.378471, 22.328194],
								[114.378197, 22.328194],
								[114.378197, 22.327639],
								[114.377914, 22.327639],
								[114.377914, 22.327362],
								[114.376808, 22.327362],
								[114.376808, 22.327639],
								[114.374863, 22.327639],
								[114.374863, 22.327362],
								[114.374580, 22.327362],
								[114.374580, 22.327639],
								[114.373474, 22.327639],
								[114.373474, 22.327915],
								[114.372086, 22.327915],
								[114.372086, 22.328194],
								[114.371803, 22.328194],
								[114.371803, 22.328472],
								[114.371529, 22.328472],
								[114.371529, 22.329025],
								[114.371246, 22.329025],
								[114.371246, 22.329584],
								[114.370972, 22.329584],
								[114.370972, 22.330416],
								[114.370697, 22.330416],
								[114.370697, 22.331247],
								[114.370415, 22.331247],
								[114.370415, 22.331806],
								[114.370140, 22.331806],
								[114.370140, 22.332642],
								[114.370415, 22.332642],
								[114.370415, 22.333752],
								[114.370140, 22.333752],
								[114.370140, 22.334028],
								[114.369583, 22.334028],
								[114.369583, 22.334583],
								[114.369858, 22.334583],
								[114.369858, 22.335138],
								[114.370407, 22.335138]
							]
						],
						[
							[
								[114.334305, 22.337639],
								[114.334305, 22.337360],
								[114.334862, 22.337360],
								[114.334862, 22.337084],
								[114.335136, 22.337084],
								[114.335136, 22.335564],
								[114.335136, 22.335417],
								[114.335243, 22.335417],
								[114.335419, 22.335417],
								[114.335419, 22.335188],
								[114.335419, 22.334862],
								[114.336250, 22.334862],
								[114.336250, 22.334305],
								[114.335976, 22.334305],
								[114.335976, 22.334028],
								[114.335136, 22.334028],
								[114.335136, 22.333752],
								[114.334862, 22.333752],
								[114.334862, 22.333469],
								[114.332916, 22.333469],
								[114.332916, 22.333752],
								[114.332642, 22.333752],
								[114.332642, 22.334028],
								[114.332359, 22.334028],
								[114.332359, 22.334305],
								[114.332085, 22.334305],
								[114.332085, 22.335417],
								[114.332642, 22.335417],
								[114.332642, 22.335974],
								[114.332916, 22.335974],
								[114.332916, 22.336805],
								[114.333191, 22.336805],
								[114.333191, 22.337360],
								[114.333473, 22.337360],
								[114.333473, 22.337639],
								[114.334305, 22.337639]
							]
						],
						[
							[
								[114.367081, 22.339306],
								[114.367081, 22.339027],
								[114.367920, 22.339027],
								[114.367920, 22.338751],
								[114.368195, 22.338751],
								[114.368195, 22.338194],
								[114.368469, 22.338194],
								[114.368469, 22.336527],
								[114.368195, 22.336527],
								[114.368195, 22.336250],
								[114.367920, 22.336250],
								[114.367920, 22.335695],
								[114.367638, 22.335695],
								[114.367638, 22.334862],
								[114.367363, 22.334862],
								[114.367363, 22.334305],
								[114.366806, 22.334305],
								[114.366806, 22.334028],
								[114.366531, 22.334028],
								[114.366531, 22.333752],
								[114.366249, 22.333752],
								[114.366249, 22.332083],
								[114.366531, 22.332083],
								[114.366531, 22.331530],
								[114.366806, 22.331530],
								[114.366806, 22.331247],
								[114.366920, 22.331247],
								[114.367081, 22.331247],
								[114.367081, 22.330694],
								[114.367363, 22.330694],
								[114.367363, 22.329861],
								[114.366531, 22.329861],
								[114.366531, 22.329584],
								[114.366249, 22.329584],
								[114.366249, 22.328472],
								[114.365417, 22.328472],
								[114.365417, 22.328751],
								[114.365135, 22.328751],
								[114.365135, 22.329025],
								[114.364586, 22.329025],
								[114.364586, 22.329306],
								[114.364029, 22.329306],
								[114.364029, 22.329584],
								[114.363472, 22.329584],
								[114.363472, 22.329861],
								[114.363197, 22.329861],
								[114.363197, 22.330137],
								[114.362083, 22.330137],
								[114.362083, 22.330416],
								[114.361809, 22.330416],
								[114.361809, 22.330137],
								[114.360970, 22.330137],
								[114.360970, 22.329861],
								[114.360420, 22.329861],
								[114.360420, 22.330137],
								[114.360138, 22.330137],
								[114.360138, 22.330973],
								[114.360420, 22.330973],
								[114.360420, 22.331806],
								[114.360970, 22.331806],
								[114.360970, 22.332642],
								[114.361252, 22.332642],
								[114.361252, 22.332916],
								[114.360970, 22.332916],
								[114.360970, 22.333195],
								[114.360420, 22.333195],
								[114.360420, 22.335417],
								[114.360695, 22.335417],
								[114.360695, 22.335695],
								[114.360970, 22.335695],
								[114.360970, 22.337084],
								[114.361252, 22.337084],
								[114.361252, 22.337639],
								[114.360970, 22.337639],
								[114.360970, 22.338194],
								[114.361252, 22.338194],
								[114.361252, 22.338472],
								[114.361526, 22.338472],
								[114.361526, 22.338751],
								[114.361809, 22.338751],
								[114.361809, 22.338472],
								[114.362358, 22.338472],
								[114.362358, 22.338751],
								[114.362915, 22.338751],
								[114.362915, 22.339027],
								[114.363472, 22.339027],
								[114.363472, 22.338751],
								[114.363747, 22.338751],
								[114.363747, 22.338194],
								[114.364029, 22.338194],
								[114.364029, 22.337917],
								[114.364250, 22.337917],
								[114.364586, 22.337917],
								[114.364586, 22.337715],
								[114.364586, 22.337639],
								[114.365135, 22.337639],
								[114.365135, 22.338751],
								[114.365417, 22.338751],
								[114.365417, 22.339306],
								[114.366539, 22.339306],
								[114.366814, 22.339306],
								[114.367081, 22.339306]
							]
						],
						[
							[
								[114.287086, 22.364027],
								[114.287086, 22.362638],
								[114.286804, 22.362638],
								[114.286804, 22.362083],
								[114.286530, 22.362083],
								[114.286530, 22.362364],
								[114.285973, 22.362364],
								[114.285973, 22.362638],
								[114.285698, 22.362638],
								[114.285698, 22.362917],
								[114.285416, 22.362917],
								[114.285416, 22.363194],
								[114.286247, 22.363195],
								[114.286247, 22.363474],
								[114.286530, 22.363474],
								[114.286530, 22.364027],
								[114.286804, 22.364027],
								[114.286804, 22.364305],
								[114.287636, 22.364305],
								[114.287636, 22.364027],
								[114.287086, 22.364027]
							]
						],
						[
							[
								[114.294586, 22.374306],
								[114.294586, 22.374027],
								[114.294861, 22.374027],
								[114.294861, 22.373751],
								[114.295135, 22.373751],
								[114.295135, 22.374027],
								[114.295975, 22.374027],
								[114.295975, 22.373472],
								[114.296249, 22.373472],
								[114.296249, 22.373194],
								[114.295975, 22.373194],
								[114.295975, 22.372917],
								[114.295418, 22.372917],
								[114.295418, 22.373194],
								[114.295135, 22.373194],
								[114.295135, 22.372917],
								[114.294861, 22.372917],
								[114.294861, 22.373194],
								[114.294586, 22.373194],
								[114.294586, 22.373472],
								[114.294304, 22.373472],
								[114.294304, 22.373194],
								[114.294029, 22.373194],
								[114.294029, 22.373472],
								[114.293472, 22.373472],
								[114.293472, 22.373194],
								[114.292641, 22.373194],
								[114.292641, 22.372917],
								[114.292915, 22.372917],
								[114.292915, 22.372084],
								[114.293472, 22.372084],
								[114.293472, 22.371248],
								[114.293633, 22.371248],
								[114.293747, 22.371248],
								[114.293747, 22.371101],
								[114.293747, 22.370972],
								[114.294029, 22.370972],
								[114.294029, 22.370724],
								[114.293747, 22.370695],
								[114.293747, 22.370136],
								[114.293472, 22.370136],
								[114.293472, 22.369862],
								[114.293747, 22.369862],
								[114.293747, 22.369026],
								[114.294304, 22.369026],
								[114.294304, 22.368473],
								[114.294586, 22.368473],
								[114.294586, 22.368195],
								[114.294861, 22.368195],
								[114.294861, 22.367920],
								[114.295135, 22.367914],
								[114.295135, 22.367640],
								[114.295692, 22.367640],
								[114.295692, 22.367361],
								[114.295135, 22.367361],
								[114.295135, 22.366804],
								[114.295692, 22.366804],
								[114.295692, 22.366249],
								[114.296471, 22.366249],
								[114.297081, 22.366249],
								[114.297081, 22.366005],
								[114.297638, 22.365973],
								[114.297638, 22.364027],
								[114.297470, 22.364027],
								[114.297363, 22.364027],
								[114.297363, 22.363857],
								[114.297363, 22.363750],
								[114.296249, 22.363750],
								[114.296249, 22.362917],
								[114.295975, 22.362917],
								[114.295975, 22.362638],
								[114.295692, 22.362638],
								[114.295692, 22.362364],
								[114.295975, 22.362364],
								[114.295975, 22.361528],
								[114.296249, 22.361528],
								[114.296249, 22.360970],
								[114.295975, 22.360970],
								[114.295975, 22.360416],
								[114.296249, 22.360416],
								[114.296249, 22.359583],
								[114.296524, 22.359583],
								[114.296524, 22.359306],
								[114.296249, 22.359306],
								[114.296249, 22.359030],
								[114.296524, 22.359030],
								[114.296524, 22.358194],
								[114.296249, 22.358194],
								[114.296249, 22.357916],
								[114.296524, 22.357916],
								[114.296524, 22.357637],
								[114.296806, 22.357637],
								[114.296806, 22.357185],
								[114.296806, 22.357084],
								[114.296524, 22.357082],
								[114.296524, 22.356806],
								[114.296806, 22.356806],
								[114.296806, 22.356527],
								[114.297081, 22.356527],
								[114.297081, 22.355972],
								[114.297363, 22.355972],
								[114.297363, 22.355694],
								[114.297081, 22.355694],
								[114.297081, 22.355415],
								[114.297363, 22.355415],
								[114.297363, 22.354860],
								[114.297081, 22.354860],
								[114.297081, 22.354584],
								[114.297363, 22.354584],
								[114.297363, 22.354029],
								[114.296806, 22.354029],
								[114.296806, 22.353472],
								[114.296524, 22.353472],
								[114.296524, 22.353193],
								[114.296249, 22.353193],
								[114.296249, 22.352638],
								[114.295692, 22.352638],
								[114.295692, 22.353193],
								[114.294861, 22.353193],
								[114.294861, 22.354305],
								[114.295135, 22.354305],
								[114.295135, 22.354584],
								[114.294861, 22.354584],
								[114.294861, 22.354860],
								[114.295135, 22.354860],
								[114.295135, 22.355139],
								[114.295418, 22.355139],
								[114.295418, 22.355415],
								[114.295975, 22.355415],
								[114.295975, 22.356527],
								[114.295692, 22.356527],
								[114.295692, 22.356806],
								[114.295135, 22.356806],
								[114.295135, 22.357082],
								[114.294586, 22.357084],
								[114.294586, 22.357361],
								[114.294304, 22.357361],
								[114.294304, 22.357487],
								[114.294304, 22.357637],
								[114.293472, 22.357637],
								[114.293472, 22.357361],
								[114.292915, 22.357361],
								[114.292915, 22.358473],
								[114.293198, 22.358473],
								[114.293198, 22.359306],
								[114.292915, 22.359306],
								[114.292915, 22.359583],
								[114.292641, 22.359583],
								[114.292641, 22.360140],
								[114.292358, 22.360140],
								[114.292358, 22.360416],
								[114.292084, 22.360416],
								[114.292084, 22.360695],
								[114.292358, 22.360695],
								[114.292358, 22.362083],
								[114.292641, 22.362083],
								[114.292641, 22.362364],
								[114.292358, 22.362364],
								[114.292358, 22.362665],
								[114.292358, 22.362917],
								[114.292107, 22.362917],
								[114.291809, 22.362917],
								[114.291809, 22.363194],
								[114.291527, 22.363194],
								[114.291527, 22.363750],
								[114.291275, 22.363750],
								[114.291252, 22.364027],
								[114.291000, 22.364027],
								[114.290970, 22.364305],
								[114.290718, 22.364305],
								[114.290413, 22.364305],
								[114.290413, 22.364582],
								[114.289864, 22.364582],
								[114.289864, 22.364861],
								[114.289307, 22.364861],
								[114.289307, 22.365139],
								[114.289024, 22.365139],
								[114.289024, 22.365696],
								[114.288475, 22.365696],
								[114.288475, 22.365973],
								[114.288193, 22.365973],
								[114.288193, 22.366528],
								[114.287918, 22.366528],
								[114.287918, 22.367083],
								[114.287086, 22.367083],
								[114.287086, 22.367361],
								[114.287636, 22.367361],
								[114.287636, 22.367640],
								[114.288193, 22.367640],
								[114.288193, 22.367914],
								[114.288750, 22.367914],
								[114.288750, 22.368195],
								[114.289307, 22.368195],
								[114.289307, 22.368750],
								[114.289581, 22.368750],
								[114.289581, 22.369305],
								[114.290138, 22.369305],
								[114.290138, 22.369862],
								[114.289864, 22.369862],
								[114.289864, 22.370136],
								[114.290138, 22.370136],
								[114.290138, 22.370695],
								[114.290413, 22.370695],
								[114.290413, 22.370972],
								[114.290695, 22.370972],
								[114.290695, 22.371531],
								[114.290970, 22.371531],
								[114.290970, 22.372084],
								[114.290413, 22.372084],
								[114.290413, 22.372358],
								[114.290695, 22.372358],
								[114.290695, 22.372917],
								[114.290970, 22.372917],
								[114.290970, 22.373472],
								[114.291252, 22.373472],
								[114.291252, 22.373751],
								[114.291527, 22.373751],
								[114.291527, 22.374027],
								[114.291809, 22.374027],
								[114.291809, 22.374306],
								[114.292915, 22.374306],
								[114.292915, 22.374863],
								[114.293198, 22.374863],
								[114.293198, 22.375139],
								[114.293747, 22.375139],
								[114.293747, 22.375416],
								[114.294861, 22.375416],
								[114.294861, 22.374306],
								[114.294586, 22.374306]
							]
						],
						[
							[
								[114.286530, 22.375973],
								[114.286530, 22.375694],
								[114.286804, 22.375694],
								[114.286804, 22.375139],
								[114.286530, 22.375139],
								[114.286530, 22.374027],
								[114.285973, 22.374027],
								[114.285973, 22.373472],
								[114.285416, 22.373472],
								[114.285416, 22.373751],
								[114.284584, 22.373751],
								[114.284584, 22.374027],
								[114.284302, 22.374027],
								[114.284302, 22.374584],
								[114.284859, 22.374584],
								[114.284859, 22.374863],
								[114.285698, 22.374863],
								[114.285698, 22.375416],
								[114.285973, 22.375416],
								[114.285973, 22.375694],
								[114.286247, 22.375694],
								[114.286247, 22.375973],
								[114.286530, 22.375973]
							]
						],
						[
							[
								[114.322914, 22.376806],
								[114.322914, 22.376249],
								[114.323196, 22.376249],
								[114.323196, 22.375973],
								[114.324028, 22.375973],
								[114.324028, 22.376249],
								[114.324585, 22.376249],
								[114.324585, 22.375973],
								[114.325417, 22.375973],
								[114.325417, 22.375694],
								[114.325691, 22.375694],
								[114.325691, 22.375416],
								[114.326530, 22.375416],
								[114.326530, 22.375139],
								[114.326805, 22.375139],
								[114.326805, 22.374863],
								[114.327080, 22.374863],
								[114.327080, 22.374584],
								[114.327362, 22.374584],
								[114.327362, 22.374306],
								[114.327637, 22.374306],
								[114.327637, 22.374027],
								[114.328476, 22.374027],
								[114.328476, 22.373751],
								[114.329308, 22.373751],
								[114.329308, 22.372917],
								[114.329582, 22.372917],
								[114.329582, 22.371805],
								[114.329865, 22.371805],
								[114.329865, 22.371531],
								[114.329582, 22.371531],
								[114.329582, 22.370136],
								[114.328476, 22.370136],
								[114.328476, 22.369305],
								[114.328751, 22.369305],
								[114.328751, 22.368750],
								[114.328476, 22.368750],
								[114.328476, 22.367914],
								[114.328751, 22.367914],
								[114.328751, 22.367083],
								[114.328476, 22.367083],
								[114.328476, 22.366528],
								[114.328751, 22.366528],
								[114.328751, 22.366249],
								[114.328476, 22.366249],
								[114.328476, 22.365696],
								[114.327637, 22.365696],
								[114.327637, 22.365417],
								[114.327080, 22.365417],
								[114.327080, 22.365139],
								[114.326805, 22.365139],
								[114.326805, 22.366249],
								[114.327080, 22.366249],
								[114.327080, 22.366804],
								[114.326805, 22.366804],
								[114.326805, 22.367361],
								[114.326530, 22.367361],
								[114.326530, 22.367640],
								[114.326248, 22.367640],
								[114.326248, 22.367914],
								[114.326530, 22.367914],
								[114.326530, 22.368750],
								[114.326248, 22.368750],
								[114.326248, 22.369026],
								[114.325691, 22.369026],
								[114.325691, 22.369583],
								[114.325974, 22.369583],
								[114.325974, 22.369862],
								[114.325691, 22.369862],
								[114.325691, 22.370695],
								[114.325974, 22.370695],
								[114.325974, 22.372358],
								[114.325691, 22.372358],
								[114.325691, 22.372641],
								[114.326248, 22.372641],
								[114.326248, 22.372917],
								[114.325974, 22.372917],
								[114.325974, 22.373194],
								[114.325417, 22.373194],
								[114.325417, 22.373472],
								[114.325142, 22.373472],
								[114.325142, 22.373751],
								[114.324860, 22.373751],
								[114.324860, 22.374027],
								[114.324303, 22.374027],
								[114.324303, 22.372084],
								[114.324028, 22.372084],
								[114.324028, 22.371805],
								[114.323753, 22.371805],
								[114.323753, 22.371531],
								[114.323471, 22.371531],
								[114.323471, 22.371248],
								[114.323196, 22.371248],
								[114.323196, 22.370972],
								[114.323471, 22.370972],
								[114.323471, 22.370695],
								[114.322914, 22.370695],
								[114.322914, 22.370417],
								[114.322639, 22.370417],
								[114.322639, 22.371531],
								[114.321808, 22.371531],
								[114.321808, 22.372084],
								[114.321251, 22.372084],
								[114.321251, 22.372358],
								[114.320694, 22.372358],
								[114.320694, 22.372641],
								[114.320419, 22.372641],
								[114.320419, 22.372917],
								[114.320694, 22.372917],
								[114.320694, 22.373194],
								[114.320969, 22.373194],
								[114.320969, 22.374027],
								[114.320694, 22.374027],
								[114.320694, 22.374584],
								[114.321251, 22.374584],
								[114.321251, 22.374863],
								[114.320969, 22.374863],
								[114.320969, 22.375139],
								[114.320694, 22.375139],
								[114.320694, 22.376249],
								[114.320969, 22.376249],
								[114.320969, 22.375973],
								[114.321808, 22.375973],
								[114.321808, 22.376806],
								[114.322914, 22.376806]
							]
						],
						[
							[
								[114.288193, 22.380138],
								[114.288193, 22.379583],
								[114.289307, 22.379583],
								[114.289307, 22.379028],
								[114.289581, 22.379028],
								[114.289581, 22.378195],
								[114.289307, 22.378195],
								[114.289307, 22.377916],
								[114.289024, 22.377916],
								[114.289024, 22.378471],
								[114.288475, 22.378471],
								[114.288475, 22.378750],
								[114.287918, 22.378750],
								[114.287918, 22.379305],
								[114.287361, 22.379305],
								[114.287361, 22.379862],
								[114.287918, 22.379862],
								[114.287918, 22.380138],
								[114.288193, 22.380138]
							]
						],
						[
							[
								[114.307915, 22.380972],
								[114.307915, 22.380695],
								[114.308472, 22.380695],
								[114.308472, 22.380417],
								[114.308746, 22.380417],
								[114.308746, 22.380138],
								[114.309029, 22.380138],
								[114.309029, 22.379862],
								[114.309303, 22.379862],
								[114.309303, 22.379583],
								[114.309586, 22.379583],
								[114.309586, 22.379862],
								[114.310974, 22.379862],
								[114.310974, 22.380138],
								[114.311531, 22.380138],
								[114.311531, 22.380417],
								[114.313194, 22.380417],
								[114.313194, 22.380138],
								[114.313751, 22.380138],
								[114.313751, 22.379305],
								[114.314026, 22.379305],
								[114.314026, 22.378750],
								[114.313751, 22.378750],
								[114.313751, 22.378647],
								[114.313751, 22.378195],
								[114.313469, 22.378195],
								[114.313469, 22.377640],
								[114.313194, 22.377640],
								[114.313194, 22.377361],
								[114.312920, 22.377361],
								[114.312920, 22.377083],
								[114.312637, 22.377083],
								[114.312637, 22.376528],
								[114.313469, 22.376528],
								[114.313469, 22.376806],
								[114.313751, 22.376806],
								[114.313751, 22.377083],
								[114.314026, 22.377083],
								[114.314026, 22.377640],
								[114.314308, 22.377640],
								[114.314308, 22.377916],
								[114.314583, 22.377916],
								[114.314583, 22.378195],
								[114.315414, 22.378195],
								[114.315414, 22.378471],
								[114.315987, 22.378471],
								[114.316170, 22.378471],
								[114.316254, 22.378471],
								[114.316254, 22.378750],
								[114.316803, 22.378750],
								[114.316803, 22.378471],
								[114.317360, 22.378471],
								[114.317360, 22.377756],
								[114.317360, 22.377640],
								[114.317558, 22.377640],
								[114.317642, 22.377640],
								[114.317642, 22.377083],
								[114.317917, 22.377083],
								[114.317917, 22.376806],
								[114.318192, 22.376806],
								[114.318192, 22.376249],
								[114.318474, 22.376249],
								[114.318474, 22.375973],
								[114.318192, 22.375973],
								[114.318192, 22.375416],
								[114.317917, 22.375416],
								[114.317917, 22.374584],
								[114.317360, 22.374584],
								[114.317360, 22.374027],
								[114.317085, 22.374027],
								[114.317085, 22.373751],
								[114.317360, 22.373751],
								[114.317360, 22.372993],
								[114.317360, 22.372084],
								[114.317642, 22.372084],
								[114.317642, 22.371531],
								[114.317917, 22.371531],
								[114.317917, 22.371248],
								[114.318192, 22.371248],
								[114.318192, 22.370695],
								[114.318474, 22.370695],
								[114.318474, 22.370417],
								[114.319031, 22.370417],
								[114.319031, 22.369862],
								[114.319305, 22.369862],
								[114.319305, 22.369583],
								[114.319580, 22.369583],
								[114.319580, 22.368750],
								[114.319862, 22.368750],
								[114.319862, 22.368195],
								[114.320137, 22.368195],
								[114.320137, 22.367361],
								[114.320419, 22.367361],
								[114.320419, 22.366774],
								[114.320419, 22.365973],
								[114.320694, 22.365973],
								[114.320694, 22.364027],
								[114.320419, 22.364027],
								[114.320419, 22.363750],
								[114.320137, 22.363750],
								[114.320137, 22.362638],
								[114.319862, 22.362638],
								[114.319862, 22.362364],
								[114.319580, 22.362364],
								[114.319580, 22.361252],
								[114.319862, 22.361252],
								[114.319862, 22.360695],
								[114.320137, 22.360695],
								[114.320137, 22.360416],
								[114.321808, 22.360416],
								[114.321808, 22.360142],
								[114.322365, 22.360140],
								[114.322365, 22.359861],
								[114.323196, 22.359861],
								[114.323196, 22.359583],
								[114.323753, 22.359583],
								[114.323753, 22.359030],
								[114.324303, 22.359030],
								[114.324303, 22.358747],
								[114.324585, 22.358747],
								[114.324585, 22.358473],
								[114.324860, 22.358473],
								[114.324860, 22.358194],
								[114.325417, 22.358194],
								[114.325417, 22.357916],
								[114.326248, 22.357916],
								[114.326248, 22.357637],
								[114.326805, 22.357637],
								[114.326805, 22.357361],
								[114.327362, 22.357361],
								[114.327362, 22.356806],
								[114.327919, 22.356806],
								[114.327919, 22.355909],
								[114.327919, 22.355694],
								[114.327637, 22.355694],
								[114.327637, 22.355139],
								[114.327362, 22.355139],
								[114.327362, 22.354860],
								[114.327080, 22.354860],
								[114.327080, 22.354029],
								[114.326805, 22.354029],
								[114.326805, 22.353907],
								[114.326805, 22.353472],
								[114.327080, 22.353472],
								[114.327080, 22.353193],
								[114.326805, 22.353193],
								[114.326805, 22.352638],
								[114.327080, 22.352638],
								[114.327080, 22.351870],
								[114.327080, 22.351170],
								[114.327080, 22.350973],
								[114.326805, 22.350973],
								[114.326805, 22.350416],
								[114.326530, 22.350416],
								[114.326530, 22.350140],
								[114.326248, 22.350140],
								[114.326248, 22.349306],
								[114.325974, 22.349306],
								[114.325974, 22.348749],
								[114.326164, 22.348749],
								[114.326248, 22.348749],
								[114.326248, 22.348194],
								[114.326126, 22.348194],
								[114.325974, 22.348194],
								[114.325974, 22.347639],
								[114.325287, 22.347639],
								[114.325142, 22.347639],
								[114.325142, 22.347359],
								[114.324028, 22.347359],
								[114.324028, 22.346806],
								[114.324303, 22.346806],
								[114.324303, 22.346056],
								[114.324303, 22.345970],
								[114.324585, 22.345970],
								[114.324585, 22.345493],
								[114.324585, 22.345419],
								[114.324860, 22.345419],
								[114.324860, 22.344940],
								[114.324860, 22.344782],
								[114.324860, 22.344305],
								[114.324585, 22.344305],
								[114.324585, 22.342916],
								[114.324303, 22.342916],
								[114.324303, 22.342083],
								[114.324585, 22.342083],
								[114.324585, 22.341808],
								[114.324860, 22.341808],
								[114.324860, 22.341249],
								[114.325974, 22.341249],
								[114.325974, 22.340973],
								[114.326248, 22.340973],
								[114.326248, 22.340414],
								[114.326530, 22.340414],
								[114.326530, 22.339582],
								[114.326744, 22.339582],
								[114.326805, 22.339027],
								[114.327080, 22.339027],
								[114.327080, 22.338472],
								[114.327362, 22.338472],
								[114.327362, 22.338194],
								[114.327637, 22.338194],
								[114.327637, 22.337639],
								[114.327919, 22.337639],
								[114.327919, 22.337360],
								[114.328194, 22.337360],
								[114.328194, 22.337084],
								[114.328476, 22.337084],
								[114.328476, 22.336527],
								[114.328751, 22.336527],
								[114.328751, 22.336250],
								[114.329025, 22.336250],
								[114.329025, 22.335974],
								[114.329308, 22.335974],
								[114.329308, 22.335417],
								[114.329582, 22.335417],
								[114.329582, 22.335138],
								[114.329865, 22.335138],
								[114.329865, 22.334862],
								[114.330139, 22.334862],
								[114.330139, 22.334305],
								[114.330414, 22.334305],
								[114.330414, 22.334028],
								[114.330696, 22.334028],
								[114.330696, 22.331247],
								[114.330414, 22.331247],
								[114.330414, 22.328751],
								[114.330139, 22.328751],
								[114.330139, 22.328472],
								[114.330414, 22.328472],
								[114.330414, 22.328169],
								[114.330414, 22.327639],
								[114.329865, 22.327639],
								[114.329865, 22.327362],
								[114.329025, 22.327362],
								[114.329025, 22.327639],
								[114.328827, 22.327639],
								[114.328476, 22.327639],
								[114.328476, 22.327362],
								[114.328194, 22.327360],
								[114.328194, 22.326969],
								[114.328194, 22.326805],
								[114.327919, 22.326805],
								[114.327919, 22.326628],
								[114.327866, 22.326578],
								[114.327637, 22.326529],
								[114.327637, 22.326250],
								[114.327362, 22.326250],
								[114.327362, 22.325972],
								[114.327080, 22.325972],
								[114.327080, 22.325693],
								[114.326805, 22.325693],
								[114.326805, 22.325417],
								[114.325974, 22.325417],
								[114.325974, 22.325693],
								[114.325691, 22.325693],
								[114.325691, 22.325972],
								[114.325417, 22.325972],
								[114.325417, 22.326250],
								[114.324860, 22.326250],
								[114.324860, 22.326529],
								[114.324585, 22.326529],
								[114.324585, 22.326805],
								[114.324028, 22.326805],
								[114.324028, 22.326529],
								[114.323471, 22.326529],
								[114.323471, 22.326250],
								[114.322639, 22.326250],
								[114.322639, 22.326529],
								[114.322044, 22.326529],
								[114.321808, 22.326529],
								[114.321808, 22.326250],
								[114.321526, 22.326250],
								[114.321526, 22.325972],
								[114.319862, 22.325972],
								[114.319862, 22.326250],
								[114.319580, 22.326250],
								[114.319580, 22.326529],
								[114.319862, 22.326529],
								[114.319862, 22.326805],
								[114.319580, 22.326805],
								[114.319580, 22.327915],
								[114.319305, 22.327915],
								[114.319305, 22.328472],
								[114.318748, 22.328472],
								[114.318748, 22.328751],
								[114.319031, 22.328751],
								[114.319031, 22.329025],
								[114.318748, 22.329025],
								[114.318748, 22.329306],
								[114.318474, 22.329306],
								[114.318474, 22.330416],
								[114.318192, 22.330416],
								[114.318192, 22.330694],
								[114.318474, 22.330694],
								[114.318474, 22.330973],
								[114.318192, 22.330973],
								[114.318192, 22.331806],
								[114.317917, 22.331806],
								[114.317917, 22.332083],
								[114.317642, 22.332083],
								[114.317642, 22.332916],
								[114.317085, 22.332916],
								[114.317085, 22.335417],
								[114.316803, 22.335417],
								[114.316803, 22.335974],
								[114.316528, 22.335974],
								[114.316528, 22.336527],
								[114.316254, 22.336527],
								[114.316254, 22.336805],
								[114.315971, 22.336805],
								[114.315971, 22.337360],
								[114.315697, 22.337360],
								[114.315697, 22.337917],
								[114.315414, 22.337917],
								[114.315414, 22.338751],
								[114.315697, 22.338751],
								[114.315697, 22.339027],
								[114.315971, 22.339027],
								[114.315971, 22.339306],
								[114.316254, 22.339306],
								[114.316254, 22.340139],
								[114.316528, 22.340139],
								[114.316528, 22.342083],
								[114.316803, 22.342083],
								[114.316803, 22.342361],
								[114.317085, 22.342361],
								[114.317085, 22.342083],
								[114.318192, 22.342083],
								[114.318192, 22.342361],
								[114.318474, 22.342361],
								[114.318474, 22.343197],
								[114.316528, 22.343197],
								[114.316528, 22.343748],
								[114.316254, 22.343748],
								[114.316254, 22.344027],
								[114.315971, 22.344027],
								[114.315971, 22.344584],
								[114.315414, 22.344584],
								[114.315414, 22.344862],
								[114.315140, 22.344862],
								[114.315140, 22.345419],
								[114.314308, 22.345419],
								[114.314308, 22.345694],
								[114.313751, 22.345694],
								[114.313751, 22.344862],
								[114.313469, 22.344862],
								[114.313469, 22.344305],
								[114.312920, 22.344305],
								[114.312920, 22.343472],
								[114.312637, 22.343472],
								[114.312637, 22.343197],
								[114.312363, 22.343197],
								[114.312363, 22.342916],
								[114.311531, 22.342916],
								[114.311531, 22.343197],
								[114.310974, 22.343197],
								[114.310974, 22.343472],
								[114.310692, 22.343472],
								[114.310692, 22.343748],
								[114.310417, 22.343748],
								[114.310417, 22.344027],
								[114.310143, 22.344027],
								[114.310143, 22.344336],
								[114.310143, 22.344584],
								[114.309860, 22.344584],
								[114.309860, 22.344862],
								[114.309532, 22.344862],
								[114.309303, 22.344862],
								[114.309303, 22.345032],
								[114.309303, 22.345140],
								[114.309204, 22.345140],
								[114.308197, 22.345140],
								[114.308197, 22.345419],
								[114.307640, 22.345419],
								[114.307640, 22.345694],
								[114.307083, 22.345694],
								[114.307083, 22.346251],
								[114.306808, 22.346251],
								[114.306808, 22.346527],
								[114.306526, 22.346527],
								[114.306526, 22.346806],
								[114.306252, 22.346806],
								[114.306252, 22.347359],
								[114.305695, 22.347359],
								[114.305695, 22.347639],
								[114.305420, 22.347639],
								[114.305420, 22.347918],
								[114.305138, 22.347918],
								[114.305138, 22.348473],
								[114.304863, 22.348473],
								[114.304863, 22.348749],
								[114.305138, 22.348749],
								[114.305138, 22.349028],
								[114.304863, 22.349028],
								[114.304863, 22.349583],
								[114.304581, 22.349583],
								[114.304581, 22.350416],
								[114.304031, 22.350416],
								[114.304031, 22.350973],
								[114.303749, 22.350973],
								[114.303749, 22.351250],
								[114.303474, 22.351250],
								[114.303474, 22.352083],
								[114.303192, 22.352083],
								[114.303192, 22.352362],
								[114.303474, 22.352362],
								[114.303474, 22.354029],
								[114.303749, 22.354029],
								[114.303749, 22.354860],
								[114.304031, 22.354860],
								[114.304031, 22.355139],
								[114.304306, 22.355139],
								[114.304306, 22.355415],
								[114.305138, 22.355415],
								[114.305138, 22.355972],
								[114.305420, 22.355972],
								[114.305420, 22.356527],
								[114.305695, 22.356527],
								[114.305695, 22.357361],
								[114.305969, 22.357361],
								[114.305969, 22.358194],
								[114.305695, 22.358194],
								[114.305695, 22.358747],
								[114.305420, 22.358747],
								[114.305420, 22.359583],
								[114.305138, 22.359583],
								[114.305138, 22.360416],
								[114.305420, 22.360416],
								[114.305420, 22.360695],
								[114.305969, 22.360695],
								[114.305969, 22.361528],
								[114.306252, 22.361528],
								[114.306252, 22.361805],
								[114.305969, 22.361805],
								[114.305969, 22.362917],
								[114.305695, 22.362917],
								[114.305695, 22.363750],
								[114.305420, 22.363750],
								[114.305420, 22.364582],
								[114.304863, 22.364582],
								[114.304863, 22.364861],
								[114.304581, 22.364861],
								[114.304581, 22.365696],
								[114.304306, 22.365696],
								[114.304306, 22.366249],
								[114.303474, 22.366249],
								[114.303474, 22.366528],
								[114.302917, 22.366528],
								[114.302917, 22.366804],
								[114.302361, 22.366804],
								[114.302361, 22.367083],
								[114.302086, 22.367083],
								[114.302086, 22.367361],
								[114.301529, 22.367361],
								[114.301529, 22.367640],
								[114.301247, 22.367640],
								[114.301247, 22.368195],
								[114.300697, 22.368195],
								[114.300697, 22.370695],
								[114.300972, 22.370695],
								[114.300972, 22.371248],
								[114.300697, 22.371248],
								[114.300697, 22.372641],
								[114.300415, 22.372641],
								[114.300415, 22.373472],
								[114.300140, 22.373472],
								[114.300140, 22.373751],
								[114.299858, 22.373751],
								[114.299858, 22.374584],
								[114.299583, 22.374584],
								[114.299583, 22.375139],
								[114.299858, 22.375139],
								[114.299858, 22.375416],
								[114.300140, 22.375416],
								[114.300140, 22.376528],
								[114.300415, 22.376528],
								[114.300415, 22.380417],
								[114.300972, 22.380417],
								[114.300972, 22.380695],
								[114.302086, 22.380695],
								[114.302086, 22.380972],
								[114.302917, 22.380972],
								[114.302917, 22.380417],
								[114.303474, 22.380417],
								[114.303474, 22.380138],
								[114.304581, 22.380138],
								[114.304581, 22.379862],
								[114.304863, 22.379862],
								[114.304863, 22.379583],
								[114.305969, 22.379583],
								[114.305969, 22.379028],
								[114.306252, 22.379028],
								[114.306252, 22.378195],
								[114.306526, 22.378195],
								[114.306526, 22.377640],
								[114.306252, 22.377640],
								[114.306252, 22.376249],
								[114.305969, 22.376249],
								[114.305969, 22.375694],
								[114.306252, 22.375694],
								[114.306252, 22.375139],
								[114.306526, 22.375139],
								[114.306526, 22.374863],
								[114.307083, 22.374863],
								[114.307083, 22.375139],
								[114.307358, 22.375139],
								[114.307358, 22.375973],
								[114.307640, 22.375973],
								[114.307640, 22.376528],
								[114.307915, 22.376528],
								[114.307915, 22.377083],
								[114.308197, 22.377083],
								[114.308197, 22.377640],
								[114.307915, 22.377640],
								[114.307915, 22.378195],
								[114.307640, 22.378195],
								[114.307640, 22.379028],
								[114.307358, 22.379028],
								[114.307358, 22.379305],
								[114.307640, 22.379305],
								[114.307640, 22.380417],
								[114.307083, 22.380417],
								[114.307083, 22.380695],
								[114.307358, 22.380695],
								[114.307358, 22.380972],
								[114.307915, 22.380972]
							]
						],
						[
							[
								[114.280418, 22.381805],
								[114.280418, 22.381527],
								[114.280693, 22.381527],
								[114.280693, 22.381250],
								[114.281250, 22.381250],
								[114.281250, 22.380972],
								[114.281807, 22.380972],
								[114.281807, 22.380695],
								[114.282082, 22.380695],
								[114.282082, 22.380417],
								[114.282364, 22.380417],
								[114.282364, 22.380695],
								[114.282639, 22.380695],
								[114.282639, 22.380417],
								[114.282913, 22.380417],
								[114.282913, 22.379862],
								[114.282639, 22.379862],
								[114.282639, 22.378471],
								[114.282364, 22.378471],
								[114.282364, 22.378750],
								[114.282082, 22.378750],
								[114.282082, 22.378471],
								[114.281807, 22.378471],
								[114.281807, 22.378750],
								[114.280975, 22.378750],
								[114.280975, 22.379862],
								[114.280693, 22.379862],
								[114.280693, 22.380138],
								[114.279861, 22.380138],
								[114.279861, 22.379862],
								[114.279587, 22.379862],
								[114.279587, 22.380972],
								[114.279305, 22.380972],
								[114.279305, 22.381250],
								[114.279030, 22.381250],
								[114.279030, 22.381527],
								[114.279587, 22.381527],
								[114.279587, 22.381805],
								[114.280418, 22.381805]
							]
						],
						[
							[
								[114.385971, 22.403749],
								[114.385971, 22.403473],
								[114.386253, 22.403473],
								[114.386253, 22.403194],
								[114.386528, 22.403194],
								[114.386528, 22.400694],
								[114.385414, 22.400694],
								[114.385414, 22.401806],
								[114.385139, 22.401806],
								[114.385139, 22.402639],
								[114.384865, 22.402639],
								[114.384865, 22.403473],
								[114.385139, 22.403473],
								[114.385139, 22.403749],
								[114.385971, 22.403749]
							]
						],
						[
							[
								[114.368469, 22.441904],
								[114.368469, 22.441528],
								[114.368752, 22.441528],
								[114.368752, 22.440969],
								[114.369026, 22.440969],
								[114.369026, 22.440695],
								[114.369308, 22.440695],
								[114.369308, 22.439859],
								[114.369583, 22.439859],
								[114.369583, 22.438747],
								[114.369858, 22.438747],
								[114.369858, 22.438194],
								[114.370102, 22.438194],
								[114.370140, 22.437916],
								[114.370407, 22.437916],
								[114.370415, 22.437641],
								[114.371529, 22.437637],
								[114.371529, 22.437361],
								[114.372086, 22.437361],
								[114.372086, 22.437637],
								[114.372360, 22.437637],
								[114.372360, 22.437916],
								[114.372643, 22.437916],
								[114.372643, 22.437641],
								[114.373474, 22.437637],
								[114.373474, 22.437916],
								[114.374031, 22.437916],
								[114.374031, 22.437637],
								[114.374580, 22.437637],
								[114.374580, 22.437916],
								[114.374863, 22.437916],
								[114.374863, 22.438194],
								[114.375420, 22.438194],
								[114.375420, 22.438747],
								[114.375694, 22.438747],
								[114.375694, 22.439030],
								[114.375969, 22.439030],
								[114.375969, 22.439306],
								[114.376808, 22.439306],
								[114.376808, 22.439030],
								[114.377083, 22.439030],
								[114.377083, 22.438747],
								[114.377357, 22.438747],
								[114.377357, 22.437920],
								[114.377640, 22.437916],
								[114.377640, 22.437361],
								[114.379303, 22.437361],
								[114.379303, 22.437084],
								[114.379570, 22.437084],
								[114.379585, 22.436806],
								[114.381248, 22.436806],
								[114.381248, 22.436531],
								[114.381531, 22.436527],
								[114.381531, 22.436806],
								[114.382324, 22.436806],
								[114.382362, 22.437084],
								[114.383186, 22.437084],
								[114.383194, 22.437361],
								[114.383469, 22.437361],
								[114.383469, 22.437637],
								[114.384026, 22.437637],
								[114.384026, 22.437916],
								[114.384308, 22.437916],
								[114.384308, 22.438194],
								[114.384583, 22.438194],
								[114.384583, 22.438473],
								[114.385696, 22.438473],
								[114.385696, 22.438747],
								[114.386803, 22.438747],
								[114.386803, 22.439030],
								[114.389305, 22.439030],
								[114.389305, 22.438747],
								[114.390694, 22.438747],
								[114.390694, 22.439030],
								[114.390953, 22.439030],
								[114.390976, 22.439583],
								[114.391251, 22.439583],
								[114.391251, 22.440695],
								[114.391525, 22.440695],
								[114.391525, 22.440969],
								[114.392082, 22.440969],
								[114.392082, 22.441528],
								[114.392357, 22.441528],
								[114.392365, 22.441252],
								[114.392639, 22.441252],
								[114.392639, 22.440969],
								[114.392365, 22.440969],
								[114.392365, 22.440695],
								[114.392639, 22.440695],
								[114.392639, 22.440416],
								[114.392365, 22.440416],
								[114.392365, 22.439859],
								[114.392082, 22.439859],
								[114.392082, 22.438747],
								[114.392357, 22.438747],
								[114.392365, 22.438473],
								[114.392082, 22.438473],
								[114.392082, 22.437916],
								[114.392357, 22.437916],
								[114.392365, 22.437361],
								[114.392639, 22.437361],
								[114.392639, 22.437084],
								[114.392906, 22.437084],
								[114.392914, 22.437361],
								[114.393753, 22.437361],
								[114.393753, 22.437637],
								[114.394028, 22.437637],
								[114.394028, 22.438194],
								[114.394302, 22.438194],
								[114.394302, 22.438473],
								[114.395142, 22.438473],
								[114.395142, 22.438194],
								[114.395416, 22.438194],
								[114.395416, 22.437084],
								[114.395691, 22.437084],
								[114.395691, 22.436806],
								[114.395973, 22.436806],
								[114.395973, 22.436531],
								[114.396248, 22.436527],
								[114.396248, 22.435694],
								[114.396530, 22.435694],
								[114.396530, 22.435139],
								[114.396805, 22.435139],
								[114.396805, 22.435415],
								[114.397087, 22.435415],
								[114.397087, 22.435694],
								[114.398476, 22.435694],
								[114.398476, 22.435415],
								[114.399582, 22.435415],
								[114.399582, 22.435139],
								[114.399864, 22.435139],
								[114.399864, 22.435694],
								[114.400406, 22.435694],
								[114.400414, 22.436527],
								[114.400970, 22.436527],
								[114.400970, 22.436251],
								[114.401802, 22.436251],
								[114.401802, 22.435972],
								[114.402283, 22.435972],
								[114.402359, 22.435972],
								[114.402359, 22.435415],
								[114.401802, 22.435415],
								[114.401802, 22.435141],
								[114.400978, 22.435139],
								[114.400970, 22.434860],
								[114.400696, 22.434860],
								[114.400696, 22.434311],
								[114.400139, 22.434305],
								[114.400139, 22.434031],
								[114.399864, 22.434029],
								[114.399864, 22.433750],
								[114.399582, 22.433750],
								[114.399582, 22.433472],
								[114.399307, 22.433472],
								[114.399307, 22.433193],
								[114.399025, 22.433193],
								[114.399025, 22.430973],
								[114.398750, 22.430973],
								[114.398750, 22.430695],
								[114.397919, 22.430695],
								[114.397919, 22.430140],
								[114.396530, 22.430140],
								[114.396530, 22.429861],
								[114.396248, 22.429861],
								[114.396248, 22.429583],
								[114.395973, 22.429583],
								[114.395973, 22.429028],
								[114.396248, 22.429028],
								[114.396248, 22.428749],
								[114.396797, 22.428749],
								[114.396805, 22.428473],
								[114.397087, 22.428473],
								[114.397087, 22.427917],
								[114.397362, 22.427917],
								[114.397362, 22.427090],
								[114.397636, 22.427084],
								[114.397636, 22.426809],
								[114.397911, 22.426805],
								[114.397919, 22.426531],
								[114.398193, 22.426529],
								[114.398193, 22.425419],
								[114.397919, 22.425419],
								[114.397919, 22.424583],
								[114.397636, 22.424583],
								[114.397636, 22.422916],
								[114.397911, 22.422916],
								[114.397919, 22.422361],
								[114.398193, 22.422361],
								[114.398193, 22.422083],
								[114.398750, 22.422083],
								[114.398750, 22.421806],
								[114.399307, 22.421806],
								[114.399307, 22.421530],
								[114.399582, 22.421528],
								[114.399582, 22.420694],
								[114.399864, 22.420694],
								[114.399864, 22.420139],
								[114.400970, 22.420139],
								[114.400970, 22.420416],
								[114.401527, 22.420416],
								[114.401527, 22.420141],
								[114.402077, 22.420139],
								[114.402084, 22.419861],
								[114.402359, 22.419861],
								[114.402359, 22.419584],
								[114.403473, 22.419582],
								[114.403473, 22.417639],
								[114.403748, 22.417639],
								[114.403748, 22.417360],
								[114.404297, 22.417360],
								[114.404305, 22.417084],
								[114.404587, 22.417084],
								[114.404587, 22.416805],
								[114.404861, 22.416805],
								[114.404861, 22.415974],
								[114.404587, 22.415974],
								[114.404587, 22.415417],
								[114.404305, 22.415417],
								[114.404305, 22.414862],
								[114.404587, 22.414862],
								[114.404587, 22.414583],
								[114.405136, 22.414583],
								[114.405136, 22.414305],
								[114.405418, 22.414305],
								[114.405418, 22.413752],
								[114.405693, 22.413752],
								[114.405693, 22.412638],
								[114.405418, 22.412638],
								[114.405418, 22.412359],
								[114.404861, 22.412359],
								[114.404861, 22.412083],
								[114.404305, 22.412083],
								[114.404305, 22.411806],
								[114.403748, 22.411806],
								[114.403748, 22.411530],
								[114.402359, 22.411530],
								[114.402359, 22.411806],
								[114.400970, 22.411806],
								[114.400970, 22.411530],
								[114.400414, 22.411530],
								[114.400414, 22.411247],
								[114.399025, 22.411247],
								[114.399025, 22.411530],
								[114.399307, 22.411530],
								[114.399307, 22.411806],
								[114.399025, 22.411806],
								[114.399025, 22.412083],
								[114.398499, 22.412083],
								[114.398476, 22.412638],
								[114.397919, 22.412638],
								[114.397919, 22.413195],
								[114.397636, 22.413195],
								[114.397636, 22.413752],
								[114.397087, 22.413752],
								[114.397087, 22.414028],
								[114.396248, 22.414028],
								[114.396248, 22.414305],
								[114.395973, 22.414305],
								[114.395973, 22.414583],
								[114.395416, 22.414583],
								[114.395416, 22.414862],
								[114.395142, 22.414862],
								[114.395142, 22.415138],
								[114.394585, 22.415138],
								[114.394585, 22.415974],
								[114.394302, 22.415974],
								[114.394302, 22.416250],
								[114.394585, 22.416250],
								[114.394585, 22.416805],
								[114.394302, 22.416805],
								[114.394302, 22.417639],
								[114.394028, 22.417639],
								[114.394028, 22.418194],
								[114.393753, 22.418194],
								[114.393684, 22.418472],
								[114.393471, 22.418472],
								[114.393471, 22.418751],
								[114.393196, 22.418751],
								[114.393196, 22.419306],
								[114.392914, 22.419306],
								[114.392914, 22.419861],
								[114.392639, 22.419861],
								[114.392639, 22.420139],
								[114.392365, 22.420139],
								[114.392365, 22.419861],
								[114.390976, 22.419861],
								[114.390976, 22.419584],
								[114.389984, 22.419584],
								[114.389862, 22.419584],
								[114.389862, 22.419306],
								[114.389030, 22.419306],
								[114.389030, 22.419584],
								[114.388885, 22.419584],
								[114.387642, 22.419584],
								[114.387642, 22.420139],
								[114.387085, 22.420139],
								[114.387085, 22.420694],
								[114.386528, 22.420694],
								[114.386528, 22.420973],
								[114.385414, 22.420973],
								[114.385414, 22.420694],
								[114.385139, 22.420694],
								[114.385139, 22.419861],
								[114.384865, 22.419861],
								[114.384865, 22.419584],
								[114.384583, 22.419584],
								[114.384583, 22.419306],
								[114.384308, 22.419306],
								[114.384308, 22.419031],
								[114.384033, 22.419027],
								[114.384026, 22.418472],
								[114.383751, 22.418472],
								[114.383751, 22.418194],
								[114.383469, 22.418194],
								[114.383469, 22.417917],
								[114.383194, 22.417917],
								[114.383194, 22.417639],
								[114.382919, 22.417639],
								[114.382919, 22.417360],
								[114.382637, 22.417360],
								[114.382637, 22.417084],
								[114.382362, 22.417084],
								[114.382362, 22.416529],
								[114.381805, 22.416529],
								[114.381805, 22.416805],
								[114.381531, 22.416805],
								[114.381531, 22.417084],
								[114.380417, 22.417084],
								[114.380417, 22.416805],
								[114.380142, 22.416805],
								[114.380142, 22.416529],
								[114.379585, 22.416529],
								[114.379585, 22.415695],
								[114.379303, 22.415695],
								[114.379303, 22.414862],
								[114.379028, 22.414862],
								[114.379028, 22.414583],
								[114.378754, 22.414583],
								[114.378754, 22.414305],
								[114.378471, 22.414305],
								[114.378471, 22.414028],
								[114.378197, 22.414028],
								[114.378197, 22.413195],
								[114.377914, 22.413195],
								[114.377914, 22.412359],
								[114.378197, 22.412359],
								[114.378197, 22.412088],
								[114.378471, 22.412083],
								[114.378471, 22.411810],
								[114.378746, 22.411806],
								[114.378754, 22.411530],
								[114.379028, 22.411530],
								[114.379028, 22.411249],
								[114.379303, 22.411247],
								[114.379303, 22.410973],
								[114.379585, 22.410973],
								[114.379585, 22.410694],
								[114.379303, 22.410694],
								[114.379303, 22.409306],
								[114.379028, 22.409306],
								[114.379028, 22.409025],
								[114.378197, 22.409025],
								[114.378197, 22.409306],
								[114.377937, 22.409306],
								[114.377914, 22.409584],
								[114.377640, 22.409584],
								[114.377640, 22.409861],
								[114.377357, 22.409861],
								[114.377357, 22.410137],
								[114.377083, 22.410137],
								[114.377083, 22.410694],
								[114.376251, 22.410694],
								[114.376251, 22.410973],
								[114.375969, 22.410973],
								[114.375969, 22.410694],
								[114.375694, 22.410694],
								[114.375694, 22.410419],
								[114.375420, 22.410419],
								[114.375420, 22.409861],
								[114.375137, 22.409861],
								[114.375137, 22.409584],
								[114.374863, 22.409584],
								[114.374863, 22.409306],
								[114.374580, 22.409306],
								[114.374580, 22.408194],
								[114.374863, 22.408194],
								[114.374863, 22.407915],
								[114.375137, 22.407915],
								[114.375137, 22.407640],
								[114.375420, 22.407639],
								[114.375420, 22.404861],
								[114.375137, 22.404861],
								[114.375137, 22.403749],
								[114.374863, 22.403749],
								[114.374863, 22.403194],
								[114.375137, 22.403194],
								[114.375137, 22.402916],
								[114.374863, 22.402916],
								[114.374863, 22.402363],
								[114.374580, 22.402363],
								[114.374580, 22.402082],
								[114.374306, 22.402082],
								[114.374306, 22.401806],
								[114.374031, 22.401806],
								[114.374031, 22.401531],
								[114.373474, 22.401527],
								[114.373474, 22.400972],
								[114.373192, 22.400972],
								[114.373192, 22.400694],
								[114.372086, 22.400694],
								[114.372086, 22.400417],
								[114.370415, 22.400417],
								[114.370415, 22.400141],
								[114.369858, 22.400141],
								[114.369858, 22.399858],
								[114.369583, 22.399858],
								[114.369583, 22.399305],
								[114.369858, 22.399305],
								[114.369858, 22.399027],
								[114.370140, 22.399027],
								[114.370140, 22.398472],
								[114.370415, 22.398472],
								[114.370415, 22.398195],
								[114.371246, 22.398195],
								[114.371246, 22.397921],
								[114.371803, 22.397919],
								[114.371803, 22.396805],
								[114.372086, 22.396805],
								[114.372086, 22.396250],
								[114.373466, 22.396250],
								[114.373474, 22.396526],
								[114.373749, 22.396526],
								[114.373749, 22.397362],
								[114.374306, 22.397362],
								[114.374306, 22.397636],
								[114.375420, 22.397636],
								[114.375420, 22.397083],
								[114.375687, 22.397083],
								[114.375694, 22.398195],
								[114.375969, 22.398195],
								[114.375969, 22.398472],
								[114.377083, 22.398472],
								[114.377083, 22.397636],
								[114.377357, 22.397636],
								[114.377357, 22.397083],
								[114.377609, 22.397083],
								[114.377640, 22.396805],
								[114.378197, 22.396805],
								[114.378197, 22.396250],
								[114.378471, 22.396250],
								[114.378471, 22.395971],
								[114.380142, 22.395971],
								[114.380142, 22.396250],
								[114.380417, 22.396250],
								[114.380417, 22.395695],
								[114.380692, 22.395695],
								[114.380692, 22.395140],
								[114.380974, 22.395140],
								[114.380974, 22.394861],
								[114.381248, 22.394861],
								[114.381248, 22.394028],
								[114.381531, 22.394028],
								[114.381531, 22.393749],
								[114.381805, 22.393749],
								[114.381805, 22.393473],
								[114.382080, 22.393473],
								[114.382080, 22.392361],
								[114.381805, 22.392361],
								[114.381805, 22.391527],
								[114.382080, 22.391527],
								[114.382080, 22.390972],
								[114.382637, 22.390972],
								[114.382637, 22.391251],
								[114.384026, 22.391251],
								[114.384026, 22.390427],
								[114.384583, 22.390417],
								[114.384583, 22.389584],
								[114.384865, 22.389584],
								[114.384865, 22.389029],
								[114.385139, 22.389029],
								[114.385139, 22.389305],
								[114.385414, 22.389305],
								[114.385414, 22.389584],
								[114.385696, 22.389584],
								[114.385696, 22.389860],
								[114.386253, 22.389862],
								[114.386253, 22.390139],
								[114.386513, 22.390139],
								[114.386528, 22.390694],
								[114.386803, 22.390694],
								[114.386803, 22.390972],
								[114.387360, 22.390972],
								[114.387360, 22.389313],
								[114.388474, 22.389305],
								[114.388474, 22.389584],
								[114.389030, 22.389584],
								[114.389030, 22.389305],
								[114.389305, 22.389305],
								[114.389305, 22.389029],
								[114.390137, 22.389029],
								[114.390137, 22.388750],
								[114.390419, 22.388750],
								[114.390419, 22.388472],
								[114.390976, 22.388472],
								[114.390976, 22.387083],
								[114.391251, 22.387083],
								[114.391251, 22.386530],
								[114.390976, 22.386530],
								[114.390976, 22.383194],
								[114.391235, 22.383194],
								[114.391251, 22.382360],
								[114.390976, 22.382360],
								[114.390976, 22.381250],
								[114.391251, 22.381250],
								[114.391251, 22.380701],
								[114.391525, 22.380695],
								[114.391525, 22.379305],
								[114.391808, 22.379305],
								[114.391808, 22.378750],
								[114.392082, 22.378750],
								[114.392082, 22.377920],
								[114.392357, 22.377916],
								[114.392365, 22.377361],
								[114.392082, 22.377361],
								[114.392082, 22.377083],
								[114.391808, 22.377083],
								[114.391808, 22.376806],
								[114.391251, 22.376806],
								[114.391251, 22.376528],
								[114.390976, 22.376528],
								[114.390976, 22.376251],
								[114.388191, 22.376249],
								[114.388191, 22.375973],
								[114.387917, 22.375973],
								[114.387917, 22.375694],
								[114.388153, 22.375694],
								[114.388191, 22.375139],
								[114.388466, 22.375139],
								[114.388474, 22.374863],
								[114.388748, 22.374863],
								[114.388748, 22.374306],
								[114.389030, 22.374306],
								[114.389030, 22.374027],
								[114.389305, 22.374027],
								[114.389305, 22.373194],
								[114.389580, 22.373194],
								[114.389580, 22.372917],
								[114.389862, 22.372917],
								[114.389862, 22.372358],
								[114.390137, 22.372358],
								[114.390137, 22.372084],
								[114.390419, 22.372084],
								[114.390419, 22.371531],
								[114.390686, 22.371531],
								[114.390694, 22.370972],
								[114.391251, 22.370972],
								[114.391251, 22.370695],
								[114.391525, 22.370695],
								[114.391525, 22.370136],
								[114.391808, 22.370136],
								[114.391808, 22.369030],
								[114.392082, 22.369026],
								[114.392082, 22.368195],
								[114.391808, 22.368195],
								[114.391808, 22.367914],
								[114.391525, 22.367914],
								[114.391525, 22.367640],
								[114.391251, 22.367640],
								[114.391251, 22.367083],
								[114.390976, 22.367083],
								[114.390976, 22.366528],
								[114.391251, 22.366528],
								[114.391251, 22.365696],
								[114.390694, 22.365696],
								[114.390694, 22.365417],
								[114.390419, 22.365417],
								[114.390419, 22.365141],
								[114.389580, 22.365139],
								[114.389580, 22.364861],
								[114.388748, 22.364861],
								[114.388748, 22.365139],
								[114.387917, 22.365139],
								[114.387917, 22.365417],
								[114.387260, 22.365417],
								[114.387085, 22.365417],
								[114.387085, 22.365696],
								[114.386528, 22.365696],
								[114.386528, 22.365973],
								[114.386253, 22.365973],
								[114.386253, 22.366249],
								[114.385971, 22.366249],
								[114.385971, 22.366804],
								[114.385696, 22.366804],
								[114.385696, 22.367640],
								[114.385414, 22.367640],
								[114.385414, 22.368195],
								[114.385139, 22.368195],
								[114.385139, 22.369583],
								[114.384865, 22.369583],
								[114.384865, 22.370417],
								[114.384583, 22.370417],
								[114.384583, 22.370695],
								[114.384026, 22.370695],
								[114.384026, 22.371248],
								[114.383751, 22.371248],
								[114.383751, 22.371805],
								[114.383469, 22.371805],
								[114.383469, 22.372084],
								[114.383194, 22.372084],
								[114.383194, 22.372917],
								[114.382919, 22.372917],
								[114.382919, 22.373194],
								[114.382637, 22.373194],
								[114.382637, 22.375416],
								[114.382080, 22.375416],
								[114.382080, 22.374584],
								[114.381531, 22.374584],
								[114.381531, 22.374027],
								[114.380974, 22.374027],
								[114.380974, 22.373751],
								[114.380142, 22.373751],
								[114.380142, 22.373472],
								[114.379303, 22.373472],
								[114.379303, 22.373751],
								[114.378754, 22.373751],
								[114.378754, 22.374027],
								[114.378471, 22.374027],
								[114.378471, 22.374584],
								[114.378197, 22.374584],
								[114.378197, 22.374863],
								[114.377640, 22.374863],
								[114.377640, 22.375139],
								[114.377083, 22.375139],
								[114.377083, 22.375416],
								[114.376808, 22.375416],
								[114.376808, 22.375141],
								[114.376526, 22.375139],
								[114.376526, 22.374584],
								[114.376251, 22.374584],
								[114.376251, 22.374027],
								[114.375969, 22.374027],
								[114.375969, 22.373751],
								[114.375694, 22.373751],
								[114.375694, 22.373472],
								[114.375420, 22.373472],
								[114.375420, 22.372641],
								[114.375687, 22.372641],
								[114.375694, 22.372358],
								[114.375969, 22.372358],
								[114.375969, 22.371531],
								[114.376251, 22.371531],
								[114.376251, 22.369862],
								[114.376808, 22.369862],
								[114.376808, 22.369583],
								[114.377083, 22.369583],
								[114.377083, 22.369305],
								[114.377640, 22.369305],
								[114.377640, 22.369026],
								[114.377907, 22.369026],
								[114.377914, 22.368750],
								[114.378471, 22.368750],
								[114.378471, 22.368473],
								[114.378944, 22.368473],
								[114.379028, 22.368473],
								[114.379028, 22.368195],
								[114.379585, 22.368195],
								[114.379585, 22.367640],
								[114.379860, 22.367640],
								[114.379860, 22.367361],
								[114.380417, 22.367361],
								[114.380417, 22.367083],
								[114.381248, 22.367083],
								[114.381248, 22.366528],
								[114.381531, 22.366528],
								[114.381531, 22.364582],
								[114.381248, 22.364582],
								[114.381248, 22.363750],
								[114.380974, 22.363750],
								[114.380974, 22.363474],
								[114.380692, 22.363474],
								[114.380692, 22.363195],
								[114.380142, 22.363194],
								[114.380142, 22.362917],
								[114.379860, 22.362917],
								[114.379860, 22.362638],
								[114.378471, 22.362638],
								[114.378471, 22.362917],
								[114.377083, 22.362917],
								[114.377083, 22.362640],
								[114.376808, 22.362638],
								[114.376808, 22.362083],
								[114.376526, 22.362083],
								[114.376526, 22.361805],
								[114.376251, 22.361805],
								[114.376251, 22.361252],
								[114.375969, 22.361252],
								[114.375969, 22.360970],
								[114.375694, 22.360970],
								[114.375694, 22.360695],
								[114.375420, 22.360695],
								[114.375420, 22.359861],
								[114.375137, 22.359861],
								[114.375137, 22.359583],
								[114.375420, 22.359583],
								[114.375420, 22.359306],
								[114.376808, 22.359306],
								[114.376808, 22.359030],
								[114.378471, 22.359030],
								[114.378471, 22.358747],
								[114.379028, 22.358747],
								[114.379028, 22.358473],
								[114.379303, 22.358473],
								[114.379303, 22.358194],
								[114.379585, 22.358194],
								[114.379585, 22.357637],
								[114.378197, 22.357637],
								[114.378197, 22.357916],
								[114.377914, 22.357916],
								[114.377914, 22.357637],
								[114.377357, 22.357637],
								[114.377357, 22.357361],
								[114.376808, 22.357361],
								[114.376808, 22.357084],
								[114.375969, 22.357084],
								[114.375969, 22.357361],
								[114.375420, 22.357361],
								[114.375420, 22.356810],
								[114.375687, 22.356806],
								[114.375694, 22.355972],
								[114.375420, 22.355972],
								[114.375420, 22.355694],
								[114.375137, 22.355694],
								[114.375137, 22.355415],
								[114.374580, 22.355415],
								[114.374580, 22.355139],
								[114.374306, 22.355139],
								[114.374306, 22.354584],
								[114.374580, 22.354584],
								[114.374580, 22.354029],
								[114.374306, 22.354029],
								[114.374306, 22.353750],
								[114.374031, 22.353750],
								[114.374031, 22.353201],
								[114.371803, 22.353193],
								[114.371803, 22.352362],
								[114.371529, 22.352362],
								[114.371529, 22.351805],
								[114.370140, 22.351805],
								[114.370140, 22.352083],
								[114.369858, 22.352083],
								[114.369858, 22.352362],
								[114.369308, 22.352362],
								[114.369308, 22.353472],
								[114.368752, 22.353472],
								[114.368752, 22.353750],
								[114.367081, 22.353750],
								[114.367081, 22.354029],
								[114.366806, 22.354029],
								[114.366806, 22.354305],
								[114.366249, 22.354305],
								[114.366249, 22.354584],
								[114.365417, 22.354584],
								[114.365417, 22.354029],
								[114.365135, 22.354029],
								[114.365135, 22.353750],
								[114.364861, 22.353750],
								[114.364861, 22.353472],
								[114.364586, 22.353472],
								[114.364586, 22.353193],
								[114.364304, 22.353193],
								[114.364304, 22.352362],
								[114.364586, 22.352362],
								[114.364586, 22.352083],
								[114.366249, 22.352083],
								[114.366249, 22.351805],
								[114.367081, 22.351805],
								[114.367081, 22.351530],
								[114.367363, 22.351528],
								[114.367363, 22.351250],
								[114.367638, 22.351250],
								[114.367638, 22.350695],
								[114.367920, 22.350695],
								[114.367920, 22.349861],
								[114.367638, 22.349861],
								[114.367638, 22.349306],
								[114.367363, 22.349306],
								[114.367363, 22.349583],
								[114.366806, 22.349583],
								[114.366806, 22.349861],
								[114.366249, 22.349861],
								[114.366249, 22.349583],
								[114.365974, 22.349583],
								[114.365974, 22.349306],
								[114.365692, 22.349306],
								[114.365692, 22.348473],
								[114.365143, 22.348473],
								[114.365135, 22.348749],
								[114.364586, 22.348749],
								[114.364586, 22.349028],
								[114.364067, 22.349028],
								[114.364029, 22.349583],
								[114.363747, 22.349583],
								[114.363747, 22.350140],
								[114.363197, 22.350140],
								[114.363197, 22.350416],
								[114.362358, 22.350416],
								[114.362289, 22.350973],
								[114.362083, 22.350973],
								[114.362083, 22.351250],
								[114.361809, 22.351250],
								[114.361809, 22.351528],
								[114.361252, 22.351528],
								[114.361252, 22.351805],
								[114.360420, 22.351805],
								[114.360420, 22.351528],
								[114.360138, 22.351528],
								[114.360138, 22.351250],
								[114.359863, 22.351250],
								[114.359863, 22.349861],
								[114.360138, 22.349861],
								[114.360138, 22.349028],
								[114.360420, 22.349028],
								[114.360420, 22.347639],
								[114.360138, 22.347639],
								[114.360138, 22.347359],
								[114.359863, 22.347359],
								[114.359863, 22.347084],
								[114.360123, 22.347084],
								[114.360138, 22.346806],
								[114.359863, 22.346806],
								[114.359863, 22.345421],
								[114.360138, 22.345419],
								[114.360138, 22.345140],
								[114.359863, 22.345140],
								[114.359863, 22.344584],
								[114.360695, 22.344584],
								[114.360695, 22.344027],
								[114.360970, 22.344027],
								[114.360970, 22.343201],
								[114.361252, 22.343197],
								[114.361252, 22.342636],
								[114.361526, 22.342636],
								[114.361526, 22.340973],
								[114.360695, 22.340973],
								[114.360695, 22.340694],
								[114.360138, 22.340694],
								[114.360138, 22.340515],
								[114.360138, 22.340414],
								[114.359863, 22.340414],
								[114.359863, 22.339861],
								[114.359581, 22.339861],
								[114.359581, 22.336811],
								[114.359306, 22.336805],
								[114.359306, 22.336527],
								[114.359024, 22.336527],
								[114.359024, 22.336250],
								[114.358749, 22.336250],
								[114.358749, 22.335974],
								[114.357918, 22.335974],
								[114.357918, 22.335695],
								[114.357361, 22.335695],
								[114.357361, 22.335421],
								[114.356804, 22.335417],
								[114.356804, 22.335138],
								[114.356247, 22.335138],
								[114.356247, 22.335417],
								[114.355698, 22.335417],
								[114.355698, 22.335695],
								[114.355415, 22.335695],
								[114.355415, 22.335974],
								[114.355141, 22.335974],
								[114.355141, 22.336527],
								[114.354858, 22.336527],
								[114.354858, 22.337639],
								[114.354584, 22.337639],
								[114.354584, 22.338194],
								[114.354309, 22.338194],
								[114.354309, 22.339027],
								[114.354584, 22.339027],
								[114.354584, 22.341528],
								[114.354309, 22.341528],
								[114.354309, 22.341808],
								[114.354057, 22.341808],
								[114.354027, 22.342083],
								[114.353752, 22.342083],
								[114.353752, 22.342361],
								[114.353203, 22.342361],
								[114.353195, 22.342636],
								[114.352913, 22.342636],
								[114.352913, 22.343197],
								[114.352638, 22.343197],
								[114.352638, 22.343748],
								[114.352364, 22.343748],
								[114.352364, 22.345419],
								[114.352638, 22.345419],
								[114.352638, 22.345694],
								[114.352364, 22.345694],
								[114.352364, 22.346527],
								[114.352081, 22.346527],
								[114.352081, 22.346806],
								[114.351822, 22.346806],
								[114.351807, 22.347084],
								[114.351250, 22.347084],
								[114.351250, 22.347639],
								[114.351524, 22.347639],
								[114.351524, 22.347918],
								[114.351761, 22.347918],
								[114.351807, 22.348194],
								[114.352081, 22.348194],
								[114.352081, 22.349306],
								[114.351524, 22.349306],
								[114.351524, 22.350140],
								[114.351807, 22.350140],
								[114.351807, 22.350416],
								[114.352081, 22.350416],
								[114.352081, 22.350695],
								[114.352364, 22.350695],
								[114.352364, 22.350973],
								[114.352638, 22.350973],
								[114.352638, 22.351805],
								[114.352371, 22.351805],
								[114.352364, 22.353472],
								[114.352081, 22.353472],
								[114.352081, 22.354305],
								[114.351250, 22.354305],
								[114.351250, 22.354029],
								[114.350975, 22.354029],
								[114.350975, 22.352917],
								[114.349586, 22.352917],
								[114.349586, 22.349861],
								[114.349030, 22.349861],
								[114.349030, 22.349583],
								[114.348473, 22.349583],
								[114.348473, 22.349306],
								[114.348198, 22.349306],
								[114.348198, 22.349583],
								[114.347641, 22.349583],
								[114.347641, 22.350140],
								[114.347359, 22.350140],
								[114.347359, 22.350416],
								[114.346252, 22.350416],
								[114.346252, 22.350140],
								[114.345695, 22.350140],
								[114.345695, 22.349583],
								[114.345139, 22.349583],
								[114.345139, 22.349028],
								[114.345413, 22.349028],
								[114.345413, 22.348749],
								[114.345139, 22.348749],
								[114.345139, 22.348194],
								[114.344864, 22.348194],
								[114.344864, 22.347918],
								[114.344582, 22.347918],
								[114.344582, 22.347359],
								[114.344307, 22.347359],
								[114.344307, 22.347084],
								[114.344025, 22.347084],
								[114.344025, 22.346527],
								[114.343475, 22.346527],
								[114.343475, 22.346806],
								[114.342957, 22.346806],
								[114.342918, 22.347084],
								[114.342636, 22.347084],
								[114.342636, 22.347359],
								[114.342361, 22.347359],
								[114.342361, 22.347639],
								[114.342087, 22.347639],
								[114.342087, 22.347918],
								[114.341850, 22.347918],
								[114.341805, 22.348194],
								[114.341530, 22.348194],
								[114.341530, 22.348473],
								[114.341248, 22.348473],
								[114.341248, 22.349028],
								[114.340736, 22.349028],
								[114.340698, 22.349583],
								[114.340416, 22.349583],
								[114.340416, 22.350140],
								[114.339859, 22.350140],
								[114.339859, 22.350695],
								[114.339584, 22.350695],
								[114.339584, 22.351250],
								[114.339027, 22.351250],
								[114.339027, 22.352083],
								[114.338753, 22.352083],
								[114.338753, 22.353193],
								[114.338470, 22.353193],
								[114.338470, 22.353472],
								[114.338196, 22.353472],
								[114.338196, 22.354029],
								[114.337929, 22.354029],
								[114.337914, 22.354860],
								[114.338196, 22.354860],
								[114.338196, 22.354584],
								[114.338470, 22.354584],
								[114.338470, 22.354860],
								[114.340141, 22.354860],
								[114.340141, 22.354584],
								[114.341530, 22.354584],
								[114.341530, 22.355139],
								[114.341263, 22.355139],
								[114.341248, 22.355972],
								[114.340973, 22.355972],
								[114.340973, 22.356527],
								[114.339584, 22.356527],
								[114.339584, 22.356806],
								[114.339027, 22.356806],
								[114.339027, 22.357361],
								[114.338753, 22.357361],
								[114.338753, 22.357637],
								[114.338196, 22.357637],
								[114.338196, 22.357916],
								[114.337914, 22.357916],
								[114.337914, 22.358473],
								[114.337639, 22.358473],
								[114.337639, 22.358747],
								[114.337082, 22.358747],
								[114.337082, 22.359030],
								[114.336250, 22.359030],
								[114.336250, 22.358747],
								[114.335136, 22.358747],
								[114.335136, 22.359030],
								[114.334305, 22.359030],
								[114.334305, 22.359583],
								[114.333473, 22.359583],
								[114.333473, 22.360416],
								[114.333229, 22.360416],
								[114.333191, 22.360695],
								[114.332916, 22.360695],
								[114.332916, 22.360970],
								[114.332642, 22.360970],
								[114.332642, 22.361252],
								[114.332085, 22.361252],
								[114.332085, 22.361805],
								[114.331802, 22.361805],
								[114.331802, 22.362364],
								[114.331253, 22.362364],
								[114.331253, 22.363750],
								[114.330971, 22.363750],
								[114.330971, 22.364027],
								[114.331253, 22.364027],
								[114.331253, 22.364305],
								[114.331512, 22.364305],
								[114.331528, 22.364582],
								[114.332077, 22.364582],
								[114.332085, 22.364861],
								[114.332642, 22.364861],
								[114.332642, 22.365417],
								[114.332359, 22.365417],
								[114.332359, 22.366528],
								[114.332642, 22.366528],
								[114.332642, 22.367361],
								[114.332916, 22.367361],
								[114.332916, 22.367083],
								[114.333191, 22.367083],
								[114.333191, 22.367361],
								[114.333473, 22.367361],
								[114.333473, 22.367640],
								[114.333748, 22.367640],
								[114.333748, 22.368195],
								[114.333473, 22.368195],
								[114.333473, 22.368750],
								[114.333191, 22.368750],
								[114.333191, 22.369305],
								[114.333473, 22.369305],
								[114.333473, 22.369583],
								[114.333748, 22.369583],
								[114.333748, 22.370136],
								[114.334030, 22.370136],
								[114.334030, 22.370695],
								[114.334305, 22.370695],
								[114.334305, 22.372084],
								[114.334030, 22.372084],
								[114.334030, 22.373472],
								[114.334297, 22.373472],
								[114.334305, 22.374306],
								[114.334587, 22.374306],
								[114.334587, 22.375139],
								[114.334862, 22.375139],
								[114.334862, 22.377640],
								[114.334305, 22.377640],
								[114.334305, 22.377361],
								[114.333855, 22.377361],
								[114.333748, 22.377361],
								[114.333748, 22.377083],
								[114.333473, 22.377083],
								[114.333473, 22.376806],
								[114.333191, 22.376806],
								[114.333191, 22.376251],
								[114.332916, 22.376249],
								[114.332916, 22.375973],
								[114.332359, 22.375973],
								[114.332359, 22.376249],
								[114.332085, 22.376249],
								[114.332085, 22.376806],
								[114.331528, 22.376806],
								[114.331528, 22.377083],
								[114.330696, 22.377083],
								[114.330696, 22.377361],
								[114.330582, 22.377361],
								[114.330139, 22.377361],
								[114.330139, 22.377640],
								[114.329308, 22.377640],
								[114.329308, 22.377916],
								[114.328751, 22.377916],
								[114.328751, 22.378195],
								[114.326805, 22.378195],
								[114.326805, 22.378471],
								[114.326530, 22.378471],
								[114.326530, 22.378750],
								[114.325974, 22.378750],
								[114.325974, 22.379028],
								[114.325691, 22.379028],
								[114.325691, 22.379305],
								[114.325142, 22.379305],
								[114.325142, 22.379583],
								[114.324585, 22.379583],
								[114.324585, 22.379862],
								[114.324028, 22.379862],
								[114.324028, 22.380138],
								[114.323753, 22.380138],
								[114.323753, 22.380417],
								[114.323471, 22.380417],
								[114.323471, 22.380695],
								[114.323219, 22.380695],
								[114.323196, 22.380972],
								[114.322914, 22.380972],
								[114.322914, 22.381527],
								[114.322639, 22.381527],
								[114.322639, 22.382639],
								[114.322914, 22.382639],
								[114.322914, 22.382915],
								[114.322662, 22.382915],
								[114.322639, 22.383194],
								[114.322365, 22.383194],
								[114.322365, 22.383472],
								[114.321808, 22.383472],
								[114.321808, 22.383194],
								[114.321526, 22.383194],
								[114.321526, 22.382360],
								[114.320969, 22.382360],
								[114.320969, 22.382084],
								[114.320694, 22.382084],
								[114.320694, 22.382360],
								[114.320419, 22.382360],
								[114.320419, 22.382084],
								[114.320137, 22.382084],
								[114.320137, 22.381805],
								[114.319580, 22.381805],
								[114.319580, 22.381527],
								[114.318474, 22.381527],
								[114.318474, 22.382084],
								[114.318192, 22.382084],
								[114.318192, 22.382639],
								[114.317917, 22.382639],
								[114.317917, 22.383472],
								[114.318192, 22.383472],
								[114.318192, 22.384308],
								[114.318474, 22.384308],
								[114.318474, 22.384859],
								[114.318748, 22.384859],
								[114.318748, 22.385695],
								[114.319031, 22.385695],
								[114.319031, 22.386251],
								[114.318474, 22.386251],
								[114.318474, 22.385973],
								[114.318192, 22.385973],
								[114.318192, 22.385416],
								[114.317917, 22.385416],
								[114.317917, 22.385138],
								[114.317642, 22.385138],
								[114.317642, 22.384859],
								[114.317360, 22.384859],
								[114.317360, 22.384310],
								[114.317093, 22.384308],
								[114.317085, 22.383751],
								[114.316803, 22.383751],
								[114.316803, 22.383472],
								[114.316254, 22.383472],
								[114.316254, 22.383751],
								[114.315697, 22.383751],
								[114.315697, 22.384027],
								[114.315140, 22.384027],
								[114.315140, 22.384308],
								[114.314857, 22.384308],
								[114.314857, 22.384583],
								[114.314583, 22.384583],
								[114.314583, 22.385138],
								[114.314857, 22.385138],
								[114.314857, 22.385416],
								[114.315140, 22.385416],
								[114.315140, 22.386251],
								[114.315414, 22.386251],
								[114.315414, 22.386801],
								[114.315697, 22.386806],
								[114.315697, 22.387362],
								[114.315971, 22.387362],
								[114.315971, 22.387917],
								[114.316238, 22.387917],
								[114.316254, 22.388472],
								[114.317360, 22.388472],
								[114.317360, 22.392361],
								[114.318192, 22.392361],
								[114.318192, 22.392639],
								[114.318748, 22.392639],
								[114.318748, 22.392916],
								[114.318222, 22.392918],
								[114.318192, 22.393194],
								[114.317642, 22.393194],
								[114.317642, 22.393473],
								[114.315971, 22.393473],
								[114.315971, 22.392918],
								[114.314583, 22.392916],
								[114.314583, 22.392641],
								[114.311813, 22.392639],
								[114.311806, 22.392361],
								[114.310692, 22.392361],
								[114.310692, 22.391809],
								[114.310417, 22.391806],
								[114.310417, 22.391251],
								[114.310143, 22.391251],
								[114.310143, 22.390141],
								[114.310417, 22.390139],
								[114.310417, 22.389862],
								[114.310143, 22.389862],
								[114.310143, 22.389584],
								[114.309860, 22.389584],
								[114.309860, 22.389305],
								[114.309586, 22.389305],
								[114.309586, 22.389029],
								[114.309303, 22.389029],
								[114.309303, 22.388472],
								[114.309029, 22.388472],
								[114.309029, 22.387917],
								[114.308746, 22.387917],
								[114.308746, 22.387083],
								[114.309006, 22.387083],
								[114.309029, 22.386806],
								[114.309303, 22.386805],
								[114.309303, 22.386530],
								[114.309586, 22.386530],
								[114.309586, 22.386251],
								[114.310143, 22.386251],
								[114.310143, 22.385973],
								[114.310387, 22.385973],
								[114.310417, 22.385695],
								[114.310692, 22.385695],
								[114.310692, 22.385138],
								[114.310417, 22.385138],
								[114.310417, 22.384583],
								[114.309303, 22.384583],
								[114.309303, 22.384859],
								[114.307915, 22.384859],
								[114.307915, 22.385138],
								[114.307358, 22.385138],
								[114.307358, 22.384859],
								[114.306252, 22.384859],
								[114.306252, 22.384583],
								[114.305969, 22.384583],
								[114.305969, 22.384027],
								[114.305794, 22.384027],
								[114.305138, 22.384027],
								[114.305138, 22.383751],
								[114.304581, 22.383751],
								[114.304581, 22.384027],
								[114.304306, 22.384027],
								[114.304306, 22.384308],
								[114.304031, 22.384308],
								[114.304031, 22.385416],
								[114.302361, 22.385416],
								[114.302361, 22.384859],
								[114.301529, 22.384859],
								[114.301529, 22.384583],
								[114.300415, 22.384583],
								[114.300415, 22.384308],
								[114.300140, 22.384308],
								[114.300140, 22.384027],
								[114.299309, 22.384027],
								[114.299309, 22.383751],
								[114.298752, 22.383751],
								[114.298752, 22.384027],
								[114.298470, 22.384027],
								[114.298470, 22.384859],
								[114.298752, 22.384859],
								[114.298752, 22.385416],
								[114.298470, 22.385416],
								[114.298470, 22.386251],
								[114.298195, 22.386251],
								[114.298195, 22.386530],
								[114.297920, 22.386530],
								[114.297920, 22.387083],
								[114.297638, 22.387083],
								[114.297638, 22.387362],
								[114.297363, 22.387362],
								[114.297363, 22.387917],
								[114.297081, 22.387917],
								[114.297081, 22.388195],
								[114.296806, 22.388195],
								[114.296806, 22.388472],
								[114.296524, 22.388472],
								[114.296524, 22.389029],
								[114.295692, 22.389029],
								[114.295692, 22.388750],
								[114.295418, 22.388750],
								[114.295418, 22.388472],
								[114.294586, 22.388472],
								[114.294586, 22.388195],
								[114.294304, 22.388195],
								[114.294304, 22.387917],
								[114.292641, 22.387917],
								[114.292641, 22.388195],
								[114.292358, 22.388195],
								[114.292358, 22.388472],
								[114.290970, 22.388472],
								[114.290970, 22.389029],
								[114.290695, 22.389029],
								[114.290695, 22.389305],
								[114.290413, 22.389305],
								[114.290413, 22.389862],
								[114.290138, 22.389862],
								[114.290138, 22.390694],
								[114.289864, 22.390694],
								[114.289864, 22.390972],
								[114.289581, 22.390972],
								[114.289581, 22.391527],
								[114.289024, 22.391527],
								[114.289024, 22.391806],
								[114.288750, 22.391806],
								[114.288750, 22.393749],
								[114.288193, 22.393749],
								[114.288193, 22.394028],
								[114.287918, 22.394028],
								[114.287918, 22.394304],
								[114.287086, 22.394304],
								[114.287086, 22.394583],
								[114.286804, 22.394583],
								[114.286804, 22.394304],
								[114.286530, 22.394304],
								[114.286530, 22.394583],
								[114.286247, 22.394583],
								[114.286247, 22.394861],
								[114.285973, 22.394861],
								[114.285973, 22.395140],
								[114.284027, 22.395140],
								[114.284027, 22.394861],
								[114.283470, 22.394861],
								[114.283470, 22.394583],
								[114.283195, 22.394583],
								[114.283195, 22.394304],
								[114.282913, 22.394304],
								[114.282913, 22.394028],
								[114.282639, 22.394028],
								[114.282639, 22.393749],
								[114.282364, 22.393749],
								[114.282364, 22.392918],
								[114.282082, 22.392916],
								[114.282082, 22.392641],
								[114.280693, 22.392639],
								[114.280693, 22.392916],
								[114.280434, 22.392918],
								[114.280418, 22.393194],
								[114.280136, 22.393194],
								[114.280136, 22.393473],
								[114.279861, 22.393473],
								[114.279861, 22.393749],
								[114.279305, 22.393749],
								[114.279305, 22.394028],
								[114.278473, 22.394028],
								[114.278473, 22.393749],
								[114.278198, 22.393749],
								[114.278191, 22.393473],
								[114.277916, 22.393473],
								[114.277916, 22.392918],
								[114.277649, 22.392916],
								[114.277641, 22.392639],
								[114.277359, 22.392639],
								[114.277359, 22.392084],
								[114.277084, 22.392084],
								[114.277084, 22.391251],
								[114.276802, 22.391251],
								[114.276802, 22.390972],
								[114.277077, 22.390972],
								[114.277084, 22.390694],
								[114.276802, 22.390694],
								[114.276802, 22.389584],
								[114.277084, 22.389584],
								[114.277084, 22.389305],
								[114.276527, 22.389305],
								[114.276527, 22.386251],
								[114.276802, 22.386251],
								[114.276802, 22.385695],
								[114.277084, 22.385695],
								[114.277084, 22.385138],
								[114.277359, 22.385138],
								[114.277359, 22.384859],
								[114.277641, 22.384859],
								[114.277641, 22.384333],
								[114.277359, 22.384308],
								[114.277359, 22.384027],
								[114.277084, 22.384027],
								[114.277084, 22.383751],
								[114.276527, 22.383751],
								[114.276527, 22.383472],
								[114.276253, 22.383472],
								[114.276253, 22.383194],
								[114.275970, 22.383194],
								[114.275970, 22.382915],
								[114.275696, 22.382915],
								[114.275696, 22.382641],
								[114.275414, 22.382639],
								[114.275414, 22.382360],
								[114.275139, 22.382360],
								[114.275139, 22.381811],
								[114.274864, 22.381805],
								[114.274864, 22.381527],
								[114.274582, 22.381527],
								[114.274582, 22.381250],
								[114.274307, 22.381250],
								[114.274307, 22.380695],
								[114.274025, 22.380695],
								[114.274025, 22.380417],
								[114.273750, 22.380417],
								[114.273750, 22.380138],
								[114.273476, 22.380138],
								[114.273476, 22.379862],
								[114.273193, 22.379862],
								[114.273193, 22.379583],
								[114.272636, 22.379583],
								[114.272636, 22.379305],
								[114.272362, 22.379305],
								[114.272362, 22.379028],
								[114.272087, 22.379028],
								[114.272087, 22.378750],
								[114.271805, 22.378750],
								[114.271805, 22.378471],
								[114.272087, 22.378471],
								[114.272087, 22.377916],
								[114.273193, 22.377916],
								[114.273193, 22.378195],
								[114.274025, 22.378195],
								[114.274025, 22.377916],
								[114.274307, 22.377916],
								[114.274307, 22.376810],
								[114.274582, 22.376806],
								[114.274582, 22.376528],
								[114.274857, 22.376528],
								[114.274864, 22.376251],
								[114.275139, 22.376249],
								[114.275139, 22.375694],
								[114.275375, 22.375694],
								[114.275414, 22.375416],
								[114.275139, 22.375416],
								[114.275139, 22.375141],
								[114.274864, 22.375139],
								[114.274864, 22.374863],
								[114.274582, 22.374863],
								[114.274582, 22.374584],
								[114.274307, 22.374584],
								[114.274307, 22.373751],
								[114.274025, 22.373751],
								[114.274025, 22.372641],
								[114.273750, 22.372641],
								[114.273750, 22.372358],
								[114.273476, 22.372358],
								[114.273476, 22.371805],
								[114.273193, 22.371805],
								[114.273193, 22.371531],
								[114.272636, 22.371531],
								[114.272636, 22.370972],
								[114.272362, 22.370972],
								[114.272362, 22.370136],
								[114.272087, 22.370136],
								[114.272087, 22.368473],
								[114.272324, 22.368473],
								[114.272362, 22.368195],
								[114.272636, 22.368195],
								[114.272636, 22.367640],
								[114.272919, 22.367640],
								[114.272919, 22.366804],
								[114.273193, 22.366804],
								[114.273193, 22.365973],
								[114.272919, 22.365973],
								[114.272919, 22.365696],
								[114.273193, 22.365696],
								[114.273193, 22.364861],
								[114.273476, 22.364861],
								[114.273476, 22.364582],
								[114.273750, 22.364582],
								[114.273750, 22.364305],
								[114.274307, 22.364305],
								[114.274307, 22.363750],
								[114.274582, 22.363750],
								[114.274582, 22.363474],
								[114.274826, 22.363474],
								[114.274864, 22.362364],
								[114.275414, 22.362364],
								[114.275414, 22.362083],
								[114.275970, 22.362083],
								[114.275970, 22.361805],
								[114.276802, 22.361805],
								[114.276802, 22.361528],
								[114.277077, 22.361528],
								[114.277084, 22.361252],
								[114.277359, 22.361252],
								[114.277359, 22.360970],
								[114.277641, 22.360970],
								[114.277641, 22.360695],
								[114.278191, 22.360695],
								[114.278198, 22.360140],
								[114.278473, 22.360140],
								[114.278473, 22.359306],
								[114.278748, 22.359306],
								[114.278748, 22.358473],
								[114.278473, 22.358473],
								[114.278473, 22.358194],
								[114.278198, 22.358194],
								[114.278191, 22.357637],
								[114.277916, 22.357637],
								[114.277916, 22.357084],
								[114.277641, 22.357084],
								[114.277641, 22.356806],
								[114.277916, 22.356806],
								[114.277916, 22.356531],
								[114.277649, 22.356527],
								[114.277641, 22.355972],
								[114.277840, 22.355972],
								[114.277916, 22.355972],
								[114.277916, 22.354584],
								[114.277641, 22.354584],
								[114.277641, 22.354029],
								[114.277084, 22.354029],
								[114.277084, 22.353750],
								[114.276527, 22.353750],
								[114.276527, 22.353472],
								[114.276253, 22.353472],
								[114.276253, 22.353193],
								[114.275970, 22.353193],
								[114.275970, 22.351805],
								[114.275696, 22.351805],
								[114.275696, 22.351250],
								[114.275414, 22.351250],
								[114.275414, 22.350973],
								[114.275627, 22.350973],
								[114.275696, 22.350416],
								[114.274864, 22.350416],
								[114.274864, 22.350695],
								[114.274582, 22.350695],
								[114.274582, 22.350973],
								[114.274025, 22.350973],
								[114.274025, 22.351250],
								[114.273483, 22.351250],
								[114.273476, 22.351528],
								[114.273193, 22.351528],
								[114.273193, 22.352362],
								[114.272636, 22.352362],
								[114.272636, 22.353193],
								[114.272362, 22.353193],
								[114.272362, 22.353750],
								[114.272087, 22.353750],
								[114.272087, 22.354029],
								[114.271805, 22.354029],
								[114.271805, 22.354305],
								[114.271530, 22.354305],
								[114.271530, 22.354860],
								[114.270691, 22.354860],
								[114.270691, 22.354584],
								[114.270416, 22.354584],
								[114.270416, 22.354860],
								[114.269859, 22.354860],
								[114.269859, 22.354584],
								[114.269585, 22.354584],
								[114.269585, 22.355415],
								[114.269859, 22.355415],
								[114.269859, 22.355972],
								[114.270142, 22.355972],
								[114.270142, 22.356251],
								[114.271248, 22.356251],
								[114.271248, 22.356527],
								[114.270973, 22.356527],
								[114.270973, 22.357084],
								[114.270142, 22.357084],
								[114.270142, 22.356806],
								[114.269302, 22.356806],
								[114.269302, 22.357084],
								[114.267082, 22.357084],
								[114.267082, 22.356806],
								[114.266525, 22.356806],
								[114.266525, 22.356527],
								[114.265419, 22.356527],
								[114.265419, 22.356806],
								[114.264862, 22.356806],
								[114.264862, 22.357637],
								[114.265137, 22.357637],
								[114.265137, 22.359306],
								[114.265419, 22.359306],
								[114.265419, 22.359861],
								[114.265686, 22.359861],
								[114.265694, 22.360140],
								[114.267082, 22.360140],
								[114.267082, 22.360416],
								[114.267563, 22.360416],
								[114.267639, 22.360416],
								[114.267639, 22.360695],
								[114.267914, 22.360695],
								[114.267914, 22.360970],
								[114.268196, 22.360970],
								[114.268196, 22.361252],
								[114.268471, 22.361252],
								[114.268471, 22.361528],
								[114.268753, 22.361528],
								[114.268753, 22.362083],
								[114.269028, 22.362083],
								[114.269028, 22.362364],
								[114.269302, 22.362364],
								[114.269302, 22.362917],
								[114.269585, 22.362917],
								[114.269585, 22.364305],
								[114.269859, 22.364305],
								[114.269859, 22.365139],
								[114.269585, 22.365139],
								[114.269585, 22.365973],
								[114.269302, 22.365973],
								[114.269302, 22.366528],
								[114.268196, 22.366528],
								[114.268196, 22.366804],
								[114.267082, 22.366804],
								[114.267082, 22.366528],
								[114.266808, 22.366528],
								[114.266808, 22.366249],
								[114.266525, 22.366249],
								[114.266525, 22.366528],
								[114.266251, 22.366528],
								[114.266251, 22.366804],
								[114.265694, 22.366804],
								[114.265694, 22.367361],
								[114.265976, 22.367361],
								[114.265976, 22.368195],
								[114.266251, 22.368195],
								[114.266251, 22.370136],
								[114.265976, 22.370136],
								[114.265976, 22.370417],
								[114.265419, 22.370417],
								[114.265419, 22.370695],
								[114.265137, 22.370695],
								[114.265137, 22.371248],
								[114.264862, 22.371248],
								[114.264862, 22.371531],
								[114.264030, 22.371531],
								[114.264030, 22.370420],
								[114.264305, 22.370417],
								[114.264305, 22.369862],
								[114.264030, 22.369862],
								[114.264030, 22.369026],
								[114.263474, 22.369026],
								[114.263474, 22.368750],
								[114.263191, 22.368750],
								[114.263191, 22.367083],
								[114.262917, 22.367083],
								[114.262917, 22.367640],
								[114.261528, 22.367640],
								[114.261528, 22.367914],
								[114.260971, 22.367914],
								[114.260971, 22.366528],
								[114.261253, 22.366528],
								[114.261253, 22.364861],
								[114.260696, 22.364861],
								[114.260696, 22.364582],
								[114.260414, 22.364582],
								[114.260414, 22.364305],
								[114.260139, 22.364305],
								[114.260139, 22.362921],
								[114.260406, 22.362917],
								[114.260414, 22.362083],
								[114.257637, 22.362083],
								[114.257637, 22.361252],
								[114.257362, 22.361252],
								[114.257362, 22.360420],
								[114.257637, 22.360416],
								[114.257637, 22.360140],
								[114.257919, 22.360140],
								[114.257919, 22.359861],
								[114.258469, 22.359861],
								[114.258469, 22.359583],
								[114.258751, 22.359583],
								[114.258751, 22.359306],
								[114.259026, 22.359306],
								[114.259026, 22.358747],
								[114.259308, 22.358747],
								[114.259308, 22.357916],
								[114.259583, 22.357916],
								[114.259583, 22.357361],
								[114.259308, 22.357361],
								[114.259308, 22.357084],
								[114.258575, 22.357084],
								[114.258469, 22.357084],
								[114.258469, 22.356806],
								[114.257362, 22.356806],
								[114.257362, 22.356527],
								[114.257080, 22.356527],
								[114.257080, 22.356251],
								[114.256805, 22.356251],
								[114.256805, 22.355972],
								[114.255974, 22.355972],
								[114.255974, 22.355694],
								[114.255142, 22.355694],
								[114.255142, 22.355415],
								[114.254860, 22.355415],
								[114.254860, 22.355141],
								[114.254303, 22.355139],
								[114.254303, 22.354860],
								[114.253197, 22.354860],
								[114.253197, 22.354584],
								[114.252914, 22.354584],
								[114.252914, 22.354305],
								[114.253197, 22.354305],
								[114.253197, 22.354029],
								[114.253754, 22.354029],
								[114.253754, 22.354305],
								[114.254303, 22.354305],
								[114.254303, 22.354584],
								[114.254860, 22.354584],
								[114.254860, 22.354860],
								[114.255142, 22.354860],
								[114.255142, 22.354584],
								[114.255417, 22.354584],
								[114.255417, 22.354305],
								[114.255966, 22.354305],
								[114.255974, 22.354031],
								[114.256531, 22.354029],
								[114.256531, 22.354305],
								[114.259026, 22.354305],
								[114.259026, 22.354584],
								[114.259308, 22.354584],
								[114.259308, 22.356527],
								[114.259583, 22.356527],
								[114.259583, 22.356806],
								[114.260139, 22.356806],
								[114.260139, 22.356531],
								[114.260406, 22.356527],
								[114.260414, 22.355972],
								[114.260620, 22.355972],
								[114.260696, 22.355972],
								[114.260696, 22.355415],
								[114.260971, 22.355415],
								[114.260971, 22.355139],
								[114.261528, 22.355139],
								[114.261528, 22.354860],
								[114.262062, 22.354860],
								[114.262085, 22.354584],
								[114.263191, 22.354584],
								[114.263191, 22.354305],
								[114.263466, 22.354305],
								[114.263474, 22.353750],
								[114.264305, 22.353750],
								[114.264305, 22.353193],
								[114.264862, 22.353193],
								[114.264862, 22.352917],
								[114.265419, 22.352917],
								[114.265419, 22.352638],
								[114.265976, 22.352638],
								[114.265976, 22.352362],
								[114.266251, 22.352362],
								[114.266251, 22.351805],
								[114.266808, 22.351805],
								[114.266808, 22.351530],
								[114.268463, 22.351528],
								[114.268471, 22.351250],
								[114.269028, 22.351250],
								[114.269028, 22.350973],
								[114.268753, 22.350973],
								[114.268753, 22.349306],
								[114.268471, 22.349306],
								[114.268471, 22.348473],
								[114.267914, 22.348473],
								[114.267914, 22.347918],
								[114.267639, 22.347918],
								[114.267639, 22.347643],
								[114.267365, 22.347639],
								[114.267365, 22.346806],
								[114.266808, 22.346806],
								[114.266808, 22.345970],
								[114.266525, 22.345970],
								[114.266525, 22.345419],
								[114.266251, 22.345419],
								[114.266251, 22.344305],
								[114.266525, 22.344305],
								[114.266525, 22.342916],
								[114.266808, 22.342916],
								[114.266808, 22.342636],
								[114.267082, 22.342636],
								[114.267082, 22.342083],
								[114.266808, 22.342083],
								[114.266808, 22.341808],
								[114.266525, 22.341808],
								[114.266525, 22.340973],
								[114.266792, 22.340973],
								[114.266808, 22.340139],
								[114.267082, 22.340139],
								[114.267082, 22.339861],
								[114.266808, 22.339861],
								[114.266808, 22.339310],
								[114.267082, 22.339306],
								[114.267082, 22.339031],
								[114.267357, 22.339027],
								[114.267365, 22.338472],
								[114.268471, 22.338472],
								[114.268471, 22.338194],
								[114.269028, 22.338194],
								[114.269028, 22.337917],
								[114.269577, 22.337917],
								[114.269585, 22.337084],
								[114.270142, 22.337084],
								[114.270142, 22.336531],
								[114.270142, 22.335974],
								[114.270325, 22.335974],
								[114.270416, 22.335974],
								[114.270416, 22.335695],
								[114.270691, 22.335695],
								[114.270691, 22.334862],
								[114.270973, 22.334862],
								[114.270973, 22.334305],
								[114.270691, 22.334305],
								[114.270691, 22.331806],
								[114.270973, 22.331806],
								[114.270973, 22.331251],
								[114.271248, 22.331247],
								[114.271248, 22.330694],
								[114.271523, 22.330694],
								[114.271530, 22.330416],
								[114.271797, 22.330416],
								[114.271805, 22.330141],
								[114.272087, 22.330137],
								[114.272087, 22.329584],
								[114.272339, 22.329584],
								[114.272362, 22.329306],
								[114.272087, 22.329306],
								[114.272087, 22.328472],
								[114.272331, 22.328472],
								[114.272362, 22.327084],
								[114.272087, 22.327084],
								[114.272087, 22.325417],
								[114.272362, 22.325417],
								[114.272362, 22.324030],
								[114.272087, 22.324028],
								[114.272087, 22.323471],
								[114.272614, 22.323471],
								[114.272636, 22.323195],
								[114.273193, 22.323195],
								[114.273193, 22.322916],
								[114.273476, 22.322916],
								[114.273476, 22.322639],
								[114.274864, 22.322639],
								[114.274864, 22.322363],
								[114.276527, 22.322363],
								[114.276527, 22.322083],
								[114.276802, 22.322083],
								[114.276802, 22.321806],
								[114.277084, 22.321806],
								[114.277084, 22.321527],
								[114.277359, 22.321527],
								[114.277359, 22.321253],
								[114.277641, 22.321253],
								[114.277641, 22.320417],
								[114.277740, 22.320417],
								[114.278473, 22.320417],
								[114.278473, 22.319859],
								[114.279030, 22.319859],
								[114.279030, 22.320141],
								[114.280136, 22.320141],
								[114.280136, 22.320417],
								[114.280388, 22.320417],
								[114.280418, 22.320694],
								[114.280693, 22.320694],
								[114.280693, 22.320417],
								[114.280975, 22.320417],
								[114.280975, 22.320694],
								[114.282364, 22.320694],
								[114.282364, 22.320972],
								[114.282913, 22.320972],
								[114.282913, 22.321527],
								[114.284302, 22.321527],
								[114.284309, 22.321806],
								[114.285416, 22.321806],
								[114.285416, 22.321253],
								[114.285698, 22.321253],
								[114.285698, 22.320694],
								[114.285965, 22.320694],
								[114.285973, 22.320417],
								[114.286247, 22.320417],
								[114.286247, 22.320141],
								[114.286804, 22.320141],
								[114.286804, 22.320417],
								[114.287086, 22.320417],
								[114.287086, 22.320141],
								[114.287636, 22.320141],
								[114.287636, 22.320417],
								[114.288193, 22.320417],
								[114.288193, 22.320972],
								[114.288475, 22.320972],
								[114.288475, 22.321253],
								[114.288750, 22.321253],
								[114.288750, 22.321527],
								[114.289307, 22.321527],
								[114.289307, 22.321253],
								[114.289581, 22.321253],
								[114.289581, 22.320457],
								[114.289856, 22.320417],
								[114.289864, 22.319584],
								[114.289581, 22.319584],
								[114.289581, 22.319029],
								[114.289856, 22.319029],
								[114.289864, 22.318199],
								[114.290138, 22.318195],
								[114.290138, 22.317362],
								[114.290413, 22.317362],
								[114.290413, 22.317083],
								[114.290138, 22.317083],
								[114.290138, 22.316805],
								[114.290413, 22.316805],
								[114.290413, 22.316250],
								[114.290138, 22.316250],
								[114.290138, 22.315971],
								[114.290413, 22.315971],
								[114.290413, 22.314028],
								[114.290970, 22.314028],
								[114.290970, 22.313749],
								[114.292076, 22.313749],
								[114.292084, 22.313473],
								[114.292358, 22.313473],
								[114.292358, 22.313194],
								[114.292641, 22.313194],
								[114.292641, 22.312916],
								[114.292358, 22.312916],
								[114.292358, 22.312361],
								[114.292641, 22.312361],
								[114.292641, 22.310139],
								[114.292358, 22.310139],
								[114.292358, 22.309860],
								[114.291809, 22.309860],
								[114.291809, 22.309031],
								[114.291527, 22.309029],
								[114.291527, 22.308472],
								[114.291252, 22.308472],
								[114.291252, 22.307917],
								[114.290970, 22.307917],
								[114.290970, 22.307362],
								[114.292915, 22.307362],
								[114.292915, 22.306805],
								[114.293198, 22.306805],
								[114.293198, 22.306528],
								[114.292915, 22.306528],
								[114.292915, 22.304859],
								[114.293198, 22.304859],
								[114.293198, 22.304583],
								[114.294586, 22.304583],
								[114.294586, 22.304859],
								[114.295418, 22.304859],
								[114.295418, 22.305695],
								[114.295135, 22.305695],
								[114.295135, 22.307362],
								[114.295692, 22.307362],
								[114.295692, 22.307638],
								[114.296249, 22.307638],
								[114.296249, 22.307917],
								[114.295975, 22.307917],
								[114.295975, 22.308195],
								[114.296249, 22.308195],
								[114.296249, 22.308472],
								[114.296516, 22.308472],
								[114.296524, 22.308750],
								[114.297081, 22.308750],
								[114.297081, 22.309029],
								[114.297363, 22.309029],
								[114.297363, 22.309305],
								[114.297638, 22.309305],
								[114.297638, 22.309584],
								[114.297920, 22.309584],
								[114.297920, 22.309860],
								[114.299026, 22.309860],
								[114.299026, 22.309584],
								[114.299309, 22.309584],
								[114.299309, 22.309305],
								[114.299858, 22.309305],
								[114.299858, 22.309584],
								[114.300140, 22.309584],
								[114.300140, 22.309860],
								[114.300415, 22.309860],
								[114.300415, 22.310139],
								[114.300972, 22.310139],
								[114.300972, 22.309860],
								[114.301247, 22.309860],
								[114.301247, 22.309584],
								[114.301796, 22.309584],
								[114.301804, 22.310139],
								[114.302086, 22.310139],
								[114.302086, 22.310417],
								[114.303749, 22.310417],
								[114.303749, 22.310139],
								[114.304031, 22.310139],
								[114.304031, 22.309860],
								[114.304581, 22.309860],
								[114.304581, 22.309029],
								[114.304863, 22.309029],
								[114.304863, 22.308203],
								[114.304588, 22.308195],
								[114.304581, 22.307362],
								[114.304863, 22.307362],
								[114.304863, 22.307083],
								[114.305138, 22.307083],
								[114.305138, 22.306805],
								[114.305420, 22.306805],
								[114.305420, 22.306528],
								[114.305687, 22.306528],
								[114.305695, 22.306252],
								[114.306526, 22.306252],
								[114.306526, 22.305973],
								[114.306786, 22.305973],
								[114.306808, 22.305138],
								[114.307640, 22.305138],
								[114.307640, 22.304583],
								[114.308197, 22.304583],
								[114.308197, 22.304308],
								[114.308472, 22.304308],
								[114.308472, 22.303761],
								[114.308746, 22.303747],
								[114.308746, 22.303473],
								[114.309029, 22.303473],
								[114.309029, 22.303194],
								[114.309303, 22.303194],
								[114.309303, 22.302919],
								[114.309425, 22.302919],
								[114.309860, 22.302919],
								[114.309860, 22.302084],
								[114.310127, 22.302084],
								[114.310143, 22.301805],
								[114.310692, 22.301805],
								[114.310692, 22.301525],
								[114.311249, 22.301525],
								[114.311249, 22.301250],
								[114.311531, 22.301250],
								[114.311531, 22.300972],
								[114.311798, 22.300972],
								[114.311806, 22.300694],
								[114.312080, 22.300694],
								[114.312080, 22.299862],
								[114.312355, 22.299862],
								[114.312363, 22.298471],
								[114.312080, 22.298471],
								[114.312080, 22.297640],
								[114.311249, 22.297638],
								[114.311249, 22.297361],
								[114.310417, 22.297361],
								[114.310417, 22.296806],
								[114.310692, 22.296806],
								[114.310692, 22.296249],
								[114.310974, 22.296249],
								[114.310974, 22.295694],
								[114.311249, 22.295694],
								[114.311249, 22.295139],
								[114.310974, 22.295139],
								[114.310974, 22.293194],
								[114.310692, 22.293194],
								[114.310692, 22.292917],
								[114.310417, 22.292917],
								[114.310417, 22.292641],
								[114.309860, 22.292641],
								[114.309860, 22.292358],
								[114.309586, 22.292358],
								[114.309586, 22.291805],
								[114.309029, 22.291805],
								[114.309029, 22.292084],
								[114.308472, 22.292084],
								[114.308472, 22.291805],
								[114.306252, 22.291805],
								[114.306252, 22.291531],
								[114.305969, 22.291531],
								[114.305969, 22.290972],
								[114.305695, 22.290972],
								[114.305695, 22.290695],
								[114.304863, 22.290695],
								[114.304863, 22.290421],
								[114.304588, 22.290419],
								[114.304581, 22.290136],
								[114.304031, 22.290136],
								[114.304031, 22.289862],
								[114.303474, 22.289862],
								[114.303474, 22.289305],
								[114.303192, 22.289305],
								[114.303192, 22.289026],
								[114.302917, 22.289026],
								[114.302917, 22.288473],
								[114.302635, 22.288473],
								[114.302635, 22.287640],
								[114.302361, 22.287640],
								[114.302361, 22.287083],
								[114.302086, 22.287083],
								[114.302086, 22.286804],
								[114.300972, 22.286804],
								[114.300972, 22.286530],
								[114.300140, 22.286528],
								[114.300140, 22.286804],
								[114.299309, 22.286804],
								[114.299309, 22.287083],
								[114.298470, 22.287083],
								[114.298470, 22.287361],
								[114.296806, 22.287361],
								[114.296806, 22.287640],
								[114.296249, 22.287640],
								[114.296249, 22.287914],
								[114.294861, 22.287914],
								[114.294861, 22.288195],
								[114.294304, 22.288195],
								[114.294304, 22.288750],
								[114.293472, 22.288750],
								[114.293472, 22.289026],
								[114.293747, 22.289026],
								[114.293747, 22.289583],
								[114.294029, 22.289583],
								[114.294029, 22.290136],
								[114.293747, 22.290136],
								[114.293747, 22.290419],
								[114.293472, 22.290419],
								[114.293472, 22.290695],
								[114.290695, 22.290695],
								[114.290695, 22.290419],
								[114.290413, 22.290419],
								[114.290413, 22.290140],
								[114.289864, 22.290136],
								[114.289864, 22.289862],
								[114.289581, 22.289862],
								[114.289581, 22.289583],
								[114.289307, 22.289583],
								[114.289307, 22.289305],
								[114.289024, 22.289305],
								[114.289024, 22.288750],
								[114.288750, 22.288750],
								[114.288750, 22.288473],
								[114.288475, 22.288473],
								[114.288475, 22.286249],
								[114.288750, 22.286249],
								[114.288750, 22.285973],
								[114.290413, 22.285973],
								[114.290413, 22.286528],
								[114.290970, 22.286528],
								[114.290970, 22.285973],
								[114.290695, 22.285973],
								[114.290695, 22.285418],
								[114.290413, 22.285418],
								[114.290413, 22.284309],
								[114.289864, 22.284306],
								[114.289864, 22.284027],
								[114.288750, 22.284027],
								[114.288750, 22.283751],
								[114.288193, 22.283751],
								[114.288193, 22.283194],
								[114.287918, 22.283194],
								[114.287918, 22.282639],
								[114.288193, 22.282639],
								[114.288193, 22.281252],
								[114.288475, 22.281252],
								[114.288475, 22.280695],
								[114.288750, 22.280695],
								[114.288750, 22.280416],
								[114.289017, 22.280416],
								[114.289024, 22.280142],
								[114.289307, 22.280140],
								[114.289307, 22.279861],
								[114.290413, 22.279861],
								[114.290413, 22.279583],
								[114.290970, 22.279583],
								[114.290970, 22.279306],
								[114.293747, 22.279306],
								[114.293747, 22.279583],
								[114.295418, 22.279583],
								[114.295418, 22.279306],
								[114.295967, 22.279306],
								[114.295975, 22.279030],
								[114.296249, 22.279030],
								[114.296249, 22.277916],
								[114.295975, 22.277916],
								[114.295975, 22.277637],
								[114.295692, 22.277637],
								[114.295692, 22.276819],
								[114.296524, 22.276808],
								[114.296524, 22.277082],
								[114.297363, 22.277082],
								[114.297363, 22.277361],
								[114.297638, 22.277361],
								[114.297638, 22.277916],
								[114.297920, 22.277916],
								[114.297920, 22.278473],
								[114.298187, 22.278473],
								[114.298195, 22.279030],
								[114.298470, 22.279030],
								[114.298470, 22.279306],
								[114.298752, 22.279306],
								[114.298752, 22.279583],
								[114.299026, 22.279583],
								[114.299026, 22.279861],
								[114.299576, 22.279861],
								[114.299583, 22.280140],
								[114.302361, 22.280140],
								[114.302361, 22.279861],
								[114.303192, 22.279861],
								[114.303192, 22.279583],
								[114.302917, 22.279583],
								[114.302917, 22.279306],
								[114.302635, 22.279306],
								[114.302635, 22.279030],
								[114.302361, 22.279030],
								[114.302361, 22.278748],
								[114.301529, 22.278748],
								[114.301529, 22.278473],
								[114.300972, 22.278473],
								[114.300972, 22.278194],
								[114.300697, 22.278194],
								[114.300697, 22.277082],
								[114.300972, 22.277082],
								[114.300972, 22.275415],
								[114.301529, 22.275415],
								[114.301529, 22.274860],
								[114.301804, 22.274860],
								[114.301804, 22.273750],
								[114.302086, 22.273750],
								[114.302086, 22.272083],
								[114.302330, 22.272083],
								[114.302361, 22.271528],
								[114.302635, 22.271528],
								[114.302635, 22.271250],
								[114.303192, 22.271250],
								[114.303192, 22.270971],
								[114.303894, 22.270971],
								[114.304031, 22.270971],
								[114.304031, 22.270695],
								[114.304306, 22.270695],
								[114.304306, 22.269583],
								[114.304581, 22.269583],
								[114.304581, 22.269306],
								[114.304306, 22.269306],
								[114.304306, 22.268749],
								[114.304863, 22.268749],
								[114.304863, 22.269306],
								[114.305695, 22.269306],
								[114.305695, 22.269583],
								[114.306526, 22.269583],
								[114.306526, 22.269306],
								[114.307083, 22.269306],
								[114.307083, 22.269028],
								[114.307358, 22.269028],
								[114.307358, 22.268749],
								[114.307632, 22.268749],
								[114.307640, 22.267639],
								[114.307358, 22.267639],
								[114.307358, 22.267084],
								[114.307083, 22.267084],
								[114.307083, 22.266527],
								[114.306808, 22.266527],
								[114.306808, 22.265417],
								[114.306526, 22.265417],
								[114.306526, 22.265141],
								[114.305969, 22.265141],
								[114.305969, 22.264862],
								[114.305695, 22.264862],
								[114.305695, 22.264584],
								[114.305138, 22.264584],
								[114.305138, 22.264311],
								[114.303749, 22.264305],
								[114.303749, 22.264584],
								[114.303482, 22.264584],
								[114.303474, 22.264862],
								[114.302917, 22.264862],
								[114.302917, 22.265141],
								[114.302635, 22.265141],
								[114.302635, 22.265417],
								[114.302361, 22.265417],
								[114.302361, 22.265694],
								[114.301804, 22.265694],
								[114.301804, 22.265972],
								[114.301247, 22.265972],
								[114.301247, 22.266251],
								[114.300972, 22.266251],
								[114.300972, 22.265972],
								[114.300697, 22.265972],
								[114.300697, 22.265417],
								[114.300415, 22.265417],
								[114.300415, 22.264027],
								[114.300697, 22.264027],
								[114.300697, 22.263748],
								[114.300972, 22.263748],
								[114.300972, 22.263472],
								[114.301247, 22.263472],
								[114.301247, 22.262636],
								[114.300972, 22.262636],
								[114.300972, 22.261250],
								[114.300697, 22.261250],
								[114.300697, 22.260414],
								[114.300140, 22.260414],
								[114.300140, 22.260139],
								[114.299026, 22.260139],
								[114.299026, 22.260414],
								[114.298752, 22.260414],
								[114.298752, 22.260695],
								[114.298195, 22.260695],
								[114.298195, 22.260973],
								[114.297638, 22.260973],
								[114.297638, 22.261250],
								[114.297363, 22.261250],
								[114.297363, 22.261526],
								[114.297081, 22.261526],
								[114.297081, 22.261808],
								[114.296837, 22.261808],
								[114.296806, 22.262362],
								[114.296524, 22.262362],
								[114.296524, 22.263748],
								[114.295975, 22.263748],
								[114.295975, 22.264584],
								[114.295418, 22.264584],
								[114.295418, 22.265141],
								[114.294586, 22.265141],
								[114.294586, 22.265417],
								[114.293747, 22.265417],
								[114.293747, 22.265694],
								[114.292915, 22.265694],
								[114.292915, 22.265972],
								[114.292358, 22.265972],
								[114.292358, 22.266251],
								[114.291527, 22.266251],
								[114.291527, 22.266806],
								[114.291008, 22.266806],
								[114.290970, 22.267084],
								[114.290413, 22.267084],
								[114.290413, 22.267363],
								[114.290138, 22.267363],
								[114.290138, 22.269306],
								[114.289864, 22.269306],
								[114.289864, 22.269583],
								[114.289581, 22.269583],
								[114.289581, 22.269861],
								[114.287918, 22.269861],
								[114.287918, 22.270140],
								[114.287086, 22.270140],
								[114.287086, 22.270416],
								[114.286530, 22.270416],
								[114.286530, 22.270695],
								[114.285973, 22.270695],
								[114.285973, 22.270416],
								[114.283470, 22.270416],
								[114.283470, 22.270695],
								[114.283195, 22.270695],
								[114.283195, 22.270971],
								[114.282913, 22.270971],
								[114.282913, 22.271250],
								[114.282082, 22.271250],
								[114.282082, 22.270971],
								[114.281807, 22.270971],
								[114.281807, 22.270140],
								[114.282364, 22.270140],
								[114.282364, 22.269583],
								[114.282631, 22.269583],
								[114.282639, 22.268749],
								[114.282364, 22.268749],
								[114.282364, 22.268473],
								[114.281807, 22.268473],
								[114.281807, 22.267918],
								[114.280418, 22.267916],
								[114.280418, 22.267363],
								[114.279861, 22.267363],
								[114.279861, 22.264584],
								[114.279594, 22.264584],
								[114.279587, 22.264862],
								[114.279305, 22.264862],
								[114.279305, 22.264305],
								[114.279030, 22.264305],
								[114.279030, 22.264030],
								[114.278748, 22.264027],
								[114.278748, 22.263748],
								[114.278473, 22.263748],
								[114.278473, 22.263472],
								[114.278198, 22.263472],
								[114.278191, 22.263197],
								[114.277641, 22.263197],
								[114.277641, 22.262918],
								[114.277359, 22.262918],
								[114.277359, 22.262638],
								[114.277084, 22.262636],
								[114.277084, 22.262362],
								[114.276802, 22.262362],
								[114.276802, 22.261808],
								[114.276527, 22.261808],
								[114.276527, 22.261526],
								[114.275970, 22.261526],
								[114.275970, 22.262083],
								[114.275696, 22.262083],
								[114.275696, 22.262362],
								[114.275414, 22.262362],
								[114.275414, 22.262636],
								[114.274307, 22.262636],
								[114.274307, 22.262362],
								[114.274025, 22.262362],
								[114.274025, 22.262083],
								[114.273476, 22.262083],
								[114.273476, 22.262362],
								[114.272919, 22.262362],
								[114.272919, 22.263197],
								[114.273193, 22.263197],
								[114.273193, 22.264027],
								[114.272919, 22.264027],
								[114.272919, 22.264305],
								[114.272362, 22.264305],
								[114.272362, 22.264584],
								[114.272087, 22.264584],
								[114.272087, 22.265141],
								[114.271820, 22.265141],
								[114.271805, 22.265694],
								[114.271530, 22.265694],
								[114.271530, 22.266251],
								[114.271248, 22.266251],
								[114.271248, 22.266527],
								[114.271530, 22.266527],
								[114.271530, 22.266806],
								[114.271011, 22.266806],
								[114.270973, 22.267084],
								[114.270691, 22.267084],
								[114.270691, 22.267639],
								[114.270416, 22.267639],
								[114.270416, 22.267916],
								[114.270149, 22.267918],
								[114.270142, 22.268473],
								[114.269859, 22.268473],
								[114.269859, 22.268749],
								[114.269302, 22.268749],
								[114.269302, 22.269583],
								[114.269577, 22.269583],
								[114.269585, 22.269861],
								[114.269859, 22.269861],
								[114.269859, 22.270416],
								[114.270142, 22.270416],
								[114.270142, 22.270971],
								[114.270416, 22.270971],
								[114.270416, 22.271250],
								[114.271248, 22.271250],
								[114.271248, 22.271528],
								[114.271530, 22.271528],
								[114.271530, 22.271805],
								[114.271805, 22.271805],
								[114.271805, 22.272083],
								[114.274025, 22.272083],
								[114.274025, 22.271805],
								[114.275139, 22.271805],
								[114.275139, 22.272917],
								[114.274582, 22.272917],
								[114.274582, 22.273193],
								[114.272919, 22.273193],
								[114.272919, 22.273472],
								[114.272636, 22.273472],
								[114.272636, 22.274027],
								[114.271805, 22.274027],
								[114.271805, 22.274305],
								[114.272057, 22.274305],
								[114.272087, 22.274584],
								[114.272362, 22.274584],
								[114.272362, 22.274860],
								[114.272636, 22.274860],
								[114.272636, 22.275139],
								[114.272919, 22.275139],
								[114.272919, 22.275415],
								[114.273193, 22.275415],
								[114.273193, 22.276529],
								[114.272087, 22.276529],
								[114.272087, 22.276251],
								[114.271805, 22.276251],
								[114.271805, 22.275972],
								[114.271530, 22.275972],
								[114.271530, 22.275694],
								[114.270973, 22.275694],
								[114.270973, 22.275415],
								[114.270691, 22.275415],
								[114.270691, 22.275141],
								[114.270416, 22.275139],
								[114.270416, 22.274860],
								[114.270142, 22.274860],
								[114.270142, 22.274584],
								[114.269859, 22.274584],
								[114.269859, 22.274305],
								[114.269028, 22.274305],
								[114.269028, 22.274031],
								[114.268196, 22.274027],
								[114.268196, 22.273472],
								[114.267365, 22.273472],
								[114.267365, 22.273750],
								[114.266251, 22.273750],
								[114.266251, 22.274027],
								[114.265419, 22.274027],
								[114.265419, 22.274305],
								[114.265137, 22.274305],
								[114.265137, 22.274860],
								[114.264580, 22.274860],
								[114.264580, 22.275415],
								[114.264305, 22.275415],
								[114.264305, 22.276251],
								[114.264580, 22.276251],
								[114.264580, 22.276529],
								[114.264862, 22.276529],
								[114.264862, 22.277916],
								[114.265137, 22.277916],
								[114.265137, 22.278194],
								[114.265419, 22.278194],
								[114.265419, 22.278473],
								[114.265686, 22.278473],
								[114.265694, 22.278748],
								[114.265976, 22.278748],
								[114.265976, 22.279030],
								[114.266251, 22.279030],
								[114.266251, 22.279583],
								[114.266525, 22.279583],
								[114.266525, 22.279861],
								[114.266808, 22.279861],
								[114.266808, 22.280140],
								[114.267082, 22.280140],
								[114.267082, 22.282084],
								[114.266533, 22.282084],
								[114.266525, 22.282917],
								[114.266808, 22.282917],
								[114.266808, 22.283472],
								[114.267082, 22.283472],
								[114.267082, 22.284027],
								[114.267365, 22.284027],
								[114.267365, 22.284861],
								[114.267639, 22.284861],
								[114.267639, 22.285139],
								[114.267914, 22.285139],
								[114.267914, 22.285694],
								[114.268196, 22.285694],
								[114.268196, 22.285973],
								[114.268471, 22.285973],
								[114.268471, 22.287083],
								[114.268753, 22.287083],
								[114.268753, 22.289026],
								[114.268471, 22.289026],
								[114.268471, 22.291248],
								[114.268196, 22.291248],
								[114.268196, 22.291531],
								[114.267914, 22.291531],
								[114.267914, 22.291805],
								[114.267639, 22.291805],
								[114.267639, 22.292084],
								[114.267082, 22.292084],
								[114.267082, 22.292358],
								[114.266808, 22.292358],
								[114.266808, 22.292641],
								[114.266525, 22.292641],
								[114.266525, 22.292917],
								[114.266251, 22.292917],
								[114.266251, 22.293194],
								[114.265976, 22.293194],
								[114.265976, 22.293472],
								[114.266251, 22.293472],
								[114.266251, 22.293751],
								[114.265976, 22.293751],
								[114.265976, 22.294582],
								[114.266251, 22.294584],
								[114.266251, 22.295139],
								[114.265976, 22.295139],
								[114.265976, 22.295416],
								[114.265694, 22.295416],
								[114.265694, 22.295973],
								[114.265419, 22.295973],
								[114.265419, 22.296249],
								[114.265137, 22.296249],
								[114.265137, 22.296806],
								[114.264862, 22.296806],
								[114.264862, 22.297085],
								[114.264580, 22.297085],
								[114.264580, 22.297638],
								[114.264305, 22.297640],
								[114.264305, 22.298195],
								[114.264030, 22.298195],
								[114.264030, 22.299028],
								[114.263748, 22.299028],
								[114.263748, 22.300694],
								[114.262642, 22.300695],
								[114.262642, 22.300972],
								[114.262360, 22.300972],
								[114.262360, 22.300695],
								[114.261803, 22.300695],
								[114.261803, 22.300972],
								[114.260696, 22.300972],
								[114.260696, 22.301250],
								[114.259583, 22.301250],
								[114.259583, 22.300972],
								[114.259308, 22.300972],
								[114.259308, 22.301250],
								[114.258194, 22.301250],
								[114.258194, 22.301525],
								[114.257637, 22.301525],
								[114.257637, 22.302637],
								[114.257919, 22.302637],
								[114.257919, 22.303473],
								[114.257362, 22.303473],
								[114.257362, 22.303747],
								[114.257080, 22.303747],
								[114.257080, 22.304029],
								[114.256531, 22.304029],
								[114.256531, 22.303747],
								[114.256248, 22.303747],
								[114.256248, 22.304029],
								[114.255692, 22.304029],
								[114.255692, 22.303747],
								[114.255417, 22.303747],
								[114.255417, 22.302637],
								[114.255692, 22.302637],
								[114.255692, 22.302084],
								[114.255959, 22.302084],
								[114.255974, 22.301805],
								[114.256248, 22.301805],
								[114.256248, 22.301525],
								[114.255974, 22.301525],
								[114.255974, 22.301250],
								[114.256248, 22.301250],
								[114.256248, 22.300972],
								[114.255974, 22.300972],
								[114.255974, 22.300417],
								[114.256531, 22.300417],
								[114.256531, 22.299303],
								[114.255974, 22.299303],
								[114.255974, 22.299028],
								[114.256248, 22.299028],
								[114.256248, 22.298750],
								[114.255417, 22.298750],
								[114.255417, 22.298471],
								[114.254860, 22.298471],
								[114.254860, 22.297916],
								[114.254585, 22.297916],
								[114.254585, 22.297640],
								[114.253761, 22.297638],
								[114.253754, 22.297361],
								[114.253471, 22.297361],
								[114.253471, 22.297085],
								[114.252914, 22.297085],
								[114.252914, 22.296528],
								[114.252640, 22.296528],
								[114.252640, 22.296249],
								[114.252083, 22.296249],
								[114.252083, 22.295973],
								[114.251808, 22.295973],
								[114.251808, 22.295416],
								[114.251526, 22.295416],
								[114.251526, 22.295141],
								[114.251251, 22.295139],
								[114.251251, 22.294584],
								[114.250969, 22.294584],
								[114.250969, 22.294306],
								[114.250694, 22.294306],
								[114.250694, 22.293751],
								[114.250420, 22.293751],
								[114.250420, 22.293472],
								[114.250137, 22.293472],
								[114.250137, 22.293194],
								[114.249863, 22.293194],
								[114.249863, 22.292917],
								[114.249580, 22.292917],
								[114.249580, 22.292641],
								[114.249306, 22.292641],
								[114.249306, 22.292358],
								[114.248474, 22.292358],
								[114.248474, 22.292084],
								[114.248322, 22.292084],
								[114.248192, 22.292084],
								[114.248192, 22.291885],
								[114.248192, 22.291531],
								[114.247955, 22.291531],
								[114.247917, 22.291248],
								[114.247765, 22.291248],
								[114.247643, 22.291248],
								[114.247643, 22.291059],
								[114.247643, 22.290695],
								[114.247406, 22.290695],
								[114.247093, 22.290701],
								[114.247086, 22.290421],
								[114.246803, 22.290419],
								[114.246803, 22.289583],
								[114.247314, 22.289583],
								[114.247360, 22.289305],
								[114.246803, 22.289305],
								[114.246803, 22.288750],
								[114.246529, 22.288750],
								[114.246529, 22.288473],
								[114.246246, 22.288473],
								[114.246246, 22.288195],
								[114.245697, 22.288195],
								[114.245697, 22.287640],
								[114.245415, 22.287640],
								[114.245415, 22.287914],
								[114.245140, 22.287914],
								[114.245140, 22.287640],
								[114.244583, 22.287640],
								[114.244583, 22.287231],
								[114.244583, 22.287083],
								[114.244308, 22.287083],
								[114.244308, 22.286804],
								[114.244026, 22.286804],
								[114.244026, 22.286528],
								[114.243752, 22.286528],
								[114.243752, 22.286257],
								[114.242386, 22.285915],
								[114.241966, 22.287601],
								[114.240700, 22.288443],
								[114.242386, 22.290546],
								[114.241547, 22.291388],
								[114.241127, 22.293074],
								[114.241966, 22.293915],
								[114.241127, 22.294758],
								[114.241966, 22.296021],
								[114.244072, 22.295176],
								[114.245331, 22.295176],
								[114.245331, 22.296438],
								[114.244911, 22.297703],
								[114.244911, 22.298964],
								[114.243652, 22.300228],
								[114.243652, 22.301493],
								[114.243652, 22.302755],
								[114.242386, 22.303177],
								[114.241966, 22.304440],
								[114.240280, 22.304859],
								[114.239861, 22.306124],
								[114.239441, 22.307386],
								[114.240280, 22.308228],
								[114.241547, 22.308647],
								[114.241966, 22.309912],
								[114.243652, 22.311174],
								[114.244072, 22.312439],
								[114.244911, 22.313702],
								[114.243233, 22.315386],
								[114.241966, 22.315386],
								[114.240280, 22.315805],
								[114.242386, 22.317911],
								[114.242805, 22.319174],
								[114.243233, 22.321280],
								[114.241966, 22.321701],
								[114.241127, 22.320436],
								[114.239441, 22.320436],
								[114.238174, 22.321280],
								[114.236069, 22.322542],
								[114.234390, 22.322542],
								[114.233124, 22.323805],
								[114.232285, 22.326752],
								[114.231865, 22.328436],
								[114.229759, 22.331804],
								[114.229340, 22.333067],
								[114.228912, 22.334330],
								[114.227654, 22.334330],
								[114.225548, 22.333910],
								[114.223022, 22.333910],
								[114.223022, 22.334042],
								[114.223122, 22.334146],
								[114.223328, 22.334375],
								[114.223579, 22.335474],
								[114.223381, 22.336473],
								[114.223381, 22.337723],
								[114.222855, 22.340071],
								[114.222450, 22.342970],
								[114.221756, 22.345760],
								[114.221756, 22.346960],
								[114.221596, 22.347445],
								[114.221405, 22.349464],
								[114.221054, 22.351864],
								[114.221107, 22.353863],
								[114.221153, 22.355913],
								[114.221405, 22.357962],
								[114.221367, 22.358696],
								[114.221352, 22.358912],
								[114.221344, 22.359262],
								[114.221336, 22.359592],
								[114.223022, 22.359592],
								[114.223022, 22.360853],
								[114.223022, 22.362118],
								[114.222923, 22.362406],
								[114.222603, 22.363380],
								[114.222603, 22.364641],
								[114.223862, 22.366325],
								[114.225128, 22.368429],
								[114.225243, 22.368786],
								[114.225548, 22.369694],
								[114.225754, 22.370314],
								[114.225967, 22.370955],
								[114.227173, 22.371859],
								[114.227654, 22.372219],
								[114.231865, 22.372219],
								[114.232498, 22.372433],
								[114.233124, 22.372641],
								[114.233971, 22.373484],
								[114.236069, 22.373901],
								[114.237335, 22.374746],
								[114.239441, 22.376431],
								[114.240280, 22.377272],
								[114.240868, 22.377853],
								[114.241127, 22.378115],
								[114.241348, 22.378777],
								[114.241547, 22.379377],
								[114.241661, 22.379736],
								[114.241814, 22.380184],
								[114.241966, 22.380638],
								[114.242676, 22.380783],
								[114.244072, 22.381062],
								[114.244911, 22.382324],
								[114.245239, 22.383299],
								[114.245331, 22.383587],
								[114.247017, 22.385271],
								[114.246788, 22.385963],
								[114.246590, 22.386560],
								[114.246178, 22.387796],
								[114.247437, 22.387796],
								[114.248703, 22.387796],
								[114.249962, 22.387796],
								[114.251228, 22.387796],
								[114.252495, 22.387796],
								[114.253754, 22.387796],
								[114.253754, 22.389061],
								[114.252541, 22.390270],
								[114.252068, 22.390745],
								[114.252068, 22.394533],
								[114.254173, 22.395796],
								[114.255440, 22.397058],
								[114.256332, 22.397358],
								[114.256699, 22.397480],
								[114.257545, 22.398743],
								[114.272278, 22.398743],
								[114.272949, 22.399414],
								[114.273964, 22.400427],
								[114.275223, 22.401690],
								[114.277748, 22.403795],
								[114.279015, 22.402954],
								[114.281540, 22.402954],
								[114.281593, 22.403059],
								[114.282806, 22.405479],
								[114.283363, 22.406036],
								[114.283646, 22.406321],
								[114.284912, 22.407164],
								[114.286591, 22.408848],
								[114.286949, 22.409922],
								[114.287010, 22.410110],
								[114.287064, 22.410643],
								[114.287437, 22.414320],
								[114.287735, 22.414772],
								[114.288277, 22.415583],
								[114.290382, 22.416426],
								[114.292068, 22.416845],
								[114.293327, 22.416845],
								[114.295013, 22.416426],
								[114.296700, 22.415583],
								[114.298599, 22.416218],
								[114.299225, 22.416426],
								[114.299629, 22.416290],
								[114.300484, 22.416004],
								[114.301414, 22.415771],
								[114.302170, 22.415583],
								[114.303856, 22.415583],
								[114.306381, 22.417267],
								[114.305115, 22.418953],
								[114.306381, 22.420635],
								[114.306610, 22.420980],
								[114.307220, 22.421900],
								[114.306953, 22.422562],
								[114.306381, 22.424006],
								[114.306381, 22.426107],
								[114.307640, 22.427374],
								[114.309326, 22.428213],
								[114.310211, 22.427992],
								[114.311012, 22.427792],
								[114.311523, 22.427622],
								[114.312271, 22.427374],
								[114.313538, 22.427374],
								[114.315643, 22.426950],
								[114.315865, 22.426893],
								[114.317322, 22.426531],
								[114.318588, 22.426531],
								[114.319099, 22.426188],
								[114.319855, 22.425690],
								[114.320267, 22.424854],
								[114.320694, 22.424006],
								[114.322258, 22.423742],
								[114.323219, 22.423582],
								[114.323822, 22.423182],
								[114.324486, 22.422743],
								[114.323601, 22.422081],
								[114.322800, 22.421476],
								[114.322243, 22.420923],
								[114.321953, 22.420635],
								[114.322800, 22.419794],
								[114.323120, 22.419306],
								[114.323639, 22.418530],
								[114.324471, 22.417906],
								[114.325325, 22.417267],
								[114.326164, 22.416426],
								[114.326508, 22.416309],
								[114.328690, 22.415583],
								[114.329575, 22.415142],
								[114.330376, 22.414742],
								[114.332062, 22.414742],
								[114.333321, 22.414742],
								[114.334572, 22.415157],
								[114.336273, 22.416845],
								[114.336563, 22.417719],
								[114.336693, 22.418112],
								[114.337532, 22.418953],
								[114.338287, 22.418701],
								[114.338799, 22.418530],
								[114.339272, 22.418051],
								[114.339638, 22.417688],
								[114.340904, 22.416004],
								[114.341743, 22.415165],
								[114.343010, 22.414320],
								[114.343369, 22.413958],
								[114.343849, 22.413479],
								[114.344688, 22.412214],
								[114.345955, 22.410534],
								[114.347214, 22.410534],
								[114.349319, 22.410534],
								[114.350327, 22.409861],
								[114.350586, 22.409689],
								[114.351204, 22.409481],
								[114.351845, 22.409267],
								[114.351845, 22.408005],
								[114.351845, 22.406742],
								[114.353111, 22.405901],
								[114.354797, 22.405901],
								[114.355240, 22.406048],
								[114.356056, 22.406321],
								[114.358162, 22.407585],
								[114.359001, 22.408426],
								[114.361534, 22.410952],
								[114.361954, 22.412214],
								[114.362450, 22.412960],
								[114.362793, 22.413479],
								[114.363785, 22.413975],
								[114.364479, 22.414320],
								[114.365738, 22.415165],
								[114.365738, 22.416426],
								[114.365738, 22.417688],
								[114.367004, 22.419794],
								[114.367172, 22.420290],
								[114.367424, 22.421059],
								[114.367554, 22.421440],
								[114.367844, 22.422319],
								[114.368225, 22.423838],
								[114.368263, 22.424006],
								[114.368690, 22.425266],
								[114.367844, 22.426107],
								[114.366165, 22.427792],
								[114.366959, 22.429785],
								[114.367004, 22.429897],
								[114.367004, 22.432001],
								[114.365929, 22.432646],
								[114.364899, 22.433266],
								[114.364616, 22.433823],
								[114.364059, 22.434950],
								[114.364250, 22.435537],
								[114.364479, 22.436213],
								[114.364975, 22.436964],
								[114.365318, 22.437473],
								[114.365318, 22.438738],
								[114.364761, 22.440126],
								[114.364479, 22.440844],
								[114.364899, 22.442104],
								[114.365242, 22.442223],
								[114.366165, 22.442528],
								[114.367424, 22.442945],
								[114.368469, 22.441904]
							]
						],
						[
							[
								[114.428192, 22.467081],
								[114.428192, 22.465973],
								[114.428474, 22.465973],
								[114.428474, 22.465694],
								[114.428749, 22.465694],
								[114.428749, 22.465137],
								[114.429031, 22.465137],
								[114.429031, 22.464582],
								[114.428192, 22.464582],
								[114.428192, 22.464861],
								[114.427917, 22.464861],
								[114.427917, 22.465137],
								[114.427643, 22.465137],
								[114.427635, 22.465416],
								[114.427361, 22.465416],
								[114.427361, 22.465973],
								[114.427086, 22.465973],
								[114.427086, 22.466240],
								[114.426804, 22.466251],
								[114.426804, 22.467081],
								[114.428192, 22.467081]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Sha Tin",
					"ID_0": 102,
					"ID_1": 9,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.232086, 22.428194],
							[114.232086, 22.427917],
							[114.232635, 22.427917],
							[114.232635, 22.427641],
							[114.233192, 22.427641],
							[114.233192, 22.427359],
							[114.233749, 22.427359],
							[114.233749, 22.427641],
							[114.234024, 22.427641],
							[114.234024, 22.427917],
							[114.234306, 22.427917],
							[114.234306, 22.429028],
							[114.234863, 22.429028],
							[114.234863, 22.429306],
							[114.235970, 22.429306],
							[114.235970, 22.429583],
							[114.236252, 22.429583],
							[114.236252, 22.429861],
							[114.236809, 22.429861],
							[114.236809, 22.430140],
							[114.237076, 22.430140],
							[114.237083, 22.430416],
							[114.237358, 22.430416],
							[114.237358, 22.430695],
							[114.237640, 22.430695],
							[114.237640, 22.430973],
							[114.238197, 22.430973],
							[114.238197, 22.431250],
							[114.238472, 22.431250],
							[114.238472, 22.431528],
							[114.238747, 22.431528],
							[114.238747, 22.432083],
							[114.239029, 22.432083],
							[114.239029, 22.432917],
							[114.238747, 22.432917],
							[114.238747, 22.434029],
							[114.238472, 22.434029],
							[114.238472, 22.434305],
							[114.238197, 22.434305],
							[114.238197, 22.434584],
							[114.237915, 22.434584],
							[114.237915, 22.434860],
							[114.237358, 22.434860],
							[114.237358, 22.435139],
							[114.237083, 22.435139],
							[114.237083, 22.436251],
							[114.237358, 22.436251],
							[114.237358, 22.436527],
							[114.238197, 22.436527],
							[114.238197, 22.436258],
							[114.238472, 22.436251],
							[114.238472, 22.435972],
							[114.239029, 22.435972],
							[114.239029, 22.436527],
							[114.239304, 22.436527],
							[114.239304, 22.436806],
							[114.239571, 22.436806],
							[114.239586, 22.437084],
							[114.239861, 22.437084],
							[114.239861, 22.437361],
							[114.240135, 22.437361],
							[114.240135, 22.437637],
							[114.241806, 22.437637],
							[114.241806, 22.437361],
							[114.242081, 22.437361],
							[114.242081, 22.437084],
							[114.242363, 22.437084],
							[114.242363, 22.437361],
							[114.242920, 22.437361],
							[114.242920, 22.436806],
							[114.243187, 22.436806],
							[114.243195, 22.436531],
							[114.243752, 22.436527],
							[114.243752, 22.435972],
							[114.243988, 22.435972],
							[114.244026, 22.435694],
							[114.244308, 22.435694],
							[114.244308, 22.435415],
							[114.244858, 22.435415],
							[114.244858, 22.435139],
							[114.245972, 22.435139],
							[114.245972, 22.435415],
							[114.246246, 22.435415],
							[114.246246, 22.435694],
							[114.246529, 22.435694],
							[114.246529, 22.435972],
							[114.247086, 22.435972],
							[114.247086, 22.435694],
							[114.247360, 22.435694],
							[114.247360, 22.435415],
							[114.247917, 22.435415],
							[114.247917, 22.435141],
							[114.247643, 22.435139],
							[114.247643, 22.434860],
							[114.246246, 22.434860],
							[114.246246, 22.434584],
							[114.245972, 22.434584],
							[114.245972, 22.434305],
							[114.245697, 22.434305],
							[114.245697, 22.434031],
							[114.245140, 22.434029],
							[114.245140, 22.433750],
							[114.244858, 22.433750],
							[114.244858, 22.433193],
							[114.244583, 22.433193],
							[114.244583, 22.432917],
							[114.244308, 22.432917],
							[114.244308, 22.431530],
							[114.244858, 22.431528],
							[114.244858, 22.431250],
							[114.245697, 22.431257],
							[114.245758, 22.431162],
							[114.247307, 22.431419],
							[114.248283, 22.431580],
							[114.248283, 22.430319],
							[114.247658, 22.429693],
							[114.247017, 22.429054],
							[114.247017, 22.427792],
							[114.249123, 22.426950],
							[114.249123, 22.425690],
							[114.249123, 22.424425],
							[114.249123, 22.422743],
							[114.248993, 22.422415],
							[114.248283, 22.420635],
							[114.248116, 22.419794],
							[114.247864, 22.418530],
							[114.248001, 22.418097],
							[114.248062, 22.417925],
							[114.248283, 22.417267],
							[114.247437, 22.414320],
							[114.247871, 22.414146],
							[114.249542, 22.413479],
							[114.250580, 22.412703],
							[114.251228, 22.412214],
							[114.251648, 22.410110],
							[114.251228, 22.407164],
							[114.252068, 22.406321],
							[114.252190, 22.406199],
							[114.252914, 22.405479],
							[114.252914, 22.404217],
							[114.252914, 22.402954],
							[114.253754, 22.402111],
							[114.253334, 22.400848],
							[114.252495, 22.400005],
							[114.252251, 22.399277],
							[114.252068, 22.398743],
							[114.252068, 22.394533],
							[114.252068, 22.390745],
							[114.252541, 22.390270],
							[114.253754, 22.389061],
							[114.253754, 22.387796],
							[114.252495, 22.387796],
							[114.251228, 22.387796],
							[114.249962, 22.387796],
							[114.248703, 22.387796],
							[114.247437, 22.387796],
							[114.246178, 22.387796],
							[114.246590, 22.386560],
							[114.246788, 22.385963],
							[114.247017, 22.385271],
							[114.245331, 22.383587],
							[114.245239, 22.383299],
							[114.244911, 22.382324],
							[114.244072, 22.381062],
							[114.242676, 22.380783],
							[114.241966, 22.380638],
							[114.241814, 22.380184],
							[114.241661, 22.379736],
							[114.241547, 22.379377],
							[114.241348, 22.378777],
							[114.241127, 22.378115],
							[114.240868, 22.377853],
							[114.240280, 22.377272],
							[114.239441, 22.376431],
							[114.237335, 22.374746],
							[114.236069, 22.373901],
							[114.233971, 22.373484],
							[114.233124, 22.372641],
							[114.232498, 22.372433],
							[114.231865, 22.372219],
							[114.227654, 22.372219],
							[114.227173, 22.371859],
							[114.225967, 22.370955],
							[114.225754, 22.370314],
							[114.225548, 22.369694],
							[114.225243, 22.368786],
							[114.225128, 22.368429],
							[114.223862, 22.366325],
							[114.222603, 22.364641],
							[114.222603, 22.363380],
							[114.222923, 22.362406],
							[114.223022, 22.362118],
							[114.223022, 22.360853],
							[114.223022, 22.359592],
							[114.221336, 22.359592],
							[114.220078, 22.359592],
							[114.218811, 22.359592],
							[114.217552, 22.359592],
							[114.217033, 22.359280],
							[114.215446, 22.358330],
							[114.213341, 22.358330],
							[114.213120, 22.357443],
							[114.213081, 22.357298],
							[114.213036, 22.357117],
							[114.212921, 22.356646],
							[114.212189, 22.356522],
							[114.211868, 22.356468],
							[114.210388, 22.356222],
							[114.210121, 22.356312],
							[114.209763, 22.356434],
							[114.209129, 22.356646],
							[114.207443, 22.355801],
							[114.205757, 22.355801],
							[114.204498, 22.355801],
							[114.204292, 22.355734],
							[114.203835, 22.355579],
							[114.203232, 22.355383],
							[114.201973, 22.355383],
							[114.200706, 22.355383],
							[114.199448, 22.356222],
							[114.199020, 22.355585],
							[114.198601, 22.354959],
							[114.197342, 22.352854],
							[114.195618, 22.352568],
							[114.194817, 22.352434],
							[114.193550, 22.354116],
							[114.191864, 22.354116],
							[114.191498, 22.353746],
							[114.191025, 22.353275],
							[114.191071, 22.353144],
							[114.191307, 22.352434],
							[114.191368, 22.352255],
							[114.191444, 22.352013],
							[114.191444, 22.350912],
							[114.191368, 22.350752],
							[114.189766, 22.350752],
							[114.189148, 22.350542],
							[114.188499, 22.350328],
							[114.188171, 22.350264],
							[114.187309, 22.350092],
							[114.186394, 22.349909],
							[114.186020, 22.350033],
							[114.185722, 22.350130],
							[114.185135, 22.350328],
							[114.184036, 22.350599],
							[114.183449, 22.350752],
							[114.181885, 22.350122],
							[114.181343, 22.349909],
							[114.179657, 22.349068],
							[114.174187, 22.349068],
							[114.173340, 22.348223],
							[114.172920, 22.346960],
							[114.171242, 22.346960],
							[114.169975, 22.347382],
							[114.167870, 22.346539],
							[114.166611, 22.346960],
							[114.165344, 22.346960],
							[114.164078, 22.348223],
							[114.162819, 22.349068],
							[114.161552, 22.347803],
							[114.160294, 22.347803],
							[114.158607, 22.345697],
							[114.157349, 22.346121],
							[114.154816, 22.347382],
							[114.152718, 22.346539],
							[114.151031, 22.347382],
							[114.150612, 22.346121],
							[114.149765, 22.345276],
							[114.148506, 22.345276],
							[114.147240, 22.345276],
							[114.146469, 22.346048],
							[114.145981, 22.346539],
							[114.144714, 22.347382],
							[114.144714, 22.348644],
							[114.143456, 22.349485],
							[114.142021, 22.349844],
							[114.141769, 22.349909],
							[114.141350, 22.351170],
							[114.140930, 22.354116],
							[114.140930, 22.355085],
							[114.140930, 22.355383],
							[114.140930, 22.356339],
							[114.140930, 22.358330],
							[114.143028, 22.360434],
							[114.143456, 22.361694],
							[114.145134, 22.362535],
							[114.145561, 22.363800],
							[114.145302, 22.364574],
							[114.145134, 22.365065],
							[114.144203, 22.366924],
							[114.143875, 22.367590],
							[114.144714, 22.369694],
							[114.146339, 22.370668],
							[114.146820, 22.370955],
							[114.145134, 22.372641],
							[114.144814, 22.373125],
							[114.144295, 22.373901],
							[114.145134, 22.376007],
							[114.147659, 22.376431],
							[114.148926, 22.377691],
							[114.148926, 22.378712],
							[114.148926, 22.380219],
							[114.149696, 22.380987],
							[114.151031, 22.382324],
							[114.151939, 22.382627],
							[114.152290, 22.382746],
							[114.153976, 22.382746],
							[114.155663, 22.383587],
							[114.155807, 22.383804],
							[114.156502, 22.384850],
							[114.157547, 22.385893],
							[114.158188, 22.386534],
							[114.158829, 22.386749],
							[114.159447, 22.386955],
							[114.161133, 22.388639],
							[114.161308, 22.389154],
							[114.161415, 22.389481],
							[114.161552, 22.389902],
							[114.162399, 22.391165],
							[114.164505, 22.392427],
							[114.166183, 22.393692],
							[114.167030, 22.394955],
							[114.167030, 22.396217],
							[114.166183, 22.399164],
							[114.166183, 22.400427],
							[114.167870, 22.401270],
							[114.167603, 22.402073],
							[114.167488, 22.402414],
							[114.167450, 22.402533],
							[114.167603, 22.403130],
							[114.167732, 22.403654],
							[114.167870, 22.404217],
							[114.167625, 22.405195],
							[114.167534, 22.405571],
							[114.167450, 22.405901],
							[114.167450, 22.407164],
							[114.166611, 22.408005],
							[114.165764, 22.408848],
							[114.165764, 22.410110],
							[114.166061, 22.411291],
							[114.166183, 22.411795],
							[114.166183, 22.412382],
							[114.166595, 22.412733],
							[114.167809, 22.412954],
							[114.169518, 22.412954],
							[114.170738, 22.412897],
							[114.171677, 22.412897],
							[114.172554, 22.412954],
							[114.172997, 22.413450],
							[114.173332, 22.414167],
							[114.173492, 22.414940],
							[114.174820, 22.415436],
							[114.175591, 22.415436],
							[114.177299, 22.415327],
							[114.179512, 22.415216],
							[114.183479, 22.415051],
							[114.184471, 22.415161],
							[114.184807, 22.415602],
							[114.184807, 22.416426],
							[114.185135, 22.416426],
							[114.185318, 22.417353],
							[114.185455, 22.418049],
							[114.186409, 22.418140],
							[114.187508, 22.418140],
							[114.189499, 22.418085],
							[114.194901, 22.418085],
							[114.196777, 22.418085],
							[114.198273, 22.418085],
							[114.198433, 22.418362],
							[114.198769, 22.419079],
							[114.198875, 22.420568],
							[114.199486, 22.422113],
							[114.199486, 22.423824],
							[114.199867, 22.424982],
							[114.200531, 22.425755],
							[114.201469, 22.425755],
							[114.202118, 22.424965],
							[114.202393, 22.423582],
							[114.202438, 22.423164],
							[114.203156, 22.423107],
							[114.203316, 22.423582],
							[114.203659, 22.423582],
							[114.204048, 22.426327],
							[114.204918, 22.426416],
							[114.205971, 22.425976],
							[114.206680, 22.425976],
							[114.207344, 22.425976],
							[114.207611, 22.426336],
							[114.207855, 22.426664],
							[114.207954, 22.426804],
							[114.208351, 22.427359],
							[114.208473, 22.427359],
							[114.208473, 22.427090],
							[114.208748, 22.427084],
							[114.208748, 22.426805],
							[114.209030, 22.426805],
							[114.209030, 22.426529],
							[114.209297, 22.426529],
							[114.209305, 22.426249],
							[114.209587, 22.426249],
							[114.209587, 22.425970],
							[114.209846, 22.425970],
							[114.209862, 22.425694],
							[114.210136, 22.425694],
							[114.210136, 22.425419],
							[114.210419, 22.425419],
							[114.210419, 22.425140],
							[114.210693, 22.425140],
							[114.210693, 22.424862],
							[114.210960, 22.424862],
							[114.210976, 22.424583],
							[114.211250, 22.424583],
							[114.211250, 22.424305],
							[114.211807, 22.424305],
							[114.211807, 22.424030],
							[114.212082, 22.424026],
							[114.212082, 22.423750],
							[114.215416, 22.423750],
							[114.215416, 22.423471],
							[114.215141, 22.423471],
							[114.215141, 22.422916],
							[114.214859, 22.422916],
							[114.214859, 22.422638],
							[114.215141, 22.422638],
							[114.215141, 22.422361],
							[114.214859, 22.422361],
							[114.214859, 22.421249],
							[114.214584, 22.421249],
							[114.214584, 22.420973],
							[114.214859, 22.420973],
							[114.214859, 22.420416],
							[114.214584, 22.420416],
							[114.214584, 22.420141],
							[114.214859, 22.420139],
							[114.214859, 22.418751],
							[114.214584, 22.418751],
							[114.214584, 22.417921],
							[114.214859, 22.417917],
							[114.214859, 22.417641],
							[114.214584, 22.417639],
							[114.214584, 22.416531],
							[114.214859, 22.416529],
							[114.214859, 22.415974],
							[114.214584, 22.415974],
							[114.214584, 22.415417],
							[114.214302, 22.415417],
							[114.214302, 22.413752],
							[114.214027, 22.413752],
							[114.214027, 22.413195],
							[114.213753, 22.413195],
							[114.213753, 22.412083],
							[114.213470, 22.412083],
							[114.213470, 22.411810],
							[114.213753, 22.411806],
							[114.213753, 22.411530],
							[114.214027, 22.411530],
							[114.214027, 22.410973],
							[114.214584, 22.410973],
							[114.214584, 22.410694],
							[114.214859, 22.410694],
							[114.214859, 22.410419],
							[114.215141, 22.410419],
							[114.215141, 22.410141],
							[114.215973, 22.410137],
							[114.215973, 22.409584],
							[114.216232, 22.409584],
							[114.216248, 22.409306],
							[114.216530, 22.409306],
							[114.216530, 22.409025],
							[114.216797, 22.409025],
							[114.216805, 22.408751],
							[114.217087, 22.408751],
							[114.217087, 22.407915],
							[114.217361, 22.407915],
							[114.217361, 22.407362],
							[114.217087, 22.407362],
							[114.217087, 22.407084],
							[114.216805, 22.407084],
							[114.216805, 22.406807],
							[114.216530, 22.406807],
							[114.216530, 22.406528],
							[114.215973, 22.406528],
							[114.215973, 22.406250],
							[114.215698, 22.406250],
							[114.215691, 22.406078],
							[114.215691, 22.405972],
							[114.215416, 22.405972],
							[114.215416, 22.405693],
							[114.215141, 22.405693],
							[114.215141, 22.405416],
							[114.214859, 22.405416],
							[114.214859, 22.405140],
							[114.214584, 22.405138],
							[114.214584, 22.404861],
							[114.214302, 22.404861],
							[114.214302, 22.404585],
							[114.214027, 22.404585],
							[114.214027, 22.404306],
							[114.213753, 22.404306],
							[114.213753, 22.404030],
							[114.213470, 22.404028],
							[114.213470, 22.403749],
							[114.213196, 22.403749],
							[114.213196, 22.403473],
							[114.212914, 22.403473],
							[114.212914, 22.403194],
							[114.212364, 22.403194],
							[114.212364, 22.402916],
							[114.212082, 22.402916],
							[114.212082, 22.402639],
							[114.211807, 22.402639],
							[114.211807, 22.402363],
							[114.211525, 22.402363],
							[114.211525, 22.402082],
							[114.211250, 22.402082],
							[114.211250, 22.401806],
							[114.210976, 22.401806],
							[114.210976, 22.401527],
							[114.210693, 22.401527],
							[114.210693, 22.400972],
							[114.210419, 22.400972],
							[114.210419, 22.400417],
							[114.209862, 22.400417],
							[114.209862, 22.399858],
							[114.209305, 22.399858],
							[114.209305, 22.399584],
							[114.209030, 22.399584],
							[114.209030, 22.399305],
							[114.208748, 22.399305],
							[114.208748, 22.399031],
							[114.208473, 22.399027],
							[114.208473, 22.398748],
							[114.208191, 22.398748],
							[114.208191, 22.398472],
							[114.207916, 22.398472],
							[114.207916, 22.398195],
							[114.207642, 22.398195],
							[114.207642, 22.397919],
							[114.207359, 22.397919],
							[114.207359, 22.397362],
							[114.206802, 22.397362],
							[114.206802, 22.396526],
							[114.206253, 22.396526],
							[114.206253, 22.395971],
							[114.205971, 22.395971],
							[114.205971, 22.395140],
							[114.205696, 22.395140],
							[114.205696, 22.394861],
							[114.205139, 22.394861],
							[114.205139, 22.394583],
							[114.204025, 22.394583],
							[114.204025, 22.394028],
							[114.203751, 22.394028],
							[114.203751, 22.393473],
							[114.203476, 22.393473],
							[114.203476, 22.392918],
							[114.202919, 22.392916],
							[114.202919, 22.392641],
							[114.202370, 22.392639],
							[114.202362, 22.392361],
							[114.202080, 22.392361],
							[114.202080, 22.391806],
							[114.201530, 22.391806],
							[114.201530, 22.391251],
							[114.200974, 22.391251],
							[114.200974, 22.390417],
							[114.200691, 22.390417],
							[114.200691, 22.390139],
							[114.200417, 22.390139],
							[114.200417, 22.389862],
							[114.200142, 22.389862],
							[114.200142, 22.389305],
							[114.199860, 22.389305],
							[114.199860, 22.389029],
							[114.199303, 22.389029],
							[114.199303, 22.388750],
							[114.199028, 22.388750],
							[114.199028, 22.388472],
							[114.198753, 22.388472],
							[114.198753, 22.388195],
							[114.198196, 22.388195],
							[114.198196, 22.387638],
							[114.197639, 22.387638],
							[114.197639, 22.387362],
							[114.197083, 22.387362],
							[114.197083, 22.386530],
							[114.196808, 22.386530],
							[114.196808, 22.386251],
							[114.196526, 22.386251],
							[114.196526, 22.385973],
							[114.195969, 22.385973],
							[114.195969, 22.385416],
							[114.195419, 22.385416],
							[114.195419, 22.385138],
							[114.195137, 22.385138],
							[114.195137, 22.384583],
							[114.194862, 22.384583],
							[114.194862, 22.383751],
							[114.194580, 22.383751],
							[114.194580, 22.383472],
							[114.194305, 22.383472],
							[114.194305, 22.383194],
							[114.194031, 22.383194],
							[114.194031, 22.382915],
							[114.193748, 22.382915],
							[114.193748, 22.382639],
							[114.193474, 22.382639],
							[114.193474, 22.382360],
							[114.193192, 22.382360],
							[114.193192, 22.381527],
							[114.192917, 22.381527],
							[114.192917, 22.381250],
							[114.192360, 22.381250],
							[114.192360, 22.380701],
							[114.192093, 22.380695],
							[114.192085, 22.380417],
							[114.191528, 22.380417],
							[114.191528, 22.380138],
							[114.191254, 22.380138],
							[114.191254, 22.379862],
							[114.190971, 22.379862],
							[114.190971, 22.379305],
							[114.190697, 22.379305],
							[114.190697, 22.379028],
							[114.190414, 22.379028],
							[114.190414, 22.378750],
							[114.190140, 22.378750],
							[114.190140, 22.378471],
							[114.189857, 22.378471],
							[114.189857, 22.378195],
							[114.189583, 22.378195],
							[114.189583, 22.377916],
							[114.189308, 22.377916],
							[114.189308, 22.377640],
							[114.188751, 22.377640],
							[114.188751, 22.377361],
							[114.188309, 22.377361],
							[114.188194, 22.377361],
							[114.188194, 22.377083],
							[114.188469, 22.377083],
							[114.188469, 22.376806],
							[114.189583, 22.376806],
							[114.189583, 22.377083],
							[114.190140, 22.377083],
							[114.190140, 22.377361],
							[114.190254, 22.377361],
							[114.190697, 22.377361],
							[114.190697, 22.377640],
							[114.190971, 22.377640],
							[114.190971, 22.377916],
							[114.191254, 22.377916],
							[114.191254, 22.378195],
							[114.191528, 22.378195],
							[114.191528, 22.378471],
							[114.191803, 22.378471],
							[114.191803, 22.378750],
							[114.192085, 22.378750],
							[114.192085, 22.379028],
							[114.192642, 22.379028],
							[114.192642, 22.379305],
							[114.193192, 22.379305],
							[114.193192, 22.379583],
							[114.193466, 22.379583],
							[114.193474, 22.380138],
							[114.194031, 22.380138],
							[114.194031, 22.380417],
							[114.194305, 22.380417],
							[114.194305, 22.380972],
							[114.194580, 22.380972],
							[114.194580, 22.381527],
							[114.194862, 22.381527],
							[114.194862, 22.382360],
							[114.195419, 22.382360],
							[114.195419, 22.382639],
							[114.195694, 22.382639],
							[114.195694, 22.383194],
							[114.196251, 22.383194],
							[114.196251, 22.383472],
							[114.196808, 22.383472],
							[114.196808, 22.383751],
							[114.197083, 22.383751],
							[114.197083, 22.384027],
							[114.197365, 22.384027],
							[114.197365, 22.384583],
							[114.197639, 22.384583],
							[114.197639, 22.385138],
							[114.197906, 22.385138],
							[114.197914, 22.385416],
							[114.198196, 22.385416],
							[114.198196, 22.385695],
							[114.198753, 22.385695],
							[114.198753, 22.385973],
							[114.199303, 22.385973],
							[114.199303, 22.386251],
							[114.199585, 22.386251],
							[114.199585, 22.386530],
							[114.199860, 22.386530],
							[114.199860, 22.386801],
							[114.200417, 22.386806],
							[114.200417, 22.387083],
							[114.200142, 22.387083],
							[114.200142, 22.387638],
							[114.200691, 22.387638],
							[114.200691, 22.387917],
							[114.201248, 22.387917],
							[114.201248, 22.388195],
							[114.201530, 22.388195],
							[114.201530, 22.388472],
							[114.201805, 22.388472],
							[114.201805, 22.388750],
							[114.202080, 22.388750],
							[114.202080, 22.389029],
							[114.202362, 22.389029],
							[114.202362, 22.389305],
							[114.202637, 22.389305],
							[114.202637, 22.389860],
							[114.203186, 22.389862],
							[114.203194, 22.390139],
							[114.203476, 22.390139],
							[114.203476, 22.390417],
							[114.204025, 22.390417],
							[114.204025, 22.390694],
							[114.204308, 22.390694],
							[114.204308, 22.391251],
							[114.204582, 22.391251],
							[114.204582, 22.391527],
							[114.204865, 22.391527],
							[114.204865, 22.391806],
							[114.205414, 22.391806],
							[114.205414, 22.392361],
							[114.205696, 22.392361],
							[114.205696, 22.393194],
							[114.205971, 22.393194],
							[114.205971, 22.393473],
							[114.206253, 22.393473],
							[114.206253, 22.393749],
							[114.206528, 22.393749],
							[114.206528, 22.394028],
							[114.206802, 22.394028],
							[114.206802, 22.394583],
							[114.207359, 22.394583],
							[114.207359, 22.394861],
							[114.207642, 22.394861],
							[114.207642, 22.395416],
							[114.208191, 22.395416],
							[114.208191, 22.395695],
							[114.208748, 22.395695],
							[114.208748, 22.396250],
							[114.209297, 22.396250],
							[114.209305, 22.396526],
							[114.209587, 22.396526],
							[114.209587, 22.397083],
							[114.209862, 22.397083],
							[114.209862, 22.397636],
							[114.210419, 22.397636],
							[114.210419, 22.397919],
							[114.210976, 22.397919],
							[114.210976, 22.398195],
							[114.211250, 22.398195],
							[114.211250, 22.398472],
							[114.211517, 22.398472],
							[114.211525, 22.398748],
							[114.211807, 22.398748],
							[114.211807, 22.399027],
							[114.212082, 22.399027],
							[114.212082, 22.399584],
							[114.212639, 22.399584],
							[114.212639, 22.400141],
							[114.212914, 22.400141],
							[114.212914, 22.400417],
							[114.213470, 22.400417],
							[114.213470, 22.400972],
							[114.213753, 22.400972],
							[114.213753, 22.401253],
							[114.214027, 22.401253],
							[114.214027, 22.401527],
							[114.214302, 22.401527],
							[114.214302, 22.401806],
							[114.214584, 22.401806],
							[114.214584, 22.402363],
							[114.214859, 22.402363],
							[114.214859, 22.402639],
							[114.215141, 22.402639],
							[114.215141, 22.402916],
							[114.215416, 22.402916],
							[114.215416, 22.403194],
							[114.215698, 22.403194],
							[114.215698, 22.403473],
							[114.215973, 22.403473],
							[114.215973, 22.403749],
							[114.216248, 22.403749],
							[114.216248, 22.404028],
							[114.216530, 22.404028],
							[114.216530, 22.404306],
							[114.216766, 22.404306],
							[114.216805, 22.404585],
							[114.217087, 22.404585],
							[114.217087, 22.404861],
							[114.217361, 22.404861],
							[114.217361, 22.405138],
							[114.217636, 22.405138],
							[114.217636, 22.405693],
							[114.217918, 22.405693],
							[114.217918, 22.405972],
							[114.218193, 22.405972],
							[114.218193, 22.406250],
							[114.218475, 22.406250],
							[114.218475, 22.406528],
							[114.218704, 22.406528],
							[114.218750, 22.406807],
							[114.220695, 22.406807],
							[114.220695, 22.407084],
							[114.221527, 22.407084],
							[114.221527, 22.407915],
							[114.221802, 22.407915],
							[114.221802, 22.408472],
							[114.221527, 22.408472],
							[114.221527, 22.409025],
							[114.221252, 22.409025],
							[114.221252, 22.409584],
							[114.220970, 22.409584],
							[114.220970, 22.409861],
							[114.220703, 22.409861],
							[114.220695, 22.410137],
							[114.220970, 22.410137],
							[114.220970, 22.410973],
							[114.221527, 22.410973],
							[114.221527, 22.412638],
							[114.221252, 22.412638],
							[114.221252, 22.412916],
							[114.221527, 22.412916],
							[114.221527, 22.413195],
							[114.221802, 22.413195],
							[114.221802, 22.413303],
							[114.221809, 22.413473],
							[114.222084, 22.413473],
							[114.222084, 22.414862],
							[114.222359, 22.414862],
							[114.222359, 22.415695],
							[114.222641, 22.415695],
							[114.222641, 22.416529],
							[114.222916, 22.416529],
							[114.222916, 22.417639],
							[114.223198, 22.417639],
							[114.223198, 22.418194],
							[114.222923, 22.418194],
							[114.222916, 22.419306],
							[114.223747, 22.419306],
							[114.223747, 22.420139],
							[114.224030, 22.420139],
							[114.224030, 22.421249],
							[114.224297, 22.421249],
							[114.224304, 22.421806],
							[114.224030, 22.421806],
							[114.224030, 22.422083],
							[114.224304, 22.422083],
							[114.224304, 22.422361],
							[114.224586, 22.422361],
							[114.224586, 22.423195],
							[114.224861, 22.423195],
							[114.224861, 22.423471],
							[114.225136, 22.423471],
							[114.225136, 22.424583],
							[114.225418, 22.424583],
							[114.225418, 22.424862],
							[114.225693, 22.424862],
							[114.225693, 22.425419],
							[114.225975, 22.425419],
							[114.225975, 22.425694],
							[114.226250, 22.425694],
							[114.226250, 22.425970],
							[114.226517, 22.425970],
							[114.226524, 22.426529],
							[114.226807, 22.426529],
							[114.226807, 22.427084],
							[114.227081, 22.427084],
							[114.227081, 22.427359],
							[114.227638, 22.427359],
							[114.227638, 22.427641],
							[114.228195, 22.427641],
							[114.228195, 22.427917],
							[114.228661, 22.427917],
							[114.228752, 22.427917],
							[114.228752, 22.428194],
							[114.230698, 22.428194],
							[114.230698, 22.428473],
							[114.231804, 22.428473],
							[114.231804, 22.428194],
							[114.232086, 22.428194]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Sham Shui Po",
					"ID_0": 102,
					"ID_1": 10,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.172920, 22.346960],
							[114.173340, 22.345276],
							[114.172348, 22.343945],
							[114.172081, 22.343592],
							[114.172081, 22.342329],
							[114.173340, 22.341488],
							[114.174606, 22.340645],
							[114.174789, 22.340097],
							[114.175026, 22.339382],
							[114.175217, 22.338814],
							[114.175446, 22.338120],
							[114.176292, 22.336857],
							[114.176292, 22.333488],
							[114.176292, 22.332226],
							[114.175995, 22.331333],
							[114.175865, 22.330957],
							[114.175674, 22.330389],
							[114.175446, 22.329699],
							[114.174606, 22.328436],
							[114.174187, 22.327173],
							[114.173805, 22.327427],
							[114.173477, 22.327644],
							[114.172920, 22.328014],
							[114.171661, 22.328014],
							[114.171036, 22.328014],
							[114.170395, 22.328014],
							[114.169136, 22.328014],
							[114.167870, 22.328014],
							[114.166611, 22.328014],
							[114.165344, 22.328014],
							[114.164078, 22.328014],
							[114.162819, 22.328014],
							[114.161552, 22.328014],
							[114.160713, 22.327173],
							[114.161926, 22.325897],
							[114.160294, 22.325489],
							[114.159813, 22.325169],
							[114.159027, 22.324648],
							[114.158188, 22.323805],
							[114.157623, 22.323425],
							[114.156883, 22.322933],
							[114.156174, 22.324455],
							[114.156082, 22.324648],
							[114.153976, 22.326332],
							[114.152290, 22.324648],
							[114.152382, 22.324371],
							[114.152458, 22.324160],
							[114.152260, 22.323528],
							[114.152260, 22.322969],
							[114.151939, 22.322931],
							[114.151802, 22.322916],
							[114.151810, 22.323202],
							[114.151642, 22.323195],
							[114.151527, 22.323195],
							[114.151527, 22.323471],
							[114.151253, 22.323471],
							[114.151253, 22.324028],
							[114.150970, 22.324028],
							[114.150970, 22.324306],
							[114.150459, 22.324306],
							[114.150352, 22.324583],
							[114.149864, 22.324583],
							[114.149864, 22.324862],
							[114.149307, 22.324862],
							[114.149307, 22.325138],
							[114.149025, 22.325138],
							[114.149025, 22.325417],
							[114.148773, 22.325417],
							[114.148750, 22.325693],
							[114.148193, 22.325693],
							[114.148193, 22.325972],
							[114.147362, 22.325972],
							[114.147362, 22.326250],
							[114.146805, 22.326250],
							[114.146805, 22.326529],
							[114.146530, 22.326529],
							[114.146530, 22.326805],
							[114.146248, 22.326805],
							[114.146248, 22.327084],
							[114.144302, 22.327084],
							[114.144302, 22.326529],
							[114.144028, 22.326529],
							[114.144028, 22.326250],
							[114.143753, 22.326250],
							[114.143753, 22.325972],
							[114.143471, 22.325972],
							[114.143471, 22.325417],
							[114.143196, 22.325417],
							[114.143196, 22.325140],
							[114.142914, 22.325138],
							[114.142914, 22.324871],
							[114.143196, 22.324862],
							[114.143196, 22.324306],
							[114.143448, 22.324306],
							[114.143753, 22.324305],
							[114.143753, 22.324028],
							[114.144028, 22.324028],
							[114.144028, 22.323750],
							[114.144585, 22.323750],
							[114.144585, 22.323471],
							[114.145119, 22.323471],
							[114.145142, 22.323195],
							[114.145691, 22.323195],
							[114.145691, 22.322916],
							[114.145973, 22.322916],
							[114.145973, 22.322639],
							[114.146248, 22.322639],
							[114.146248, 22.322363],
							[114.146530, 22.322363],
							[114.146530, 22.322083],
							[114.146805, 22.322083],
							[114.146805, 22.321527],
							[114.146248, 22.321527],
							[114.146248, 22.321253],
							[114.145973, 22.321253],
							[114.145973, 22.320694],
							[114.145691, 22.320694],
							[114.145691, 22.320417],
							[114.145142, 22.320417],
							[114.145142, 22.320141],
							[114.144859, 22.320141],
							[114.144859, 22.319859],
							[114.144585, 22.319859],
							[114.144585, 22.319584],
							[114.144302, 22.319584],
							[114.144302, 22.319305],
							[114.144028, 22.319305],
							[114.144028, 22.318748],
							[114.143753, 22.318748],
							[114.143753, 22.318472],
							[114.143471, 22.318472],
							[114.143471, 22.318195],
							[114.143753, 22.318195],
							[114.143753, 22.317083],
							[114.144028, 22.317083],
							[114.144028, 22.316805],
							[114.143753, 22.316805],
							[114.143753, 22.316526],
							[114.143471, 22.316526],
							[114.143471, 22.315701],
							[114.143196, 22.315695],
							[114.143196, 22.315420],
							[114.142082, 22.315416],
							[114.142082, 22.315695],
							[114.141808, 22.315695],
							[114.141808, 22.316250],
							[114.141251, 22.316250],
							[114.141251, 22.316805],
							[114.140694, 22.316805],
							[114.140694, 22.317083],
							[114.140419, 22.317083],
							[114.140419, 22.317362],
							[114.140137, 22.317362],
							[114.140137, 22.317636],
							[114.139862, 22.317636],
							[114.139862, 22.317919],
							[114.136528, 22.317919],
							[114.136528, 22.318195],
							[114.133751, 22.318195],
							[114.133751, 22.317921],
							[114.132919, 22.317921],
							[114.132919, 22.317640],
							[114.132698, 22.317640],
							[114.132088, 22.319174],
							[114.130821, 22.320436],
							[114.130661, 22.321072],
							[114.131516, 22.321922],
							[114.132645, 22.323198],
							[114.135048, 22.324699],
							[114.137451, 22.326277],
							[114.139175, 22.327930],
							[114.139809, 22.328857],
							[114.139999, 22.329130],
							[114.140305, 22.330406],
							[114.140228, 22.331684],
							[114.140083, 22.331856],
							[114.140083, 22.332226],
							[114.140083, 22.333488],
							[114.138824, 22.333910],
							[114.137558, 22.333910],
							[114.135643, 22.334457],
							[114.135643, 22.335363],
							[114.135719, 22.336266],
							[114.135292, 22.337049],
							[114.135452, 22.337698],
							[114.131668, 22.338543],
							[114.130821, 22.340223],
							[114.132088, 22.340645],
							[114.133347, 22.340223],
							[114.134193, 22.341488],
							[114.135033, 22.342329],
							[114.136299, 22.342329],
							[114.137558, 22.341908],
							[114.139244, 22.341488],
							[114.140503, 22.340645],
							[114.141769, 22.341066],
							[114.143028, 22.341908],
							[114.145134, 22.340645],
							[114.146400, 22.341488],
							[114.147072, 22.342161],
							[114.147659, 22.342751],
							[114.146782, 22.343044],
							[114.146614, 22.343626],
							[114.146553, 22.344587],
							[114.147240, 22.345276],
							[114.148506, 22.345276],
							[114.149765, 22.345276],
							[114.150612, 22.346121],
							[114.151031, 22.347382],
							[114.152718, 22.346539],
							[114.154816, 22.347382],
							[114.157349, 22.346121],
							[114.158607, 22.345697],
							[114.160294, 22.347803],
							[114.161552, 22.347803],
							[114.162819, 22.349068],
							[114.164078, 22.348223],
							[114.165344, 22.346960],
							[114.166611, 22.346960],
							[114.167870, 22.346539],
							[114.169975, 22.347382],
							[114.171242, 22.346960],
							[114.172920, 22.346960]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Southern",
					"ID_0": 102,
					"ID_1": 11,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.184860, 22.217638],
								[114.184860, 22.217361],
								[114.185143, 22.217361],
								[114.185143, 22.217085],
								[114.185692, 22.217085],
								[114.185692, 22.216806],
								[114.186249, 22.216806],
								[114.186249, 22.216528],
								[114.186806, 22.216528],
								[114.186806, 22.216806],
								[114.187080, 22.216806],
								[114.187080, 22.216528],
								[114.187363, 22.216528],
								[114.187363, 22.216249],
								[114.187637, 22.216249],
								[114.187637, 22.215790],
								[114.187637, 22.215416],
								[114.187920, 22.215416],
								[114.187920, 22.214863],
								[114.188194, 22.214863],
								[114.188194, 22.214027],
								[114.187920, 22.214027],
								[114.187920, 22.213751],
								[114.187363, 22.213751],
								[114.187363, 22.214027],
								[114.186806, 22.214027],
								[114.186806, 22.213751],
								[114.186249, 22.213751],
								[114.186249, 22.213472],
								[114.185974, 22.213472],
								[114.185974, 22.213194],
								[114.184860, 22.213194],
								[114.184860, 22.213472],
								[114.184586, 22.213472],
								[114.184586, 22.213751],
								[114.184303, 22.213751],
								[114.184303, 22.214306],
								[114.184029, 22.214306],
								[114.184029, 22.214582],
								[114.183746, 22.214582],
								[114.183746, 22.215139],
								[114.183472, 22.215139],
								[114.183472, 22.215973],
								[114.183197, 22.215973],
								[114.183197, 22.217361],
								[114.183472, 22.217361],
								[114.183472, 22.217638],
								[114.184860, 22.217638]
							]
						],
						[
							[
								[114.259026, 22.226528],
								[114.259026, 22.226252],
								[114.259308, 22.226252],
								[114.259308, 22.225140],
								[114.259026, 22.225140],
								[114.259026, 22.224859],
								[114.258751, 22.224859],
								[114.258751, 22.224583],
								[114.258194, 22.224583],
								[114.258194, 22.224859],
								[114.256805, 22.224859],
								[114.256805, 22.225140],
								[114.256248, 22.225140],
								[114.256248, 22.225416],
								[114.255974, 22.225416],
								[114.255974, 22.225695],
								[114.255692, 22.225695],
								[114.255692, 22.225973],
								[114.255974, 22.225973],
								[114.255974, 22.226252],
								[114.256248, 22.226252],
								[114.256248, 22.226528],
								[114.257362, 22.226528],
								[114.257362, 22.226252],
								[114.258194, 22.226252],
								[114.258194, 22.226528],
								[114.259026, 22.226528]
							]
						],
						[
							[
								[114.187363, 22.236805],
								[114.187363, 22.236526],
								[114.187637, 22.236526],
								[114.187637, 22.236250],
								[114.188194, 22.236250],
								[114.188194, 22.235971],
								[114.188751, 22.235971],
								[114.188751, 22.235832],
								[114.188751, 22.235697],
								[114.189003, 22.235697],
								[114.189026, 22.235416],
								[114.189186, 22.235416],
								[114.189308, 22.235416],
								[114.189308, 22.235235],
								[114.189308, 22.235140],
								[114.189583, 22.235138],
								[114.189583, 22.234823],
								[114.189583, 22.234304],
								[114.189308, 22.234304],
								[114.189308, 22.234098],
								[114.189026, 22.234028],
								[114.189026, 22.233749],
								[114.188751, 22.233749],
								[114.188751, 22.233543],
								[114.188469, 22.233473],
								[114.188469, 22.233194],
								[114.188194, 22.233194],
								[114.188194, 22.232916],
								[114.187920, 22.232916],
								[114.187920, 22.232639],
								[114.187637, 22.232639],
								[114.187637, 22.232916],
								[114.186249, 22.232916],
								[114.186249, 22.232639],
								[114.185692, 22.232639],
								[114.185692, 22.232361],
								[114.185143, 22.232361],
								[114.185143, 22.231806],
								[114.183746, 22.231806],
								[114.183746, 22.232082],
								[114.183472, 22.232084],
								[114.183472, 22.232506],
								[114.183472, 22.232639],
								[114.183197, 22.232639],
								[114.183197, 22.232822],
								[114.183197, 22.232916],
								[114.182915, 22.232916],
								[114.182915, 22.233173],
								[114.182640, 22.233194],
								[114.182640, 22.233473],
								[114.182083, 22.233473],
								[114.182083, 22.233749],
								[114.181808, 22.233749],
								[114.181808, 22.234304],
								[114.181526, 22.234304],
								[114.181526, 22.234861],
								[114.181808, 22.234861],
								[114.181808, 22.235697],
								[114.182083, 22.235697],
								[114.182083, 22.235971],
								[114.183472, 22.235971],
								[114.183472, 22.235697],
								[114.183746, 22.235697],
								[114.183746, 22.235971],
								[114.184303, 22.235971],
								[114.184303, 22.236250],
								[114.184586, 22.236250],
								[114.184586, 22.236526],
								[114.185692, 22.236526],
								[114.185692, 22.236250],
								[114.186249, 22.236250],
								[114.186249, 22.236805],
								[114.187363, 22.236805]
							]
						],
						[
							[
								[114.157913, 22.245693],
								[114.157913, 22.245417],
								[114.158752, 22.245417],
								[114.158752, 22.244583],
								[114.159027, 22.244583],
								[114.159027, 22.243195],
								[114.159302, 22.243195],
								[114.159309, 22.242916],
								[114.159584, 22.242916],
								[114.159584, 22.242640],
								[114.159859, 22.242640],
								[114.159859, 22.242083],
								[114.160141, 22.242083],
								[114.160141, 22.241806],
								[114.160416, 22.241806],
								[114.160416, 22.241528],
								[114.160698, 22.241528],
								[114.160698, 22.241249],
								[114.160973, 22.241249],
								[114.160973, 22.240973],
								[114.161247, 22.240973],
								[114.161247, 22.240417],
								[114.161530, 22.240417],
								[114.161530, 22.240141],
								[114.161804, 22.240141],
								[114.161804, 22.237640],
								[114.161530, 22.237640],
								[114.161530, 22.236805],
								[114.161247, 22.236805],
								[114.161247, 22.236250],
								[114.160973, 22.236250],
								[114.160973, 22.235697],
								[114.160416, 22.235697],
								[114.160416, 22.234861],
								[114.160141, 22.234861],
								[114.160141, 22.233749],
								[114.160416, 22.233749],
								[114.160416, 22.233473],
								[114.160698, 22.233473],
								[114.160698, 22.233194],
								[114.161247, 22.233194],
								[114.161247, 22.232916],
								[114.161804, 22.232916],
								[114.161804, 22.231806],
								[114.162086, 22.231806],
								[114.162086, 22.230232],
								[114.162086, 22.230139],
								[114.162361, 22.230139],
								[114.162361, 22.229959],
								[114.162361, 22.229860],
								[114.162636, 22.229860],
								[114.162636, 22.229029],
								[114.162361, 22.229029],
								[114.162361, 22.228472],
								[114.161247, 22.228472],
								[114.161247, 22.228750],
								[114.160973, 22.228750],
								[114.160973, 22.229029],
								[114.160698, 22.229029],
								[114.160698, 22.229305],
								[114.160416, 22.229305],
								[114.160416, 22.229584],
								[114.160141, 22.229584],
								[114.160141, 22.229860],
								[114.159859, 22.229860],
								[114.159859, 22.230139],
								[114.159584, 22.230139],
								[114.159584, 22.230694],
								[114.159302, 22.230694],
								[114.159302, 22.231251],
								[114.159027, 22.231251],
								[114.159027, 22.231806],
								[114.158752, 22.231806],
								[114.158752, 22.233194],
								[114.158470, 22.233194],
								[114.158470, 22.234304],
								[114.157913, 22.234304],
								[114.157913, 22.234583],
								[114.157364, 22.234583],
								[114.157364, 22.234304],
								[114.156250, 22.234304],
								[114.156250, 22.234583],
								[114.155693, 22.234583],
								[114.155693, 22.234861],
								[114.155418, 22.234861],
								[114.155418, 22.235138],
								[114.154861, 22.235138],
								[114.154861, 22.235416],
								[114.153748, 22.235416],
								[114.153748, 22.236250],
								[114.153473, 22.236250],
								[114.153473, 22.236805],
								[114.153198, 22.236805],
								[114.153191, 22.237083],
								[114.152916, 22.237083],
								[114.152916, 22.237362],
								[114.152641, 22.237362],
								[114.152641, 22.237993],
								[114.152641, 22.238470],
								[114.152084, 22.238470],
								[114.152084, 22.238194],
								[114.151253, 22.238194],
								[114.151253, 22.237919],
								[114.150696, 22.237919],
								[114.150696, 22.237640],
								[114.149864, 22.237640],
								[114.149864, 22.238750],
								[114.147919, 22.238750],
								[114.147919, 22.239305],
								[114.147636, 22.239305],
								[114.147636, 22.239584],
								[114.147362, 22.239584],
								[114.147362, 22.240694],
								[114.147087, 22.240694],
								[114.147087, 22.241806],
								[114.146805, 22.241806],
								[114.146805, 22.242361],
								[114.146248, 22.242361],
								[114.146248, 22.242640],
								[114.145973, 22.242640],
								[114.145973, 22.242916],
								[114.145691, 22.242916],
								[114.145691, 22.243195],
								[114.145416, 22.243195],
								[114.145416, 22.243814],
								[114.145416, 22.244028],
								[114.146248, 22.244028],
								[114.146248, 22.244305],
								[114.146530, 22.244305],
								[114.146530, 22.244583],
								[114.146805, 22.244583],
								[114.146805, 22.244862],
								[114.147362, 22.244862],
								[114.147362, 22.245138],
								[114.150970, 22.245138],
								[114.150970, 22.244862],
								[114.151527, 22.244862],
								[114.151527, 22.244583],
								[114.153191, 22.244583],
								[114.153198, 22.244862],
								[114.153748, 22.244862],
								[114.153748, 22.245138],
								[114.156525, 22.245138],
								[114.156525, 22.245417],
								[114.157082, 22.245417],
								[114.157082, 22.245693],
								[114.157913, 22.245693]
							]
						],
						[
							[
								[114.134743, 22.272917],
								[114.135452, 22.272444],
								[114.137138, 22.270760],
								[114.137367, 22.270073],
								[114.137482, 22.269733],
								[114.137558, 22.269497],
								[114.137688, 22.269299],
								[114.138443, 22.268145],
								[114.139244, 22.266550],
								[114.140503, 22.265707],
								[114.140587, 22.265459],
								[114.140701, 22.265108],
								[114.140930, 22.264444],
								[114.141129, 22.264147],
								[114.141769, 22.263182],
								[114.142097, 22.262684],
								[114.142609, 22.261919],
								[114.142326, 22.261076],
								[114.142189, 22.260654],
								[114.143028, 22.259813],
								[114.144295, 22.258970],
								[114.145134, 22.258129],
								[114.145134, 22.256866],
								[114.144897, 22.256144],
								[114.144753, 22.255718],
								[114.144714, 22.255604],
								[114.145981, 22.254761],
								[114.148926, 22.256023],
								[114.151451, 22.257708],
								[114.151871, 22.258972],
								[114.152084, 22.259604],
								[114.152290, 22.260235],
								[114.153137, 22.261076],
								[114.154816, 22.261076],
								[114.155441, 22.260578],
								[114.156921, 22.259392],
								[114.157402, 22.259272],
								[114.158073, 22.259108],
								[114.158607, 22.258970],
								[114.160713, 22.259813],
								[114.160820, 22.259777],
								[114.161285, 22.259623],
								[114.161980, 22.259392],
								[114.163307, 22.260391],
								[114.163689, 22.260689],
								[114.164398, 22.259987],
								[114.164536, 22.259848],
								[114.165794, 22.259426],
								[114.167061, 22.259005],
								[114.169167, 22.258163],
								[114.170425, 22.257322],
								[114.174217, 22.256479],
								[114.175903, 22.256058],
								[114.177162, 22.256479],
								[114.177582, 22.257742],
								[114.179268, 22.257742],
								[114.180954, 22.256058],
								[114.184319, 22.255638],
								[114.186005, 22.255217],
								[114.189796, 22.256479],
								[114.192741, 22.257322],
								[114.195686, 22.257742],
								[114.196953, 22.258163],
								[114.197289, 22.258274],
								[114.198212, 22.258585],
								[114.198250, 22.258787],
								[114.198792, 22.258600],
								[114.198936, 22.258551],
								[114.199341, 22.258411],
								[114.199448, 22.258551],
								[114.200577, 22.258551],
								[114.200706, 22.258551],
								[114.202393, 22.258970],
								[114.204079, 22.259813],
								[114.206184, 22.258970],
								[114.208290, 22.259813],
								[114.209969, 22.260654],
								[114.212074, 22.261076],
								[114.213341, 22.261076],
								[114.214600, 22.261076],
								[114.215446, 22.259813],
								[114.216286, 22.258970],
								[114.217552, 22.258551],
								[114.219231, 22.258129],
								[114.220497, 22.257708],
								[114.221756, 22.257708],
								[114.223022, 22.257708],
								[114.224281, 22.257708],
								[114.227234, 22.257708],
								[114.228493, 22.257288],
								[114.230179, 22.256023],
								[114.231018, 22.254761],
								[114.233124, 22.254761],
								[114.234810, 22.255604],
								[114.236496, 22.256866],
								[114.238174, 22.256866],
								[114.239861, 22.256445],
								[114.241547, 22.256023],
								[114.244072, 22.253920],
								[114.247017, 22.253498],
								[114.247437, 22.252235],
								[114.248283, 22.250973],
								[114.248703, 22.249710],
								[114.249603, 22.248459],
								[114.250465, 22.247114],
								[114.250420, 22.246529],
								[114.250137, 22.246529],
								[114.250137, 22.246250],
								[114.249580, 22.246250],
								[114.249580, 22.245972],
								[114.248474, 22.245972],
								[114.248474, 22.246250],
								[114.247917, 22.246250],
								[114.247917, 22.246529],
								[114.247086, 22.246529],
								[114.247086, 22.245972],
								[114.246803, 22.245972],
								[114.246803, 22.245693],
								[114.247086, 22.245693],
								[114.247086, 22.244583],
								[114.247360, 22.244583],
								[114.247360, 22.244305],
								[114.247643, 22.244305],
								[114.247643, 22.244028],
								[114.247917, 22.244028],
								[114.247917, 22.243750],
								[114.248192, 22.243750],
								[114.248192, 22.243195],
								[114.248474, 22.243195],
								[114.248474, 22.242916],
								[114.248749, 22.242916],
								[114.248749, 22.242640],
								[114.249031, 22.242640],
								[114.249031, 22.242361],
								[114.249580, 22.242361],
								[114.249580, 22.242083],
								[114.250137, 22.242083],
								[114.250137, 22.241806],
								[114.250969, 22.241806],
								[114.250969, 22.241528],
								[114.251251, 22.241528],
								[114.251251, 22.240417],
								[114.251526, 22.240417],
								[114.251526, 22.239859],
								[114.251251, 22.239859],
								[114.251251, 22.239584],
								[114.250969, 22.239584],
								[114.250969, 22.239029],
								[114.251251, 22.239029],
								[114.251251, 22.238470],
								[114.251495, 22.238470],
								[114.251526, 22.238195],
								[114.251808, 22.238194],
								[114.251808, 22.237640],
								[114.252083, 22.237640],
								[114.252083, 22.237362],
								[114.252357, 22.237362],
								[114.252357, 22.237083],
								[114.252914, 22.237083],
								[114.252914, 22.236805],
								[114.253197, 22.236805],
								[114.253197, 22.236250],
								[114.253746, 22.236250],
								[114.253754, 22.235971],
								[114.254028, 22.235971],
								[114.254028, 22.235138],
								[114.253754, 22.235138],
								[114.253754, 22.234583],
								[114.253471, 22.234583],
								[114.253471, 22.234304],
								[114.253197, 22.234304],
								[114.253197, 22.233749],
								[114.252914, 22.233749],
								[114.252914, 22.232916],
								[114.252357, 22.232916],
								[114.252357, 22.232639],
								[114.252083, 22.232639],
								[114.252083, 22.231527],
								[114.252602, 22.231527],
								[114.252640, 22.231251],
								[114.254028, 22.231251],
								[114.254028, 22.230972],
								[114.254303, 22.230972],
								[114.254303, 22.230694],
								[114.256531, 22.230694],
								[114.256531, 22.230972],
								[114.259026, 22.230972],
								[114.259026, 22.231251],
								[114.259583, 22.231251],
								[114.259583, 22.231527],
								[114.260414, 22.231527],
								[114.260414, 22.230972],
								[114.260696, 22.230972],
								[114.260696, 22.230139],
								[114.260414, 22.230139],
								[114.260414, 22.229584],
								[114.260139, 22.229584],
								[114.260139, 22.229305],
								[114.259865, 22.229305],
								[114.259865, 22.229029],
								[114.259308, 22.229029],
								[114.259308, 22.228750],
								[114.258469, 22.228750],
								[114.258469, 22.229029],
								[114.257919, 22.229029],
								[114.257919, 22.229305],
								[114.256531, 22.229305],
								[114.256531, 22.228750],
								[114.256248, 22.228750],
								[114.256248, 22.228195],
								[114.253471, 22.228195],
								[114.253471, 22.228472],
								[114.252914, 22.228472],
								[114.252914, 22.228750],
								[114.252640, 22.228750],
								[114.252640, 22.229029],
								[114.251526, 22.229029],
								[114.251526, 22.228750],
								[114.251251, 22.228750],
								[114.251251, 22.228472],
								[114.250969, 22.228472],
								[114.250969, 22.228203],
								[114.250694, 22.228195],
								[114.250694, 22.227917],
								[114.250420, 22.227917],
								[114.250420, 22.227638],
								[114.250694, 22.227638],
								[114.250694, 22.227362],
								[114.250969, 22.227362],
								[114.250969, 22.227083],
								[114.251251, 22.227083],
								[114.251251, 22.226805],
								[114.251526, 22.226805],
								[114.251526, 22.225416],
								[114.251808, 22.225416],
								[114.251808, 22.225140],
								[114.252083, 22.225140],
								[114.252083, 22.224859],
								[114.252640, 22.224859],
								[114.252640, 22.224583],
								[114.252914, 22.224583],
								[114.252914, 22.224306],
								[114.253197, 22.224306],
								[114.253197, 22.223747],
								[114.253471, 22.223747],
								[114.253471, 22.223473],
								[114.253754, 22.223473],
								[114.253754, 22.223194],
								[114.254028, 22.223194],
								[114.254028, 22.222637],
								[114.254303, 22.222637],
								[114.254303, 22.222086],
								[114.254585, 22.222086],
								[114.254585, 22.220972],
								[114.254860, 22.220972],
								[114.254860, 22.220694],
								[114.255142, 22.220694],
								[114.255142, 22.218750],
								[114.255417, 22.218750],
								[114.255417, 22.216249],
								[114.255692, 22.216249],
								[114.255692, 22.215139],
								[114.255417, 22.215139],
								[114.255417, 22.213751],
								[114.255692, 22.213751],
								[114.255692, 22.212084],
								[114.255417, 22.212084],
								[114.255417, 22.211805],
								[114.255142, 22.211805],
								[114.255142, 22.210699],
								[114.255974, 22.210695],
								[114.255974, 22.210972],
								[114.257362, 22.210972],
								[114.257362, 22.211250],
								[114.259026, 22.211250],
								[114.259026, 22.210972],
								[114.259308, 22.210972],
								[114.259308, 22.209862],
								[114.259583, 22.209862],
								[114.259583, 22.209583],
								[114.260414, 22.209583],
								[114.260414, 22.209305],
								[114.260696, 22.209305],
								[114.260696, 22.209026],
								[114.261253, 22.209026],
								[114.261253, 22.208750],
								[114.261528, 22.208750],
								[114.261528, 22.207640],
								[114.261253, 22.207640],
								[114.261253, 22.207361],
								[114.260971, 22.207361],
								[114.260971, 22.206804],
								[114.260696, 22.206804],
								[114.260696, 22.205694],
								[114.260941, 22.205694],
								[114.260971, 22.204027],
								[114.260696, 22.204027],
								[114.260696, 22.203751],
								[114.259865, 22.203751],
								[114.259865, 22.204027],
								[114.259583, 22.204027],
								[114.259583, 22.204306],
								[114.259331, 22.204306],
								[114.259308, 22.204582],
								[114.258751, 22.204582],
								[114.258751, 22.204861],
								[114.258469, 22.204861],
								[114.258469, 22.205973],
								[114.259026, 22.205973],
								[114.259026, 22.206249],
								[114.259583, 22.206249],
								[114.259583, 22.206804],
								[114.259865, 22.206804],
								[114.259865, 22.207361],
								[114.257919, 22.207361],
								[114.257919, 22.207083],
								[114.257080, 22.207083],
								[114.257080, 22.207361],
								[114.256805, 22.207361],
								[114.256805, 22.207640],
								[114.256538, 22.207640],
								[114.256531, 22.207914],
								[114.255974, 22.207914],
								[114.255974, 22.207640],
								[114.255692, 22.207640],
								[114.255692, 22.206804],
								[114.255417, 22.206804],
								[114.255417, 22.206251],
								[114.255142, 22.206249],
								[114.255142, 22.205973],
								[114.254585, 22.205973],
								[114.254585, 22.206249],
								[114.253754, 22.206249],
								[114.253754, 22.205973],
								[114.253471, 22.205973],
								[114.253471, 22.206528],
								[114.253197, 22.206528],
								[114.253197, 22.206804],
								[114.252914, 22.206804],
								[114.252914, 22.207083],
								[114.252357, 22.207083],
								[114.252357, 22.207361],
								[114.251526, 22.207361],
								[114.251526, 22.207083],
								[114.250694, 22.207083],
								[114.250694, 22.206804],
								[114.249031, 22.206804],
								[114.249031, 22.206528],
								[114.247917, 22.206528],
								[114.247917, 22.206251],
								[114.247643, 22.206249],
								[114.247643, 22.206528],
								[114.246803, 22.206528],
								[114.246803, 22.206249],
								[114.246529, 22.206249],
								[114.246529, 22.206528],
								[114.245972, 22.206528],
								[114.245972, 22.206804],
								[114.244858, 22.206804],
								[114.244858, 22.207083],
								[114.244026, 22.207083],
								[114.244026, 22.207361],
								[114.243752, 22.207361],
								[114.243752, 22.207640],
								[114.242638, 22.207640],
								[114.242638, 22.207914],
								[114.242363, 22.207914],
								[114.242363, 22.208195],
								[114.242081, 22.208195],
								[114.242081, 22.208750],
								[114.241806, 22.208750],
								[114.241806, 22.209026],
								[114.241531, 22.209026],
								[114.241531, 22.209583],
								[114.241249, 22.209583],
								[114.241249, 22.210972],
								[114.241531, 22.210972],
								[114.241531, 22.211250],
								[114.241806, 22.211250],
								[114.241806, 22.211529],
								[114.242081, 22.211529],
								[114.242081, 22.211805],
								[114.242638, 22.211805],
								[114.242638, 22.212641],
								[114.242920, 22.212641],
								[114.242920, 22.213194],
								[114.242638, 22.213194],
								[114.242638, 22.214306],
								[114.242363, 22.214306],
								[114.242363, 22.214863],
								[114.242081, 22.214863],
								[114.242081, 22.215694],
								[114.241806, 22.215694],
								[114.241806, 22.216806],
								[114.241570, 22.216806],
								[114.241531, 22.217085],
								[114.241806, 22.217085],
								[114.241806, 22.217638],
								[114.241249, 22.217638],
								[114.241249, 22.218193],
								[114.239586, 22.218193],
								[114.239586, 22.217916],
								[114.238747, 22.217916],
								[114.238747, 22.218193],
								[114.238472, 22.218193],
								[114.238472, 22.218472],
								[114.238197, 22.218472],
								[114.238197, 22.218750],
								[114.237915, 22.218750],
								[114.237915, 22.219028],
								[114.237640, 22.219028],
								[114.237640, 22.219584],
								[114.237083, 22.219584],
								[114.237083, 22.219862],
								[114.237358, 22.219862],
								[114.237358, 22.220415],
								[114.237083, 22.220415],
								[114.237083, 22.220694],
								[114.236809, 22.220694],
								[114.236740, 22.220972],
								[114.236252, 22.220972],
								[114.236252, 22.221251],
								[114.235970, 22.221251],
								[114.235970, 22.221525],
								[114.235695, 22.221525],
								[114.235695, 22.221806],
								[114.235420, 22.221806],
								[114.235420, 22.222361],
								[114.235138, 22.222361],
								[114.235138, 22.223473],
								[114.235420, 22.223473],
								[114.235420, 22.224030],
								[114.235672, 22.224030],
								[114.235695, 22.224583],
								[114.235970, 22.224583],
								[114.235970, 22.225140],
								[114.236252, 22.225140],
								[114.236252, 22.225695],
								[114.236526, 22.225695],
								[114.236526, 22.226528],
								[114.235970, 22.226528],
								[114.235970, 22.227083],
								[114.235695, 22.227083],
								[114.235695, 22.227638],
								[114.235420, 22.227638],
								[114.235420, 22.227917],
								[114.235695, 22.227917],
								[114.235695, 22.230694],
								[114.235420, 22.230694],
								[114.235420, 22.232082],
								[114.235138, 22.232084],
								[114.235138, 22.232916],
								[114.234863, 22.232916],
								[114.234863, 22.233749],
								[114.234581, 22.233749],
								[114.234581, 22.234583],
								[114.234306, 22.234583],
								[114.234306, 22.234861],
								[114.234024, 22.234861],
								[114.234024, 22.235138],
								[114.233749, 22.235138],
								[114.233749, 22.236250],
								[114.234306, 22.236250],
								[114.234306, 22.236805],
								[114.234581, 22.236805],
								[114.234581, 22.238470],
								[114.234306, 22.238470],
								[114.234306, 22.239029],
								[114.234024, 22.239029],
								[114.234024, 22.239305],
								[114.233192, 22.239305],
								[114.233192, 22.239584],
								[114.232918, 22.239584],
								[114.232918, 22.239859],
								[114.232643, 22.239859],
								[114.232635, 22.240141],
								[114.232086, 22.240141],
								[114.232086, 22.240417],
								[114.231529, 22.240417],
								[114.231529, 22.240694],
								[114.231247, 22.240694],
								[114.231247, 22.240973],
								[114.230972, 22.240973],
								[114.230972, 22.241249],
								[114.227364, 22.241249],
								[114.227364, 22.240973],
								[114.226807, 22.240973],
								[114.226807, 22.240694],
								[114.226524, 22.240694],
								[114.226524, 22.240417],
								[114.226250, 22.240417],
								[114.226250, 22.240694],
								[114.225983, 22.240694],
								[114.225975, 22.241249],
								[114.225693, 22.241249],
								[114.225693, 22.242361],
								[114.225418, 22.242361],
								[114.225418, 22.244583],
								[114.225136, 22.244583],
								[114.225136, 22.245417],
								[114.224861, 22.245417],
								[114.224861, 22.245693],
								[114.224586, 22.245693],
								[114.224586, 22.245972],
								[114.224030, 22.245972],
								[114.224030, 22.246250],
								[114.223473, 22.246250],
								[114.223473, 22.244305],
								[114.223198, 22.244305],
								[114.223198, 22.242640],
								[114.223473, 22.242640],
								[114.223473, 22.240973],
								[114.225136, 22.240973],
								[114.225136, 22.240694],
								[114.225418, 22.240694],
								[114.225418, 22.240417],
								[114.225975, 22.240417],
								[114.225975, 22.240141],
								[114.226250, 22.240141],
								[114.226250, 22.239584],
								[114.226807, 22.239584],
								[114.226807, 22.239305],
								[114.227081, 22.239305],
								[114.227081, 22.239029],
								[114.227356, 22.239029],
								[114.227364, 22.238750],
								[114.227638, 22.238750],
								[114.227638, 22.238470],
								[114.228127, 22.238470],
								[114.228195, 22.238195],
								[114.228752, 22.238194],
								[114.228752, 22.237919],
								[114.229027, 22.237919],
								[114.229027, 22.235971],
								[114.229309, 22.235971],
								[114.229309, 22.234861],
								[114.229584, 22.234861],
								[114.229584, 22.234583],
								[114.229858, 22.234583],
								[114.229858, 22.232361],
								[114.230141, 22.232361],
								[114.230141, 22.231527],
								[114.229858, 22.231527],
								[114.229858, 22.230972],
								[114.229584, 22.230972],
								[114.229584, 22.230694],
								[114.229858, 22.230694],
								[114.229858, 22.230139],
								[114.229584, 22.230139],
								[114.229584, 22.229584],
								[114.227081, 22.229584],
								[114.227081, 22.229305],
								[114.226807, 22.229305],
								[114.226807, 22.229029],
								[114.226303, 22.229029],
								[114.226250, 22.229584],
								[114.225975, 22.229584],
								[114.225975, 22.229860],
								[114.225693, 22.229860],
								[114.225693, 22.230417],
								[114.225136, 22.230417],
								[114.225136, 22.230694],
								[114.224861, 22.230694],
								[114.224861, 22.231251],
								[114.224586, 22.231251],
								[114.224586, 22.231806],
								[114.224304, 22.231806],
								[114.224304, 22.232082],
								[114.224030, 22.232084],
								[114.224030, 22.232361],
								[114.223473, 22.232361],
								[114.223404, 22.232084],
								[114.223198, 22.232082],
								[114.223198, 22.231806],
								[114.222916, 22.231806],
								[114.222916, 22.231251],
								[114.222641, 22.231251],
								[114.222641, 22.230972],
								[114.222359, 22.230972],
								[114.222359, 22.230694],
								[114.222084, 22.230694],
								[114.222084, 22.230417],
								[114.221809, 22.230417],
								[114.221802, 22.229860],
								[114.221527, 22.229860],
								[114.221527, 22.229305],
								[114.220970, 22.229305],
								[114.220970, 22.229031],
								[114.220413, 22.229029],
								[114.220413, 22.228750],
								[114.218193, 22.228750],
								[114.218193, 22.228195],
								[114.218475, 22.228195],
								[114.218475, 22.227083],
								[114.218193, 22.227083],
								[114.218193, 22.226252],
								[114.217918, 22.226252],
								[114.217918, 22.225695],
								[114.217636, 22.225695],
								[114.217636, 22.225416],
								[114.217087, 22.225416],
								[114.217087, 22.225140],
								[114.216805, 22.225140],
								[114.216805, 22.224859],
								[114.216530, 22.224859],
								[114.216530, 22.224583],
								[114.215973, 22.224583],
								[114.215973, 22.224306],
								[114.215698, 22.224306],
								[114.215698, 22.223747],
								[114.215416, 22.223747],
								[114.215416, 22.222637],
								[114.215141, 22.222637],
								[114.215141, 22.222086],
								[114.214859, 22.222086],
								[114.214859, 22.219584],
								[114.215141, 22.219584],
								[114.215141, 22.219303],
								[114.215698, 22.219303],
								[114.215698, 22.219028],
								[114.216248, 22.219028],
								[114.216248, 22.218750],
								[114.217087, 22.218750],
								[114.217087, 22.218193],
								[114.217346, 22.218193],
								[114.217361, 22.217916],
								[114.217636, 22.217916],
								[114.217636, 22.217638],
								[114.218193, 22.217638],
								[114.218193, 22.217361],
								[114.218475, 22.217361],
								[114.218475, 22.217085],
								[114.219025, 22.217085],
								[114.219025, 22.216806],
								[114.220413, 22.216806],
								[114.220413, 22.216539],
								[114.220695, 22.216528],
								[114.220695, 22.216249],
								[114.220970, 22.216249],
								[114.220970, 22.215973],
								[114.221222, 22.215973],
								[114.221252, 22.215694],
								[114.221527, 22.215694],
								[114.221527, 22.215416],
								[114.222076, 22.215416],
								[114.222084, 22.215139],
								[114.222641, 22.215139],
								[114.222641, 22.214863],
								[114.222916, 22.214863],
								[114.222916, 22.213751],
								[114.223198, 22.213751],
								[114.223198, 22.213194],
								[114.222916, 22.213194],
								[114.222916, 22.212358],
								[114.222641, 22.212358],
								[114.222641, 22.211809],
								[114.222359, 22.211805],
								[114.222359, 22.212084],
								[114.221252, 22.212084],
								[114.221252, 22.211805],
								[114.220970, 22.211805],
								[114.220970, 22.211250],
								[114.220695, 22.211250],
								[114.220695, 22.210419],
								[114.220413, 22.210419],
								[114.220413, 22.209862],
								[114.220139, 22.209862],
								[114.220139, 22.209583],
								[114.219025, 22.209583],
								[114.219025, 22.209305],
								[114.218750, 22.209305],
								[114.218750, 22.208471],
								[114.219025, 22.208471],
								[114.219025, 22.208195],
								[114.219307, 22.208195],
								[114.219307, 22.207640],
								[114.219582, 22.207640],
								[114.219582, 22.207361],
								[114.219864, 22.207361],
								[114.219864, 22.207083],
								[114.220139, 22.207083],
								[114.220139, 22.206528],
								[114.220413, 22.206528],
								[114.220413, 22.205141],
								[114.220695, 22.205139],
								[114.220695, 22.204582],
								[114.220970, 22.204582],
								[114.220970, 22.204306],
								[114.221252, 22.204306],
								[114.221252, 22.204027],
								[114.221527, 22.204027],
								[114.221527, 22.203751],
								[114.221802, 22.203751],
								[114.221809, 22.203194],
								[114.222084, 22.203194],
								[114.222084, 22.202084],
								[114.222359, 22.202084],
								[114.222359, 22.201805],
								[114.222641, 22.201805],
								[114.222641, 22.200972],
								[114.223198, 22.200972],
								[114.223198, 22.200695],
								[114.223473, 22.200695],
								[114.223473, 22.200138],
								[114.223747, 22.200138],
								[114.223747, 22.199583],
								[114.223999, 22.199583],
								[114.224030, 22.199030],
								[114.224297, 22.199030],
								[114.224304, 22.198748],
								[114.224586, 22.198748],
								[114.224586, 22.197359],
								[114.224304, 22.197359],
								[114.224304, 22.196808],
								[114.224030, 22.196808],
								[114.224030, 22.195694],
								[114.223198, 22.195694],
								[114.223198, 22.195415],
								[114.222359, 22.195415],
								[114.222359, 22.195139],
								[114.221809, 22.195139],
								[114.221802, 22.194860],
								[114.219582, 22.194860],
								[114.219582, 22.194584],
								[114.218491, 22.194584],
								[114.218475, 22.194860],
								[114.217918, 22.194860],
								[114.217918, 22.195139],
								[114.217361, 22.195139],
								[114.217361, 22.194860],
								[114.217087, 22.194860],
								[114.217087, 22.194584],
								[114.216248, 22.194584],
								[114.216248, 22.194305],
								[114.214584, 22.194305],
								[114.214584, 22.194027],
								[114.213470, 22.194027],
								[114.213470, 22.194584],
								[114.213196, 22.194584],
								[114.213196, 22.194860],
								[114.212914, 22.194860],
								[114.212914, 22.195139],
								[114.212639, 22.195139],
								[114.212639, 22.195415],
								[114.212082, 22.195415],
								[114.212082, 22.195694],
								[114.211807, 22.195694],
								[114.211807, 22.195972],
								[114.211525, 22.195972],
								[114.211525, 22.196808],
								[114.211250, 22.196808],
								[114.211250, 22.198748],
								[114.210976, 22.198748],
								[114.210976, 22.200417],
								[114.211250, 22.200417],
								[114.211250, 22.200972],
								[114.211525, 22.200972],
								[114.211525, 22.202084],
								[114.211807, 22.202084],
								[114.211807, 22.203751],
								[114.211525, 22.203751],
								[114.211525, 22.205139],
								[114.211807, 22.205139],
								[114.211807, 22.205973],
								[114.211525, 22.205973],
								[114.211525, 22.207083],
								[114.211250, 22.207083],
								[114.211250, 22.208750],
								[114.211525, 22.208750],
								[114.211525, 22.209583],
								[114.211807, 22.209583],
								[114.211807, 22.209862],
								[114.212082, 22.209862],
								[114.212082, 22.210136],
								[114.212639, 22.210136],
								[114.212639, 22.210695],
								[114.212914, 22.210695],
								[114.212914, 22.210972],
								[114.213196, 22.210972],
								[114.213196, 22.211250],
								[114.213470, 22.211250],
								[114.213470, 22.211529],
								[114.213753, 22.211529],
								[114.213753, 22.211805],
								[114.214256, 22.211805],
								[114.214302, 22.213194],
								[114.214584, 22.213194],
								[114.214584, 22.214027],
								[114.214302, 22.214027],
								[114.214302, 22.214863],
								[114.214027, 22.214863],
								[114.214027, 22.215139],
								[114.213753, 22.215139],
								[114.213753, 22.215416],
								[114.213470, 22.215416],
								[114.213470, 22.215694],
								[114.213196, 22.215694],
								[114.213196, 22.216249],
								[114.212914, 22.216249],
								[114.212914, 22.216528],
								[114.212639, 22.216528],
								[114.212639, 22.217085],
								[114.211807, 22.217085],
								[114.211807, 22.217916],
								[114.210693, 22.217916],
								[114.210693, 22.217638],
								[114.210419, 22.217638],
								[114.210419, 22.217361],
								[114.210136, 22.217361],
								[114.210136, 22.217085],
								[114.209862, 22.217085],
								[114.209862, 22.216806],
								[114.209618, 22.216806],
								[114.208191, 22.216806],
								[114.208191, 22.216528],
								[114.207916, 22.216528],
								[114.207916, 22.216249],
								[114.207642, 22.216249],
								[114.207642, 22.215973],
								[114.207359, 22.215973],
								[114.207359, 22.215694],
								[114.207085, 22.215694],
								[114.207085, 22.215139],
								[114.207359, 22.215139],
								[114.207359, 22.214863],
								[114.207642, 22.214863],
								[114.207642, 22.214582],
								[114.207359, 22.214582],
								[114.207359, 22.214027],
								[114.207085, 22.214027],
								[114.207085, 22.213472],
								[114.206528, 22.213472],
								[114.206528, 22.213194],
								[114.206253, 22.213194],
								[114.206253, 22.212917],
								[114.205971, 22.212917],
								[114.205971, 22.212358],
								[114.205414, 22.212358],
								[114.205414, 22.212084],
								[114.204865, 22.212084],
								[114.204865, 22.212358],
								[114.204033, 22.212358],
								[114.204025, 22.212641],
								[114.201805, 22.212641],
								[114.201805, 22.212358],
								[114.201530, 22.212358],
								[114.201530, 22.211805],
								[114.200974, 22.211805],
								[114.200974, 22.211250],
								[114.200691, 22.211250],
								[114.200691, 22.210695],
								[114.200417, 22.210695],
								[114.200417, 22.210419],
								[114.199860, 22.210419],
								[114.199860, 22.210136],
								[114.199303, 22.210136],
								[114.199303, 22.209862],
								[114.197639, 22.209862],
								[114.197639, 22.210136],
								[114.197083, 22.210136],
								[114.197083, 22.210695],
								[114.196808, 22.210695],
								[114.196808, 22.211529],
								[114.196526, 22.211529],
								[114.196526, 22.212358],
								[114.196808, 22.212358],
								[114.196808, 22.214306],
								[114.197083, 22.214306],
								[114.197083, 22.214863],
								[114.197365, 22.214863],
								[114.197365, 22.215416],
								[114.197639, 22.215416],
								[114.197639, 22.215694],
								[114.197914, 22.215694],
								[114.197914, 22.215139],
								[114.198196, 22.215139],
								[114.198196, 22.215416],
								[114.198471, 22.215416],
								[114.198471, 22.215694],
								[114.198753, 22.215694],
								[114.198753, 22.216249],
								[114.199303, 22.216249],
								[114.199303, 22.216528],
								[114.199585, 22.216528],
								[114.199585, 22.216806],
								[114.200974, 22.216806],
								[114.200974, 22.216528],
								[114.201248, 22.216528],
								[114.201248, 22.216806],
								[114.201530, 22.216806],
								[114.201530, 22.217085],
								[114.201805, 22.217085],
								[114.201805, 22.218472],
								[114.201530, 22.218472],
								[114.201530, 22.219028],
								[114.201248, 22.219028],
								[114.201248, 22.219862],
								[114.200974, 22.219862],
								[114.200974, 22.220415],
								[114.200691, 22.220415],
								[114.200691, 22.220694],
								[114.200417, 22.220694],
								[114.200417, 22.220972],
								[114.200119, 22.220972],
								[114.198196, 22.220972],
								[114.198196, 22.221251],
								[114.197083, 22.221251],
								[114.197083, 22.221525],
								[114.196526, 22.221525],
								[114.196526, 22.221806],
								[114.196251, 22.221806],
								[114.196251, 22.222086],
								[114.195694, 22.222086],
								[114.195694, 22.222361],
								[114.195137, 22.222361],
								[114.195137, 22.222637],
								[114.194862, 22.222637],
								[114.194862, 22.222919],
								[114.194580, 22.222919],
								[114.194580, 22.223194],
								[114.194305, 22.223194],
								[114.194305, 22.223473],
								[114.194031, 22.223473],
								[114.194031, 22.223747],
								[114.193474, 22.223747],
								[114.193474, 22.224859],
								[114.194031, 22.224859],
								[114.194031, 22.224583],
								[114.194305, 22.224583],
								[114.194305, 22.224306],
								[114.194862, 22.224306],
								[114.194862, 22.224030],
								[114.195969, 22.224030],
								[114.195969, 22.223747],
								[114.197083, 22.223747],
								[114.197083, 22.224030],
								[114.197342, 22.224030],
								[114.197365, 22.224583],
								[114.197639, 22.224583],
								[114.197639, 22.225973],
								[114.197365, 22.225973],
								[114.197365, 22.226252],
								[114.197083, 22.226252],
								[114.197083, 22.226805],
								[114.196808, 22.226805],
								[114.196808, 22.227083],
								[114.196533, 22.227083],
								[114.196526, 22.227638],
								[114.196251, 22.227638],
								[114.196251, 22.229029],
								[114.196526, 22.229029],
								[114.196526, 22.229305],
								[114.196808, 22.229305],
								[114.196808, 22.229584],
								[114.197357, 22.229584],
								[114.197365, 22.229860],
								[114.197914, 22.229860],
								[114.197914, 22.233749],
								[114.197639, 22.233749],
								[114.197639, 22.234304],
								[114.197365, 22.234304],
								[114.197365, 22.234861],
								[114.197083, 22.234861],
								[114.197083, 22.235971],
								[114.196808, 22.235971],
								[114.196808, 22.236250],
								[114.196526, 22.236250],
								[114.196526, 22.236526],
								[114.196251, 22.236526],
								[114.196251, 22.236805],
								[114.195969, 22.236805],
								[114.195969, 22.237083],
								[114.195694, 22.237083],
								[114.195694, 22.237362],
								[114.195419, 22.237362],
								[114.195419, 22.237640],
								[114.194580, 22.237640],
								[114.194580, 22.237362],
								[114.194305, 22.237362],
								[114.194305, 22.237083],
								[114.194031, 22.237083],
								[114.194031, 22.236805],
								[114.193192, 22.236805],
								[114.193192, 22.236526],
								[114.193069, 22.236526],
								[114.192642, 22.236526],
								[114.192642, 22.236250],
								[114.192360, 22.236250],
								[114.192360, 22.235971],
								[114.191528, 22.235971],
								[114.191528, 22.235697],
								[114.190697, 22.235697],
								[114.190697, 22.235416],
								[114.190414, 22.235416],
								[114.190414, 22.235697],
								[114.189857, 22.235697],
								[114.189857, 22.236250],
								[114.189308, 22.236250],
								[114.189308, 22.236526],
								[114.189026, 22.236526],
								[114.189026, 22.236805],
								[114.188751, 22.236805],
								[114.188751, 22.237083],
								[114.188194, 22.237083],
								[114.188194, 22.237640],
								[114.187927, 22.237640],
								[114.187920, 22.238194],
								[114.187637, 22.238194],
								[114.187637, 22.238470],
								[114.187363, 22.238470],
								[114.187363, 22.238750],
								[114.187080, 22.238750],
								[114.187080, 22.239029],
								[114.186806, 22.239029],
								[114.186806, 22.239305],
								[114.186531, 22.239305],
								[114.186531, 22.240417],
								[114.186249, 22.240417],
								[114.186249, 22.240694],
								[114.186531, 22.240694],
								[114.186531, 22.241806],
								[114.186806, 22.241806],
								[114.186806, 22.242361],
								[114.187080, 22.242361],
								[114.187080, 22.242640],
								[114.187637, 22.242640],
								[114.187637, 22.242916],
								[114.187920, 22.242916],
								[114.187920, 22.243750],
								[114.187637, 22.243750],
								[114.187637, 22.244028],
								[114.187363, 22.244028],
								[114.187363, 22.244583],
								[114.186806, 22.244583],
								[114.186806, 22.244862],
								[114.186531, 22.244862],
								[114.186531, 22.245138],
								[114.185692, 22.245138],
								[114.185692, 22.244862],
								[114.185417, 22.244862],
								[114.185417, 22.244583],
								[114.183746, 22.244583],
								[114.183746, 22.244862],
								[114.181808, 22.244862],
								[114.181808, 22.244583],
								[114.180138, 22.244583],
								[114.180138, 22.244305],
								[114.179306, 22.244305],
								[114.179306, 22.244028],
								[114.179031, 22.244028],
								[114.179031, 22.243750],
								[114.178749, 22.243750],
								[114.178749, 22.243195],
								[114.178474, 22.243195],
								[114.178474, 22.242640],
								[114.178749, 22.242640],
								[114.178749, 22.242083],
								[114.178474, 22.242083],
								[114.178474, 22.241806],
								[114.178192, 22.241806],
								[114.178192, 22.241528],
								[114.177917, 22.241528],
								[114.177917, 22.241249],
								[114.177635, 22.241249],
								[114.177635, 22.240694],
								[114.177086, 22.240694],
								[114.177086, 22.240141],
								[114.176804, 22.240141],
								[114.176804, 22.239859],
								[114.176247, 22.239859],
								[114.176247, 22.239029],
								[114.175972, 22.239029],
								[114.175972, 22.238470],
								[114.175697, 22.238470],
								[114.175697, 22.238194],
								[114.175415, 22.238194],
								[114.175415, 22.237640],
								[114.175140, 22.237640],
								[114.175140, 22.237083],
								[114.174858, 22.237083],
								[114.174858, 22.236805],
								[114.174583, 22.236805],
								[114.174583, 22.235697],
								[114.174309, 22.235697],
								[114.174309, 22.235140],
								[114.174454, 22.235138],
								[114.174583, 22.235138],
								[114.174583, 22.234861],
								[114.174309, 22.234861],
								[114.174309, 22.233194],
								[114.174026, 22.233194],
								[114.174026, 22.232916],
								[114.173752, 22.232916],
								[114.173752, 22.231810],
								[114.173195, 22.231806],
								[114.173195, 22.231251],
								[114.173470, 22.231251],
								[114.173470, 22.230972],
								[114.173195, 22.230972],
								[114.173195, 22.230417],
								[114.172920, 22.230417],
								[114.172920, 22.230139],
								[114.172638, 22.230139],
								[114.172638, 22.229860],
								[114.172363, 22.229860],
								[114.172363, 22.229584],
								[114.171524, 22.229584],
								[114.171524, 22.229860],
								[114.170975, 22.229860],
								[114.170975, 22.230139],
								[114.170418, 22.230139],
								[114.170418, 22.230694],
								[114.169586, 22.230694],
								[114.169586, 22.230972],
								[114.169472, 22.230972],
								[114.169029, 22.230972],
								[114.169029, 22.231251],
								[114.168472, 22.231251],
								[114.168472, 22.231527],
								[114.167915, 22.231527],
								[114.167915, 22.232082],
								[114.167496, 22.232084],
								[114.167358, 22.232084],
								[114.167358, 22.232361],
								[114.166809, 22.232361],
								[114.166809, 22.232639],
								[114.166527, 22.232639],
								[114.166527, 22.232916],
								[114.166252, 22.232916],
								[114.166252, 22.233473],
								[114.165970, 22.233473],
								[114.165970, 22.234028],
								[114.166245, 22.234028],
								[114.166252, 22.234583],
								[114.166527, 22.234583],
								[114.166527, 22.235138],
								[114.166656, 22.235138],
								[114.166809, 22.235140],
								[114.166809, 22.235697],
								[114.165970, 22.235697],
								[114.165970, 22.235971],
								[114.165695, 22.235971],
								[114.165695, 22.236250],
								[114.165413, 22.236250],
								[114.165413, 22.237362],
								[114.165138, 22.237362],
								[114.165138, 22.238750],
								[114.164864, 22.238750],
								[114.164864, 22.240141],
								[114.164581, 22.240141],
								[114.164581, 22.240694],
								[114.164024, 22.240694],
								[114.164024, 22.241249],
								[114.163750, 22.241249],
								[114.163750, 22.241806],
								[114.162918, 22.241806],
								[114.162918, 22.242361],
								[114.161804, 22.242361],
								[114.161804, 22.244028],
								[114.161530, 22.244028],
								[114.161530, 22.244583],
								[114.161247, 22.244583],
								[114.161247, 22.244862],
								[114.160973, 22.244862],
								[114.160973, 22.245138],
								[114.160698, 22.245138],
								[114.160698, 22.246529],
								[114.159859, 22.246529],
								[114.159859, 22.246805],
								[114.152916, 22.246805],
								[114.152916, 22.247084],
								[114.150696, 22.247084],
								[114.150696, 22.247360],
								[114.145416, 22.247360],
								[114.145416, 22.247639],
								[114.143753, 22.247639],
								[114.143753, 22.247915],
								[114.141251, 22.247915],
								[114.141251, 22.248194],
								[114.140976, 22.248194],
								[114.140976, 22.248472],
								[114.140419, 22.248472],
								[114.140419, 22.248194],
								[114.138748, 22.248194],
								[114.138748, 22.247915],
								[114.137917, 22.247915],
								[114.137917, 22.247639],
								[114.137360, 22.247639],
								[114.137360, 22.247915],
								[114.136528, 22.247915],
								[114.136528, 22.248194],
								[114.135696, 22.248194],
								[114.135696, 22.248751],
								[114.135139, 22.248751],
								[114.135139, 22.249584],
								[114.134865, 22.249584],
								[114.134865, 22.249861],
								[114.134583, 22.249861],
								[114.134583, 22.250137],
								[114.134308, 22.250137],
								[114.134308, 22.250317],
								[114.134308, 22.250694],
								[114.134026, 22.250694],
								[114.134026, 22.250866],
								[114.134026, 22.251247],
								[114.133469, 22.251247],
								[114.133469, 22.251530],
								[114.132957, 22.251530],
								[114.132919, 22.252083],
								[114.132362, 22.252083],
								[114.132362, 22.252361],
								[114.131805, 22.252361],
								[114.131805, 22.252642],
								[114.132080, 22.252642],
								[114.132080, 22.252916],
								[114.131805, 22.252916],
								[114.131805, 22.255417],
								[114.131531, 22.255417],
								[114.131531, 22.255695],
								[114.131248, 22.255695],
								[114.131248, 22.256805],
								[114.130974, 22.256805],
								[114.130974, 22.257084],
								[114.130417, 22.257084],
								[114.130417, 22.257360],
								[114.129860, 22.257360],
								[114.129860, 22.257917],
								[114.129379, 22.257917],
								[114.129303, 22.257917],
								[114.129303, 22.258192],
								[114.129028, 22.258192],
								[114.129028, 22.258472],
								[114.128754, 22.258472],
								[114.128754, 22.259027],
								[114.128471, 22.259027],
								[114.128471, 22.259861],
								[114.128197, 22.259861],
								[114.128197, 22.260414],
								[114.127914, 22.260414],
								[114.127914, 22.261250],
								[114.127640, 22.261250],
								[114.127640, 22.261526],
								[114.127357, 22.261526],
								[114.127357, 22.261805],
								[114.127083, 22.261808],
								[114.127083, 22.262083],
								[114.126808, 22.262083],
								[114.126808, 22.262362],
								[114.126526, 22.262362],
								[114.126526, 22.263197],
								[114.126251, 22.263197],
								[114.126251, 22.263748],
								[114.125969, 22.263748],
								[114.125969, 22.264027],
								[114.125420, 22.264027],
								[114.125420, 22.264858],
								[114.125137, 22.264862],
								[114.125137, 22.265417],
								[114.124863, 22.265417],
								[114.124863, 22.265972],
								[114.124580, 22.265972],
								[114.124580, 22.266251],
								[114.124306, 22.266251],
								[114.124306, 22.266806],
								[114.124031, 22.266806],
								[114.124031, 22.267639],
								[114.123749, 22.267639],
								[114.123749, 22.267916],
								[114.123489, 22.267916],
								[114.123474, 22.268473],
								[114.123192, 22.268473],
								[114.123192, 22.268749],
								[114.122917, 22.268749],
								[114.122917, 22.269306],
								[114.122643, 22.269306],
								[114.122643, 22.270140],
								[114.122360, 22.270140],
								[114.122360, 22.270695],
								[114.121803, 22.270695],
								[114.121803, 22.270971],
								[114.120972, 22.270971],
								[114.120972, 22.271250],
								[114.120415, 22.271250],
								[114.120415, 22.271528],
								[114.120140, 22.271528],
								[114.120140, 22.271805],
								[114.119583, 22.271805],
								[114.119522, 22.272083],
								[114.119026, 22.272083],
								[114.119026, 22.272638],
								[114.118469, 22.272638],
								[114.118469, 22.272917],
								[114.118195, 22.272917],
								[114.118195, 22.273211],
								[114.119453, 22.273285],
								[114.119659, 22.273285],
								[114.123245, 22.273285],
								[114.124931, 22.274130],
								[114.126610, 22.274969],
								[114.131241, 22.274969],
								[114.134193, 22.273285],
								[114.134743, 22.272917]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Tai Po",
					"ID_0": 102,
					"ID_1": 12,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.222641, 22.440695],
								[114.222641, 22.439859],
								[114.222916, 22.439859],
								[114.222916, 22.439583],
								[114.223198, 22.439583],
								[114.223198, 22.439030],
								[114.222641, 22.439030],
								[114.222641, 22.438473],
								[114.222359, 22.438473],
								[114.222359, 22.437916],
								[114.221527, 22.437916],
								[114.221527, 22.438194],
								[114.221252, 22.438194],
								[114.221252, 22.439030],
								[114.221527, 22.439030],
								[114.221527, 22.440695],
								[114.221802, 22.440695],
								[114.221802, 22.441252],
								[114.223198, 22.441252],
								[114.223198, 22.440969],
								[114.222916, 22.440969],
								[114.222916, 22.440695],
								[114.222641, 22.440695]
							]
						],
						[
							[
								[114.232361, 22.458750],
								[114.232361, 22.458471],
								[114.232788, 22.458471],
								[114.232918, 22.458471],
								[114.232918, 22.458195],
								[114.233749, 22.458195],
								[114.233749, 22.457640],
								[114.234024, 22.457640],
								[114.234024, 22.457083],
								[114.234306, 22.457083],
								[114.234306, 22.456528],
								[114.234581, 22.456528],
								[114.234581, 22.456251],
								[114.234306, 22.456249],
								[114.234306, 22.455694],
								[114.234024, 22.455694],
								[114.234024, 22.455416],
								[114.233749, 22.455416],
								[114.233749, 22.455139],
								[114.233475, 22.455139],
								[114.233475, 22.454584],
								[114.233192, 22.454584],
								[114.233192, 22.454306],
								[114.232918, 22.454306],
								[114.232918, 22.454027],
								[114.232635, 22.454027],
								[114.232635, 22.453470],
								[114.232361, 22.453470],
								[114.232361, 22.453194],
								[114.232086, 22.453194],
								[114.232086, 22.452641],
								[114.231804, 22.452641],
								[114.231804, 22.452084],
								[114.231247, 22.452084],
								[114.231247, 22.451805],
								[114.230972, 22.451805],
								[114.230972, 22.451530],
								[114.230698, 22.451530],
								[114.230698, 22.451262],
								[114.230423, 22.451248],
								[114.230415, 22.450972],
								[114.230141, 22.450972],
								[114.230141, 22.450695],
								[114.229309, 22.450695],
								[114.229309, 22.450420],
								[114.228203, 22.450417],
								[114.228195, 22.450136],
								[114.227913, 22.450136],
								[114.227913, 22.450417],
								[114.227638, 22.450417],
								[114.227638, 22.450136],
								[114.227081, 22.450136],
								[114.227081, 22.449862],
								[114.226524, 22.449862],
								[114.226524, 22.449583],
								[114.226250, 22.449583],
								[114.226250, 22.449026],
								[114.225693, 22.449026],
								[114.225693, 22.448750],
								[114.224861, 22.448750],
								[114.224861, 22.449026],
								[114.224304, 22.449026],
								[114.224304, 22.449305],
								[114.223747, 22.449306],
								[114.223747, 22.449583],
								[114.223473, 22.449583],
								[114.223473, 22.449862],
								[114.223198, 22.449862],
								[114.223198, 22.450695],
								[114.223747, 22.450695],
								[114.223747, 22.450972],
								[114.224030, 22.450972],
								[114.224030, 22.451248],
								[114.224586, 22.451248],
								[114.224586, 22.451805],
								[114.224861, 22.451805],
								[114.224861, 22.452084],
								[114.225418, 22.452084],
								[114.225418, 22.452362],
								[114.225525, 22.452362],
								[114.226250, 22.452362],
								[114.226250, 22.452641],
								[114.226524, 22.452641],
								[114.226524, 22.453751],
								[114.226807, 22.453751],
								[114.226807, 22.454306],
								[114.227081, 22.454306],
								[114.227081, 22.454863],
								[114.227364, 22.454863],
								[114.227364, 22.455973],
								[114.227913, 22.455973],
								[114.227913, 22.456528],
								[114.228470, 22.456528],
								[114.228470, 22.457083],
								[114.228752, 22.457083],
								[114.228752, 22.457361],
								[114.229027, 22.457361],
								[114.229027, 22.457640],
								[114.229576, 22.457640],
								[114.229584, 22.457916],
								[114.229858, 22.457916],
								[114.229858, 22.458195],
								[114.230415, 22.458195],
								[114.230415, 22.458471],
								[114.230698, 22.458471],
								[114.230698, 22.458750],
								[114.232361, 22.458750]
							]
						],
						[
							[
								[114.221802, 22.463472],
								[114.221809, 22.463194],
								[114.222084, 22.463194],
								[114.222084, 22.462915],
								[114.222641, 22.462915],
								[114.222641, 22.462639],
								[114.222916, 22.462639],
								[114.222916, 22.462084],
								[114.223198, 22.462084],
								[114.223198, 22.461805],
								[114.223473, 22.461805],
								[114.223473, 22.461250],
								[114.223747, 22.461250],
								[114.223747, 22.460138],
								[114.222641, 22.460138],
								[114.222641, 22.459862],
								[114.220970, 22.459862],
								[114.220970, 22.460138],
								[114.220413, 22.460138],
								[114.220413, 22.460695],
								[114.220139, 22.460695],
								[114.220139, 22.461527],
								[114.219864, 22.461527],
								[114.219864, 22.462084],
								[114.220139, 22.462084],
								[114.220139, 22.462360],
								[114.220413, 22.462360],
								[114.220413, 22.462639],
								[114.220695, 22.462639],
								[114.220695, 22.463194],
								[114.221252, 22.463194],
								[114.221252, 22.463472],
								[114.221802, 22.463472]
							]
						],
						[
							[
								[114.322357, 22.480417],
								[114.322365, 22.480141],
								[114.322632, 22.480141],
								[114.322639, 22.479584],
								[114.322914, 22.479584],
								[114.322914, 22.479031],
								[114.323196, 22.479031],
								[114.323196, 22.478472],
								[114.323471, 22.478472],
								[114.323471, 22.478195],
								[114.323753, 22.478195],
								[114.323753, 22.477921],
								[114.324028, 22.477917],
								[114.324028, 22.477083],
								[114.324280, 22.477083],
								[114.324303, 22.476805],
								[114.324860, 22.476805],
								[114.324860, 22.476526],
								[114.324585, 22.476526],
								[114.324585, 22.475695],
								[114.324303, 22.475695],
								[114.324303, 22.475416],
								[114.324028, 22.475416],
								[114.324028, 22.474861],
								[114.323753, 22.474861],
								[114.323753, 22.474304],
								[114.323471, 22.474304],
								[114.323471, 22.473749],
								[114.323753, 22.473749],
								[114.323753, 22.473473],
								[114.324028, 22.473473],
								[114.324028, 22.472918],
								[114.324303, 22.472918],
								[114.324303, 22.472361],
								[114.324860, 22.472361],
								[114.324860, 22.472086],
								[114.325378, 22.472086],
								[114.325417, 22.471806],
								[114.327080, 22.471806],
								[114.327080, 22.472086],
								[114.327637, 22.472086],
								[114.327637, 22.472361],
								[114.328186, 22.472361],
								[114.328194, 22.472639],
								[114.328476, 22.472639],
								[114.328476, 22.472918],
								[114.328712, 22.472918],
								[114.328751, 22.473194],
								[114.329025, 22.473194],
								[114.329025, 22.473473],
								[114.330139, 22.473473],
								[114.330139, 22.473749],
								[114.330971, 22.473749],
								[114.330971, 22.473473],
								[114.331253, 22.473473],
								[114.331253, 22.471806],
								[114.331528, 22.471806],
								[114.331528, 22.470421],
								[114.330414, 22.470417],
								[114.330414, 22.470694],
								[114.329865, 22.470694],
								[114.329865, 22.470417],
								[114.329582, 22.470417],
								[114.329582, 22.469864],
								[114.329308, 22.469864],
								[114.329308, 22.469305],
								[114.329582, 22.469305],
								[114.329582, 22.468752],
								[114.329308, 22.468752],
								[114.329308, 22.467640],
								[114.329025, 22.467640],
								[114.329025, 22.466530],
								[114.328751, 22.466530],
								[114.328751, 22.465416],
								[114.328476, 22.465416],
								[114.328476, 22.465137],
								[114.328194, 22.465137],
								[114.328194, 22.464861],
								[114.327919, 22.464861],
								[114.327919, 22.464310],
								[114.327637, 22.464306],
								[114.327637, 22.463472],
								[114.327362, 22.463472],
								[114.327362, 22.462360],
								[114.327637, 22.462360],
								[114.327637, 22.462084],
								[114.327911, 22.462084],
								[114.327919, 22.461805],
								[114.328041, 22.461805],
								[114.329582, 22.461805],
								[114.329582, 22.462360],
								[114.330139, 22.462360],
								[114.330139, 22.463194],
								[114.330414, 22.463194],
								[114.330414, 22.463472],
								[114.330696, 22.463472],
								[114.330696, 22.463751],
								[114.330971, 22.463751],
								[114.330971, 22.464027],
								[114.331528, 22.464027],
								[114.331528, 22.464306],
								[114.332077, 22.464306],
								[114.332085, 22.464031],
								[114.332642, 22.464027],
								[114.332642, 22.463751],
								[114.332916, 22.463751],
								[114.332916, 22.463472],
								[114.334862, 22.463472],
								[114.334862, 22.463194],
								[114.335136, 22.463194],
								[114.335136, 22.463472],
								[114.335976, 22.463472],
								[114.335976, 22.463751],
								[114.336525, 22.463751],
								[114.336525, 22.464861],
								[114.336250, 22.464861],
								[114.336250, 22.465973],
								[114.335976, 22.465973],
								[114.335976, 22.466251],
								[114.336250, 22.466251],
								[114.336250, 22.466806],
								[114.336754, 22.466806],
								[114.336807, 22.467081],
								[114.337364, 22.467081],
								[114.337364, 22.467361],
								[114.337914, 22.467361],
								[114.337914, 22.468195],
								[114.338196, 22.468195],
								[114.338196, 22.468470],
								[114.338470, 22.468470],
								[114.338470, 22.469584],
								[114.338753, 22.469584],
								[114.338753, 22.470417],
								[114.339027, 22.470417],
								[114.339027, 22.470972],
								[114.339302, 22.470972],
								[114.339302, 22.471527],
								[114.339027, 22.471527],
								[114.339027, 22.472639],
								[114.338753, 22.472639],
								[114.338753, 22.473194],
								[114.338196, 22.473194],
								[114.338196, 22.473749],
								[114.337914, 22.473749],
								[114.337914, 22.474028],
								[114.337364, 22.474028],
								[114.337364, 22.474861],
								[114.337639, 22.474861],
								[114.337639, 22.475140],
								[114.338196, 22.475140],
								[114.338196, 22.474861],
								[114.339302, 22.474861],
								[114.339302, 22.475140],
								[114.339577, 22.475140],
								[114.339584, 22.476250],
								[114.339859, 22.476250],
								[114.339859, 22.477636],
								[114.340141, 22.477636],
								[114.340141, 22.477917],
								[114.340408, 22.477917],
								[114.340416, 22.478472],
								[114.340698, 22.478472],
								[114.340698, 22.478748],
								[114.341248, 22.478748],
								[114.341248, 22.479031],
								[114.341530, 22.479031],
								[114.341530, 22.479305],
								[114.341797, 22.479305],
								[114.341805, 22.479031],
								[114.342087, 22.479031],
								[114.342087, 22.478748],
								[114.342361, 22.478748],
								[114.342361, 22.478472],
								[114.342918, 22.478472],
								[114.342918, 22.477362],
								[114.343193, 22.477362],
								[114.343193, 22.476250],
								[114.343475, 22.476250],
								[114.343475, 22.475451],
								[114.343742, 22.475416],
								[114.343750, 22.474861],
								[114.344017, 22.474861],
								[114.344025, 22.474310],
								[114.344307, 22.474304],
								[114.344307, 22.473473],
								[114.344582, 22.473473],
								[114.344582, 22.471251],
								[114.344307, 22.471251],
								[114.344307, 22.470417],
								[114.344582, 22.470417],
								[114.344582, 22.469584],
								[114.344864, 22.469584],
								[114.344864, 22.469305],
								[114.345139, 22.469305],
								[114.345139, 22.468752],
								[114.345413, 22.468752],
								[114.345413, 22.468195],
								[114.345688, 22.468195],
								[114.345695, 22.467640],
								[114.345970, 22.467640],
								[114.345970, 22.467081],
								[114.346230, 22.467081],
								[114.346252, 22.466530],
								[114.346527, 22.466530],
								[114.346527, 22.465416],
								[114.346802, 22.465416],
								[114.346802, 22.464861],
								[114.347076, 22.464861],
								[114.347084, 22.464310],
								[114.347359, 22.464306],
								[114.347359, 22.463472],
								[114.347641, 22.463472],
								[114.347641, 22.462639],
								[114.347916, 22.462639],
								[114.347916, 22.462360],
								[114.347641, 22.462360],
								[114.347641, 22.462084],
								[114.347084, 22.462084],
								[114.347084, 22.461805],
								[114.346802, 22.461805],
								[114.346802, 22.461531],
								[114.346527, 22.461527],
								[114.346527, 22.461250],
								[114.345970, 22.461250],
								[114.345970, 22.460972],
								[114.345413, 22.460972],
								[114.345413, 22.460695],
								[114.344582, 22.460695],
								[114.344582, 22.460417],
								[114.343475, 22.460417],
								[114.343475, 22.460695],
								[114.342361, 22.460695],
								[114.342361, 22.460972],
								[114.341805, 22.460972],
								[114.341805, 22.461250],
								[114.341530, 22.461250],
								[114.341530, 22.461527],
								[114.341248, 22.461527],
								[114.341248, 22.461805],
								[114.340996, 22.461805],
								[114.340973, 22.462084],
								[114.340698, 22.462084],
								[114.340698, 22.462360],
								[114.340141, 22.462360],
								[114.340141, 22.462639],
								[114.339584, 22.462639],
								[114.339584, 22.462915],
								[114.338753, 22.462915],
								[114.338753, 22.463194],
								[114.338470, 22.463194],
								[114.338470, 22.462915],
								[114.338196, 22.462915],
								[114.338196, 22.462641],
								[114.337914, 22.462639],
								[114.337914, 22.461811],
								[114.338470, 22.461805],
								[114.338470, 22.461531],
								[114.338753, 22.461527],
								[114.338753, 22.460972],
								[114.339027, 22.460972],
								[114.339027, 22.460695],
								[114.338753, 22.460695],
								[114.338753, 22.460417],
								[114.339027, 22.460417],
								[114.339027, 22.460138],
								[114.338753, 22.460138],
								[114.338753, 22.458195],
								[114.338470, 22.458195],
								[114.338470, 22.457361],
								[114.338196, 22.457361],
								[114.338196, 22.456810],
								[114.337914, 22.456806],
								[114.337914, 22.456249],
								[114.337639, 22.456249],
								[114.337639, 22.455694],
								[114.337364, 22.455694],
								[114.337364, 22.455139],
								[114.337082, 22.455139],
								[114.337082, 22.454584],
								[114.337341, 22.454584],
								[114.337364, 22.453751],
								[114.337639, 22.453751],
								[114.337639, 22.453194],
								[114.337914, 22.453194],
								[114.337914, 22.452917],
								[114.338196, 22.452917],
								[114.338196, 22.452641],
								[114.338470, 22.452641],
								[114.338470, 22.451811],
								[114.338753, 22.451805],
								[114.338753, 22.450138],
								[114.338470, 22.450136],
								[114.338470, 22.449310],
								[114.338600, 22.449306],
								[114.338753, 22.449305],
								[114.338753, 22.448473],
								[114.338470, 22.448473],
								[114.338470, 22.448195],
								[114.339027, 22.448195],
								[114.339027, 22.447920],
								[114.340141, 22.447914],
								[114.340141, 22.447639],
								[114.340691, 22.447639],
								[114.340698, 22.447376],
								[114.340973, 22.447361],
								[114.340973, 22.447083],
								[114.341530, 22.447083],
								[114.341530, 22.446527],
								[114.341797, 22.446527],
								[114.341805, 22.444860],
								[114.341530, 22.444860],
								[114.341530, 22.444311],
								[114.341248, 22.444305],
								[114.341248, 22.443474],
								[114.340973, 22.443474],
								[114.340973, 22.442917],
								[114.340698, 22.442917],
								[114.340691, 22.442362],
								[114.340416, 22.442362],
								[114.340416, 22.442083],
								[114.340141, 22.442083],
								[114.340141, 22.441528],
								[114.339584, 22.441528],
								[114.339584, 22.441252],
								[114.339302, 22.441252],
								[114.339302, 22.440969],
								[114.339027, 22.440969],
								[114.339027, 22.440142],
								[114.336533, 22.440142],
								[114.336525, 22.440416],
								[114.334305, 22.440416],
								[114.334305, 22.439030],
								[114.334587, 22.439030],
								[114.334587, 22.438747],
								[114.334862, 22.438747],
								[114.334862, 22.438194],
								[114.335106, 22.438194],
								[114.335136, 22.437916],
								[114.335419, 22.437916],
								[114.335419, 22.437637],
								[114.335693, 22.437637],
								[114.335693, 22.437084],
								[114.335960, 22.437084],
								[114.335976, 22.436806],
								[114.336250, 22.436806],
								[114.336250, 22.436531],
								[114.336807, 22.436527],
								[114.336807, 22.436258],
								[114.337082, 22.436251],
								[114.337082, 22.435694],
								[114.337364, 22.435694],
								[114.337364, 22.434584],
								[114.337639, 22.434584],
								[114.337639, 22.434305],
								[114.337364, 22.434305],
								[114.337364, 22.432917],
								[114.337082, 22.432917],
								[114.337082, 22.431250],
								[114.336525, 22.431250],
								[114.336525, 22.430416],
								[114.336250, 22.430416],
								[114.336250, 22.430140],
								[114.335976, 22.430140],
								[114.335976, 22.429861],
								[114.335693, 22.429861],
								[114.335693, 22.429306],
								[114.335419, 22.429306],
								[114.335419, 22.429029],
								[114.335136, 22.429028],
								[114.335136, 22.428749],
								[114.334862, 22.428749],
								[114.334862, 22.428194],
								[114.335388, 22.428194],
								[114.335419, 22.427917],
								[114.336250, 22.427917],
								[114.336250, 22.428194],
								[114.336525, 22.428194],
								[114.336525, 22.428473],
								[114.337082, 22.428473],
								[114.337082, 22.428749],
								[114.337364, 22.428749],
								[114.337364, 22.429028],
								[114.337914, 22.429028],
								[114.337914, 22.429306],
								[114.338196, 22.429306],
								[114.338196, 22.429583],
								[114.337914, 22.429583],
								[114.337914, 22.429861],
								[114.338196, 22.429861],
								[114.338196, 22.430140],
								[114.337914, 22.430140],
								[114.337914, 22.430973],
								[114.338196, 22.430973],
								[114.338196, 22.431528],
								[114.338470, 22.431528],
								[114.338470, 22.431805],
								[114.339584, 22.431805],
								[114.339584, 22.431528],
								[114.341248, 22.431528],
								[114.341248, 22.431805],
								[114.341805, 22.431805],
								[114.341805, 22.433193],
								[114.342087, 22.433193],
								[114.342087, 22.433472],
								[114.341805, 22.433472],
								[114.341805, 22.435694],
								[114.342361, 22.435694],
								[114.342361, 22.436527],
								[114.342636, 22.436527],
								[114.342636, 22.437084],
								[114.343193, 22.437084],
								[114.343193, 22.437361],
								[114.343475, 22.437361],
								[114.343475, 22.437916],
								[114.343750, 22.437916],
								[114.343750, 22.438194],
								[114.344017, 22.438194],
								[114.344025, 22.438747],
								[114.344582, 22.438747],
								[114.344582, 22.439030],
								[114.345139, 22.439030],
								[114.345139, 22.439306],
								[114.345413, 22.439306],
								[114.345413, 22.439030],
								[114.345970, 22.439030],
								[114.345970, 22.439306],
								[114.346252, 22.439306],
								[114.346252, 22.439030],
								[114.346802, 22.439030],
								[114.346802, 22.438747],
								[114.347359, 22.438747],
								[114.347359, 22.438194],
								[114.347610, 22.438194],
								[114.347641, 22.437084],
								[114.347359, 22.437084],
								[114.347359, 22.435415],
								[114.347641, 22.435415],
								[114.347641, 22.434584],
								[114.347916, 22.434584],
								[114.347916, 22.434029],
								[114.348198, 22.434029],
								[114.348198, 22.433472],
								[114.348747, 22.433472],
								[114.348747, 22.433193],
								[114.349030, 22.433193],
								[114.349030, 22.432638],
								[114.349297, 22.432638],
								[114.349304, 22.431250],
								[114.349586, 22.431250],
								[114.349586, 22.430420],
								[114.349861, 22.430416],
								[114.349861, 22.429583],
								[114.350136, 22.429583],
								[114.350136, 22.428749],
								[114.350418, 22.428749],
								[114.350418, 22.428194],
								[114.350677, 22.428194],
								[114.350693, 22.426529],
								[114.350418, 22.426529],
								[114.350418, 22.426249],
								[114.349861, 22.426249],
								[114.349861, 22.425970],
								[114.349586, 22.425970],
								[114.349586, 22.425695],
								[114.349030, 22.425694],
								[114.349030, 22.425419],
								[114.347641, 22.425419],
								[114.347641, 22.424583],
								[114.347916, 22.424583],
								[114.347916, 22.423750],
								[114.348198, 22.423750],
								[114.348198, 22.423471],
								[114.348473, 22.423471],
								[114.348473, 22.423195],
								[114.348747, 22.423195],
								[114.348747, 22.422916],
								[114.349030, 22.422916],
								[114.349030, 22.422640],
								[114.349297, 22.422638],
								[114.349304, 22.422361],
								[114.351517, 22.422361],
								[114.351524, 22.422638],
								[114.354027, 22.422638],
								[114.354027, 22.422361],
								[114.355415, 22.422361],
								[114.355415, 22.422638],
								[114.355141, 22.422640],
								[114.355141, 22.422916],
								[114.354584, 22.422916],
								[114.354584, 22.423195],
								[114.354309, 22.423195],
								[114.354309, 22.423471],
								[114.354027, 22.423471],
								[114.354027, 22.423750],
								[114.353752, 22.423750],
								[114.353752, 22.424305],
								[114.353470, 22.424305],
								[114.353470, 22.425140],
								[114.353752, 22.425140],
								[114.353752, 22.425419],
								[114.355141, 22.425419],
								[114.355141, 22.425694],
								[114.355019, 22.425694],
								[114.354858, 22.425695],
								[114.354858, 22.426249],
								[114.354584, 22.426249],
								[114.354584, 22.426805],
								[114.354309, 22.426805],
								[114.354309, 22.427359],
								[114.354027, 22.427359],
								[114.354027, 22.427917],
								[114.353752, 22.427917],
								[114.353752, 22.429028],
								[114.354027, 22.429028],
								[114.354027, 22.430416],
								[114.353752, 22.430416],
								[114.353752, 22.432917],
								[114.354027, 22.432917],
								[114.354027, 22.434029],
								[114.354309, 22.434029],
								[114.354309, 22.435415],
								[114.354027, 22.435415],
								[114.354027, 22.435972],
								[114.354309, 22.435972],
								[114.354309, 22.436806],
								[114.354027, 22.436806],
								[114.354027, 22.437361],
								[114.354309, 22.437361],
								[114.354309, 22.437637],
								[114.354584, 22.437637],
								[114.354584, 22.438194],
								[114.354858, 22.438194],
								[114.354858, 22.438473],
								[114.355141, 22.438473],
								[114.355141, 22.438747],
								[114.355415, 22.438747],
								[114.355415, 22.439306],
								[114.355698, 22.439306],
								[114.355698, 22.439583],
								[114.355415, 22.439583],
								[114.355415, 22.439859],
								[114.355141, 22.439859],
								[114.355141, 22.440416],
								[114.354858, 22.440416],
								[114.354858, 22.440695],
								[114.354027, 22.440695],
								[114.354027, 22.439583],
								[114.354309, 22.439583],
								[114.354309, 22.438473],
								[114.354027, 22.438473],
								[114.354027, 22.438194],
								[114.353470, 22.438194],
								[114.353470, 22.438473],
								[114.352913, 22.438473],
								[114.352913, 22.439583],
								[114.352638, 22.439583],
								[114.352638, 22.440695],
								[114.352364, 22.440695],
								[114.352364, 22.441528],
								[114.352081, 22.441528],
								[114.352081, 22.441805],
								[114.352356, 22.441805],
								[114.352364, 22.442638],
								[114.352638, 22.442638],
								[114.352638, 22.444027],
								[114.352913, 22.444027],
								[114.352913, 22.444860],
								[114.353195, 22.444860],
								[114.353195, 22.445696],
								[114.353470, 22.445696],
								[114.353470, 22.446251],
								[114.353752, 22.446251],
								[114.353752, 22.447083],
								[114.353470, 22.447083],
								[114.353470, 22.447914],
								[114.352913, 22.447914],
								[114.352913, 22.448473],
								[114.352364, 22.448473],
								[114.352364, 22.449862],
								[114.352638, 22.449862],
								[114.352638, 22.450136],
								[114.352913, 22.450136],
								[114.352913, 22.450417],
								[114.353195, 22.450417],
								[114.353195, 22.450695],
								[114.354027, 22.450695],
								[114.354027, 22.450972],
								[114.354309, 22.450972],
								[114.354309, 22.450695],
								[114.354584, 22.450695],
								[114.354584, 22.450972],
								[114.354858, 22.450972],
								[114.354858, 22.450695],
								[114.355415, 22.450695],
								[114.355415, 22.450417],
								[114.356247, 22.450417],
								[114.356247, 22.450695],
								[114.357086, 22.450695],
								[114.357086, 22.451248],
								[114.357361, 22.451248],
								[114.357361, 22.451805],
								[114.357918, 22.451805],
								[114.357918, 22.452358],
								[114.358467, 22.452362],
								[114.358475, 22.452641],
								[114.358749, 22.452641],
								[114.358749, 22.452917],
								[114.359306, 22.452917],
								[114.359306, 22.452641],
								[114.359863, 22.452641],
								[114.359863, 22.452362],
								[114.360092, 22.452358],
								[114.360138, 22.452084],
								[114.361809, 22.452084],
								[114.361809, 22.452917],
								[114.361526, 22.452917],
								[114.361526, 22.453751],
								[114.361252, 22.453751],
								[114.361252, 22.454027],
								[114.360970, 22.454027],
								[114.360970, 22.454584],
								[114.360695, 22.454584],
								[114.360695, 22.455694],
								[114.360420, 22.455694],
								[114.360420, 22.456249],
								[114.360138, 22.456249],
								[114.360138, 22.456806],
								[114.360420, 22.456806],
								[114.360420, 22.457083],
								[114.360687, 22.457083],
								[114.360695, 22.458750],
								[114.360138, 22.458750],
								[114.360138, 22.459305],
								[114.360420, 22.459305],
								[114.360420, 22.459583],
								[114.360138, 22.459583],
								[114.360138, 22.459862],
								[114.360420, 22.459862],
								[114.360420, 22.460695],
								[114.360695, 22.460695],
								[114.360695, 22.461250],
								[114.361809, 22.461250],
								[114.361809, 22.460972],
								[114.362083, 22.460972],
								[114.362083, 22.460695],
								[114.362640, 22.460695],
								[114.362640, 22.460421],
								[114.362915, 22.460417],
								[114.362915, 22.460138],
								[114.363197, 22.460138],
								[114.363197, 22.459862],
								[114.363739, 22.459862],
								[114.363747, 22.459583],
								[114.364029, 22.459583],
								[114.364029, 22.459305],
								[114.364433, 22.459305],
								[114.365135, 22.459305],
								[114.365135, 22.459188],
								[114.365135, 22.459028],
								[114.365692, 22.459028],
								[114.365692, 22.458750],
								[114.367638, 22.458750],
								[114.367638, 22.457083],
								[114.367920, 22.457083],
								[114.367920, 22.456806],
								[114.368187, 22.456806],
								[114.368195, 22.456530],
								[114.368469, 22.456528],
								[114.368469, 22.456251],
								[114.369308, 22.456249],
								[114.369308, 22.455694],
								[114.369560, 22.455694],
								[114.369583, 22.455416],
								[114.369858, 22.455416],
								[114.369858, 22.455139],
								[114.370140, 22.455139],
								[114.370140, 22.454863],
								[114.370415, 22.454863],
								[114.370415, 22.454584],
								[114.370674, 22.454584],
								[114.370697, 22.453194],
								[114.370415, 22.453194],
								[114.370415, 22.452917],
								[114.370140, 22.452917],
								[114.370140, 22.450420],
								[114.369858, 22.450417],
								[114.369858, 22.449862],
								[114.369583, 22.449862],
								[114.369583, 22.449306],
								[114.369308, 22.449306],
								[114.369308, 22.449026],
								[114.368752, 22.449026],
								[114.368752, 22.448473],
								[114.368469, 22.448473],
								[114.368469, 22.448195],
								[114.368195, 22.448195],
								[114.368195, 22.447914],
								[114.367638, 22.447914],
								[114.367638, 22.447639],
								[114.367363, 22.447639],
								[114.367363, 22.447083],
								[114.367081, 22.447083],
								[114.367081, 22.444860],
								[114.367638, 22.444860],
								[114.367638, 22.444311],
								[114.367920, 22.444305],
								[114.367920, 22.444027],
								[114.368187, 22.444027],
								[114.368195, 22.443750],
								[114.367920, 22.443750],
								[114.367920, 22.443474],
								[114.368195, 22.443474],
								[114.368195, 22.442638],
								[114.368469, 22.442638],
								[114.368469, 22.441904],
								[114.367424, 22.442945],
								[114.366165, 22.442528],
								[114.365242, 22.442223],
								[114.364899, 22.442104],
								[114.364479, 22.440844],
								[114.364761, 22.440126],
								[114.365318, 22.438738],
								[114.365318, 22.437473],
								[114.364975, 22.436964],
								[114.364479, 22.436213],
								[114.364250, 22.435537],
								[114.364059, 22.434950],
								[114.364616, 22.433823],
								[114.364899, 22.433266],
								[114.365929, 22.432646],
								[114.367004, 22.432001],
								[114.367004, 22.429897],
								[114.366959, 22.429785],
								[114.366165, 22.427792],
								[114.367844, 22.426107],
								[114.368690, 22.425266],
								[114.368263, 22.424006],
								[114.368225, 22.423838],
								[114.367844, 22.422319],
								[114.367554, 22.421440],
								[114.367424, 22.421059],
								[114.367172, 22.420290],
								[114.367004, 22.419794],
								[114.365738, 22.417688],
								[114.365738, 22.416426],
								[114.365738, 22.415165],
								[114.364479, 22.414320],
								[114.363785, 22.413975],
								[114.362793, 22.413479],
								[114.362450, 22.412960],
								[114.361954, 22.412214],
								[114.361534, 22.410952],
								[114.359001, 22.408426],
								[114.358162, 22.407585],
								[114.356056, 22.406321],
								[114.355240, 22.406048],
								[114.354797, 22.405901],
								[114.353111, 22.405901],
								[114.351845, 22.406742],
								[114.351845, 22.408005],
								[114.351845, 22.409267],
								[114.351204, 22.409481],
								[114.350586, 22.409689],
								[114.350327, 22.409861],
								[114.349319, 22.410534],
								[114.347214, 22.410534],
								[114.345955, 22.410534],
								[114.344688, 22.412214],
								[114.343849, 22.413479],
								[114.343369, 22.413958],
								[114.343010, 22.414320],
								[114.341743, 22.415165],
								[114.340904, 22.416004],
								[114.339638, 22.417688],
								[114.339272, 22.418051],
								[114.338799, 22.418530],
								[114.338287, 22.418701],
								[114.337532, 22.418953],
								[114.336693, 22.418112],
								[114.336563, 22.417719],
								[114.336273, 22.416845],
								[114.334572, 22.415157],
								[114.333321, 22.414742],
								[114.332062, 22.414742],
								[114.330376, 22.414742],
								[114.329575, 22.415142],
								[114.328690, 22.415583],
								[114.326508, 22.416309],
								[114.326164, 22.416426],
								[114.325325, 22.417267],
								[114.324471, 22.417906],
								[114.323639, 22.418530],
								[114.323120, 22.419306],
								[114.322800, 22.419794],
								[114.321953, 22.420635],
								[114.322243, 22.420923],
								[114.322800, 22.421476],
								[114.323601, 22.422081],
								[114.324486, 22.422743],
								[114.323822, 22.423182],
								[114.323219, 22.423582],
								[114.322258, 22.423742],
								[114.320694, 22.424006],
								[114.320267, 22.424854],
								[114.319855, 22.425690],
								[114.319099, 22.426188],
								[114.318588, 22.426531],
								[114.317322, 22.426531],
								[114.315865, 22.426893],
								[114.315643, 22.426950],
								[114.313538, 22.427374],
								[114.312271, 22.427374],
								[114.311523, 22.427622],
								[114.311012, 22.427792],
								[114.310211, 22.427992],
								[114.309326, 22.428213],
								[114.307640, 22.427374],
								[114.306381, 22.426107],
								[114.306381, 22.424006],
								[114.306953, 22.422562],
								[114.307220, 22.421900],
								[114.306610, 22.420980],
								[114.306381, 22.420635],
								[114.305115, 22.418953],
								[114.306381, 22.417267],
								[114.303856, 22.415583],
								[114.302170, 22.415583],
								[114.301414, 22.415771],
								[114.300484, 22.416004],
								[114.299629, 22.416290],
								[114.299225, 22.416426],
								[114.298599, 22.416218],
								[114.296700, 22.415583],
								[114.295013, 22.416426],
								[114.293327, 22.416845],
								[114.292068, 22.416845],
								[114.290382, 22.416426],
								[114.288277, 22.415583],
								[114.287735, 22.414772],
								[114.287437, 22.414320],
								[114.287064, 22.410643],
								[114.287010, 22.410110],
								[114.286949, 22.409922],
								[114.286591, 22.408848],
								[114.284912, 22.407164],
								[114.283646, 22.406321],
								[114.283363, 22.406036],
								[114.282806, 22.405479],
								[114.281593, 22.403059],
								[114.281540, 22.402954],
								[114.279015, 22.402954],
								[114.277748, 22.403795],
								[114.275223, 22.401690],
								[114.273964, 22.400427],
								[114.272949, 22.399414],
								[114.272278, 22.398743],
								[114.257545, 22.398743],
								[114.256699, 22.397480],
								[114.256332, 22.397358],
								[114.255440, 22.397058],
								[114.254173, 22.395796],
								[114.252068, 22.394533],
								[114.252068, 22.398743],
								[114.252251, 22.399277],
								[114.252495, 22.400005],
								[114.253334, 22.400848],
								[114.253754, 22.402111],
								[114.252914, 22.402954],
								[114.252914, 22.404217],
								[114.252914, 22.405479],
								[114.252190, 22.406199],
								[114.252068, 22.406321],
								[114.251228, 22.407164],
								[114.251648, 22.410110],
								[114.251228, 22.412214],
								[114.250580, 22.412703],
								[114.249542, 22.413479],
								[114.247871, 22.414146],
								[114.247437, 22.414320],
								[114.248283, 22.417267],
								[114.248062, 22.417925],
								[114.248001, 22.418097],
								[114.247864, 22.418530],
								[114.248116, 22.419794],
								[114.248283, 22.420635],
								[114.248993, 22.422415],
								[114.249123, 22.422743],
								[114.249123, 22.424425],
								[114.249123, 22.425690],
								[114.249123, 22.426950],
								[114.247017, 22.427792],
								[114.247017, 22.429054],
								[114.247658, 22.429693],
								[114.248283, 22.430319],
								[114.248283, 22.431580],
								[114.247307, 22.431419],
								[114.245758, 22.431162],
								[114.245697, 22.431257],
								[114.245697, 22.431528],
								[114.246246, 22.431528],
								[114.246246, 22.432083],
								[114.246529, 22.432083],
								[114.246529, 22.432362],
								[114.247917, 22.432362],
								[114.247917, 22.432638],
								[114.248192, 22.432638],
								[114.248192, 22.432917],
								[114.248985, 22.432917],
								[114.249031, 22.433193],
								[114.250969, 22.433193],
								[114.250969, 22.433472],
								[114.251251, 22.433472],
								[114.251251, 22.433750],
								[114.251526, 22.433750],
								[114.251526, 22.434029],
								[114.251808, 22.434029],
								[114.251808, 22.434305],
								[114.252357, 22.434305],
								[114.252357, 22.434584],
								[114.252640, 22.434584],
								[114.252640, 22.434305],
								[114.252914, 22.434305],
								[114.252914, 22.434584],
								[114.253197, 22.434584],
								[114.253197, 22.434860],
								[114.253754, 22.434860],
								[114.253754, 22.435139],
								[114.254028, 22.435139],
								[114.254028, 22.434584],
								[114.254303, 22.434584],
								[114.254303, 22.434029],
								[114.254585, 22.434029],
								[114.254585, 22.433750],
								[114.254860, 22.433750],
								[114.254860, 22.433193],
								[114.255127, 22.433193],
								[114.255142, 22.432917],
								[114.255417, 22.432917],
								[114.255417, 22.432362],
								[114.255692, 22.432362],
								[114.255692, 22.432083],
								[114.257637, 22.432083],
								[114.257637, 22.432362],
								[114.259026, 22.432362],
								[114.259026, 22.432638],
								[114.260139, 22.432638],
								[114.260139, 22.432917],
								[114.261452, 22.432917],
								[114.261528, 22.432917],
								[114.261528, 22.433193],
								[114.262642, 22.433193],
								[114.262642, 22.433472],
								[114.264862, 22.433472],
								[114.264862, 22.433750],
								[114.267082, 22.433750],
								[114.267082, 22.434029],
								[114.268196, 22.434029],
								[114.268196, 22.434860],
								[114.268463, 22.434860],
								[114.268471, 22.434311],
								[114.268753, 22.434305],
								[114.268753, 22.434029],
								[114.269028, 22.434029],
								[114.269028, 22.432920],
								[114.269302, 22.432917],
								[114.269302, 22.432640],
								[114.269577, 22.432638],
								[114.269585, 22.432083],
								[114.269798, 22.432083],
								[114.269859, 22.431805],
								[114.270416, 22.431805],
								[114.270416, 22.431250],
								[114.270691, 22.431250],
								[114.270691, 22.430973],
								[114.270943, 22.430973],
								[114.270973, 22.430695],
								[114.270691, 22.430695],
								[114.270691, 22.429583],
								[114.270973, 22.429583],
								[114.270973, 22.427641],
								[114.271248, 22.427641],
								[114.271248, 22.425970],
								[114.271515, 22.425970],
								[114.271530, 22.424026],
								[114.271248, 22.424026],
								[114.271248, 22.422920],
								[114.270973, 22.422916],
								[114.270973, 22.422640],
								[114.270691, 22.422638],
								[114.270691, 22.422361],
								[114.270416, 22.422361],
								[114.270416, 22.422083],
								[114.270142, 22.422083],
								[114.270142, 22.421528],
								[114.269859, 22.421528],
								[114.269859, 22.421249],
								[114.269585, 22.421249],
								[114.269585, 22.420973],
								[114.269302, 22.420973],
								[114.269302, 22.420694],
								[114.269028, 22.420694],
								[114.269028, 22.420416],
								[114.268753, 22.420416],
								[114.268753, 22.419861],
								[114.268471, 22.419861],
								[114.268471, 22.419584],
								[114.268196, 22.419584],
								[114.268196, 22.419027],
								[114.268463, 22.419027],
								[114.268471, 22.418751],
								[114.269577, 22.418751],
								[114.269585, 22.419027],
								[114.270142, 22.419027],
								[114.270142, 22.417917],
								[114.270416, 22.417917],
								[114.270416, 22.417360],
								[114.270691, 22.417360],
								[114.270691, 22.417084],
								[114.270416, 22.417084],
								[114.270416, 22.416805],
								[114.270973, 22.416805],
								[114.270973, 22.416250],
								[114.271523, 22.416250],
								[114.271530, 22.415974],
								[114.272362, 22.415974],
								[114.272362, 22.415695],
								[114.272636, 22.415695],
								[114.272636, 22.415421],
								[114.272919, 22.415417],
								[114.272919, 22.415138],
								[114.273193, 22.415138],
								[114.273193, 22.414305],
								[114.273476, 22.414305],
								[114.273476, 22.414028],
								[114.273750, 22.414028],
								[114.273750, 22.413752],
								[114.274025, 22.413752],
								[114.274025, 22.413473],
								[114.274307, 22.413473],
								[114.274307, 22.413195],
								[114.274582, 22.413195],
								[114.274582, 22.412083],
								[114.274857, 22.412083],
								[114.274864, 22.410973],
								[114.275406, 22.410973],
								[114.275414, 22.412359],
								[114.275696, 22.412359],
								[114.275696, 22.413752],
								[114.275414, 22.413752],
								[114.275414, 22.414028],
								[114.275139, 22.414028],
								[114.275139, 22.414305],
								[114.274864, 22.414305],
								[114.274864, 22.415138],
								[114.275139, 22.415138],
								[114.275139, 22.416250],
								[114.275414, 22.416250],
								[114.275414, 22.416805],
								[114.275696, 22.416805],
								[114.275696, 22.418472],
								[114.275970, 22.418472],
								[114.275970, 22.419027],
								[114.276527, 22.419027],
								[114.276527, 22.419306],
								[114.276802, 22.419306],
								[114.276802, 22.419861],
								[114.277084, 22.419861],
								[114.277084, 22.420139],
								[114.277359, 22.420139],
								[114.277359, 22.420416],
								[114.278473, 22.420416],
								[114.278473, 22.420694],
								[114.279305, 22.420694],
								[114.279305, 22.420973],
								[114.279861, 22.420973],
								[114.279861, 22.421249],
								[114.280418, 22.421249],
								[114.280418, 22.421528],
								[114.280975, 22.421528],
								[114.280975, 22.421806],
								[114.281807, 22.421806],
								[114.281807, 22.422083],
								[114.282364, 22.422083],
								[114.282364, 22.422361],
								[114.282639, 22.422361],
								[114.282639, 22.422916],
								[114.282883, 22.422916],
								[114.282913, 22.423195],
								[114.283195, 22.423195],
								[114.283195, 22.423471],
								[114.283463, 22.423471],
								[114.283470, 22.423750],
								[114.283752, 22.423750],
								[114.283752, 22.424026],
								[114.284027, 22.424026],
								[114.284027, 22.424305],
								[114.284584, 22.424305],
								[114.284584, 22.424862],
								[114.285141, 22.424862],
								[114.285141, 22.425140],
								[114.285416, 22.425140],
								[114.285416, 22.425419],
								[114.286247, 22.425419],
								[114.286247, 22.425694],
								[114.286522, 22.425694],
								[114.286530, 22.426249],
								[114.287086, 22.426249],
								[114.287086, 22.426529],
								[114.287361, 22.426529],
								[114.287361, 22.426805],
								[114.287636, 22.426805],
								[114.287636, 22.427084],
								[114.287361, 22.427084],
								[114.287361, 22.427359],
								[114.287636, 22.427359],
								[114.287636, 22.427641],
								[114.287361, 22.427641],
								[114.287361, 22.428749],
								[114.286530, 22.428749],
								[114.286530, 22.429028],
								[114.285973, 22.429028],
								[114.285973, 22.428749],
								[114.285416, 22.428749],
								[114.285416, 22.428473],
								[114.284859, 22.428473],
								[114.284859, 22.429028],
								[114.285095, 22.429028],
								[114.285141, 22.430695],
								[114.284859, 22.430695],
								[114.284859, 22.430973],
								[114.284584, 22.430973],
								[114.284584, 22.431250],
								[114.284309, 22.431250],
								[114.284302, 22.431671],
								[114.284302, 22.431805],
								[114.283752, 22.431805],
								[114.283691, 22.432083],
								[114.283470, 22.432083],
								[114.283470, 22.432638],
								[114.283195, 22.432638],
								[114.283195, 22.435139],
								[114.282913, 22.435139],
								[114.282913, 22.435415],
								[114.282639, 22.435415],
								[114.282639, 22.435972],
								[114.282082, 22.435972],
								[114.282082, 22.436806],
								[114.281807, 22.436806],
								[114.281807, 22.437916],
								[114.281525, 22.437916],
								[114.281525, 22.438473],
								[114.281250, 22.438473],
								[114.281250, 22.439030],
								[114.280975, 22.439030],
								[114.280975, 22.440416],
								[114.281250, 22.440416],
								[114.281250, 22.440695],
								[114.281525, 22.440695],
								[114.281525, 22.440969],
								[114.281807, 22.440969],
								[114.281807, 22.441252],
								[114.282364, 22.441252],
								[114.282364, 22.441805],
								[114.282913, 22.441805],
								[114.282913, 22.442362],
								[114.283195, 22.442362],
								[114.283195, 22.442917],
								[114.280975, 22.442917],
								[114.280975, 22.443195],
								[114.280212, 22.443195],
								[114.280136, 22.443195],
								[114.280136, 22.443474],
								[114.279305, 22.443474],
								[114.279305, 22.443195],
								[114.278748, 22.443195],
								[114.278748, 22.442917],
								[114.278198, 22.442917],
								[114.278191, 22.442638],
								[114.277916, 22.442638],
								[114.277916, 22.442917],
								[114.277641, 22.442917],
								[114.277641, 22.443474],
								[114.277359, 22.443474],
								[114.277359, 22.443750],
								[114.277084, 22.443750],
								[114.277084, 22.445417],
								[114.278091, 22.445417],
								[114.278198, 22.445417],
								[114.278198, 22.445696],
								[114.278473, 22.445696],
								[114.278473, 22.445972],
								[114.279030, 22.445972],
								[114.279030, 22.446251],
								[114.279305, 22.446251],
								[114.279305, 22.446804],
								[114.279587, 22.446804],
								[114.279587, 22.447361],
								[114.280136, 22.447361],
								[114.280136, 22.447639],
								[114.280418, 22.447639],
								[114.280418, 22.448195],
								[114.280678, 22.448195],
								[114.280693, 22.448473],
								[114.280975, 22.448473],
								[114.280975, 22.449305],
								[114.281250, 22.449306],
								[114.281250, 22.449583],
								[114.281517, 22.449583],
								[114.281525, 22.450136],
								[114.282082, 22.450136],
								[114.282082, 22.450972],
								[114.282356, 22.450972],
								[114.282364, 22.451248],
								[114.282913, 22.451248],
								[114.282913, 22.451530],
								[114.283470, 22.451530],
								[114.283470, 22.451805],
								[114.284584, 22.451805],
								[114.284584, 22.452084],
								[114.284859, 22.452084],
								[114.284859, 22.452358],
								[114.285141, 22.452362],
								[114.285141, 22.453194],
								[114.285416, 22.453194],
								[114.285416, 22.453470],
								[114.285698, 22.453470],
								[114.285698, 22.453751],
								[114.286247, 22.453751],
								[114.286247, 22.454027],
								[114.287086, 22.454027],
								[114.287086, 22.453751],
								[114.287361, 22.453751],
								[114.287361, 22.453470],
								[114.287605, 22.453470],
								[114.287636, 22.453194],
								[114.288475, 22.453194],
								[114.288475, 22.452917],
								[114.289017, 22.452917],
								[114.289024, 22.452362],
								[114.289261, 22.452358],
								[114.289307, 22.451811],
								[114.289581, 22.451805],
								[114.289581, 22.450695],
								[114.289856, 22.450695],
								[114.289864, 22.450420],
								[114.291252, 22.450417],
								[114.291252, 22.450695],
								[114.291527, 22.450695],
								[114.291527, 22.452641],
								[114.291290, 22.452641],
								[114.291252, 22.453194],
								[114.291527, 22.453194],
								[114.291527, 22.455139],
								[114.291252, 22.455139],
								[114.291252, 22.455694],
								[114.291527, 22.455694],
								[114.291527, 22.457361],
								[114.291809, 22.457361],
								[114.291809, 22.457916],
								[114.291527, 22.457916],
								[114.291527, 22.458195],
								[114.291809, 22.458195],
								[114.291809, 22.458471],
								[114.291931, 22.458471],
								[114.292358, 22.458471],
								[114.292358, 22.458750],
								[114.292641, 22.458750],
								[114.292641, 22.458471],
								[114.293198, 22.458471],
								[114.293198, 22.458195],
								[114.293747, 22.458195],
								[114.293747, 22.457916],
								[114.294029, 22.457916],
								[114.294029, 22.457361],
								[114.294296, 22.457361],
								[114.294304, 22.457083],
								[114.294586, 22.457083],
								[114.294586, 22.456806],
								[114.294861, 22.456806],
								[114.294861, 22.456528],
								[114.295135, 22.456528],
								[114.295135, 22.456249],
								[114.295418, 22.456249],
								[114.295418, 22.455973],
								[114.295967, 22.455973],
								[114.295975, 22.456249],
								[114.297638, 22.456249],
								[114.297638, 22.456528],
								[114.299576, 22.456528],
								[114.299583, 22.455973],
								[114.299858, 22.455973],
								[114.299858, 22.454584],
								[114.300011, 22.454584],
								[114.301796, 22.454584],
								[114.301804, 22.455416],
								[114.302605, 22.455416],
								[114.302635, 22.455973],
								[114.302917, 22.455973],
								[114.302917, 22.457083],
								[114.303192, 22.457083],
								[114.303192, 22.459305],
								[114.302917, 22.459305],
								[114.302917, 22.460138],
								[114.302635, 22.460138],
								[114.302635, 22.461527],
								[114.302361, 22.461527],
								[114.302361, 22.462360],
								[114.302086, 22.462360],
								[114.302086, 22.462915],
								[114.302917, 22.462915],
								[114.302917, 22.463194],
								[114.304031, 22.463194],
								[114.304031, 22.463472],
								[114.304306, 22.463472],
								[114.304306, 22.463751],
								[114.304581, 22.463751],
								[114.304581, 22.465416],
								[114.304863, 22.465416],
								[114.304863, 22.466251],
								[114.305138, 22.466251],
								[114.305138, 22.467361],
								[114.305420, 22.467361],
								[114.305420, 22.467640],
								[114.305695, 22.467640],
								[114.305695, 22.468195],
								[114.305969, 22.468195],
								[114.305969, 22.468470],
								[114.306252, 22.468470],
								[114.306252, 22.468752],
								[114.306808, 22.468752],
								[114.306808, 22.469028],
								[114.307030, 22.469028],
								[114.307083, 22.469584],
								[114.307632, 22.469584],
								[114.307640, 22.470139],
								[114.307877, 22.470139],
								[114.307915, 22.470694],
								[114.308197, 22.470694],
								[114.308197, 22.470972],
								[114.308472, 22.470972],
								[114.308472, 22.471251],
								[114.308746, 22.471251],
								[114.308746, 22.471527],
								[114.309586, 22.471527],
								[114.309586, 22.471806],
								[114.310898, 22.471806],
								[114.310974, 22.471806],
								[114.310974, 22.472086],
								[114.311806, 22.472086],
								[114.311806, 22.472361],
								[114.312363, 22.472361],
								[114.312363, 22.472639],
								[114.312637, 22.472639],
								[114.312637, 22.472918],
								[114.313118, 22.472918],
								[114.313194, 22.472918],
								[114.313194, 22.473194],
								[114.313469, 22.473194],
								[114.313469, 22.473749],
								[114.314026, 22.473749],
								[114.314026, 22.474583],
								[114.314308, 22.474583],
								[114.314308, 22.475416],
								[114.314583, 22.475416],
								[114.314583, 22.475973],
								[114.315140, 22.475973],
								[114.315140, 22.476250],
								[114.315407, 22.476250],
								[114.315414, 22.476526],
								[114.315971, 22.476526],
								[114.315971, 22.476805],
								[114.316528, 22.476805],
								[114.316528, 22.477083],
								[114.316864, 22.477083],
								[114.320137, 22.477083],
								[114.320137, 22.477636],
								[114.320419, 22.477636],
								[114.320419, 22.479305],
								[114.320694, 22.479305],
								[114.320694, 22.480141],
								[114.321251, 22.480141],
								[114.321251, 22.480417],
								[114.322357, 22.480417]
							]
						],
						[
							[
								[114.331253, 22.480972],
								[114.331253, 22.480694],
								[114.331802, 22.480694],
								[114.331802, 22.480417],
								[114.332085, 22.480417],
								[114.332085, 22.477636],
								[114.331802, 22.477636],
								[114.331802, 22.476805],
								[114.331253, 22.476805],
								[114.331253, 22.476526],
								[114.330696, 22.476526],
								[114.330696, 22.477083],
								[114.330414, 22.477083],
								[114.330414, 22.480141],
								[114.330696, 22.480141],
								[114.330696, 22.480972],
								[114.331253, 22.480972]
							]
						],
						[
							[
								[114.371529, 22.484028],
								[114.371529, 22.483194],
								[114.372643, 22.483194],
								[114.372643, 22.482363],
								[114.371803, 22.482363],
								[114.371803, 22.482639],
								[114.370972, 22.482639],
								[114.370972, 22.482916],
								[114.370148, 22.482916],
								[114.370140, 22.483473],
								[114.369858, 22.483473],
								[114.369858, 22.484028],
								[114.371529, 22.484028]
							]
						],
						[
							[
								[114.359863, 22.486528],
								[114.359863, 22.486250],
								[114.360970, 22.486250],
								[114.360970, 22.485971],
								[114.362083, 22.485971],
								[114.362083, 22.485695],
								[114.362915, 22.485695],
								[114.362915, 22.485416],
								[114.363472, 22.485416],
								[114.363472, 22.485140],
								[114.364029, 22.485138],
								[114.364029, 22.484585],
								[114.364304, 22.484585],
								[114.364304, 22.484028],
								[114.364586, 22.484028],
								[114.364586, 22.483473],
								[114.364861, 22.483473],
								[114.364861, 22.482916],
								[114.365135, 22.482916],
								[114.365135, 22.482084],
								[114.364861, 22.482080],
								[114.364861, 22.481253],
								[114.364586, 22.481253],
								[114.364586, 22.480972],
								[114.364304, 22.480972],
								[114.364304, 22.480694],
								[114.364029, 22.480694],
								[114.364029, 22.479305],
								[114.364304, 22.479305],
								[114.364304, 22.479031],
								[114.364586, 22.479031],
								[114.364586, 22.478748],
								[114.364861, 22.478748],
								[114.364861, 22.478472],
								[114.365417, 22.478472],
								[114.365417, 22.478195],
								[114.365692, 22.478195],
								[114.365692, 22.477917],
								[114.365974, 22.477917],
								[114.365974, 22.477636],
								[114.366249, 22.477636],
								[114.366249, 22.477083],
								[114.366531, 22.477083],
								[114.366531, 22.476805],
								[114.366806, 22.476805],
								[114.366806, 22.476250],
								[114.367081, 22.476250],
								[114.367081, 22.475973],
								[114.367363, 22.475973],
								[114.367363, 22.475695],
								[114.367638, 22.475695],
								[114.367638, 22.475140],
								[114.367920, 22.475140],
								[114.367920, 22.474861],
								[114.367638, 22.474861],
								[114.367638, 22.474304],
								[114.367363, 22.474304],
								[114.367363, 22.474028],
								[114.367081, 22.474028],
								[114.367081, 22.473473],
								[114.366806, 22.473473],
								[114.366806, 22.472864],
								[114.366806, 22.472639],
								[114.366531, 22.472639],
								[114.366531, 22.472265],
								[114.366531, 22.472086],
								[114.365974, 22.472086],
								[114.365974, 22.471806],
								[114.365692, 22.471806],
								[114.365692, 22.471527],
								[114.365135, 22.471527],
								[114.365135, 22.471251],
								[114.364029, 22.471251],
								[114.364029, 22.470694],
								[114.363747, 22.470694],
								[114.363747, 22.469584],
								[114.364029, 22.469584],
								[114.364029, 22.469305],
								[114.364304, 22.469305],
								[114.364304, 22.469028],
								[114.364586, 22.469028],
								[114.364586, 22.468752],
								[114.364304, 22.468752],
								[114.364304, 22.467916],
								[114.364029, 22.467916],
								[114.364029, 22.467081],
								[114.363472, 22.467081],
								[114.363472, 22.466806],
								[114.362640, 22.466806],
								[114.362640, 22.466530],
								[114.362358, 22.466530],
								[114.362358, 22.465694],
								[114.362083, 22.465694],
								[114.362083, 22.465416],
								[114.361809, 22.465416],
								[114.361809, 22.465137],
								[114.361526, 22.465137],
								[114.361526, 22.464861],
								[114.360138, 22.464861],
								[114.360138, 22.465137],
								[114.359512, 22.465137],
								[114.359306, 22.465137],
								[114.359306, 22.465416],
								[114.358749, 22.465416],
								[114.358749, 22.465694],
								[114.358192, 22.465694],
								[114.358192, 22.466251],
								[114.357918, 22.466251],
								[114.357918, 22.467081],
								[114.358192, 22.467081],
								[114.358192, 22.470139],
								[114.358475, 22.470139],
								[114.358475, 22.470694],
								[114.358749, 22.470694],
								[114.358749, 22.471527],
								[114.358582, 22.471527],
								[114.357635, 22.471527],
								[114.357635, 22.474583],
								[114.357918, 22.474583],
								[114.357918, 22.474861],
								[114.357635, 22.474861],
								[114.357635, 22.475140],
								[114.357361, 22.475140],
								[114.357361, 22.475416],
								[114.356804, 22.475416],
								[114.356804, 22.475973],
								[114.355698, 22.475973],
								[114.355698, 22.475416],
								[114.355415, 22.475416],
								[114.355415, 22.475695],
								[114.354858, 22.475695],
								[114.354858, 22.476250],
								[114.354584, 22.476250],
								[114.354584, 22.476526],
								[114.354309, 22.476526],
								[114.354309, 22.477636],
								[114.354027, 22.477636],
								[114.354027, 22.479031],
								[114.353752, 22.479031],
								[114.353752, 22.479858],
								[114.353470, 22.479858],
								[114.353470, 22.480141],
								[114.353195, 22.480141],
								[114.353195, 22.480694],
								[114.353470, 22.480694],
								[114.353470, 22.481527],
								[114.354584, 22.481527],
								[114.354584, 22.482084],
								[114.354858, 22.482084],
								[114.354858, 22.482363],
								[114.355415, 22.482363],
								[114.355415, 22.482916],
								[114.356247, 22.482916],
								[114.356247, 22.483194],
								[114.356804, 22.483194],
								[114.356804, 22.483749],
								[114.357086, 22.483749],
								[114.357086, 22.484306],
								[114.357635, 22.484306],
								[114.357635, 22.485138],
								[114.357918, 22.485140],
								[114.357918, 22.485416],
								[114.358475, 22.485416],
								[114.358475, 22.485971],
								[114.358749, 22.485971],
								[114.358749, 22.486250],
								[114.359306, 22.486250],
								[114.359306, 22.486528],
								[114.359863, 22.486528]
							]
						],
						[
							[
								[114.359306, 22.505138],
								[114.359306, 22.504862],
								[114.360695, 22.504862],
								[114.360695, 22.504583],
								[114.361252, 22.504583],
								[114.361252, 22.504305],
								[114.362640, 22.504305],
								[114.362640, 22.504026],
								[114.363472, 22.504026],
								[114.363472, 22.504305],
								[114.364029, 22.504305],
								[114.364029, 22.504583],
								[114.364304, 22.504583],
								[114.364304, 22.504862],
								[114.364861, 22.504862],
								[114.364861, 22.504583],
								[114.365135, 22.504583],
								[114.365135, 22.503471],
								[114.364861, 22.503471],
								[114.364861, 22.503195],
								[114.364586, 22.503195],
								[114.364586, 22.502916],
								[114.364029, 22.502916],
								[114.364029, 22.502640],
								[114.363197, 22.502640],
								[114.363197, 22.502361],
								[114.362915, 22.502361],
								[114.362915, 22.502083],
								[114.362358, 22.502083],
								[114.362358, 22.500416],
								[114.361809, 22.500416],
								[114.361809, 22.500141],
								[114.361526, 22.500139],
								[114.361526, 22.499861],
								[114.361252, 22.499861],
								[114.361252, 22.496531],
								[114.360970, 22.496529],
								[114.360970, 22.496250],
								[114.360695, 22.496250],
								[114.360695, 22.495974],
								[114.359306, 22.495974],
								[114.359306, 22.496250],
								[114.358475, 22.496250],
								[114.358475, 22.495974],
								[114.357635, 22.495974],
								[114.357635, 22.496250],
								[114.357361, 22.496250],
								[114.357361, 22.496529],
								[114.357086, 22.496529],
								[114.357086, 22.496805],
								[114.357361, 22.496805],
								[114.357361, 22.497084],
								[114.357635, 22.497084],
								[114.357635, 22.497639],
								[114.357361, 22.497639],
								[114.357361, 22.497917],
								[114.357086, 22.497917],
								[114.357086, 22.498194],
								[114.356804, 22.498194],
								[114.356804, 22.498472],
								[114.356247, 22.498472],
								[114.356247, 22.499306],
								[114.355713, 22.499306],
								[114.355637, 22.499584],
								[114.355141, 22.499584],
								[114.355141, 22.500416],
								[114.355408, 22.500416],
								[114.355415, 22.500694],
								[114.355972, 22.500694],
								[114.355972, 22.500973],
								[114.357086, 22.500973],
								[114.357086, 22.502083],
								[114.356804, 22.502083],
								[114.356804, 22.502361],
								[114.357086, 22.502361],
								[114.357086, 22.502640],
								[114.356529, 22.502640],
								[114.356529, 22.502916],
								[114.356247, 22.502916],
								[114.356247, 22.503750],
								[114.356804, 22.503750],
								[114.356804, 22.504026],
								[114.357918, 22.504026],
								[114.357918, 22.504305],
								[114.358475, 22.504305],
								[114.358475, 22.504583],
								[114.358742, 22.504583],
								[114.358749, 22.505138],
								[114.359306, 22.505138]
							]
						],
						[
							[
								[114.334587, 22.510975],
								[114.334587, 22.510695],
								[114.335136, 22.510695],
								[114.335136, 22.510416],
								[114.335419, 22.510416],
								[114.335419, 22.510139],
								[114.335976, 22.510139],
								[114.335976, 22.509863],
								[114.336525, 22.509863],
								[114.336525, 22.508753],
								[114.336807, 22.508753],
								[114.336807, 22.508354],
								[114.336807, 22.507896],
								[114.336807, 22.507641],
								[114.336525, 22.507641],
								[114.336525, 22.507359],
								[114.336250, 22.507359],
								[114.336250, 22.507084],
								[114.335976, 22.507084],
								[114.335976, 22.506805],
								[114.335625, 22.506805],
								[114.335419, 22.506805],
								[114.335419, 22.506632],
								[114.335419, 22.506527],
								[114.335297, 22.506527],
								[114.335136, 22.506527],
								[114.335136, 22.506392],
								[114.335136, 22.506248],
								[114.334976, 22.506248],
								[114.334862, 22.506248],
								[114.334862, 22.505972],
								[114.334587, 22.505972],
								[114.334587, 22.505695],
								[114.334305, 22.505695],
								[114.334305, 22.505419],
								[114.334000, 22.505419],
								[114.333748, 22.505419],
								[114.333748, 22.505199],
								[114.333473, 22.505138],
								[114.333473, 22.504965],
								[114.333473, 22.504862],
								[114.333351, 22.504862],
								[114.333191, 22.504862],
								[114.333191, 22.504583],
								[114.332642, 22.504583],
								[114.332642, 22.504305],
								[114.332359, 22.504305],
								[114.332359, 22.504026],
								[114.331802, 22.504026],
								[114.331802, 22.503750],
								[114.331528, 22.503750],
								[114.331528, 22.503471],
								[114.331253, 22.503471],
								[114.331253, 22.503195],
								[114.330971, 22.503195],
								[114.330971, 22.502916],
								[114.329865, 22.502916],
								[114.329865, 22.502640],
								[114.329582, 22.502640],
								[114.329582, 22.502361],
								[114.329308, 22.502361],
								[114.329308, 22.502083],
								[114.329025, 22.502083],
								[114.329025, 22.501806],
								[114.328751, 22.501806],
								[114.328751, 22.501528],
								[114.328194, 22.501528],
								[114.328194, 22.501249],
								[114.327919, 22.501249],
								[114.327919, 22.500973],
								[114.327637, 22.500973],
								[114.327637, 22.500694],
								[114.327362, 22.500694],
								[114.327362, 22.500416],
								[114.327080, 22.500416],
								[114.327080, 22.500139],
								[114.326530, 22.500139],
								[114.326530, 22.499861],
								[114.325417, 22.499861],
								[114.325417, 22.499584],
								[114.325142, 22.499584],
								[114.325142, 22.499306],
								[114.324860, 22.499306],
								[114.324860, 22.498751],
								[114.324585, 22.498751],
								[114.324585, 22.498472],
								[114.324303, 22.498472],
								[114.324303, 22.498194],
								[114.324028, 22.498194],
								[114.324028, 22.498074],
								[114.324028, 22.497917],
								[114.323753, 22.497917],
								[114.323753, 22.497639],
								[114.323471, 22.497639],
								[114.323471, 22.497084],
								[114.323196, 22.497084],
								[114.323196, 22.496805],
								[114.322639, 22.496805],
								[114.322639, 22.496529],
								[114.322365, 22.496529],
								[114.322365, 22.496250],
								[114.322083, 22.496250],
								[114.322083, 22.495695],
								[114.321808, 22.495695],
								[114.321808, 22.495417],
								[114.321526, 22.495417],
								[114.321526, 22.495138],
								[114.321251, 22.495138],
								[114.321251, 22.494862],
								[114.320694, 22.494862],
								[114.320694, 22.494583],
								[114.320419, 22.494583],
								[114.320419, 22.494305],
								[114.319862, 22.494305],
								[114.319862, 22.493473],
								[114.319580, 22.493473],
								[114.319580, 22.492916],
								[114.319305, 22.492916],
								[114.319305, 22.492638],
								[114.319031, 22.492638],
								[114.319031, 22.492359],
								[114.318748, 22.492359],
								[114.318748, 22.492083],
								[114.318474, 22.492083],
								[114.318474, 22.491808],
								[114.318192, 22.491808],
								[114.318192, 22.491625],
								[114.318192, 22.491528],
								[114.317085, 22.491528],
								[114.317085, 22.491247],
								[114.316803, 22.491247],
								[114.316803, 22.491070],
								[114.316803, 22.490973],
								[114.316559, 22.490973],
								[114.316528, 22.490694],
								[114.316254, 22.490694],
								[114.316254, 22.490137],
								[114.315971, 22.490137],
								[114.315971, 22.489584],
								[114.315697, 22.489584],
								[114.315697, 22.489305],
								[114.315414, 22.489305],
								[114.315414, 22.489025],
								[114.315140, 22.489025],
								[114.315140, 22.488750],
								[114.314583, 22.488750],
								[114.314583, 22.488472],
								[114.314026, 22.488472],
								[114.314026, 22.488195],
								[114.313469, 22.488194],
								[114.313469, 22.487917],
								[114.313194, 22.487917],
								[114.313194, 22.487638],
								[114.312363, 22.487638],
								[114.312363, 22.487917],
								[114.312080, 22.487917],
								[114.312080, 22.488194],
								[114.311531, 22.488195],
								[114.311531, 22.488472],
								[114.310661, 22.488472],
								[114.310425, 22.488472],
								[114.309586, 22.488472],
								[114.309586, 22.488195],
								[114.309029, 22.488194],
								[114.309029, 22.487917],
								[114.308746, 22.487917],
								[114.308746, 22.487362],
								[114.308197, 22.487362],
								[114.308197, 22.486528],
								[114.307915, 22.486528],
								[114.307915, 22.486250],
								[114.308197, 22.486250],
								[114.308197, 22.485971],
								[114.308472, 22.485971],
								[114.308472, 22.485695],
								[114.309029, 22.485695],
								[114.309029, 22.485416],
								[114.309303, 22.485416],
								[114.309303, 22.484585],
								[114.309029, 22.484585],
								[114.309029, 22.484306],
								[114.308472, 22.484306],
								[114.308472, 22.484028],
								[114.307915, 22.484028],
								[114.307915, 22.483473],
								[114.307640, 22.483473],
								[114.307640, 22.483364],
								[114.307640, 22.483194],
								[114.307083, 22.483194],
								[114.307083, 22.482916],
								[114.306526, 22.482916],
								[114.306526, 22.482639],
								[114.306252, 22.482639],
								[114.306252, 22.482363],
								[114.305695, 22.482363],
								[114.305695, 22.482084],
								[114.305138, 22.482084],
								[114.305138, 22.482363],
								[114.304863, 22.482363],
								[114.304863, 22.482176],
								[114.304863, 22.482084],
								[114.303749, 22.482084],
								[114.303749, 22.481806],
								[114.303474, 22.481806],
								[114.303474, 22.481527],
								[114.302917, 22.481527],
								[114.302917, 22.481253],
								[114.302361, 22.481253],
								[114.302361, 22.480972],
								[114.301804, 22.480972],
								[114.301804, 22.480694],
								[114.301247, 22.480694],
								[114.301247, 22.480417],
								[114.300697, 22.480417],
								[114.300697, 22.480141],
								[114.300415, 22.480141],
								[114.300415, 22.479996],
								[114.300415, 22.479858],
								[114.300140, 22.479858],
								[114.300140, 22.479584],
								[114.299858, 22.479584],
								[114.299858, 22.479305],
								[114.299026, 22.479305],
								[114.299026, 22.479031],
								[114.297081, 22.479031],
								[114.297081, 22.478748],
								[114.296524, 22.478748],
								[114.296524, 22.478472],
								[114.295975, 22.478472],
								[114.295975, 22.478748],
								[114.295418, 22.478748],
								[114.295418, 22.478472],
								[114.295029, 22.478472],
								[114.294861, 22.478472],
								[114.294861, 22.478251],
								[114.294586, 22.478195],
								[114.294586, 22.477917],
								[114.294304, 22.477917],
								[114.294304, 22.477604],
								[114.294304, 22.477362],
								[114.294029, 22.477362],
								[114.294029, 22.476805],
								[114.293747, 22.476805],
								[114.293747, 22.476526],
								[114.293472, 22.476526],
								[114.293472, 22.475973],
								[114.292915, 22.475973],
								[114.292915, 22.475695],
								[114.292358, 22.475695],
								[114.292358, 22.475416],
								[114.291809, 22.475416],
								[114.291809, 22.475140],
								[114.290413, 22.475140],
								[114.290413, 22.474583],
								[114.289864, 22.474583],
								[114.289864, 22.474304],
								[114.288750, 22.474304],
								[114.288750, 22.474028],
								[114.288475, 22.474028],
								[114.288475, 22.474304],
								[114.288193, 22.474304],
								[114.288193, 22.474028],
								[114.287636, 22.474028],
								[114.287636, 22.473749],
								[114.286804, 22.473749],
								[114.286804, 22.473473],
								[114.285141, 22.473473],
								[114.285141, 22.473194],
								[114.284843, 22.473194],
								[114.284584, 22.473194],
								[114.284584, 22.472935],
								[114.284027, 22.472918],
								[114.284027, 22.472761],
								[114.284027, 22.472361],
								[114.283752, 22.472361],
								[114.283752, 22.472086],
								[114.283195, 22.472086],
								[114.283195, 22.471806],
								[114.282082, 22.471806],
								[114.282082, 22.471527],
								[114.281807, 22.471527],
								[114.281807, 22.471251],
								[114.281250, 22.471251],
								[114.281250, 22.470972],
								[114.280693, 22.470972],
								[114.280693, 22.470694],
								[114.280136, 22.470694],
								[114.280136, 22.470417],
								[114.278198, 22.470417],
								[114.278198, 22.470694],
								[114.277641, 22.470694],
								[114.277641, 22.470972],
								[114.276253, 22.470972],
								[114.276253, 22.471251],
								[114.275139, 22.471251],
								[114.275139, 22.470972],
								[114.274582, 22.470972],
								[114.274582, 22.470694],
								[114.273476, 22.470694],
								[114.273476, 22.470417],
								[114.273193, 22.470417],
								[114.273193, 22.470139],
								[114.272919, 22.470139],
								[114.272919, 22.469584],
								[114.272636, 22.469584],
								[114.272636, 22.469305],
								[114.272362, 22.469305],
								[114.272362, 22.469028],
								[114.272087, 22.469028],
								[114.272087, 22.468752],
								[114.272919, 22.468752],
								[114.272919, 22.468470],
								[114.274025, 22.468470],
								[114.274025, 22.468752],
								[114.274307, 22.468752],
								[114.274307, 22.468195],
								[114.274025, 22.468195],
								[114.274025, 22.467640],
								[114.273750, 22.467640],
								[114.273750, 22.467361],
								[114.273476, 22.467361],
								[114.273476, 22.467081],
								[114.272919, 22.467081],
								[114.272919, 22.466806],
								[114.272362, 22.466806],
								[114.272362, 22.466530],
								[114.271805, 22.466530],
								[114.271805, 22.466251],
								[114.270973, 22.466251],
								[114.270973, 22.465973],
								[114.270691, 22.465973],
								[114.270691, 22.465694],
								[114.269859, 22.465694],
								[114.269859, 22.465416],
								[114.269302, 22.465416],
								[114.269302, 22.465137],
								[114.268471, 22.465137],
								[114.268471, 22.464861],
								[114.267914, 22.464861],
								[114.267914, 22.464582],
								[114.267365, 22.464582],
								[114.267365, 22.464306],
								[114.267082, 22.464306],
								[114.267082, 22.463751],
								[114.266525, 22.463751],
								[114.266525, 22.463194],
								[114.265976, 22.463194],
								[114.265976, 22.462915],
								[114.265694, 22.462915],
								[114.265694, 22.462639],
								[114.264580, 22.462639],
								[114.264580, 22.462360],
								[114.264030, 22.462360],
								[114.264030, 22.462084],
								[114.263474, 22.462084],
								[114.263474, 22.461805],
								[114.263191, 22.461805],
								[114.263191, 22.461527],
								[114.262642, 22.461527],
								[114.262642, 22.460972],
								[114.262344, 22.460972],
								[114.262085, 22.460972],
								[114.262085, 22.460695],
								[114.261803, 22.460695],
								[114.261803, 22.460138],
								[114.261253, 22.460138],
								[114.261253, 22.459583],
								[114.260971, 22.459583],
								[114.260971, 22.459028],
								[114.260696, 22.459028],
								[114.260696, 22.458750],
								[114.260414, 22.458750],
								[114.260414, 22.458195],
								[114.260139, 22.458195],
								[114.260139, 22.457916],
								[114.259865, 22.457916],
								[114.259865, 22.457640],
								[114.259583, 22.457640],
								[114.259583, 22.457361],
								[114.259308, 22.457361],
								[114.259308, 22.456806],
								[114.259026, 22.456806],
								[114.259026, 22.456528],
								[114.258751, 22.456528],
								[114.258751, 22.455973],
								[114.258469, 22.455973],
								[114.258469, 22.455694],
								[114.258194, 22.455694],
								[114.258194, 22.455578],
								[114.258194, 22.455418],
								[114.257858, 22.455416],
								[114.257637, 22.455416],
								[114.257637, 22.455139],
								[114.257362, 22.455139],
								[114.257362, 22.454863],
								[114.257080, 22.454863],
								[114.257080, 22.454306],
								[114.256531, 22.454306],
								[114.256531, 22.454145],
								[114.256531, 22.454027],
								[114.256248, 22.454027],
								[114.256248, 22.453751],
								[114.255692, 22.453751],
								[114.255692, 22.453470],
								[114.255417, 22.453470],
								[114.255417, 22.453194],
								[114.254860, 22.453194],
								[114.254860, 22.452917],
								[114.254585, 22.452917],
								[114.254585, 22.452641],
								[114.254303, 22.452641],
								[114.254303, 22.453194],
								[114.254028, 22.453194],
								[114.254028, 22.453470],
								[114.253754, 22.453470],
								[114.253754, 22.453751],
								[114.252785, 22.453751],
								[114.252083, 22.453751],
								[114.252083, 22.453470],
								[114.251228, 22.453470],
								[114.250969, 22.453470],
								[114.250969, 22.453342],
								[114.250969, 22.453194],
								[114.250694, 22.453194],
								[114.250694, 22.452362],
								[114.249863, 22.452362],
								[114.249863, 22.452084],
								[114.249580, 22.452084],
								[114.249580, 22.452362],
								[114.249306, 22.452362],
								[114.249306, 22.452084],
								[114.249031, 22.452084],
								[114.249031, 22.451805],
								[114.248474, 22.451805],
								[114.248474, 22.451530],
								[114.248192, 22.451530],
								[114.248192, 22.451248],
								[114.247917, 22.451248],
								[114.247917, 22.450972],
								[114.247643, 22.450972],
								[114.247643, 22.450695],
								[114.247360, 22.450695],
								[114.247360, 22.450136],
								[114.247086, 22.450136],
								[114.247086, 22.449862],
								[114.246529, 22.449862],
								[114.246529, 22.451248],
								[114.246803, 22.451248],
								[114.246803, 22.451805],
								[114.246529, 22.451805],
								[114.246529, 22.453751],
								[114.246803, 22.453751],
								[114.246803, 22.454027],
								[114.246529, 22.454027],
								[114.246529, 22.454306],
								[114.247360, 22.454306],
								[114.247360, 22.454407],
								[114.247360, 22.455139],
								[114.247086, 22.455139],
								[114.247086, 22.455416],
								[114.246803, 22.455418],
								[114.246803, 22.455694],
								[114.246529, 22.455694],
								[114.246529, 22.455973],
								[114.246246, 22.455973],
								[114.246246, 22.456249],
								[114.245972, 22.456249],
								[114.245972, 22.456528],
								[114.245415, 22.456528],
								[114.245415, 22.456806],
								[114.245140, 22.456806],
								[114.245140, 22.457083],
								[114.244858, 22.457083],
								[114.244858, 22.457361],
								[114.244583, 22.457361],
								[114.244583, 22.457640],
								[114.244308, 22.457640],
								[114.244308, 22.457916],
								[114.244026, 22.457916],
								[114.244026, 22.458195],
								[114.243752, 22.458195],
								[114.243752, 22.458471],
								[114.243469, 22.458471],
								[114.243469, 22.458750],
								[114.243195, 22.458750],
								[114.243195, 22.459028],
								[114.242920, 22.459028],
								[114.242920, 22.459305],
								[114.242638, 22.459305],
								[114.242638, 22.459583],
								[114.242363, 22.459583],
								[114.242363, 22.459862],
								[114.242081, 22.459862],
								[114.242081, 22.460138],
								[114.241806, 22.460138],
								[114.241806, 22.460417],
								[114.241531, 22.460417],
								[114.241531, 22.460695],
								[114.241249, 22.460695],
								[114.241249, 22.460972],
								[114.240974, 22.460972],
								[114.240974, 22.461250],
								[114.240692, 22.461250],
								[114.240692, 22.461527],
								[114.240417, 22.461527],
								[114.240417, 22.461805],
								[114.240135, 22.461805],
								[114.240135, 22.462084],
								[114.239861, 22.462084],
								[114.239861, 22.462360],
								[114.239586, 22.462360],
								[114.239586, 22.462639],
								[114.239304, 22.462639],
								[114.239304, 22.462915],
								[114.239029, 22.462915],
								[114.239029, 22.463194],
								[114.238747, 22.463194],
								[114.238747, 22.463472],
								[114.238472, 22.463472],
								[114.238472, 22.463751],
								[114.238197, 22.463751],
								[114.238197, 22.464027],
								[114.237915, 22.464027],
								[114.237915, 22.464306],
								[114.237640, 22.464306],
								[114.237640, 22.464582],
								[114.237358, 22.464582],
								[114.237358, 22.464861],
								[114.237083, 22.464861],
								[114.237083, 22.465137],
								[114.236526, 22.465137],
								[114.236526, 22.465416],
								[114.236252, 22.465416],
								[114.236252, 22.465694],
								[114.235970, 22.465694],
								[114.235970, 22.465973],
								[114.235695, 22.465973],
								[114.235695, 22.466251],
								[114.235420, 22.466251],
								[114.235420, 22.466530],
								[114.235138, 22.466530],
								[114.235138, 22.466806],
								[114.234863, 22.466806],
								[114.234863, 22.467081],
								[114.234581, 22.467081],
								[114.234581, 22.467361],
								[114.234398, 22.467361],
								[114.234306, 22.467361],
								[114.234306, 22.467640],
								[114.233932, 22.467640],
								[114.232918, 22.467640],
								[114.232918, 22.467916],
								[114.232635, 22.467916],
								[114.232635, 22.468195],
								[114.232361, 22.468195],
								[114.232361, 22.468582],
								[114.232361, 22.470139],
								[114.232086, 22.470139],
								[114.232086, 22.470694],
								[114.231804, 22.470694],
								[114.231804, 22.471251],
								[114.231247, 22.471251],
								[114.231247, 22.471527],
								[114.230972, 22.471527],
								[114.230972, 22.471806],
								[114.230698, 22.471806],
								[114.230698, 22.471527],
								[114.230415, 22.471527],
								[114.230415, 22.471806],
								[114.229584, 22.471806],
								[114.229584, 22.471636],
								[114.229584, 22.471527],
								[114.228470, 22.471527],
								[114.228470, 22.471251],
								[114.227638, 22.471251],
								[114.227638, 22.470972],
								[114.226807, 22.470972],
								[114.226807, 22.470694],
								[114.225975, 22.470694],
								[114.225975, 22.470417],
								[114.225693, 22.470417],
								[114.225693, 22.470139],
								[114.225418, 22.470139],
								[114.225418, 22.469864],
								[114.224861, 22.469864],
								[114.224861, 22.469305],
								[114.224586, 22.469305],
								[114.224586, 22.469028],
								[114.224304, 22.469028],
								[114.224304, 22.468752],
								[114.224030, 22.468752],
								[114.224030, 22.468470],
								[114.223747, 22.468470],
								[114.223747, 22.468195],
								[114.222916, 22.468195],
								[114.222916, 22.467916],
								[114.221527, 22.467916],
								[114.221527, 22.467640],
								[114.219864, 22.467640],
								[114.219864, 22.467361],
								[114.219582, 22.467361],
								[114.219582, 22.467640],
								[114.219307, 22.467640],
								[114.219307, 22.467361],
								[114.218750, 22.467361],
								[114.218750, 22.467640],
								[114.218193, 22.467640],
								[114.218193, 22.467916],
								[114.217918, 22.467916],
								[114.217918, 22.467640],
								[114.217087, 22.467640],
								[114.217087, 22.469028],
								[114.216805, 22.469028],
								[114.216805, 22.470417],
								[114.216530, 22.470417],
								[114.216530, 22.470942],
								[114.216530, 22.471251],
								[114.216248, 22.471251],
								[114.216248, 22.471527],
								[114.215820, 22.471527],
								[114.215698, 22.471527],
								[114.215698, 22.472086],
								[114.215126, 22.472086],
								[114.213753, 22.472086],
								[114.213753, 22.471527],
								[114.213470, 22.471527],
								[114.213470, 22.470972],
								[114.213196, 22.470972],
								[114.213196, 22.470694],
								[114.212914, 22.470694],
								[114.212914, 22.470139],
								[114.212082, 22.470139],
								[114.212082, 22.469864],
								[114.211525, 22.469864],
								[114.211525, 22.469584],
								[114.211250, 22.469584],
								[114.211250, 22.469305],
								[114.210693, 22.469305],
								[114.210693, 22.468752],
								[114.210419, 22.468752],
								[114.210419, 22.467916],
								[114.210136, 22.467916],
								[114.210136, 22.467640],
								[114.209862, 22.467640],
								[114.209862, 22.467361],
								[114.209587, 22.467361],
								[114.209587, 22.466530],
								[114.209305, 22.466530],
								[114.209305, 22.464861],
								[114.209587, 22.464861],
								[114.209587, 22.464582],
								[114.209862, 22.464582],
								[114.209862, 22.464306],
								[114.209587, 22.464306],
								[114.209587, 22.463472],
								[114.209305, 22.463472],
								[114.209305, 22.462639],
								[114.209030, 22.462639],
								[114.209030, 22.461527],
								[114.208748, 22.461527],
								[114.208748, 22.459862],
								[114.209030, 22.459862],
								[114.209030, 22.459305],
								[114.209305, 22.459305],
								[114.209305, 22.459028],
								[114.209862, 22.459028],
								[114.209862, 22.458750],
								[114.210693, 22.458750],
								[114.210693, 22.458471],
								[114.211250, 22.458471],
								[114.211250, 22.458195],
								[114.212082, 22.458195],
								[114.212082, 22.457916],
								[114.213470, 22.457916],
								[114.213470, 22.458195],
								[114.213623, 22.458195],
								[114.214584, 22.458195],
								[114.214584, 22.457640],
								[114.215141, 22.457640],
								[114.215141, 22.457361],
								[114.216248, 22.457361],
								[114.216248, 22.457640],
								[114.217087, 22.457640],
								[114.217087, 22.457361],
								[114.218750, 22.457361],
								[114.218750, 22.457083],
								[114.219025, 22.457083],
								[114.219025, 22.456806],
								[114.219307, 22.456806],
								[114.219307, 22.456528],
								[114.219582, 22.456528],
								[114.219582, 22.456249],
								[114.219864, 22.456249],
								[114.219864, 22.455973],
								[114.220139, 22.455973],
								[114.220139, 22.455694],
								[114.220413, 22.455694],
								[114.220413, 22.455416],
								[114.220695, 22.455416],
								[114.220695, 22.455139],
								[114.221252, 22.455139],
								[114.221252, 22.454863],
								[114.222084, 22.454863],
								[114.222084, 22.454584],
								[114.223198, 22.454584],
								[114.223198, 22.454863],
								[114.224304, 22.454863],
								[114.224304, 22.453470],
								[114.223198, 22.453470],
								[114.223198, 22.453194],
								[114.221802, 22.453194],
								[114.221802, 22.452917],
								[114.220139, 22.452917],
								[114.220139, 22.453194],
								[114.219307, 22.453194],
								[114.219307, 22.453470],
								[114.218750, 22.453470],
								[114.218750, 22.455139],
								[114.218475, 22.455139],
								[114.218475, 22.455694],
								[114.217636, 22.455694],
								[114.217636, 22.455973],
								[114.217361, 22.455973],
								[114.217361, 22.455694],
								[114.216805, 22.455694],
								[114.216805, 22.455418],
								[114.216530, 22.455416],
								[114.216530, 22.454863],
								[114.216248, 22.454863],
								[114.216248, 22.454584],
								[114.215973, 22.454584],
								[114.215973, 22.454306],
								[114.215698, 22.454306],
								[114.215691, 22.454027],
								[114.215446, 22.454027],
								[114.215141, 22.454027],
								[114.215141, 22.453470],
								[114.215416, 22.453470],
								[114.215416, 22.453194],
								[114.215691, 22.453194],
								[114.215698, 22.452917],
								[114.216248, 22.452917],
								[114.216248, 22.452641],
								[114.216530, 22.452641],
								[114.216530, 22.452414],
								[114.216797, 22.452396],
								[114.217361, 22.452358],
								[114.217361, 22.452248],
								[114.217361, 22.452084],
								[114.217918, 22.452084],
								[114.217918, 22.451805],
								[114.218750, 22.451805],
								[114.218750, 22.451530],
								[114.219307, 22.451530],
								[114.219307, 22.451248],
								[114.219978, 22.451248],
								[114.220139, 22.451248],
								[114.220139, 22.450979],
								[114.220695, 22.450972],
								[114.220695, 22.450695],
								[114.221527, 22.450695],
								[114.221527, 22.450417],
								[114.221802, 22.450417],
								[114.221802, 22.449862],
								[114.222084, 22.449862],
								[114.222084, 22.449583],
								[114.221527, 22.449583],
								[114.221527, 22.449026],
								[114.221252, 22.449026],
								[114.221252, 22.448750],
								[114.220970, 22.448750],
								[114.220970, 22.448473],
								[114.220695, 22.448473],
								[114.220695, 22.448750],
								[114.219025, 22.448750],
								[114.219025, 22.449026],
								[114.218475, 22.449026],
								[114.218475, 22.449306],
								[114.217087, 22.449306],
								[114.217087, 22.449026],
								[114.217361, 22.449026],
								[114.217361, 22.448473],
								[114.217636, 22.448473],
								[114.217636, 22.447639],
								[114.217361, 22.447639],
								[114.217361, 22.447083],
								[114.216805, 22.447083],
								[114.216805, 22.446804],
								[114.216248, 22.446804],
								[114.216248, 22.447083],
								[114.215973, 22.447083],
								[114.215973, 22.447361],
								[114.215698, 22.447361],
								[114.215698, 22.447639],
								[114.215416, 22.447639],
								[114.215416, 22.447914],
								[114.215141, 22.447914],
								[114.215141, 22.448473],
								[114.214859, 22.448473],
								[114.214859, 22.448750],
								[114.211525, 22.448750],
								[114.211525, 22.449026],
								[114.210976, 22.449026],
								[114.210976, 22.448750],
								[114.210693, 22.448750],
								[114.210693, 22.449026],
								[114.210136, 22.449026],
								[114.210136, 22.449306],
								[114.209862, 22.449306],
								[114.209862, 22.449862],
								[114.209587, 22.449862],
								[114.209587, 22.450136],
								[114.209862, 22.450136],
								[114.209862, 22.450417],
								[114.210419, 22.450417],
								[114.210419, 22.450695],
								[114.210831, 22.450695],
								[114.210976, 22.450695],
								[114.210976, 22.450886],
								[114.210976, 22.451248],
								[114.211250, 22.451248],
								[114.211250, 22.452362],
								[114.210693, 22.452362],
								[114.210693, 22.452641],
								[114.209587, 22.452641],
								[114.209587, 22.452362],
								[114.209030, 22.452362],
								[114.209030, 22.452084],
								[114.207916, 22.452084],
								[114.207916, 22.451805],
								[114.205971, 22.451805],
								[114.205971, 22.452084],
								[114.205696, 22.452084],
								[114.205696, 22.452917],
								[114.205414, 22.452917],
								[114.205414, 22.453470],
								[114.205139, 22.453470],
								[114.205139, 22.453751],
								[114.205414, 22.453751],
								[114.205414, 22.454863],
								[114.205696, 22.454863],
								[114.205696, 22.455139],
								[114.206802, 22.455139],
								[114.206802, 22.455416],
								[114.208191, 22.455416],
								[114.208191, 22.455973],
								[114.207916, 22.455973],
								[114.207916, 22.456249],
								[114.207642, 22.456249],
								[114.207642, 22.456528],
								[114.205414, 22.456528],
								[114.205414, 22.456806],
								[114.204865, 22.456806],
								[114.204865, 22.457083],
								[114.204308, 22.457083],
								[114.204308, 22.457361],
								[114.204025, 22.457361],
								[114.204025, 22.457640],
								[114.203476, 22.457640],
								[114.203476, 22.457916],
								[114.202637, 22.457916],
								[114.202637, 22.458750],
								[114.202362, 22.458750],
								[114.202362, 22.459305],
								[114.201248, 22.459305],
								[114.201248, 22.459028],
								[114.200417, 22.459028],
								[114.200417, 22.457916],
								[114.199860, 22.457916],
								[114.199860, 22.457640],
								[114.199585, 22.457640],
								[114.199585, 22.457361],
								[114.199303, 22.457361],
								[114.199303, 22.457083],
								[114.199028, 22.457083],
								[114.199028, 22.456806],
								[114.198753, 22.456806],
								[114.198753, 22.455416],
								[114.199028, 22.455416],
								[114.199028, 22.454027],
								[114.198753, 22.454027],
								[114.198753, 22.453470],
								[114.198196, 22.453470],
								[114.198196, 22.453194],
								[114.197914, 22.453194],
								[114.197914, 22.452917],
								[114.197639, 22.452917],
								[114.197639, 22.452641],
								[114.197365, 22.452641],
								[114.197365, 22.452362],
								[114.196526, 22.452362],
								[114.196526, 22.452084],
								[114.195694, 22.452084],
								[114.195694, 22.451805],
								[114.195137, 22.451805],
								[114.195137, 22.451530],
								[114.194580, 22.451530],
								[114.194580, 22.451248],
								[114.193748, 22.451248],
								[114.193748, 22.450695],
								[114.193474, 22.450695],
								[114.193474, 22.450972],
								[114.193176, 22.450972],
								[114.193031, 22.450972],
								[114.192360, 22.450972],
								[114.192360, 22.451139],
								[114.192360, 22.451248],
								[114.191917, 22.451248],
								[114.190971, 22.451248],
								[114.190971, 22.451527],
								[114.189857, 22.451530],
								[114.189857, 22.451805],
								[114.188469, 22.451805],
								[114.188469, 22.452078],
								[114.184586, 22.452084],
								[114.184586, 22.452362],
								[114.184029, 22.452362],
								[114.184029, 22.452616],
								[114.183746, 22.452641],
								[114.183746, 22.452362],
								[114.182915, 22.452362],
								[114.182915, 22.452629],
								[114.182640, 22.452641],
								[114.182640, 22.452362],
								[114.182358, 22.452362],
								[114.182358, 22.452084],
								[114.182060, 22.452084],
								[114.181808, 22.452084],
								[114.181808, 22.451834],
								[114.181252, 22.451805],
								[114.181252, 22.451300],
								[114.181252, 22.450695],
								[114.180969, 22.450695],
								[114.180969, 22.450136],
								[114.180695, 22.450136],
								[114.180695, 22.449862],
								[114.180969, 22.449862],
								[114.180969, 22.449583],
								[114.180695, 22.449583],
								[114.180695, 22.449306],
								[114.180138, 22.449306],
								[114.180138, 22.449026],
								[114.178749, 22.449026],
								[114.178749, 22.448750],
								[114.178474, 22.448750],
								[114.178474, 22.448195],
								[114.178192, 22.448195],
								[114.178192, 22.447639],
								[114.177917, 22.447639],
								[114.177917, 22.447098],
								[114.177917, 22.446804],
								[114.178192, 22.446804],
								[114.178192, 22.446251],
								[114.178749, 22.446251],
								[114.178749, 22.445972],
								[114.178932, 22.445972],
								[114.179031, 22.445972],
								[114.179031, 22.445696],
								[114.179306, 22.445696],
								[114.179306, 22.444916],
								[114.179581, 22.444860],
								[114.179581, 22.444366],
								[114.180138, 22.444305],
								[114.180138, 22.444052],
								[114.180420, 22.444027],
								[114.180420, 22.443750],
								[114.180969, 22.443750],
								[114.180969, 22.443474],
								[114.181252, 22.443474],
								[114.181252, 22.443195],
								[114.181808, 22.443195],
								[114.181808, 22.442917],
								[114.182312, 22.442917],
								[114.184029, 22.442917],
								[114.184029, 22.442427],
								[114.184029, 22.441528],
								[114.184303, 22.441528],
								[114.184303, 22.441252],
								[114.185143, 22.441252],
								[114.185143, 22.440969],
								[114.185692, 22.440969],
								[114.185692, 22.440695],
								[114.185974, 22.440695],
								[114.185974, 22.440416],
								[114.186249, 22.440416],
								[114.186249, 22.440142],
								[114.187233, 22.440142],
								[114.187363, 22.440142],
								[114.187363, 22.439859],
								[114.188469, 22.439859],
								[114.188469, 22.439583],
								[114.188751, 22.439583],
								[114.188751, 22.439859],
								[114.189583, 22.439859],
								[114.189583, 22.440142],
								[114.190140, 22.440142],
								[114.190140, 22.440416],
								[114.190971, 22.440416],
								[114.190971, 22.440695],
								[114.191803, 22.440695],
								[114.191803, 22.440519],
								[114.191803, 22.440416],
								[114.193192, 22.440416],
								[114.193192, 22.440142],
								[114.194031, 22.440142],
								[114.194031, 22.440416],
								[114.194405, 22.440416],
								[114.195137, 22.440416],
								[114.195137, 22.440142],
								[114.195969, 22.440142],
								[114.195969, 22.439859],
								[114.196808, 22.439859],
								[114.196808, 22.439215],
								[114.196808, 22.439030],
								[114.197037, 22.439030],
								[114.197083, 22.438473],
								[114.197365, 22.438473],
								[114.197365, 22.438194],
								[114.197639, 22.438194],
								[114.197639, 22.437916],
								[114.197914, 22.437916],
								[114.197914, 22.437637],
								[114.198196, 22.437637],
								[114.198196, 22.437361],
								[114.198471, 22.437361],
								[114.198471, 22.437084],
								[114.198738, 22.437084],
								[114.199303, 22.437084],
								[114.199303, 22.436806],
								[114.199860, 22.436806],
								[114.199860, 22.436527],
								[114.200691, 22.436527],
								[114.200691, 22.436251],
								[114.202362, 22.436251],
								[114.202362, 22.435972],
								[114.202637, 22.435972],
								[114.202637, 22.435694],
								[114.202919, 22.435694],
								[114.202919, 22.435415],
								[114.203194, 22.435415],
								[114.203194, 22.433472],
								[114.202919, 22.433472],
								[114.202919, 22.433193],
								[114.202637, 22.433193],
								[114.202637, 22.432917],
								[114.202362, 22.432917],
								[114.202362, 22.432638],
								[114.202080, 22.432638],
								[114.202080, 22.431805],
								[114.202362, 22.431805],
								[114.202362, 22.431528],
								[114.203476, 22.431528],
								[114.203476, 22.431250],
								[114.203751, 22.431250],
								[114.203751, 22.430254],
								[114.203751, 22.429861],
								[114.204025, 22.429861],
								[114.204025, 22.429306],
								[114.204308, 22.429306],
								[114.204308, 22.429028],
								[114.204582, 22.429028],
								[114.204582, 22.428749],
								[114.205414, 22.428749],
								[114.205414, 22.428473],
								[114.205780, 22.428473],
								[114.206253, 22.428473],
								[114.206253, 22.428194],
								[114.207359, 22.428194],
								[114.207359, 22.427917],
								[114.207642, 22.427917],
								[114.207642, 22.427641],
								[114.208191, 22.427641],
								[114.208191, 22.427359],
								[114.208351, 22.427359],
								[114.207954, 22.426804],
								[114.207855, 22.426664],
								[114.207611, 22.426336],
								[114.207344, 22.425976],
								[114.206680, 22.425976],
								[114.205971, 22.425976],
								[114.204918, 22.426416],
								[114.204048, 22.426327],
								[114.203659, 22.423582],
								[114.203316, 22.423582],
								[114.203156, 22.423107],
								[114.202438, 22.423164],
								[114.202393, 22.423582],
								[114.202118, 22.424965],
								[114.201469, 22.425755],
								[114.200531, 22.425755],
								[114.199867, 22.424982],
								[114.199486, 22.423824],
								[114.199486, 22.422113],
								[114.198875, 22.420568],
								[114.198769, 22.419079],
								[114.198433, 22.418362],
								[114.198273, 22.418085],
								[114.196777, 22.418085],
								[114.194901, 22.418085],
								[114.189499, 22.418085],
								[114.187508, 22.418140],
								[114.186409, 22.418140],
								[114.185455, 22.418049],
								[114.185318, 22.417353],
								[114.185135, 22.416426],
								[114.184807, 22.416426],
								[114.184807, 22.415602],
								[114.184471, 22.415161],
								[114.183479, 22.415051],
								[114.179512, 22.415216],
								[114.177299, 22.415327],
								[114.175591, 22.415436],
								[114.174820, 22.415436],
								[114.173492, 22.414940],
								[114.173332, 22.414167],
								[114.172997, 22.413450],
								[114.172554, 22.412954],
								[114.171677, 22.412897],
								[114.170738, 22.412897],
								[114.169518, 22.412954],
								[114.167809, 22.412954],
								[114.166595, 22.412733],
								[114.166183, 22.412382],
								[114.165939, 22.412659],
								[114.163124, 22.413033],
								[114.160492, 22.412939],
								[114.158806, 22.411814],
								[114.157112, 22.411531],
								[114.154579, 22.412188],
								[114.148849, 22.412376],
								[114.146881, 22.412376],
								[114.146629, 22.412432],
								[114.146286, 22.412317],
								[114.145981, 22.412214],
								[114.145828, 22.412676],
								[114.145676, 22.413141],
								[114.145561, 22.413479],
								[114.145561, 22.414742],
								[114.145561, 22.415606],
								[114.145470, 22.416037],
								[114.144249, 22.416132],
								[114.142654, 22.415943],
								[114.141060, 22.415850],
								[114.140404, 22.416037],
								[114.138435, 22.416695],
								[114.136559, 22.416788],
								[114.134300, 22.416882],
								[114.131485, 22.416695],
								[114.128761, 22.416695],
								[114.126419, 22.416037],
								[114.124435, 22.415266],
								[114.124519, 22.415628],
								[114.124649, 22.416866],
								[114.124504, 22.416845],
								[114.124107, 22.420435],
								[114.124001, 22.420671],
								[114.122688, 22.422050],
								[114.121651, 22.423540],
								[114.120163, 22.425203],
								[114.119133, 22.426521],
								[114.117699, 22.427780],
								[114.117149, 22.428526],
								[114.117035, 22.428581],
								[114.116928, 22.428637],
								[114.116859, 22.428852],
								[114.116821, 22.428968],
								[114.116379, 22.429560],
								[114.115982, 22.431337],
								[114.115967, 22.431528],
								[114.115845, 22.431879],
								[114.115669, 22.432425],
								[114.115669, 22.433685],
								[114.115669, 22.434664],
								[114.115646, 22.434982],
								[114.114822, 22.436213],
								[114.113792, 22.437761],
								[114.113144, 22.438738],
								[114.112297, 22.440844],
								[114.112244, 22.441010],
								[114.112198, 22.441135],
								[114.111732, 22.441818],
								[114.111496, 22.443251],
								[114.111458, 22.443369],
								[114.111580, 22.443851],
								[114.111671, 22.444557],
								[114.111877, 22.445055],
								[114.112267, 22.445988],
								[114.113632, 22.447458],
								[114.113945, 22.447847],
								[114.114403, 22.448421],
								[114.116508, 22.450947],
								[114.116821, 22.451410],
								[114.117348, 22.452209],
								[114.118614, 22.453894],
								[114.119453, 22.454737],
								[114.120361, 22.455414],
								[114.121010, 22.455902],
								[114.121048, 22.456593],
								[114.121346, 22.457485],
								[114.121971, 22.457785],
								[114.126190, 22.463156],
								[114.126335, 22.463577],
								[114.126610, 22.464418],
								[114.126892, 22.465261],
								[114.127037, 22.465683],
								[114.127151, 22.466040],
								[114.127213, 22.466219],
								[114.127457, 22.466946],
								[114.129135, 22.468208],
								[114.130821, 22.469049],
								[114.132088, 22.469049],
								[114.133347, 22.468630],
								[114.134613, 22.469049],
								[114.135452, 22.469893],
								[114.137138, 22.470314],
								[114.137978, 22.471155],
								[114.139244, 22.471996],
								[114.140083, 22.472839],
								[114.141769, 22.474102],
								[114.143456, 22.474524],
								[114.145561, 22.475786],
								[114.146820, 22.475786],
								[114.147659, 22.476627],
								[114.148506, 22.477892],
								[114.148926, 22.479574],
								[114.151031, 22.481258],
								[114.152290, 22.480839],
								[114.153976, 22.480417],
								[114.153976, 22.481680],
								[114.153976, 22.482943],
								[114.153137, 22.484205],
								[114.151451, 22.486732],
								[114.151451, 22.487995],
								[114.152718, 22.489258],
								[114.156082, 22.489258],
								[114.157349, 22.489258],
								[114.160713, 22.489679],
								[114.162399, 22.490103],
								[114.164078, 22.490520],
								[114.164078, 22.489258],
								[114.164505, 22.486313],
								[114.166611, 22.486732],
								[114.168289, 22.487156],
								[114.169556, 22.486732],
								[114.169975, 22.485472],
								[114.170815, 22.484627],
								[114.172081, 22.484627],
								[114.172920, 22.487995],
								[114.174187, 22.490103],
								[114.174606, 22.491364],
								[114.175446, 22.492205],
								[114.176712, 22.493050],
								[114.177551, 22.493891],
								[114.178818, 22.494734],
								[114.180504, 22.495575],
								[114.182182, 22.495996],
								[114.184288, 22.496414],
								[114.185555, 22.496414],
								[114.186813, 22.496414],
								[114.190605, 22.495575],
								[114.191864, 22.494734],
								[114.194397, 22.495152],
								[114.196075, 22.493467],
								[114.199028, 22.493467],
								[114.201126, 22.493050],
								[114.204079, 22.492205],
								[114.205338, 22.491364],
								[114.206604, 22.490944],
								[114.207863, 22.490520],
								[114.209549, 22.490944],
								[114.210815, 22.491364],
								[114.213341, 22.491364],
								[114.214600, 22.490944],
								[114.215866, 22.490520],
								[114.217552, 22.490103],
								[114.218811, 22.490103],
								[114.220078, 22.490103],
								[114.223022, 22.487995],
								[114.224281, 22.487156],
								[114.225128, 22.490944],
								[114.225967, 22.491785],
								[114.227654, 22.492205],
								[114.228912, 22.492626],
								[114.230598, 22.494310],
								[114.231865, 22.496414],
								[114.232285, 22.497681],
								[114.234810, 22.498522],
								[114.236069, 22.499784],
								[114.237335, 22.500626],
								[114.238602, 22.500204],
								[114.239441, 22.501045],
								[114.239441, 22.502310],
								[114.239021, 22.503571],
								[114.239861, 22.504416],
								[114.241966, 22.504833],
								[114.242386, 22.502729],
								[114.243233, 22.501886],
								[114.245331, 22.501045],
								[114.246178, 22.500204],
								[114.248283, 22.497257],
								[114.249542, 22.495575],
								[114.250809, 22.494734],
								[114.251648, 22.493891],
								[114.253334, 22.493467],
								[114.254593, 22.493467],
								[114.255859, 22.493467],
								[114.257126, 22.493467],
								[114.258385, 22.493467],
								[114.259651, 22.493467],
								[114.262177, 22.493891],
								[114.263855, 22.493467],
								[114.266388, 22.493891],
								[114.267227, 22.494734],
								[114.266808, 22.495996],
								[114.268066, 22.495996],
								[114.269333, 22.495996],
								[114.271019, 22.497257],
								[114.272278, 22.496414],
								[114.275223, 22.496414],
								[114.276489, 22.497257],
								[114.278175, 22.498098],
								[114.284485, 22.497681],
								[114.285751, 22.496838],
								[114.287010, 22.496414],
								[114.288277, 22.495575],
								[114.289543, 22.495152],
								[114.291222, 22.494310],
								[114.293327, 22.493050],
								[114.294594, 22.491364],
								[114.295853, 22.491364],
								[114.297119, 22.491364],
								[114.298378, 22.490944],
								[114.300484, 22.490103],
								[114.301331, 22.489258],
								[114.302589, 22.489258],
								[114.303429, 22.490103],
								[114.306801, 22.492205],
								[114.308060, 22.492626],
								[114.309326, 22.493467],
								[114.310593, 22.494310],
								[114.311852, 22.495152],
								[114.313118, 22.495575],
								[114.314377, 22.495996],
								[114.316063, 22.498098],
								[114.319427, 22.499784],
								[114.320274, 22.500626],
								[114.321114, 22.501469],
								[114.323219, 22.502729],
								[114.324486, 22.503153],
								[114.325745, 22.502729],
								[114.327431, 22.503153],
								[114.328690, 22.504416],
								[114.331215, 22.505676],
								[114.332481, 22.508204],
								[114.334587, 22.509464],
								[114.333984, 22.510975],
								[114.334587, 22.510975]
							]
						],
						[
							[
								[114.430138, 22.552917],
								[114.430138, 22.552639],
								[114.430420, 22.552639],
								[114.430420, 22.552361],
								[114.430138, 22.552361],
								[114.430138, 22.552082],
								[114.430420, 22.552082],
								[114.430420, 22.550974],
								[114.430138, 22.550974],
								[114.430138, 22.549864],
								[114.429863, 22.549864],
								[114.429863, 22.548815],
								[114.430138, 22.548752],
								[114.430138, 22.548470],
								[114.430420, 22.548470],
								[114.430420, 22.545973],
								[114.430695, 22.545973],
								[114.430695, 22.545416],
								[114.430969, 22.545416],
								[114.430969, 22.545137],
								[114.431252, 22.545137],
								[114.431252, 22.544861],
								[114.431526, 22.544861],
								[114.431526, 22.544584],
								[114.432640, 22.544584],
								[114.432640, 22.544306],
								[114.432915, 22.544306],
								[114.432915, 22.544027],
								[114.433197, 22.544027],
								[114.433197, 22.543472],
								[114.433472, 22.543472],
								[114.433472, 22.543194],
								[114.433746, 22.543194],
								[114.433746, 22.542915],
								[114.434303, 22.542915],
								[114.434303, 22.542639],
								[114.434860, 22.542639],
								[114.434860, 22.542360],
								[114.435143, 22.542360],
								[114.435143, 22.542084],
								[114.435417, 22.542084],
								[114.435417, 22.541805],
								[114.435692, 22.541805],
								[114.435692, 22.541529],
								[114.435974, 22.541529],
								[114.435974, 22.541250],
								[114.436806, 22.541250],
								[114.436806, 22.541529],
								[114.437363, 22.541529],
								[114.437363, 22.541805],
								[114.437637, 22.541805],
								[114.437637, 22.542084],
								[114.439026, 22.542084],
								[114.439026, 22.541805],
								[114.439583, 22.541805],
								[114.439583, 22.541529],
								[114.440414, 22.541529],
								[114.440414, 22.541250],
								[114.440971, 22.541250],
								[114.440971, 22.540138],
								[114.440697, 22.540138],
								[114.440697, 22.539862],
								[114.440140, 22.539862],
								[114.440140, 22.539583],
								[114.439857, 22.539583],
								[114.439857, 22.539028],
								[114.439583, 22.539028],
								[114.439583, 22.538473],
								[114.439308, 22.538473],
								[114.439308, 22.538195],
								[114.439026, 22.538195],
								[114.439026, 22.537916],
								[114.436531, 22.537916],
								[114.436531, 22.537640],
								[114.435417, 22.537640],
								[114.435417, 22.537361],
								[114.434029, 22.537361],
								[114.434029, 22.537916],
								[114.433746, 22.537916],
								[114.433746, 22.538195],
								[114.433197, 22.538195],
								[114.433197, 22.538473],
								[114.432640, 22.538473],
								[114.432640, 22.538750],
								[114.432083, 22.538750],
								[114.432083, 22.539028],
								[114.431526, 22.539028],
								[114.431526, 22.539305],
								[114.431252, 22.539305],
								[114.431252, 22.539583],
								[114.430695, 22.539583],
								[114.430695, 22.539862],
								[114.430138, 22.539862],
								[114.430138, 22.540138],
								[114.429581, 22.540138],
								[114.429581, 22.540417],
								[114.429306, 22.540417],
								[114.429306, 22.540695],
								[114.428192, 22.540695],
								[114.428192, 22.540417],
								[114.427361, 22.540417],
								[114.427361, 22.540695],
								[114.427086, 22.540695],
								[114.427086, 22.540972],
								[114.426804, 22.540972],
								[114.426804, 22.541529],
								[114.426529, 22.541529],
								[114.426529, 22.541805],
								[114.426247, 22.541805],
								[114.426247, 22.542084],
								[114.425972, 22.542084],
								[114.425972, 22.542639],
								[114.425697, 22.542639],
								[114.425697, 22.543472],
								[114.425415, 22.543472],
								[114.425415, 22.543751],
								[114.425140, 22.543751],
								[114.425140, 22.544582],
								[114.424858, 22.544584],
								[114.424858, 22.545137],
								[114.425140, 22.545137],
								[114.425140, 22.545416],
								[114.425415, 22.545416],
								[114.425415, 22.546247],
								[114.425140, 22.546247],
								[114.425140, 22.547083],
								[114.424858, 22.547083],
								[114.424858, 22.547359],
								[114.424583, 22.547359],
								[114.424583, 22.547640],
								[114.424026, 22.547640],
								[114.424026, 22.548470],
								[114.424583, 22.548470],
								[114.424583, 22.549028],
								[114.424858, 22.549028],
								[114.424858, 22.549305],
								[114.425140, 22.549305],
								[114.425140, 22.549864],
								[114.425415, 22.549864],
								[114.425415, 22.550694],
								[114.425697, 22.550695],
								[114.425697, 22.551527],
								[114.426247, 22.551527],
								[114.426247, 22.551805],
								[114.427635, 22.551805],
								[114.427635, 22.552082],
								[114.428192, 22.552082],
								[114.428192, 22.552361],
								[114.428749, 22.552361],
								[114.428749, 22.552639],
								[114.429306, 22.552639],
								[114.429306, 22.552917],
								[114.430138, 22.552917]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Tsuen Wan",
					"ID_0": 102,
					"ID_1": 13,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[114.045692, 22.347084],
								[114.045692, 22.346806],
								[114.045975, 22.346806],
								[114.045975, 22.346527],
								[114.046524, 22.346527],
								[114.046524, 22.346291],
								[114.047638, 22.346251],
								[114.047638, 22.346121],
								[114.047638, 22.345970],
								[114.048470, 22.345970],
								[114.048470, 22.346121],
								[114.048470, 22.346251],
								[114.049026, 22.346251],
								[114.049026, 22.345970],
								[114.049309, 22.345970],
								[114.049309, 22.345694],
								[114.049858, 22.345694],
								[114.049858, 22.345419],
								[114.050697, 22.345419],
								[114.050697, 22.345140],
								[114.051247, 22.345140],
								[114.051247, 22.344862],
								[114.051529, 22.344862],
								[114.051529, 22.344584],
								[114.051804, 22.344584],
								[114.051804, 22.344305],
								[114.052086, 22.344305],
								[114.052086, 22.344027],
								[114.052361, 22.344027],
								[114.052361, 22.343748],
								[114.052635, 22.343748],
								[114.052635, 22.343472],
								[114.053192, 22.343472],
								[114.053192, 22.343197],
								[114.053474, 22.343197],
								[114.053474, 22.342636],
								[114.053749, 22.342636],
								[114.053749, 22.342361],
								[114.054306, 22.342361],
								[114.054306, 22.342083],
								[114.054581, 22.342083],
								[114.054581, 22.341808],
								[114.054863, 22.341808],
								[114.054863, 22.340694],
								[114.055138, 22.340694],
								[114.055138, 22.340139],
								[114.055420, 22.340139],
								[114.055420, 22.339861],
								[114.055138, 22.339861],
								[114.055138, 22.339582],
								[114.055420, 22.339582],
								[114.055420, 22.339027],
								[114.055695, 22.339027],
								[114.055695, 22.338472],
								[114.055969, 22.338472],
								[114.055969, 22.337917],
								[114.056252, 22.337917],
								[114.056252, 22.337639],
								[114.056526, 22.337639],
								[114.056526, 22.337360],
								[114.056808, 22.337360],
								[114.056808, 22.336805],
								[114.057083, 22.336805],
								[114.057083, 22.336527],
								[114.057358, 22.336527],
								[114.057358, 22.335974],
								[114.057640, 22.335974],
								[114.057640, 22.335417],
								[114.057915, 22.335417],
								[114.057915, 22.335138],
								[114.057083, 22.335138],
								[114.057083, 22.334862],
								[114.056526, 22.334862],
								[114.056526, 22.334583],
								[114.055695, 22.334583],
								[114.055695, 22.334305],
								[114.055420, 22.334305],
								[114.055420, 22.334028],
								[114.054581, 22.334028],
								[114.054581, 22.333195],
								[114.054863, 22.333195],
								[114.054863, 22.333006],
								[114.054863, 22.332916],
								[114.055138, 22.332916],
								[114.055138, 22.330973],
								[114.055420, 22.330973],
								[114.055420, 22.330694],
								[114.055695, 22.330694],
								[114.055695, 22.330416],
								[114.055969, 22.330416],
								[114.055969, 22.330137],
								[114.056526, 22.330137],
								[114.056526, 22.329861],
								[114.056808, 22.329861],
								[114.056808, 22.329025],
								[114.056526, 22.329025],
								[114.056526, 22.328751],
								[114.056252, 22.328751],
								[114.056252, 22.328472],
								[114.055969, 22.328472],
								[114.055969, 22.327639],
								[114.055138, 22.327639],
								[114.055138, 22.327084],
								[114.054863, 22.327084],
								[114.054863, 22.325138],
								[114.054581, 22.325138],
								[114.054581, 22.324862],
								[114.054306, 22.324862],
								[114.054306, 22.324028],
								[114.054031, 22.324028],
								[114.054031, 22.323750],
								[114.053749, 22.323750],
								[114.053749, 22.323471],
								[114.053474, 22.323471],
								[114.053474, 22.323195],
								[114.053749, 22.323195],
								[114.053749, 22.322916],
								[114.054306, 22.322916],
								[114.054306, 22.322639],
								[114.054581, 22.322639],
								[114.054581, 22.322363],
								[114.054863, 22.322363],
								[114.054863, 22.322083],
								[114.055138, 22.322083],
								[114.055138, 22.321806],
								[114.055420, 22.321806],
								[114.055420, 22.321253],
								[114.055695, 22.321253],
								[114.055695, 22.320694],
								[114.055138, 22.320694],
								[114.055138, 22.320972],
								[114.054863, 22.320972],
								[114.054863, 22.321253],
								[114.054306, 22.321253],
								[114.054306, 22.321527],
								[114.053474, 22.321527],
								[114.053474, 22.320974],
								[114.052917, 22.320972],
								[114.052917, 22.320694],
								[114.052635, 22.320694],
								[114.052635, 22.320196],
								[114.052635, 22.319859],
								[114.052361, 22.319859],
								[114.052361, 22.319584],
								[114.052086, 22.319584],
								[114.052086, 22.319305],
								[114.051521, 22.319305],
								[114.051247, 22.319305],
								[114.051247, 22.319029],
								[114.050972, 22.319029],
								[114.050972, 22.318867],
								[114.050972, 22.318472],
								[114.050697, 22.318472],
								[114.050697, 22.318195],
								[114.049583, 22.318195],
								[114.049583, 22.318472],
								[114.046524, 22.318472],
								[114.046524, 22.318195],
								[114.046249, 22.318195],
								[114.046249, 22.316526],
								[114.045692, 22.316526],
								[114.045692, 22.316250],
								[114.045135, 22.316250],
								[114.045135, 22.315971],
								[114.044861, 22.315971],
								[114.044861, 22.316250],
								[114.043747, 22.316250],
								[114.043747, 22.316526],
								[114.043198, 22.316526],
								[114.043198, 22.316805],
								[114.042915, 22.316805],
								[114.042915, 22.317362],
								[114.042641, 22.317362],
								[114.042641, 22.318195],
								[114.042358, 22.318195],
								[114.042358, 22.318472],
								[114.042084, 22.318472],
								[114.042084, 22.318748],
								[114.041809, 22.318748],
								[114.041809, 22.319029],
								[114.041527, 22.319029],
								[114.041527, 22.319584],
								[114.041252, 22.319584],
								[114.041252, 22.319859],
								[114.040970, 22.319859],
								[114.040970, 22.320141],
								[114.040695, 22.320141],
								[114.040695, 22.320417],
								[114.040413, 22.320417],
								[114.040413, 22.320694],
								[114.040138, 22.320694],
								[114.040138, 22.321806],
								[114.039864, 22.321806],
								[114.039864, 22.322363],
								[114.039581, 22.322363],
								[114.039581, 22.323471],
								[114.039307, 22.323471],
								[114.039307, 22.323750],
								[114.039024, 22.323750],
								[114.039024, 22.324028],
								[114.038750, 22.324028],
								[114.038750, 22.324306],
								[114.038475, 22.324306],
								[114.038475, 22.324583],
								[114.038193, 22.324583],
								[114.038193, 22.324862],
								[114.037636, 22.324862],
								[114.037636, 22.325138],
								[114.037086, 22.325138],
								[114.037086, 22.324862],
								[114.036530, 22.324862],
								[114.036530, 22.325138],
								[114.036247, 22.325138],
								[114.036247, 22.325417],
								[114.035141, 22.325417],
								[114.035141, 22.325138],
								[114.034027, 22.325138],
								[114.034027, 22.324862],
								[114.034302, 22.324862],
								[114.034302, 22.323195],
								[114.034584, 22.323195],
								[114.034584, 22.322916],
								[114.034859, 22.322916],
								[114.034859, 22.322083],
								[114.034584, 22.322083],
								[114.034584, 22.321806],
								[114.035141, 22.321806],
								[114.035141, 22.320417],
								[114.035416, 22.320417],
								[114.035416, 22.319584],
								[114.035698, 22.319584],
								[114.035698, 22.318472],
								[114.035973, 22.318472],
								[114.035973, 22.317919],
								[114.036247, 22.317919],
								[114.036247, 22.317636],
								[114.036530, 22.317636],
								[114.036530, 22.317083],
								[114.036247, 22.317083],
								[114.036247, 22.315695],
								[114.035973, 22.315695],
								[114.035973, 22.314304],
								[114.036247, 22.314304],
								[114.036247, 22.314035],
								[114.035126, 22.314013],
								[114.028908, 22.313904],
								[114.013885, 22.313904],
								[113.998405, 22.314135],
								[113.995117, 22.314402],
								[113.994583, 22.314445],
								[113.994583, 22.314583],
								[113.994858, 22.314583],
								[113.994858, 22.314861],
								[113.995140, 22.314861],
								[113.995140, 22.315140],
								[113.995415, 22.315140],
								[113.995415, 22.315416],
								[113.995529, 22.315416],
								[113.995697, 22.315416],
								[113.995697, 22.315971],
								[113.995972, 22.315971],
								[113.995972, 22.316250],
								[113.996246, 22.316250],
								[113.996246, 22.316526],
								[113.996529, 22.316526],
								[113.996529, 22.317083],
								[113.996803, 22.317083],
								[113.996803, 22.317362],
								[113.997086, 22.317362],
								[113.997086, 22.318195],
								[113.997360, 22.318195],
								[113.997360, 22.318472],
								[113.997643, 22.318472],
								[113.997643, 22.319029],
								[113.997917, 22.319029],
								[113.997917, 22.319305],
								[113.998192, 22.319305],
								[113.998192, 22.320141],
								[113.998474, 22.320141],
								[113.998474, 22.320417],
								[113.998749, 22.320417],
								[113.998749, 22.320972],
								[113.999306, 22.320972],
								[113.999306, 22.321253],
								[113.999931, 22.321253],
								[114.000137, 22.321253],
								[114.000137, 22.321074],
								[114.000137, 22.320972],
								[114.000694, 22.320972],
								[114.000694, 22.320417],
								[114.001251, 22.320417],
								[114.001251, 22.320694],
								[114.001526, 22.320694],
								[114.001526, 22.320972],
								[114.001808, 22.320972],
								[114.001808, 22.321253],
								[114.002640, 22.321253],
								[114.002640, 22.321527],
								[114.003471, 22.321527],
								[114.003471, 22.321806],
								[114.004303, 22.321806],
								[114.004303, 22.322083],
								[114.004585, 22.322083],
								[114.004585, 22.322363],
								[114.005142, 22.322363],
								[114.005142, 22.322639],
								[114.005974, 22.322639],
								[114.005974, 22.322916],
								[114.006531, 22.322916],
								[114.006531, 22.323195],
								[114.006805, 22.323195],
								[114.006805, 22.323471],
								[114.007637, 22.323471],
								[114.007637, 22.323750],
								[114.007919, 22.323750],
								[114.007919, 22.324305],
								[114.008469, 22.324306],
								[114.008469, 22.324583],
								[114.009865, 22.324583],
								[114.009865, 22.325138],
								[114.010696, 22.325138],
								[114.010696, 22.324862],
								[114.011253, 22.324862],
								[114.011253, 22.325138],
								[114.011528, 22.325138],
								[114.011528, 22.325693],
								[114.011253, 22.325693],
								[114.011253, 22.326250],
								[114.011108, 22.326250],
								[114.010971, 22.326250],
								[114.010971, 22.327639],
								[114.011253, 22.327639],
								[114.011253, 22.327915],
								[114.011528, 22.327915],
								[114.011528, 22.328194],
								[114.012917, 22.328194],
								[114.012917, 22.328472],
								[114.013191, 22.328472],
								[114.013191, 22.328739],
								[114.013191, 22.329025],
								[114.013748, 22.329025],
								[114.013748, 22.329306],
								[114.014305, 22.329306],
								[114.014305, 22.329584],
								[114.016197, 22.329584],
								[114.017365, 22.329584],
								[114.017365, 22.330137],
								[114.017914, 22.330137],
								[114.017914, 22.330416],
								[114.018196, 22.330416],
								[114.018196, 22.330973],
								[114.018471, 22.330973],
								[114.018471, 22.331860],
								[114.018471, 22.332083],
								[114.018753, 22.332083],
								[114.018753, 22.332359],
								[114.018974, 22.332359],
								[114.019028, 22.332642],
								[114.019249, 22.332642],
								[114.019302, 22.332916],
								[114.019859, 22.332916],
								[114.019859, 22.332796],
								[114.019859, 22.332642],
								[114.019966, 22.332642],
								[114.020142, 22.332642],
								[114.020142, 22.331247],
								[114.020691, 22.331247],
								[114.020691, 22.331530],
								[114.021530, 22.331530],
								[114.021530, 22.331806],
								[114.022087, 22.331806],
								[114.022087, 22.332083],
								[114.022636, 22.332083],
								[114.022636, 22.332359],
								[114.023476, 22.332359],
								[114.023476, 22.333195],
								[114.023193, 22.333195],
								[114.023193, 22.334028],
								[114.022919, 22.334028],
								[114.022919, 22.334305],
								[114.022636, 22.334305],
								[114.022636, 22.334583],
								[114.022362, 22.334583],
								[114.022362, 22.335138],
								[114.022087, 22.335138],
								[114.022087, 22.336250],
								[114.023193, 22.336250],
								[114.023193, 22.335974],
								[114.023476, 22.335974],
								[114.023476, 22.335695],
								[114.024025, 22.335695],
								[114.024025, 22.335417],
								[114.024307, 22.335417],
								[114.024307, 22.335138],
								[114.024582, 22.335138],
								[114.024582, 22.333195],
								[114.024307, 22.333195],
								[114.024307, 22.331806],
								[114.024025, 22.331806],
								[114.024025, 22.331530],
								[114.023476, 22.331530],
								[114.023476, 22.331247],
								[114.023193, 22.331247],
								[114.023193, 22.330973],
								[114.022636, 22.330973],
								[114.022636, 22.330420],
								[114.022362, 22.330416],
								[114.022362, 22.328472],
								[114.022087, 22.328472],
								[114.022087, 22.328194],
								[114.021805, 22.328194],
								[114.021805, 22.327915],
								[114.021248, 22.327915],
								[114.021248, 22.327362],
								[114.021141, 22.327362],
								[114.020691, 22.327362],
								[114.020691, 22.327084],
								[114.020416, 22.327084],
								[114.020416, 22.326529],
								[114.020142, 22.326529],
								[114.020142, 22.326250],
								[114.020416, 22.326250],
								[114.020416, 22.325693],
								[114.021248, 22.325693],
								[114.021248, 22.325972],
								[114.021805, 22.325972],
								[114.021805, 22.326250],
								[114.022362, 22.326250],
								[114.022362, 22.326529],
								[114.022636, 22.326529],
								[114.022636, 22.326805],
								[114.023476, 22.326805],
								[114.023476, 22.327084],
								[114.024307, 22.327084],
								[114.024307, 22.327360],
								[114.024582, 22.327362],
								[114.024582, 22.327639],
								[114.024864, 22.327639],
								[114.024864, 22.327915],
								[114.025139, 22.327915],
								[114.025139, 22.328472],
								[114.025414, 22.328472],
								[114.025414, 22.328751],
								[114.025970, 22.328751],
								[114.025970, 22.329025],
								[114.026253, 22.329025],
								[114.026253, 22.329306],
								[114.026527, 22.329306],
								[114.026527, 22.329584],
								[114.026802, 22.329584],
								[114.026802, 22.329861],
								[114.027084, 22.329861],
								[114.027084, 22.330694],
								[114.027359, 22.330694],
								[114.027359, 22.330973],
								[114.027641, 22.330973],
								[114.027641, 22.331530],
								[114.028198, 22.331530],
								[114.028198, 22.331806],
								[114.028473, 22.331806],
								[114.028473, 22.332359],
								[114.028748, 22.332359],
								[114.028748, 22.332642],
								[114.029030, 22.332642],
								[114.029030, 22.333195],
								[114.029305, 22.333195],
								[114.029305, 22.333469],
								[114.029587, 22.333469],
								[114.029587, 22.333752],
								[114.030136, 22.333752],
								[114.030136, 22.334028],
								[114.030418, 22.334028],
								[114.030418, 22.334305],
								[114.030975, 22.334305],
								[114.030975, 22.334583],
								[114.031250, 22.334583],
								[114.031250, 22.334862],
								[114.031525, 22.334862],
								[114.031525, 22.335138],
								[114.031807, 22.335138],
								[114.031807, 22.335417],
								[114.032082, 22.335417],
								[114.032082, 22.335695],
								[114.032494, 22.335695],
								[114.032913, 22.335695],
								[114.032913, 22.335974],
								[114.033195, 22.335974],
								[114.033195, 22.336250],
								[114.033470, 22.336250],
								[114.033470, 22.336527],
								[114.034027, 22.336527],
								[114.034027, 22.336655],
								[114.034027, 22.336805],
								[114.035141, 22.336805],
								[114.035141, 22.337084],
								[114.035416, 22.337084],
								[114.035416, 22.337360],
								[114.035973, 22.337360],
								[114.035973, 22.337639],
								[114.036247, 22.337639],
								[114.036247, 22.337917],
								[114.036804, 22.337917],
								[114.036804, 22.338194],
								[114.037086, 22.338194],
								[114.037086, 22.338472],
								[114.037636, 22.338472],
								[114.037636, 22.338751],
								[114.038475, 22.338751],
								[114.038475, 22.339027],
								[114.038750, 22.339027],
								[114.038750, 22.339306],
								[114.039024, 22.339306],
								[114.039024, 22.339582],
								[114.039307, 22.339582],
								[114.039307, 22.339861],
								[114.039581, 22.339861],
								[114.039581, 22.340973],
								[114.039864, 22.340973],
								[114.039864, 22.341528],
								[114.040138, 22.341528],
								[114.040138, 22.341808],
								[114.040695, 22.341808],
								[114.040695, 22.342083],
								[114.041252, 22.342083],
								[114.041252, 22.342361],
								[114.041809, 22.342361],
								[114.041809, 22.342083],
								[114.042084, 22.342083],
								[114.042084, 22.341066],
								[114.042084, 22.340973],
								[114.042641, 22.340973],
								[114.042641, 22.340694],
								[114.043747, 22.340694],
								[114.043747, 22.341249],
								[114.044029, 22.341249],
								[114.044029, 22.341528],
								[114.043747, 22.341528],
								[114.043747, 22.342361],
								[114.043472, 22.342361],
								[114.043472, 22.342636],
								[114.043198, 22.342636],
								[114.043198, 22.343197],
								[114.043472, 22.343197],
								[114.043472, 22.343472],
								[114.044029, 22.343472],
								[114.044029, 22.343748],
								[114.044304, 22.343748],
								[114.044304, 22.344584],
								[114.044029, 22.344584],
								[114.044029, 22.346527],
								[114.044304, 22.346527],
								[114.044304, 22.346806],
								[114.044586, 22.346806],
								[114.044586, 22.346910],
								[114.044586, 22.347084],
								[114.044716, 22.347084],
								[114.045235, 22.347084],
								[114.045692, 22.347084]
							]
						],
						[
							[
								[114.060417, 22.356527],
								[114.060417, 22.355694],
								[114.060692, 22.355694],
								[114.060692, 22.355141],
								[114.060966, 22.355139],
								[114.060974, 22.354860],
								[114.061806, 22.354860],
								[114.061806, 22.355139],
								[114.062363, 22.355139],
								[114.062363, 22.355415],
								[114.063194, 22.355415],
								[114.063194, 22.355694],
								[114.063751, 22.355694],
								[114.063751, 22.355972],
								[114.064308, 22.355972],
								[114.064308, 22.355694],
								[114.065140, 22.355694],
								[114.065140, 22.354860],
								[114.064857, 22.354860],
								[114.064857, 22.354584],
								[114.063469, 22.354584],
								[114.063469, 22.352083],
								[114.063194, 22.352083],
								[114.063194, 22.351805],
								[114.062637, 22.351805],
								[114.062637, 22.351528],
								[114.062363, 22.351528],
								[114.062363, 22.350695],
								[114.061806, 22.350695],
								[114.061806, 22.350140],
								[114.062080, 22.350140],
								[114.062080, 22.349310],
								[114.062637, 22.349306],
								[114.062637, 22.348751],
								[114.063194, 22.348749],
								[114.063194, 22.349028],
								[114.064308, 22.349028],
								[114.064308, 22.348473],
								[114.064583, 22.348473],
								[114.064583, 22.347918],
								[114.064857, 22.347918],
								[114.064857, 22.347643],
								[114.065140, 22.347639],
								[114.065140, 22.347359],
								[114.065414, 22.347359],
								[114.065414, 22.346809],
								[114.065697, 22.346806],
								[114.065697, 22.346527],
								[114.065971, 22.346527],
								[114.065971, 22.345419],
								[114.065697, 22.345419],
								[114.065697, 22.345140],
								[114.064857, 22.345140],
								[114.064857, 22.344862],
								[114.064308, 22.344862],
								[114.064308, 22.344311],
								[114.063751, 22.344305],
								[114.063751, 22.344027],
								[114.063194, 22.344027],
								[114.063194, 22.343748],
								[114.062080, 22.343748],
								[114.062080, 22.343472],
								[114.060974, 22.343472],
								[114.060974, 22.343197],
								[114.060417, 22.343197],
								[114.060417, 22.342916],
								[114.060143, 22.342916],
								[114.060143, 22.342636],
								[114.059860, 22.342636],
								[114.059860, 22.342361],
								[114.059029, 22.342361],
								[114.059029, 22.342636],
								[114.058746, 22.342636],
								[114.058746, 22.343197],
								[114.058472, 22.343197],
								[114.058472, 22.343470],
								[114.058197, 22.343472],
								[114.058197, 22.344580],
								[114.057915, 22.344584],
								[114.057915, 22.344862],
								[114.057640, 22.344862],
								[114.057640, 22.345140],
								[114.057083, 22.345140],
								[114.057083, 22.345419],
								[114.056808, 22.345419],
								[114.056808, 22.346806],
								[114.056526, 22.346806],
								[114.056526, 22.347084],
								[114.056252, 22.347084],
								[114.056252, 22.347359],
								[114.055695, 22.347359],
								[114.055695, 22.347639],
								[114.055420, 22.347639],
								[114.055420, 22.347918],
								[114.054863, 22.347918],
								[114.054863, 22.348190],
								[114.054581, 22.348194],
								[114.054581, 22.348473],
								[114.054306, 22.348473],
								[114.054306, 22.348749],
								[114.054031, 22.348749],
								[114.054031, 22.349583],
								[114.054306, 22.349583],
								[114.054306, 22.349861],
								[114.056808, 22.349861],
								[114.056808, 22.350416],
								[114.057083, 22.350416],
								[114.057083, 22.351528],
								[114.057358, 22.351528],
								[114.057358, 22.353193],
								[114.056808, 22.353193],
								[114.056808, 22.353472],
								[114.055969, 22.353472],
								[114.055969, 22.353750],
								[114.055695, 22.353750],
								[114.055695, 22.354029],
								[114.055969, 22.354029],
								[114.055969, 22.354584],
								[114.056252, 22.354584],
								[114.056252, 22.355139],
								[114.056526, 22.355139],
								[114.056526, 22.355694],
								[114.057083, 22.355694],
								[114.057083, 22.355972],
								[114.057640, 22.355972],
								[114.057640, 22.356251],
								[114.058197, 22.356251],
								[114.058197, 22.356527],
								[114.060417, 22.356527]
							]
						],
						[
							[
								[114.111900, 22.361782],
								[114.111839, 22.361538],
								[114.111526, 22.361677],
								[114.111526, 22.361811],
								[114.111900, 22.361782]
							]
						],
						[
							[
								[114.140404, 22.416037],
								[114.141060, 22.415850],
								[114.142654, 22.415943],
								[114.144249, 22.416132],
								[114.145470, 22.416037],
								[114.145561, 22.415606],
								[114.145561, 22.414742],
								[114.145561, 22.413479],
								[114.145676, 22.413141],
								[114.145828, 22.412676],
								[114.145981, 22.412214],
								[114.146286, 22.412317],
								[114.146629, 22.412432],
								[114.146881, 22.412376],
								[114.148849, 22.412376],
								[114.154579, 22.412188],
								[114.157112, 22.411531],
								[114.158806, 22.411814],
								[114.160492, 22.412939],
								[114.163124, 22.413033],
								[114.165939, 22.412659],
								[114.166183, 22.412382],
								[114.166183, 22.411795],
								[114.166061, 22.411291],
								[114.165764, 22.410110],
								[114.165764, 22.408848],
								[114.166611, 22.408005],
								[114.167450, 22.407164],
								[114.167450, 22.405901],
								[114.167534, 22.405571],
								[114.167625, 22.405195],
								[114.167870, 22.404217],
								[114.167732, 22.403654],
								[114.167603, 22.403130],
								[114.167450, 22.402533],
								[114.167488, 22.402414],
								[114.167603, 22.402073],
								[114.167870, 22.401270],
								[114.166183, 22.400427],
								[114.166183, 22.399164],
								[114.167030, 22.396217],
								[114.167030, 22.394955],
								[114.166183, 22.393692],
								[114.164505, 22.392427],
								[114.162399, 22.391165],
								[114.161552, 22.389902],
								[114.161415, 22.389481],
								[114.161308, 22.389154],
								[114.161133, 22.388639],
								[114.159447, 22.386955],
								[114.158829, 22.386749],
								[114.158188, 22.386534],
								[114.157547, 22.385893],
								[114.156502, 22.384850],
								[114.155807, 22.383804],
								[114.155663, 22.383587],
								[114.153976, 22.382746],
								[114.152290, 22.382746],
								[114.151939, 22.382627],
								[114.151031, 22.382324],
								[114.149696, 22.380987],
								[114.148926, 22.380219],
								[114.146820, 22.378956],
								[114.146111, 22.379133],
								[114.145920, 22.379181],
								[114.145134, 22.379377],
								[114.143875, 22.379377],
								[114.143875, 22.380638],
								[114.143784, 22.380903],
								[114.143707, 22.381140],
								[114.143616, 22.381418],
								[114.143501, 22.381756],
								[114.143501, 22.382050],
								[114.143875, 22.383165],
								[114.144562, 22.383854],
								[114.144714, 22.384008],
								[114.143875, 22.384850],
								[114.142189, 22.384850],
								[114.141884, 22.384548],
								[114.141022, 22.383968],
								[114.140259, 22.383738],
								[114.139938, 22.383656],
								[114.139664, 22.383587],
								[114.138397, 22.383587],
								[114.137978, 22.383482],
								[114.137390, 22.383335],
								[114.136719, 22.383165],
								[114.136322, 22.383068],
								[114.136177, 22.383030],
								[114.135033, 22.382746],
								[114.135033, 22.381483],
								[114.136719, 22.381062],
								[114.137138, 22.379799],
								[114.136719, 22.378532],
								[114.136017, 22.377493],
								[114.135872, 22.377272],
								[114.135872, 22.376007],
								[114.135872, 22.374746],
								[114.136032, 22.373976],
								[114.136230, 22.372969],
								[114.136124, 22.372641],
								[114.130402, 22.372641],
								[114.129448, 22.372259],
								[114.129158, 22.372143],
								[114.128296, 22.371799],
								[114.126610, 22.371799],
								[114.125351, 22.371799],
								[114.124084, 22.371799],
								[114.122826, 22.370113],
								[114.122665, 22.369905],
								[114.122055, 22.369091],
								[114.121559, 22.368429],
								[114.120369, 22.366346],
								[114.119873, 22.365482],
								[114.118195, 22.364641],
								[114.116783, 22.364079],
								[114.116089, 22.363800],
								[114.114822, 22.362959],
								[114.113144, 22.362118],
								[114.112099, 22.361767],
								[114.112083, 22.362083],
								[114.112358, 22.362083],
								[114.112358, 22.363194],
								[114.112175, 22.363195],
								[114.112083, 22.363195],
								[114.112076, 22.364040],
								[114.111809, 22.364027],
								[114.111809, 22.364582],
								[114.111526, 22.364580],
								[114.111526, 22.365419],
								[114.111252, 22.365417],
								[114.111252, 22.365696],
								[114.110970, 22.365696],
								[114.110970, 22.365973],
								[114.110420, 22.365971],
								[114.110420, 22.366529],
								[114.109863, 22.366529],
								[114.109863, 22.366810],
								[114.109581, 22.366810],
								[114.109581, 22.367640],
								[114.109306, 22.367640],
								[114.109306, 22.367920],
								[114.109024, 22.367914],
								[114.109024, 22.368195],
								[114.108749, 22.368195],
								[114.108749, 22.368750],
								[114.108673, 22.368750],
								[114.108192, 22.368750],
								[114.108192, 22.369030],
								[114.107918, 22.369026],
								[114.107918, 22.369305],
								[114.107635, 22.369305],
								[114.107635, 22.369583],
								[114.107361, 22.369583],
								[114.107361, 22.369862],
								[114.107086, 22.369850],
								[114.107086, 22.370138],
								[114.106529, 22.370138],
								[114.106529, 22.370420],
								[114.105980, 22.370420],
								[114.105980, 22.370701],
								[114.105415, 22.370695],
								[114.105415, 22.370972],
								[114.104858, 22.370970],
								[114.104858, 22.371248],
								[114.103195, 22.371248],
								[114.103195, 22.370972],
								[114.102638, 22.370972],
								[114.102638, 22.370695],
								[114.102043, 22.370695],
								[114.101807, 22.370701],
								[114.101807, 22.370516],
								[114.101807, 22.370417],
								[114.101250, 22.370420],
								[114.101250, 22.370138],
								[114.100983, 22.370138],
								[114.100983, 22.369875],
								[114.100693, 22.369862],
								[114.100693, 22.369583],
								[114.100601, 22.369583],
								[114.099861, 22.369583],
								[114.099861, 22.369305],
								[114.099586, 22.369305],
								[114.099586, 22.369026],
								[114.099297, 22.369030],
								[114.099297, 22.368750],
								[114.099030, 22.368750],
								[114.099030, 22.368641],
								[114.099030, 22.368473],
								[114.098526, 22.368473],
								[114.098198, 22.368473],
								[114.098198, 22.368195],
								[114.095139, 22.368195],
								[114.095139, 22.367640],
								[114.094017, 22.367640],
								[114.094017, 22.367361],
								[114.092361, 22.367361],
								[114.092361, 22.367081],
								[114.092087, 22.367083],
								[114.092087, 22.366804],
								[114.091530, 22.366810],
								[114.091530, 22.366529],
								[114.091248, 22.366529],
								[114.091248, 22.366810],
								[114.090416, 22.366810],
								[114.090416, 22.366529],
								[114.089302, 22.366529],
								[114.089302, 22.365971],
								[114.089027, 22.365973],
								[114.089027, 22.365696],
								[114.088470, 22.365696],
								[114.088470, 22.365417],
								[114.088196, 22.365417],
								[114.088196, 22.365696],
								[114.087639, 22.365696],
								[114.087639, 22.365973],
								[114.087082, 22.365971],
								[114.087082, 22.366251],
								[114.086517, 22.366251],
								[114.086517, 22.366810],
								[114.085976, 22.366804],
								[114.085976, 22.367083],
								[114.085136, 22.367081],
								[114.085136, 22.367361],
								[114.084587, 22.367361],
								[114.084587, 22.367590],
								[114.084030, 22.367640],
								[114.084030, 22.367920],
								[114.083679, 22.367914],
								[114.083473, 22.367914],
								[114.083473, 22.368084],
								[114.083473, 22.368195],
								[114.083336, 22.368195],
								[114.082916, 22.368195],
								[114.082916, 22.368473],
								[114.081802, 22.368473],
								[114.081810, 22.368750],
								[114.080406, 22.368750],
								[114.080406, 22.369030],
								[114.078979, 22.369030],
								[114.078186, 22.369030],
								[114.078186, 22.368750],
								[114.077637, 22.368750],
								[114.077637, 22.368471],
								[114.077362, 22.368471],
								[114.077362, 22.368750],
								[114.077080, 22.368750],
								[114.077080, 22.368471],
								[114.076530, 22.368473],
								[114.076530, 22.368195],
								[114.076248, 22.368195],
								[114.076248, 22.367914],
								[114.075691, 22.367920],
								[114.075691, 22.367361],
								[114.075417, 22.367361],
								[114.075417, 22.367640],
								[114.075142, 22.367640],
								[114.075142, 22.367361],
								[114.074577, 22.367361],
								[114.074577, 22.367081],
								[114.074028, 22.367083],
								[114.074028, 22.366804],
								[114.073753, 22.366810],
								[114.073753, 22.366529],
								[114.073349, 22.366529],
								[114.073196, 22.366529],
								[114.073196, 22.366377],
								[114.073196, 22.365973],
								[114.072914, 22.365973],
								[114.072914, 22.365696],
								[114.072365, 22.365696],
								[114.072365, 22.365417],
								[114.072083, 22.365419],
								[114.072083, 22.364861],
								[114.071526, 22.364861],
								[114.071526, 22.363750],
								[114.071251, 22.363750],
								[114.071251, 22.363470],
								[114.069847, 22.363474],
								[114.069580, 22.363474],
								[114.069580, 22.363195],
								[114.068192, 22.363190],
								[114.068192, 22.362917],
								[114.067642, 22.362917],
								[114.067642, 22.363190],
								[114.066528, 22.363195],
								[114.066528, 22.363474],
								[114.066254, 22.363474],
								[114.066254, 22.363195],
								[114.065697, 22.363195],
								[114.065697, 22.363474],
								[114.064857, 22.363470],
								[114.064857, 22.363750],
								[114.064308, 22.363750],
								[114.064308, 22.364311],
								[114.064026, 22.364305],
								[114.064026, 22.364582],
								[114.063469, 22.364580],
								[114.063469, 22.364861],
								[114.063187, 22.364861],
								[114.063187, 22.365419],
								[114.062157, 22.365417],
								[114.060143, 22.365419],
								[114.060143, 22.365141],
								[114.059593, 22.365141],
								[114.059593, 22.365419],
								[114.059029, 22.365417],
								[114.059029, 22.365696],
								[114.058472, 22.365696],
								[114.058472, 22.365417],
								[114.058197, 22.365417],
								[114.058197, 22.365696],
								[114.057915, 22.365696],
								[114.057915, 22.365417],
								[114.057358, 22.365419],
								[114.057358, 22.364861],
								[114.057083, 22.364861],
								[114.057083, 22.364580],
								[114.055527, 22.364582],
								[114.055420, 22.364582],
								[114.055420, 22.364305],
								[114.055138, 22.364311],
								[114.055138, 22.364040],
								[114.054871, 22.364040],
								[114.054871, 22.363750],
								[114.054581, 22.363750],
								[114.054581, 22.363470],
								[114.054031, 22.363474],
								[114.054031, 22.363195],
								[114.053749, 22.363190],
								[114.053749, 22.362638],
								[114.053192, 22.362640],
								[114.053192, 22.362362],
								[114.052635, 22.362364],
								[114.052635, 22.362083],
								[114.051247, 22.362083],
								[114.051247, 22.361805],
								[114.050140, 22.361811],
								[114.050140, 22.361252],
								[114.049576, 22.361252],
								[114.049576, 22.360970],
								[114.049309, 22.360970],
								[114.049309, 22.360695],
								[114.049026, 22.360695],
								[114.049026, 22.359861],
								[114.047638, 22.359861],
								[114.047638, 22.360142],
								[114.046806, 22.360142],
								[114.046806, 22.360420],
								[114.045975, 22.360416],
								[114.045975, 22.360695],
								[114.045418, 22.360695],
								[114.045418, 22.360416],
								[114.044861, 22.360416],
								[114.044861, 22.360695],
								[114.043198, 22.360695],
								[114.043198, 22.360416],
								[114.042641, 22.360420],
								[114.042641, 22.360142],
								[114.042358, 22.360142],
								[114.042358, 22.359861],
								[114.041252, 22.359861],
								[114.041252, 22.359306],
								[114.040703, 22.359310],
								[114.040703, 22.359030],
								[114.040413, 22.359030],
								[114.040413, 22.358747],
								[114.040138, 22.358747],
								[114.040138, 22.358471],
								[114.039864, 22.358473],
								[114.039864, 22.357916],
								[114.039581, 22.357916],
								[114.039581, 22.358194],
								[114.039307, 22.358194],
								[114.039307, 22.357637],
								[114.039017, 22.357641],
								[114.039017, 22.357361],
								[114.038750, 22.357361],
								[114.038750, 22.356810],
								[114.038193, 22.356806],
								[114.038193, 22.357084],
								[114.037918, 22.357084],
								[114.037918, 22.356806],
								[114.037086, 22.356806],
								[114.037086, 22.357084],
								[114.035141, 22.357084],
								[114.035141, 22.356806],
								[114.034859, 22.356810],
								[114.034859, 22.356531],
								[114.034027, 22.356531],
								[114.034027, 22.356251],
								[114.033737, 22.356251],
								[114.033737, 22.355970],
								[114.032082, 22.355972],
								[114.032082, 22.355694],
								[114.031807, 22.355694],
								[114.031807, 22.355415],
								[114.030701, 22.355421],
								[114.030701, 22.354851],
								[114.030418, 22.354851],
								[114.030418, 22.355141],
								[114.029007, 22.355141],
								[114.028748, 22.355141],
								[114.028748, 22.355421],
								[114.027405, 22.355421],
								[114.026268, 22.356176],
								[114.026016, 22.358391],
								[114.026413, 22.359592],
								[114.028938, 22.361275],
								[114.029213, 22.361456],
								[114.030205, 22.362118],
								[114.032730, 22.364641],
								[114.032951, 22.365313],
								[114.033150, 22.365906],
								[114.033691, 22.366446],
								[114.033989, 22.366747],
								[114.035255, 22.366747],
								[114.036140, 22.366924],
								[114.037361, 22.367167],
								[114.037834, 22.367483],
								[114.038620, 22.368008],
								[114.038925, 22.368914],
								[114.039047, 22.369272],
								[114.038811, 22.369974],
								[114.038620, 22.370537],
								[114.034836, 22.369694],
								[114.033569, 22.370537],
								[114.032730, 22.371799],
								[114.032516, 22.372440],
								[114.032310, 22.373060],
								[114.032310, 22.374325],
								[114.030853, 22.374617],
								[114.030205, 22.374746],
								[114.030205, 22.378115],
								[114.031044, 22.378532],
								[114.031891, 22.378956],
								[114.033569, 22.378115],
								[114.034004, 22.378258],
								[114.034836, 22.378532],
								[114.035507, 22.379206],
								[114.035675, 22.379377],
								[114.036942, 22.381062],
								[114.038002, 22.382124],
								[114.038200, 22.382324],
								[114.039886, 22.381062],
								[114.041519, 22.380791],
								[114.042412, 22.380638],
								[114.043251, 22.379799],
								[114.044518, 22.379799],
								[114.045784, 22.379799],
								[114.045784, 22.381062],
								[114.045357, 22.382746],
								[114.045158, 22.383339],
								[114.044937, 22.384008],
								[114.045105, 22.384523],
								[114.045357, 22.385271],
								[114.046623, 22.386534],
								[114.047058, 22.386391],
								[114.047615, 22.386204],
								[114.047882, 22.386114],
								[114.049149, 22.386114],
								[114.049988, 22.385271],
								[114.050575, 22.385124],
								[114.051102, 22.384995],
								[114.051674, 22.384850],
								[114.052094, 22.384745],
								[114.053360, 22.384430],
								[114.054199, 22.385271],
								[114.055046, 22.386114],
								[114.057144, 22.386114],
								[114.057976, 22.385836],
								[114.058411, 22.385693],
								[114.059677, 22.385693],
								[114.060936, 22.385693],
								[114.061722, 22.385851],
								[114.062073, 22.385920],
								[114.063042, 22.386114],
								[114.063042, 22.387377],
								[114.063042, 22.388639],
								[114.063179, 22.389061],
								[114.063286, 22.389389],
								[114.063461, 22.389902],
								[114.064308, 22.390745],
								[114.064598, 22.390842],
								[114.065567, 22.391165],
								[114.066406, 22.392008],
								[114.067253, 22.392849],
								[114.067368, 22.392906],
								[114.068939, 22.393692],
								[114.070007, 22.393959],
								[114.070618, 22.394112],
								[114.071884, 22.393270],
								[114.072372, 22.393923],
								[114.072540, 22.394142],
								[114.073036, 22.394810],
								[114.073143, 22.394955],
								[114.073570, 22.396639],
								[114.074409, 22.397480],
								[114.075096, 22.397251],
								[114.075668, 22.397058],
								[114.076141, 22.397154],
								[114.076355, 22.397196],
								[114.076805, 22.397285],
								[114.077133, 22.397352],
								[114.077484, 22.397423],
								[114.077774, 22.397480],
								[114.079041, 22.397480],
								[114.081566, 22.399586],
								[114.081833, 22.399853],
								[114.082405, 22.400427],
								[114.083160, 22.401554],
								[114.083252, 22.401690],
								[114.084930, 22.402533],
								[114.086197, 22.402533],
								[114.086319, 22.402493],
								[114.087456, 22.402111],
								[114.088631, 22.401329],
								[114.089989, 22.400427],
								[114.092087, 22.400427],
								[114.093292, 22.401230],
								[114.093414, 22.401320],
								[114.095459, 22.402952],
								[114.096230, 22.403530],
								[114.097145, 22.404217],
								[114.097984, 22.405479],
								[114.098610, 22.406420],
								[114.098824, 22.406742],
								[114.099670, 22.407583],
								[114.100761, 22.407948],
								[114.100929, 22.408005],
								[114.103455, 22.408005],
								[114.104721, 22.407164],
								[114.104790, 22.406963],
								[114.104866, 22.406719],
								[114.105141, 22.405901],
								[114.106407, 22.405901],
								[114.106781, 22.406151],
								[114.107048, 22.406328],
								[114.107185, 22.406422],
								[114.107666, 22.406742],
								[114.108292, 22.406588],
								[114.108513, 22.406532],
								[114.109352, 22.406321],
								[114.109985, 22.406532],
								[114.110161, 22.406591],
								[114.110611, 22.406742],
								[114.110847, 22.406820],
								[114.111244, 22.406952],
								[114.111877, 22.407164],
								[114.112717, 22.408848],
								[114.116089, 22.411373],
								[114.117661, 22.410746],
								[114.118195, 22.410534],
								[114.119873, 22.410534],
								[114.121559, 22.410534],
								[114.121559, 22.411795],
								[114.122826, 22.413057],
								[114.123154, 22.413385],
								[114.124084, 22.414320],
								[114.124084, 22.415583],
								[114.124504, 22.416845],
								[114.124649, 22.416866],
								[114.124519, 22.415628],
								[114.124435, 22.415266],
								[114.126419, 22.416037],
								[114.128761, 22.416695],
								[114.131485, 22.416695],
								[114.134300, 22.416882],
								[114.136559, 22.416788],
								[114.138435, 22.416695],
								[114.140404, 22.416037]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Tuen Mun",
					"ID_0": 102,
					"ID_1": 14,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[113.965973, 22.334305],
								[113.965973, 22.334028],
								[113.966248, 22.334028],
								[113.966248, 22.332642],
								[113.966805, 22.332642],
								[113.966805, 22.332359],
								[113.967636, 22.332359],
								[113.967636, 22.332083],
								[113.967918, 22.332083],
								[113.967918, 22.331806],
								[113.969017, 22.331806],
								[113.969025, 22.330694],
								[113.968750, 22.330694],
								[113.968750, 22.330137],
								[113.968475, 22.330137],
								[113.968475, 22.329861],
								[113.968193, 22.329861],
								[113.968193, 22.329584],
								[113.967636, 22.329584],
								[113.967636, 22.329306],
								[113.967361, 22.329306],
								[113.967361, 22.328751],
								[113.966530, 22.328751],
								[113.966530, 22.329025],
								[113.964859, 22.329025],
								[113.964859, 22.329306],
								[113.964600, 22.329306],
								[113.964584, 22.330973],
								[113.964859, 22.330973],
								[113.964859, 22.333469],
								[113.965141, 22.333469],
								[113.965141, 22.334028],
								[113.965416, 22.334028],
								[113.965416, 22.334305],
								[113.965973, 22.334305]
							]
						],
						[
							[
								[113.982361, 22.341249],
								[113.982361, 22.340694],
								[113.982635, 22.340694],
								[113.982635, 22.340414],
								[113.983192, 22.340414],
								[113.983192, 22.339582],
								[113.983475, 22.339582],
								[113.983475, 22.339027],
								[113.983192, 22.339027],
								[113.983192, 22.338472],
								[113.982918, 22.338472],
								[113.982918, 22.338194],
								[113.982635, 22.338194],
								[113.982635, 22.337360],
								[113.982361, 22.337360],
								[113.982361, 22.335974],
								[113.982086, 22.335974],
								[113.982086, 22.335695],
								[113.981804, 22.335695],
								[113.981804, 22.335138],
								[113.981529, 22.335138],
								[113.981529, 22.334862],
								[113.981247, 22.334862],
								[113.981247, 22.334305],
								[113.980972, 22.334305],
								[113.980972, 22.334028],
								[113.980415, 22.334028],
								[113.980415, 22.334583],
								[113.980141, 22.334583],
								[113.980141, 22.335417],
								[113.980408, 22.335417],
								[113.980415, 22.336250],
								[113.980698, 22.336250],
								[113.980698, 22.337639],
								[113.981247, 22.337639],
								[113.981247, 22.337917],
								[113.981529, 22.337917],
								[113.981529, 22.338194],
								[113.981796, 22.338194],
								[113.981804, 22.339027],
								[113.982048, 22.339027],
								[113.982086, 22.339582],
								[113.982361, 22.339582],
								[113.982361, 22.340139],
								[113.982086, 22.340139],
								[113.982086, 22.341249],
								[113.982361, 22.341249]
							]
						],
						[
							[
								[113.888191, 22.347639],
								[113.888191, 22.347359],
								[113.888474, 22.347359],
								[113.888474, 22.347084],
								[113.888748, 22.347084],
								[113.888748, 22.346806],
								[113.889580, 22.346806],
								[113.889580, 22.346251],
								[113.890419, 22.346251],
								[113.890419, 22.347084],
								[113.890976, 22.347084],
								[113.890976, 22.347359],
								[113.891251, 22.347359],
								[113.891251, 22.345970],
								[113.891808, 22.345970],
								[113.891808, 22.345694],
								[113.891525, 22.345694],
								[113.891525, 22.345140],
								[113.891251, 22.345140],
								[113.891251, 22.344862],
								[113.890976, 22.344862],
								[113.890976, 22.344584],
								[113.890694, 22.344584],
								[113.890694, 22.343748],
								[113.890976, 22.343748],
								[113.890976, 22.342640],
								[113.890694, 22.342636],
								[113.890694, 22.342361],
								[113.890419, 22.342361],
								[113.890419, 22.342083],
								[113.889580, 22.342083],
								[113.889580, 22.343197],
								[113.889305, 22.343197],
								[113.889305, 22.343748],
								[113.889030, 22.343748],
								[113.889030, 22.344305],
								[113.888748, 22.344305],
								[113.888748, 22.345419],
								[113.888474, 22.345419],
								[113.888474, 22.345970],
								[113.888191, 22.345970],
								[113.888191, 22.346527],
								[113.887642, 22.346527],
								[113.887642, 22.347078],
								[113.886803, 22.347084],
								[113.886803, 22.346809],
								[113.886528, 22.346806],
								[113.886528, 22.347078],
								[113.886253, 22.347084],
								[113.886253, 22.347639],
								[113.886528, 22.347639],
								[113.886528, 22.347918],
								[113.886803, 22.347918],
								[113.886803, 22.348194],
								[113.887917, 22.348194],
								[113.887917, 22.347643],
								[113.888191, 22.347639]
							]
						],
						[
							[
								[113.886253, 22.350695],
								[113.885971, 22.350695],
								[113.885971, 22.352362],
								[113.886238, 22.352362],
								[113.886253, 22.353193],
								[113.886528, 22.353193],
								[113.886528, 22.353472],
								[113.887360, 22.353472],
								[113.887360, 22.353750],
								[113.887917, 22.353750],
								[113.887917, 22.353201],
								[113.888466, 22.353193],
								[113.888474, 22.352921],
								[113.888748, 22.352917],
								[113.888748, 22.352083],
								[113.888191, 22.352083],
								[113.888191, 22.351805],
								[113.887642, 22.351805],
								[113.887642, 22.351530],
								[113.887360, 22.351528],
								[113.887360, 22.351250],
								[113.886803, 22.351250],
								[113.886803, 22.350973],
								[113.886253, 22.350973],
								[113.886253, 22.350695]
							]
						],
						[
							[
								[113.879860, 22.382080],
								[113.879860, 22.381805],
								[113.880142, 22.381805],
								[113.880142, 22.381531],
								[113.880966, 22.381527],
								[113.880974, 22.381250],
								[113.881248, 22.381250],
								[113.881248, 22.380701],
								[113.881531, 22.380695],
								[113.881531, 22.380972],
								[113.882362, 22.380972],
								[113.882362, 22.380701],
								[113.884308, 22.380695],
								[113.884308, 22.380417],
								[113.884857, 22.380417],
								[113.884865, 22.380140],
								[113.885139, 22.380138],
								[113.885139, 22.379583],
								[113.885414, 22.379583],
								[113.885414, 22.378471],
								[113.885139, 22.378471],
								[113.885139, 22.378195],
								[113.884865, 22.378195],
								[113.884865, 22.377916],
								[113.884583, 22.377916],
								[113.884583, 22.377640],
								[113.884308, 22.377640],
								[113.884308, 22.377361],
								[113.884026, 22.377361],
								[113.884026, 22.375420],
								[113.883751, 22.375416],
								[113.883751, 22.374584],
								[113.884026, 22.374584],
								[113.884026, 22.374306],
								[113.884308, 22.374306],
								[113.884308, 22.374027],
								[113.884583, 22.374027],
								[113.884583, 22.373194],
								[113.884308, 22.373194],
								[113.884308, 22.372641],
								[113.884033, 22.372641],
								[113.884026, 22.371805],
								[113.883751, 22.371805],
								[113.883751, 22.370701],
								[113.883469, 22.370695],
								[113.883469, 22.370417],
								[113.883194, 22.370417],
								[113.883194, 22.370136],
								[113.882919, 22.370136],
								[113.882919, 22.369862],
								[113.882637, 22.369862],
								[113.882637, 22.369583],
								[113.880974, 22.369583],
								[113.880974, 22.370136],
								[113.880417, 22.370136],
								[113.880417, 22.370417],
								[113.880142, 22.370417],
								[113.880142, 22.370972],
								[113.880417, 22.370972],
								[113.880417, 22.371805],
								[113.880692, 22.371805],
								[113.880692, 22.372358],
								[113.880974, 22.372358],
								[113.880974, 22.374306],
								[113.881531, 22.374306],
								[113.881531, 22.374584],
								[113.882080, 22.374584],
								[113.882080, 22.376528],
								[113.881805, 22.376528],
								[113.881805, 22.377359],
								[113.881531, 22.377361],
								[113.881531, 22.377640],
								[113.881248, 22.377640],
								[113.881248, 22.377916],
								[113.880974, 22.377916],
								[113.880974, 22.378195],
								[113.880417, 22.378195],
								[113.880417, 22.378471],
								[113.879593, 22.378471],
								[113.879585, 22.378750],
								[113.879303, 22.378750],
								[113.879303, 22.379028],
								[113.878754, 22.379028],
								[113.878754, 22.379305],
								[113.877357, 22.379305],
								[113.877357, 22.379583],
								[113.877083, 22.379583],
								[113.877083, 22.380970],
								[113.876808, 22.380972],
								[113.876808, 22.381805],
								[113.877083, 22.381805],
								[113.877083, 22.382084],
								[113.879860, 22.382080]
							]
						],
						[
							[
								[113.938255, 22.426823],
								[113.938194, 22.427084],
								[113.938469, 22.427084],
								[113.938255, 22.426823]
							]
						],
						[
							[
								[113.938255, 22.426823],
								[113.938004, 22.426107],
								[113.938133, 22.425476],
								[113.938423, 22.424006],
								[113.938423, 22.422743],
								[113.938004, 22.421476],
								[113.938622, 22.420250],
								[113.938843, 22.419794],
								[113.940109, 22.421476],
								[113.940948, 22.422319],
								[113.942215, 22.423161],
								[113.943054, 22.424425],
								[113.943626, 22.425283],
								[113.943901, 22.425690],
								[113.945160, 22.426950],
								[113.945343, 22.427488],
								[113.945580, 22.428213],
								[113.945930, 22.428917],
								[113.946426, 22.429897],
								[113.947266, 22.431162],
								[113.947403, 22.431580],
								[113.947685, 22.432425],
								[113.948181, 22.433168],
								[113.948532, 22.433685],
								[113.969162, 22.433266],
								[113.969162, 22.432001],
								[113.970078, 22.431393],
								[113.970421, 22.431162],
								[113.971687, 22.431162],
								[113.972946, 22.431162],
								[113.974213, 22.431162],
								[113.975471, 22.431162],
								[113.976738, 22.431162],
								[113.977997, 22.431162],
								[113.979263, 22.431162],
								[113.981789, 22.431162],
								[113.982323, 22.430981],
								[113.983055, 22.430738],
								[113.983803, 22.430990],
								[113.984314, 22.431162],
								[113.985069, 22.430656],
								[113.985580, 22.430319],
								[113.987259, 22.430738],
								[113.987938, 22.430876],
								[113.988396, 22.430965],
								[113.989365, 22.431162],
								[113.989929, 22.431723],
								[113.990211, 22.432001],
								[113.992599, 22.432343],
								[113.993156, 22.432425],
								[113.993629, 22.432516],
								[113.995262, 22.432842],
								[113.995262, 22.431580],
								[113.994415, 22.430319],
								[113.993805, 22.429398],
								[113.993576, 22.429054],
								[113.993576, 22.427792],
								[113.993576, 22.426531],
								[113.995338, 22.424065],
								[113.995682, 22.423582],
								[113.996521, 22.422743],
								[113.997787, 22.423161],
								[113.998291, 22.423037],
								[113.999474, 22.422743],
								[114.000732, 22.422743],
								[114.001999, 22.422743],
								[114.003258, 22.422743],
								[114.004524, 22.422743],
								[114.005783, 22.422743],
								[114.006119, 22.421747],
								[114.006203, 22.421476],
								[114.006203, 22.420214],
								[114.006203, 22.418953],
								[114.006203, 22.417688],
								[114.006203, 22.416426],
								[114.007469, 22.415583],
								[114.008736, 22.415583],
								[114.009995, 22.415583],
								[114.010445, 22.415138],
								[114.010834, 22.414742],
								[114.010834, 22.413479],
								[114.010834, 22.412214],
								[114.010834, 22.410952],
								[114.010834, 22.409689],
								[114.010834, 22.408426],
								[114.010834, 22.407164],
								[114.010902, 22.406973],
								[114.011261, 22.405901],
								[114.012520, 22.405901],
								[114.013786, 22.405901],
								[114.015045, 22.405901],
								[114.016312, 22.405901],
								[114.017570, 22.405901],
								[114.018837, 22.405901],
								[114.020096, 22.405901],
								[114.021362, 22.405901],
								[114.022629, 22.405901],
								[114.023888, 22.405901],
								[114.025154, 22.405901],
								[114.049988, 22.405901],
								[114.049988, 22.407164],
								[114.049988, 22.408426],
								[114.050407, 22.408840],
								[114.050835, 22.409267],
								[114.052094, 22.409267],
								[114.053360, 22.409267],
								[114.054619, 22.409267],
								[114.055885, 22.409267],
								[114.057144, 22.409267],
								[114.058411, 22.409267],
								[114.059250, 22.408426],
								[114.058998, 22.407665],
								[114.058830, 22.407164],
								[114.058830, 22.405901],
								[114.057571, 22.405479],
								[114.056305, 22.405479],
								[114.056030, 22.404640],
								[114.055885, 22.404217],
								[114.055885, 22.402952],
								[114.055885, 22.401690],
								[114.055885, 22.400427],
								[114.055885, 22.399164],
								[114.055885, 22.397902],
								[114.057144, 22.397902],
								[114.058411, 22.397902],
								[114.059677, 22.397902],
								[114.060936, 22.397902],
								[114.062202, 22.397902],
								[114.063461, 22.397902],
								[114.064728, 22.397058],
								[114.064423, 22.396156],
								[114.064308, 22.395796],
								[114.064308, 22.394533],
								[114.064308, 22.393270],
								[114.064308, 22.392008],
								[114.064308, 22.390745],
								[114.063461, 22.389902],
								[114.063286, 22.389389],
								[114.063179, 22.389061],
								[114.063042, 22.388639],
								[114.063042, 22.387377],
								[114.063042, 22.386114],
								[114.062073, 22.385920],
								[114.061722, 22.385851],
								[114.060936, 22.385693],
								[114.059677, 22.385693],
								[114.058411, 22.385693],
								[114.057976, 22.385836],
								[114.057144, 22.386114],
								[114.055046, 22.386114],
								[114.054199, 22.385271],
								[114.053360, 22.384430],
								[114.052094, 22.384745],
								[114.051674, 22.384850],
								[114.051102, 22.384995],
								[114.050575, 22.385124],
								[114.049988, 22.385271],
								[114.049149, 22.386114],
								[114.047882, 22.386114],
								[114.047615, 22.386204],
								[114.047058, 22.386391],
								[114.046623, 22.386534],
								[114.045357, 22.385271],
								[114.045105, 22.384523],
								[114.044937, 22.384008],
								[114.045158, 22.383339],
								[114.045357, 22.382746],
								[114.045784, 22.381062],
								[114.045784, 22.379799],
								[114.044518, 22.379799],
								[114.043251, 22.379799],
								[114.042412, 22.380638],
								[114.041519, 22.380791],
								[114.039886, 22.381062],
								[114.038200, 22.382324],
								[114.038002, 22.382124],
								[114.036942, 22.381062],
								[114.035675, 22.379377],
								[114.035507, 22.379206],
								[114.034836, 22.378532],
								[114.034004, 22.378258],
								[114.033569, 22.378115],
								[114.031891, 22.378956],
								[114.031044, 22.378532],
								[114.030205, 22.378115],
								[114.030205, 22.374746],
								[114.030853, 22.374617],
								[114.032310, 22.374325],
								[114.032310, 22.373060],
								[114.032516, 22.372440],
								[114.032730, 22.371799],
								[114.033569, 22.370537],
								[114.034836, 22.369694],
								[114.038620, 22.370537],
								[114.038811, 22.369974],
								[114.039047, 22.369272],
								[114.038925, 22.368914],
								[114.038620, 22.368008],
								[114.037834, 22.367483],
								[114.037361, 22.367167],
								[114.036140, 22.366924],
								[114.035255, 22.366747],
								[114.033989, 22.366747],
								[114.033691, 22.366446],
								[114.033150, 22.365906],
								[114.032951, 22.365313],
								[114.032730, 22.364641],
								[114.030205, 22.362118],
								[114.029213, 22.361456],
								[114.028938, 22.361275],
								[114.026413, 22.359592],
								[114.026016, 22.358391],
								[114.026268, 22.356176],
								[114.027405, 22.355421],
								[114.027359, 22.355141],
								[114.027084, 22.355139],
								[114.027084, 22.354860],
								[114.026253, 22.354860],
								[114.026253, 22.355139],
								[114.025139, 22.355139],
								[114.025139, 22.355415],
								[114.023750, 22.355415],
								[114.023750, 22.355694],
								[114.023193, 22.355694],
								[114.023193, 22.355972],
								[114.022362, 22.355972],
								[114.022362, 22.355694],
								[114.022087, 22.355694],
								[114.022087, 22.355415],
								[114.021248, 22.355415],
								[114.021248, 22.355694],
								[114.020416, 22.355694],
								[114.020348, 22.355972],
								[114.020142, 22.355972],
								[114.020142, 22.356251],
								[114.019859, 22.356251],
								[114.019859, 22.356527],
								[114.019302, 22.356527],
								[114.019302, 22.356806],
								[114.019028, 22.356806],
								[114.019028, 22.357084],
								[114.018753, 22.357084],
								[114.018753, 22.357361],
								[114.018471, 22.357361],
								[114.018471, 22.357637],
								[114.018196, 22.357637],
								[114.018196, 22.358194],
								[114.017914, 22.358194],
								[114.017914, 22.358747],
								[114.017639, 22.358747],
								[114.017639, 22.359583],
								[114.017365, 22.359583],
								[114.017365, 22.359861],
								[114.017082, 22.359861],
								[114.017082, 22.360140],
								[114.016808, 22.360142],
								[114.016808, 22.360416],
								[114.016327, 22.360416],
								[114.016251, 22.360416],
								[114.016251, 22.360970],
								[114.015701, 22.360970],
								[114.015694, 22.361528],
								[114.015419, 22.361528],
								[114.015419, 22.361805],
								[114.015137, 22.361805],
								[114.015137, 22.362364],
								[114.014580, 22.362364],
								[114.014580, 22.362638],
								[114.014305, 22.362638],
								[114.014305, 22.362917],
								[114.013748, 22.362917],
								[114.013748, 22.362640],
								[114.012360, 22.362638],
								[114.012360, 22.362364],
								[114.012085, 22.362364],
								[114.012085, 22.362083],
								[114.011253, 22.362083],
								[114.011253, 22.361805],
								[114.010971, 22.361805],
								[114.010971, 22.362083],
								[114.010696, 22.362083],
								[114.010696, 22.362364],
								[114.010414, 22.362364],
								[114.010414, 22.364305],
								[114.010139, 22.364305],
								[114.010139, 22.365139],
								[114.009865, 22.365139],
								[114.009865, 22.365417],
								[114.009026, 22.365417],
								[114.009026, 22.365139],
								[114.007919, 22.365139],
								[114.007919, 22.364861],
								[114.007637, 22.364861],
								[114.007637, 22.364582],
								[114.007362, 22.364582],
								[114.007362, 22.364305],
								[114.007080, 22.364305],
								[114.007080, 22.364040],
								[114.005974, 22.364027],
								[114.005974, 22.363750],
								[114.005142, 22.363750],
								[114.005142, 22.363474],
								[114.004585, 22.363474],
								[114.004585, 22.363195],
								[114.004303, 22.363194],
								[114.004303, 22.362638],
								[114.004196, 22.362638],
								[114.003754, 22.362638],
								[114.003754, 22.362364],
								[114.002640, 22.362364],
								[114.002640, 22.362083],
								[114.000969, 22.362083],
								[114.000969, 22.361805],
								[114.000023, 22.361805],
								[113.999306, 22.361805],
								[113.999306, 22.362083],
								[113.998749, 22.362083],
								[113.998749, 22.362364],
								[113.998474, 22.362364],
								[113.998474, 22.362638],
								[113.998192, 22.362638],
								[113.998192, 22.363190],
								[113.997917, 22.363195],
								[113.997917, 22.363474],
								[113.997360, 22.363474],
								[113.997360, 22.363750],
								[113.996246, 22.363750],
								[113.996246, 22.364305],
								[113.995735, 22.364305],
								[113.995697, 22.364582],
								[113.995415, 22.364582],
								[113.995415, 22.364305],
								[113.994583, 22.364305],
								[113.994583, 22.364582],
								[113.994308, 22.364582],
								[113.994308, 22.364861],
								[113.992638, 22.364861],
								[113.992638, 22.365139],
								[113.992363, 22.365139],
								[113.992363, 22.365696],
								[113.992081, 22.365696],
								[113.992081, 22.365973],
								[113.991821, 22.365973],
								[113.991806, 22.366804],
								[113.991531, 22.366804],
								[113.991531, 22.367640],
								[113.991806, 22.367640],
								[113.991806, 22.367914],
								[113.990692, 22.367914],
								[113.990692, 22.368195],
								[113.990417, 22.368195],
								[113.990417, 22.368750],
								[113.990135, 22.368750],
								[113.990135, 22.369862],
								[113.989304, 22.369862],
								[113.989304, 22.369026],
								[113.989586, 22.369026],
								[113.989586, 22.368750],
								[113.989304, 22.368750],
								[113.989304, 22.367640],
								[113.988747, 22.367640],
								[113.988747, 22.366804],
								[113.988472, 22.366804],
								[113.988472, 22.366249],
								[113.988197, 22.366249],
								[113.988197, 22.365696],
								[113.987915, 22.365696],
								[113.987915, 22.365417],
								[113.987640, 22.365417],
								[113.987640, 22.365139],
								[113.987358, 22.365139],
								[113.987358, 22.364582],
								[113.986809, 22.364582],
								[113.986809, 22.364305],
								[113.986664, 22.364305],
								[113.984581, 22.364305],
								[113.984581, 22.364040],
								[113.983749, 22.364027],
								[113.983749, 22.363474],
								[113.983032, 22.363474],
								[113.982918, 22.363474],
								[113.982918, 22.363195],
								[113.982635, 22.363194],
								[113.982635, 22.362925],
								[113.981804, 22.362917],
								[113.981804, 22.363194],
								[113.981529, 22.363195],
								[113.981529, 22.363474],
								[113.981247, 22.363474],
								[113.981247, 22.364305],
								[113.980736, 22.364305],
								[113.980698, 22.364582],
								[113.980141, 22.364582],
								[113.980141, 22.365139],
								[113.979858, 22.365139],
								[113.979858, 22.365417],
								[113.979309, 22.365417],
								[113.979309, 22.363750],
								[113.979584, 22.363750],
								[113.979584, 22.362917],
								[113.980141, 22.362917],
								[113.980141, 22.362083],
								[113.980415, 22.362083],
								[113.980415, 22.361528],
								[113.980141, 22.361528],
								[113.980141, 22.361252],
								[113.979858, 22.361252],
								[113.979858, 22.360695],
								[113.979317, 22.360695],
								[113.979309, 22.360970],
								[113.979027, 22.360970],
								[113.979027, 22.360695],
								[113.977081, 22.360695],
								[113.977081, 22.360970],
								[113.976807, 22.360970],
								[113.976807, 22.361252],
								[113.976524, 22.361252],
								[113.976524, 22.361528],
								[113.976807, 22.361528],
								[113.976807, 22.362638],
								[113.977081, 22.362638],
								[113.977081, 22.362917],
								[113.976807, 22.362917],
								[113.976807, 22.363474],
								[113.976524, 22.363474],
								[113.976524, 22.363750],
								[113.976250, 22.363750],
								[113.976250, 22.364305],
								[113.975990, 22.364305],
								[113.975975, 22.364582],
								[113.975693, 22.364582],
								[113.975693, 22.364861],
								[113.975136, 22.364861],
								[113.975136, 22.365417],
								[113.974861, 22.365417],
								[113.974861, 22.365696],
								[113.974304, 22.365696],
								[113.974304, 22.366249],
								[113.974030, 22.366249],
								[113.974030, 22.366528],
								[113.973473, 22.366528],
								[113.973473, 22.366804],
								[113.971527, 22.366804],
								[113.971527, 22.367083],
								[113.971252, 22.367083],
								[113.971252, 22.367640],
								[113.970711, 22.367640],
								[113.970695, 22.367914],
								[113.970139, 22.367914],
								[113.970139, 22.368195],
								[113.969864, 22.368195],
								[113.969864, 22.368750],
								[113.967918, 22.368750],
								[113.967918, 22.368195],
								[113.967636, 22.368195],
								[113.967636, 22.367361],
								[113.967918, 22.367361],
								[113.967918, 22.367083],
								[113.968193, 22.367083],
								[113.968193, 22.366528],
								[113.968475, 22.366528],
								[113.968475, 22.364311],
								[113.967651, 22.364305],
								[113.967636, 22.364027],
								[113.967361, 22.364027],
								[113.967361, 22.363750],
								[113.967087, 22.363750],
								[113.967087, 22.363474],
								[113.965645, 22.363474],
								[113.965416, 22.363474],
								[113.965416, 22.363195],
								[113.965141, 22.363194],
								[113.965141, 22.362917],
								[113.964027, 22.362917],
								[113.964027, 22.362638],
								[113.962639, 22.362638],
								[113.962639, 22.362917],
								[113.962364, 22.362917],
								[113.962364, 22.363474],
								[113.962082, 22.363474],
								[113.962082, 22.363750],
								[113.961525, 22.363750],
								[113.961525, 22.364305],
								[113.961266, 22.364305],
								[113.961250, 22.364582],
								[113.960693, 22.364582],
								[113.960693, 22.364861],
								[113.960419, 22.364861],
								[113.960419, 22.365417],
								[113.960136, 22.365417],
								[113.960136, 22.365696],
								[113.959587, 22.365696],
								[113.959587, 22.365417],
								[113.959457, 22.365417],
								[113.959030, 22.365417],
								[113.959030, 22.364861],
								[113.959305, 22.364861],
								[113.959305, 22.363474],
								[113.959549, 22.363474],
								[113.959587, 22.362917],
								[113.959862, 22.362917],
								[113.959862, 22.362364],
								[113.960129, 22.362364],
								[113.960136, 22.362083],
								[113.960419, 22.362083],
								[113.960419, 22.360416],
								[113.960693, 22.360416],
								[113.960693, 22.359583],
								[113.960968, 22.359583],
								[113.960976, 22.359306],
								[113.961525, 22.359306],
								[113.961525, 22.359583],
								[113.961807, 22.359583],
								[113.961807, 22.359861],
								[113.962082, 22.359861],
								[113.962082, 22.359306],
								[113.962639, 22.359306],
								[113.962639, 22.359030],
								[113.962906, 22.359030],
								[113.962914, 22.358473],
								[113.963196, 22.358473],
								[113.963196, 22.358194],
								[113.962914, 22.358194],
								[113.962914, 22.357916],
								[113.962082, 22.357916],
								[113.962082, 22.357084],
								[113.961807, 22.357084],
								[113.961807, 22.356527],
								[113.961250, 22.356527],
								[113.961250, 22.356806],
								[113.960976, 22.356806],
								[113.960976, 22.357361],
								[113.960419, 22.357361],
								[113.960419, 22.357637],
								[113.960136, 22.357637],
								[113.960136, 22.358194],
								[113.959862, 22.358194],
								[113.959862, 22.358473],
								[113.959587, 22.358473],
								[113.959587, 22.359030],
								[113.959030, 22.359030],
								[113.959030, 22.359306],
								[113.957916, 22.359306],
								[113.957916, 22.359583],
								[113.957642, 22.359583],
								[113.957642, 22.359861],
								[113.957085, 22.359861],
								[113.957085, 22.360416],
								[113.956802, 22.360416],
								[113.956802, 22.360142],
								[113.955971, 22.360140],
								[113.955971, 22.359861],
								[113.956253, 22.359861],
								[113.956253, 22.359306],
								[113.956528, 22.359306],
								[113.956528, 22.356806],
								[113.957077, 22.356806],
								[113.957085, 22.355139],
								[113.956528, 22.355139],
								[113.956528, 22.354860],
								[113.956253, 22.354860],
								[113.956253, 22.354584],
								[113.955971, 22.354584],
								[113.955971, 22.354860],
								[113.955711, 22.354860],
								[113.955696, 22.355139],
								[113.955177, 22.355139],
								[113.955139, 22.355694],
								[113.954865, 22.355694],
								[113.954865, 22.355972],
								[113.955139, 22.355972],
								[113.955139, 22.356251],
								[113.955414, 22.356251],
								[113.955414, 22.356806],
								[113.955696, 22.356806],
								[113.955696, 22.357084],
								[113.955139, 22.357084],
								[113.955139, 22.356806],
								[113.954865, 22.356806],
								[113.954865, 22.357084],
								[113.954582, 22.357084],
								[113.954582, 22.355972],
								[113.954308, 22.355972],
								[113.954308, 22.355694],
								[113.953194, 22.355694],
								[113.953194, 22.355972],
								[113.951805, 22.355972],
								[113.951805, 22.356251],
								[113.951248, 22.356251],
								[113.951248, 22.356527],
								[113.950691, 22.356527],
								[113.950691, 22.356806],
								[113.950417, 22.356806],
								[113.950417, 22.357361],
								[113.950142, 22.357361],
								[113.950142, 22.357637],
								[113.949860, 22.357637],
								[113.949860, 22.357916],
								[113.949303, 22.357916],
								[113.949303, 22.358194],
								[113.948753, 22.358194],
								[113.948753, 22.358747],
								[113.948471, 22.358747],
								[113.948471, 22.359030],
								[113.948196, 22.359030],
								[113.948196, 22.359583],
								[113.947914, 22.359583],
								[113.947914, 22.359861],
								[113.947639, 22.359861],
								[113.947639, 22.359306],
								[113.947365, 22.359306],
								[113.947365, 22.359030],
								[113.946808, 22.359030],
								[113.946808, 22.358747],
								[113.945969, 22.358747],
								[113.945969, 22.359030],
								[113.945419, 22.359030],
								[113.945419, 22.359306],
								[113.945137, 22.359306],
								[113.945137, 22.360140],
								[113.944862, 22.360142],
								[113.944862, 22.360416],
								[113.944382, 22.360416],
								[113.944305, 22.360416],
								[113.944305, 22.360695],
								[113.943474, 22.360695],
								[113.943474, 22.360970],
								[113.943192, 22.360970],
								[113.943192, 22.361252],
								[113.942360, 22.361252],
								[113.942360, 22.361528],
								[113.941803, 22.361528],
								[113.941803, 22.361805],
								[113.941528, 22.361805],
								[113.941528, 22.362083],
								[113.941254, 22.362083],
								[113.941254, 22.362364],
								[113.940971, 22.362364],
								[113.940971, 22.362917],
								[113.941254, 22.362917],
								[113.941254, 22.363474],
								[113.941528, 22.363474],
								[113.941528, 22.364305],
								[113.941269, 22.364305],
								[113.941254, 22.364582],
								[113.940971, 22.364582],
								[113.940971, 22.365696],
								[113.939857, 22.365696],
								[113.939857, 22.366249],
								[113.939308, 22.366249],
								[113.939308, 22.366804],
								[113.939026, 22.366804],
								[113.939026, 22.367640],
								[113.938751, 22.367640],
								[113.938751, 22.367914],
								[113.937920, 22.367914],
								[113.937920, 22.367640],
								[113.937637, 22.367640],
								[113.937637, 22.367083],
								[113.937363, 22.367083],
								[113.937363, 22.366249],
								[113.937080, 22.366249],
								[113.937080, 22.365417],
								[113.936806, 22.365417],
								[113.936806, 22.365139],
								[113.936531, 22.365139],
								[113.936531, 22.364582],
								[113.936249, 22.364582],
								[113.936249, 22.363750],
								[113.935974, 22.363750],
								[113.935974, 22.362917],
								[113.935692, 22.362917],
								[113.935692, 22.362640],
								[113.935417, 22.362638],
								[113.935417, 22.361811],
								[113.935143, 22.361805],
								[113.935143, 22.361252],
								[113.934029, 22.361252],
								[113.934029, 22.361528],
								[113.933746, 22.361528],
								[113.933746, 22.361805],
								[113.933472, 22.361805],
								[113.933472, 22.363194],
								[113.933746, 22.363194],
								[113.933746, 22.364027],
								[113.934029, 22.364027],
								[113.934029, 22.364861],
								[113.934303, 22.364861],
								[113.934303, 22.365696],
								[113.934586, 22.365696],
								[113.934586, 22.366249],
								[113.934860, 22.366249],
								[113.934860, 22.366804],
								[113.934586, 22.366804],
								[113.934586, 22.367640],
								[113.934303, 22.367640],
								[113.934303, 22.367914],
								[113.934029, 22.367914],
								[113.933960, 22.368473],
								[113.933472, 22.368473],
								[113.933472, 22.368750],
								[113.931526, 22.368750],
								[113.931526, 22.368473],
								[113.931252, 22.368473],
								[113.931252, 22.368195],
								[113.930695, 22.368195],
								[113.930695, 22.367640],
								[113.930420, 22.367640],
								[113.930420, 22.367083],
								[113.929863, 22.367083],
								[113.929863, 22.366804],
								[113.927635, 22.366804],
								[113.927635, 22.366528],
								[113.927361, 22.366528],
								[113.927361, 22.366249],
								[113.927086, 22.366249],
								[113.927086, 22.365973],
								[113.926529, 22.365973],
								[113.926529, 22.366249],
								[113.926247, 22.366249],
								[113.926247, 22.366528],
								[113.925415, 22.366528],
								[113.925415, 22.366804],
								[113.924858, 22.366804],
								[113.924858, 22.367083],
								[113.924583, 22.367083],
								[113.924583, 22.367361],
								[113.924026, 22.367361],
								[113.924026, 22.367640],
								[113.922638, 22.367640],
								[113.922638, 22.367914],
								[113.922363, 22.367914],
								[113.922363, 22.368195],
								[113.921806, 22.368195],
								[113.921806, 22.368473],
								[113.921524, 22.368473],
								[113.921524, 22.368750],
								[113.921249, 22.368750],
								[113.921249, 22.369026],
								[113.920418, 22.369026],
								[113.920418, 22.369862],
								[113.920692, 22.369862],
								[113.920692, 22.370136],
								[113.920975, 22.370136],
								[113.920975, 22.370695],
								[113.920692, 22.370695],
								[113.920692, 22.371531],
								[113.920418, 22.371531],
								[113.920418, 22.371805],
								[113.920135, 22.371805],
								[113.920135, 22.372358],
								[113.919861, 22.372358],
								[113.919861, 22.373194],
								[113.919586, 22.373194],
								[113.919586, 22.373751],
								[113.919029, 22.373751],
								[113.919029, 22.373472],
								[113.918747, 22.373472],
								[113.918747, 22.373194],
								[113.918472, 22.373194],
								[113.918472, 22.372084],
								[113.918198, 22.372084],
								[113.918198, 22.371805],
								[113.917915, 22.371805],
								[113.917915, 22.371531],
								[113.917641, 22.371531],
								[113.917641, 22.371805],
								[113.917358, 22.371805],
								[113.917358, 22.372084],
								[113.917084, 22.372084],
								[113.917084, 22.372641],
								[113.916832, 22.372641],
								[113.916809, 22.373194],
								[113.916252, 22.373194],
								[113.916252, 22.373751],
								[113.915970, 22.373751],
								[113.915970, 22.374027],
								[113.915695, 22.374027],
								[113.915695, 22.374306],
								[113.915413, 22.374306],
								[113.915413, 22.374863],
								[113.915138, 22.374863],
								[113.915138, 22.375139],
								[113.915413, 22.375139],
								[113.915413, 22.375416],
								[113.915138, 22.375416],
								[113.915138, 22.375973],
								[113.914864, 22.375973],
								[113.914864, 22.376528],
								[113.915131, 22.376528],
								[113.915077, 22.377361],
								[113.914864, 22.377361],
								[113.914864, 22.377640],
								[113.915138, 22.377640],
								[113.915138, 22.377916],
								[113.915695, 22.377916],
								[113.915695, 22.378195],
								[113.916252, 22.378195],
								[113.916252, 22.378471],
								[113.916527, 22.378471],
								[113.916527, 22.379028],
								[113.916809, 22.379028],
								[113.916809, 22.378750],
								[113.917641, 22.378750],
								[113.917641, 22.379305],
								[113.918198, 22.379305],
								[113.918198, 22.379583],
								[113.919029, 22.379583],
								[113.919029, 22.380138],
								[113.919304, 22.380138],
								[113.919304, 22.380417],
								[113.919586, 22.380417],
								[113.919586, 22.380972],
								[113.919861, 22.380972],
								[113.919861, 22.382084],
								[113.920135, 22.382084],
								[113.920135, 22.384308],
								[113.920418, 22.384308],
								[113.920418, 22.384859],
								[113.920692, 22.384859],
								[113.920692, 22.385138],
								[113.920967, 22.385138],
								[113.920975, 22.386806],
								[113.920692, 22.386806],
								[113.920692, 22.388195],
								[113.920418, 22.388195],
								[113.920418, 22.389029],
								[113.920174, 22.389029],
								[113.920135, 22.389862],
								[113.919601, 22.389862],
								[113.919586, 22.390139],
								[113.919029, 22.390139],
								[113.919029, 22.390417],
								[113.918747, 22.390417],
								[113.918747, 22.390694],
								[113.918198, 22.390694],
								[113.918198, 22.390972],
								[113.917358, 22.390972],
								[113.917358, 22.391251],
								[113.916809, 22.391251],
								[113.916809, 22.391527],
								[113.916527, 22.391527],
								[113.916527, 22.391806],
								[113.916008, 22.391806],
								[113.915970, 22.392084],
								[113.915138, 22.392084],
								[113.915138, 22.392361],
								[113.914864, 22.392361],
								[113.914864, 22.393749],
								[113.914581, 22.393749],
								[113.914581, 22.395140],
								[113.914307, 22.395140],
								[113.914307, 22.395416],
								[113.914024, 22.395416],
								[113.914024, 22.395695],
								[113.913750, 22.395695],
								[113.913750, 22.396805],
								[113.914024, 22.396805],
								[113.914024, 22.397083],
								[113.914581, 22.397083],
								[113.914581, 22.397362],
								[113.914856, 22.397362],
								[113.914864, 22.397636],
								[113.915138, 22.397636],
								[113.915138, 22.398472],
								[113.914864, 22.398472],
								[113.914864, 22.399305],
								[113.915138, 22.399305],
								[113.915138, 22.400417],
								[113.914864, 22.400417],
								[113.914864, 22.400972],
								[113.914581, 22.400972],
								[113.914581, 22.401253],
								[113.914024, 22.401253],
								[113.914024, 22.401527],
								[113.913750, 22.401527],
								[113.913750, 22.401806],
								[113.913475, 22.401806],
								[113.913475, 22.402363],
								[113.912918, 22.402363],
								[113.912918, 22.402639],
								[113.911804, 22.402639],
								[113.911804, 22.402363],
								[113.910698, 22.402363],
								[113.910698, 22.402916],
								[113.910141, 22.402916],
								[113.910141, 22.403194],
								[113.909859, 22.403194],
								[113.909859, 22.403473],
								[113.909584, 22.403473],
								[113.909584, 22.403749],
								[113.909027, 22.403749],
								[113.909027, 22.404028],
								[113.907913, 22.404028],
								[113.907913, 22.403749],
								[113.906807, 22.403749],
								[113.906807, 22.404028],
								[113.905975, 22.404028],
								[113.905975, 22.404306],
								[113.904495, 22.404306],
								[113.904305, 22.404306],
								[113.904305, 22.404585],
								[113.903198, 22.404585],
								[113.903198, 22.404861],
								[113.902359, 22.404861],
								[113.902359, 22.405416],
								[113.901802, 22.405416],
								[113.901802, 22.405693],
								[113.901527, 22.405693],
								[113.901527, 22.406250],
								[113.901802, 22.406250],
								[113.901802, 22.406530],
								[113.902031, 22.406528],
								[113.902084, 22.406807],
								[113.902359, 22.406807],
								[113.902359, 22.407362],
								[113.902641, 22.407362],
								[113.902641, 22.407640],
								[113.902870, 22.407639],
								[113.902916, 22.408194],
								[113.903198, 22.408194],
								[113.903198, 22.408472],
								[113.903473, 22.408472],
								[113.903473, 22.409025],
								[113.903748, 22.409025],
								[113.903748, 22.409584],
								[113.904030, 22.409584],
								[113.904030, 22.409861],
								[113.904305, 22.409861],
								[113.904305, 22.410419],
								[113.904549, 22.410419],
								[113.904587, 22.410694],
								[113.904861, 22.410694],
								[113.904861, 22.410973],
								[113.905418, 22.410973],
								[113.905418, 22.411247],
								[113.905693, 22.411247],
								[113.905693, 22.411530],
								[113.905975, 22.411530],
								[113.905975, 22.412083],
								[113.906250, 22.412083],
								[113.906250, 22.412359],
								[113.906525, 22.412359],
								[113.906525, 22.412638],
								[113.906807, 22.412638],
								[113.906807, 22.413195],
								[113.907082, 22.413195],
								[113.907082, 22.413473],
								[113.907227, 22.413473],
								[113.908195, 22.413473],
								[113.908195, 22.414028],
								[113.908470, 22.414028],
								[113.908470, 22.414583],
								[113.908752, 22.414583],
								[113.908752, 22.414862],
								[113.909302, 22.414925],
								[113.909309, 22.415138],
								[113.909584, 22.415138],
								[113.909584, 22.415417],
								[113.909859, 22.415417],
								[113.909859, 22.415695],
								[113.910416, 22.415695],
								[113.910416, 22.415974],
								[113.910698, 22.415974],
								[113.910698, 22.416250],
								[113.910973, 22.416250],
								[113.910973, 22.416529],
								[113.911247, 22.416529],
								[113.911247, 22.416805],
								[113.911804, 22.416805],
								[113.911804, 22.417084],
								[113.912086, 22.417084],
								[113.912086, 22.417360],
								[113.912361, 22.417360],
								[113.912361, 22.417639],
								[113.912918, 22.417639],
								[113.912918, 22.417917],
								[113.913193, 22.417917],
								[113.913193, 22.418472],
								[113.913467, 22.418472],
								[113.913475, 22.419861],
								[113.913750, 22.419861],
								[113.913750, 22.420973],
								[113.914017, 22.420973],
								[113.914024, 22.421249],
								[113.914307, 22.421249],
								[113.914307, 22.421806],
								[113.914581, 22.421806],
								[113.914581, 22.421530],
								[113.915695, 22.421528],
								[113.915695, 22.421806],
								[113.917084, 22.421806],
								[113.917084, 22.422083],
								[113.917915, 22.422083],
								[113.917915, 22.422361],
								[113.918472, 22.422361],
								[113.918472, 22.422638],
								[113.919304, 22.422638],
								[113.919304, 22.422916],
								[113.920044, 22.422916],
								[113.920135, 22.422916],
								[113.920135, 22.423195],
								[113.920975, 22.423195],
								[113.920975, 22.423471],
								[113.921516, 22.423471],
								[113.921524, 22.423750],
								[113.922363, 22.423750],
								[113.922363, 22.424026],
								[113.925407, 22.424026],
								[113.925415, 22.423750],
								[113.927635, 22.423750],
								[113.927635, 22.423231],
								[113.928749, 22.423195],
								[113.928749, 22.423750],
								[113.929306, 22.423750],
								[113.929306, 22.424026],
								[113.929581, 22.424026],
								[113.929581, 22.424305],
								[113.930695, 22.424305],
								[113.930695, 22.424583],
								[113.931526, 22.424583],
								[113.931526, 22.424862],
								[113.932358, 22.424862],
								[113.932358, 22.425140],
								[113.932915, 22.425140],
								[113.932915, 22.425419],
								[113.933197, 22.425419],
								[113.933197, 22.425694],
								[113.933746, 22.425694],
								[113.933746, 22.425970],
								[113.934586, 22.425970],
								[113.934586, 22.426249],
								[113.935692, 22.426249],
								[113.935692, 22.426529],
								[113.937637, 22.426529],
								[113.937637, 22.426805],
								[113.938255, 22.426823]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Wan Chai",
					"ID_0": 102,
					"ID_1": 15,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.166977, 22.281528],
							[114.168076, 22.281528],
							[114.168472, 22.281528],
							[114.168472, 22.281805],
							[114.171249, 22.281805],
							[114.171249, 22.282917],
							[114.171524, 22.282917],
							[114.171524, 22.284861],
							[114.172081, 22.284861],
							[114.172081, 22.284582],
							[114.173195, 22.284582],
							[114.173195, 22.284861],
							[114.173752, 22.284861],
							[114.173752, 22.284582],
							[114.174309, 22.284582],
							[114.174309, 22.284861],
							[114.174583, 22.284861],
							[114.174583, 22.284306],
							[114.174858, 22.284306],
							[114.174858, 22.284027],
							[114.174583, 22.284027],
							[114.174583, 22.283751],
							[114.174858, 22.283751],
							[114.174858, 22.282917],
							[114.175415, 22.282917],
							[114.175415, 22.282639],
							[114.176804, 22.282639],
							[114.176804, 22.282917],
							[114.177361, 22.282917],
							[114.177361, 22.283194],
							[114.177475, 22.283194],
							[114.177635, 22.283194],
							[114.177635, 22.283472],
							[114.177917, 22.283470],
							[114.180305, 22.283072],
							[114.180473, 22.283043],
							[114.182129, 22.283279],
							[114.182449, 22.283232],
							[114.183044, 22.283381],
							[114.183495, 22.283079],
							[114.183601, 22.283064],
							[114.184135, 22.282984],
							[114.185379, 22.282808],
							[114.187210, 22.281742],
							[114.187447, 22.280857],
							[114.187447, 22.280716],
							[114.187553, 22.280739],
							[114.188919, 22.281284],
							[114.189705, 22.281597],
							[114.190346, 22.281855],
							[114.191025, 22.282125],
							[114.191681, 22.282560],
							[114.192009, 22.282782],
							[114.192291, 22.282969],
							[114.192490, 22.282156],
							[114.192711, 22.281284],
							[114.193970, 22.280443],
							[114.194214, 22.279469],
							[114.194397, 22.278761],
							[114.194679, 22.278034],
							[114.195053, 22.277111],
							[114.195343, 22.276524],
							[114.197304, 22.274239],
							[114.197372, 22.274162],
							[114.197884, 22.273785],
							[114.199448, 22.274548],
							[114.200706, 22.275406],
							[114.200706, 22.275646],
							[114.200706, 22.276653],
							[114.201553, 22.277494],
							[114.202492, 22.276867],
							[114.202812, 22.276653],
							[114.202911, 22.276558],
							[114.203873, 22.275597],
							[114.204048, 22.275539],
							[114.204765, 22.274767],
							[114.204872, 22.274652],
							[114.206413, 22.273354],
							[114.206825, 22.272646],
							[114.206886, 22.271345],
							[114.206474, 22.270342],
							[114.205582, 22.269453],
							[114.203873, 22.268097],
							[114.202217, 22.266737],
							[114.200386, 22.265554],
							[114.199203, 22.264963],
							[114.199104, 22.264368],
							[114.198738, 22.262077],
							[114.198608, 22.261059],
							[114.198250, 22.258787],
							[114.198212, 22.258585],
							[114.197289, 22.258274],
							[114.196953, 22.258163],
							[114.195686, 22.257742],
							[114.192741, 22.257322],
							[114.189796, 22.256479],
							[114.186005, 22.255217],
							[114.184319, 22.255638],
							[114.180954, 22.256058],
							[114.179268, 22.257742],
							[114.177582, 22.257742],
							[114.177162, 22.256479],
							[114.175903, 22.256058],
							[114.174217, 22.256479],
							[114.170425, 22.257322],
							[114.169167, 22.258163],
							[114.167061, 22.259005],
							[114.165794, 22.259426],
							[114.164536, 22.259848],
							[114.164398, 22.259987],
							[114.163689, 22.260689],
							[114.164505, 22.261501],
							[114.165375, 22.262794],
							[114.165794, 22.264057],
							[114.166222, 22.266163],
							[114.165794, 22.267426],
							[114.165794, 22.268688],
							[114.165794, 22.269951],
							[114.166222, 22.271214],
							[114.166641, 22.273319],
							[114.168289, 22.274973],
							[114.168289, 22.276232],
							[114.168320, 22.278654],
							[114.168182, 22.278849],
							[114.167244, 22.280384],
							[114.167130, 22.280863],
							[114.166977, 22.281528]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Wong Tai Sin",
					"ID_0": 102,
					"ID_1": 16,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.221336, 22.359592],
							[114.221344, 22.359262],
							[114.221352, 22.358912],
							[114.221367, 22.358696],
							[114.221405, 22.357962],
							[114.221153, 22.355913],
							[114.221107, 22.353863],
							[114.221054, 22.351864],
							[114.221405, 22.349464],
							[114.221596, 22.347445],
							[114.221756, 22.346960],
							[114.221756, 22.345760],
							[114.222450, 22.342970],
							[114.222855, 22.340071],
							[114.223381, 22.337723],
							[114.223381, 22.336473],
							[114.223579, 22.335474],
							[114.223328, 22.334375],
							[114.223122, 22.334146],
							[114.223022, 22.334042],
							[114.223022, 22.333910],
							[114.222900, 22.333910],
							[114.221756, 22.333910],
							[114.219650, 22.333488],
							[114.217125, 22.333067],
							[114.215446, 22.332645],
							[114.214180, 22.332645],
							[114.212921, 22.333488],
							[114.212303, 22.334103],
							[114.211235, 22.335173],
							[114.209129, 22.334330],
							[114.207443, 22.334330],
							[114.206184, 22.334330],
							[114.204918, 22.334751],
							[114.204079, 22.335592],
							[114.202812, 22.336014],
							[114.201553, 22.336014],
							[114.199448, 22.334751],
							[114.196922, 22.333488],
							[114.194817, 22.331804],
							[114.192711, 22.333067],
							[114.191864, 22.333910],
							[114.190186, 22.334330],
							[114.188919, 22.333067],
							[114.187233, 22.333067],
							[114.187233, 22.335173],
							[114.185974, 22.335592],
							[114.185974, 22.337698],
							[114.185974, 22.338961],
							[114.184708, 22.339804],
							[114.183449, 22.340223],
							[114.183449, 22.341488],
							[114.184288, 22.342751],
							[114.184288, 22.344013],
							[114.182602, 22.345276],
							[114.181343, 22.345276],
							[114.181343, 22.346960],
							[114.180504, 22.347803],
							[114.179657, 22.349068],
							[114.181343, 22.349909],
							[114.181885, 22.350122],
							[114.183449, 22.350752],
							[114.184036, 22.350599],
							[114.185135, 22.350328],
							[114.185722, 22.350130],
							[114.186020, 22.350033],
							[114.186394, 22.349909],
							[114.187309, 22.350092],
							[114.188171, 22.350264],
							[114.188499, 22.350328],
							[114.189148, 22.350542],
							[114.189766, 22.350752],
							[114.191368, 22.350752],
							[114.191444, 22.350912],
							[114.191444, 22.352013],
							[114.191368, 22.352255],
							[114.191307, 22.352434],
							[114.191071, 22.353144],
							[114.191025, 22.353275],
							[114.191498, 22.353746],
							[114.191864, 22.354116],
							[114.193550, 22.354116],
							[114.194817, 22.352434],
							[114.195618, 22.352568],
							[114.197342, 22.352854],
							[114.198601, 22.354959],
							[114.199020, 22.355585],
							[114.199448, 22.356222],
							[114.200706, 22.355383],
							[114.201973, 22.355383],
							[114.203232, 22.355383],
							[114.203835, 22.355579],
							[114.204292, 22.355734],
							[114.204498, 22.355801],
							[114.205757, 22.355801],
							[114.207443, 22.355801],
							[114.209129, 22.356646],
							[114.209763, 22.356434],
							[114.210121, 22.356312],
							[114.210388, 22.356222],
							[114.211868, 22.356468],
							[114.212189, 22.356522],
							[114.212921, 22.356646],
							[114.213036, 22.357117],
							[114.213081, 22.357298],
							[114.213120, 22.357443],
							[114.213341, 22.358330],
							[114.215446, 22.358330],
							[114.217033, 22.359280],
							[114.217552, 22.359592],
							[114.218811, 22.359592],
							[114.220078, 22.359592],
							[114.221336, 22.359592]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Yau Tsim Mong",
					"ID_0": 102,
					"ID_1": 17,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.174187, 22.327173],
							[114.173340, 22.325911],
							[114.172501, 22.323383],
							[114.172920, 22.321701],
							[114.173340, 22.320436],
							[114.174187, 22.318333],
							[114.174606, 22.317070],
							[114.175026, 22.315805],
							[114.175873, 22.314123],
							[114.177132, 22.312859],
							[114.179237, 22.310333],
							[114.180077, 22.309492],
							[114.180504, 22.308228],
							[114.182602, 22.305700],
							[114.182602, 22.304016],
							[114.184708, 22.302755],
							[114.184708, 22.301493],
							[114.184692, 22.299797],
							[114.184837, 22.297915],
							[114.184860, 22.297640],
							[114.184586, 22.297638],
							[114.184586, 22.296806],
							[114.184029, 22.296806],
							[114.184029, 22.296528],
							[114.183472, 22.296528],
							[114.183472, 22.296249],
							[114.183197, 22.296249],
							[114.183197, 22.296528],
							[114.182640, 22.296528],
							[114.182640, 22.296806],
							[114.182083, 22.296806],
							[114.182083, 22.297085],
							[114.181808, 22.297085],
							[114.181808, 22.297638],
							[114.181526, 22.297640],
							[114.181526, 22.297916],
							[114.180969, 22.297916],
							[114.180969, 22.298195],
							[114.180298, 22.298195],
							[114.179703, 22.298195],
							[114.179581, 22.298195],
							[114.179581, 22.297916],
							[114.179306, 22.297916],
							[114.179306, 22.297640],
							[114.179031, 22.297638],
							[114.179031, 22.297085],
							[114.178474, 22.297085],
							[114.178474, 22.296528],
							[114.178192, 22.296528],
							[114.178192, 22.296249],
							[114.177917, 22.296249],
							[114.177917, 22.295973],
							[114.177635, 22.295973],
							[114.177635, 22.295694],
							[114.177086, 22.295694],
							[114.177086, 22.295416],
							[114.176804, 22.295416],
							[114.176804, 22.295139],
							[114.176529, 22.295139],
							[114.176529, 22.294863],
							[114.176247, 22.294863],
							[114.176247, 22.294306],
							[114.175972, 22.294306],
							[114.175972, 22.293751],
							[114.175415, 22.293751],
							[114.175415, 22.293194],
							[114.175140, 22.293194],
							[114.175140, 22.292917],
							[114.174858, 22.292917],
							[114.174858, 22.292641],
							[114.174583, 22.292641],
							[114.174583, 22.292358],
							[114.174026, 22.292358],
							[114.174026, 22.292641],
							[114.172363, 22.292641],
							[114.172363, 22.292917],
							[114.170975, 22.292917],
							[114.170975, 22.293074],
							[114.170975, 22.293194],
							[114.170135, 22.293194],
							[114.170135, 22.293074],
							[114.170135, 22.292641],
							[114.169304, 22.292641],
							[114.169304, 22.292917],
							[114.168198, 22.292917],
							[114.168198, 22.293472],
							[114.167915, 22.293472],
							[114.167915, 22.293751],
							[114.167641, 22.293751],
							[114.167641, 22.294027],
							[114.167084, 22.294027],
							[114.167084, 22.294306],
							[114.165138, 22.294306],
							[114.165138, 22.294582],
							[114.165695, 22.294584],
							[114.165695, 22.294863],
							[114.165939, 22.294863],
							[114.166809, 22.294863],
							[114.166809, 22.295139],
							[114.167358, 22.295139],
							[114.167358, 22.295416],
							[114.167641, 22.295416],
							[114.167641, 22.295881],
							[114.167641, 22.296824],
							[114.167641, 22.297085],
							[114.167358, 22.297085],
							[114.167358, 22.297361],
							[114.165413, 22.297361],
							[114.165413, 22.297638],
							[114.165695, 22.297638],
							[114.165695, 22.298195],
							[114.165413, 22.298195],
							[114.165413, 22.299583],
							[114.165695, 22.299583],
							[114.165695, 22.299862],
							[114.166801, 22.299862],
							[114.166809, 22.300972],
							[114.166611, 22.300972],
							[114.166527, 22.300972],
							[114.166527, 22.303194],
							[114.166252, 22.303194],
							[114.166252, 22.304308],
							[114.165138, 22.304308],
							[114.165138, 22.304029],
							[114.164307, 22.304029],
							[114.164307, 22.303747],
							[114.163750, 22.303747],
							[114.163750, 22.303473],
							[114.163475, 22.303473],
							[114.163475, 22.303194],
							[114.163193, 22.303194],
							[114.163193, 22.302919],
							[114.162918, 22.302919],
							[114.162918, 22.302637],
							[114.162361, 22.302637],
							[114.162361, 22.302361],
							[114.161530, 22.302361],
							[114.161530, 22.302084],
							[114.160698, 22.302084],
							[114.160698, 22.301805],
							[114.160141, 22.301805],
							[114.160141, 22.301525],
							[114.159584, 22.301525],
							[114.159584, 22.301250],
							[114.159302, 22.301250],
							[114.159302, 22.300972],
							[114.158752, 22.300972],
							[114.158752, 22.300695],
							[114.158195, 22.300694],
							[114.158195, 22.300417],
							[114.157639, 22.300417],
							[114.157639, 22.300138],
							[114.157364, 22.300138],
							[114.157364, 22.299862],
							[114.156807, 22.299862],
							[114.156807, 22.299583],
							[114.156387, 22.299583],
							[114.156250, 22.299583],
							[114.156250, 22.299303],
							[114.155975, 22.299303],
							[114.155975, 22.299643],
							[114.155975, 22.299862],
							[114.155693, 22.299862],
							[114.155693, 22.302084],
							[114.155418, 22.302084],
							[114.155418, 22.303209],
							[114.155418, 22.303473],
							[114.155693, 22.303473],
							[114.155693, 22.303747],
							[114.155975, 22.303747],
							[114.155975, 22.304308],
							[114.156250, 22.304308],
							[114.156250, 22.304583],
							[114.155975, 22.304583],
							[114.155975, 22.305973],
							[114.155693, 22.305973],
							[114.155693, 22.306805],
							[114.155418, 22.306805],
							[114.155418, 22.309029],
							[114.155136, 22.309029],
							[114.155136, 22.312361],
							[114.154861, 22.312361],
							[114.154861, 22.314861],
							[114.154991, 22.314861],
							[114.155418, 22.314861],
							[114.155418, 22.315140],
							[114.156807, 22.315140],
							[114.156807, 22.315416],
							[114.157913, 22.315416],
							[114.157913, 22.315971],
							[114.157639, 22.315971],
							[114.157639, 22.316526],
							[114.157364, 22.316526],
							[114.157364, 22.316805],
							[114.157082, 22.316805],
							[114.157082, 22.317362],
							[114.156807, 22.317362],
							[114.156807, 22.317919],
							[114.156525, 22.317919],
							[114.156525, 22.318472],
							[114.156250, 22.318472],
							[114.156250, 22.319029],
							[114.155975, 22.319029],
							[114.155975, 22.319305],
							[114.155693, 22.319305],
							[114.155693, 22.319584],
							[114.155418, 22.319584],
							[114.155418, 22.320141],
							[114.155136, 22.320141],
							[114.155136, 22.320417],
							[114.154861, 22.320417],
							[114.154861, 22.320694],
							[114.154587, 22.320694],
							[114.154587, 22.320972],
							[114.154434, 22.320972],
							[114.154305, 22.320972],
							[114.154305, 22.321253],
							[114.154030, 22.321253],
							[114.154030, 22.321527],
							[114.153748, 22.321527],
							[114.153748, 22.321806],
							[114.153473, 22.321806],
							[114.153473, 22.322083],
							[114.153198, 22.322083],
							[114.153198, 22.322363],
							[114.152641, 22.322363],
							[114.152641, 22.322639],
							[114.152359, 22.322639],
							[114.152359, 22.322916],
							[114.152260, 22.322969],
							[114.152260, 22.323528],
							[114.152458, 22.324160],
							[114.152382, 22.324371],
							[114.152290, 22.324648],
							[114.153976, 22.326332],
							[114.156082, 22.324648],
							[114.156174, 22.324455],
							[114.156883, 22.322933],
							[114.157623, 22.323425],
							[114.158188, 22.323805],
							[114.159027, 22.324648],
							[114.159813, 22.325169],
							[114.160294, 22.325489],
							[114.161926, 22.325897],
							[114.160713, 22.327173],
							[114.161552, 22.328014],
							[114.162819, 22.328014],
							[114.164078, 22.328014],
							[114.165344, 22.328014],
							[114.166611, 22.328014],
							[114.167870, 22.328014],
							[114.169136, 22.328014],
							[114.170395, 22.328014],
							[114.171036, 22.328014],
							[114.171661, 22.328014],
							[114.172920, 22.328014],
							[114.173477, 22.327644],
							[114.173805, 22.327427],
							[114.174187, 22.327173]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"name": "Yuen Long",
					"ID_0": 102,
					"ID_1": 18,
					"ISO": "HKG"
				},
				"geometry": {
					"type": "Polygon",
					"coordinates": [
						[
							[114.084511, 22.519991],
							[114.085205, 22.520164],
							[114.086197, 22.520412],
							[114.086365, 22.520670],
							[114.087036, 22.521675],
							[114.087120, 22.521759],
							[114.088303, 22.522938],
							[114.089561, 22.522938],
							[114.091667, 22.521254],
							[114.091248, 22.519150],
							[114.090950, 22.518259],
							[114.090897, 22.518093],
							[114.090828, 22.517887],
							[114.090790, 22.517719],
							[114.090752, 22.517523],
							[114.090652, 22.517031],
							[114.090408, 22.515781],
							[114.091248, 22.514936],
							[114.091248, 22.512835],
							[114.091751, 22.512329],
							[114.092934, 22.511150],
							[114.092300, 22.510305],
							[114.091667, 22.509464],
							[114.091255, 22.509604],
							[114.090408, 22.509888],
							[114.089668, 22.509148],
							[114.089561, 22.509045],
							[114.089676, 22.508720],
							[114.089737, 22.508532],
							[114.089874, 22.508114],
							[114.089943, 22.507906],
							[114.089989, 22.507780],
							[114.089561, 22.506517],
							[114.090828, 22.505676],
							[114.090828, 22.504416],
							[114.091248, 22.503153],
							[114.091278, 22.502922],
							[114.091370, 22.502287],
							[114.091393, 22.502115],
							[114.091423, 22.501919],
							[114.091446, 22.501760],
							[114.091568, 22.500889],
							[114.091667, 22.500204],
							[114.090828, 22.499363],
							[114.089989, 22.498098],
							[114.090408, 22.496838],
							[114.090828, 22.495575],
							[114.092087, 22.493891],
							[114.092934, 22.492626],
							[114.092476, 22.491940],
							[114.092087, 22.491364],
							[114.092087, 22.490387],
							[114.092087, 22.487574],
							[114.091919, 22.487059],
							[114.091812, 22.486732],
							[114.091736, 22.486519],
							[114.091667, 22.486313],
							[114.090927, 22.484829],
							[114.090775, 22.484547],
							[114.090584, 22.484261],
							[114.090172, 22.483641],
							[114.089989, 22.483364],
							[114.089561, 22.482101],
							[114.089211, 22.481039],
							[114.089142, 22.480839],
							[114.089142, 22.479574],
							[114.089142, 22.478312],
							[114.089142, 22.477049],
							[114.089142, 22.475786],
							[114.089142, 22.474524],
							[114.089142, 22.473261],
							[114.089142, 22.471996],
							[114.089142, 22.470734],
							[114.089142, 22.469471],
							[114.089142, 22.468208],
							[114.089142, 22.466946],
							[114.090408, 22.466946],
							[114.090523, 22.466988],
							[114.091667, 22.467367],
							[114.092934, 22.467367],
							[114.094193, 22.467367],
							[114.095459, 22.467367],
							[114.096718, 22.467367],
							[114.098404, 22.467787],
							[114.100090, 22.468208],
							[114.102196, 22.469471],
							[114.103035, 22.470314],
							[114.105980, 22.470314],
							[114.107666, 22.469893],
							[114.110611, 22.471155],
							[114.111877, 22.471577],
							[114.113983, 22.471996],
							[114.115242, 22.471577],
							[114.116089, 22.470314],
							[114.117775, 22.469049],
							[114.119034, 22.469049],
							[114.120300, 22.467787],
							[114.120682, 22.467278],
							[114.120804, 22.467112],
							[114.121132, 22.466669],
							[114.121559, 22.466103],
							[114.121750, 22.466141],
							[114.122879, 22.466368],
							[114.123260, 22.466444],
							[114.123665, 22.466524],
							[114.124931, 22.466524],
							[114.126190, 22.466524],
							[114.127457, 22.466946],
							[114.127213, 22.466219],
							[114.127151, 22.466040],
							[114.127037, 22.465683],
							[114.126892, 22.465261],
							[114.126610, 22.464418],
							[114.126335, 22.463577],
							[114.126190, 22.463156],
							[114.121971, 22.457785],
							[114.121346, 22.457485],
							[114.121048, 22.456593],
							[114.121010, 22.455902],
							[114.120361, 22.455414],
							[114.119453, 22.454737],
							[114.118614, 22.453894],
							[114.117348, 22.452209],
							[114.116821, 22.451410],
							[114.116508, 22.450947],
							[114.114403, 22.448421],
							[114.113945, 22.447847],
							[114.113632, 22.447458],
							[114.112267, 22.445988],
							[114.111877, 22.445055],
							[114.111671, 22.444557],
							[114.111580, 22.443851],
							[114.111458, 22.443369],
							[114.111496, 22.443251],
							[114.111732, 22.441818],
							[114.112198, 22.441135],
							[114.112244, 22.441010],
							[114.112297, 22.440844],
							[114.113144, 22.438738],
							[114.113792, 22.437761],
							[114.114822, 22.436213],
							[114.115646, 22.434982],
							[114.115669, 22.434664],
							[114.115669, 22.433685],
							[114.115669, 22.432425],
							[114.115845, 22.431879],
							[114.115967, 22.431528],
							[114.115982, 22.431337],
							[114.116379, 22.429560],
							[114.116821, 22.428968],
							[114.116859, 22.428852],
							[114.116928, 22.428637],
							[114.117035, 22.428581],
							[114.117149, 22.428526],
							[114.117699, 22.427780],
							[114.119133, 22.426521],
							[114.120163, 22.425203],
							[114.121651, 22.423540],
							[114.122688, 22.422050],
							[114.124001, 22.420671],
							[114.124107, 22.420435],
							[114.124504, 22.416845],
							[114.124084, 22.415583],
							[114.124084, 22.414320],
							[114.123154, 22.413385],
							[114.122826, 22.413057],
							[114.121559, 22.411795],
							[114.121559, 22.410534],
							[114.119873, 22.410534],
							[114.118195, 22.410534],
							[114.117661, 22.410746],
							[114.116089, 22.411373],
							[114.112717, 22.408848],
							[114.111877, 22.407164],
							[114.111244, 22.406952],
							[114.110847, 22.406820],
							[114.110611, 22.406742],
							[114.110161, 22.406591],
							[114.109985, 22.406532],
							[114.109352, 22.406321],
							[114.108513, 22.406532],
							[114.108292, 22.406588],
							[114.107666, 22.406742],
							[114.107185, 22.406422],
							[114.107048, 22.406328],
							[114.106781, 22.406151],
							[114.106407, 22.405901],
							[114.105141, 22.405901],
							[114.104866, 22.406719],
							[114.104790, 22.406963],
							[114.104721, 22.407164],
							[114.103455, 22.408005],
							[114.100929, 22.408005],
							[114.100761, 22.407948],
							[114.099670, 22.407583],
							[114.098824, 22.406742],
							[114.098610, 22.406420],
							[114.097984, 22.405479],
							[114.097145, 22.404217],
							[114.096230, 22.403530],
							[114.095459, 22.402952],
							[114.093414, 22.401320],
							[114.093292, 22.401230],
							[114.092087, 22.400427],
							[114.089989, 22.400427],
							[114.088631, 22.401329],
							[114.087456, 22.402111],
							[114.086319, 22.402493],
							[114.086197, 22.402533],
							[114.084930, 22.402533],
							[114.083252, 22.401690],
							[114.083160, 22.401554],
							[114.082405, 22.400427],
							[114.081833, 22.399853],
							[114.081566, 22.399586],
							[114.079041, 22.397480],
							[114.077774, 22.397480],
							[114.077484, 22.397423],
							[114.077133, 22.397352],
							[114.076805, 22.397285],
							[114.076355, 22.397196],
							[114.076141, 22.397154],
							[114.075668, 22.397058],
							[114.075096, 22.397251],
							[114.074409, 22.397480],
							[114.073570, 22.396639],
							[114.073143, 22.394955],
							[114.073036, 22.394810],
							[114.072540, 22.394142],
							[114.072372, 22.393923],
							[114.071884, 22.393270],
							[114.070618, 22.394112],
							[114.070007, 22.393959],
							[114.068939, 22.393692],
							[114.067368, 22.392906],
							[114.067253, 22.392849],
							[114.066406, 22.392008],
							[114.065567, 22.391165],
							[114.064598, 22.390842],
							[114.064308, 22.390745],
							[114.064308, 22.392008],
							[114.064308, 22.393270],
							[114.064308, 22.394533],
							[114.064308, 22.395796],
							[114.064423, 22.396156],
							[114.064728, 22.397058],
							[114.063461, 22.397902],
							[114.062202, 22.397902],
							[114.060936, 22.397902],
							[114.059677, 22.397902],
							[114.058411, 22.397902],
							[114.057144, 22.397902],
							[114.055885, 22.397902],
							[114.055885, 22.399164],
							[114.055885, 22.400427],
							[114.055885, 22.401690],
							[114.055885, 22.402952],
							[114.055885, 22.404217],
							[114.056030, 22.404640],
							[114.056305, 22.405479],
							[114.057571, 22.405479],
							[114.058830, 22.405901],
							[114.058830, 22.407164],
							[114.058998, 22.407665],
							[114.059250, 22.408426],
							[114.058411, 22.409267],
							[114.057144, 22.409267],
							[114.055885, 22.409267],
							[114.054619, 22.409267],
							[114.053360, 22.409267],
							[114.052094, 22.409267],
							[114.050835, 22.409267],
							[114.050407, 22.408840],
							[114.049988, 22.408426],
							[114.049988, 22.407164],
							[114.049988, 22.405901],
							[114.025154, 22.405901],
							[114.023888, 22.405901],
							[114.022629, 22.405901],
							[114.021362, 22.405901],
							[114.020096, 22.405901],
							[114.018837, 22.405901],
							[114.017570, 22.405901],
							[114.016312, 22.405901],
							[114.015045, 22.405901],
							[114.013786, 22.405901],
							[114.012520, 22.405901],
							[114.011261, 22.405901],
							[114.010902, 22.406973],
							[114.010834, 22.407164],
							[114.010834, 22.408426],
							[114.010834, 22.409689],
							[114.010834, 22.410952],
							[114.010834, 22.412214],
							[114.010834, 22.413479],
							[114.010834, 22.414742],
							[114.010445, 22.415138],
							[114.009995, 22.415583],
							[114.008736, 22.415583],
							[114.007469, 22.415583],
							[114.006203, 22.416426],
							[114.006203, 22.417688],
							[114.006203, 22.418953],
							[114.006203, 22.420214],
							[114.006203, 22.421476],
							[114.006119, 22.421747],
							[114.005783, 22.422743],
							[114.004524, 22.422743],
							[114.003258, 22.422743],
							[114.001999, 22.422743],
							[114.000732, 22.422743],
							[113.999474, 22.422743],
							[113.998291, 22.423037],
							[113.997787, 22.423161],
							[113.996521, 22.422743],
							[113.995682, 22.423582],
							[113.995338, 22.424065],
							[113.993576, 22.426531],
							[113.993576, 22.427792],
							[113.993576, 22.429054],
							[113.993805, 22.429398],
							[113.994415, 22.430319],
							[113.995262, 22.431580],
							[113.995262, 22.432842],
							[113.993629, 22.432516],
							[113.993156, 22.432425],
							[113.992599, 22.432343],
							[113.990211, 22.432001],
							[113.989929, 22.431723],
							[113.989365, 22.431162],
							[113.988396, 22.430965],
							[113.987938, 22.430876],
							[113.987259, 22.430738],
							[113.985580, 22.430319],
							[113.985069, 22.430656],
							[113.984314, 22.431162],
							[113.983803, 22.430990],
							[113.983055, 22.430738],
							[113.982323, 22.430981],
							[113.981789, 22.431162],
							[113.979263, 22.431162],
							[113.977997, 22.431162],
							[113.976738, 22.431162],
							[113.975471, 22.431162],
							[113.974213, 22.431162],
							[113.972946, 22.431162],
							[113.971687, 22.431162],
							[113.970421, 22.431162],
							[113.970078, 22.431393],
							[113.969162, 22.432001],
							[113.969162, 22.433266],
							[113.948532, 22.433685],
							[113.948181, 22.433168],
							[113.947685, 22.432425],
							[113.947403, 22.431580],
							[113.947266, 22.431162],
							[113.946426, 22.429897],
							[113.945930, 22.428917],
							[113.945580, 22.428213],
							[113.945343, 22.427488],
							[113.945160, 22.426950],
							[113.943901, 22.425690],
							[113.943626, 22.425283],
							[113.943054, 22.424425],
							[113.942215, 22.423161],
							[113.940948, 22.422319],
							[113.940109, 22.421476],
							[113.938843, 22.419794],
							[113.938622, 22.420250],
							[113.938004, 22.421476],
							[113.938423, 22.422743],
							[113.938423, 22.424006],
							[113.938133, 22.425476],
							[113.938004, 22.426107],
							[113.938255, 22.426823],
							[113.938469, 22.427084],
							[113.938469, 22.427359],
							[113.938751, 22.427359],
							[113.938751, 22.427641],
							[113.939026, 22.427641],
							[113.939026, 22.427359],
							[113.939308, 22.427359],
							[113.939308, 22.427641],
							[113.939583, 22.427641],
							[113.939583, 22.427917],
							[113.940048, 22.427917],
							[113.940140, 22.427917],
							[113.940140, 22.428194],
							[113.940697, 22.428194],
							[113.940697, 22.428473],
							[113.941246, 22.428473],
							[113.941254, 22.428749],
							[113.941528, 22.428749],
							[113.941528, 22.429028],
							[113.942085, 22.429028],
							[113.942085, 22.429306],
							[113.942642, 22.429306],
							[113.942642, 22.429583],
							[113.943192, 22.429583],
							[113.943192, 22.429861],
							[113.943474, 22.429861],
							[113.943474, 22.430140],
							[113.943748, 22.430140],
							[113.943748, 22.430416],
							[113.944305, 22.430416],
							[113.944305, 22.430973],
							[113.944862, 22.430973],
							[113.944862, 22.431250],
							[113.945419, 22.431250],
							[113.945419, 22.431528],
							[113.945694, 22.431528],
							[113.945694, 22.432083],
							[113.945969, 22.432083],
							[113.945969, 22.432638],
							[113.946251, 22.432638],
							[113.946251, 22.434305],
							[113.946526, 22.434305],
							[113.946526, 22.434860],
							[113.946808, 22.434860],
							[113.946808, 22.435139],
							[113.946526, 22.435139],
							[113.946526, 22.436806],
							[113.946808, 22.436806],
							[113.946808, 22.437637],
							[113.947083, 22.437637],
							[113.947083, 22.437916],
							[113.947365, 22.437916],
							[113.947365, 22.438473],
							[113.947639, 22.438473],
							[113.947639, 22.439030],
							[113.947891, 22.439030],
							[113.947914, 22.439583],
							[113.948196, 22.439583],
							[113.948196, 22.439859],
							[113.948471, 22.439859],
							[113.948471, 22.440142],
							[113.948753, 22.440142],
							[113.948753, 22.440695],
							[113.949028, 22.440695],
							[113.949028, 22.440969],
							[113.949287, 22.440969],
							[113.949303, 22.441252],
							[113.949577, 22.441252],
							[113.949585, 22.441528],
							[113.950142, 22.441528],
							[113.950142, 22.441805],
							[113.950417, 22.441805],
							[113.950417, 22.442083],
							[113.950974, 22.442083],
							[113.950974, 22.442362],
							[113.951248, 22.442362],
							[113.951248, 22.442638],
							[113.951805, 22.442638],
							[113.951805, 22.442917],
							[113.952080, 22.442917],
							[113.952080, 22.443195],
							[113.952637, 22.443195],
							[113.952637, 22.443750],
							[113.953186, 22.443750],
							[113.953194, 22.444860],
							[113.952919, 22.444860],
							[113.952919, 22.445139],
							[113.953186, 22.445139],
							[113.953194, 22.445417],
							[113.953476, 22.445417],
							[113.953476, 22.447639],
							[113.954025, 22.447639],
							[113.954025, 22.447914],
							[113.954308, 22.447914],
							[113.954308, 22.448195],
							[113.954582, 22.448195],
							[113.954582, 22.448750],
							[113.954857, 22.448750],
							[113.954865, 22.449026],
							[113.955139, 22.449026],
							[113.955139, 22.449305],
							[113.956253, 22.449306],
							[113.956253, 22.449583],
							[113.957359, 22.449583],
							[113.957359, 22.449306],
							[113.958191, 22.449306],
							[113.958191, 22.449583],
							[113.958473, 22.449583],
							[113.958473, 22.449862],
							[113.959030, 22.449862],
							[113.959030, 22.450136],
							[113.959862, 22.450136],
							[113.959862, 22.450417],
							[113.960419, 22.450417],
							[113.960419, 22.450695],
							[113.960693, 22.450695],
							[113.960693, 22.450972],
							[113.961250, 22.450972],
							[113.961250, 22.451248],
							[113.961525, 22.451248],
							[113.961525, 22.451530],
							[113.961807, 22.451530],
							[113.961807, 22.451805],
							[113.962082, 22.451805],
							[113.962082, 22.452084],
							[113.962639, 22.452084],
							[113.962639, 22.452358],
							[113.962906, 22.452362],
							[113.962914, 22.452641],
							[113.963470, 22.452641],
							[113.963470, 22.452917],
							[113.964027, 22.452917],
							[113.964027, 22.453194],
							[113.964584, 22.453194],
							[113.964584, 22.453470],
							[113.965141, 22.453470],
							[113.965141, 22.453751],
							[113.967636, 22.453751],
							[113.967636, 22.454027],
							[113.969025, 22.454027],
							[113.969025, 22.454306],
							[113.970413, 22.454306],
							[113.970413, 22.454584],
							[113.970970, 22.454584],
							[113.970970, 22.454863],
							[113.971527, 22.454863],
							[113.971527, 22.455139],
							[113.971802, 22.455139],
							[113.971802, 22.454863],
							[113.972076, 22.454863],
							[113.972084, 22.455139],
							[113.972641, 22.455139],
							[113.972641, 22.455416],
							[113.972900, 22.455416],
							[113.972916, 22.455694],
							[113.973473, 22.455694],
							[113.973473, 22.455973],
							[113.974297, 22.455973],
							[113.974304, 22.456528],
							[113.974861, 22.456528],
							[113.974861, 22.456806],
							[113.975418, 22.456806],
							[113.975418, 22.457083],
							[113.975975, 22.457083],
							[113.975975, 22.457361],
							[113.976250, 22.457361],
							[113.976250, 22.457640],
							[113.976807, 22.457640],
							[113.976807, 22.458195],
							[113.977081, 22.458195],
							[113.977081, 22.458750],
							[113.977638, 22.458750],
							[113.977638, 22.459028],
							[113.977913, 22.459028],
							[113.977913, 22.459583],
							[113.978470, 22.459583],
							[113.978470, 22.460138],
							[113.978752, 22.460138],
							[113.978752, 22.460972],
							[113.979027, 22.460972],
							[113.979027, 22.462084],
							[113.979309, 22.462084],
							[113.979309, 22.463472],
							[113.979584, 22.463472],
							[113.979584, 22.464582],
							[113.979858, 22.464582],
							[113.979858, 22.465137],
							[113.980141, 22.465137],
							[113.980141, 22.465694],
							[113.980415, 22.465694],
							[113.980415, 22.466251],
							[113.980698, 22.466251],
							[113.980698, 22.466806],
							[113.980942, 22.466806],
							[113.980972, 22.467081],
							[113.981247, 22.467081],
							[113.981247, 22.467640],
							[113.981529, 22.467640],
							[113.981529, 22.468195],
							[113.981796, 22.468195],
							[113.981804, 22.468752],
							[113.982086, 22.468752],
							[113.982086, 22.469028],
							[113.982361, 22.469028],
							[113.982361, 22.469305],
							[113.982635, 22.469305],
							[113.982635, 22.469584],
							[113.982918, 22.469584],
							[113.982918, 22.469864],
							[113.983192, 22.469864],
							[113.983192, 22.470139],
							[113.983475, 22.470139],
							[113.983475, 22.470417],
							[113.984024, 22.470417],
							[113.984024, 22.470694],
							[113.984306, 22.470694],
							[113.984306, 22.470972],
							[113.984863, 22.470972],
							[113.984863, 22.471251],
							[113.985138, 22.471251],
							[113.985138, 22.471527],
							[113.985695, 22.471527],
							[113.985695, 22.472086],
							[113.985970, 22.472086],
							[113.985970, 22.472639],
							[113.986252, 22.472639],
							[113.986252, 22.472918],
							[113.986488, 22.472918],
							[113.986526, 22.473194],
							[113.986809, 22.473194],
							[113.986809, 22.473473],
							[113.987358, 22.473473],
							[113.987358, 22.473749],
							[113.988472, 22.473749],
							[113.988472, 22.474861],
							[113.988747, 22.474861],
							[113.988747, 22.475416],
							[113.989029, 22.475416],
							[113.989029, 22.475695],
							[113.989304, 22.475695],
							[113.989304, 22.475973],
							[113.989479, 22.475973],
							[113.990692, 22.475973],
							[113.990692, 22.476250],
							[113.990967, 22.476250],
							[113.990974, 22.476526],
							[113.991249, 22.476526],
							[113.991249, 22.476805],
							[113.991531, 22.476805],
							[113.991531, 22.477083],
							[113.991806, 22.477083],
							[113.991806, 22.477362],
							[113.992081, 22.477362],
							[113.992081, 22.477636],
							[113.992363, 22.477636],
							[113.992363, 22.478195],
							[113.992638, 22.478195],
							[113.992638, 22.478472],
							[113.992920, 22.478472],
							[113.992920, 22.479305],
							[113.993195, 22.479305],
							[113.993195, 22.480141],
							[113.993469, 22.480141],
							[113.993469, 22.480417],
							[113.993752, 22.480417],
							[113.993752, 22.480972],
							[113.994026, 22.480972],
							[113.994026, 22.481253],
							[113.994308, 22.481253],
							[113.994308, 22.482363],
							[113.994583, 22.482363],
							[113.994583, 22.483194],
							[113.994858, 22.483194],
							[113.994858, 22.484028],
							[113.995140, 22.484028],
							[113.995140, 22.485138],
							[113.995415, 22.485138],
							[113.995415, 22.485971],
							[113.995697, 22.485971],
							[113.995697, 22.486528],
							[113.995972, 22.486528],
							[113.995972, 22.486803],
							[113.996246, 22.486803],
							[113.996246, 22.487083],
							[113.996529, 22.487083],
							[113.996529, 22.487362],
							[113.997360, 22.487362],
							[113.997360, 22.487638],
							[113.997643, 22.487638],
							[113.997643, 22.487917],
							[113.997917, 22.487917],
							[113.997917, 22.488750],
							[113.998192, 22.488750],
							[113.998192, 22.489025],
							[113.999031, 22.489025],
							[113.999031, 22.489305],
							[113.999290, 22.489305],
							[113.999306, 22.489861],
							[113.999580, 22.489861],
							[113.999580, 22.490137],
							[114.000000, 22.490137],
							[114.000420, 22.490137],
							[114.000420, 22.489861],
							[114.000694, 22.489861],
							[114.000694, 22.489584],
							[114.001808, 22.489584],
							[114.001808, 22.489861],
							[114.002357, 22.489861],
							[114.002357, 22.490137],
							[114.002640, 22.490137],
							[114.002640, 22.489861],
							[114.003754, 22.489861],
							[114.003754, 22.489588],
							[114.004028, 22.489584],
							[114.004028, 22.489305],
							[114.004303, 22.489305],
							[114.004303, 22.489025],
							[114.004860, 22.489025],
							[114.004860, 22.488750],
							[114.005142, 22.488750],
							[114.005142, 22.488472],
							[114.005417, 22.488472],
							[114.005417, 22.488194],
							[114.006248, 22.488194],
							[114.006248, 22.487917],
							[114.007080, 22.487917],
							[114.007080, 22.487638],
							[114.009026, 22.487638],
							[114.009026, 22.487362],
							[114.010139, 22.487362],
							[114.010139, 22.487083],
							[114.010971, 22.487083],
							[114.010971, 22.486803],
							[114.011803, 22.486803],
							[114.011803, 22.483194],
							[114.011528, 22.483194],
							[114.011528, 22.481253],
							[114.011253, 22.481253],
							[114.011253, 22.479858],
							[114.010971, 22.479858],
							[114.010971, 22.478472],
							[114.010414, 22.478472],
							[114.010414, 22.478729],
							[114.009026, 22.478748],
							[114.009026, 22.478472],
							[114.008751, 22.478472],
							[114.008751, 22.477636],
							[114.008469, 22.477636],
							[114.008469, 22.476526],
							[114.008194, 22.476526],
							[114.008194, 22.475973],
							[114.008430, 22.475973],
							[114.008469, 22.475695],
							[114.008194, 22.475695],
							[114.008194, 22.474583],
							[114.008469, 22.474583],
							[114.008469, 22.473473],
							[114.008194, 22.473473],
							[114.008194, 22.473194],
							[114.007919, 22.473194],
							[114.007919, 22.472918],
							[114.007637, 22.472918],
							[114.007637, 22.472641],
							[114.007362, 22.472639],
							[114.007362, 22.471809],
							[114.007889, 22.471806],
							[114.007919, 22.472086],
							[114.008186, 22.472086],
							[114.008194, 22.472361],
							[114.008469, 22.472361],
							[114.008469, 22.472639],
							[114.009308, 22.472639],
							[114.009308, 22.471251],
							[114.009583, 22.471251],
							[114.009583, 22.470694],
							[114.009865, 22.470694],
							[114.009865, 22.470417],
							[114.010139, 22.470417],
							[114.010139, 22.470140],
							[114.010406, 22.470139],
							[114.010414, 22.469864],
							[114.010910, 22.469864],
							[114.010971, 22.469584],
							[114.011253, 22.469584],
							[114.011253, 22.469305],
							[114.011528, 22.469305],
							[114.011528, 22.469028],
							[114.011803, 22.469028],
							[114.011803, 22.468752],
							[114.012085, 22.468752],
							[114.012085, 22.468195],
							[114.012360, 22.468195],
							[114.012360, 22.467916],
							[114.012917, 22.467916],
							[114.012917, 22.467081],
							[114.013420, 22.467081],
							[114.013474, 22.466806],
							[114.014030, 22.466806],
							[114.014030, 22.466532],
							[114.014305, 22.466530],
							[114.014305, 22.466251],
							[114.014580, 22.466251],
							[114.014580, 22.465973],
							[114.015694, 22.465973],
							[114.015694, 22.466251],
							[114.015976, 22.466251],
							[114.015976, 22.466530],
							[114.016525, 22.466530],
							[114.016525, 22.466806],
							[114.016777, 22.466806],
							[114.016808, 22.467081],
							[114.017082, 22.467081],
							[114.017082, 22.467361],
							[114.017639, 22.467361],
							[114.017639, 22.467916],
							[114.018471, 22.467916],
							[114.018471, 22.467640],
							[114.019028, 22.467640],
							[114.019028, 22.467361],
							[114.019859, 22.467361],
							[114.019859, 22.467640],
							[114.020142, 22.467640],
							[114.020142, 22.468195],
							[114.020416, 22.468195],
							[114.020416, 22.468470],
							[114.020691, 22.468470],
							[114.020691, 22.468752],
							[114.020973, 22.468752],
							[114.020973, 22.469028],
							[114.020691, 22.469028],
							[114.020691, 22.469305],
							[114.020416, 22.469305],
							[114.020416, 22.469584],
							[114.020142, 22.469584],
							[114.020142, 22.469864],
							[114.019859, 22.469864],
							[114.019859, 22.470139],
							[114.019585, 22.470139],
							[114.019585, 22.470417],
							[114.019302, 22.470417],
							[114.019302, 22.470694],
							[114.019028, 22.470694],
							[114.019028, 22.470972],
							[114.018471, 22.470972],
							[114.018471, 22.471251],
							[114.017914, 22.471251],
							[114.017914, 22.471527],
							[114.018471, 22.471527],
							[114.018471, 22.471806],
							[114.018738, 22.471806],
							[114.018753, 22.472361],
							[114.019028, 22.472361],
							[114.019028, 22.472639],
							[114.019302, 22.472639],
							[114.019302, 22.472918],
							[114.019783, 22.472918],
							[114.019859, 22.472918],
							[114.019859, 22.473194],
							[114.020142, 22.473194],
							[114.020142, 22.473749],
							[114.020416, 22.473749],
							[114.020416, 22.474028],
							[114.022362, 22.474028],
							[114.022362, 22.474304],
							[114.022919, 22.474304],
							[114.022919, 22.474583],
							[114.023476, 22.474583],
							[114.023476, 22.474861],
							[114.023750, 22.474861],
							[114.023750, 22.475140],
							[114.025414, 22.475140],
							[114.025414, 22.474861],
							[114.025696, 22.474861],
							[114.025696, 22.474583],
							[114.026253, 22.474583],
							[114.026253, 22.474304],
							[114.027077, 22.474304],
							[114.027084, 22.474030],
							[114.027641, 22.474028],
							[114.027641, 22.473751],
							[114.027916, 22.473749],
							[114.027916, 22.473473],
							[114.028191, 22.473473],
							[114.028198, 22.473194],
							[114.028473, 22.473194],
							[114.028473, 22.472361],
							[114.028748, 22.472361],
							[114.028748, 22.470139],
							[114.028473, 22.470139],
							[114.028473, 22.469030],
							[114.028198, 22.469028],
							[114.028198, 22.468916],
							[114.028191, 22.467081],
							[114.027916, 22.467081],
							[114.027916, 22.465694],
							[114.027641, 22.465694],
							[114.027641, 22.464861],
							[114.027916, 22.464861],
							[114.027916, 22.464582],
							[114.028191, 22.464582],
							[114.028198, 22.464306],
							[114.028748, 22.464306],
							[114.028748, 22.464582],
							[114.029030, 22.464582],
							[114.029030, 22.464861],
							[114.029305, 22.464861],
							[114.029305, 22.467361],
							[114.029587, 22.467361],
							[114.029587, 22.468752],
							[114.029861, 22.468752],
							[114.029861, 22.469028],
							[114.030136, 22.469028],
							[114.030136, 22.469305],
							[114.032364, 22.469305],
							[114.032364, 22.469028],
							[114.032486, 22.469028],
							[114.032913, 22.469028],
							[114.032913, 22.468752],
							[114.034027, 22.468752],
							[114.034027, 22.468470],
							[114.034859, 22.468470],
							[114.034859, 22.468195],
							[114.035698, 22.468195],
							[114.035698, 22.467916],
							[114.036247, 22.467916],
							[114.036247, 22.467642],
							[114.037086, 22.467640],
							[114.037086, 22.467361],
							[114.037361, 22.467361],
							[114.037361, 22.467081],
							[114.037918, 22.467081],
							[114.037918, 22.466806],
							[114.038193, 22.466806],
							[114.038193, 22.466530],
							[114.038467, 22.466530],
							[114.038475, 22.466251],
							[114.039024, 22.466251],
							[114.039024, 22.465973],
							[114.039581, 22.465973],
							[114.039581, 22.465694],
							[114.040138, 22.465694],
							[114.040138, 22.465416],
							[114.040695, 22.465416],
							[114.040695, 22.465137],
							[114.041527, 22.465137],
							[114.041527, 22.464861],
							[114.042076, 22.464861],
							[114.042084, 22.464310],
							[114.042641, 22.464306],
							[114.042641, 22.464031],
							[114.043198, 22.464027],
							[114.043198, 22.463751],
							[114.043472, 22.463751],
							[114.043472, 22.463472],
							[114.043747, 22.463472],
							[114.043747, 22.463194],
							[114.044304, 22.463194],
							[114.044304, 22.463751],
							[114.044029, 22.463751],
							[114.044029, 22.464582],
							[114.043747, 22.464582],
							[114.043747, 22.465694],
							[114.042915, 22.465694],
							[114.042915, 22.465973],
							[114.042084, 22.465973],
							[114.042084, 22.466251],
							[114.041809, 22.466251],
							[114.041809, 22.466530],
							[114.041252, 22.466530],
							[114.041252, 22.466806],
							[114.040749, 22.466806],
							[114.040695, 22.467081],
							[114.040138, 22.467081],
							[114.040138, 22.467361],
							[114.039581, 22.467361],
							[114.039581, 22.467640],
							[114.039024, 22.467640],
							[114.039024, 22.467916],
							[114.038780, 22.467916],
							[114.038750, 22.468195],
							[114.038475, 22.468195],
							[114.038475, 22.468470],
							[114.037918, 22.468470],
							[114.037918, 22.468752],
							[114.037361, 22.468752],
							[114.037361, 22.469028],
							[114.036713, 22.469028],
							[114.036530, 22.469028],
							[114.036530, 22.469305],
							[114.035973, 22.469305],
							[114.035973, 22.469584],
							[114.035698, 22.469584],
							[114.035698, 22.469864],
							[114.034859, 22.469864],
							[114.034859, 22.470139],
							[114.034027, 22.470139],
							[114.034027, 22.470417],
							[114.033752, 22.470417],
							[114.033752, 22.470694],
							[114.033195, 22.470694],
							[114.033195, 22.470972],
							[114.032913, 22.470972],
							[114.032913, 22.471251],
							[114.032639, 22.471251],
							[114.032639, 22.471527],
							[114.032082, 22.471527],
							[114.032082, 22.471806],
							[114.031822, 22.471806],
							[114.031807, 22.472086],
							[114.031525, 22.472086],
							[114.031525, 22.472361],
							[114.031250, 22.472361],
							[114.031250, 22.473194],
							[114.030975, 22.473194],
							[114.030975, 22.473473],
							[114.030701, 22.473473],
							[114.030693, 22.474028],
							[114.029861, 22.474028],
							[114.029861, 22.474304],
							[114.028473, 22.474304],
							[114.028473, 22.474583],
							[114.028198, 22.474583],
							[114.028198, 22.474861],
							[114.027916, 22.474861],
							[114.027916, 22.475140],
							[114.027641, 22.475140],
							[114.027641, 22.475695],
							[114.027084, 22.475695],
							[114.027084, 22.475973],
							[114.026802, 22.475973],
							[114.026802, 22.476805],
							[114.026527, 22.476805],
							[114.026527, 22.477083],
							[114.026253, 22.477083],
							[114.026253, 22.477362],
							[114.025970, 22.477362],
							[114.025970, 22.477636],
							[114.025696, 22.477636],
							[114.025696, 22.477917],
							[114.025414, 22.477917],
							[114.025414, 22.478195],
							[114.025139, 22.478195],
							[114.025139, 22.478472],
							[114.024864, 22.478472],
							[114.024864, 22.478748],
							[114.024307, 22.478748],
							[114.024307, 22.479031],
							[114.024025, 22.479031],
							[114.024025, 22.479305],
							[114.023750, 22.479305],
							[114.023750, 22.479584],
							[114.023476, 22.479584],
							[114.023476, 22.479858],
							[114.022636, 22.479858],
							[114.022636, 22.479584],
							[114.022362, 22.479584],
							[114.022362, 22.478195],
							[114.020416, 22.478195],
							[114.020416, 22.477917],
							[114.019585, 22.477917],
							[114.019585, 22.477636],
							[114.019302, 22.477636],
							[114.019302, 22.477362],
							[114.018753, 22.477362],
							[114.018753, 22.477083],
							[114.018196, 22.477083],
							[114.018196, 22.476805],
							[114.017365, 22.476805],
							[114.017365, 22.476526],
							[114.017082, 22.476526],
							[114.017082, 22.476250],
							[114.016808, 22.476250],
							[114.016808, 22.475973],
							[114.016525, 22.475973],
							[114.016525, 22.475416],
							[114.016251, 22.475416],
							[114.016251, 22.474861],
							[114.015976, 22.474861],
							[114.015976, 22.474310],
							[114.015701, 22.474304],
							[114.015694, 22.473749],
							[114.015419, 22.473749],
							[114.015419, 22.473194],
							[114.014862, 22.473194],
							[114.014862, 22.468195],
							[114.014580, 22.468195],
							[114.014580, 22.467916],
							[114.014030, 22.467916],
							[114.014030, 22.468195],
							[114.014305, 22.468195],
							[114.014305, 22.472361],
							[114.014030, 22.472361],
							[114.014030, 22.472918],
							[114.013550, 22.472918],
							[114.013474, 22.472918],
							[114.013474, 22.473473],
							[114.012917, 22.473473],
							[114.012917, 22.473749],
							[114.012642, 22.473749],
							[114.012642, 22.474028],
							[114.011528, 22.474028],
							[114.011528, 22.474304],
							[114.011253, 22.474304],
							[114.011253, 22.474583],
							[114.010971, 22.474583],
							[114.010971, 22.474861],
							[114.010696, 22.474861],
							[114.010696, 22.475140],
							[114.010414, 22.475140],
							[114.010414, 22.475416],
							[114.010139, 22.475416],
							[114.010139, 22.475695],
							[114.009865, 22.475695],
							[114.009865, 22.475973],
							[114.009583, 22.475973],
							[114.009583, 22.476250],
							[114.009857, 22.476250],
							[114.009865, 22.476526],
							[114.010971, 22.476526],
							[114.010971, 22.475973],
							[114.011215, 22.475973],
							[114.011253, 22.475695],
							[114.011803, 22.475695],
							[114.011803, 22.475416],
							[114.012642, 22.475416],
							[114.012642, 22.475695],
							[114.013191, 22.475695],
							[114.013191, 22.475973],
							[114.013298, 22.475973],
							[114.014030, 22.475973],
							[114.014030, 22.476250],
							[114.014305, 22.476250],
							[114.014305, 22.476526],
							[114.014580, 22.476526],
							[114.014580, 22.476805],
							[114.014862, 22.476805],
							[114.014862, 22.477083],
							[114.015137, 22.477083],
							[114.015137, 22.477362],
							[114.015419, 22.477362],
							[114.015419, 22.479031],
							[114.015976, 22.479031],
							[114.015976, 22.479305],
							[114.016251, 22.479305],
							[114.016251, 22.479584],
							[114.017082, 22.479584],
							[114.017082, 22.479858],
							[114.017639, 22.479858],
							[114.017639, 22.480141],
							[114.018196, 22.480141],
							[114.018196, 22.480417],
							[114.018753, 22.480417],
							[114.018753, 22.480694],
							[114.019302, 22.480694],
							[114.019302, 22.480972],
							[114.020416, 22.480972],
							[114.020416, 22.481253],
							[114.021530, 22.481253],
							[114.021530, 22.481527],
							[114.021805, 22.481527],
							[114.021805, 22.482080],
							[114.022087, 22.482084],
							[114.022087, 22.482363],
							[114.022636, 22.482363],
							[114.022636, 22.482639],
							[114.023193, 22.482639],
							[114.023193, 22.482916],
							[114.023476, 22.482916],
							[114.023476, 22.483194],
							[114.024025, 22.483194],
							[114.024025, 22.483473],
							[114.025139, 22.483473],
							[114.025139, 22.484306],
							[114.025414, 22.484306],
							[114.025414, 22.485416],
							[114.024864, 22.485416],
							[114.024864, 22.487362],
							[114.024582, 22.487362],
							[114.024582, 22.487917],
							[114.024864, 22.487917],
							[114.024864, 22.488472],
							[114.025139, 22.488472],
							[114.025139, 22.488750],
							[114.025696, 22.488750],
							[114.025696, 22.489025],
							[114.025970, 22.489025],
							[114.025970, 22.489305],
							[114.026253, 22.489305],
							[114.026253, 22.489584],
							[114.026527, 22.489584],
							[114.026527, 22.490694],
							[114.026802, 22.490694],
							[114.026802, 22.490973],
							[114.027077, 22.490973],
							[114.027077, 22.491247],
							[114.026802, 22.491247],
							[114.026802, 22.491810],
							[114.027084, 22.491808],
							[114.027084, 22.493473],
							[114.027641, 22.493473],
							[114.027641, 22.494032],
							[114.027916, 22.494028],
							[114.027916, 22.494305],
							[114.028473, 22.494305],
							[114.028473, 22.494862],
							[114.028748, 22.494862],
							[114.028748, 22.495420],
							[114.029297, 22.495420],
							[114.029297, 22.495703],
							[114.029587, 22.495695],
							[114.029694, 22.495731],
							[114.031044, 22.497257],
							[114.031891, 22.498522],
							[114.033569, 22.498943],
							[114.037361, 22.502729],
							[114.038620, 22.504416],
							[114.040306, 22.503992],
							[114.041992, 22.505676],
							[114.044518, 22.505257],
							[114.046623, 22.504833],
							[114.048309, 22.503992],
							[114.052094, 22.503992],
							[114.055466, 22.504416],
							[114.058411, 22.507359],
							[114.058411, 22.510305],
							[114.058830, 22.511992],
							[114.059677, 22.513676],
							[114.063881, 22.517042],
							[114.067673, 22.518307],
							[114.069359, 22.518728],
							[114.073570, 22.519150],
							[114.075668, 22.517466],
							[114.078194, 22.516203],
							[114.079460, 22.516623],
							[114.082825, 22.519150],
							[114.084511, 22.519991]
						]
					]
				}
			}
	
		]
	}
} 

export default {
    cityJson
}
