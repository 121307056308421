<!-- 旭日图配置 -->
<template>
    <div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'risingsunmap',
    inject: ["main"]
  }
  </script>
  
  <style>
  </style>