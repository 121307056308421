<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <!-- <div :ref="id"
         :style="styleChartName"></div>-->
    <h3>我是参数:{{ option }}</h3>
    <iframe id="iframe" :src="option.iframeSrc" height="100%" width="100%" frameborder="0"></iframe>
  </div> 
  
</template>

<script>
import create from "../../create";

export default create({
  name: "area",
  computed: {
    x2 () {
      return this.option.gridX2 || 20;
    }
  },

  methods: {
    updateChart () {
      this.option.iframeSrc = 'http://127.0.0.1:8080/build/1';
    }
  }
});

</script>
