<template>
    <div :class="[b(),className]"
         :style="styleSizeName">
      <div :ref="id"
           :style="styleChartName"></div>
    </div>
  </template>
  
  <script>
  import create from "../../create";
  // import 'echarts/extension/bmap/bmap';
  
  export default create({
    name: "heatandbdmap",
    computed: {
      x2 () {
        return this.option.gridX2 || 20;
      }
    },
  
    methods: {
      updateChart () {


        const optionData = this.deepClone(this.dataChart);

        console.log('optionData.categories>>>>>>' + optionData)

        var points = [].concat.apply(
          [],
          optionData.map(function (track) {
            return track.map(function (seg) {
              return seg.coord.concat([1]);
            });
          })
        );
        const option = {
          animation: false,
          bmap: {
            center: [120.13066322374, 30.240018034923],
            zoom: 14,
            roam: true
          },
          visualMap: {
            show: false,
            top: 'top',
            min: 0,
            max: 5,
            seriesIndex: 0,
            calculable: true,
            inRange: {
              color: ['blue', 'blue', 'green', 'yellow', 'red']
            }
          },
          series: [
            {
              type: 'heatmap',
              coordinateSystem: 'bmap',
              data: points,
              pointSize: 5,
              blurSize: 6
            }
          ]
        }
        // 添加百度地图插件
        // var bmap = this.myChart.getModel().getComponent('bmap').getBMap();
        // bmap.addControl(new BMap.MapTypeControl());
        
        this.myChart.resize();
        this.myChart.setOption(option, true);
      }
    }
  });
  
  </script>
  