<template>
    <div :class="[b(),className]"
         :style="styleSizeName">
      <div :ref="id"
           :style="styleChartName"></div>
    </div>
  </template>
  
  <script>
  import create from "../../create";
//   import 'echarts-gl';
//   import cityJson from "../../../cons/stateOwnedLand";
  
  export default create({
    name: "buildings",
    computed: {
      x2 () {
        return this.option.gridX2 || 20;
      }
    },
  
    methods: {

      updateChart () {

		
        const optionData = this.deepClone(this.dataChart);
		

		let that = this;
		$.getJSON(optionData.geoJsonUrl,
			function (cityJson) {
				console.log(cityJson)

			for(let i = 0 ; i < cityJson.features.length ; i ++){
				cityJson.features[i]['properties']['name'] = '';
			}
			// for(let feature in cityJson.features){
			// 	// if( !feature.properties.name){
			// 		console.log(feature.id)
			// 		if(feature['properties']){
			// 			feature['properties']['name'] ='name';
			// 		}
					
			// 	// }
			// }

			console.log(cityJson)

			echarts.registerMap('buildings', cityJson);

			
			const option = {
				geo3D: {  //注意这里和图表不一样  使用的是geo
						type: 'map',
						map: 'buildings', //这里指定的  是registerMap 里指定的名字自定义
						viewControl: {
							beta: 45, //x轴旋转
							alpha: 45, //Y轴旋转
							panMouseButton: 'right', //平移操作使用的鼠标按键
							rotateMouseButton: 'left', //旋转操作使用的鼠标按键
						},
						light: {
							main: {
								color: '#ffffff',
								intensity: 1,
								shadow: false,
							},
						},
						itemStyle: {
							color: '#4D96FA',
							borderWidth: 1,
							borderColor: '#fff',
							opcity: 1,
						},

						shading: 'realistic',
						label: {
							show: true,
							color: '#fff',
							distance: 5,
						},
						emphasis: {
							label: { show: true },
							itemStyle: { color: '#36A8FF' },
						},
						groundPlane: false,
						// 将geo3d放在最底层
						zlevel: -1
				},
				
			};
			that.myChart.resize();
			that.myChart.setOption(option, true);
			
		})

      }
    }
  });
  
  </script>
  